<template>
  <div class="myPublished">
    <div class="pageWrap">
      <div class="header">
        <div class="headerContentWrap">
          <div class="list1">
            <span
              class="item"
              :class="selectedScope == item.scopeType ? 'active' : ''"
              v-for="(item, index) in scopeList"
              :key="index"
              @click="doSelectScope(item.scopeType)"
            >
              <img v-if="item.icon" :src="item.icon" alt="" />
              {{ item.label }}
            </span>
          </div>
          <div class="list2" v-if="selectedScope != publicScopeType.enterpriceOnly" >
            <span
              class="item"
              :class="selectType === item.searchType ? 'active' : ''"
              v-for="(item, index) in tabList2"
              :key="index"
              @click="selectTab(item.searchType)"
            >
              <img v-if="item.icon" :src="item.icon" alt="" />
              {{ item.label }}
            </span>
          </div>
        </div>
      </div>
      <div class="body">
        <div class="bodyWrap">
          <div class="listWrap" v-if="dataList && dataList.length > 0">
            <div
              class="itemWrap"
              v-for="(item, index) in dataList"
              :key="item.projectGuid"
            >
              <div class="item">
                <div class="detailInfo"  >
                  <!-- @click="goToChangeProject(item, index)" 
                   -->
                  <div class="imgWrap" @click="goToReleaseProject(item, index)" >
                    <el-image class="cover" :src="returnCoverImageUrl(item)" fit="cover" alt="">
                      <template #error>
                        <div class="image-slot">
                          <img  src="@/assets/home/defaultCover.png" alt="" />
                        </div>
                      </template>
                    </el-image>
                    <div class="projectTypeIcon">
                      <div v-if="item.editType == 0" class="threeD">
                        <img src="@/assets/home/threeD.png" alt="" />
                      </div>
                      <div v-if="item.editType == 1" class="twoD">
                        <img src="@/assets/home/twoD.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="projectInfoWrap" @click="goToReleaseProject(item, index)" >
                    <div class="info">
                      <div class="proName">
                        <span class="projectName">
                          {{ returnReleaseName(item) }}
                        </span>
                      </div>
                    </div>
                    <div class="time">
                      <div>
                        <div>
                          <span
                            v-if="item.auditStatus && item.auditStatus.version"
                          >
                            {{
                              $t("myPublished.version") +
                                item.auditStatus.version
                            }}
                          </span>
                          <span v-if="item.releaseInfo">
                            <!-- {{ getVersion(item) }} -->
                            {{
                              $t("myPublished.version") +
                                item.releaseInfo.version
                            }}
                          </span>
                        </div>
                        <div class="status">
                          <button
                            class="auditing"
                            v-if="item.auditStatus?.auditStatus === auditStatusType.tobeAudit"
                          >
                            <img src="@/assets/myTask/auditing.png" alt="" />
                            <!-- 审核中 -->
                            <span>{{ $t("myPublished.auditting") }}</span>
                          </button>
                          <button
                            class="passed"
                            v-else-if="item.auditStatus?.auditStatus === auditStatusType.passed"
                          >
                            <img src="@/assets/myTask/passed.png" alt="" />
                            <!-- 已通过 -->
                            <span>{{ $t("myPublished.pass") }}</span>
                          </button>
                          <button
                            class="reject"
                            v-if="item.auditStatus?.auditStatus === auditStatusType.rejected"
                          >
                            <img src="@/assets/myTask/reject.png" alt="" />
                            <!-- 未通过 -->
                            <span>{{ $t("myPublished.noPass") }}</span>
                          </button>
                          <el-popover
                            v-if="item.auditStatus?.auditStatus === auditStatusType.rejected"
                            placement="bottom"
                            :append-to-body="false"
                            popper-class="myPopover"
                            :width="200"
                            trigger="click"
                            :content="item.auditStatus.reason"
                          >
                            <template #reference>
                              <button class="showReason">
                                <!-- 查看原因 -->
                                {{ $t("myPublished.showReson") }}
                              </button>
                            </template>
                          </el-popover>
                        </div>
                      </div>
                    </div>
                    <div class="nearlyTime">
                    </div>
                    <div class="operation">
                      <span
                        ><img src="@/assets/myTask/thumbnail.png" alt="" />
                        {{
                          item.likeCount
                            ? item.likeCount > 99
                              ? "99+"
                              : item.likeCount
                            : "0"
                        }}
                      </span>
                      <span
                        ><img src="@/assets/myTask/star.png" alt="" />
                        {{
                          item.favourateCount
                            ? item.favourateCount > 99
                              ? "99+"
                              : item.favourateCount
                            : "0"
                        }}
                      </span>
                      <span
                        ><img src="@/assets/myTask/eye.png" alt="" />
                        {{
                          item.previewCount
                            ? item.previewCount > 99
                              ? "99+"
                              : item.previewCount
                            : "0"
                        }}
                      </span>
                      <span
                        ><img src="@/assets/myTask/share.png" alt="" />
                        {{
                          item.forwardCount
                            ? item.forwardCount > 99
                              ? "99+"
                              : item.forwardCount
                            : "0"
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- cancelRelease(item, index) -->
                <div class="ctrl">
                  <button v-if="selectedScope == publicScopeType.enterpriceOnly || selectType == auditStatusType.passed"
                    @click="cancelRelease(item, index)"
                    class="primary"
                  >
                    {{ $t("myPublished.offline") }} 
                  </button>

                  <button
                    v-if="item.auditStatus?.auditStatus == auditStatusType.tobeAudit"
                    class="primary"
                    @click="cancelAudit(item, index)"
                  >
                    <!-- 撤回审核 -->
                    {{ $t("myPublished.withdraw") }}
                  </button>
                  <!-- <button
                    v-if="item.auditStatus === 2"
                    class="delete"
                    @click="cancelRelease(item, index)"
                  >
                    {{ $t("myPublished.offline") }}
                  </button> -->

                  <button
                    @click="reSubmit(item, index)"
                    v-if="item.auditStatus?.auditStatus === auditStatusType.rejected"
                    class="primary"
                  >
                    {{ $t("myPublished.reSubmit") }}
                  </button>

                  <!-- v-if="selectType === 999" -->
                  <!-- v-if="
                      (item.auditStatus &&
                        item.auditStatus.auditStatus === 0) ||
                        (!item.auditStatus && item.auditStatus !== 0) ||
                        (item.auditStatus &&
                          item.auditStatus.auditStatus === 3) ||
                        (item.auditStatus && item.auditStatus.auditStatus === 1)
                    " -->
                  <!-- <button v-if="selectType != -1"
                    @click="goToChangeProject(item, index)"
                    class="primary"
                  >
                    {{ $t("myPublished.edit") }}
                  </button> -->
                  <!-- <button v-if="selectType != -1"
                    class="primary"
                    @click="goToReleaseProject(item, index)"
                  > 
                    {{ $t("myPublished.viewCurrentVersion") }}
                  </button>-->
                  <button v-if="selectedScope != publicScopeType.enterpriceOnly"
                    class="primary"
                    @click="showAuditHistoryList(item, index)"
                  >
                    {{ $t("myPublished.auditHistory") }}
                  </button>
                  <el-dropdown trigger="click" class="dropDown" v-if="false">
                    <button class="aggregate">
                      <i class="el-icon-more"></i>
                    </button>
                    <template #dropdown>
                      <div class="taskCtrlListWarp">
                        <el-dropdown-menu>
                          <el-dropdown-item command="toBottom" class="btnWarp">
                            <!-- 审核记录 -->
                            <button @click="showAuditHistoryList(item, index)">
                              {{ $t("myPublished.auditHistory") }}
                            </button>
                            <!-- 删除项目 -->
                            
                            <button
                              class="delete"
                              v-if="selectType === 999"
                              @click="deleteProject(item, index)"
                            >
                              {{ $t("myPublished.deleteProject") }}
                            </button>
                            <!-- 下架 -->
                            <!-- <button
                              class="delete"
                              v-if="
                                item.releaseInfo ||
                                  (item.auditStatus &&
                                    item.auditStatus.auditStatus === 2)
                              "
                              @click="cancelRelease(item, index)"
                            >
                              {{ $t("myPublished.offlineTip") }}
                            </button> -->
                            <button
                              class="delete"
                              v-if="item.auditStatus?.auditStatus == auditStatusType.passed ||item.releaseInfo"
                              @click="cancelRelease(item, index)"
                            >
                              {{ $t("myPublished.offlineTip") }}
                            </button>
                            <!-- <button
                              class="delete"
                              @click="cancelRelease(item, index)"
                            >
                              {{ $t("myPublished.offlineTip") }}
                            </button> -->
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </div>
                    </template>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </div>
          <div class="enpty" v-else>
            <div>
              <img src="@/assets/myTask/defaultEnpty.png" alt="" />
              <!-- 当前无可浏览项目 -->
              <div>{{ $t("myTask.word8") }}</div>
            </div>
          </div>
        </div>
        <div class="pages" v-if="dataList.length !== 0">
          <el-pagination
            background
            :page-size="size"
            :page-sizes="[5, 10, 20, 50]"
            layout="prev, pager, next, jumper, sizes"
            :total="total"
            :current-page="page"
            @size-change="handleSizeChange"
            @current-change="changePageIndex"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <AuditHistory ref="auditHistory" />
    <CancelRelease @cancelRelease="callCancelRelease" ref="cancelRelease" />
  </div>
</template>

<script>
import {
  getMyProjectsWithAudit,
  getAllReleasesForFirm,
  cancelAudit,
  auditProject,
} from "@/api/mine.js";
import { formatDateTime } from "@/utils/time.js";
import AuditHistory from "@/views/myTasks/components/auditHistory.vue";
import CancelRelease from "@/views/myTasks/components/cancelRelease.vue";
import { AuditStatusType, PublicScopeType } from "@/utils/common";

export default {
  components: { AuditHistory, CancelRelease },
  props: {},
  data() {
    return {
      auditStatusType: AuditStatusType,
      publicScopeType: PublicScopeType,
      submitLoading: false,
      selectType: AuditStatusType.tobeAudit, // -1无需审核（企业发布） 1//待审核 2//审核通过 3//审核拒绝  
      selectedScope: PublicScopeType.enterpriceOnly, //-1 企业可见 999 全部可见 
      page: 1,
      size: 5,
      total: 0,
      scopeList: [
        {
          // 企业公开
          label: this.$t("myPublished.enterpriceOnly"),
          scopeType: PublicScopeType.enterpriceOnly,
          value: 0,
          icon: null,
        },
        {
          // 全部公开
          label: this.$t("myPublished.allProcess"),
          scopeType: PublicScopeType.all,
          value: 0,
          icon: null,
        },
      ],
      tabList2: [
        // {
        //   // 未提交
        //   label: this.$t("myPublished.unsubmitted"),
        //   searchType: 0,
        //   value: 0,
        //   icon: require("@/assets/myTask/noSubmit.png"),
        // },
        {
          // 审核中
          label: this.$t("myPublished.auditting"),
          searchType: AuditStatusType.tobeAudit,
          value: 0,
          icon: require("@/assets/myTask/auditing1.png"),
        },
        {
          // 未通过
          label: this.$t("myPublished.noPass"),
          searchType: AuditStatusType.rejected,
          value: 0,
          icon: require("@/assets/myTask/reject1.png"),
        },
        {
          // 已通过
          label: this.$t("myPublished.pass"),
          searchType: AuditStatusType.passed,
          value: 0,
          icon: require("@/assets/myTask/passed.png"),
        },
      ],
      dataList: [],
    };
  },
  computed: {    
  },
  async created() {
    console.log("created");
    //获取列表数字
    // this.getTabNum();
    //获取 全部待审核的列表
    this.getData();
  },
  mounted() {},
  watch: {
    '$i18n.locale'(newValue, oldValue) {
      this.loadTabNames()
    }
  },
  methods: {
    //判断显示的项目名称
    // showProjectName(item) {
    //   if (
    //     item.auditStatus &&
    //     item.auditStatus.projectName !== item.projectName
    //   ) {
    //     return item.auditStatus.projectName;
    //   } else if (
    //     item.releaseInfo &&
    //     item.releaseInfo.projectName !== item.projectName
    //   ) {
    //     return item.releaseInfo.projectName;
    //   } else {
    //     return item.projectName;
    //   }
    // },
    // //判断是否显示已发布的版本的项目名称
    // isShowOldProjectName(item) {
    //   if (item.auditStatus) {
    //     return item.auditStatus.projectName !== item.projectName;
    //   } else if (item.releaseInfo) {
    //     return item.releaseInfo.projectName !== item.projectName;
    //   }
    // },
    loadTabNames(){
      this.scopeList = [
        {
          // 企业公开
          label: this.$t("myPublished.enterpriceOnly"),
          scopeType: PublicScopeType.enterpriceOnly,
          value: 0,
          icon: null,
        },
        {
          // 全部公开
          label: this.$t("myPublished.allProcess"),
          scopeType: PublicScopeType.all,
          value: 0,
          icon: null,
        },
      ];
      this.tabList2= [
        // {
        //   // 未提交
        //   label: this.$t("myPublished.unsubmitted"),
        //   searchType: 0,
        //   value: 0,
        //   icon: require("@/assets/myTask/noSubmit.png"),
        // },
        {
          // 审核中
          label: this.$t("myPublished.auditting"),
          searchType: AuditStatusType.tobeAudit,
          value: 0,
          icon: require("@/assets/myTask/auditing1.png"),
        },
        {
          // 未通过
          label: this.$t("myPublished.noPass"),
          searchType: AuditStatusType.rejected,
          value: 0,
          icon: require("@/assets/myTask/reject1.png"),
        },
        {
          // 已通过
          label: this.$t("myPublished.pass"),
          searchType: AuditStatusType.passed,
          value: 0,
          icon: require("@/assets/myTask/passed.png"),
        }]
    },

    returnReleaseName(item) {
        if (item.auditStatus) {
          return item.auditStatus.projectName;
        } else if (item.releaseInfo) {
          return item.releaseInfo.projectName;
        }
        else {
          return item.projectName;
        }
    },
    returnCoverImageUrl(item) {
      if (item.auditStatus) {
        return item.auditStatus.coverImageUrl;
      } else if (item.releaseInfo) {
        return item.releaseInfo.coverImageUrl;
      }
      else {
        return item.coverImageUrl;
      }
    },
    deleteProject(item, index) {
      console.log(item, index);
      this.$emit("deleteProject", item);
    },
    // async getData() {
    //   await this.getListByType(this.selectType, this.page, this.selectedScope);
    // },
    callCancelRelease(cancelAll, item) {
      console.log(item);
      this.$emit("cancelRelease", cancelAll, item);
    },
    getVersion(item) {
      if (item.auditStatus) {
        return (
          this.$t("myPublished.version") +
          this.formatDateTimeVue(item.auditStatus.updatedAt)
        );
      } else if (item.releaseInfo) {
        return (
          this.$t("myPublished.version") +
          this.formatDateTimeVue(item.releaseInfo.createdAt)
        );
      } else {
        return "";
      }
    },
    showAuditHistoryList(item, index) {
      console.log(item, index);
      this.$refs.auditHistory.open(true, item);
    },
    async reSubmit(item, index) {
      // "确认重新提交此项目?", "重新提交"
      // this.$confirm(
      //   this.$t("myPublished.reSubmitTip"),
      //   this.$t("myPublished.reSubmit"),
      //   {
      //     // 确定
      //     confirmButtonText: this.$t("myPublished.confirm"),
      //     // 取消
      //     cancelButtonText: this.$t("myPublished.cancel"),
      //   }
      // )
      //   .then(async () => {

      //   })
      //   .catch(() => {});
      this.$prompt("", this.$t("myPublished.reSubmit"), {
        // 确定
        confirmButtonText: this.$t("releaseProject.submit"),
        // 取消
        cancelButtonText: this.$t("auditProject.cancel"),
        // inputPattern: /^(?!\s*$).+/,
        showInput: true,
        // 填写违规原因
        inputPlaceholder: this.$t("releaseProject.inputDes"),
        inputType: "textarea",
        // 内容不能为空
        inputErrorMessage: this.$t("auditProject.errorTip"),
        // customClass: "deleteBox",
      })
        .then(async ({ value }) => {
          this.submitLoading = true;
          try {
            await auditProject(
              item.projectGuid,
              true,
              value
            );
            // if (res.code == 200) {
              this.$message({
                type: "success",
                // 已提交
                message: this.$t("myPublished.submited"),
              });
              await this.getData();
            this.submitLoading = false;
          } catch (e) {
            // 提交失败,请检查网络或服务
            console.log('resubmit e', e)

            if (e.code == 30135){
              this.$message.error(this.$t("myPublished.summitErrorTitle"), this.$t("myPublished.submitErrorAuditing"));
            }
            else{
              this.$message.error(this.$t("myPublished.submitError"));
            }
            this.submitLoading = false;
          }
        })
        .catch(() => {
          console.log('resubmit 2', e)
          this.loading = false;
        });
    },
    
    cancelRelease(item, index) {
      this.$refs.cancelRelease.open(true, item);
    },
    goToReleaseProject(item, index) {
      
      let inputReleaseGuid;
      if (item.auditStatus) {
        inputReleaseGuid = item.auditStatus.auditReleaseGuid;
      } else if (item.releaseInfo) {
        inputReleaseGuid = item.releaseInfo.releaseGuid;
      }
      else{
        inputReleaseGuid = item.releaseGuid;
      }
      this.$router.push({
        path: "/taskDetail",
        query: {
          projectGuid: item.projectGuid,
          releaseGuid: inputReleaseGuid,
          releaseMode: true,
        },
      });
    },
    goToChangeProject(item, index) {
      console.log(item, index);
      this.$router.push({
        path: "/taskDetail",
        query: {
          projectGuid: item.projectGuid,
        },
      });
    },
    showReason(item, index) {
      // 拒绝原因
      this.$confirm(item.auditStatus.reason, this.$t("myPublished.reason"), {
        // 知道了
        confirmButtonText: this.$t("myPublished.know"),
        // type: "warning",
        showCancelButton: false,
      })
        .then(() => {})
        .catch(() => {});
    },
    cancelAudit(item, index) {
      console.log(item, index);
      // 确认撤回已提交的审核?", "撤回审核
      this.$confirm(
        this.$t("myPublished.withdrawTip"),
        this.$t("myPublished.withdraw"),
        {
          // 确定
          confirmButtonText: this.$t("myPublished.confirm"),
          // 取消
          cancelButtonText: this.$t("myPublished.cancel"),
          // type: "error",
        }
      )
        .then(async () => {
          try {
            await cancelAudit(item.projectGuid);
            //获取列表数字
            // this.getTabNum();
            //获取 全部待审核的列表
            // await this.getListByType(this.selectType, this.page);
            await this.getData();
            this.$emit("publishedListChange");
            this.$message({
              type: "success",
              // 删除成功
              message: this.$t("myPublished.deleteSuccess"),
            });
          } catch (e) {
            console.log(e);
            this.$message({
              type: "error",
              // 删除失败
              message: this.$t("myPublished.deleteFail"),
            });
          }
        })
        .catch(() => {});
    },
    formatDateTimeVue(time) {
      let res = formatDateTime(time);
      console.log(res);
      return res;
    },
    //选择tab
    selectTab(searchType) {
      this.selectType = searchType;
      // this.getListByType(item.searchType, this.page);
      this.getData();
    },

    doSelectScope(scope) {
      console.log('select Scope', scope)
      if (this.selectedScope == scope)
        return;

      this.selectedScope = scope;

      this.page = 1;      
      this.getData();
    },
    findValueByType(type, dataList) {
      console.log(type, dataList);
      let res = dataList.find((item) => item.status === type);
      console.log(res);
      return res;
    },
    changePageIndex(pageIndex) {
      this.page = pageIndex;
      
      this.getData();
    },

    handleSizeChange(size){
      this.page = 1;
      this.size = size;
      this.getData();
    },
    //根据被选中的tab获取列表
    async getData() {
    // async getData(){
      // this.selectType = selectType == 999 ? 1 : selectType;
      let res;
      if (this.selectedScope == PublicScopeType.enterpriceOnly) {
        res = await getAllReleasesForFirm(this.page - 1, this.size);
      } else {
        res = await getMyProjectsWithAudit(this.page - 1, this.size, this.selectType);
      }
      console.log(res);
      // this.page = res.page + 1;
      // this.size = res.size;
      this.total = res.total;
      this.dataList = res.data ? res.data : [];
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/el-dropdown.scss";
@import "@/styles/el-popover.scss";
.myPublished {
  width: 100%;
  height: 100%;
  .pageWrap {
    width: 100%;
    //height: 100%;
    .header {
      width: 100%;
      // height: 40px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin: 25px 0 5px 0;
      > .headerContentWrap {
        width: 75%;
        div {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .item {
            font-size: 14px;
            color: #616161;
            cursor: pointer;
            margin-right: 1rem;
            font-size: 0.8rem;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 12px;
            border-radius: 6px;
            img {
              width: 12px;
              height: 12px;
              margin-right: 5px;
            }
          }
          .active {
            color: #4582ff;
            background: #ecf2ff;
          }
        }
        .list2 {
          margin-top: 10px;
        }
      }
    }
    .body {
      width: 100%;
      height: calc(100% - 102px);
      padding-bottom: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .bodyWrap {
        width: 75%;
        height: 100%;
        .listWrap {
          width: 100%;
          height: 100%;
          .itemWrap {
            border-bottom: 1px solid #dde2e5;
            .item {
              width: 100%;
              height: 156px;
              padding: 24px 0;
              box-sizing: border-box;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .detailInfo {
                width: 70%;
                height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .imgWrap {
                  width: 192px;
                  height: 108px;
                  border-radius: 12px;
                  overflow: hidden;
                  margin-right: 24px;
                  position: relative;
                  // cursor: pointer;
                  .cover{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }

                  .image-slot img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }

                   img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                  .projectTypeIcon div {
                    width: 22%;
                    position: absolute;
                    bottom: 6%;
                    left: 4%;
                    // background: #fff;
                    border-radius: 14%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    div {
                      width: 100%;
                      img {
                        width: 100%;
                      }
                    }
                  }
                }
                .projectInfoWrap {
                  cursor: default;
                  width: calc(100% - 192px - 24px);
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  .info {
                    width: 100%;
                    height: 25px;
                    .proName {
                      text-align: left;
                      font-weight: bold;
                      color: #262626;
                      font-size: 18px;
                      white-space: nowrap; /* 禁止换行 */
                      // overflow: hidden; /* 隐藏超出容器的部分 */
                      // text-overflow: ellipsis; /* 显示省略号 */
                      line-height: 25px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      // .projectName {
                      //   cursor: pointer;

                      //   &:hover {
                      //     text-decoration: underline 1px;
                      //   }
                      // }
                      .prevVersionName {
                        color: #818181;
                        font-size: 14px;
                        font-weight: 300;
                        margin-left: 10px;
                      }
                      .enterpriseTag {
                        margin-right: 8px;
                        background: #cccccc;
                        border-radius: 4px 4px 4px 4px;
                        color: #ffffff;
                        box-sizing: border-box;
                        padding: 5px 4px;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 14px;
                      }
                    }
                  }
                  .time {
                    color: #474747;
                    font-size: 14px;
                    height: 24px;
                    margin-top: 10px;
                    div {
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      div:nth-child(1) {
                        margin-right: 1rem;
                        font-weight: bold;
                      }
                      .status {
                        text-align: left;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        button {
                          margin-right: 8px;
                          padding: 4px 10px;
                          border-radius: 4px;
                          font-size: 14px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          border: none;
                          outline: none;
                          img {
                            width: 16px;
                            height: 16px;
                          }
                        }
                        .auditing {
                          background: #e3ecff;
                          color: #4582ff;
                        }
                        .passed {
                          background: #d5fadf;
                          color: #3aab58;
                          img {
                            width: 12px;
                            height: 12px;
                            margin-right: 3px;
                          }
                        }
                        .reject {
                          background: #ffecec;
                          color: #f94a4a;
                        }
                        .showReason {
                          background: #ebeaea;
                          color: #3b3b3b;
                          cursor: pointer;
                          &:hover {
                            text-decoration: 1px underline;
                          }
                        }
                      }
                    }
                  }
                  .nearlyTime {
                    height: 18px;
                    margin-top: 10px;
                    span {
                      color: #818181;
                    }
                  }
                  .operation {
                    font-size: 14px;
                    color: #888;  
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 10px;
                    :deep(.dropDown) {
                      width: 2rem;
                      height: 1rem;
                      position: absolute;
                      right: 0;
                    }
                    span {
                      // margin-right: 1.5vw;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      // width: 2vw;
                      // height: 1vw;
                      padding-right: 16px;
                      img {
                        width: 14px;
                        height: 14px;
                        margin-right: 6px;
                      }
                    }
                    .more {
                      margin: 0;
                      position: absolute;
                      right: 0;
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                      font-size: 14px;
                      cursor: pointer;
                      top: -26%;
                      i {
                        transform: rotate(-90deg);
                      }
                    }
                  }
                }
              }
              .ctrl {
                width: 280px;
                height: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                button {
                  min-width: 160px;
                  padding: 10px 10px;
                  font-size: 14px;
                  border: none;
                  border-radius: 4px;
                  margin-left: 20px;
                  cursor: pointer;
                }
                .delete {
                  color: #f94a4a;
                  background: #ffecec;
                }
                .primary {
                  background: #ecf2ff;
                  color: #274e78;
                }

                :deep(.dropDown) {
                  margin-left: 20px;
                }
                .aggregate {
                  width: 36px;
                  height: 36px;
                  min-width: 36px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 4px 4px 4px 4px;
                  border: 1px solid #bdccd9;
                  box-sizing: border-box;
                  background: #fff;
                  i {
                    transform: rotateZ(90deg);
                  }
                }
              }
            }
          }
        }
        .enpty {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .pages {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        position: absolute;
        bottom: 0.7rem;

        :deep(.el-pagination) {
          display: flex;
          justify-content: space-around;
          align-items: center;
          button {
            background: #fff;
            color: #8fb5ff;            
            border: 1px solid #dae6ff;
            border-radius: 10px;
            width: 60px;
            height: 38px;
          }
          button:hover {
            border: 1px solid #4582ff;
          }
          button[disabled] {
            border: 1px solid #dae6ff;
          }
          .el-pager {
            display: flex;
            justify-content: space-around;
            align-items: center;
            li {
              height: 36px;
              padding: 0 1rem;
              background: #ffffff;
              border-radius: 10px;
              opacity: 1;
              border: 1px solid #dae6ff;
              color: #224b77;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 400;
              
              &:hover {
                border: 1px solid #4582ff;
              }
            }
            .active {
              border: 1px solid #4582ff;
              background: #4582ff;
              color: #ffffff;
            }
          }

          .el-input {
            width: auto;
            height: auto;

            .el-input__inner {
              background: #eff3fc;
              width: 60px;  
              // width: fit-content;
              height: 36px;
              border-radius: 28px;
              color: #224b77;
            }
          }

          .el-pagination__sizes{ 
            height: auto;           
            .el-input .el-input__inner {
              width: 80px;  
            }
          }

          .el-pagination__jump{
            height: auto;
            .el-input .el-input__inner {
                width: 60px;  
              }
          }
        }
      }
    }
  }
}
</style>
