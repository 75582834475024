<template>
  <div>
    <p class="recordNumber">
      <a
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202007667"
        >
        <!-- <img
          :src="$store.state.TaskDetail.headerPath + 'picture/beian/beian.png'"
          alt=""
        /> -->
        沪公网安备31011202007667号</a
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a href="http://www.beian.miit.gov.cn/"
        >
        <!-- <img
          :src="$store.state.TaskDetail.headerPath + 'picture/beian/lz2.jpg'"
          alt=""
        /> -->
        沪ICP备17045764号-6A</a
      >
    </p>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss">
.recordNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #9e9e9e;
    transition: all 0.2s;
    font-size: 12px;
    > img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
  > a:hover {
    color: #4395ff;
  }
}
</style>
