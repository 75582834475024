<template>
  <div id="modelSetter">
    <!-- 基础模型 -->
    <el-dialog
      :title="$t('modelSetter.model')"
      v-model="showLoader"
      width="80vw"
      max-width="1600px"
      :before-close="handleClose"
      :destroy-on-close="true"
      referrerpolicy="same-origin no-referrer-when-downgrade"
      custom-class="modelViewDialog"
      ref="modelViewDialog"
      @open="opened"
    >
      <iframe
        v-if="showIframe"
        id="modelSetterView"
        ref="modelLoader"
        sandbox="allow-scripts allow-same-origin"
        frameborder="0"
        scrolling="no"
        style="width: 77.125%; border-radius: 15px"
      ></iframe>
      <div class="ctrlTipWarp" id="textBar">
        <div class="tipItem">
          <el-upload
            class="change-btn"
            action=""
            accept=".fbx,.obj,.glb,.gltf,.stl"
            :show-file-list="false"
            :multiple="false"
            :auto-upload="false"
            :on-change="onUploadMoldelChange"
          >
            <!-- 更换    上传-->
            <button class="upload" @click="uploadModel" :disabled="isLoading">
              {{
                hasModel ? $t("modelSetter.change") : $t("modelSetter.upload")
              }}
            </button>
          </el-upload>

          <button
            class="delete"
            :disabled="!baseModelFile"
            @click="deleteModel"
          >
            <!-- 删除 -->
            {{ $t("modelSetter.delete") }}
          </button>
        </div>
        <div class="tipItem" v-if="isModelPosition">
          <el-form>
            <!-- 模型使用尺寸 -->
            <el-form-item
              :label="$t('modelSetter.modelSize')"
              prop="modelSize"
              class="formItemWarpSelf"
            >
              <!-- 模型使用尺寸 -->
              <el-select
                size="mini"
                v-model="baseModelUnit"
                :placeholder="$t('modelSetter.modelSize')"
                popper-class="selectWarp"
                @change="positionTypeChange"
              >
                <el-option
                  v-for="item in baseModelUnitList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  class="provinces_select"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 模型重力方向 -->
            <el-form-item
              class="formItemWarpSelf"
              :label="$t('modelSetter.gravityArrow')"
              prop="modelG"
            >
              <!-- 模型重力方向 -->
              <el-select
                size="mini"
                v-model="selectKey"
                :placeholder="$t('modelSetter.gravityArrow')"
                popper-class="selectWarp"
                @change="positionTypeChange"
              >
                <el-option
                  v-for="item in baseModelGravityList"
                  :key="item.key"
                  :label="item.label"
                  :value="item.key"
                  class="provinces_select"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="tipItem" v-if="baseModelFile">
          <div class="modelWarp">
            <el-image :src="modelCoverUrl" fit="contain">
              <template #error>
                <div>
                  <div class="image-slot">
                    <img src="@/assets/task-detail/noModel.png" alt="" />
                    <!-- 无基础模型 -->
                    <p>{{ $t("modelSetter.noModel") }}</p>
                  </div>
                </div>
              </template>
            </el-image>
            <!-- :disabled="screenPrintType !== 0 || !baseModelFile" -->
            <button
              class="getCoverBtn"
              @click="getCover(1)"
              v-if="baseModelFile"
              :disabled="isLoading"
            >
              <i v-if="screenPrintType === 1" class="el-icon-loading"></i>
              <!-- 生成基础模型封面 -->
              {{ $t("modelSetter.getCover") }}
            </button>
          </div>
        </div>

        <div class="tipItem" v-if="!isModelPosition">
          <div class="QRCodeWarp">
            <!-- style="width: 190px; height: 120px" -->
            <el-image :src="QRCodePositionUrl" fit="contain">
              <template #error>
                <div>
                  <div class="image-slot">
                    <img src="@/assets/task-detail/defaultPicture.png" alt="" />
                    <!-- 无位置参考图 -->
                    <p>{{ $t("modelSetter.qrcodeCover") }}</p>
                  </div>
                </div>
              </template>
            </el-image>
            <!-- :disabled="screenPrintType !== 0 || !baseModelFile" -->
            <button
              class="getCoverBtn"
              @click="getCover(2)"
              :disabled="isLoading"
            >
              <i v-if="screenPrintType === 2" class="el-icon-loading"></i>
              <!-- 生成二维码位置参考图 -->
              {{ $t("modelSetter.gwtQRCodeCover") }}
            </button>
          </div>
        </div>
      </div>
      <ModelLoading
        v-if="isLoading"
        :scale="0.5"
        id="modelLoadingAnimate"
        :class="isLoading ? 'modelLoading' : 'modelLoading modelLiodingHidden'"
      />
      <!-- modelLiodingHidden -->
      <!-- 暂未上传基础模型 -->
      <!-- <div class="noModel" v-if="!hasModel && !baseModelFile" id="noModel">
        <div>
          <img src="@/assets/task-detail/noModel.png" alt="" />
          
          <p>{{ $t("modelSetter.noModel") }}</p>
        </div>
      </div> -->
      <template #footer>
        <span class="dialog-footer" v-if="showLoader">
          <!-- <el-button @click="showLoader = false">取 消</el-button>
          <el-button type="primary" @click="generateThumbnail">确 定</el-button> -->
          <!-- <button class="selfBtn cancel">取消</button> -->
          <!-- 取消 -->
          <button class="selfBtn cancel" @click="closeModelView">
            {{ $t("modelSetter.cancel") }}
          </button>
          <button
            class="selfBtn confirm"
            :disabled="submitLoadig"
            @click="submitInfo"
          >
            <i v-if="submitLoadig" class="el-icon-loading"></i>
            <!-- 确定 -->
            {{ $t("modelSetter.ok") }}
          </button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
// });
import { getLanguage } from "@/utils/getLanguage.js";
import { ElMessage } from "element-plus";
import { uuid } from "vue-uuid";
import ModelLoading from "@/views/components/animation/modelLoading.vue";
import {
  baseModelGravityList,
  positionType,
  baseModelUnitList,
} from "@/utils/asset";
import { getUploadSas } from "@/api/workflow";
import { BlockBlobClient, AnonymousCredential } from "@azure/storage-blob";
import { modelMaxSize } from "@/utils/asset.js";
import LangManager from "../../../utils/langManager";
export default {
  components: { ModelLoading },
  props: ["projectInfo", "baseModelTransform"],
  data() {
    return {
      iframeSrc:
        this.$store.state.TaskDetail.headerPath + "modelInfoSetter/index.html",
      showIframe: false,
      type: null,
      modelInfo: {
        fileUrl: null,
      },
      projectModel: null,
      showLoader: false,
      showTask: false,
      iframWidth: 0,
      iframHeight: 0,
      testBase64: null,
      webGLLoaded: false,
      isLoading: true,
      hasModel: false,
      positionType: positionType,
      baseModelGravityList: baseModelGravityList,
      baseModelUnitList: baseModelUnitList,
      baseModelUnit: 1,
      selectKey: 4,
      modelCoverUrl: null,
      modelCoverPath: null,
      QRCodePositionUrl: null,
      QRCodePositionPath: null,
      isModelPosition: false,
      submitLoadig: false,
      screenPrintType: 0, //当前输出的截图 0空闲状态 1正在输出模型封面 2正在输出二维码参照图
      baseModelFile: null,
      baseModelPath: null,
      QRCodeFileUrl: null,
      QRCodeFilePath: null,
      modelCoverNeedSave: false,
      QRCodeCoverNeedSave: false,
      modelNeedSave: false,
    };
  },
  computed: {},
  created() {
    console.log(this.$route);
    console.log(this.projectInfo);
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.setIframeSize);
      console.log(this.$refs.modelLoader);
    });
  },
  watch: {
    projectInfo: {
      async handler(newVal, oldVal) {
        this.projectModel = newVal;
        console.log(this.projectModel);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    submitInfo() {
      let options = {
        type: "SaveTransform",
        value: "",
      };
      this.CallUnity(options);
    },
    setIframeSize() {
      console.log([this.$refs.modelLoader]);
      if (this.$refs.modelLoader) {
        this.$refs.modelLoader.style.height =
          this.$refs.modelLoader.clientWidth * 0.5625 + "px";

        this.$refs.modelLoader.contentWindow.setcanvasSize({
          width: this.$refs.modelLoader.clientWidth,
          height: this.$refs.modelLoader.clientHeight,
        });
      }

      let textBar = document.getElementById("textBar");
      if (textBar) {
        textBar.style.height =
          this.$refs.modelLoader.clientWidth * 0.5625 + "px";
      }

      let modelLoading = document.getElementById("modelLoadingAnimate");
      if (modelLoading) {
        modelLoading.style.width = this.$refs.modelLoader.clientWidth + "px";
        modelLoading.style.height =
          this.$refs.modelLoader.clientWidth * 0.5625 + "px";
      }
      let noModel = document.getElementById("noModel");
      let modelLoadingAnimate = document.getElementById("modelLoadingAnimate");
      if (noModel) {
        noModel.style.width = this.$refs.modelLoader.clientWidth + "px";
        noModel.style.height =
          this.$refs.modelLoader.clientWidth * 0.575 + "px";
      }
      if (modelLoadingAnimate) {
        modelLoadingAnimate.style.width =
          this.$refs.modelLoader.clientWidth + "px";
        modelLoadingAnimate.style.height =
          this.$refs.modelLoader.clientWidth * 0.575 + "px";
      }
    },
    getCover(type) {
      // console.log("webgl输出封面图");
      let options = {
        type: "ScreenPrint",
        value: type === 1 ? "false" : type === 2 ? "true" : "",
      };
      if (type === 1) {
        this.modelCoverNeedSave = true;
      } else if (type === 2) {
        this.QRCodeCoverNeedSave = true;
      }
      this.CallUnity(options);
      this.screenPrintType = type;
    },
    deleteModel() {
      let options = {
        type: "DeleteModel",
        value: "",
      };
      //让unity删除空间中的模型
      this.CallUnity(options);
      //打开遮罩
      this.hasModel = false;
      //清除本地模型的数据
      this.modelInfo.fileName = "";
      this.modelInfo.fileData = "";
      this.baseModelFile = null;
      this.baseModelPath = "";
      //删除基础模型的封面和二维码位置参考图和二维码的文件及path
      this.modelCoverUrl = "";
      this.modelCoverPath = "";
      this.QRCodePositionUrl = "";
      this.QRCodePositionPath = "";
      this.QRCodeFileUrl = "";
      this.QRCodeFilePath = "";
      this.modelCoverNeedSave = true;
      this.QRCodeCoverNeedSave = true;
      this.modelNeedSave = true;
    },
    // uploadModel() {},
    onUploadMoldelChange(f, fileList) {
      let that = this;
      let maxSize = modelMaxSize;
      if (f.raw.size > maxSize) {
        // 文件大小超出限制
        ElMessage.error(that.$t("modelSetter.sizeError"));
        return;
      }
      console.log(f);
      this.modelNeedSave = true;
      this.modelCoverNeedSave = true;
      this.QRCodeCoverNeedSave = true;
      this.modelCoverUrl = "";
      this.modelCoverPath = "";
      this.QRCodePositionUrl = "";
      this.QRCodePositionPath = "";
      this.QRCodeFileUrl = "";
      this.QRCodeFilePath = "";
      let urlData = URL.createObjectURL(f.raw);
      this.baseModelFile = f.raw;
      this.modelInfo = {
        bool: true,
        type: "localUrl",
        fileName: f.raw.name,
        fileData: urlData,
      };
      let options = {
        // type:
        //   this.type === "netUrl"
        //     ? "LoadModelFormURL"
        //     : "LoadModelFormLocalFile",
        type: "LoadModelFormLocalFile",
        value: JSON.stringify(this.modelInfo),
      };
      this.CallUnity(options);
      this.isLoading = true;
    },
    positionTypeChange() {
      console.log("aoa change");
      console.log(this.projectInfo);
    },
    //发送模型缩放，位移，位置等信息
    giveModelTransformInfo() {
      // LoadTransformFromLocal
    },
    init() {
      let token = localStorage.getItem("JTWorkflow-Token");
      let lang = LangManager.getLanguage().value;
      console.log(lang);
      if (this.type === "localUrl") {
        let tokenOptions = {
          type: "GiveToken",
          value: token,
        };
        let isQRcodeOption = {
          type: "IsQRCode",
          value: this.isModelPosition ? "false" : "true",
        };
        let url = {
          type: "GiveUrl",
          value: process.env.VUE_APP_WORKFLOW_API,
        };
        let languageType = {
          type: "GiveLanguageType",
          value: "0",
        };
        let lang = LangManager.getLanguage().value;
        if (lang === "zh") {
          languageType.value = "0";
        } else if (lang === "kr") {
          languageType.value = "2";
        } else if (lang === "en") {
          languageType.value = "1";
        } else {
          languageType.value = "1";
        }

        let transformOption = null;

        this.CallUnity(languageType);
        this.CallUnity(tokenOptions);
        this.CallUnity(url);
        this.CallUnity(isQRcodeOption);
        let transformInfo = this.baseModelTransform;
        console.log(JSON.parse(transformInfo));
        if (transformInfo) {
          transformOption = {
            type: "LoadTransformFromLocal",
            value: JSON.parse(transformInfo).baseModelTransform,
          };
          this.CallUnity(transformOption);
          console.log(transformOption);
        }

        if (this.baseModelFile) {
          let urlData = URL.createObjectURL(this.baseModelFile);
          let modelInfo = {
            bool: true,
            type: "localUrl",
            fileName: this.baseModelFile.name,
            fileData: urlData,
          };
          let options = {
            type: "LoadModelFormLocalFile",
            value: JSON.stringify(modelInfo),
          };
          this.CallUnity(options);
        } else {
          this.isLoading = false;
        }
      } else if (this.type === "netUrl") {
        console.log(this.type);

        console.log(this.modelInfo);

        // ZH_CN, //中文简体      0
        // EN_US, //英语（美国）  1
        // KO_KR, //韩语          2
        // JA_JP; //日语          3

        //   this.webGLLoaded = true;
        let tokenOptions = {
          type: "GiveToken",
          value: token,
        };
        let url = {
          type: "GiveUrl",
          value: process.env.VUE_APP_WORKFLOW_API,
        };
        let fileNameOptions = {
          type: "GiveName",
          value: this.projectInfo.projectGuid,
        };

        let languageType = {
          type: "GiveLanguageType",
          value: "0",
        };
        let isQRcodeOption = {
          type: "IsQRCode",
          value: this.isModelPosition ? "false" : "true",
        };
        let lang = LangManager.getLanguage().value;
        if (lang === "zh") {
          languageType.value = "0";
        } else if (lang === "kr") {
          languageType.value = "2";
        } else if (lang === "en") {
          languageType.value = "1";
        } else {
          languageType.value = "1";
        }
        this.CallUnity(languageType);
        this.CallUnity(tokenOptions);
        this.CallUnity(url);
        this.CallUnity(isQRcodeOption);
        let transformInfo = this.baseModelTransform;
        console.log(transformInfo);
        if (transformInfo) {
          let transformOption = {
            type: "LoadTransformFromLocal",
            value: JSON.parse(transformInfo).baseModelTransform,
          };
          this.CallUnity(transformOption);
          console.log(transformOption);
        }
        this.CallUnity(fileNameOptions);
      }
      // if (this.modelInfo.fileUrl) {
      //   options = {
      //     // type:
      //     //   this.type === "netUrl"
      //     //     ? "LoadModelFormURL"
      //     //     : "LoadModelFormLocalFile",
      //     type: "LoadModelFormLocalFile",
      //     value: JSON.stringify(this.modelInfo),
      //   };
      //   this.hasModel = true;
      //   this.CallUnity(options);
      // } else {
      //   this.isLoading = false;
      //   this.hasModel = false;
      // }

      //
    },
    dataInit() {
      this.type = null;
      this.modelInfo = {};
      this.showLoader = false;
      this.showTask = false;
      this.iframWidth = 0;
      this.iframHeight = 0;
      this.testBase64 = null;
    },
    closeModelView() {
      this.showLoader = false;
    },
    generateThumbnail() {
      let options = {
        type: "ScreenPrint",
        value: "",
      };
      console.log(this.type);
      if (this.type === "netUrl") {
        this.showLoader = false;
        return;
      }
      this.CallUnity(options);
    },
    handleClose() {
      this.showLoader = false;
      this.dataInit();
      window.removeEventListener("message", this.receiveMessage, false);
    },
    opened() {
      this.isLoading = true;
      this.webGLLoaded = false;
      // window["getInstruction"] = (type, bool) => {
      //   console.log(type, bool);
      // };
      console.log(this.$refs.modelViewDialog.dialogRef);
      console.log(this.$refs.modelViewDialog.children);
      this.showLoader = true;
      this.showIframe = true;
      window.addEventListener("message", this.receiveMessage, false);
      // :src="`../../../../modelLoader/index.html`"
      this.$nextTick(() => {
        let dom = document.getElementById("modelSetterView");
        console.log(dom);
        //宽高 580  327
        dom.src = this.iframeSrc;
        let modelLoadingAnimate = document.getElementById(
          "modelLoadingAnimate"
        );
        modelLoadingAnimate.style.height =
          modelLoadingAnimate.clientWidth * 0.5725 + "px";
        if (this.$refs.modelLoader) {
          this.$refs.modelLoader.style.width = modelLoadingAnimate.clientWidth;
          this.$refs.modelLoader.style.height =
            modelLoadingAnimate.clientWidth * 0.5725 + "px";

          this.$refs.modelLoader.contentWindow.setcanvasSize({
            width: this.$refs.modelLoader.clientWidth,
            height: this.$refs.modelLoader.clientHeight,
          });
        }
      });

      // dom.src = "../../../../modelLoader/index.html";
      // this.$nextTick(() => {
      //   let dialogWarp = document.getElementsByClassName("modelViewDialog")[0];
      //   console.log(dialogWarp);
      //   let dialogBody = dialogWarp.getElementsByClassName("el-dialog__body");
      //   let iframeBody = document.getElementById("modelView");
      //   iframeBody.style.width = dialogBody[0].offsetWidth + "px";
      //   iframeBody.style.height =
      //     (dialogBody[0].offsetWidth * 9) / 16 + 50 + "px";
      //   window.addEventListener("resize", () => {
      //     iframeBody.style.width = dialogBody[0].offsetWidth + "px";
      //     iframeBody.style.height = (dialogBody[0].offsetWidth * 9) / 16 + "px";
      //   });
      // });
    },
    setShow(bool, type, isGiveModel, isModelPosition = false, source) {
      this.showLoader = bool;
      this.hasModel = isGiveModel;
      // this.modelInfo = modelInfo;
      this.type = type;
      this.isModelPosition = isModelPosition;
      if (source) {
        console.log(source);
        this.baseModelFile = source.baseModelFile;
        this.baseModelPath = source.baseModelPath;
        this.modelCoverUrl = source.modelCoverUrl;
        this.modelCoverPath = source.modelCoverPath;
        this.QRCodePositionUrl = source.QRCodePositionUrl;
        this.QRCodePositionPath = source.QRCodePositionPath;
        this.QRCodeFileUrl = source.QRCodeFileUrl;
        this.QRCodeFilePath = source.QRCodeFilePath;
      }
    },
    CallUnity(data) {
      if (this.$refs.modelLoader && this.$refs.modelLoader.contentWindow) {
        this.$refs.modelLoader.contentWindow.send(data);
      }
    },
    receiveMessage(event) {
      console.log(event.data);
      console.log(event);
      console.log(this.type);
      // if (!this.showLoader) {
      //   return;
      // }
      // if (!event.data.type) {
      //   this.testBase64 = event.data.img;
      //   console.log("unity return image", event.data);
      //   let base64Data = event.data.img.replace(/^data:image\/\w+;base64,/, "");
      //   let dataBuffer = Buffer.from(base64Data, "base64");
      //   console.log("buffer base64", dataBuffer);
      //   this.$emit("getThumbnail", event.data.img);
      //   this.showLoader = false;
      // }

      //unity告诉web已经加载完成
      if (event.data.type === "LoadDone") {
        this.webGLLoaded = true;
        this.init();
        this.setIframeSize();
        console.log(this.modelInfo);
        this.isLoading = false;
      }
      // if (event.data.type === "GetScreenPrint") {
      // }
      if (event.data.type === "GiveFilePath") {
        console.log(event.data);
        console.log(event.data);
        if (this.screenPrintType === 1) {
          console.log("模型封面");
          this.modelCoverUrl = JSON.parse(event.data.value)[0].sas;
          this.modelCoverPath = JSON.parse(event.data.value)[0].path;
          this.screenPrintType = 0;
        } else if (this.screenPrintType === 2) {
          console.log("二维码参照图");
          this.QRCodePositionUrl = JSON.parse(event.data.value)[0].sas;
          this.QRCodePositionPath = JSON.parse(event.data.value)[0].path;
          this.QRCodeFileUrl = JSON.parse(event.data.value)[1].sas;
          this.QRCodeFilePath = JSON.parse(event.data.value)[1].path;
          this.screenPrintType = 0;
        } else {
          console.log("啥也不是");
          this.screenPrintType = 0;
        }
      }
      if (event.data.type === "ModelLoadDone") {
        console.log(event.data);
        this.isLoading = false;

        // this.baseModelPath = null;
      }
      if (event.data.type === "SaveTransform") {
        console.log(event.data);
        console.log(JSON.parse(event.data.value));
        this.submitLoadig = true;

        let returnOptions = {
          baseModelFile: this.baseModelFile,
          baseModelPath: this.baseModelPath,
          baseModelUnit: this.baseModelUnit,
          baseModelGravityKey: this.selectKey,
          enableObjectAnchorModel: this.isModelPosition,
          modelCoverUrl: this.modelCoverUrl,
          modelCoverPath: this.modelCoverPath,
          QRCodePositionUrl: this.QRCodePositionUrl,
          QRCodePositionPath: this.QRCodePositionPath,
          QRCodeFileUrl: this.QRCodeFileUrl,
          QRCodeFilePath: this.QRCodeFilePath,
          SaveTransformInfo: JSON.parse(event.data.value),
          SaveTransformInfoStr: event.data.value,
          modelCoverNeedSave: this.modelCoverNeedSave,
          QRCodeCoverNeedSave: this.QRCodeCoverNeedSave,
          modelNeedSave: this.modelNeedSave,
        };
        this.$emit("getPositionInfo", returnOptions);
        this.submitLoadig = false;
        this.showLoader = false;
        this.modelCoverNeedSave = false;
        this.QRCodeCoverNeedSave = false;
        this.modelNeedSave = false;
      }
      // if (event.data.type === "LoadDone") {
      //   console.log("发送");
      //   let options = {
      //     type: "LoadModelFormURL",
      //     value: this.modelInfo.fileUrl,
      //   };
      //   this.CallUnity(options);
      // }
    },
    async uploadStageFile(file) {
      if (!file) {
        return "";
      }
      try {
        const { sas, path } = await getUploadSas(
          file.name || `${uuid.v4()}.png`
        );

        const blobClient = new BlockBlobClient(sas, new AnonymousCredential());
        await blobClient.uploadData(file, {
          blockSize: 512 * 1024,
          concurrency: 2,
        });

        // await saveMyProfilePhoto(path)
        return path;
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.webGLWarp {
  position: relative;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  .taskLinkWapr {
    position: absolute;
    width: 30%;
    height: 80%;
    background: #fff;
    top: 8%;
    left: 1%;
    overflow: hidden;
    z-index: 10;
    opacity: 0;
  }
  .taskActive {
    z-index: 20;
    opacity: 1;
  }
  .betweenWapr {
    position: absolute;
    width: 100%;
    height: 50vh;
    overflow: hidden;
    z-index: 15;
    iframe {
      width: 100%;
      // height: calc(100vh - 8rem);
      height: 100.2%;
    }
  }
}

// iframe::-webkit-scrollbar {
//   width: 5px;
//   height: 5px;
// }
// iframe::-webkit-scrollbar-thumb {
//   background-color: #999;
//   -webkit-border-radius: 5px;
//   border-radius: 5px;
// }
// iframe::-webkit-scrollbar-thumb:vertical:hover {
//   background-color: #666;
// }
// iframe::-webkit-scrollbar-thumb:vertical:active {
//   background-color: #333;
// }
// iframe::-webkit-scrollbar-button {
//   display: none;
// }
// iframe::-webkit-scrollbar-track {
//   background-color: #f1f1f1;
// }
#modelSetterView {
  width: 100%;
}
#modelSetter {
  :deep(.el-dialog__header) {
    padding: 20px 30px 10px;
    text-align: left;

    .el-dialog__title {
      color: #4582ff;
    }
  }
  :deep(.el-dialog__body) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
  }
  :deep(.dialog-footer) {
    .selfBtn {
      outline: none;
      border: none;
      width: 120px;
      height: 32px;
      background: #4582ff;
      border-radius: 4px 4px 4px 4px;
      color: #fff;
      margin-left: 20px;
      cursor: pointer;
      transition: background 0.1s;
    }
    .selfBtn:hover {
      background: #8fb5ff;
    }
    .selfBtn[disabled] {
      background: #8fb5ff;
    }
    .cancel {
      background: #a8b7ca;
    }
  }
  .modelViewDialog {
    position: relative;
  }
  :deep(.modelViewDialog) {
    max-width: 1600px;
  }
}
.importWarp {
  width: 100%;
  height: 100%;
  background: #fff;
}
.zIndex16 {
  z-index: 16;
}
.modelLoading {
  position: absolute;
  width: 75%;
  height: 80%;
  border-radius: 15px;
  background: #fff;
  z-index: 2;
}
.noModel {
  position: absolute;
  width: 75%;
  height: 81%;
  border-radius: 15px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  > div {
    > p {
      margin: 0;
      position: relative;
      top: -15px;
      color: rgba($color: #000000, $alpha: 0.25);
      font-size: 14px;
    }
    > img {
      width: 160px;
      height: 90px;
    }
  }
}
.modelLiodingHidden {
  z-index: -1;
}
.ctrlTipWarp {
  width: 20.5%;
  height: 100%;
  .tipItem {
    width: 100%;
    // height: 30%;
    margin-bottom: 1%;
    // background: #fafafa;
    border-radius: 4px;
    // font-size: 85%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    .change-btn {
      :deep(.el-upload) {
        width: 100%;
        > button {
          background: #4582ff;
        }
        > button:hover {
          background: #8fb5ff;
        }
        > button[disabled] {
          cursor: no-drop;
          background: #b6b6b6;
        }
      }
    }
    button {
      cursor: pointer;
      width: 100%;
      height: 26px;
      color: #fff;
      font-size: 12px;
      line-height: 16px;
      transition: background 0.3s;
      outline: none;
      border: none;
      border-radius: 4px;
      margin-bottom: 10px;
    }
    // .upload {
    //   background: #4582ff;
    // }
    // .upload:hover {
    //   background: #8fb5ff;
    // }
    > .delete {
      background: #ea6767;
    }
    > .delete:hover {
      background: #ff8e8e;
    }
    > .delete[disabled] {
      cursor: no-drop;
      background: #b6b6b6;
    }
    :deep(.formItemWarpSelf) {
      text-align: left;
      .el-form-item__label {
        line-height: 16px;
        color: #224b77;
        margin: 0;
        padding: 0;
      }
      .el-form-item__content {
        .el-select {
          width: 100%;
        }
      }
    }
    .modelWarp,
    .QRCodeWarp {
      padding-top: 61%;
      position: relative;
      > .el-image {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 45px;
        img {
          width: 75%;
        }
        p {
          margin: 0;
          font-size: 12px;
        }
      }
      > .getCoverBtn {
        background: #4582ff;
      }
      > .getCoverBtn:hover {
        background: #8fb5ff;
      }
      > .getCoverBtn[disabled] {
        cursor: no-drop;
        background: #b6b6b6;
      }
    }
  }
}
</style>
