// import { createStore } from "vuex";
import { login, getFeatures } from '@/api/user';
import { getUserInfo, getMyUserInfo } from '@/api/workflow.js';
import { setToken, removeToken, getToken, removeProjectInfo, setTokenKey } from '@/utils/setLocalstorage'
import { removeSelectedGuid } from '@/views/taskDetail/components/taskChart/setSelectShape'
import { removeTaskGuid } from '@/utils/setTaskGuid'
// import lang_kr from "element-plus/lib/locale/lang/ko";
// import lang_zhCn from "element-plus/lib/locale/lang/zh-cn";
import vue from '../../main';
// import ElementPlus from "element-plus";
// import Store from "@/store/index.js"
import { longPolling, cancelLongPolling } from "@/utils/longPolling.js";
const User = {
    state: () => ({
        userToken: '',
        user: null,
        roles: [],
        accountId: null,
        displayName: '',
        profilePhotoUrl: '',
        introduction: '',
        avatarHeadGuid: '',
        userGuid: '',
        tenantName: '',
        showLogin: false,
        email: '',
        // loginLoading: false,
        accountSign: 'login',
        lang: "zh",
        changeLang: false,
    }),
    mutations: {
        SET_LANGUAGECHANGE(state, data) {
            state.changeLang = data;
            // let langStr = localStorage.getItem("language_type");
            // if (langStr === "zh") {
            //     vue.use(ElementPlus, { locale: lang_zhCn }, { zIndex: 999 });
            // } else if (langStr === "kr") {
            //     vue.use(ElementPlus, { locale: lang_kr }, { zIndex: 999 });
            // }

        },
        SET_LANGUAGE(state, data) {
            state.lang = data;
        },
        SET_USERLOGOUT(state) {
            // console.log(state);
            state.userToken = null;
            state.roles = [];
            state.displayName = null;
            state.profilePhotoUrl = null;
            state.introduction = null;
            state.avatarHeadGuid = null;
            state.userGuid = null;
            state.tenantName = null;
            state.email = null;
            state.user = null;
            state.accountId = null;
        },
        // SET_LOGINLOADING(state, data) {
        //     state.loginLoading = data;
        // },
        SET_SHOWlOGIN(state, data) {
            state.showLogin = data;
        },
        SET_ACCOUNTSIGN(state, data) {
            state.accountSign = data;
        },
        SET_USER(state, data) {
            console.log(state, data);
            state.user = data;
        },
        SET_TOKEN(state, data) {
            state.userToken = data
        },
        SET_USERINFO(state, data) {
            //   commit('SET_ROLES', roles)
            //   commit('SET_NAME', displayName)
            //   commit('SET_AVATAR', profilePhoto)
            // commit('SET_INTRODUCTION', introduction)
            // commit('SET_AVATARHEADGUID', avatarHeadGuid)
            //   commit('SET_USERGUID', userGuid)
            //   commit('SET_TENANTNAME', tenantName)
            state.accountId = data.accountId;
            state.roles = data.roles;
            state.displayName = data.displayName;
            state.profilePhotoUrl = data.profilePhotoUrl;
            state.introduction = data.introduction;
            state.avatarHeadGuid = data.avatarHeadGuid;
            state.userGuid = data.userGuid;
            state.tenantName = data.tenantName;
            state.email = data.email;
            state.user = data
        },
        SET_USERROLES(state, data) {
            state.roles = data;
        },
    },
    actions: {
        logout({ commit }) {
            removeToken();
            removeProjectInfo();
            // removeUserInfo();
            removeSelectedGuid();
            removeTaskGuid();
            commit('SET_USERLOGOUT');
            localStorage.removeItem("JTWorkflow_refreshTokenKey");
            //取消长轮询请求
            cancelLongPolling();
        },
        // getUserInfo({ commit, state }, data) {
        //     commit('SET_USERINFO', data)
        // },
        async login({ commit }, user) {
            const { username, password, authcode } = user
            // return new Promise((resolve, reject) => {
            try {
                // commit('SET_LOGINLOADING', true);
                const data = await login({ username: username.trim(), password: password, authcode: authcode });

                // .then(response => {
                // const data = response
                if (data.user.weakPassword) {
                    // setToken(data.accessToken)
                    throw {
                        code: 40199,
                        message: {
                            code: 40199,
                            // message: "密码强度不够",
                            message: vue.$t('store.tip1'),
                        },
                        data: {
                            accessToken: data.accessToken,
                            password
                        }

                    }
                }
                commit('SET_USER', data)
                commit('SET_TOKEN', data.accessToken)
                setToken(data.accessToken)
                setTokenKey(data.refreshToken);
                try {
                    const userInfo = await getUserInfo(data.accessToken);
                    commit('SET_USERINFO', userInfo);
                    // setUserInfo(JSON.stringify(userInfo));
                    // console.log(Store.state.User);
                    const userRoles = await getMyUserInfo(data.accessToken);
                    console.log('userRoles', userRoles);
                    commit("SET_USERROLES", userRoles.roles);
                    commit("SET_SHOWlOGIN", false);
                    console.log(data);
                    if (data) {
                        longPolling();
                    }
                } catch (e) {

                    console.log(e);
                }
                // console.log('userInfo11', userInfo);

                // commit('SET_LOGINLOADING', false)
                // Store.dispatch('User/getInfo',{},{ root: true })
                // dispatch("other/clear",{},{ root: true })
                // console.log(userInfo);
                // console.log(Store.state);
                // resolve()
            }
            catch (e) {
                return Promise.reject(e);
            }
            // }).catch(error => {
            //   console.log('error', error)
            //   reject(error)
            // })
            // })
        },
        async getInfo({ commit, state }) {
            try {
                const data = await getUserInfo(getToken())
                commit('SET_USERINFO', data);
                const userRoles = await getMyUserInfo(data.accessToken);
                // console.log('userRoles', userRoles);
                commit("SET_USERROLES", userRoles.roles);
                // console.log(data);
                if (data) {
                    longPolling();
                }
                // console.log('getInfo111', data)
                // await getRAUserInfo(state.token);
                // await getMeetingUserInfo(state.token);
                if (!data) {
                    throw 'Verification failed, please Login again.'
                }

                //   const { userGuid, roles, displayName, headImage, profilePhoto, tenantName } = data

                // if (!state.socketsConnected) {
                //   // await Conductor.connect(state.token);

                //   // Conductor.OnWebsocketDisconnect(() => {
                //   //   // state.mediaConnected = false;
                //   //   commit('SET_MEDIACONNECTED', false)
                //   //   console.log('disconnected');
                //   // })
                //   await SocketClient.connect(state.token)
                //   // console.log('connected all')
                //   commit('SET_MEDIACONNECTED', true)
                // }


                //   data.roles = roles
                // console.log('roles', roles,  data, roles.includes(BackRoleId))
                //   if (!roles.includes(BackRoleId)) { throw { code: 40101, message: 'No permission' } }

                // commit('SET_USERINFO', data)
                // setUserInfo(JSON.stringify(data));
                //   commit('SET_ROLES', roles)
                //   commit('SET_NAME', displayName)
                //   commit('SET_AVATAR', profilePhoto)
                // commit('SET_INTRODUCTION', introduction)
                // commit('SET_AVATARHEADGUID', avatarHeadGuid)
                //   commit('SET_USERGUID', userGuid)
                //   commit('SET_TENANTNAME', tenantName)

                return data
            } catch (e) {
                console.log('login error', e)
                return Promise.reject(e)
            }
        },
    },
    getters: {}
}
export default User;