<template>
  <div style="height: 100vh" v-if="projectInfo">
    <div class="taskDetailHeader">
      <div class="picture">
        <!-- <i class="el-icon-arrow-left" @click="backPrePage"></i> -->
        <!-- 后退 -->
        <el-tooltip
          class="item"
          effect="light"
          :content="$t('taskNewDatail.back')"
          placement="bottom"
          :show-after="500"
        >
          <button>
            <img
              src="@/assets/task-detail/back.png"
              @click="backPrePage"
              alt=""
            />
          </button>
        </el-tooltip>
        <!-- 保存 -->
        <el-tooltip
          v-if="isCreator"
          class="item"
          effect="light"
          :content="$t('taskNewDatail.save')"
          placement="bottom"
          :show-after="500"
        >
          <button
            class="saveBtn"
            @click="saveTaskChar"
            :disabled="!taskChartNeedSave && !templateNeedSave"
          >
            <img src="@/assets/task-detail/save.png" alt="" />
            <div class="saveWord">{{ $t("taskNewDatail.save") }}</div>
          </button>
        </el-tooltip>
      </div>
      <div class="name">
        <el-dropdown @command="handleCommand" trigger="click">
          <span class="el-dropdown-link nameStr">
            {{ projectInfo.projectName }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <!-- 历史版本 -->
              <el-dropdown-item
                v-if="isCreator"
                command="history"
                id="history"
                >{{ $t("taskNewDatail.history") }}</el-dropdown-item
              >
              <!-- 项目设置 -->
              <el-dropdown-item command="projectSetting" id="taskSet">{{
                $t("taskNewDatail.projectSetter")
              }}</el-dropdown-item>
              <!-- 删除 -->
              <el-dropdown-item
                v-if="isCreator"
                command="delete"
                id="deleteMission"
                >{{ $t("taskNewDatail.delete") }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="other">
        <!-- 获取    已拥有-->
        <el-tooltip
          class="item"
          effect="light"
          :content="
            !myProjectStatus.own && !isCreator
              ? $t('taskNewDatail.get')
              : $t('taskNewDatail.got')
          "
          placement="bottom"
          :show-after="500"
          v-if="(!isCreator || $route.query.releaseGuid) && !auditReleaseMode"
        >
          <button
            v-if="!myProjectStatus.own && !isCreator"
            class="smallBtn ownBtn"
            @click.prevent="ownProject"
            type="primary"
          >
            <img src="@/assets/task-detail/getOwn.png" alt="" />
            {{ $t("taskNewDatail.get") }}
          </button>
          <button v-else class="smallBtn ownActive" @click.prevent="">
            {{ $t("taskNewDatail.got") }}
          </button>
        </el-tooltip>
        <!-- <button
          v-if="!myProjectStatus.own && !isCreator"
          class="config"
          @click.prevent="ownProject"
          type="primary"
        >
          <img src="@/assets/task-detail/getOwn.png" alt="" /> 获取
        </button> -->
        <!-- 配置 -->
        <!-- {{ isCreator }}
        {{ auditReleaseMode }}
        {{ !$route.query.releaseGuid }}
        {{ !projectInfo?.editType }} -->
        <el-tooltip
          class="item"
          effect="light"
          :content="$t('taskNewDatail.config')"
          placement="bottom"
          :show-after="500"
          v-if="
            isCreator &&
              !auditReleaseMode &&
              !$route.query.releaseGuid &&
              !projectInfo?.editType
          "
        >
          <button class="config" @click="topCtrl('1')">
            <img src="@/assets/task-detail/setting.png" alt="" />

            <span>{{ $t("taskNewDatail.config") }}</span>
          </button>
        </el-tooltip>
        <!-- 预览 -->
        <el-tooltip
          class="item"
          effect="light"
          :content="$t('taskNewDatail.preview')"
          placement="bottom"
          :show-after="500"
        >
          <button class="smallBtn" @click="topCtrl('3')">
            <img src="@/assets/task-detail/player.png" alt="" />
          </button>
        </el-tooltip>
        <!-- 发布 -->
        <el-tooltip
          class="item"
          effect="light"
          :content="$t('taskNewDatail.release')"
          placement="bottom"
          :show-after="500"
          v-if="isCreator && !$route.query.releaseGuid"
        >
          <button class="config" @click="topCtrl('2')">
            <img src="@/assets/task-detail/release.png" alt="" />
            <span>{{ $t("taskNewDatail.release") }}</span>
          </button>
        </el-tooltip>

        <button
          v-if="!isCreator || $route.query.releaseGuid"
          @click="setLike(!myProjectStatus.like)"
        >
          <!-- <img src="@/assets/task-detail/thumbnail.svg" alt="" /> -->
          <div
            class="thumbnail"
            :class="myProjectStatus.like ? 'selected' : ''"
          ></div>
        </button>

        <button
          v-if="!isCreator || $route.query.releaseGuid"
          @click="setFavourate(!myProjectStatus.favourate)"
        >
          <!-- <img src="@/assets/task-detail/star.svg" alt="" />-->
          <div
            class="star"
            :class="myProjectStatus.favourate ? 'selected' : ''"
          ></div>
        </button>
        <!-- 分享 -->
        <el-tooltip
          v-if="!isCreator || $route.query.releaseGuid"
          class="item"
          effect="dark"
          :content="$t('taskNewDatail.share')"
          placement="bottom"
          :show-after="500"
        >
          <button @click="forward()">
            <img src="@/assets/task-detail/share.png" alt="" />
          </button>
        </el-tooltip>

        <!-- 帮助 -->
        <!-- <button v-if="isCreator" @click="topCtrl('4')">
          <img src="@/assets/task-detail/helpBig.png" alt="" />
        </button> -->
      </div>
    </div>
    <div class="mainBody" ref="taskDetail">
      <div class="taskList">
        <div class="taskListHeader">
          <!-- 任务 -->
          <div class="task">{{ $t("taskNewDatail.task") }}</div>
          <div class="searchTask">
            <!-- 搜索 -->
            <el-input
              size="mini"
              v-model="searchStr"
              :placeholder="$t('taskNewDatail.search')"
              class="input-with-select"
              @input="searchTask"
            >
              <!-- <template #append>
                <el-button class="searchBtn" icon="el-icon-search" />
              </template> -->
              <template #suffix>
                <i class="el-icon-search"> </i>
              </template>
            </el-input>
          </div>
          <div class="createTask" v-if="isCreator">
            <!-- 新建任务 -->
            <el-tooltip
              class="item"
              effect="light"
              :content="$t('taskNewDatail.newTask')"
              placement="bottom"
              :show-after="500"
            >
              <el-button size="mini" @click="addTask">
                <img src="@/assets/task-detail/add.png" alt="" />
              </el-button>
            </el-tooltip>
          </div>
        </div>
        <div class="taskListBody" v-if="projectInfo">
          <div
            :class="
              selectedTask && selectedTask.taskGuid === item.taskGuid
                ? 'taskItem active'
                : selectTaskStepInfo &&
                  selectTaskStepInfo.properties.stepInfo.refTaskGuid ===
                    item.taskGuid
                ? 'taskItem taskActive'
                : 'taskItem'
            "
            v-for="(item, index) in showList"
            :key="index"
          >
            <div class="taskLeft" @click="selectItemWarp(item, $event)">
              <!-- <div></div> -->
              <div>
                <!-- 请输入内容 -->
                <el-input
                  class="taskEditInput"
                  :id="item.taskGuid"
                  v-if="isCreator && item.dbClick && item.dbClick === 2"
                  v-model="item.taskName"
                  size="small"
                  :placeholder="$t('taskNewDatail.pleaseEnterContent')"
                  @blur="onblur(item, index)"
                ></el-input>
                <el-tooltip
                  :show-after="500"
                  placement="bottom"
                  :content="item.taskName"
                >
                  <span>{{ item.taskName }}</span>
                </el-tooltip>
              </div>
            </div>
            <div class="taskRight">
              <el-dropdown v-if="isCreator" @command="itemCtrl" trigger="click">
                <span class="el-dropdown-link nameStr1">
                  <i class="el-icon-more"></i>
                </span>
                <template #dropdown>
                  <div class="taskCtrlListWarp">
                    <el-dropdown-menu>
                      <!-- <el-dropdown-item command="clone" class="btnWarp"
                        ><button>
                          <img
                            src="@/assets/task-detail/clone.png"
                            alt=""
                          />克隆 
                          <span>&nbsp;&nbsp;Ctrl + D</span>
                        </button></el-dropdown-item
                      > -->
                      <el-dropdown-item
                        v-if="!searchStr"
                        command="toTop"
                        class="btnWarp"
                        ><button
                          @click="moveTop(item, index)"
                          :disabled="index === 0"
                        >
                          <!-- 上移 -->
                          <img src="@/assets/task-detail/toTop.png" alt="" />{{
                            $t("taskNewDatail.moveUp")
                          }}
                        </button></el-dropdown-item
                      >
                      <el-dropdown-item
                        v-if="!searchStr"
                        command="toBottom"
                        class="btnWarp"
                        ><button
                          @click="moveDown(item, index)"
                          :disabled="index === showList.length - 1"
                        >
                          <!-- 下移 -->
                          <img
                            src="@/assets/task-detail/toBottom.png"
                            alt=""
                          />{{ $t("taskNewDatail.moveDown") }}
                        </button></el-dropdown-item
                      >
                      <el-dropdown-item command="toBottom" class="btnWarp"
                        ><button @click="resetTaskName(item, index)">
                          <!-- 重命名 -->
                          <img
                            src="@/assets/task-detail/resetTaskName.png"
                            alt=""
                          />{{ $t("taskNewDatail.resetTaskName") }}
                        </button></el-dropdown-item
                      >
                      <el-dropdown-item command="delete" class="btnWarp"
                        ><button
                          class="delete"
                          @click="deleteTask(item, index)"
                        >
                          <!-- 删除 -->
                          <img src="@/assets/task-detail/delete.png" alt="" />{{
                            $t("taskNewDatail.delete")
                          }}
                        </button></el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </div>
                </template>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
      <!-- 组件位置 -->
      <!-- <TaskChart
        ref="taskChart"
        v-if="selectedTask"
        :isCreator="isCreator"
        :shortcutKey="true"
        :projectInfo="projectInfo"
        :taskGuid="selectedTask.taskGuid"
        :own="myProjectStatus.own"
        @onCreateTask="onCreateTask"
        @addTask="addTask"
        @needSave="needSave"
        @setOwn="setOwn"
        @projectNameChange="projectNameChange"
      /> -->
      <!-- v-if="selectedTask" -->
      <div
        class="workspace"
        :style="{
          width: readOnly
            ? 'calc(100% - 15rem)'
            : isSelectShape || isShowHistory || isShowProjectInfo
            ? 'calc(100% - 28rem)'
            : 'calc(100%)',
        }"
      >
        <LogicflowChart
          v-if="selectedTask"
          id="logicflowDom"
          ref="logicflow"
          :isCreator="isCreator"
          :class="workingType ? 'logicflow' : 'logicflow logicflowActive'"
          :projectInfo="projectInfo"
          :taskGuid="selectedTask.taskGuid"
          :templateNeedSave="templateNeedSave"
          :auditReleaseMode="auditReleaseMode"
          :auditReleaseGuid="auditReleaseGuid"
          @refreshTaskInfo="refreshTaskInfo"
          @needSave="needSave"
          @onCreateTask="onCreateTask"
          @editStepSetPanel="editStepSetPanel"
          @refreashHistoryList="refreashHistoryList"
          @textChange="textChange"
          @closeEditer="closeStepEdit"
          @selectedNode="selectedNode"
          @askIsSave="askIsSave"
          @selectTaskStep="selectTaskStep"
          @saveTemplate="saveTemplate"
        />
        <Template2D
          v-if="selectedTask"
          id="template2DDom"
          ref="template2D"
          @needSave="templateNeedSaveFun"
          @template2DLoaddone="template2DLoaddone"
          @outPrintScreen="outPrintScreen"
          @initDone="outPrintScreen"
          @clearStepObject="clearStepObject"
          :projectInfo="projectInfo"
          :projectGuid="projectInfo.projectGuid"
          :isCreator="isCreator"
          :shapeItem="shapeItem"
          :taskGuid="selectedTask.taskGuid"
          :class="workingType ? 'template2D template2DActive' : 'template2D'"
          :templateInfo="templateInfo"
          :showType="showType"
        />
        <div
          class="toggleBtn"
          @click="toggleWorkingType"
          v-if="
            shapeItem &&
              shapeTypeList.includes(shapeItem.data.type) &&
              projectInfo?.editType === 1 &&
              isCreator &&
              shapeItem.data.type !== 'pointer'
          "
        >
          <el-tooltip
            class="item"
            effect="light"
            :content="$t('taskNewDatail.toggleView')"
            placement="top"
          >
            <img src="@/assets/task-detail/sortIcon.png" alt="" />
          </el-tooltip>

          <!-- <i class="el-icon-sort sortIcon"></i> -->
        </div>
      </div>

      <EditStep
        v-if="selectedTask && !readOnly"
        ref="editStep"
        class="others"
        :isCreator="isCreator"
        :auditReleaseMode="auditReleaseMode"
        :projectInfo="projectInfo"
        :readOnly="readOnly"
        :templateInfo="templateInfo"
        :workingType="workingType"
        :step2DInfo="step2DInfo"
        @setStepName="setStepName"
        @setDescription="setDescription"
        @setLinkName="setLinkName"
        @onNeedSave="dataNeedSave"
        @backPoisition="changeSwitch"
        @saveHistory="noAutoSaveHistory"
        @setOwn="setOwn"
        @projectNameChange="projectNameChange"
        @selectedTemplate="selectedTemplate"
        @setWorkingType="setWorkingType"
        @thumbnailLoaddone="thumbnailLoaddone"
        @gotoPointer="gotoPointer"
        @languageChange="languageChange"
      ></EditStep>
    </div>
    <!-- <Delete ref="deleteProject" /> -->
    <ReleaseProject
      ref="ReleaseProject"
      v-if="projectInfo"
      :show="releaseShow"
      :projectGuid="projectInfo.projectGuid"
      :projectInfo="projectInfo"
      @onClose="releaseShow = false"
    />
    <CreateTask
      ref="createTask"
      v-if="projectInfo"
      @refreshTaskList="refreshList"
      :newTaskIndex="projectInfo.tasks.length"
    />
    <Share ref="share" :projectInfo="projectInfo" />
    <div class="warps" v-if="needSaveShow">
      <div class="self_content">
        <!-- 提示 -->
        <div class="self_header">{{ $t("taskNewDatail.tip1") }}</div>
        <!-- 检测到流程图有未保存的内容,是否保存? -->
        <div class="self_body">{{ $t("taskNewDatail.tip2") }}</div>
        <div class="self_footer">
          <!-- 取消 -->
          <button class="cancel" @click="cancleSave">
            {{ $t("taskNewDatail.cancel") }}
          </button>
          <!-- 不保存 -->
          <button class="noSave" @click="noSave">
            {{ $t("taskNewDatail.noSave") }}
          </button>
          <!-- 保存 -->
          <button class="save" @click="save">
            {{ $t("taskNewDatail.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { template2DList } from "@/utils/asset.js";
import html2canvas from "html2canvas";
import { ElMessage } from "element-plus";
// import TaskChart from "./components/taskChart/taskChart.vue";
import LogicflowChart from "./components/logicflowChart/logicflowChart.vue";
import Template2D from "./components/template2D/template2D.vue";
// import Delete from "./components/deleteProject/deleteProject.vue";
import ReleaseProject from "./components/releaseProject/releaseProject.vue";
import CreateTask from "./components/createTask/createTask.vue";
import Share from "./components/share/share.vue";
import { saveOwnProject } from "@/api/mine";
import {
  getProjectTasks,
  getReleaseById,
  getProjectById,
  saveTask,
  getTaskById,
  saveProject,
  getStepInfo,
} from "@/api/workflow.js";
import { getTaskGuid, setTaskGuid, removeTaskGuid } from "@/utils/setTaskGuid";
import { setProjectInfo, getProjectInfo } from "@/utils/setLocalstorage";
import {
  getSelectedGuid,
  setSelectedGuid,
  removeSelectedGuid,
} from "./components/taskChart/setSelectShape.js";
import {
  saveFavourate,
  saveLikeProject,
  addForwardCount,
  getProjectStatusById,
  addPreviewCount,
} from "../../api/mine";
import EditStep from "./components/editStep/editStep.vue";
import { get } from "@logicflow/core";
export default {
  components: {
    ReleaseProject,
    CreateTask,
    Share,
    LogicflowChart,
    EditStep,
    Template2D,
  },
  props: {},
  data() {
    return {
      shapeTypeList: [
        "action",
        "pointer",
        "judge",
        "StartShape",
        "CloneActionShape",
        "CloneJudgeShape",
        "InputShape",
        "CloneInputShape",
      ],
      workingTypeThumbnail: null,
      workingType: 0, //工作状态0表示在编辑流程图,1表示在编辑模板
      readOnly: false,
      readOnlyRouterList: ["/webGL"],
      showList: [],
      tempItemName: "",
      selectedTask: null,
      isCreator: false,
      taskList: [],
      // selectMissios: null,
      release: false,
      // historyEdition: true,
      searchStr: "",
      canAddStartStep: true,
      projectInfo: null,
      projectGuid: "",
      releaseGuid: "",
      auditReleaseGuid: null,
      auditReleaseMode: false,
      releaseMode: true,
      myProjectStatus: {
        like: false,
        own: false,
        favourate: false,
      },
      releaseShow: false,
      taskChartNeedSave: false,
      templateNeedSave: false,
      needSaveShow: false,
      toPath: null,
      templateList: [...template2DList],
      templateInfo: template2DList[0],
      shapeItem: false,
      step2DInfo: null,
      selectTaskStepInfo: null,
    };
  },
  computed: {
    showType() {
      return (
        this.shapeItem &&
        this.shapeItem.data.type !== "InputShape" &&
        this.shapeItem &&
        this.shapeItem.data.type !== "CloneInputShape"
      );
    },
    isSelectShape() {
      return this.$store.state.TaskDetail.selectShape;
    },
    isShowHistory() {
      return this.$store.state.TaskDetail.showHistory;
    },
    isShowProjectInfo() {
      return this.$store.state.TaskDetail.showProjectInfo;
    },
    isCloseEditer() {
      return (
        this.$store.state.TaskDetail.selectShape ||
        this.$store.state.TaskDetail.showHistory ||
        this.$store.state.TaskDetail.showProjectInfo
      );
    },
    isCreator: {
      get() {
        return (
          this.projectInfo &&
          !this.projectInfo.releaseGuid &&
          !this.projectInfo.auditReleaseGuid &&
          this.$store.state.User &&
          this.projectInfo.createdBy == this.$store.state.User.userGuid
        );
      },
    },
  },
  watch: {
    async shapeItem(newVal, oldVal) {
      console.log(this.shapeItem);
      if (this.shapeItem) {
        try {
          let sourceStepGuid = this.shapeItem.data.properties.stepInfo
            .sourceStepGuid;
          console.log(sourceStepGuid);

          if (this.shapeItem.data.properties.stepInfo.stepObject) {
            console.log('this.shapeItem changed', this.shapeItem.data.properties.stepInfo.stepObject)
            this.step2DInfo = this.shapeItem.data.properties.stepInfo.stepObject;
          } else {
            try {
              let res = await getStepInfo(
                sourceStepGuid ? sourceStepGuid : this.shapeItem.data.id
              );
              console.log('this.shapeItem changed getStepInfo', res)
              this.step2DInfo = res;
            } catch (e) {
              console.log(e);
              this.step2DInfo = false;
            }
          }
          this.templateInfo = this.templateList.find((item) => {
            if (item.type === this.step2DInfo.panelType) {
              return item;
            }
          });
          if (!this.templateInfo) {
            this.templateInfo = template2DList[0];
          }
          console.log(this.templateInfo);
        } catch (e) {
          this.step2DInfo = false;
          this.templateInfo = template2DList[0];
          // this.$refs.template2D.printScreen();
          await this.$refs.template2D.initData(false);
          // this.$nextTick(() => {
          //   this.outprintWorkingTypeThumbnail(1);
          // });
          // this.$emit("outPrintScreen");
        }

        if (this.showType) {
          //默认模板
          console.log("获取默认模板数据");
          this.$nextTick(async () => {
            console.log(this.shapeItem);
            let relationship = this.$refs.logicflow.apiGetEdgeByNodeId(
              this.shapeItem.data.id
            );
            await this.$refs.template2D.initData(
              this.step2DInfo,
              this.shapeItem,
              relationship
            );

            // this.$emit("outPrintScreen");
          });
        } else {
          //输入步骤模板
          console.log("获取输入模板数据");
          this.$nextTick(async () => {
            console.log(this.shapeItem);
            let relationship = this.$refs.logicflow.apiGetEdgeByNodeId(
              this.shapeItem.data.id
            );
            await this.$refs.template2D.initData(
              this.step2DInfo,
              this.shapeItem,
              relationship
            );
            // this.$emit("outPrintScreen");
          });
        }
      }
    },
    isCloseEditer(newVal, oldVal) {
      console.log(newVal);
      console.log(this.$refs.template2D);
      this.$nextTick(() => {
        if (this.$refs.template2D) {
          this.$refs.template2D.resize();
        }
      });
    },
    // selectedTask(value) {
    //   console.log("条件改变");
    // },
  },
  async created() {
    // console.log(JSON.parse(this.json));
    console.log(this.projectInfo);
    console.log(this.$store.state.User);
    console.log(this.projectInfo && this.projectInfo.createdBy);

    console.log(this.isCreator);
    this.projectGuid = this.$route.query.projectGuid;
    this.releaseGuid = this.$route.query.releaseGuid;
    this.releaseMode =
      this.$route.query.releaseGuid || this.$route.query.releaseMode;
    this.auditReleaseGuid = this.$route.query.auditReleaseGuid;
    if (this.auditReleaseGuid) {
      this.auditReleaseMode = true;
    }
    await this.loadProject();
    console.log(this.projectInfo);
    this.projectInfo.editType = parseInt(this.projectInfo.editType);

    let selectTaskGuid = localStorage.getItem("JTWorkflow_taskGuid");
    let selectIndex = 0;
    if (selectTaskGuid) {
      this.projectInfo.tasks.forEach((value, index) => {
        if (value.taskGuid === selectTaskGuid) {
          selectIndex = index;
        }
      });
    }
    if (this.readOnlyRouterList.includes(this.$route.path)) {
      this.readOnly = true;
    } else {
      this.readOnly = false;
      // this.config.keyboard = true;
      // this.config.keyboard.shortcuts = this.shortcuts;
    }
    this.selectItem(this.projectInfo.tasks[selectIndex]);
  },
  mounted() {
    document.onkeydown = this.keyDown;
    // document.addEventListener("keydown", (e) => {
    //   this.keyDown(e);
    // });
    // this.$nextTick(() => {
    //   window.onclick = (e) => {
    //     let targetDom = document.getElementById("svgBodyWarp");
    //     console.log(targetDom);
    //     let eventDom = this.$refs.logicflow;
    //     console.log(eventDom);
    //     if (targetDom) {
    //       let isSelf = targetDom.contains(e.target);
    //       if (isSelf) {
    //         // console.log("点到了");
    //         eventDom.bandingWindowEvent();
    //       } else {
    //         // console.log("没点到");
    //         eventDom.unbandingWindowEvent();
    //       }
    //     }
    //   };
    // });
  },
  beforeRouteLeave(to, from, next) {
    if (this.taskChartNeedSave || this.templateNeedSave) {
      this.needSaveShow = true;
      console.log(to);
      this.toPath = to.fullPath;
      // this.$confirm("检测到流程图有未保存的内容,是否保存?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   // type: "warning",
      // })
      //   .then(async () => {
      //     await this.$refs.taskChart.saveAll(true);
      //     next();
      //   })
      //   .catch(async () => {
      //     let result = await getTaskById(
      //       this.selectedTask.taskGuid,
      //       this.projectInfo.releaseGuid ? this.projectInfo.releaseGuid : null
      //     );

      //     if (to.path === "/webGL" && result.steps.length < 1) {
      //       ElMessage.warning({
      //         message: "请先给当前任务内添加步骤并保存!",
      //         type: "warning",
      //       });

      //       console.log(from);
      //       console.log(to);
      //       console.log(this.$router);
      //       // this.$router
      //       return;
      //     }
      //     next();
      //   });
    } else {
      next();
    }
  },
  unmounted() {
    console.log("解除绑定");
    window.onclick = null;
    window.onkeydown = null;
    window.onkeyup = null;
    document.onkeydown = null;
  },
  methods: {
    //项目语言改变，重新设置项目语言
    languageChange(data) {
      this.projectInfo.language = data;
    },
    //清除首次添加输入步骤的时候，自定义的一个默认项，解决首次添加输入步骤，输入步骤保存之后重新加载加载不出真实数据的BUG
    clearStepObject(options) {
      console.log(options);
      this.$refs.logicflow.clearStepObject(options.stepGuid);
    },
    async saveTemplate() {
      console.log("保存模板");
      await this.$refs.template2D.saveAll(true);
      this.templateNeedSave = false;
    },
    selectTaskStep(data) {
      this.selectTaskStepInfo = data;
    },
    //选中任务步骤的时候双击右下角缩略图，加载目标任务
    gotoPointer(data) {
      let targetTaskItem = this.showList.find((item) => {
        return item.taskGuid == data.data.properties.stepInfo.refTaskGuid;
      });
      console.log(targetTaskItem);
      this.selectItemWarp(targetTaskItem);
    },
    //工作模式切换之前触发
    beforeChangeWorkingType() {
      return new Promise((reslove, reject) => {
        // 检测到未保存的配置信息，是否保存修改？ 提示
        this.$confirm(
          this.$t("taskNewDatail.tip13"),
          this.$t("taskNewDatail.tip1"),
          {
            closeOnPressEscape: false,
            closeOnClickModal: false,
            distinguishCancelAndClose: true,
            // 保存
            confirmButtonText: this.$t("taskNewDatail.save"),
            // 不保存
            cancelButtonText: this.$t("taskNewDatail.noSave"),
          }
        )
          .then(async () => {
            //保存之后再切换
            // if (this.taskChartNeedSave) {
            await this.$refs.logicflow.saveAll(false, false);
            // }
            console.log(this.templateNeedSave);
            if (this.templateNeedSave) {
              setTimeout(async () => {
                await this.$refs.template2D.saveAll(true);
              }, 300);
            }
            this.templateNeedSave = false;
            // this.workingType = 0;
            reslove(true);
          })
          .catch((action) => {
            if (action == "cancel") {
              //不保存刚才的配置 直接切换步骤
              this.templateNeedSave = false;
              let selectStepGuid = getSelectedGuid();
              let nodeInfo = this.$refs.logicflow.apiGetNodeById(
                selectStepGuid
              );
              this.$refs.logicflow.selectElement({ data: nodeInfo });
              // this.workingType = 0;
              reslove(true);
            } else if (action === "close") {
              //取消这次操作，继续留在此步骤
              console.log("close");
              reslove(false);
              // this.templateNeedSave = false;
            }
          });
      });
    },
    askIsSave(nodesInfo) {
      console.log("nodeInfo", nodesInfo);
      this.$refs.logicflow.apiSelectById(nodesInfo.nowNode.id);
      this.$confirm(
        // 检测到未保存的步骤配置信息，是否在切换步骤之前前保存修改？
        this.$t("taskNewDatail.tip13"),
        // 切换步骤
        this.$t("taskNewDatail.tip14"),
        {
          closeOnPressEscape: false,
          closeOnClickModal: false,
          distinguishCancelAndClose: true,
          // 保存
          confirmButtonText: this.$t("taskNewDatail.save"),
          // 不保存
          cancelButtonText: this.$t("taskNewDatail.noSave"),
        }
      )
        .then(async () => {
          //保存之后再切换
          // this.$refs.logicflow.saveStepConfig();
          // await this.saveTaskChar();
          // if (this.taskChartNeedSave) {
          await this.$refs.logicflow.saveAll(false, false);
          // }

          if (this.templateNeedSave) {
            setTimeout(async () => {
              await this.$refs.template2D.saveAll(true);
              console.log(this.$refs.logicflow);
              this.taskChartNeedSave = false;
              this.templateNeedSave = false;
              this.$nextTick(() => {
                this.$refs.logicflow.apiSelectById(
                  nodesInfo.targetNode.data.id
                );
                this.$refs.logicflow.selectElement(nodesInfo.targetNode);
              });
            }, 300);
          }
        })
        .catch((action) => {
          if (action == "cancel") {
            //不保存刚才的配置 直接切换步骤

            console.log("cancel");
            this.templateNeedSave = false;
            this.$nextTick(() => {
              this.$refs.logicflow.apiSelectById(nodesInfo.targetNode.data.id);
              this.$refs.logicflow.selectElement(nodesInfo.targetNode);
            });
          } else if (action === "close") {
            //取消这次操作，继续留在此步骤
            console.log("close");
            // this.templateNeedSave = false;
          }
        });
    },
    keyDown(e) {
      // console.log(e);
      if (e.ctrlKey && e.altKey && e.keyCode === 83) {
        console.log("保存历史纪录");
        this.$refs.logicflow.noAutoSaveHistory2();
      }
    },
    //数据填充完毕 生成缩略图
    async outPrintScreen() {
      console.log("生成截图");
      console.log(this.projectInfo);
      if (!this.projectInfo.editType) {
        return;
      }
      this.$nextTick(async () => {
        let img;
        if (this.workingType === 0) {
          img = await this.outprintWorkingTypeThumbnail(0);
        } else {
          img = await this.outprintWorkingTypeThumbnail(1);
        }

        this.workingTypeThumbnail = img;
        if (this.$refs.editStep) {
          this.$refs.editStep.setWorkingTypeThumbnail(img);
        }
      });
    },
    // async logicflowSelectNode(data) {
    //   console.log(data);
    //   //选中的步骤改变
    //   this.$nextTick(async () => {
    //     let img = await this.outprintWorkingTypeThumbnail(0);
    //     this.workingTypeThumbnail = img;
    //     this.$refs.editStep.setWorkingTypeThumbnail(img);
    //   });
    // },

    async thumbnailLoaddone() {
      //缩略图容器加载完毕
      let img = await this.outprintWorkingTypeThumbnail(this.workingType);
      this.workingTypeThumbnail = img;
      this.$nextTick(() => {
        if (this.$refs.editStep) {
          this.$refs.editStep.setWorkingTypeThumbnail(img);
        }
      });
    },
    async template2DLoaddone() {
      console.log("加载完毕");
    },
    //为当前active的工作模式生成缩略图
    outprintWorkingTypeThumbnail(workingType) {
      console.log(html2canvas);
      return new Promise((reslove, reject) => {
        let dom;
        if (workingType) {
          //切换模板编辑模式的时候
          dom = document.getElementById("logicflowDom");
        } else {
          //切换流程图编辑模式的时候
          dom = document.getElementById("templatesWrap1");
        }
        //允许跨域的图片 让画布可以绘制网络资源图片
        html2canvas(dom, { useCORS: true }).then((canvas) => {
          // var image = new Image();
          let img = canvas.toDataURL("image/png");
          // document.body.appendChild(image);
          reslove(img);
        });
      });
    },
    //点击切换按钮切换工作状态
    toggleWorkingType() {
      if (!this.workingType) {
        this.setWorkingType(1);
      } else {
        this.setWorkingType(0);
      }
    },
    //切换工作状态 流程图模式 or 步骤信息编辑模式
    async setWorkingType(data) {
      let next = true;
      if (data === 0 && this.templateNeedSave) {
        next = await this.beforeChangeWorkingType();
      }
      if (!next) {
        return;
      }
      this.workingType = data;
      let img = await this.outprintWorkingTypeThumbnail(data);
      this.workingTypeThumbnail = img;
      if (this.$refs.editStep) {
        this.$refs.editStep.setWorkingTypeThumbnail(this.workingTypeThumbnail);
      }
    },
    async selectedTemplate(data) {
      if (data.type === this.templateInfo.type) {
        return;
      }
      this.templateInfo = data;
      this.needSave(true);
      this.templateNeedSave = true;
      await this.saveTaskChar();
      // await this.$refs.template2D.saveAll(true);
      this.needSave(false);
      this.templateNeedSave = false;

      if (!this.workingType) {
        //当流程图在编辑模式中时需要重新生成模板的缩略图
        this.$nextTick(async () => {
          console.log(getSelectedGuid());

          this.thumbnailLoaddone();
        });
      }
    },
    closeStepEdit() {
      this.$refs.editStep.init(false);
    },
    textChange(data) {
      this.$refs.editStep.init(data);
    },
    refreashHistoryList() {
      this.$refs.editStep.refreashHistoryList();
    },
    //选中线或节点打开右侧详情面板
    editStepSetPanel(data) {
      console.log('editStepSetPanel', data);
      this.shapeItem = data;
      this.$refs.editStep.init(data);
    },
    noAutoSaveHistory(data) {
      this.$refs.logicflow.noAutoSaveHistory(data);
    },
    dataNeedSave(data) {
      this.$refs.logicflow.dataNeedSave(data);
    },
    setLinkName(data) {
      this.$refs.logicflow.setLinkName(data);
    },
    setDescription(data) {
      this.$refs.logicflow.setDescription(data);
    },
    setStepName(data) {
      this.$refs.logicflow.setStepName(data);
    },
    resetTaskName(item, index) {
      // this.tempItemName = "";
      item.dbClick = 2;
      this.$nextTick(() => {
        this.tempItemName = item.taskName ? item.taskName : "Please enter Item";
        let dom = document.getElementById(item.taskGuid);
        if (dom) {
          dom.focus();
        }
      });
    },
    cancleSave() {
      this.needSaveShow = false;
    },
    noSave() {
      this.needSaveShow = false;
      this.templateNeedSave = false;
      this.taskChartNeedSave = false;
      this.$router.push(this.toPath);
    },
    async save() {
      this.needSaveShow = false;
      // await this.$refs.logicflow.saveAll(true);
      await this.saveTaskChar();
      this.taskChartNeedSave = false;
      this.$router.push(this.toPath);
    },
    async ownProject() {
      console.log("own", this.projectInfo);
      await saveOwnProject(
        this.projectInfo.projectGuid,
        this.projectInfo.releaseGuid,
        false
      );
      // this.$emit("setOwn", true);
      this.myProjectStatus.own = true;
      // 以获取此项目,可在'我的项目->已拥有项目'中查看
      this.$alert(this.$t("taskNewDatail.tip3"));
    },
    async refreshList(data) {
      console.log(data);
      if (data.taskOrder >= 0) {
        await this.loadProject(data.taskOrder);
        if (data.taskOrder === null) {
          this.selectItem(this.projectInfo.tasks[0]);
        } else {
          if (this.projectInfo.tasks.length > 0) {
            this.selectItem(this.projectInfo.tasks[data.taskOrder]);
          }
        }
      } else {
        this.loadProject();
      }
    },
    projectNameChange(projectName) {
      console.log(projectName);
      this.projectInfo.projectName = projectName;
    },
    async moveDown(item, index) {
      if (
        this.projectInfo.tasks.length < 2 ||
        index > this.projectInfo.tasks.length - 2
      ) {
        return;
      }
      [this.projectInfo.tasks[index + 1], this.projectInfo.tasks[index]] = [
        this.projectInfo.tasks[index],
        this.projectInfo.tasks[index + 1],
      ];
      [
        this.projectInfo.tasks[index].taskOrder,
        this.projectInfo.tasks[index + 1].taskOrder,
      ] = [
        this.projectInfo.tasks[index + 1].taskOrder,
        this.projectInfo.tasks[index].taskOrder,
      ];
      await this.saveTaskByOrder(
        this.projectInfo.tasks[index + 1],
        this.projectInfo.tasks[index + 1].taskOrder
      );
      await this.saveTaskByOrder(
        this.projectInfo.tasks[index],
        this.projectInfo.tasks[index].taskOrder
      );
      this.searchTask();
    },
    async moveTop(item, index) {
      if (index < 1) {
        return;
      }
      [this.projectInfo.tasks[index - 1], this.projectInfo.tasks[index]] = [
        this.projectInfo.tasks[index],
        this.projectInfo.tasks[index - 1],
      ];
      [
        this.projectInfo.tasks[index].taskOrder,
        this.projectInfo.tasks[index - 1].taskOrder,
      ] = [
        this.projectInfo.tasks[index - 1].taskOrder,
        this.projectInfo.tasks[index].taskOrder,
      ];
      await this.saveTaskByOrder(
        this.projectInfo.tasks[index - 1],
        this.projectInfo.tasks[index - 1].taskOrder
      );
      await this.saveTaskByOrder(
        this.projectInfo.tasks[index],
        this.projectInfo.tasks[index].taskOrder
      );
      this.searchTask();
    },
    async saveTaskByOrder(item, order) {
      let options = {
        taskGuid: item.taskGuid,
        projectGuid: item.projectGuid,
        taskOrder: order,
      };
      await saveTask(options);
    },
    setOwn(bool) {
      this.myProjectStatus.own = bool;
      console.log("taskDetailNew", this.myProjectStatus.own);
    },
    templateNeedSaveFun(data) {
      console.log("模板needSave", data);
      this.templateNeedSave = data;
    },
    needSave(data) {
      this.taskChartNeedSave = data;
    },
    onCreateTask() {
      this.$refs.createTask.init(true);
    },
    async deleteTask(item, index) {
      console.log(item, index);
      if (this.projectInfo.tasks.length < 2) {
        // 每个项目中至少需要包含一个任务!
        ElMessage.error(this.$t("taskNewDatail.tip4"));
        return;
      }
      // 此操作将永久删除该任务, 是否继续?", "提示"
      this.$confirm(
        this.$t("taskNewDatail.tip5"),
        this.$t("taskNewDatail.tip6"),
        {
          // 确定
          confirmButtonText: this.$t("taskNewDatail.ok"),
          // 取消
          cancelButtonText: this.$t("taskNewDatail.cancel"),
          type: "warning",
          customClass: "messBoxWarp",
        }
      )
        .then(async () => {
          await saveTask({
            taskGuid: item.taskGuid,
            isDeleted: true,
            projectGuid: item.projectGuid,
          });
          // this.$store.state.TaskDetail.projectTaskResult.tasks.splice(index, 1);
          this.showList.splice(index, 1);
          this.$message({
            type: "success",
            // 已删除
            message: this.$t("taskNewDatail.deleted"),
          });
          this.projectInfo.tasks.forEach((v, i) => {
            if (v.taskGuid === item.taskGuid) {
              this.projectInfo.tasks.splice(i, 1);
            }
          });
          this.showList = JSON.parse(JSON.stringify(this.projectInfo.tasks));
          this.searchTask();
          if (this.selectedTask.taskGuid === item.taskGuid) {
            this.selectItem(this.showList[0]);
          }
        })
        .catch(() => {});
    },
    async saveTaskChar() {
      if (this.workingType) {
        //模板状态下 保存模板时需要确认该编辑步骤已被保存
        if (this.taskChartNeedSave) {
          await this.$refs.logicflow.saveAll(false, false); //保存后不重绘流程图 保存成功后不弹"保存成功"的提示框
        }
        //保存模板中的信息
        setTimeout(async () => {
          await this.$refs.template2D.saveAll(true);
          this.taskChartNeedSave = false;
          this.templateNeedSave = false;
        }, 300);
      } else {
        //流程图模式下
        await this.$refs.logicflow.saveAll(true);
        console.log(this.templateNeedSave);
        if (this.templateNeedSave) {
          setTimeout(async () => {
            await this.$refs.template2D.saveAll(true);
            console.log(this.$refs.logicflow);
            this.taskChartNeedSave = false;
            this.templateNeedSave = false;
          }, 300);
        }
      }
    },
    setTaskOrder() {
      this.projectInfo.tasks.forEach(async (value, index) => {
        if (!value.taskOrder && value.taskOrder !== 0) {
          let options = {
            taskGuid: value.taskGuid,
            projectGuid: value.projectGuid,
            taskOrder: index,
          };
          await saveTask(options);
        }
      });
    },
    async loadProject() {
      if (this.auditReleaseGuid) {
        let tempObj = await getReleaseById(this.auditReleaseGuid);
        tempObj.tasks.forEach((value, index) => {
          value.dbClick = 0;
        });
        this.projectInfo = tempObj;
        this.showList = JSON.parse(JSON.stringify(this.projectInfo.tasks));
        this.searchTask();
        try {
          this.myProjectStatus = await getProjectStatusById(this.projectGuid);
        } catch (e) {
          console.log(e);
        }
      } else if (this.releaseMode) {
        let tempObj = await getReleaseById(this.releaseGuid, this.projectGuid);
        tempObj.tasks.forEach((value, index) => {
          value.dbClick = 0;
        });
        this.projectInfo = tempObj;
        this.showList = JSON.parse(JSON.stringify(this.projectInfo.tasks));
        this.searchTask();
        try {
          this.myProjectStatus = await getProjectStatusById(this.projectGuid);
        } catch (e) {
          console.log(e);
        }
      } else if (this.projectGuid) {
        let tempObj = await getProjectById(this.projectGuid);
        tempObj.tasks.forEach((value, index) => {
          value.dbClick = 0;
        });

        this.projectInfo = tempObj;
        this.setTaskOrder();
        this.showList = JSON.parse(JSON.stringify(this.projectInfo.tasks));
        this.searchTask();
        // await this.refreshTaskList();
      } else {
        return;
      }

      console.log(this.projectInfo);
    },
    async refreshTaskList(taskItem) {
      this.projectInfo = JSON.parse(getProjectInfo());
      let temp;
      if (this.$route.query.isRelease === "true") {
        if (this.$route.query.releaseGuid !== "*") {
          temp = await getReleaseById(this.$route.query.releaseGuid, null);
        } else if (this.$route.query.projectGuid !== "*") {
          temp = await getReleaseById(null, this.$route.query.projectGuid);
        }
      } else {
        if (this.auditReleaseMode) {
          temp = await getReleaseById(this.$route.query.auditReleaseGuid);
        } else {
          temp = await getProjectById(this.$route.query.projectGuid);
        }
      }
      console.log(temp);
      this.projectInfo = temp;
      this.$store.commit("SET_PROJECTTASKRESULT", temp);
    },
    addTask() {
      console.log(this.$refs);
      this.$refs.createTask.init(true);
    },
    refreshTaskInfo(data) {
      this.projectInfo.tasks.forEach((item, index) => {
        if (item.taskGuid === data.taskGuid) {
          this.projectInfo.tasks[index] = data;
        }
      });
    },
    async selectItemWarp(item, e) {
      await this.selectItem(item, e);
      // this.$refs.taskChart.workflowChart.deleteMulSelect();
      // this.$refs.taskChart.startTask(6);
      // let res = await getTaskById(item.taskGuid);
      // this.projectInfo.tasks.forEach((item, index) => {
      //   if (item.taskGuid === item.taskGuid) {
      //     this.projectInfo.tasks[index] = res;
      //   }
      // });
      // this.$refs.logicflow.loadShape(item);
    },
    async selectItem(item, e) {
      //单击
      if (!item.dbClick) {
        item.dbClick = 0;
      }
      if (item.dbClick === 0 || !item.dbClick) {
        item.dbClick += 1;
        item.dbClickTimer = setTimeout(() => {
          item.dbClick = 0;
          if (this.taskChartNeedSave || this.templateNeedSave) {
            // 检测到流程图有未保存的内容,是否保存? 提示
            this.$confirm(
              this.$t("taskNewDatail.tip2"),
              this.$t("taskNewDatail.tip1"),

              {
                closeOnPressEscape: false,
                closeOnClickModal: false,
                distinguishCancelAndClose: true,
                // 确定
                confirmButtonText: this.$t("taskNewDatail.ok"),
                // 取消
                cancelButtonText: this.$t("taskNewDatail.cancel"),
                // type: "warning",
              }
            )
              .then(async () => {
                await this.$refs.logicflow.saveAll(false);
                if (this.templateNeedSave) {
                  setTimeout(async () => {
                    await this.$refs.template2D.saveAll(true);
                    // this.saveTaskChar();
                    this.templateNeedSave = false;
                    this.selectedTask = item;
                    // this.$refs.logicflow.closeStepEdit();
                    this.closeStepEdit();
                    this.workingType = 0;
                  }, 300);
                }
              })
              .catch((action) => {
                if (action === "cancel") {
                  this.selectedTask = item;
                  this.taskChartNeedSave = false;
                } else if (action === "close") {
                  return;
                }
              });
          } else {
            this.selectedTask = item;
            this.$store.commit("SET_PROJECTINFO", true);
            this.$store.commit("SET_SHOWHISTORY", false);
            this.$store.commit("SET_SELECTSHAPE", false);
            this.workingType = 0;
            if (this.$refs.logicflow) {
              // this.$refs.logicflow.closeStepEdit();
              this.closeStepEdit();
            }
          }
          // if (this.templateNeedSave) {
          //   this.beforeChangeWorkingType();
          // }
          setTaskGuid(item.taskGuid);
        }, 200);
        return;
      }
      if (item.dbClick === 1) {
        if (item.dbClickTimer) {
          clearTimeout(item.dbClickTimer);
          item.dbClickTimer = null;
        }
        //把其它已经出现的框框隐藏
        this.projectInfo.tasks.forEach((value, index) => {
          value.dbClick = 0;
        });
        //把当前双击的输入框显示出来
        item.dbClick = 2;
        this.$nextTick(() => {
          this.tempItemName = item.taskName
            ? item.taskName
            : "Please enter Item";
          let dom = document.getElementById(item.taskGuid);
          if (dom) {
            dom.focus();
          }
        });
        return;
      }
      if (!item.dbClick) {
        item.dbClick = 1;
        item.dbClickTimer = setTimeout(() => {
          item.dbClick = 0;
        }, 200);
      }
    },
    async onblur(item, index) {
      console.log(item);
      item.dbClick = 0;
      console.log(this.tempItemName);
      console.log(item.taskName);
      if (!item.taskName) {
        item.taskName = this.tempItemName;
      }
      let options = {
        projectGuid: item.projectGuid,
        taskGuid: item.taskGuid,
        taskName: item.taskName,
        isDeleted: false,
      };
      try {
        let result = await saveTask(options);
        // this.$message({
        //   type: "success",
        //   message: "已修改",
        // });
        //通知其他组件刷新任务名称
        console.log(result);
        console.log(this.projectInfo.tasks[index]);
        this.projectInfo.tasks[index] = result;
        this.$refs.logicflow.updateDropdownList(this.projectInfo.tasks);
      } catch (e) {
        console.log(e);
        item.taskName = this.tempItemName;
      }
    },
    itemCtrl(ctrl) {
      if (ctrl === "clone") {
        console.log(1);
      } else if (ctrl === "toTop") {
        console.log(2);
      } else if (ctrl === "toBottom") {
        console.log(3);
      } else if (ctrl === "delete") {
        console.log(4);
      }
    },
    async prevView2D() {
      console.log("2D");
      try {
          await addPreviewCount(this.projectInfo.projectGuid);
      } catch (e) {
        console.log(e);
      }

      let selectedNodeGuid = getSelectedGuid();
      let selectNode = this.$refs.logicflow.apiGetNodeById(selectedNodeGuid);
      if (selectNode) {
        //选中node点击预览
        console.log(selectNode);
        if (selectNode.type === "pointer") {
          console.log(1);
          //被选中的是任务步骤
          if (this.auditReleaseGuid) {
            removeSelectedGuid();
            this.$router.push({
              path: "/prevTemplate2D",
              query: {
                projectGuid: this.projectInfo.projectGuid,
                taskGuid: selectNode.properties.stepInfo.refTaskGuid,
                auditReleaseGuid: this.projectInfo.auditReleaseGuid
                  ? this.projectInfo.auditReleaseGuid
                  : null,
              },
            });
          } else if (this.isCreator) {
            console.log(2);
            //编辑模式下 项目拥有者
            removeSelectedGuid();
            this.$router.push({
              path: "/prevTemplate2D",
              query: {
                projectGuid: this.projectInfo.projectGuid,
                taskGuid: selectNode.properties.stepInfo.refTaskGuid,
              },
            });
          } else {
            console.log(3);
            //非此项目的创建者 预览此项目
            removeSelectedGuid();
            this.$router.push({
              path: "/prevTemplate2D",
              query: {
                releaseGuid: selectNode.properties.stepInfo.releaseGuid,
                projectGuid: this.projectInfo.projectGuid,
                taskGuid: selectNode.properties.stepInfo.refTaskGuid,
              },
            });
          }
        } else {
          console.log(4);
          //被选中的是普通步骤
          if (this.auditReleaseGuid) {
            removeSelectedGuid();
            this.$router.push({
              path: "/prevTemplate2D",
              query: {
                projectGuid: this.projectInfo.projectGuid,
                taskGuid: getTaskGuid(),
                auditReleaseGuid: this.projectInfo.auditReleaseGuid
                  ? this.projectInfo.auditReleaseGuid
                  : null,
              },
            });
          } else if (this.isCreator) {
            console.log(5);
            //编辑模式下 项目拥有者
            this.$router.push({
              path: "/prevTemplate2D",
              query: {
                projectGuid: this.projectInfo.projectGuid,
                taskGuid: getTaskGuid(),
              },
            });
          } else {
            console.log(6);
            //预览模式下，项目发布状态预览此项目
            this.$router.push({
              path: "/prevTemplate2D",
              query: {
                releaseGuid: this.projectInfo.releaseGuid,
                projectGuid: this.projectInfo.projectGuid,
                taskGuid: getTaskGuid(),
              },
            });
          }
        }
      } else {
        console.log(7);
        //没选中任何node就点击了预览
        if (this.auditReleaseGuid) {
          removeSelectedGuid();
          this.$router.push({
            path: "/prevTemplate2D",
            query: {
              projectGuid: this.projectInfo.projectGuid,
              taskGuid: getTaskGuid(),
              auditReleaseGuid: this.projectInfo.auditReleaseGuid
                ? this.projectInfo.auditReleaseGuid
                : null,
            },
          });
        } else if (this.isCreator) {
          this.$router.push({
            path: "/prevTemplate2D",
            query: {
              projectGuid: this.projectInfo.projectGuid,
              taskGuid: getTaskGuid(),
            },
          });
        } else {
          this.$router.push({
            path: "/prevTemplate2D",
            query: {
              releaseGuid: this.projectInfo.releaseGuid,
              projectGuid: this.projectInfo.projectGuid,
              taskGuid: getTaskGuid(),
            },
          });
        }
      }
    },
    async topCtrl(ctrl) {
      if (ctrl === "1") {
        console.log(1);
        console.log(this.$route.params.id);
        //新逻辑
        let selectedNodeGuid = getSelectedGuid();
        //已有选中的节点
        if (selectedNodeGuid) {
          console.log(this.$refs.logicflow);
          console.log(this.$refs.logicflow.apiGetNodeById);
          let selectNode = this.$refs.logicflow.apiGetNodeById(
            selectedNodeGuid
          );
          console.log(selectNode);
          //如果选中的节点是任务步骤
          if (selectNode.type === "pointer") {
            let result;
            //向服务端获取这个任务步骤的信息
            if (this.auditReleaseMode) {
              result = await getTaskById(
                selectNode.properties.stepInfo.refTaskGuid,
                this.projectInfo.auditReleaseGuid
                  ? this.projectInfo.auditReleaseGuid
                  : null
              );
            } else {
              result = await getTaskById(
                selectNode.properties.stepInfo.refTaskGuid,
                this.projectInfo.releaseGuid
                  ? this.projectInfo.releaseGuid
                  : null
              );
            }

            //把这个步骤的guid设置为选中的guid
            setSelectedGuid(result.beginStepGuid);
            //跳转路由
            this.$router.push({
              path: "/webGL",
              query: {
                projectGuid: this.projectInfo.projectGuid,
                taskGuid: selectNode.properties.stepInfo.refTaskGuid,
                isEdit: true,
              },
            });
          } else {
            //不是任务步骤
            this.$router.push({
              path: "/webGL",
              query: {
                projectGuid: this.projectInfo.projectGuid,
                taskGuid: getTaskGuid(),
                isEdit: true,
              },
            });
          }
        } else {
          //没有选中的节点 直接跳转路由
          this.$router.push({
            path: "/webGL",
            query: {
              projectGuid: this.projectInfo.projectGuid,
              taskGuid: getTaskGuid(),
              isEdit: true,
            },
          });
          removeSelectedGuid();
        }
        //旧逻辑
        // console.log(this.$refs.taskChart.workflowChart.selectedShape);
        // let selectShape = this.$refs.taskChart.workflowChart.selectedShape;
        // if (this.$refs.taskChart.workflowChart.selectedShape) {
        //   if (
        //     this.$refs.taskChart.workflowChart.selectedShape.type === "pointer"
        //   ) {
        //     let result = await getTaskById(
        //       selectShape.data.refTaskGuid,
        //       this.projectInfo.releaseGuid ? this.projectInfo.releaseGuid : null
        //     );
        //     console.log(result);
        //     setSelectedGuid(result.beginStepGuid);
        //     this.$router.push({
        //       path: "/webGL",
        //       query: {
        //         projectGuid: this.projectInfo.projectGuid,
        //         taskGuid: selectShape.data.refTaskGuid,
        //         isEdit: true,
        //       },
        //     });
        //   } else {
        //     this.$router.push({
        //       path: "/webGL",
        //       query: {
        //         projectGuid: this.projectInfo.projectGuid,
        //         taskGuid: getTaskGuid(),
        //         isEdit: true,
        //       },
        //     });
        //   }
        // } else {
        //   this.$router.push({
        //     path: "/webGL",
        //     query: {
        //       projectGuid: this.projectInfo.projectGuid,
        //       taskGuid: getTaskGuid(),
        //       isEdit: true,
        //     },
        //   });
        //   removeSelectedGuid();
        // }
        //旧逻辑
      } else if (ctrl === "2") {
        console.log(2);
        // this.$refs.ReleaseProject.init(true);
        await this.$refs.logicflow.saveAll(false);
        // if (this.templateNeedSave) {
        setTimeout(async () => {
          try {
            await this.$refs.template2D.saveAll(true);
          } catch (e) {}
          this.templateNeedSave = false;
          this.releaseShow = true;
        }, 300);
        // }
      } else if (ctrl === "3") {
        console.log(3);
        if (this.projectInfo.editType == 1) {
          //2D项目
          this.prevView2D();
          return;
        }
        let selectShape = getSelectedGuid();
        //已有选中的节点
        if (selectShape) {
          //获取当前被选中节点的详情
          let selectNode = this.$refs.logicflow.apiGetNodeById(selectShape);
          console.log(selectNode);
          if (selectNode.type === "pointer") {
            //如果是任务步骤，先获取详情，在改变传参
            let result;
            if (this.auditReleaseMode) {
              result = await getTaskById(
                selectNode.properties.stepInfo.refTaskGuid,
                this.projectInfo.auditReleaseGuid
                  ? this.projectInfo.auditReleaseGuid
                  : null
              );
            } else {
              result = await getTaskById(
                selectNode.properties.stepInfo.refTaskGuid,
                this.projectInfo.releaseGuid
                  ? this.projectInfo.releaseGuid
                  : null
              );
            }

            console.log(result);
            setSelectedGuid(result.beginStepGuid);
            if (this.isCreator) {
              console.log(1);
              this.$router.push({
                path: "/webGL",
                query: {
                  projectGuid: this.projectInfo.projectGuid,
                  taskGuid: selectNode.properties.stepInfo.refTaskGuid,
                  isEdit: false,
                },
              });
            } else {
              console.log(2);
              this.$router.push({
                path: "/webGL",
                query: {
                  releaseGuid: selectNode.properties.stepInfo.releaseGuid,
                  projectGuid: this.projectInfo.projectGuid,
                  taskGuid: selectNode.properties.stepInfo.refTaskGuid,
                  auditReleaseGuid: this.projectInfo.auditReleaseGuid
                    ? this.projectInfo.auditReleaseGuid
                    : null,
                  isEdit: false,
                },
              });
              console.log(this.isCreator);
              try {
                await addPreviewCount(this.projectInfo.projectGuid);
              } catch (e) {
                console.log(e);
              }
            }
          } else {
            //如果不是任务步骤
            if (this.isCreator) {
              console.log(3);
              this.$router.push({
                path: "/webGL",
                query: {
                  projectGuid: this.projectInfo.projectGuid,
                  taskGuid: getTaskGuid(),
                  isEdit: false,
                },
              });
            } else {
              console.log(4);
              this.$router.push({
                path: "/webGL",
                query: {
                  releaseGuid: selectNode.properties.stepInfo.releaseGuid,
                  projectGuid: this.projectInfo.projectGuid,
                  auditReleaseGuid: this.projectInfo.auditReleaseGuid
                    ? this.projectInfo.auditReleaseGuid
                    : null,
                  taskGuid: getTaskGuid(),
                  isEdit: false,
                },
              });
              console.log(this.isCreator);
              try {
                await addPreviewCount(this.projectInfo.projectGuid);
              } catch (e) {
                console.log(e);
              }
            }
          }
        } else {
          if (this.isCreator) {
            console.log(5);
            this.$router.push({
              path: "/webGL",
              query: {
                projectGuid: this.projectInfo.projectGuid,
                taskGuid: getTaskGuid(),
                isEdit: false,
              },
            });
          } else {
            console.log(6);
            this.$router.push({
              path: "/webGL",
              query: {
                releaseGuid: this.projectInfo.releaseGuid,
                projectGuid: this.projectInfo.projectGuid,
                auditReleaseGuid: this.projectInfo.auditReleaseGuid
                  ? this.projectInfo.auditReleaseGuid
                  : null,
                taskGuid: getTaskGuid(),
                isEdit: false,
              },
            });
            console.log(this.isCreator);
            try {
              await addPreviewCount(this.projectInfo.projectGuid);
            } catch (e) {
              console.log(e);
            }
          }

          removeSelectedGuid();
        }
        //旧逻辑
        // let selectShape = this.$refs.taskChart.workflowChart.selectedShape;
        // if (this.$refs.taskChart.workflowChart.selectedShape) {
        //   if (
        //     this.$refs.taskChart.workflowChart.selectedShape.type === "pointer"
        //   ) {
        //     //如果是任务步骤，先获取详情，在改变传参
        //     let result = await getTaskById(
        //       selectShape.data.refTaskGuid,
        //       this.projectInfo.releaseGuid ? this.projectInfo.releaseGuid : null
        //     );
        //     console.log(result);
        //     setSelectedGuid(result.beginStepGuid);
        //     if (this.isCreator) {
        //       console.log(1);
        //       this.$router.push({
        //         path: "/webGL",
        //         query: {
        //           projectGuid: this.projectInfo.projectGuid,
        //           taskGuid: selectShape.data.refTaskGuid,
        //           isEdit: false,
        //         },
        //       });
        //     } else {
        //       console.log(2);
        //       this.$router.push({
        //         path: "/webGL",
        //         query: {
        //           releaseGuid: selectShape.data.releaseGuid,
        //           projectGuid: this.projectInfo.projectGuid,
        //           taskGuid: selectShape.data.refTaskGuid,
        //           isEdit: false,
        //         },
        //       });
        //       console.log(this.isCreator);
        //       try {
        //         await addPreviewCount(this.projectInfo.projectGuid);
        //       } catch (e) {
        //         console.log(e);
        //       }
        //     }
        //   } else {
        //     if (this.isCreator) {
        //       console.log(3);
        //       this.$router.push({
        //         path: "/webGL",
        //         query: {
        //           projectGuid: this.projectInfo.projectGuid,
        //           taskGuid: getTaskGuid(),
        //           isEdit: false,
        //         },
        //       });
        //     } else {
        //       console.log(4);
        //       this.$router.push({
        //         path: "/webGL",
        //         query: {
        //           releaseGuid: selectShape.data.releaseGuid,
        //           projectGuid: this.projectInfo.projectGuid,
        //           taskGuid: getTaskGuid(),
        //           isEdit: false,
        //         },
        //       });
        //       console.log(this.isCreator);
        //       try {
        //         await addPreviewCount(this.projectInfo.projectGuid);
        //       } catch (e) {
        //         console.log(e);
        //       }
        //     }
        //   }
        // } else {
        //   if (this.isCreator) {
        //     console.log(5);
        //     this.$router.push({
        //       path: "/webGL",
        //       query: {
        //         projectGuid: this.projectInfo.projectGuid,
        //         taskGuid: getTaskGuid(),
        //         isEdit: false,
        //       },
        //     });
        //   } else {
        //     console.log(6);
        //     this.$router.push({
        //       path: "/webGL",
        //       query: {
        //         releaseGuid: this.projectInfo.releaseGuid,
        //         projectGuid: this.projectInfo.projectGuid,
        //         taskGuid: getTaskGuid(),
        //         isEdit: false,
        //       },
        //     });
        //     console.log(this.isCreator);
        //     try {
        //       await addPreviewCount(this.projectInfo.projectGuid);
        //     } catch (e) {
        //       console.log(e);
        //     }
        //   }

        //   removeSelectedGuid();
        // }
        //旧逻辑
      } else if (ctrl === "4") {
        console.log("4");
      }
    },

    async setLike(liked) {
      try {
        await saveLikeProject(
          this.projectInfo.projectGuid,
          this.myProjectStatus.like
        );
        this.myProjectStatus.like = liked;
      } catch (e) {
        console.log(e);
      }
    },

    async setFavourate(favourate) {
      try {
        await saveFavourate(
          this.projectInfo.projectGuid,
          this.myProjectStatus.favourate
        );
        this.myProjectStatus.favourate = favourate;
      } catch (e) {
        console.log(e);
      }
    },

    async forward() {
      console.log(this.$route);
      console.log(process.env.VUE_APP_WORKFLOW_API);
      this.$refs.share.init(true);
      // await addForwardCount(this.projectInfo.projectGuid);
    },

    releaseClose(data) {
      this.releaseShow = false;
      if (data) {
        alert("released");
      }
    },

    backPrePage() {
      this.$router.go(-1);
    },
    // 头部下拉菜单
    handleCommand(command) {
      console.log(command);
      // this.$message("click on item " + command);
      // this.$refs.editStep.init(null);
      // this.$refs.editStep.setSwitch(true);
      if (command === "history") {
        this.$store.commit("SET_PROJECTINFO", false);
        //手动关闭步骤配置项
        // this.$refs.logicflow.closeStepEdit();
        this.$refs.editStep.init(false);
        this.$store.commit("SET_SHOWHISTORY", true);
        this.$store.commit("SET_SELECTSHAPE", false);
      } else if (command === "projectSetting") {
        console.log("打开项目详情");
        this.$store.commit("SET_PROJECTINFO", true);
        // this.$refs.logicflow.closeStepEdit();
        // this.$refs.editStep.init(false);
        this.$store.commit("SET_SHOWHISTORY", false);
        this.$store.commit("SET_SELECTSHAPE", false);
      } else if (command === "delete") {
        console.log("删除项目");
        // this.$refs.deleteProject.init(true, this.projectInfo);
        this.$confirm(
          // 将同时下架该项目,删除后无法恢复,确认删除该项目吗?
          this.$t("taskNewDatail.tip10"),
          // 删除项目
          this.$t("taskNewDatail.tip11"),
          {
            distinguishCancelAndClose: true,
            // 删除
            confirmButtonText: this.$t("taskNewDatail.delete"),
            // 取消
            cancelButtonText: this.$t("taskNewDatail.cancel"),
            customClass: "deleteBox",
          }
        )
          .then(async () => {
            try {
              await saveProject({
                projectGuid: this.projectInfo.projectGuid,
                isDeleted: true,
              });
              ElMessage.success({
                // 删除成功！
                message: this.$t("taskNewDatail.tip12"),
                type: "success",
              });
              this.$router.push("/myTasks?tab=self");
            } catch (e) {
              console.log(e);
            }
          })
          .catch((action) => {});
      }
    },
    //左侧任务列表搜索字符串
    searchTask() {
      console.log(this.searchStr);
      this.showList = this.filterDataList(
        this.projectInfo.tasks,
        this.searchStr
      );
    },
    filterDataList(dataList, searchStr) {
      let list = JSON.parse(JSON.stringify(dataList));
      let result = list.filter((value, index) => {
        return value.taskName.includes(searchStr);
      });
      return result;
    },
  },
};
</script>

<style scoped lang="scss">
.warps {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  .self_content {
    width: 520px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
    background: #fff;
    border-radius: 10px;
    .self_header {
      color: #4582ff;
      padding: 6px 30px 5px;
      /* padding: 20px; */
      text-align: left;
      font-size: 18px;
      .el-message-box__headerbtn {
        top: 20px;
      }
    }
    .self_body {
      padding: 30px 30px;
      text-align: left;
      color: #1f1f1f;
    }
    .self_footer {
      text-align: right;
      > button {
        width: 120px;
        height: 32px;
        // background: #fff;
        border: none;
        cursor: pointer;
        transition: all 0.3s;
        color: #fff;
        border-radius: 4px;
        margin: 0 5px;
        font-size: 14px;
      }
      .cancel {
        background: #a8b7ca;
      }
      .cancel:hover {
        background: #d3dce5;
      }
      .noSave {
        background: #ea6767;
      }
      .noSave:hover {
        background: #ff8e8e;
      }
      .save {
        background: #4582ff;
      }
      .save:hover {
        background: #8fb5ff;
      }
    }
    .el-message-box__content {
      padding: 15px 30px;
    }
    .el-message-box__btns {
      > button {
        width: 120px;
        height: 32px;
        border-radius: 4px;
        transition: background 0.1s;
        border: none;
      }
      > button:nth-child(1) {
        background: #a8b7ca;
        color: #ffffff;
      }
      > button:nth-child(1):hover {
        background: #b8c2d2;
      }
      > button:nth-child(2) {
        background: #ea6767;
        color: #ffffff;
      }
      > button:nth-child(2):hover {
        background: #ff8e8e;
      }
    }
  }
}

.taskDetailHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  color: #fff;
  background: #4582ff;
  > div {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .picture {
    width: 26rem;
    height: 3rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > i {
      cursor: pointer;
      margin-left: 2rem;
      font-weight: 700;
      font-size: 1.5rem;
    }
    > button {
      width: 2.2rem;
      height: 2.2rem;
      padding: 0.5rem 1.5rem;
      background: rgba(0, 0, 0, 0);
      cursor: pointer;
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.5s;
      border-radius: 3px;
      position: relative;
      > img:nth-child(1) {
        width: 2rem;
      }
      .saveWord {
        position: absolute;
        top: 0.6rem;
        right: 0.7rem;
        width: 2rem;
        color: #fff;
        font-size: 14px;
      }
    }
    .saveBtn {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 5.4rem;
      padding: 0;
    }
    > button:nth-child(1) {
      margin-left: 2rem;
    }
    > button:nth-child(2) {
      margin-left: 1rem;
      > img {
        cursor: pointer;
        width: 3rem;
      }
    }
    > button:hover {
      background: #5d92ff;
      border: 1px solid #80a9ff;
    }
    > button:nth-child(2)[disabled] {
      .saveWord {
        color: #ddd;
      }
      border: 1px solid rgba(0, 0, 0, 0) !important;
      background: rgba(0, 0, 0, 0) !important;
      > img {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
  .name {
    .nameStr {
      color: #fff;
      font-size: 0.95rem;
      cursor: pointer;
      line-height: 2.5rem;
    }
  }
  .other {
    width: 25rem;
    height: 3rem;
    display: flex;
    justify-content: right;
    align-content: center;
    > button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0);
      background: rgba(0, 0, 0, 0);
      // margin-right: 1rem;
      // width: 2rem;
      // height: 2rem;
      cursor: pointer;
      // padding: 0.5rem 1.5rem;
      text-align: center;
      line-height: 2rem;
      border-radius: 3px;
      font-size: 0.8rem;
      transition: all 0.5s;
      margin: 0 0.5rem;
      width: 2.2rem;
      height: 2.2rem;
      > img {
        width: 2rem;
      }
    }
    > .config {
      width: 8rem;
      background: #5d92ff;
      height: 2.2rem;
      color: #fff;
      font-size: 0.95rem;
      border: 1px solid #80a9ff;
      margin-right: 1rem;
    }
    > .smallBtn {
      background: #5d92ff;
      height: 2.2rem;
      color: #fff;
      font-size: 0.95rem;
      border: 1px solid #80a9ff;
      margin-right: 1rem;
    }

    > .ownBtn {
      width: 7.5rem;
      > img {
        width: 1.5rem;
        margin-right: 0.5rem;
      }
    }
    > .ownActive {
      width: 6.5rem;
      background: #4070d1 !important;
      color: #d9e5ff !important;
      border: none !important;
    }
    > button:hover {
      background: #5d92ff;
      border: 1px solid #80a9ff;
    }

    .thumbnail {
      background: url("~@/assets/task-detail/thumbnail.svg") no-repeat center;
      background-size: cover;
      width: 100%;
      height: 100%;

      &.selected {
        background: url("~@/assets/task-detail/thumbnail-selected.svg")
          no-repeat center;
      }
    }

    .star {
      background: url("~@/assets/task-detail/star.svg") no-repeat center;
      background-size: cover;
      width: 100%;
      height: 100%;

      &.selected {
        background: url("~@/assets/task-detail/star-selected.svg") no-repeat
          center;
      }
    }
  }
}
.mainBody {
  display: flex;
  justify-content: space-between;
  position: relative;
  // .others {
  //   width: 28rem;
  //   background: #fff;
  //   height: 100%;
  // }

  .workspace {
    position: relative;
    overflow: hidden;
    .logicflow {
      position: absolute;
      z-index: 1;
      // top: 0;
      // left: 0;
      // right: 0;
      // bottom: 0;
      height: 100%;
    }
    .logicflowActive {
      z-index: 5;
    }
    .template2D {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .template2DActive {
      z-index: 5;
    }
    .toggleBtn {
      cursor: pointer;
      position: absolute;
      z-index: 6;
      right: 0rem;
      bottom: 1rem;
      width: 4.5rem;
      height: 3.5rem;
      background: #333333;
      // border-radius: 0.3rem;
      border-top-left-radius: 1.75rem;
      border-bottom-left-radius: 1.75rem;
      background: #4582ff;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .sortIcon {
        width: 100%;
        color: #fff;
        font-size: 1.75rem;
        font-weight: bold;

        transform: rotateZ(90deg);
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      > img {
        // width: 100%;
        // height: 100%;
        width: 2.5rem;
        margin-left: 1rem;
      }
    }
  }

  .others {
    // position: absolute;
    top: 0px;
    right: 0px;
    width: 28rem;
    background: #fff;
    // padding: 1rem 3rem 1rem 3rem;
    height: calc(100vh - 3rem);
    box-sizing: border-box;
    overflow-y: auto;
    border-left: 1px solid #ccc;
  }
  .others::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  .others::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  .others::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    background-clip: padding-box;
    border: solid transparent;
    border-radius: 10px;
  }

  .others::-webkit-scrollbar-track {
    background-clip: padding-box;
    border: solid transparent;
    border-width: 1px;
  }
  .taskList {
    width: 15rem;
    background: #fff;
    height: calc(100vh - 3rem);
    border-right: 1px solid #ccc;
    // display: flex;
    // justify-content: space-around;
    // align-items: center;

    .taskListHeader {
      width: 100%;
      height: 3.5rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // border: 1px solid #ccc;
      border-right: none;
      box-sizing: border-box;
      border-bottom: 1px solid #ccc;
      .task {
        font-size: 0.8rem;
        width: 20%;
      }
      .searchTask {
        width: 50%;
      }
      .createTask {
        width: 20%;
        > button {
          width: 1.8rem;
          height: 1.8rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 0.8rem;
          > span {
            display: block;
            width: 1.2rem;
            height: 1.2rem;
            > img {
              display: block;
              width: 1.2rem;
              height: 1.2rem;
            }
          }
        }
      }
    }
    .taskListBody {
      width: 100%;
      // padding-top: 0.5rem;
      padding: 0.5rem;
      box-sizing: border-box;
      .taskItem {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 3rem;
        padding: 0 1rem;
        border-radius: 5px;
        transition: all 0.3s;
        margin-top: 0.25rem;
        .taskLeft {
          line-height: 3rem;
          display: flex;
          height: 3rem;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          // > div:nth-child(1) {
          //   width: 1rem;
          //   height: 1rem;
          //   background: #333;
          // }
          > div:nth-child(1) {
            color: #224b77;
            text-align: left;
            // width: 6rem;
            width: 8rem;
            height: 3rem;
            padding-left: 0.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 0.85rem;
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            > .taskEditInput {
              position: absolute;
              width: 8rem;
            }
          }
        }
        .taskRight {
          transform: rotateZ(90deg);
          color: #ccc;
        }
      }
      .taskItem:hover {
        background: #ecf2ff;
      }
      .active {
        background: #ecf2ff;
      }
      .taskActive {
        background: #f2f4f7;
      }
    }
  }
  .taskChartComp {
    width: calc(100% - 15rem);
  }
}

.taskCtrlListWarp {
  padding: 0px 10px 0px 10px;
  width: 7rem;
  // line-height: 2rem;
  .btnWarp {
    padding: 0;
    button {
      text-align: center;
      // padding: 0 20px;
      display: block;
      outline: none;
      border: none;
      width: 100%;
      background: #fff;
      text-align: left;
      color: #274e78;
      font-size: 0.8rem;
      border-radius: 3px;
      cursor: pointer;
      line-height: 36px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      > span {
        font-size: 0.9rem;
        color: #8fb5ff;
      }
      > img {
        width: 1.5rem;
        line-height: 2rem;
        margin-right: 0.5rem;
      }
    }
    button[disabled] {
      // background: #ccc!important;
      color: #ccc !important;
      cursor: not-allowed;
      filter: grayscale(1);
    }
    button:hover {
      background: #ecf2ff;
    }
  }

  .btnWarp:last-child {
    button {
      background: #fff;
      border-radius: 4px 4px 4px 4px;
      color: #f94a4a;
    }
    button:hover {
      background: #ffecec;
    }
  }
}
#deleteMission,
#history,
#taskSet {
  margin: 0 0.5rem;
  border-radius: 3px;
}
#deleteMission {
  color: #f94a4a;
}
#deleteMission:hover {
  color: #f94a4a;
  background: #ffecec;
}
#history:hover {
  background: #ecf2ff;
  color: #274e78;
}
#taskSet:hover {
  background: #ecf2ff;
  color: #274e78;
}
.selfFillBorder {
  stroke-dasharray: 0 !important;
}
</style>
<style langs="scope">
.noHeader {
  height: 100vh;
}
:deep(.el-select-dropdown__item) {
  padding: 5px;
  margin: 0 0.5rem;
  border-radius: 3px;
}
</style>
