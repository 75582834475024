<template>
  <div class="inputTemplateWrap" id="inputWrap">
    <div
      class="templatesWrap"
      id="templatesWrap1"
      :style="{ width: maxWidth + 'px', height: maxHeight + 'px' }"
    >
      <div class="inputTemplate">
        <div class="prevWrap">
          <div class="btnWrap">
            <button class="backBtn" @click="backTop">
              <img src="@/assets/task-detail/back.png" alt="" />
            </button>
            <button :class="showMap ? 'map active' : 'map'" @click="toggleMap">
              <img src="@/assets/task-detail/showMap.png" alt="" />
            </button>
            <button
              v-if="isEdit"
              class="saveBtn"
              @click="saveTemplateInfo"
              :disabled="!stepNeedSave"
            >
              <img src="@/assets/task-detail/save.png" alt="" />
              <!-- <div class="saveWord">{{ $t("taskNewDatail.save") }}</div> -->
            </button>
            <div class="taskName">
              {{ taskName }}
            </div>
          </div>
          <PrevNext
            :showPrev="showPrev"
            :showNext="showNext"
            :relationship="relationship"
            @nextNode="nextNode"
            @prevNode="prevNode"
          />
          <div class="rightBtnsWrap"></div>
        </div>

        <div class="numCtrls">
          <!-- <div class="textInput">
            <div class="text">需要输入的多媒体数量</div>
            <div class="number">
              <button class="btn" @click="reduceMediaNum">-</button>
              <el-input-number
                v-model="mediaNum"
                :controls="false"
                size="small"
                :min="0"
                :max="3"
                :step-strictly="true"
              ></el-input-number>
              <button class="btn" @click="addMediaNum">+</button>
            </div>
          </div>
          <div class="textInput text">
            <div class="text">需要输入的文本数量</div>
            <div class="number">
              <button class="btn" @click="reduceTextNum">-</button>
              <el-input-number
                v-model="textNum"
                :controls="false"
                size="small"
                :min="0"
                :max="4"
                :step-strictly="true"
              ></el-input-number>
              <button class="btn" @click="addTextNum">+</button>
            </div>
          </div> -->
          <el-dropdown trigger="click">
            <span class="exploreDropdownTitle">
              <!-- {{ dropdownShowInfo ? dropdownShowInfo.label : ""
              }}<i class="el-icon-arrow-down el-icon--right"></i> -->
              <button class="addMedia" v-if="isEdit">
                <img src="@/assets/task-detail/addBtn.png" alt="" />
              </button>
            </span>
            <template #dropdown>
              <div class="taskCtrlListWarp">
                <el-dropdown-menu>
                  <el-dropdown-item
                    class="btnWarp"
                    @click.prevent="selectDrop(item, index)"
                    v-for="(item, index) in selectList"
                    :key="index"
                  >
                    <button
                      :disabled="
                        item.value === 1 || item.value === 2
                          ? dataList.medias.length >= 3
                          : dataList.texts.length >= 4
                      "
                    >
                      <img
                        v-if="
                          ((item.value === 1 || item.value === 2) &&
                            dataList.medias.length < 3) ||
                            (item.value === 99 && dataList.texts.length < 4)
                        "
                        :src="item.icon"
                        alt=""
                      />
                      <img v-else :src="item.disabledIcon" alt="" />

                      {{ item.label }}
                    </button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </div>
            </template>
          </el-dropdown>
        </div>
        <div
          class="medias"
          v-if="mediaNum > 0"
          :style="{
            height: textStyle,
          }"
        >
          <div
            class="mediaItem"
            v-for="(item, index) in dataList.medias"
            :key="index"
          >
            <div class="input">
              <!-- 在此输入内容标题 -->
              <el-input
                :placeholder="$t('webGL.tip6')"
                v-model="item.title"
                @input="mediaTitleChange"
                :disabled="!isEdit"
                maxlength="10"
                show-word-limit
                class="inputTitle"
              ></el-input>
            </div>
            <div class="picture">
              <!-- <img v-if="item.imgUrl" :src="item.imgUrl" alt="" /> -->
              <img
                class="img"
                v-if="(item.fileType && item.fileType == 1) || !item.fileType"
                :style="{ maxWidth: mediaNum * 20 + '%' }"
                src="@/assets/task-detail/camera.png"
                alt=""
              />
              <img
                class="img"
                v-if="item.fileType && item.fileType == 2"
                :style="{ maxWidth: mediaNum * 20 + '%' }"
                src="@/assets/task-detail/video.png"
                alt=""
              />
              <div
                v-if="isEdit"
                class="deleteImgWrap"
                @click="deleteMediaItem(item, index)"
              >
                <!-- <img
                  class="deleteImg"
                  @click="deleteMediaItem(item, index)"
                  src="@/assets/task-detail/delete.png"
                  alt=""
                /> -->
              </div>
              <!-- <span v-if="item.fileType && item.fileType == 2">视频</span> -->
            </div>
          </div>
        </div>
        <div
          class="texts"
          v-if="textNum > 0"
          :style="{
            height: mediaNum > 0 ? 'auto' : `calc(100% - 4rem - 1rem)`,
          }"
        >
          <div :class="mediaNum < 1 ? 'textWraps active' : 'textWraps'">
            <div
              class="textItem"
              v-for="(item, index) in dataList.texts"
              :key="index"
            >
              <div class="title">
                <el-input
                  :placeholder="$t('webGL.tip6')"
                  v-model="item.title"
                  :disabled="!isEdit"
                  @input="textTitleChange"
                  maxlength="15"
                  show-word-limit
                  class="inputTitle"
                ></el-input>
              </div>
              <div class="content">
                <!-- {{ item.content }} -->
                <div class="imgWrap">
                  <!-- <img src="@/assets/task-detail/edit.png" alt="" /> -->
                  <!-- 此处为使用者填写 -->
                  {{ $t("webGL.tip7") }}
                </div>
                <!-- <img
                  class="deleteImg"
                  @click="deleteTextItem(item, index)"
                  src="@/assets/task-detail/delete.png"
                  alt=""
                /> -->
                <div
                  v-if="isEdit"
                  class="deleteImgWrap"
                  @click="deleteTextItem(item, index)"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import { getSelectedGuid } from "@/views/taskDetail/components/taskChart/setSelectShape.js";
import { updateStepInfo, getStepInfo } from "@/api/workflow";
import PrevNext from "./prevNext.vue";
export default {
  components: { PrevNext },
  props: [
    "taskGuid",
    "projectGuid",
    "shapeItem",
    "relationship",
    "showMap",
    "taskInfo",
  ],
  data() {
    return {
      isEdit: false,
      stepNeedSave: false,
      tempData: null,
      textNum: 0,
      mediaNum: 0,
      maxWidth: 1280,
      maxHeight: 720,
      dataList: {
        type: 5,
        medias: [],
        texts: [],
      },
      showPrev: false,
      showNext: false,
      // relationship: {
      //   prev: [],
      //   next: [],
      // },
      selectList: [
        {
          // 图片
          label: this.$t("webGL.tip3"),
          value: 1,
          icon: require("@/assets/task-detail/photoIcon.png"),
          disabledIcon: require("@/assets/task-detail/disabledPhotoIcon.png"),
        },
        {
          // 视频
          label: this.$t("webGL.tip4"),
          value: 2,
          icon: require("@/assets/task-detail/videoIcon.png"),
          disabledIcon: require("@/assets/task-detail/disabledVideoIcon.png"),
        },
        {
          // 文字
          label: this.$t("webGL.tip5"),
          value: 99,
          icon: require("@/assets/task-detail/textIcon.png"),
          disabledIcon: require("@/assets/task-detail/disabledTextIcon.png"),
        },
      ],
    };
  },
  computed: {
    taskName() {
      return this.taskInfo?.taskName;
    },
    stepName() {
      return this.shapeItem?.data?.properties?.stepInfo?.stepName;
    },
    textStyle2() {
      if (this.mediaNum < 1) {
        return `height:100%,marginTop:1rem`;
      } else {
        return ``;
      }
    },
    textStyle() {
      if (this.textNum < 1) {
        return "calc(100% - 4rem - 6%)";
      } else {
        return `calc(100% - 4rem - 2rem - 10px - ${this.textNum * 50}px - 6%)`;
      }
    },
  },
  created() {
    console.log(this.relationship);
  },
  mounted() {
    this.resize();
    // let dom = document.getElementById("defaultTemplateWrap");
    window.addEventListener("resize", this.resize);
    if (this.$route.query.isEdit === "false") {
      this.isEdit = false;
    } else {
      this.isEdit = true;
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.resize);
  },
  watch: {
    // textStyle(newVal,oldVal){
    //   console.log(newVal,oldVal);
    // },
  },
  methods: {
    backTop() {
      console.log(this.stepNeedSave);
      if (this.stepNeedSave) {
        console.log(this.stepNeedSave);
        // 检测到流程图有未保存的内容,是否保存? 提示
        this.$confirm(
          this.$t("webGL.tip1"),
          this.$t("webGL.tip2"),

          {
            closeOnClickModal: false,
            distinguishCancelAndClose: true,
            // 确定
            confirmButtonText: this.$t("webGL.save"),
            // 取消
            cancelButtonText: this.$t("webGL.noSave"),
            // type: "warning",
          }
        )
          .then(async () => {
            await this.saveTemplateInfo();
            this.$emit("needSave", false);
            this.$emit("backRouter");
          })
          .catch((action) => {
            if (action === "cancel") {
              this.$emit("backRouter");
              this.$emit("needSave", false);
            } else if (action === "close") {
              return;
            }
          });
      } else {
        this.$emit("backRouter");
      }
    },
    toggleMap() {
      this.$emit("toggleMap", !this.showMap);
    },
    nextNode(stepGuid) {
      console.log(this.stepNeedSave);
      if (this.stepNeedSave) {
        console.log(this.stepNeedSave);
        // 检测到流程图有未保存的内容,是否保存? 提示
        this.$confirm(
          this.$t("webGL.tip1"),
          this.$t("webGL.tip2"),

          {
            closeOnClickModal: false,
            distinguishCancelAndClose: true,
            // 确定
            confirmButtonText: this.$t("webGL.save"),
            // 取消
            cancelButtonText: this.$t("webGL.noSave"),
            // type: "warning",
          }
        )
          .then(async () => {
            await this.saveTemplateInfo();
            this.$emit("nextNode", stepGuid);
            this.$emit("needSave", false);
          })
          .catch((action) => {
            if (action === "cancel") {
              this.$emit("nextNode", stepGuid);
              this.$emit("needSave", false);
            } else if (action === "close") {
              return;
            }
          });
      } else {
        this.$emit("nextNode", stepGuid);
      }
    },
    prevNode(stepGuid) {
      if (this.stepNeedSave) {
        console.log(this.stepNeedSave);
        // 检测到流程图有未保存的内容,是否保存? 提示
        this.$confirm(
          this.$t("webGL.tip1"),
          this.$t("webGL.tip2"),

          {
            closeOnClickModal: false,
            distinguishCancelAndClose: true,
            // 确定
            confirmButtonText: this.$t("webGL.save"),
            // 取消
            cancelButtonText: this.$t("webGL.noSave"),
            // type: "warning",
          }
        )
          .then(async () => {
            await this.saveTemplateInfo();
            this.$emit("prevNode", stepGuid);
            this.$emit("needSave", false);
          })
          .catch((action) => {
            if (action === "cancel") {
              this.$emit("nextNode", stepGuid);
              this.$emit("needSave", false);
            } else if (action === "close") {
              return;
            }
          });
      } else {
        this.$emit("prevNode", stepGuid);
      }
    },
    deleteTextItem(item, index) {
      if (
        this.dataList.medias.length === 0 &&
        this.dataList.texts.length <= 1
      ) {
        ElMessage.error(this.$t("webGL.tip8"));
        return;
      } else {
        this.dataList.texts.splice(index, 1);
        this.textNum--;
        this.needSave(true);
      }
    },
    deleteMediaItem(item, index) {
      if (this.dataList.texts.length === 0 && this.mediaNum <= 1) {
        ElMessage.error(this.$t("webGL.tip8"));
        return;
      } else {
        this.dataList.medias.splice(index, 1);
        this.mediaNum--;
        this.needSave(true);
      }
    },
    async initData(stepInfos) {
      try {
        this.tempData = stepInfos;
        let stepInfo = stepInfos;

        console.log(stepInfo);
        console.log(JSON.parse(stepInfo.stepObjectJson));
        this.dataList = JSON.parse(stepInfo.stepObjectJson);
        this.textNum = this.dataList.texts.length;
        this.mediaNum = this.dataList.medias.length;
        // this.$nextTick(() => {
        //   this.$emit("initDone");
        // });
      } catch (e) {
        this.dataList = {
          type: 5,
          medias: [],
          texts: [],
        };
        this.textNum = 0;
        this.mediaNum = 0;
        // this.$nextTick(() => {
        //   this.$emit("initDone");
        // });
        console.log(e);
      }
      this.needSave(false);
    },
    //保存模板内容
    async saveTemplateInfo() {
      console.log(this.dataList);
      console.log(this.shapeItem);
      console.log(this.shapeItem?.data?.properties?.stepInfo);
      console.log(getSelectedGuid());
      let updateOption = {
        taskGuid: this.taskInfo.taskGuid,
        stepGuid:
          this.shapeItem?.data?.properties?.stepInfo?.sourceStepGuid ||
          getSelectedGuid(),
        panelType: this.dataList.type,
        appearType: 0,
        index: 0,
        delaySeconds: 0,
        stepObjectJson: JSON.stringify(this.dataList),
      };
      if (
        this.shapeItem &&
        this.shapeItem.data &&
        this.shapeItem.data.type === "InputShape"
      ) {
        updateOption.stepGuid = this.shapeItem.data.properties.stepInfo.stepGuid;
      }
      try {
        let res = await updateStepInfo(updateOption);
        this.needSave(false);
      } catch (e) {
        this.needSave(true);
      }

      // console.log(res);
    },
    mediaTitleChange() {
      this.needSave(true);
    },
    textTitleChange() {
      this.needSave(true);
    },
    needSave(bool) {
      // console.log("needSave", bool);
      this.stepNeedSave = bool;
      this.$emit("stepConfigInfoNeedSaveStatus", bool);
    },
    resize() {
      let dom1 = document.getElementById("inputWrap");
      //用外边框的宽计算高
      //比例
      let proportion = 0.5625;
      let shouldHeight = dom1.offsetWidth * proportion;
      if (shouldHeight > dom1.offsetHeight) {
        this.maxHeight = dom1.offsetHeight;
        this.maxWidth = dom1.offsetHeight / proportion;
      } else {
        this.maxWidth = dom1.offsetWidth;
        this.maxHeight = dom1.offsetWidth * proportion;
      }
    },
    selectDrop(item, index) {
      console.log(item, index);
      if (item.value === 1) {
        this.addMediaNum(1);
      } else if (item.value === 2) {
        this.addMediaNum(2);
      } else if (item.value === 99) {
        this.addTextNum();
      }
    },
    //点击文本加号
    addTextNum() {
      if (this.textNum >= 4) {
        return;
      }
      let options = {
        index: this.textNum,
        title: "",
        content: "",
        description: "",
      };
      this.dataList.texts.push(options);
      this.textNum++;
      this.needSave(true);
      // console.log(this.dataList);
    },
    //点击文本减号
    reduceTextNum() {
      if (this.textNum <= 0) {
        return;
      }
      this.textNum--;
      this.dataList.texts.pop();
      this.needSave(true);
    },
    //点击多媒体加号
    addMediaNum(fileType) {
      if (this.mediaNum >= 3) {
        return;
      }
      let options = {
        index: this.mediaNum,
        title: "",
        description: "",
        path: null,
        imgUrl: null,
        fileType,
      };
      this.dataList.medias.push(options);
      this.mediaNum++;
      this.needSave(true);
      // console.log(this.dataList);
    },
    //点击多媒体减号
    reduceMediaNum() {
      if (this.mediaNum <= 0) {
        return;
      }
      this.mediaNum--;
      this.dataList.medias.pop();
      this.needSave(true);
    },
    handleChange(data) {
      console.log(data);
    },
  },
};
</script>

<style scoped lang="scss">
.inputTemplateWrap {
  width: 98%;
  height: 98%;
  // background: #000;
  // padding: 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  .prevWrap {
    width: 100%;
    height: 6%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #4582ff;
    > div {
      height: 100%;
      width: 16rem;
    }
    .btnWrap {
      width: 16rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 0.95rem;
      > button {
        border-radius: 5px;
        margin-left: 1rem;
        cursor: pointer;
        width: 2.5rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: none;
        border: 1px solid #4482ff;
        outline: none;
        background: #4482ff;
        color: #fff;
        transition: all 0.3s;
        > img {
          width: 2rem;
          margin: 0;
          // height: 100%;
        }
      }
      > button:hover {
        background: #5d92ff;
        border: 1px solid #80a9ff;
      }
      > .active {
        background: #5d92ff;
        border: 1px solid #80a9ff;
      }
      .taskName {
        color: #fff;
        margin-left: 1rem;
      }
      .map {
        //   width: 3rem;
        > img {
          width: 2.5rem;
        }
      }
      .saveBtn {
        // width: 100%;
        > img {
          width: 2.8rem;
        }
      }
      .saveBtn[disabled] {
        color: #ddd;
        border: 1px solid rgba(0, 0, 0, 0) !important;
        background: rgba(0, 0, 0, 0) !important;
        > img {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }
    .rightBtnsWrap {
      width: 16rem;
      height: 100%;
    }
  }
  .templatesWrap {
    width: 100%;
    position: relative;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    .inputTemplate {
      // padding: 1.5rem 2.5rem;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      border-radius: 10px;
      box-sizing: border-box;
      overflow: hidden;

      .numCtrls {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background: #f1f5fd;
        width: 100%;
        height: 4rem;
        padding: 0 1rem;
        box-sizing: border-box;
        .textInput {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .text {
            color: #224b77;
            font-size: 1.2rem;
          }
          .number {
            margin-left: 1.2rem;
            width: 6.5rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .btn {
              width: 26px;
              height: 26px;
              outline: none;
              border: none;
              min-width: auto;
              min-height: auto;
              background: #deb573;
              color: #fff;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 16px;
              padding: 0;
              cursor: pointer;
            }
            .btn:hover {
              background: #deb573;
            }
            :deep(.el-input-number) {
              width: 2rem;
              margin: 0 0.5rem;
              .el-input {
                .el-input__inner {
                  padding: 0 5px;
                }
              }
            }
          }
        }
        > .text {
          margin-left: 1.5rem;
        }
      }

      .medias {
        width: 100%;

        display: flex;
        justify-content: space-around;
        align-items: center;
        .mediaItem {
          width: 100%;
          height: 100%;
          padding: 1rem;
          box-sizing: border-box;
          .input {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .deleteImg {
              width: 2.5rem;
              cursor: pointer;
            }
            :deep(.inputTitle) {
              > input[disabled] {
                color: #333;
              }
            }
          }
          .picture {
            width: 100%;
            height: calc(100% - 40px - 1rem);
            background: #f1f5f9;
            border-radius: 10px;
            margin-top: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .img {
              width: 60%;
              max-width: 350px;
            }
            .deleteImgWrap {
              // padding: 5px 20px;
              background: #e0e5ea url("~@/assets/task-detail/delete.png")
                no-repeat;
              background-size: 56%;
              background-position: center center;
              transition: all 0.3s;
              position: absolute;
              right: 0.5rem;
              bottom: 0.5rem;
              width: 3.2rem;
              height: 2.4rem;
              border-radius: 4px;
              cursor: pointer;
              .deleteImg {
                width: 100%;
              }
            }
            .deleteImgWrap:hover {
              background: #f94a4a url("~@/assets/task-detail/deleteWrite.png")
                no-repeat;
              background-size: 56%;
              background-position: center center;
            }
          }
        }
      }
      .texts {
        width: 100%;
        padding: 0rem 1rem 1rem;
        box-sizing: border-box;
        .textWraps {
          border-radius: 10px;
          // background: #dce2e8;
          padding: 0.5rem 0.5rem calc(0.5rem + 10px);
          box-sizing: border-box;
          .textItem {
            height: 40px;
            background: #f1f5f9;
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title {
              width: 32%;
              min-width: 280px;
              height: 100%;
              :deep(.inputTitle) {
                > input[disabled] {
                  color: #333;
                }
              }
            }
            .content {
              width: 79%;
              height: 100%;
              text-align: left;
              line-height: 40px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              > .imgWrap {
                width: 11.5rem;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #bfcede;
                > img {
                  width: 100%;
                }
              }
              .deleteImg {
                width: 2.2rem;
                cursor: pointer;
              }
              .deleteImgWrap {
                // padding: 5px 20px;
                background: #e0e5ea url("~@/assets/task-detail/delete.png")
                  no-repeat;
                background-size: 56%;
                background-position: center center;
                transition: all 0.3s;
                position: absolute;
                right: 2rem;
                width: 3rem;
                height: 2rem;
                border-radius: 4px;
                cursor: pointer;
                .deleteImg {
                  width: 100%;
                }
              }
              .deleteImgWrap:hover {
                background: #f94a4a url("~@/assets/task-detail/deleteWrite.png")
                  no-repeat;
                background-size: 56%;
                background-position: center center;
              }
            }
          }
        }
        .active {
          height: 100%;
          margin-top: 1rem;
        }
      }
    }
  }
}
.taskCtrlListWarp {
  padding: 0px 10px 0px 10px;
  // width: 10rem;
  line-height: 2rem;
  .btnWarp {
    padding: 0;
    border-radius: 5px;
    overflow: hidden;
    button {
      padding: 0.3rem 0.8rem;
      display: block;
      outline: none;
      border: none;
      width: 100%;
      background: #fff;
      text-align: left;
      color: #274e78;
      font-size: 0.8rem;
      cursor: pointer;
      line-height: 1.5rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      > span {
        font-size: 0.9rem;
        color: #8fb5ff;
      }
      > img {
        width: 1.3rem;
        line-height: 2rem;
        margin-right: 0.5rem;
      }
    }
    button[disabled] {
      // background: #ccc!important;
      color: #ccc !important;
      cursor: not-allowed;
    }
    button:hover {
      // color: #4582ff;
      background: #f5f7fa;
    }
  }
}
.exploreDropdownTitle {
  > button {
    border: none;
    outline: none;
    cursor: pointer;
    padding: 18px 20px;
    background: #224b77;
    border-radius: 5px;
    color: #fff;
    width: 4rem;
    height: 2.2rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 100%;
    }
  }
}
</style>
