<template>
  <!-- 上传素材 -->
  <div id="uploadAssetId">
    <el-dialog
      ref="upload-asset"
      :title="$t('uploadAsset.title')"
      width="600px"
      heigt="500px"
      v-model="showSwitch"
      custom-class="upload-asset"
      :show-close="true"
      :destroy-on-close="true"
      :loading="uploading"
      @open="open()"
      @close="close()"
    >
      <!-- <i class="el-icon-close close-btn" @click="cancel()" /> -->

      <el-form
        ref="editForm"
        :model="fileItem"
        :rules="rules"
        label-position="top"
      >
        <!-- <el-form-item prop="shareLevel">
        <div class="item">
          <el-select
            v-if="fileItem.shareLevel == 2"
            v-model="fileItem.projectGuid"
            class="project"
            :disabled="projectGuid != null"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in projects"
              :key="item.projectGuid"
              :label="item.projectName"
              :value="item.projectGuid"
            />
          </el-select>
        </div>
      </el-form-item> -->
        <!-- <el-form-item prop="assetName">
          <div class="title">
            <span class="need">*</span>
            素材名称
          </div>
          <div class="item">
            <el-input
              v-model="fileItem.assetName"
              placeholder="请输入素材名称"
            />
          </div>
        </el-form-item> -->
        <div id="testCanvas"></div>
        <el-form-item
          class="main-item"
          prop="fileToUpload"
          v-if="!fileItem.fileToUpload"
        >
          <!-- .上传文件 文件大小不超过50M -->
          <div class="title">
            <div>
              <div class="uploadWarp">
                <div>
                  <p>
                    <span class="need">*</span>
                    <!-- 上传文件 -->
                    <span>{{ $t("uploadAsset.uploadGile") }}</span>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :show-after="500"
                      :hide-after="500"
                      placement="bottom-start"
                      popper-class="myTooltip"
                    >
                      <template #content>
                        <p>{{ $t("uploadAsset.tip4") }}</p>
                        <p>{{ $t("uploadAsset.tip5") }}</p>
                      </template>
                      <span class="helpWrap">
                        <img src="@/assets/task-detail/help.png" alt="" />
                      </span>
                    </el-tooltip>
                  </p>
                  <!-- <p>文件大小不超过50M</p> -->
                  <!-- 文件名 -->
                  <p v-if="fileItem.fileToUpload">
                    {{ $t("uploadAsset.fileName") }}:
                    {{ fileItem.fileToUpload.name }}
                  </p>
                </div>
                <!-- <div>
                  <el-upload
                    action=""
                    accept=".fbx,.obj,.glb,.gltf,.stl,video/mp4,image/*,application/pdf,.FBX,.GLB,.GLTF,.STL,VIDEO/MP4,APPLICATION/PDF"
                    :show-file-list="false"
                    :multiple="false"
                    :auto-upload="false"
                    :on-change="onUploadChange"
                  >
                    <img src="@/assets/task-detail/addFloder.png" alt="" />
                  </el-upload>
                </div> -->
              </div>
              <div class="uploadBtnWarp">
                <el-upload
                  action=""
                  :accept="projectInfo?.editType ? accept2D : accept3D"
                  :show-file-list="false"
                  :multiple="false"
                  :auto-upload="false"
                  :on-change="onUploadChange"
                >
                  <!-- 上传文件 -->
                  <el-button type="warning" size="small">{{
                    $t("uploadAsset.uploadGile")
                  }}</el-button>
                </el-upload>
              </div>
            </div>
          </div>
          <div
            class="item imageWarp shortHeight"
            :class="imagePreview ? 'image-item' : ''"
            style="display: none"
          >
            <!-- <el-button >选择文件</el-button> -->
            <!-- 截图 -->
            <el-button @click="cutPng">{{
              $t("uploadAsset.getCover")
            }}</el-button>
            <!-- <div v-if="fileItem.fileToUpload">
              {{ fileItem.fileToUpload.name }}
            </div> -->
            <!-- 选择文件 -->
            <!-- <el-upload
              v-if="!fileItem.fileToUpload"
              class="upload-btn targetUrl"
              action=""
              accept=".fbx,.obj,.glb,.gltf,.stl,video/mp4,image/*,application/pdf,.FBX,.GLB,.GLTF,.STL,VIDEO/MP4,APPLICATION/PDF"
              :show-file-list="false"
              :multiple="false"
              :auto-upload="false"
              :on-change="onUploadChange"
            >
              <el-button class="btn">选择文件</el-button>
            </el-upload> -->
            <!-- 更换文件 -->
            <!-- <el-upload
              v-else
              class="change-btn targetUrl"
              action=""
              accept=".fbx,.obj,.glb,.gltf,.stl,video/mp4,image/*,application/pdf,.FBX,.GLB,.GLTF,.STL,VIDEO/MP4,APPLICATION/PDF"
              :show-file-list="false"
              :multiple="false"
              :auto-upload="false"
              :on-change="onUploadChange"
            >
              更换文件
            </el-upload> -->
          </div>
        </el-form-item>
        <el-form-item prop="assetName" v-if="fileItem.fileToUpload">
          <div class="title">
            <!-- 上传文件 -->
            <div>
              <span class="need">*</span> {{ $t("uploadAsset.uploadGile") }}
            </div>
            <div>
              <el-upload
                action=""
                :accept="projectInfo?.editType ? accept2D : accept3D"
                :show-file-list="false"
                :multiple="false"
                :auto-upload="false"
                :on-change="onUploadChange"
              >
                <!-- 更换文件 -->
                {{ $t("uploadAsset.changeFile") }}
              </el-upload>
            </div>
          </div>
          <div class="item">
            <!-- 文件名称 -->
            <el-input
              v-model="fileItem.assetName"
              :placeholder="$t('uploadAsset.fileName')"
            />
          </div>
        </el-form-item>
        <el-form-item prop="thumbnail">
          <!-- 上传缩略图  >文件格式.jpg，尺寸256x256像素, 大小不得超过50K-->
          <div class="title">
            <div>
              <div class="uploadWarp">
                <div>
                  <p>
                    <span class="need">*</span>
                    <!-- 上传缩略图 -->
                    <span>{{ $t("uploadAsset.uploadThubnail") }}</span>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :show-after="500"
                      :hide-after="500"
                      placement="bottom-start"
                      popper-class="myTooltip"
                    >
                      <template #content>
                        <p>{{ $t("uploadAsset.tip6") }}</p>
                        <p>{{ $t("uploadAsset.tip7") }}</p>
                      </template>
                      <span class="helpWrap">
                        <img src="@/assets/task-detail/help.png" alt="" />
                      </span>
                    </el-tooltip>
                  </p>
                  <!-- <p>文件格式.jpg，尺寸256x256像素, 大小不得超过50K</p> -->
                </div>
                <!-- <div>
                  <el-upload
                    action=""
                    accept="image/*,IMAGE/*"
                    :show-file-list="false"
                    :multiple="false"
                    :auto-upload="false"
                    :on-change="onUploadThumbnail"
                  >
                    <img src="@/assets/task-detail/addFloder.png" alt="" />
                  </el-upload>
                </div> -->
              </div>
            </div>
            <div>
              <el-upload
                action=""
                accept="image/*,IMAGE/*"
                :show-file-list="false"
                :multiple="false"
                :auto-upload="false"
                :on-change="onUploadThumbnail"
              >
                <img
                  v-if="!fileItem.thumbnail"
                  src="@/assets/task-detail/addFloder.png"
                  alt=""
                />
                <!-- 更换缩略图 -->
                <span v-if="fileItem.thumbnail">{{
                  $t("uploadAsset.changeThubnail")
                }}</span>
              </el-upload>
            </div>
            <!-- <span class="need">*</span>上传缩略图<span class="note"
              >文件格式.jpg，尺寸256x256像素, 大小不得超过50K</span
            > -->
          </div>
          <div class="item imageWarp image-item">
            <div class="placehodler" v-if="!fileItem.thumbnail">
              <div class="image-slot">
                <img src="@/assets/task-detail/defaultPicture.png" alt="" />
                <!-- 点击上传 -->
                <p class="clickUpload">
                  <el-upload
                    class="change-btn"
                    action=""
                    accept=".jpg, .png, .jpeg, .mp4, .rmvb, .rm, .asf, .divx, .mpg, .mpeg, .wmv, .mkv, .vob"
                    :show-file-list="false"
                    :multiple="false"
                    :auto-upload="false"
                    :on-change="onUploadThumbnail"
                  >
                    <span>{{ $t("projectInfo.clickUpload") }}</span>
                  </el-upload>
                </p>
              </div>
            </div>
            <el-image
              v-if="thumbnailPreview"
              ref="uploadThumbnail"
              class="thumbnail-image"
              :src="thumbnailPreview"
              fit="contain"
              @load="uploadImageLoaded($event, 'uploadThumbnail')"
            />
            <video ref="videoContainer" :src="videoSrc"></video>
            <img
              ref="pdfDefaultImg"
              src="@/assets/task-detail/defaultPDF.png"
              alt=""
            />
            <el-image
              ref="uploadImage"
              class="preview-image"
              :src="imagePreview"
              :preview-src-list="[imagePreview]"
              fit="contain"
              @load="uploadImageLoaded($event, 'uploadImage')"
            >
              <template #error>
                <div class="image-slot">
                  <img src="@/assets/task-detail/defaultPicture.png" alt="" />
                </div>
              </template>
            </el-image>
            <div class="canvasWrap" v-show="fileItem.thumbnail">
              <canvas
                ref="canvas"
                class="thumbnail-image"
                style="border: 0px solid pink"
              />
            </div>
            <!-- <img style="display: none" ref="tempImage" :src="tempSrc" alt="" /> -->
            <!-- <el-upload
              v-if="!fileItem.thumbnail && !thumbnailPreview"
              class="upload-cover"
              action=""
              accept="image/*,IMAGE/*"
              :show-file-list="false"
              :multiple="false"
              :auto-upload="false"
              :on-change="onUploadThumbnail"
            >
              <img src="@/assets/greyPlus.svg" />
            </el-upload> -->

            <!-- 更换缩略图 -->
            <!-- <el-upload
              v-else
              class="change-btn"
              action=""
              accept="image/*,IMAGE/*"
              :show-file-list="false"
              :multiple="false"
              :auto-upload="false"
              :on-change="onUploadThumbnail"
            >
              更换缩略图
            </el-upload> -->
            <!-- <input type="file" ref="test" v-on:change="handleFileUpload($event)"/> -->
          </div>
        </el-form-item>

        <!-- <el-form-item prop="description">
        <div class="title"><span class="need">*</span>素材描述</div>
        <div class="item">
          <el-input
            v-model="fileItem.description"
            type="textarea"
            rows="2"
            placeholder="素材描述"
          />
        </div>
      </el-form-item> -->

        <!-- <el-form-item prop="isPublic" >
            <div class="title"><span class="need">*</span>公开<span class="note">所有平台用户均可以使用</span></div>
            <div class="item">
                <el-switch
                    v-model="fileItem.isPublic"
                    active-color="#13ce66">
                </el-switch>
            </div>
         </el-form-item> -->

        <!-- <el-form-item > -->

        <div class="btn-bar">
          <!-- 取消 -->
          <el-button class="btn cancal-btn" size="small" @click="cancel()">{{
            $t("uploadAsset.cancel")
          }}</el-button>
          <!-- 确认 -->
          <el-button
            class="btn"
            size="small"
            :disabled="!fileItem.fileToUpload || uploading"
            @click="ok('editForm')"
            >{{ $t("uploadAsset.ok") }}</el-button
          >
        </div>
        <!-- </el-form-item> -->
      </el-form>
      <!-- <ModelDialog
      ref="modelViewer"
      :show="showModelViewer"
      :model-file="fileItem.fileToUpload"
      :catch-thumbnail="true"
      @onClose="closeModelViewer"
    /> -->
      <!-- <ModelLoader @getThumbnail="thumbnailData" ref="modelLoader" /> -->
    </el-dialog>
  </div>
</template>
<script src="./uploadAsset.js"></script>

<style lang="scss" scoped>
@import "./uploadAsset.scss";
</style>
