<template>
  <div id="templateDialog">
    <!-- 模板选择 -->
    <el-dialog
      :title="$t('templateDialog.word1')"
      v-model="show"
      width="60%"
      top="10%"
      :before-close="handleClose"
    >
      <div class="templates">
        <div
          class="templateItem clearFix"
          v-for="(item, index) in templates"
          :key="index"
          @click="selectTemplate(item)"
        >
          <div
            class="templateImg"
            :class="item.type === selectedTemplate.type ? 'active' : ''"
          >
            <img :src="item.img" alt="" />
          </div>
          <div class="templateName">{{ $t(item.name) }}</div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <!-- 取 消 -->
          <el-button @click="show = false">{{
            $t("templateDialog.word2")
          }}</el-button>
          <!-- 确 定 -->
          <el-button type="primary" @click="confirmSelect">{{
            $t("templateDialog.word3")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { template2DList } from "@/utils/asset.js";
export default {
  props: [],
  data() {
    return {
      show: false,
      modelData: null,
      templates: [...template2DList],
      selectedTemplate: null,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    init(templateInfo, bool) {
      this.show = bool;
      this.selectedTemplate = templateInfo;
    },
    handleClose() {
      this.show = false;
    },
    selectTemplate(item) {
      this.selectedTemplate = item;
    },
    confirmSelect() {
      this.$emit("selectedTemplate", this.selectedTemplate);
      this.handleClose();
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
#templateDialog {
  :deep(.el-dialog) {
    border-radius: 12px;
  }
  :deep(.el-dialog__header) {
    padding: 20px 40px 10px;
    text-align: left;

    .el-dialog__title {
      color: #4582ff;
      font-size: 1.1rem;
    }
  }
}
.templates {
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  .templateItem {
    width: 30%;
    padding-bottom: 21.7%;
    border-radius: 10px;
    margin: 0.5% 1.5%;
    float: left;
    position: relative;
    .templateImg {
      border-radius: 10px;
      background: #f8f9fb;
      border: 2px solid rgba(0, 0, 0, 0);
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 20%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .templateImg:hover {
      border: 2px solid #4582ff;
    }
    .active {
      border: 2px solid #4582ff;
    }
    .templateName {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 20%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .clearfix:after {
    /*伪元素是行内元素 正常浏览器清除浮动方法*/
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
  .clearfix {
    *zoom: 1; /*ie6清除浮动的方式 *号只有IE6-IE7执行，其他浏览器不执行*/
  }
}
</style>
