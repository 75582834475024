<template>
  <div class="media">
    <div class="mediaBody">
      <div class="img1">
        <!-- <img v-if="imgUrl" :src="imgUrl" alt="" /> -->
        <div class="imgWrap" v-if="fileType === 1">
          <img crossorigin="anonymous" :src="imgUrl" v-if="imgUrl" alt="" />
        </div>

        <div class="videoWrap" v-if="fileType === 2">
          <video
            crossorigin="anonymous"
            :id="videoRef"
            controls
            :src="imgUrl"
          ></video>
        </div>

        <div class="videoWrap" v-if="fileType === 3">
          <div class="imgWrap">
            <div class="img">
              <img
                class="pdfdefault"
                @click="preViewPdf"
                src="@/assets/project/pdfDefault.png"
                alt=""
              />
              <PdfView ref="pdfView" />
            </div>
            <p>{{ fileName }}</p>
          </div>
        </div>
        <!-- 暂未上传 -->
        <span v-if="!imgUrl">{{ $t("prevTemplate2D.media.noUpload") }}</span>
      </div>
      <div class="img2">
        <!-- <img v-if="imgUrl1" :src="imgUrl1" alt="" /> -->
        <div class="imgWrap" v-if="fileType1 === 1">
          <img crossorigin="anonymous" :src="imgUrl1" v-if="imgUrl" alt="" />
        </div>

        <div class="videoWrap" v-if="fileType1 === 2">
          <video
            crossorigin="anonymous"
            :id="videoRef"
            controls
            :src="imgUrl1"
          ></video>
        </div>

        <div class="videoWrap" v-if="fileType1 === 3">
          <div class="imgWrap">
            <div class="img">
              <img
                class="pdfdefault"
                @click="preViewPdf1"
                src="@/assets/project/pdfDefault.png"
                alt=""
              />
              <PdfView ref="pdfView1" />
            </div>
            <p>{{ fileName1 }}</p>
          </div>
        </div>
        <!-- 暂未上传 -->
        <span v-if="!imgUrl1">{{ $t("prevTemplate2D.media.noUpload") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getAssetById } from "@/api/workflow";
import { getFileTypeByFileName } from "@/utils/getFileType.js";
import PdfView from "@/views/components/pdfView/pdfView.vue";
export default {
  props: ["data"],
  data() {
    return {
      imgUrl: null,
      imgUrl1: null,
      fileType: 65535,
      fileName: null,
      fileType1: 65535,
      fileName1: null,
    };
  },
  computed: {},
  async created() {
    console.log(this.data);
  },
  mounted() {},
  watch: {
    data: {
      async handler(newVal, oldVal) {
        if (this.data) {
          //   let data = JSON.parse(this.data);
          let data = this.data;
          let targetMedia = data.medias[0];
          // let res = await getAssetById(targetMedia.assetGuid);
          this.imgUrl = targetMedia.fileUrl;
          this.fileType = getFileTypeByFileName(targetMedia.assetName);
          if (this.fileType === 3) {
            this.fileName = targetMedia.assetName;
          }

          let targetMedia1 = data.medias[1];
          // let res1 = await getAssetById(targetMedia1.assetGuid);
          this.imgUrl1 = targetMedia1.fileUrl;
          // console.log(res1);
          this.fileType1 = getFileTypeByFileName(targetMedia1.assetName);
          if (this.fileType1 === 3) {
            this.fileName1 = targetMedia1.assetName;
          }
        } else {
          this.imgUrl = null;
          this.fileType = 65535;
          this.imgUrl1 = null;
          this.fileType1 = 65535;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    preViewPdf() {
      this.$refs.pdfView.setSwitch(true, this.imgUrl);
    },
    preViewPdf1() {
      this.$refs.pdfView1.setSwitch(true, this.imgUrl1);
    },
  },
  components: { PdfView },
};
</script>

<style scoped lang="scss">
.media {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  .mediaBody {
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    > div {
      border: 1px solid #ccc;
      box-sizing: border-box;
      border-radius: 10px;
      overflow: hidden;
      height: 100%;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .imgWrap {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
        background: #e9eef3;
        > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .pdfdefault {
          cursor: pointer;
        }
      }
      .videoWrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
        > video {
          width: 100%;
          height: 100%;
          object-fit: contain;
          background: #000;
        }
        .imgWrap {
          border-radius: 10px;
          overflow: hidden;
          position: relative;
          background: #e9eef3;
          > .img {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            > img {
              width: 20rem;
            }
            .pdfdefault {
              cursor: pointer;
            }
          }
          > p {
            text-align: center;
            color: #ccc;
            font-size: 1.5rem;
            position: absolute;
            bottom: 1rem;
            left: 0;
            right: 0;
          }
        }
      }
    }
    .img1 {
      width: 62%;
      display: flex;
      justify-content: center;
      align-items: center;
      > span {
        font-size: 34px;
        color: #ccc;
        font-weight: bold;
      }
    }
    .img2 {
      width: 35%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ccc;
      border-radius: 10px;
      > span {
        font-size: 34px;
        color: #ccc;
        font-weight: bold;
      }
    }
  }
}
</style>
