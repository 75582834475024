<template>
  <div>
    <div class="accountHeader">
      <Header />
      <div class="myBigHeadImage">
        <img v-if="profilePhotoUrl" :src="profilePhotoUrl" alt="" />
        <img v-else src="@/assets/login/default-head.png" alt="" />
        <!-- <img src="@/assets/login/default-head.png" alt="" /> -->
      </div>
      <!-- <div class="headerContent">
        <div>
          <div class="imgWarp">
            <img :src="profilePhotoUrl" alt="">
          </div>
          <div>
            <div class="username">{{$store.state.User.displayName}}</div>
            <div class="editUserinfo" @click="test">编辑个人信息</div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="myAccountContentWapr">
      <div class="myAccountContent">
        <div class="tabs">
          <button
            @click="changeSelectTab('userInfo')"
            :class="selectedTab === 'userInfo' ? 'active' : ''"
          >
            <!-- 个人信息 -->
            <i class="el-icon-user"></i
            ><span>{{ $t("myAccount.userInfo") }}</span>
          </button>
          <button
            @click="changeSelectTab('accountInfo')"
            :class="selectedTab === 'accountInfo' ? 'active' : ''"
          >
            <!-- 账户设置 -->
            <i class="el-icon-postcard"></i
            ><span>{{ $t("myAccount.accountSetting") }}</span>
          </button>
        </div>
        <div class="contants">
          <div class="title">
            <!-- 编辑个人信息   账户设置 -->
            {{
              selectedTab === "userInfo"
                ? $t("myAccount.userInfo")
                : $t("myAccount.accountSetting")
            }}
          </div>
          <UserInfo v-if="selectedTab === 'userInfo'" />
          <AccountInfo v-if="selectedTab === 'accountInfo'" />
        </div>
      </div>
    </div>
    <Footer v-if="language !== 'en'" />
  </div>
</template>

<script>
import Header from "@/views/components/header/header.vue";
import Footer from "@/views/components/footer/footer.vue";
import UserInfo from "./userInfo/userInfo.vue";
import AccountInfo from "./accountInfo/accountInfo.vue";
// import { getLanguage } from "@/utils/getLanguage.js";
import { mapGetters } from "vuex/dist/vuex.cjs.js";
export default {
  components: { Header, Footer, UserInfo, AccountInfo },
  data() {
    return {
      // language: getLanguage(),
      selectedTab: "userInfo",
    };
  },
  created() {
    console.log(this.$store.state.User);
  },
  mounted() {},
  computed: {
    profilePhotoUrl() {
      return this.$store.state.User.profilePhotoUrl;
    },
    ...mapGetters(['language'])
  },
  watch: {},
  methods: {
    changeSelectTab(tabName) {
      this.selectedTab = tabName;
    },
  },
};
</script>
<style scoped lang="scss">
.accountHeader {
  background: -webkit-linear-gradient(#ebf5fd, #cbe4fe);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(#ebf5fd, #cbe4fe);
  /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(#ebf5fd, #cbe4fe);
  /* Firefox 3.6 - 15 */
  background: linear-gradient(#ebf5fd, #cbe4fe);
  // height: 12rem;
  padding-bottom: 18rem;
  // display: flex;
  // justify-content: center;
  position: relative;
  overflow: hidden;
  #headerWarp {
    position: absolute;
    z-index: 3;
  }
  .myBigHeadImage {
    width: 50rem;
    height: 50rem;
    border-radius: 25rem;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    overflow: hidden;
    opacity: 0.5;
    > img {
      width: 100%;
    }
  }
  .headerContent {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 1;
    bottom: 4rem;
    z-index: 2;
    width: 100%;
    > div {
      width: 70%;
      box-sizing: border-box;
      padding-left: 2rem;
      display: flex;
      justify-content: flex-start;
      .imgWarp {
        border-radius: 50%;
        overflow: hidden;
        width: 6rem;
        height: 6rem;
        background-color: #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        > img {
          width: 100%;
        }
      }
      > div {
        width: 15rem;
        height: 6rem;
        box-sizing: border-box;
        padding: 1rem 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        > div {
          width: 100%;
          text-align: left;
          box-sizing: border-box;
          overflow: hidden;
          padding-left: 3rem;
        }
        .username {
          font-size: 1.5rem;
          color: #1b476e;
        }
        .editUserinfo {
          font-size: 0.9rem;
          color: #6291ce;
          cursor: pointer;
        }
        .editUserinfo:hover {
          text-decoration: 1px underline #6291ce;
        }
      }
    }
  }
}
.myAccountContentWapr {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #f1f4fc;
  .myAccountContent {
    width: 70%;
    height: calc(100vh - 21.5rem);
    background: #ccc;
    display: flex;
    justify-content: space-between;
    background: #f1f4fc;
    box-sizing: border-box;
    padding: 1.5rem;
    > div {
      height: 100%;
      background: #fff;
      border-radius: 5px;
      padding: 1.5rem 0;
      box-sizing: border-box;
    }
    .tabs {
      width: 25%;
      > button {
        outline: none;
        border: 1px solid #eff2fa;
        cursor: pointer;
        width: 100%;
        height: 10%;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.95rem;
        > i {
          font-size: 1.1rem;
          margin-right: 0.5rem;
        }
      }
      .active {
        background: #6a9bff;
        color: #fff;
      }
    }
    .contants {
      width: 74%;
      padding: 1.5rem;
      > .title {
        color: #224b77;
        font-size: 1.4rem;
        font-weight: 600;
        text-align: left;
        // height: 2rem;
        padding: 0.5rem 0 1.5rem 0;
        border-bottom: 1px solid #f2f5fb;
      }
    }
  }
}
</style>
