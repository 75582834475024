import { h } from "@logicflow/core";
import { RectResize } from "@logicflow/extension";
class HexagonModel extends RectResize.model {
  setAttributes() {
    this.text.x = this.x;
    this.text.y = this.y;
    this.menu = [
      {
        // 删除
        text: `${this.properties.language.delete} Delete`,
        callback: (node) => {
          this.graphModel.eventCenter.emit("nodeDelete", node);
        },
      },
    ];
  }
  initNodeData(data) {
    super.initNodeData(data);
    this.width = 160;
    this.height = 64;
    this.isShowAnchor = false;
    const cantLinkStart = {
      message: "任务步骤不允许作为有下一步",
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        // if (targetNode.type == "StartShape") {
        //   return false
        // } else {
        //   return true
        // }
        if (sourceNode.type === "TaskShape") {
          return false
        }
        // return targetNode.id !== sourceNode.id
      },
    };

    const rule2 = {
      message: "不允许连接到'开始步骤'",
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        if (targetNode.type === "StartShape") {
          return false
        }
      }
    }
    const rule3 = {
      message: "不允许连接到自己",
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        if (sourceNode.id === targetNode.id) {
          return false
        } else {
          return true
        }
      }
    }
    this.sourceRules.push(cantLinkStart);
    this.sourceRules.push(rule2, rule3);
  }
  //重写节点文本样式
  getTextStyle() {
    const style = super.getTextStyle();
    style.color = "#333"
    style.padding = "0 17%";
    return style;
  }
  //重写节点样式
  getNodeStyle() {
    const style = super.getNodeStyle();
    style.strokeWidth = 2;

    // if (this.isSelected) {
    //   style.stroke = "#00de24"
    // } else {
    //   style.stroke = "#f1a131"
    // }
    if (this.properties.onLinkLine) {
      style.strokeDasharray = "0,0";
      style.fill = "#EAEAEA";
      style.stroke = "#333"
      //webgl页面被选中的外边框高亮状态
      if (this.properties.isWebGl || this.properties.isAuthor) {
        if (this.isSelected) {
          style.stroke = "#4582FF"
        } else {
          style.stroke = "#333"
        }
      } else {
        if (this.isSelected) {
          style.strokeDasharray = "0,0";
          style.stroke = "#4582FF"
        } else {
          style.stroke = "#333"
        }
      }
    } else {
      style.strokeDasharray = "4,4";
      style.fill = "#EAEAEA";
      style.stroke = "#333"
      //webgl页面被选中的外边框高亮状态
      if (this.properties.isWebGl || this.properties.isAuthor) {

        if (this.isSelected) {
          style.strokeDasharray = "0,0";
          style.stroke = "#4582FF"
        } else {
          style.stroke = "#333"
        }
      } else {
        if (this.isSelected) {
          style.strokeDasharray = "0,0";
          style.stroke = "#4582FF"
        } else {
          style.stroke = "#333"
        }
      }
    }

    return style;
  }
  //重写锚点样式
  getAnchorStyle(anchorInfo) {
    const style = super.getAnchorStyle(anchorInfo);
    // style.fill = "#32ff32";
    // style.stroke = "#32ff32";
    // style.fill = "#4582FF";
    style.stroke = "#4582FF";
    style.r = 8;
    style.strokeWidth = 2;
    style.hover.fill = "transparent";
    style.hover.stroke = "transpanrent";
    style.className = "lf-hide-default";
    return style;
  }
  //重写节点外边框样式
  getResizeOutlineStyle() {
    return {
      strokeWidth: 2,
      // stroke: "#32ff32",
      stroke: "#4582FF",
      x: this.x - this.width / 2 - 0.5,
      y: this.y - this.height / 2 - 0.5,
    }
  }
  //重写节点缩放四个点样式
  getControlPointStyle() {
    return {
      strokeWidth: 2,
      width: 14,
      height: 14,
      fill: "#EAEAEA",
      // stroke: "#32ff32",
      stroke: "#4582FF",
      // x: this.x - this.width / 2 - 3.5,
      // y: this.y - this.height / 2 - 3.5,
    };
  }
}
//重写开始步骤的样式
class HexagonView extends RectResize.view {
  getResizeShape() {
    const { x, y, width, height } = this.props.model;
    // console.log(x, y, width, height);
    const style = this.props.model.getNodeStyle();
    const offset = width / 10;
    const attrs = [
      h('rect', {
        ...style,
        x: x - width / 2,
        y: y - height / 2,
        width: 2 * offset + width - 2 * offset,
        height,
      }),
      h('line', {
        ...style,
        x1: (x - width / 2) + offset,
        y1: y - height / 2,
        x2: (x - width / 2) + offset,
        y2: y - height / 2 + height,
      }),
      h('line', {
        ...style,
        x1: (x - width / 2) + offset + width - 2 * offset,
        y1: y - height / 2,
        x2: (x - width / 2) + offset + width - 2 * offset,
        y2: y - height / 2 + height,
      })
      // h('rect', {
      //   ...style,
      //   x: (x - width / 2) + offset,
      //   y: y - height / 2,
      //   width: width - 2 * offset,
      //   height,
      // }),
      // h('rect', {
      //   ...style,
      //   x: (x - width / 2) + offset + width - 2 * offset + 1,
      //   y: y - height / 2,
      //   width: offset,
      //   height,
      // })
    ];
    return h("g", {}, [...attrs]);
  }
  // getResizeShape() {
  //   const { x, y, width, height } = this.props.model;
  //   const style = this.props.model.getNodeStyle();
  //   const pointList = [
  //     [x - 0.28 * width, y - 0.5 * height],
  //     [x + 0.28 * width, y - 0.5 * height],
  //     [x + 0.5 * width, y],
  //     [x + 0.28 * width, y + 0.5 * height],
  //     [x - 0.28 * width, y + 0.5 * height],
  //     [x - 0.5 * width, y]
  //   ];
  //   const points = pointList.map((item) => {
  //     return `${item[0]},${item[1]}`;
  //   });
  //   const attrs = {
  //     ...style,
  //     x,
  //     y,
  //     width,
  //     height,
  //     points: points.join(" ")
  //   };

  //   return h("g", {}, [h("polygon", { ...attrs })]);
  // };
}

export default {
  type: "pointer",
  view: HexagonView,
  model: HexagonModel
};
