<template>
  <div class="templateWrap">
    <div class="textTemplateWrap">
      <Text
        :ref="'text' + templateNum.texts[0].index"
        @needSave="textNeedSave"
        :templateNum="templateNum.texts[0]"
        :maxlength="500"
        :projectInfo="projectInfo"
      />
    </div>
  </div>
</template>

<script>
import { getFileTypeByFileName } from "@/utils/getFileType.js";
import { getSpeak } from "@/utils/SpeechSynthesis.js";
import { removeNonAlphanumeric } from "@/utils/utils.js";
import Text from "./tenplateItem/text.vue";
import Media from "./tenplateItem/media.vue";
import { uuid } from "vue-uuid";
import { BlockBlobClient, AnonymousCredential } from "@azure/storage-blob";
import {
  saveAssetNew,
  getUploadSasNew,
  updateStepInfo,
  getStepInfo,
  getAssetById,
} from "@/api/workflow";
import { getSelectedGuid } from "@/views/taskDetail/components/taskChart/setSelectShape.js";
export default {
  props: ["isCreator", "projectGuid", "taskGuid", "shapeItem", "projectInfo"],
  data() {
    return {
      templateNum: {
        type: 1,
        texts: [
          {
            type: 0, //文本框
            index: 0, //此模板中json数据中 文本文件在列表中的index
            tag: "0",
            title: "",
            content: null,
            webNeedSaveFlag: false,
            filePath: null,
          },
        ],
        medias: [
          {
            type: 1, //多媒体框
            index: 0, //此模板中json数据中 多媒体文件在列表中的index
            tag: "0",
            title: "",
            content: "",
            webNeedSaveFlag: false,
            filePath: null,
          },
        ],
      },
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    async initData(stepInfos) {
      console.log(stepInfos);
      if (!stepInfos) {
        this.templateNum = {
          type: 0,
          texts: [
            {
              type: 0, //文本框
              index: 0, //此模板中json数据中 文本文件在列表中的index
              tag: "0",
              title: "",
              content: null,
              webNeedSaveFlag: false,
              filePath: null,
            },
          ],
          medias: [
            {
              type: 1, //多媒体框
              index: 0, //此模板中json数据中 多媒体文件在列表中的index
              tag: "0",
              title: "",
              webNeedSaveFlag: false,
              filePath: null,
              thumbnailPath: null, //video和图片有缩略图
            },
          ],
        };
        this.$nextTick(() => {
          this.$emit("initDone");
          this.$emit("sourceLoaded");
        });
      } else {
        let stepInfo = stepInfos;

        console.log(stepInfo);
        let temp = JSON.parse(stepInfo.stepObjectJson);
        //单独取素材的url
        // temp.texts.forEach(async (item, index) => {
        //   if (item.assetGuid) {
        //     let res = await getAssetById(item.assetGuid);
        //     this.templateNum.texts[index] = {
        //       ...item,
        //       ...res.result,
        //     };
        //   }
        // });
        // temp.medias.forEach(async (item, index) => {
        //   if (item.assetGuid) {
        //     let res = await getAssetById(item.assetGuid);
        //     this.templateNum.medias[index] = {
        //       ...item,
        //       ...res.result,
        //     };
        //   }
        // });
        this.$emit("stepInfo", temp);
        this.templateNum = temp;
        this.$nextTick(() => {
          this.$emit("initDone");
        });
        console.log(this.templateNum);
      }
    },
    //保存模板内容
    async saveTemplateInfo() {
      console.log(this.templateNum);
      // let options = {
      //   type: this.templateNum.type,
      //   texts: this.templateNum.texts,
      //   medias: this.templateNum.medias,
      // };
      // console.log(options);
      //纯文本，把多媒体列表置空
      this.templateNum.medias = [];
      for (let i = 0; i < this.templateNum.texts.length; i++) {
        let item = this.templateNum.texts[i];
        if (item.webNeedSaveFlag) {
          console.log("textNeedSave");
          console.log("text" + item.index);

          let text = this.$refs["text" + item.index].returnText();
          console.log(text);
          let guid = uuid.v4();
          let options = {
            assetCategory: 2,
            assetType: 2,
            assetGuid: guid,
            assetName: null,
            description: null,
            filePath: null,
            originFileName: item.assetName,
            fileSize: null,
            isDeleted: false,
            projectGuid: null,
            shareLevel: 2,
            thumbnailPath: null,
            stepGuid:
              this.shapeItem?.data?.properties?.stepInfo?.sourceStepGuid ||
              getSelectedGuid(),
          };
          let textRes;
          let uploadRes;
          if (text) {
            textRes = await getSpeak(
              removeNonAlphanumeric(
                this.$refs["text" + item.index].returnText()
              ),
              this.projectInfo.language
            );
            console.log(text);
            console.log(textRes);
            let blob = new Blob([textRes], { type: "audio/mp3" });
            blob.name = uuid.v4() + ".mp3";
            uploadRes = await this.uploadStageFile(blob);
            console.log(uploadRes);
            options.assetName = blob.name;
            options.originFileName = blob.name;
          } else {
            uploadRes = null;
            options.fileUrl = null;
          }
          this.templateNum.texts[i].content = this.$refs[
            "text" + item.index
          ].returnText();
          options.filePath = uploadRes;

          let res = await saveAssetNew(options);
          console.log(res);
          this.templateNum.texts[i] = {
            ...item,
            ...res,
          };
          this.templateNum.texts[i].webNeedSaveFlag = false;
        }
      }
      for (let i = 0; i < this.templateNum.medias.length; i++) {
        let item = this.templateNum.medias[i];
        if (item.webNeedSaveFlag) {
          let guid = uuid.v4();
          let options = {
            assetCategory: getFileTypeByFileName(item.assetName),
            assetType: 2,
            assetGuid: guid,
            assetName: item.assetName,
            description: null,
            filePath: item.filePath,
            originFileName: item.assetName,
            fileSize: null,
            isDeleted: false,
            projectGuid: null,
            shareLevel: 2,
            thumbnailPath: item.thumbnailPath,
            stepGuid:
              this.shapeItem?.data?.properties?.stepInfo?.sourceStepGuid ||
              getSelectedGuid(),
          };
          let res = await saveAssetNew(options);
          console.log(res);
          this.templateNum.medias[i] = {
            ...item,
            ...res,
          };
          this.templateNum.medias[i].webNeedSaveFlag = false;
        }
      }
      console.log(this.templateNum.medias);
      // this.$emit("saveTemplateInfo", options);
      let updateOption = {
        taskGuid: this.taskGuid,
        stepGuid:
          this.shapeItem?.data?.properties?.stepInfo?.sourceStepGuid ||
          getSelectedGuid(),
        panelType: this.templateNum.type,
        appearType: 0,
        index: 0,
        delaySeconds: 0,
        stepObjectJson: JSON.stringify(this.templateNum),
      };
      let res1 = await updateStepInfo(updateOption);
    },
    async getuploadedFile(data, indexInfo) {
      console.log(data);
      // this.templateNum.media[indexInfo.index].value = data;
      //选择文件后立即上传
      if (data.fileType === 3) {
        //pdf不需要上传缩略图
        let pdfRes = await this.uploadStageFile(data.file);
        console.log(pdfRes);
        this.templateNum.medias[indexInfo.index].webNeedSaveFlag = true;
        // this.templateNum.medias[indexInfo.index].value = data;
        this.templateNum.medias[indexInfo.index].filePath = pdfRes;
        this.templateNum.medias[indexInfo.index].assetName = data.file.name;
        this.templateNum.medias[indexInfo.index].fileSize = data.file.size;
        console.log(this.templateNum);
      } else if (data.fileType === 1 || data.fileType === 2) {
        //图片或多媒体
        //文件
        let mediaFile = await this.uploadStageFile(data.file);
        //缩略图文件
        let mediaThumbnail = await this.uploadStageFile(data.fileThumbnail);
        this.templateNum.medias[indexInfo.index].webNeedSaveFlag = true;
        // this.templateNum.medias[indexInfo.index].value = data;
        this.templateNum.medias[indexInfo.index].filePath = mediaFile;
        this.templateNum.medias[indexInfo.index].thumbnailPath = mediaThumbnail;
        this.templateNum.medias[indexInfo.index].assetName = data.file.name;
        this.templateNum.medias[indexInfo.index].fileSize = data.file.size;
        console.log(mediaFile);
        console.log(mediaThumbnail);
        console.log(this.templateNum);
      }
    },
    selectedItem(data, indexInfo) {
      console.log(data);
      // this.templateNum.medias[indexInfo.index].fileUrl = data.fileUrl;

      this.templateNum.medias[indexInfo.index] = {
        ...this.templateNum.medias[indexInfo.index],
        ...data,
      };
      this.$emit("needSave", data);
    },
    textNeedSave(data, indexInfo) {
      this.$emit("needSave", data);
      this.templateNum.texts[indexInfo.index].webNeedSaveFlag = true;
    },
    needSave(data) {
      this.$emit("needSave", data);
    },
    async uploadStageFile(file) {
      if (!file) {
        return "";
      }
      try {
        const res = await getUploadSasNew(file.name || `${uuid.v4()}.jpeg`);

        const blobClient = new BlockBlobClient(
          res.sas,
          new AnonymousCredential()
        );
        await blobClient.uploadData(file, {
          blockSize: 512 * 1024,
          concurrency: 2,
        });

        // await saveMyProfilePhoto(path)
        return res.path;
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
  components: {
    Text,
    Media,
  },
};
</script>

<style scoped lang="scss">
.templateWrap {
  width: 100%;
  height: 94%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
  > div {
    background: #ededed;
    border-radius: 10px;
    height: 100%;
  }
  .textTemplateWrap {
    width: 100%;
  }
}
</style>
