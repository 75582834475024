export function getFileTypeByFileName(fileName) {
    // Image: 1,
    // Video/Audio: 2,
    // PDF: 3,
    // ThreeD: 1001,
    // UnityAB: 2001,
    // Others: 65535
    if (!fileName) {
        return 65535
    }
    let modelTypes = [".fbx", ".FBX"];
    // let modelTypes = [".fbx", ".obj", ".glb", ".gltf", ".stl"];
    // let pictureTypes = [".jpg", ".jpeg", ".png", ".tif", ".bmp", "gif"];
    let videoTypes = [
        ".mp4",
        ".MP4",
        // ".rmvb",
        // ".rm",
        // ".asf",
        // ".divx",
        // ".mpg",
        // ".mpeg",
        // ".wmv",
        // ".mkv",
        // ".vob",
        ".mp3",
        ".MP3",
    ];
    let pictureTypes = [".jpg", ".JPG", ".png", ".PNG", ".jpeg", "JPEG"];
    let PDFTypes = [".PDF", ".pdf"];
    let type = 65535;
    modelTypes.forEach((value, index) => {
        if (fileName.indexOf(value) !== -1) {
            type = 1001;
        }
    });
    pictureTypes.forEach((value, index) => {
        if (fileName.indexOf(value) !== -1) {
            type = 1;
        }
    });
    videoTypes.forEach((value, index) => {
        if (fileName.indexOf(value) !== -1) {
            type = 2;
        }
    });
    PDFTypes.forEach((value, index) => {
        if (fileName.indexOf(value) !== -1) {
            type = 3;
        }
    });
    return type;
}