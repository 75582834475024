<template>
  <div>
    <div class="nodataBody" v-if="list.length === 0">
      <div>
        <img class="nodataImg" src="@/assets/myTask/defaultEnpty.png" alt="" />
        <!-- 您还未产生任何数据 -->
        <div class="nodataTip">{{ $t("dataCharts.nodata") }}</div>
        <div class="createBtn">
          <!-- 创建项目 -->
          <button @click="goCreateProject" v-if="selectItem === 0">
            {{ $t("dataCharts.createPro") }}
          </button>
          <button @click="goRelease" v-if="selectItem === 1">
            {{ $t("dataCharts.goRelease") }}
          </button>
        </div>
      </div>
    </div>
    <div class="dataBody" v-if="list.length !== 0">
      <!-- <FixHeader /> -->
      <div class="tableListWrap" ref="dataListWrap">
        <div class="tableListHeader">
          <!-- 项目名称 -->
          <div class="proName">{{ $t("dataCharts.word7") }}</div>
          <!-- 操作次数 -->
          <div class="datas">{{ $t("dataCharts.word8") }}</div>
          <!-- 平均每次花费时长 -->
          <div class="datas lang">{{ $t("dataCharts.word9") }}</div>
          <!-- 最后一次操作时长 -->
          <div class="datas lang">{{ $t("dataCharts.word10") }}</div>
        </div>
        <div class="tableListBody" v-for="(item, index) in list" :key="index">
          <div class="dataItem">
            <div class="proName">
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.project_name"
                placement="top"
              >
                <span>{{ item.project_name }}</span>
              </el-tooltip>
            </div>

            <div class="datas">{{ item.use_times }}</div>
            <div class="datas lang">
              {{ getTimeMs(item.avg_duration / 1000) }}
            </div>
            <div class="datas lang">
              {{ getTimeMs(item.latest_duration / 1000) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pages" v-if="dataPage.total > dataPage.pageSize">
      <el-pagination
        background
        layout="prev, pager, next ,jumper"
        :total="list.length"
        @current-change="currentChange"
        :page-size="dataPage.pageSize"
      >
        <!-- 跳转到 -->
        <template #layout> {{ $t("explore.word5") }} </template>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getOprSessionByRelease } from "@/api/workflow.js";
// import { deviceType, category } from "@/utils/asset.js";
import { dealTimeToHMS } from "@/utils/utils.js";
// import FixHeader from "./fixHeader.vue";
export default {
  components: {},
  props: ["queryOptions"],
  data() {
    return {
      // deviceType: [...deviceType],
      // category: [...category],
      list: [],
      dataPage: {
        total: 0,
        page: 1,
        pageSize: 10,
      },
      // queryOptions: {
      //   selectedCategoryId: 0,
      //   deviceType: 0,
      //   searchStr: "",
      // },
    };
  },
  computed: {},
  created() {
    console.log(22);
    this.getData();
  },
  mounted() {},
  watch: {},
  methods: {
    getTimeMs(value) {
      return dealTimeToHMS(value);
    },
    currentChange(page) {
      this.dataPage.page = page;
      this.getData();
    },
    async getData() {
      console.log("getData");
      let options = {
        fetch_type: 2,
        // fetch_type: this.selectItem === 0 ? 1 : 2, //管理员0 创建者1 使用者2
        equip_type: this.queryOptions.deviceType,
        category_id: this.queryOptions.selectedCategoryId,
        search_content: this.queryOptions.searchStr,
        page_num: this.dataPage.page - 1,
        page_size: this.dataPage.pageSize,
      };
      let res = await getOprSessionByRelease(options);
      let dataList = res.data;
      this.$emit("returnData", { type: "view", total: res.total });
      console.log(dataList);
      this.list = dataList;
      // this.list = this.list.concat(this.list);
      // this.list = this.list.concat(this.list);
      // this.list = this.list.concat(this.list);
      // this.list = this.list.concat(this.list);
      console.log(res);
    },
    searchGloval() {
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.nodataBody {
  width: 100%;
  height: calc(100vh - 26rem);
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    > .nodataImg {
      width: 200px;
    }
    .createBtn {
      margin-top: 1rem;
      > button {
        width: 120px;
        height: 32px;
        background: #4582ff;
        border-radius: 4px 4px 4px 4px;
        outline: none;
        border: none;
        background: #4582ff;
        color: #fff;
        line-height: 32px;
        text-align: center;
        cursor: pointer;
      }
    }
    .nodataTip {
      margin: 10px 0 20px 0;
      font-size: 14px;
    }
  }
}
.dataBody {
  margin-top: 1rem;
  .searchWarp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > div {
      margin-right: 2rem;
    }
    // > .searchDropdown {
    //   > ::v-deep .select-trigger {
    //     > .el-input {
    //       > ::v-deep .el-input__inner {
    //         height: 30px;
    //       }
    //       > ::v-deep .el-input__suffix {
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //       }
    //     }
    //   }
    // }
    .searchGlobal {
      width: 320px;
      overflow: hidden;
      > :deep(.el-input__inner) {
        background: #cee5fc;
        border-radius: 1.5rem;
        color: #47505d;
        &::placeholder {
          color: #47505d;
        }
      }
      > :deep(.el-input__suffix) {
        right: 10px;
        cursor: pointer;
        transition: color 0.2s;
        :hover {
          color: cornflowerblue;
        }
      }
    }
    .searchInput {
      :deep(.el-input__inner) {
        // width: 300px;
        height: 40px;
        background: #fff;
      }
      :deep(.el-input__suffix) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    // ::v-deep .searchDropdown {
    //   > .select-trigger {
    //     > .el-input {
    //       > .el-input__inner {
    //         height: 30px;
    //       }
    //     }
    //   }
    // }
  }
  .tableListWrap {
    .tableListHeader {
      margin-top: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      padding: 0 4%;
      > div {
        color: #787d89;
        font-size: 14px;
        text-align: right;
      }
      .proName {
        width: 10%;
        text-align: left;
      }
      .datas {
        width: 25%;
      }
      .lang {
        width: 32%;
      }
    }
    .tableListBody {
      .dataItem {
        font-size: 16px;
        color: #353535;
        font-weight: 500;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding: 0 4%;
        height: 68px;
        border-radius: 12px;
        border: 1px solid #dde2e5;
        margin-top: 10px;
        > div {
          text-align: right;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 400;
        }
        .proName {
          width: 10%;
          text-align: left;
          font-weight: 600;
        }
        .datas {
          width: 25%;
        }
        .lang {
          width: 32%;
        }
      }
    }
  }
}
.pages {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  :deep(.el-pagination) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    > button {
      background: #fff;
      color: #8fb5ff;
      border: 1px solid #dae6ff;
      border-radius: 10px;
      width: 60px;
      height: 38px;
    }
    > button:hover {
      border: 1px solid #4582ff;
    }
    > button[disabled] {
      border: 1px solid #dae6ff;
    }
    .el-pager {
      display: flex;
      justify-content: space-around;
      align-items: center;
      > li {
        height: 36px;
        padding: 0 1rem;
        background: #ffffff;
        border-radius: 10px;
        opacity: 1;
        border: 1px solid #dae6ff;
        color: #224b77;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
      }
      > li:hover {
        border: 1px solid #4582ff;
      }
      .active {
        border: 1px solid #4582ff;
        background: #4582ff;
        color: #ffffff;
      }
    }
    > span {
      height: auto;
      > div {
        width: auto;
        height: auto;
        > input {
          background: #eff3fc;
          width: 60px;
          height: 36px;
          border-radius: 28px;
          color: #224b77;
        }
      }
    }
  }
}
</style>
