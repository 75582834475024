const localStorageKey = 'JTWorkflow_taskGuid'

export function getTaskGuid() {
  return localStorage.getItem(localStorageKey)
}

export function setTaskGuid(guid) {
  return localStorage.setItem(localStorageKey, guid)
}

export function removeTaskGuid() {
  return localStorage.removeItem(localStorageKey)
}