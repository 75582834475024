<template>
  <div class="home" id="mainBodyHome">
    <div class="header">
      <Header @refreshList="refreshList" />
      <div class="modelConctrl">
        <BannerLoading
          class="bannerLoadingComp"
          :scale="0.54"
          v-if="!webGLLoaded"
        />
        <iframe
          id="mainView"
          ref="mainView"
          :src="$store.state.TaskDetail.headerPath + 'mainPage/index.html'"
          sandbox="allow-scripts allow-same-origin"
          frameborder="0"
          scrolling="no"
        ></iframe>
      </div>
      <div class="headerBodyWarp">
        <!-- v-if="webGLLoaded" -->
        <div class="paddingBox">
          <div class="headerBody">
            <div class="titleTip">
              <div>
                <!-- 天下无难事 -->
                <p>{{ $t("home.word1") }}</p>
                <!-- 以全新虚实结合的全新形式创作、管理、分享标准化的工作经验和可视化的知识 -->
                <p>
                  {{ $t("home.word2") }}
                </p>
              </div>

              <div>
                <!-- 个人免费使用 -->
                <button @click="goRegiste">{{ $t("home.word3") }}</button>
              </div>
              <div>
                <!-- 企业用户计划 -->
                <button @click="goEnterprise">{{ $t("home.word4") }}</button>
              </div>
            </div>
            <div class="titleModel"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="canDo">
      <div class="title">
        <!-- 全球首个企业元宇宙流程指导平台 -->
        <div>{{ $t("home.word5") }}</div>
        <!-- 基于XR(VR/AR/MR)技术的知识分享与交流 -->
        <div>{{ $t("home.word6") }}</div>
      </div>
      <div class="canDoItem">
        <div>
          <div class="backgroundContainer"></div>
          <div class="contant">
            <!-- 轻松创建标准化流程指导 -->
            <p>{{ $t("home.word7") }}</p>
            <!-- 轻松创建虚实结合指导流程 -->
            <p>{{ $t("home.word8") }}</p>
            <p>
              <!-- 自由 -->
              <span>{{ $t("home.word9") }}</span>
              <!-- 轻松 -->
              <span>{{ $t("home.word10") }}</span>
            </p>
          </div>
        </div>
        <div>
          <div class="backgroundContainer"></div>
          <div class="contant">
            <!-- 支持全球头部企业级XR设备 -->
            <p>{{ $t("home.word11") }}</p>
            <!-- 全球范围眼镜设备覆盖 -->
            <p>{{ $t("home.word12") }}</p>
            <p>
              <!-- Hololens2 -->
              <span>{{ $t("home.word13") }}</span>
              <span>{{ $t("home.word51") }}</span>
              <span>{{ $t("home.word52") }}</span>
            </p>
          </div>
        </div>
        <div>
          <div class="backgroundContainer"></div>
          <div class="contant">
            <!-- 虚实结合的可视化指导,答案就在问题边上 -->
            <p>{{ $t("home.word14") }}</p>
            <!-- 流程问题，即时解决 -->
            <p>{{ $t("home.word15") }}</p>
            <p>
              <!-- 无忧 -->
              <span>{{ $t("home.word16") }}</span>
              <!-- 流畅 -->
              <span>{{ $t("home.word17") }}</span>
            </p>
          </div>
        </div>
        <div>
          <div class="backgroundContainer"></div>
          <div class="contant">
            <!-- 支持手机设备AR查看 -->
            <p>{{ $t("home.word18") }}</p>
            <!-- 掏出手机便可使用 -->
            <p>{{ $t("home.word19") }}</p>
            <p>
              <!-- 便捷 -->
              <span>{{ $t("home.word20") }}</span>
              <!-- iOS -->
              <span>{{ $t("home.word21") }}</span>
              <!-- Android -->
              <span>{{ $t("home.word22") }}</span>
            </p>
          </div>
        </div>
        <div>
          <div class="backgroundContainer"></div>
          <div class="contant">
            <!-- 超过10w+全家可用素材 -->
            <p>{{ $t("home.word23") }}</p>
            <!-- 轻松创建虚实结合指导流程 -->
            <p>{{ $t("home.word24") }}</p>
            <p>
              <!-- 自主 -->
              <span>{{ $t("home.word25") }}</span>
              <!-- 分类 -->
              <span>{{ $t("home.word26") }}</span>
              <!-- 收藏 -->
              <span>{{ $t("home.word27") }}</span>
            </p>
          </div>
        </div>
        <div>
          <div class="backgroundContainer"></div>
          <div class="contant">
            <!-- 兼容VR设备的虚拟操作流程 -->
            <p>{{ $t("home.word28") }}</p>
            <!-- 虚拟现实呈现全流程解决方案 -->
            <p>{{ $t("home.word29") }}</p>
            <p>
              <!-- VR -->
              <span>{{ $t("home.word30") }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="demoVideo">
      <div class="demoVideoTitle">
        <!-- 强大且方便的编辑工具 -->
        <div>{{ $t("home.word31") }}</div>
        <!-- 多种平台，多种交互方式的表达与创作 -->
        <div>{{ $t("home.word32") }}</div>
      </div>
      <div class="videoWarp">
        <div class="video">
          <video
            muted
            controls
            :src="$store.state.TaskDetail.headerPath + 'video/workflow1.mp4'"
          ></video>
        </div>
      </div>
      <div class="buttonWarp">
        <!-- 马上体验 -->
        <button @click="goRegiste">
          {{ $t("home.word33") }}<img src="@/assets/home/go.png" alt="" />
        </button>
      </div>
    </div>
    <div class="share">
      <div class="shareTitle">
        <!-- 分享你的流程和知识 -->
        <div>{{ $t("home.word34") }}</div>
        <!-- 多种方式的分享你的经验 -->
        <div>{{ $t("home.word35") }}</div>
      </div>
      <div class="shareContant">
        <div
          class="shareItem"
          v-for="(item, index) in releaseList.data"
          :key="index"
        >
          <div>
            <div class="expImgWarp" @click="enterProject(item)">
              <div>
                <!-- <img
                  v-if="!item.enableObjectAnchorModel"
                  src="@/assets/home/position1.png"
                  alt=""
                />
                <img
                  v-if="item.enableObjectAnchorModel"
                  src="@/assets/home/position2.png"
                  alt=""
                /> -->
                <div v-if="item.editType === 0" class="threeD">
                  <img src="@/assets/home/threeD.png" alt="" />
                </div>
                <div v-if="item.editType === 1" class="twoD">
                  <img src="@/assets/home/twoD.png" alt="" />
                </div>
              </div>
              <!-- <img src="" alt="" /> -->
              <img v-if="item.coverImageUrl" :src="item.coverImageUrl" alt="" />
              <img v-else src="@/assets/home/defaultCover.png" alt="" />
            </div>
            <p>{{ item.projectName ? item.projectName : "0" }}</p>
            <div class="operation">
              <span
                ><img src="@/assets/myTask/thumbnail.png" alt="" />
                {{
                  item.likeCount
                    ? item.likeCount > 99
                      ? "99+"
                      : item.likeCount
                    : "0"
                }}
              </span>
              <span
                ><img src="@/assets/myTask/star.png" alt="" />
                {{
                  item.favourateCount
                    ? item.favourateCount > 99
                      ? "99+"
                      : item.favourateCount
                    : "0"
                }}
              </span>
              <span
                ><img src="@/assets/myTask/eye.png" alt="" />
                {{
                  item.previewCount
                    ? item.previewCount > 99
                      ? "99+"
                      : item.previewCount
                    : "0"
                }}
              </span>
              <span
                ><img src="@/assets/myTask/share.png" alt="" />
                {{
                  item.forwardCount
                    ? item.forwardCount > 99
                      ? "99+"
                      : item.forwardCount
                    : "0"
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="joinBtn">
        <!-- 马上加入 -->
        <button @click="goRegiste">
          {{ $t("home.word36") }}<img src="@/assets/home/go.png" alt="" />
        </button>
      </div>
    </div>
    <div class="collaborator">
      <div class="collaboratorTitle">
        <!-- 为全球各大企业所信任 -->
        <div>{{ $t("home.word37") }}</div>
        <!-- 已服务过全球各行业客户 -->
        <div>{{ $t("home.word38") }}</div>
      </div>
      <div class="collaboratorItemWarp">
        <div :class="marginLeft(item)" v-for="item in 23" :key="item">
          <div>
            <img
              :src="
                $store.state.TaskDetail.headerPath +
                  'picture/cooperativePartner/cooperativePartner' +
                  item +
                  '.png'
              "
              alt=""
            />
            <!-- <img src="../assets/home/cooperativePartner/cooperativePartner1.png" alt=""> -->
          </div>
        </div>
        <div class="collaboratorItem">
          <div></div>
        </div>
      </div>
      <div class="collaboratorBtn">
        <!-- 获取企业用户专属计划 -->
        <button @click="goEnterprise">
          {{ $t("home.word39") }} <img src="@/assets/home/go.png" alt="" />
        </button>
      </div>
    </div>
    <div id="download" class="download">
      <div class="downloadTitle">
        <!-- 多平台支持的客户端 -->
        <div>{{ $t("home.word40") }}</div>
        <!-- 支持MR、AR、VR多平台客户端使用，无拘无束 -->
        <div>{{ $t("home.word41") }}</div>
      </div>
      <div class="qrCodeWarp">
        <div class="QrcodeItem">
          <!-- HoloLens2客户端下载 -->
          <p>{{ $t("home.word42") }}</p>
          <div class="Qrcode hololensbg">
            <div class="warp">
              <!-- HoloLens -->
              <p class="type">{{ $t("home.word43") }}</p>
              <!-- 版本号：4.0 -->
              <!-- <p class="versionNo">{{ $t("home.word44") }}</p> -->
              <!-- 2022-9-20 -->
              <!-- <p class="date">{{ $t("home.word45") }}</p> -->
              <!-- .转到商店 -->
              <a
                class="link"
                href="https://www.microsoft.com/en-us/p/jtworkflow/9n9l3nrb1g34"
                >{{ $t("home.word46") }} <i class="el-icon-caret-right"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="QrcodeItem">
          <!-- iOS客户端下载 -->
          <p>{{ $t("home.word47") }}</p>
          <div class="Qrcode">
            <img src="@/assets/home/iOSQRcode.png" alt="" />
            <!-- <QRCode
              text="https://apps.apple.com/ky/app/jtworkflow/id6444054101"
              :width="140"
              :height="140"
              :correctLevel="1"
            /> -->
          </div>
        </div>
        <div class="QrcodeItem">
          <!-- Pico Neos客户端下载 -->
          <p>{{ $t("home.word48") }}</p>
          <div class="Qrcode hololensbg">
            <!-- <img src="@/assets/home/noOnLine.png" alt="" /> -->
            <div class="warp">
              <!-- HoloLens -->
              <p class="type">{{ $t("home.word49") }}</p>
              <!-- 版本号：4.0 -->
              <!-- <p class="versionNo">{{ $t("home.word44") }}</p> -->
              <!-- 2022-9-20 -->
              <!-- <p class="date">{{ $t("home.word45") }}</p> -->
              <!-- .转到商店 -->
              <a class="link">{{ $t("home.word50") }} </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer v-if="language !== 'en'" />
  </div>
</template>

<script>
import QRCode from "@/components/qrcordDownload.vue";
import Header from "@/views/components/header/header.vue";
import Footer from "@/views/components/footer/footer.vue";
import BannerLoading from "@/views/components/animation/bannerLoading.vue";
import { ElMessage } from "element-plus";
import { getAllReleases, getAllReleasesForUser } from "@/api/workflow.js";
import { getLanguage } from "@/utils/getLanguage.js";
import { mapGetters } from "vuex/dist/vuex.cjs.js";
export default {
  components: { Footer, Header, BannerLoading, QRCode },
  data() {
    return {
      // language: getLanguage(),
      viewType: 0,
      releaseList: [],
      webGLLoaded: false,
      pageSize: 10,
    };
  },
  created: async function() {
    this.setPageSizeByScreen();
    // this.releaseList = await getAllReleasesForUser(0, this.pageSize);
    // this.refreshList();
    console.log(this.releaseList);
    console.log(this.$route.query);
    if (this.$route.query.page === "download") {
      this.$nextTick(() => {
        // document.getElementById("scrollMainBody").className = "el-main scrolls";
        this.scrollPage("#download");
        this.$router.replace("/");
        console.log(this.$router);
      });
    }
  },
  unmounted() {
    window.removeEventListener("message", this.receiveMessage);
  },
  mounted() {
    window.addEventListener("message", this.receiveMessage, false);
    window.addEventListener("resize", this.setPageSizeByScreen);
  },
  watch: {
    async pageSize(newVal, oldVal) {
      console.log(newVal, oldVal);
      if (newVal != oldVal) {
        // this.releaseList = await getAllReleasesForUser(0, this.pageSize);
        this.refreshList();
      }
    },
  },
  computed: {
    ...mapGetters(['language'])
  },
  methods: {
    async refreshList() {
      if (this.$store.state.User.accountId) {
        this.releaseList = await getAllReleasesForUser(0, this.pageSize);
      } else {
        this.releaseList = await getAllReleases(0, this.pageSize);
      }
    },
    setPageSizeByScreen() {
      let width = document.body.offsetWidth;
      if (width > 1919) {
        this.pageSize = 10;
      } else {
        this.pageSize = 8;
      }
    },
    receiveMessage(event) {
      if (event.data.type === "loadingProgress") {
        if (event.data.value === 1) {
          setTimeout(() => {
            this.webGLLoaded = true;
          }, 2000);
        }
      } else if (event.data.type === "LoadDone") {
        let options = {
          type: "Language",
          value: "ZH_CN",
        };
        // ZH_CN, //中文简体      0
        // EN_US, //英语（美国）  1
        // KO_KR, //韩语          2
        // JA_JP; //日语          3
        let lang = this.language;
        console.log(lang);
        if (lang === "zh") {
          options.value = "0";
        } else if (lang === "kr") {
          options.value = "2";
        } else if (lang === "en") {
          options.value = "1";
        } else {
          options.value = "1";
        }
        this.CallUnity(options);
      }
    },
    CallUnity(data) {
      //调用unity的方法
      this.$refs.mainView.contentWindow.send(data);
      console.log("postMessage to iframe");
      // window.parent.postMessage(data, "*");
      // unityInstance.SendMessage('JSScriptObject', 'JSCallUnity', 'MyString');
    },
    enterProject(project) {
      if (!this.$store.state.User.accountId) {
        this.$store.commit("SET_SHOWlOGIN", true);
        this.$store.commit("SET_ACCOUNTSIGN", "login");
        return;
      }
      this.$router.push({
        path: "/taskDetail",
        query: {
          releaseGuid: project.releaseGuid,
          projectGuid: project.projectGuid,
        },
      });
      // removeTaskGuid();
    },
    goEnterprise() {
      this.$router.push("/enterprisePage?goForm=true");
    },
    goRegiste() {
      this.$router.push("/explore");
      // if (this.$store.state.User.accountId) {
      //   this.$router.push("/explore");
      //   return;
      // }
      // this.$store.commit("SET_ACCOUNTSIGN", "login");
      // this.$store.commit("SET_SHOWlOGIN", true);
    },
    scrollPage(domName) {
      //index与循环的index对应可自由设置动态获取
      const PageId = document.querySelector(domName);
      PageId.scrollIntoView();
    },
    marginLeft(item) {
      if (item / 6 >= 1 && item % 6 === 1 && parseInt(item / 6) % 2 !== 0) {
        return "collaboratorItem lefts";
      } else if (item % 6 === 0 && parseInt(item / 6) % 2 !== 0) {
        return "collaboratorItem rights";
      } else {
        return "collaboratorItem";
      }
    },
  },
};
</script>
<style scoped lang="scss">
.home {
  .header {
    background: -webkit-linear-gradient(#ebf5fd, #cbe4fe);
    /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(#ebf5fd, #cbe4fe);
    /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(#ebf5fd, #cbe4fe);
    /* Firefox 3.6 - 15 */
    background: linear-gradient(#ebf5fd, #cbe4fe);
    // height: 32rem;
    padding: 0 2rem 2rem 2rem;
    position: relative;
    background: #fff;
    .modelConctrl {
      width: 100%;
      // height: 30rem;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      // .background{

      // }
      .bannerLoadingComp {
        position: absolute;
        z-index: 2;
        background: -webkit-linear-gradient(#ebf5fd, #cbe4fe);
        /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(#ebf5fd, #cbe4fe);
        /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(#ebf5fd, #cbe4fe);
        /* Firefox 3.6 - 15 */
        background: linear-gradient(#ebf5fd, #cbe4fe);
      }
      > iframe {
        width: 100%;
        height: 100%;
      }
    }
    // width: 80%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    > .headerBodyWarp {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem 0;
      position: relative;
      padding-bottom: 28%;
      width: 50%;
      .paddingBox {
        width: 100%;

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: right;
        > .headerBody {
          width: 75%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          position: relative;
          .titleTip {
            width: 35%;
            z-index: 3;
            > div:nth-child(1) {
              height: 8vw;
              //  background-color: #ccc;
              margin-top: 3vw;
              position: relative;
              z-index: 2;
              > p {
                text-align: left;
                word-wrap: normal;
                transition: all 0.3s;
                width: 100%;
                margin: 0;
              }
              > p:nth-child(1) {
                width: 45vw;
                color: #4582ff;
                // font-size: 2rem;
                font-size: 2.1vw;
                font-weight: 700;
                letter-spacing: 1px;
              }
              > p:nth-child(2) {
                width: 45vw;
                // font-size: 1.2rem;
                font-size: 1.2vw;
                margin-top: 1vw;
                color: #7b93ad;
              }
            }
            // > div:nth-child(1):hover {
            //   > p:nth-child(1) {
            //     background: #6a9bff;
            //   }
            //   > p:nth-child(2) {
            //     background: #deb573;
            //   }
            // }
            > div {
              margin-top: 2.5vw;
              text-align: left;
              z-index: 2;
              > button {
                width: 12vw;
                height: calc(2.5vw + 8px);
                border-radius: calc(1.25vw + 4px);
                outline: none;
                background-color: #fff;
                border: 1px solid #fff;
                color: #fff;
                font-size: 0.9vw;
                cursor: pointer;
                transition: background 0.3s;
              }
            }
            > div:nth-child(2) {
              text-align: left;
              > button {
                background-color: #6a9bff;
                border: 4px solid #edf4ff;
              }
              > button:hover {
                background: #8fb5ff;
              }
            }
            > div:nth-child(3) {
              margin-top: 1.5rem;
              text-align: left;
              > button {
                background-color: #deb573;
                border: 4px solid #ffe4b8;
              }
              > button:hover {
                background: #e7c796;
              }
            }
          }
          .titleModel {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30rem;
            position: absolute;
            height: 100%;
          }
        }
      }
    }
  }
  .canDo {
    background-color: #fff;
    .title {
      padding: 8rem 0 4rem 0;
      > div:nth-child(1) {
        color: #224b77;
        font-size: 2.6rem;
        font-weight: 700;
      }
      > div:nth-child(2) {
        color: #224b77;
        font-size: 1.2rem;
        margin-top: 1rem;
      }
    }
    .canDoItem {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 99vw; //100vw会出现横向滚动条
      height: 31.093vw;
      position: relative;

      > div {
        box-sizing: border-box;
        width: 50%;
        height: 33.3%;
        display: flex;
        justify-content: left;
        align-items: center;
        overflow: hidden;
        > .backgroundContainer {
          z-index: 1;
          padding: 0 !important;
          width: 100%;
          height: 100%;
          transform: scale(1.06);
          // background: hsla(0, 0%, 100%, 0);
          opacity: 0;
          transform-origin: 50% 50%;
          transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
            opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        // background-color: #ccc;
        > .contant {
          position: absolute;
          padding-left: 6rem;
          z-index: 2;
          background: rgba(0, 0, 0, 0);
          // padding: 2rem 6rem;
          > p {
            text-align: left;
            margin-top: 0.5rem;
            margin-bottom: 0;
            width: 30rem;
          }
          > p:nth-child(1) {
            font-weight: 700;
            font-size: 1.5rem;
            color: #333;
          }
          > p:nth-child(2) {
            font-size: 0.95rem;
            color: #999;
          }
          > p:nth-child(3) {
            margin-top: 1rem;
            span {
              color: #6c9bff;
              font-size: 0.85rem;
              margin-right: 1.5rem;
            }
          }
        }
      }
      > div:nth-child(1),
      > div:nth-child(3),
      > div:nth-child(5) {
        border-top: 1px solid #efefef;
        > div {
          padding-left: 13vw;
        }
      }
      > div:nth-child(2),
      > div:nth-child(4),
      > div:nth-child(6) {
        border-top: 1px solid #efefef;
        border-left: 1px solid #efefef;
        // > div {
        //   padding-left: 0rem;
        // }
      }
      > div:nth-child(5),
      > div:nth-child(6) {
        height: 34%;
        border-bottom: 1px solid #efefef;
      }
      > div:nth-child(1):hover .backgroundContainer {
        background: url("../assets/home/bg1.png");
        background-size: 100% 100%;
        background-size: cover;
        background-repeat: no-repeat;
        // background-attachment: fixed; /*关键*/
        background-position: center;
        transform: scale(1);
        opacity: 1;
        transform-origin: 50% 50%;
        transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
          opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      > div:nth-child(2):hover .backgroundContainer {
        background: url("../assets/home/bg2.png");
        background-size: 100% 100%;
        background-size: cover;
        background-repeat: no-repeat;
        // background-attachment: fixed; /*关键*/
        background-position: center;
        transform: scale(1);
        opacity: 1;
        transform-origin: 50% 50%;
        transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
          opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      > div:nth-child(3):hover .backgroundContainer {
        background: url("../assets/home/bg3.png");
        background-size: 100% 100%;
        background-size: cover;
        background-repeat: no-repeat;
        // background-attachment: fixed; /*关键*/
        background-position: center;
        transform: scale(1);
        opacity: 1;
        transform-origin: 50% 50%;
        transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
          opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      > div:nth-child(4):hover .backgroundContainer {
        background: url("../assets/home/bg4.png");
        background-size: 100% 100%;
        background-size: cover;
        background-repeat: no-repeat;
        // background-attachment: fixed; /*关键*/
        background-position: center;
        transform: scale(1);
        opacity: 1;
        transform-origin: 50% 50%;
        transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
          opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      > div:nth-child(5):hover .backgroundContainer {
        background: url("../assets/home/bg5.png");
        background-size: 100% 100%;
        background-size: cover;
        background-repeat: no-repeat;
        // background-attachment: fixed; /*关键*/
        background-position: center;
        transform: scale(1);
        opacity: 1;
        transform-origin: 50% 50%;
        transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
          opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      > div:nth-child(6):hover .backgroundContainer {
        background: url("../assets/home/bg6.png");
        background-size: 100% 100%;
        background-size: cover;
        background-repeat: no-repeat;
        // background-attachment: fixed; /*关键*/
        background-position: center;
        transform: scale(1);
        opacity: 1;
        transform-origin: 50% 50%;
        transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
          opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
  .demoVideo {
    .demoVideoTitle {
      padding: 8rem 0 4rem 0;
      > div:nth-child(1) {
        color: #224b77;
        font-weight: 700;
        font-size: 2.6rem;
      }
      > div:nth-child(2) {
        margin-top: 1rem;
        color: #224b77;
        font-size: 1.2rem;
      }
    }
    .videoWarp {
      width: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
      .video {
        width: 70rem;
        // height: 40rem;
        background-color: #333;
        > video {
          width: 100%;
        }
      }
    }
    .buttonWarp {
      text-align: center;
      margin: 3rem 0 3rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 10rem;
        height: 3rem;
        outline: none;
        border: 1px solid #fff;
        background-color: #699bff;
        color: #fff;
        font-size: 0.8rem;
        border-radius: 1.5rem;
        transition: background 0.3s;
        > img {
          margin-left: 0.3rem;
          width: 1rem;
        }
      }
      > button:hover {
        background: #8fb5ff;
      }
    }
  }
  .share {
    background-color: #fff;
    .shareTitle {
      // padding: 2rem 0;
      padding: 8rem 0 4rem 0;
      > div:nth-child(1) {
        color: #224b77;
        font-weight: 700;
        font-size: 2.6rem;
      }
      > div:nth-child(2) {
        margin-top: 1rem;
        color: #224b77;
        font-size: 1.2rem;
      }
    }
    @media (min-width: 1920px) {
      .shareContant {
        padding: 0 6vw;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        flex-wrap: wrap;
        > div {
          width: calc(20% - 2rem);
          box-sizing: border-box;
          // margin: 1rem;
          text-align: left;
        }
        .shareItem {
          width: 18%;
          // height: 10rem;
          text-align: left;
          // padding: 1rem;
          box-sizing: border-box;
          position: relative;
          padding-bottom: 16.5%;
          margin-right: 1%;
          margin-left: 1%;
          float: left;
          > div {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            .expImgWarp {
              border: none;
              background-color: #ccc;
              width: 100%;
              overflow: hidden;
              border-radius: 1rem;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 60%;
              cursor: pointer;
              position: relative;
              > div {
                width: 15%;
                position: absolute;
                bottom: 6%;
                left: 3%;
                // background: #fff;
                border-radius: 14%;
                display: flex;
                justify-content: center;
                align-items: center;
                > div {
                  width: 100%;
                  > img {
                    width: 100%;
                  }
                }
              }
              > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              img {
                width: 100%;
                // height: 5rem;
                border: none;
              }
            }
            > p {
              font-size: 0.7vw;
              font-weight: 600;
              line-height: 1.05vw;
              height: 2vw;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              margin-top: 5px;
              margin-bottom: 5px;
              color: #262626;
            }
            .operation {
              font-size: 0.7vw;
              color: #888;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-top: 2%;
              :deep(.dropDown) {
                width: 2rem;
                height: 1rem;
                position: absolute;
                right: 0;
              }
              > span {
                // margin-right: 1.5vw;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                // width: 2vw;
                // height: 1vw;
                padding-right: 0.85vw;
                line-height: 1vw;
                > img {
                  width: 0.7vw;
                  height: 0.7vw;
                  margin-right: 0.3vw;
                }
              }
              .more {
                margin: 0;
                position: absolute;
                right: 0;
                display: flex;
                justify-content: right;
                align-items: center;
                font-size: 0.9vw;
                cursor: pointer;
                top: -26%;
                > i {
                  transform: rotate(-90deg);
                }
              }
            }
          }
        }
      }
    }
    @media (min-width: 0px) and (max-width: 1919px) {
      .shareContant {
        padding: 0 6vw;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        flex-wrap: wrap;
        > div {
          width: calc(20% - 2rem);
          box-sizing: border-box;
          // margin: 1rem;
          text-align: left;
        }
        .shareItem {
          width: 23%;
          // height: 10rem;
          text-align: left;
          // padding: 1rem;
          box-sizing: border-box;
          position: relative;
          padding-bottom: 21.5%;
          margin-right: 1%;
          margin-left: 1%;
          float: left;
          > div {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            .expImgWarp {
              border: none;
              background-color: #ccc;
              width: 100%;
              overflow: hidden;
              border-radius: 1rem;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 60%;
              cursor: pointer;
              position: relative;
              > div {
                width: 15%;
                position: absolute;
                bottom: 6%;
                left: 3%;
                // background: #fff;
                border-radius: 14%;
                display: flex;
                justify-content: center;
                align-items: center;
                > div {
                  width: 100%;
                  > img {
                    width: 100%;
                  }
                }
              }
              > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              img {
                width: 100%;
                // height: 5rem;
                border: none;
              }
            }
            > p {
              font-size: 0.85vw;
              font-weight: 600;
              line-height: 1.1vw;
              height: 2.5vw;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              margin-top: 5px;
              margin-bottom: 5px;
              color: #262626;
            }
            .operation {
              font-size: 0.9vw;
              color: #888;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-top: 2%;
              :deep(.dropDown) {
                width: 2rem;
                height: 1rem;
                position: absolute;
                right: 0;
              }
              > span {
                // margin-right: 1.5vw;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                // width: 2vw;
                // height: 1vw;
                padding-right: 0.85vw;
                line-height: 1vw;
                > img {
                  width: 1vw;
                  height: 1vw;
                  margin-right: 0.3vw;
                }
              }
              .more {
                margin: 0;
                position: absolute;
                right: 0;
                display: flex;
                justify-content: right;
                align-items: center;
                font-size: 1.2vw;
                cursor: pointer;
                top: -26%;
                > i {
                  transform: rotate(-90deg);
                }
              }
            }
          }
        }
      }
    }

    .joinBtn {
      text-align: center;
      padding: 1.5rem 0 5rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      > button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 10rem;
        height: 3rem;
        outline: none;
        border: 1px solid #fff;
        background-color: #699bff;
        color: #fff;
        font-size: 0.8rem;
        border-radius: 1.5rem;
        transition: background 0.3s;
        > img {
          margin-left: 0.3rem;
          width: 1rem;
        }
      }
      > button:hover {
        background: #8fb5ff;
      }
    }
  }
  .collaborator {
    .collaboratorTitle {
      padding: 8rem 0 0rem 0;
      > div:nth-child(1) {
        color: #224b77;
        font-weight: 700;
        font-size: 2.6rem;
      }
      > div:nth-child(2) {
        margin-top: 1rem;
        color: #224b77;
        font-size: 1.2rem;
      }
    }
    .collaboratorItemWarp {
      background: url("../assets/home/map.png") no-repeat center center;
      background-size: 100% 114%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 80%;
      margin-left: 10%;
      padding: 7% 0;

      > div {
        width: 14%;
        background-color: rgba(0, 0, 0, 0);
        margin-top: 3%;
        overflow: hidden;
        border-radius: 8px;
        box-sizing: border-box;
        // padding: 0 1rem;
        box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.06);
        background: #fff;
        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          overflow: hidden;
          > img {
            width: 100%;
            background: #fff;
            // height: ;
          }
        }
      }
      .lefts {
        margin-left: 5%;
      }
      .rights {
        margin-right: 5%;
      }
    }
    .collaboratorBtn {
      margin: 2.5rem 0 5rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      > button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 15rem;
        height: 3rem;
        outline: none;
        border: 4px solid #ffe4b8;
        background-color: #deb573;
        color: #fff;
        font-size: 0.8rem;
        border-radius: 1.5rem;
        transition: background 0.3s, border 0.3s;
        > img {
          margin-left: 0.3rem;
          width: 1rem;
        }
      }
      > button:hover {
        background: #e7c796;
        // border: 4px solid #e7c796;
      }
    }
  }
  .download {
    background-color: #fff;
    .downloadTitle {
      padding: 8rem 0 4rem 0;
      > div:nth-child(1) {
        color: #224b77;
        font-weight: 700;
        font-size: 2.6rem;
      }
      > div:nth-child(2) {
        margin-top: 1rem;
        color: #224b77;
        font-size: 1.2rem;
      }
    }
    .qrCodeWarp {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-bottom: 5rem;
      padding-left: 25%;
      padding-right: 25%;
      > .QrcodeItem {
        width: 12rem;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        > p {
          color: #699bfe;
          font-size: 1rem;
          font-weight: 500;
        }
        > .Qrcode {
          width: 10rem;
          height: 10rem;
          border-radius: 1rem;
          box-sizing: border-box;
          overflow: hidden;
          // margin-left: 1rem;
          border: 4px solid #9ebeff;
          display: flex;
          justify-content: center;
          align-items: center;
          > img {
            width: 100%;
          }
        }
        .hololensbg {
          background: url("../assets/home/hololensbg.png");
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          .warp {
            > p {
              margin: 0;
              color: #fff;
            }
            .type {
              font-size: 22px;
              margin-bottom: 10px;
              font-weight: 600;
            }
            .versionNo {
              font-size: 12px;
              margin-bottom: 5px;
            }
            .date {
              font-size: 12px;
              margin-bottom: 14px;
            }
            .link {
              text-decoration: none;
              color: #fff;
              font-size: 14px;
              font-weight: 500;
            }
            .linkLhover {
              cursor: pointer;
              text-decoration: underline 1px solid #fff;
            }
          }
        }
      }
    }
  }
  .footer {
    padding: 0.5rem 0;
    > p {
      > a {
        text-decoration: none;
        color: #333;
        transition: all 0.3s;
      }
      > a:hover {
        color: #4984f5;
      }
    }
  }
}
/* 当屏幕宽度在 0 到 1200 像素之间时应用的样式 */
@media screen and (max-width: 1200px) {
  .home {
    .header {
      > .headerBodyWarp {
        padding-bottom: 18%;
        > .paddingBox {
          > .headerBody {
            > .titleTip {
              > div {
                margin-top: 0;
                > button {
                  margin-top: 1.5vw;
                  font-size: 12px;
                  min-width: 140px;
                }
              }
              > div:nth-child(1) {
                margin-top: 1.5vw;
                > button {
                  border: 2px;
                }
                > p:nth-child(2) {
                  width: 35vw;
                }
              }
              > div:nth-child(2) {
                margin-top: 0.5vw;
                > button {
                  border: 2px;
                }
              }
              > div:nth-child(3) {
                margin-top: 0;
                > button {
                  border: 2px;
                }
              }
            }
          }
        }
      }
    }
    > .canDo {
      overflow: hidden;
      > .title {
        padding: 4rem 0 4rem 0;
        > div:nth-child(1) {
          font-size: 1.6rem;
        }
        > div:nth-child(2) {
          font-size: 0.9rem;
        }
      }
      > .canDoItem {
        > div {
          // >.backgroundContainer{

          // }
          > .contant {
            padding-left: 3rem;
            width: 49%;
            height: 33.33%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            > p {
              width: 100%;
            }
            > p:nth-child(1) {
              font-size: 0.7rem;
              margin-top: 0;
            }
            > p:nth-child(2) {
              font-size: 0.6rem;
            }
            > p:nth-child(3) {
              margin-top: 0;
              span {
                font-size: 0.5rem;
              }
            }
          }
        }
      }
    }
    > .demoVideo,
    > .share {
      > .demoVideoTitle,
      > .shareTitle {
        padding: 4rem 0 4rem 0;
        // font-size: 1.6rem;
        > div:nth-child(1) {
          font-size: 1.6rem;
        }
        > div:nth-child(2) {
          font-size: 0.9rem;
        }
      }
    }
    > .share {
      > .shareContant {
        > .shareItem {
          > div {
            > .expImgWarp {
              border-radius: 0.4rem;
              > div {
                width: 25%;
              }
            }
            > p {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              font-size: 13px;
              line-height: 2.5vw;
            }
            > .operation {
              font-size: 10px;
              > span {
                > img {
                  width: 10px;
                  height: 10px;
                }
              }
            }
          }
        }
      }
      // >.joinBtn{
      //   padding-bottom: 3rem;
      // }
    }
    > .collaborator {
      > .collaboratorTitle {
        padding: 4rem 0 0 0;
        > div:nth-child(1) {
          font-size: 1.6rem;
        }
        > div:nth-child(2) {
          font-size: 0.9rem;
        }
      }
      > .collaboratorItemWarp {
        > .collaboratorItem {
          border-radius: 4px;
        }
      }
      > .collaboratorBtn {
        margin: 1.5rem 0 5rem 0;
      }
    }
    > .download {
      > .downloadTitle {
        padding: 4rem 0 4rem 0;
        > div:nth-child(1) {
          font-size: 1.6rem;
        }
        > div:nth-child(2) {
          font-size: 0.9rem;
        }
      }
      > .qrCodeWarp {
        padding: 0 10% 5rem 10%;
        > .QrcodeItem {
          width: 33%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          > p {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}
</style>
