
const AuditStatusType = {
    withdraw: 0,
    tobeAudit: 1,
    passed: 2,
    rejected: 3
  }
  
  const PublicScopeType = {
    enterpriceOnly: -1,
    all: 999
  }
  

  
module.exports = {
    AuditStatusType,
    PublicScopeType
}