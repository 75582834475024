<template>
  <div class="registerWarp">
    <!-- <div class="login">密码登录</div> -->
    <div class="resetSuccess">
      <div class="successImg">
        <img src="@/assets/login/success.png" alt="" />
      </div>
      <!-- 密码已重置 -->
      <div class="successTip1">{{$t('resetPass.resetResult')}}</div>
      <!-- 恭喜您,您的密码已重置成功! -->
      <div class="successTip2">{{$t('resetPass.resetPasswordRes')}}</div>
      <div class="successButten">
        <!-- 开始使用 -->
        <button @click="goLogin">{{$t('resetPass.go')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    goLogin() {
      this.$store.commit("SET_SHOWlOGIN", true);
      this.$store.commit("SET_ACCOUNTSIGN", "login");
    },
  },
};
</script>
<style scoped lang='scss'>
.registerWarp {
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: 26rem;
  }
  .resetSuccess {
    .successTip1 {
      color: #4582ff;
      font-size: 14px;
      font-weight: bold;
      margin: 1rem 0 0.5rem 0;
    }
    .successTip2 {
      color: #7590b2;
      font-size: 14px;
    }
    .successButten {
      margin-top: 4rem;
      text-align: right;
      > button {
        width: 120px;
        height: 32px;
        background: #4582ff;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        outline: none;
        color: #fff;
        border: none;
        cursor: pointer;
        font-size: 14px;
        transition: background 0.1s;
      }
      >button:hover{
        background: #8FB5FF;
      }
    }
  }
}
</style>