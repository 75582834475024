<template>
  <div class="body">
    <div class="bodyWarp">
      <div class="logoWarp">
        <span>
          <!-- <img
            src="../assets/indexPic/indexBigLogo.png"
            alt="世界技能大赛-上海"
            class="logoPng"
          /> -->
        </span>
      </div>
      <div class="formWarp">
        <div class="form">
          <el-form
            :model="loginForm"
            v-loading="loading"
            element-loading-text="登录中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(238, 238, 238, 1)"
            status-icon
            :rules="rules"
            ref="loginForm"
            label-width="0px"
            class="demo-ruleForm formItemUsername"
          >
            <el-form-item prop="username">
              <span class="usernameWord"
                >用户名&nbsp;<span>Username</span></span
              >
              <el-input
                placeholder="用户名"
                v-model="loginForm.username"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item
              prop="password"
              @keyup.enter="submitForm('loginForm')"
            >
              <span class="usernameWord">密码&nbsp;<span>Password</span></span>
              <el-input
                type="password"
                placeholder="密码"
                v-model="loginForm.password"
                clearable
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <div>
                <el-button type="primary" @click="submitForm('loginForm')"
                  >登 录</el-button
                >
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      loading: false,
      loginForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, message: "密码长度不能小于6位", trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    submitForm(formName) {
      const $this = this;
      $this.loading = true;

      this.$refs[formName].validate((valid) => {
        if (valid) {
          // clear errors
          $this.errors = [];
          $this.$auth.login(
            $this.loginForm,
            // error
            (error) => {
              $this.errors = [
                {
                  message: "用户名或密码错误。",
                  path: ["password"],
                },
              ];
            },
            // final
            () => {
              $this.loading = false;
            }
          );
        } else {
          console.log("error submit!!");
          $this.loading = false;
          return false;
        }
      });
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
.body {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
}
.bodyWarp {
  width: 40rem;
  //   position: fixed;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
}
.logoWarp {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 0 auto 2rem;
  box-sizing: border-box;
  padding: 0 1.5rem;
}
.logoPng {
  width: 100%;
}
.form {
  width: 100%;
  margin: 0rem auto 0;
}
.usernameWord {
  color: #686868;
  padding-left: 2.5rem;
  > span {
    font-size: 0.8rem;
  }
}
.formItemUsername {
  width: 66%;
  margin: auto;
  > div {
    margin-bottom: 10px;
  }
  > div:nth-child(3) {
    > div {
      > div {
        display: flex;
        justify-content: center;
        > button {
          width: 10rem;
          margin: 4rem auto 0rem;
        }
      }
    }
  }
}
</style>
