import { getSpeechToken } from "@/api/workflow.js"
import { getLanguageToSpeak, getLanguage } from "@/utils/getLanguage.js"
export function getSpeak(inputText,language) {
    return new Promise(async (resolve, reject) => {
        var sdk = require("microsoft-cognitiveservices-speech-sdk");
        const { PassThrough } = require('stream');
        // const speechConfig = sdk.SpeechConfig.fromSubscription("aa71c49e3316423b99d30bb3984583dd", "chinaeast2");
        let token = await getSpeechToken();
        // console.log(token.result.data, token.result.region);
        const speechConfig = sdk.SpeechConfig.fromAuthorizationToken(token.data.token, token.data.region);
        let languageType = getLanguageToSpeak(language);
        speechConfig.speechSynthesisLanguage = languageType;
        console.log();
        // speechConfig.speechSynthesisVoiceName = "zh-CN-XiaoxiaoNeural";
        // speechConfig.speechRecognitionLanguage = "friendly";
        // console.log(speechConfig);
        // Set the output format
        speechConfig.speechSynthesisOutputFormat = sdk.SpeechSynthesisOutputFormat.Audio16Khz32KBitRateMonoMp3;
        var ssml = "";
        switch (languageType) {
            case "ko-KR":
                ssml = `
                <speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis"
                    xmlns:mstts="https://www.w3.org/2001/mstts" xml:lang="ko-KR">
                    <voice name="ko-KR-JiMinNeural">
                        <mstts:express-as style="friendly"> ${inputText} </mstts:express-as>
                    </voice>
                </speak>`
                break;
            case "zh-CN":
                ssml = `
                <speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis"
                    xmlns:mstts="https://www.w3.org/2001/mstts" xml:lang="zh-CN">
                    <voice name="zh-CN-XiaoxiaoNeural">
                        <mstts:express-as style="friendly"> ${inputText} </mstts:express-as>
                    </voice>
                </speak>`;
                break
            default:
                ssml = `
                <speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis"
                    xmlns:mstts="https://www.w3.org/2001/mstts" xml:lang="en-US">
                    <voice name="en-US-JaneNeural">
                        <mstts:express-as style="friendly"> ${inputText} </mstts:express-as>
                    </voice>
                </speak>`
                break;
        }
        const speechSynthesizer = new sdk.SpeechSynthesizer(speechConfig, null);
        speechSynthesizer.speakSsmlAsync(
            ssml,
            result => {
                console.log(inputText);
                // Interact with the audio ArrayBuffer data
                const audioData = result.audioData;
                console.log(`Audio data byte size: ${audioData.byteLength}.`)
                console.log(result);
                speechSynthesizer.close();
                const bufferStream = new PassThrough();
                bufferStream.end(Buffer.from(audioData));
                resolve(audioData)
            },
            error => {
                console.log(error);
                speechSynthesizer.close();
                reject(false)
            });
    })

}