import { h } from "@logicflow/core";
import { RectResize } from "@logicflow/extension";
class HexagonModel extends RectResize.model {
    getDefaultAnchor() {
        const { width, height, x, y, id } = this;
        return [
            {
                x: x - width / 2 + 0.15 * width / 3,//原位置平移半个平行四边形偏移量再减去一个锚点直径
                y,
                name: 'left',
                id: `${id}_0`
            },
            {
                x: x + width / 2 - 0.15 * width / 3,
                y,
                name: 'right',
                id: `${id}_1`,
            },
            {
                x,
                y: y - height / 2,
                name: 'top',
                id: `${id}_2`
            },
            {
                x,
                y: y + height / 2,
                name: 'bottom',
                id: `${id}_3`
            },
        ]
    }
    setAttributes() {
        this.text.x = this.x;
        this.text.y = this.y;
        this.menu = [
            {
                className: "items",
                //复制属性
                text: `${this.properties.language.copy} Ctrl + C`,
                callback: (node) => {
                    this.graphModel.eventCenter.emit("nodeCopy", node);
                },
            },
            {
                className: "items",
                // 克隆步骤
                text: `${this.properties.language.clone} Ctrl + D`,
                callback: (node) => {
                    this.graphModel.eventCenter.emit("nodeClone", node);
                },
            },
            {

                className: "items",
                // 设为起始步骤
                text: `${this.properties.language.setStartStep}`,
                callback: (node) => {
                    this.graphModel.eventCenter.emit("nodeSetStart", node);
                },
            },
            {
                //删除
                text: `${this.properties.language.delete} Delete`,
                callback: (node) => {
                    this.graphModel.eventCenter.emit("nodeDelete", node);
                },
            },
        ];
        if (this.properties.stepInfo.instanceShapes && this.properties.stepInfo.instanceShapes.length > 0) {
            this.menu.splice(2, 1)
        }
    }
    initNodeData(data) {
        super.initNodeData(data);
        this.width = 160;
        this.height = 64;
        const rule1 = {
            message: "不允许连接到'开始步骤'",
            validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
                if (targetNode.type === "StartShape") {
                    return false
                } else {
                    return true
                }
            }
        }
        const rule2 = {
            message: "'输入步骤'只允许有一个出口",
            validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
                let edgesList = sourceNode.graphModel.edges;
                let hasEdges = edgesList.find((item) => {
                    return item.sourceNode.id === sourceNode.id;
                });
                if (hasEdges) {
                    return false
                } else {
                    return true
                }
            }
        }
        const rule3 = {
            message: "不允许连接到自己",
            validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
                if (sourceNode.id === targetNode.id) {
                    return false
                } else {
                    return true
                }
            }
        }
        this.sourceRules.push(rule1, rule2, rule3);
    }
    //重写节点文本样式
    getTextStyle() {
        const style = super.getTextStyle();
        style.padding = "0 20%"
        // if (this.properties.stepInfo.instanceShapes && this.properties.stepInfo.instanceShapes.length > 0) {
        if (this.properties.cloneNodeActive) {
            style.color = "#333333"
        } else {
            style.color = "#333333"
        }
        // } else {
        //     if (this.properties.cloneNodeActive) {
        //         style.color = "#333333"
        //     } else {
        //         style.color = "#ffffff"
        //     }
        // }
        return style;
    }
    //重写节点样式
    getNodeStyle() {
        const style = super.getNodeStyle();
        style.strokeWidth = 2;
        // if (this.isSelected) {
        //     style.stroke = "#00de24"
        // } else {
        //     style.stroke = "#4582ff"
        // }

        //在"开始步骤"的链接树上
        if (this.properties.onLinkLine) {
            style.strokeDasharray = "0,0";
            //克隆母体高亮状态
            if (this.properties.cloneNodeActive || this.isSelected) {
                style.strokeDasharray = "0";
                style.fill = "#ffffff";
                style.stroke = "#4582FF"
            } else {

                style.fill = "#fff";
                style.stroke = "#000"
            }
            //webgl页面被选中的外边框高亮状态
            if (this.properties.isWebGl || this.properties.isAuthor) {
                if (this.isSelected) {
                    style.stroke = "#4582FF"
                } else {
                    style.stroke = "#000"
                }
            }
        } else {
            style.strokeDasharray = "4,4";
            //是克隆母体
            if (this.properties.cloneNodeActive || this.isSelected) {
                style.strokeDasharray = "0";
                style.stroke = "#4582FF"
                style.fill = "#fff";
            } else {
                style.fill = "#fff";
                style.stroke = "#000"
            }
            //webgl页面被选中的外边框高亮状态
            if (this.properties.isWebGl || this.properties.isAuthor) {
                if (this.isSelected) {
                    style.stroke = "#4582FF"
                } else {
                    style.stroke = "#000"
                }
            }
        }

        return style;
    }
    //重写锚点样式
    getAnchorStyle(anchorInfo) {
        const style = super.getAnchorStyle(anchorInfo);
        // style.fill = "#32ff32";
        // style.stroke = "#32ff32";
        // style.fill = "#4582FF";
        style.stroke = "#4582FF";
        style.r = 8;
        style.strokeWidth = 2;
        style.hover.fill = "transparent";
        style.hover.stroke = "transpanrent";
        style.className = "lf-hide-default";
        return style;
    }
    //重写节点外边框样式
    getResizeOutlineStyle() {
        return {
            strokeWidth: 2,
            // stroke: "#32ff32",
            stroke: "#4582FF",
            x: this.x - this.width / 2 - 0.5,
            y: this.y - this.height / 2 - 0.5,
        }
    }
    //重写节点缩放四个点样式
    getControlPointStyle() {
        return {
            strokeWidth: 2,
            width: 14,
            height: 14,
            fill: "#FFFFFF",
            // stroke: "#32ff32",
            stroke: "#4582FF",
            // x: this.x - this.width / 2 - 3.5,
            // y: this.y - this.height / 2 - 3.5,
        };
    }

}

class HexagonView extends RectResize.view {
    getResizeShape() {
        const { x, y, width, height } = this.props.model;
        const style = this.props.model.getNodeStyle();
        const pointList = [
            // [x - 0.25 * width, y - 0.5 * height],
            // [x + 0.25 * width, y - 0.5 * height],
            [x - 0.4 * width, y - 0.5 * height],
            [x + 0.5 * width, y - 0.5 * height],
            [x + 0.4 * width, y + 0.5 * height],
            [x - 0.5 * width, y + 0.5 * height]
        ];
        const points = pointList.map((item) => {
            return `${item[0]},${item[1]}`;
        });
        const attrs = {
            ...style,
            x,
            y,
            width,
            height,
            points: points.join(" ")
        };

        return h("g", {}, [h("polygon", { ...attrs })]);
    };
}

export default {
    type: "InputShape",
    view: HexagonView,
    model: HexagonModel
};
