<template>
  <div class="qrcodes" :id="id"></div>
</template>

<script>
import QRCode from "../utils/qrcode.js";
const QRErrorCorrectLevel = {
  L: 1,
  M: 0,
  Q: 3,
  H: 2,
};

export default {
  // props: {
  //   typeNumber: Number,
  //   correctLevel: Number,
  //   text: String,
  //   width: Number,
  //   height: Number,
  // },
  props: ["typeNumber", "correctLevel", "text", "width", "height"],
  data() {
    return {
      id: "",
      content: "",
    };
  },
  computed: {},
  watch: {
    text: function (val) {
      this.qrcode?.clear();
      this.qrcode?.makeCode(val);
    },
  },
  created() {
    this.id = "qrcode-" + this.text.projectGuid;
    this.$nextTick(() => {
      const options = {
        typeNumber: 1,
        correctLevel: QRErrorCorrectLevel.M,
        width: this.width,
        height: this.height,
      };
      this.qrcode = new QRCode(this.id, options);
      // let base64obj = new Base64();
      let QRCodeInfo = JSON.stringify({
        // projectName: this.text.projectName,
        projectGuid: this.text.projectGuid,
        releaseGuid: this.text.releaseGuid ? this.text.releaseGuid : null,
      });
      console.log(QRCodeInfo);
      this.qrcode.makeCode(QRCodeInfo);
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.qrcodes {
  display: flex;
  justify-content: center;
  > img {
    width: 100%;
  }
}
</style>
