<template>
  <div class="logicflowWrap" :class="readOnly ? 'readOnlyLogicflowWrap' : ''">
    <div
      class="canvasCover"
      :class="logicFlowLoading ? 'coverActive' : ''"
      v-loading="logicFlowLoading"
    ></div>
    <div class="ctrlWarps" v-if="!readOnly">
      <div class="ctrls">
        <!-- 框选 -->
        <el-tooltip
          v-if="isCreator"
          class="item"
          effect="light"
          :content="$t('taskChart.word1')"
          placement="bottom"
          :show-after="500"
        >
          <button
            :class="selectLine === 5 ? 'active' : ''"
            @click="startTask(5, $event)"
            :disabled="!isCreator"
          >
            <img
              src="@/assets/task-detail/selectHover.webp"
              v-if="selectLine === 5"
              alt=""
            />
            <img v-else src="@/assets/task-detail/select.webp" alt="" />
          </button>
        </el-tooltip>
        <!-- 拖动 -->
        <el-tooltip
          class="item"
          effect="light"
          :content="$t('taskChart.drag')"
          placement="bottom"
          :show-after="500"
        >
          <button
            :class="selectLine === 6 ? 'active' : ''"
            @click="startTask(6, $event)"
          >
            <img
              src="@/assets/task-detail/dragHover.webp"
              v-if="selectLine === 6"
              alt=""
            />
            <img v-else src="@/assets/task-detail/drag.webp" alt="" />
          </button>
        </el-tooltip>
      </div>
      <div v-if="isCreator" class="selfLine"></div>
      <div v-if="isCreator" class="lines">
        <!-- 操作步骤 -->
        <el-tooltip
          class="item"
          effect="light"
          :content="$t('taskChart.word2')"
          placement="bottom"
          :show-after="500"
        >
          <button
            :disabled="!isCreator"
            :class="selectLine === 2 ? 'active' : ''"
            @click="startTask(2, $event)"
          >
            <img
              src="@/assets/task-detail/ordinaryStepHover.png"
              v-if="selectLine === 2"
              alt=""
            />
            <img v-else src="@/assets/task-detail/ordinaryStep.png" alt="" />
          </button>
        </el-tooltip>
        <!-- 判定步骤 -->
        <el-tooltip
          class="item"
          effect="light"
          :content="$t('taskChart.word3')"
          placement="bottom"
          :show-after="500"
        >
          <button
            :disabled="!isCreator"
            :class="selectLine === 3 ? 'active' : ''"
            @click="startTask(3, $event)"
          >
            <img
              src="@/assets/task-detail/judgeStepHover.png"
              v-if="selectLine === 3"
              alt=""
            />
            <img v-else src="@/assets/task-detail/judgeStep.png" alt="" />
          </button>
        </el-tooltip>
        <!-- 输入步骤 -->
        <!-- v-if="projectInfo && projectInfo.editType === 1" -->
        <el-tooltip
          class="item"
          effect="light"
          :content="$t('taskChart.inputStep')"
          placement="bottom"
          :show-after="500"
        >
          <button
            :disabled="!isCreator"
            :class="selectLine === 7 ? 'active' : ''"
            @click="startTask(7, $event)"
          >
            <img
              src="@/assets/task-detail/inputShapeHover.png"
              v-if="selectLine === 7"
              alt=""
            />
            <img v-else src="@/assets/task-detail/inputShape.png" alt="" />
          </button>
        </el-tooltip>
        <el-dropdown
          trigger="click"
          @visible-change="visibleChange"
          @command="selectTaskItem"
        >
          <el-tooltip
            class="item"
            effect="light"
            :content="$t('taskChart.taskStep')"
            placement="bottom"
            :show-after="500"
          >
            <button
              :disabled="!isCreator"
              :class="selectLine === 7 ? '' : ''"
              @click="startTask(8, $event)"
              class="myTaskBtn"
            >
              <img
                src="@/assets/task-detail/newTask.png"
                v-if="selectLine === 7"
                alt=""
              />
              <img v-else src="@/assets/task-detail/newTask.png" alt="" />
              <div class="line"></div>
              <i class="el-icon-arrow-down downArrow"></i>
            </button>
          </el-tooltip>
          <template #dropdown>
            <el-dropdown-menu>
              <!-- 新建任务 -->
              <el-dropdown-item
                command="createTask"
                class="createTaskQuick"
                icon="el-icon-circle-plus-outline"
                >{{ $t("taskChart.newTask") }}</el-dropdown-item
              >
              <el-dropdown-item
                :command="item"
                class="createTaskQuick"
                v-for="(item, index) in dropdownList"
                :key="index"
                >{{ item.taskName }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="scaleDropdown">
        <el-select
          style="width:7rem"
          v-model="selectScale"
          size="mini"
          placeholder=""
          @change="scaleChange"
        >
          <el-option
            v-for="item in scaleList"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="containerWrap">
      <div
        ref="container"
        id="container"
        class="logicflowBody"
        :class="
          selectLine === 2
            ? 'defaultStep'
            : selectLine === 3
            ? 'judgeStep'
            : selectLine === 7
            ? 'inputStep'
            : ''
        "
      ></div>
      <!-- :style="{
          width: readOnly
            ? '100%'
            : isSelectShape || isShowHistory || isShowProjectInfo
            ? 'calc(100% - 28rem)'
            : '100%',
        }" -->
      <!-- <EditStep
        v-if="!readOnly"
        ref="editStep"
        class="others"
        :isCreator="isCreator"
        :projectInfo="projectInfo"
        :own="own"
        @setStepName="setStepName"
        @setDescription="setDescription"
        @setLinkName="setLinkName"
        @onNeedSave="dataNeedSave"
        @backPoisition="changeSwitch"
        @saveHistory="noAutoSaveHistory"
        @setOwn="setOwn"
        @projectNameChange="projectNameChange"
      ></EditStep> -->
    </div>

    <!-- v-loading="flowChartLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)" -->
    <!-- <div class="buttons">
      <el-button @click="addStart">新增开始步骤</el-button>
      <el-button @click="addActioon">新增普通步骤</el-button>
      <el-button @click="addTask">新增任务步骤</el-button>
      <el-button @click="addJudge">新增判断步骤</el-button>
      <el-button @click="getSelectElements">获取当前选中的元素</el-button>
      <el-button @click="openSelectionSelect">开启框选</el-button>
      <el-button @click="closeSelectionSelect">关闭框选</el-button>
    </div> -->
  </div>
</template>

<script>
//把logicflow挂载到vue实例外面
var lf;
import moment from "moment";
// import LogicFlow from "@/plugins/logicflow/core";
// import "@/plugins/logicflow/core/dist/style/index.css";
// import "@/plugins/logicflow/extension/lib/style/index.css";
import LogicFlow from "@logicflow/core";
import "@logicflow/core/dist/style/index.css";
import "@logicflow/extension/lib/style/index.css";
import { DndPanel, Menu, SelectionSelect } from "@logicflow/extension";
// LogicFlow.use(SelectionSelect);
import Temp from "./logicflow_ui_1/temp.js";
import StartShape from "./logicflow_ui_1/startShape.js";
import ActionShape from "./logicflow_ui_1/actionShape.js";
import CloneActionShape from "./logicflow_ui_1/cloneActionShape.js";
import TaskShape from "./logicflow_ui_1/taskShape.js";
import JudgeShape from "./logicflow_ui_1/judgeShape.js";
import CloneJudgeShape from "./logicflow_ui_1/cloneJudgeShape.js";
import InputShape from "./logicflow_ui_1/inputShape.js";
import CloneInputShape from "./logicflow_ui_1/cloneInputShape.js";
import PolylineConfig from "./logicflow_ui_1/polylineConfig.js";
import { uuid } from "vue-uuid";
import { ElMessage } from "element-plus";
import {
  getSelectedGuid,
  setSelectedGuid,
  removeSelectedGuid,
} from "../../components/taskChart/setSelectShape";
import {
  getTaskById,
  saveTaskSteps,
  generateHistory,
  getStepCheckpoint,
  getStepInfo,
  updateStepInfo,
} from "@/api/workflow.js";
import EditStep from "../editStep/editStep.vue";
export default {
  components: { EditStep },
  props: [
    "projectInfo",
    "taskGuid",
    "isCreator",
    "templateNeedSave",
    "auditReleaseGuid",
    "auditReleaseMode",
  ],
  data() {
    return {
      selectedNode: null,
      refreshDown: true,
      cacheSelect: {
        nodes: [],
        edges: [],
      },
      setZoomMaxSize: 2,
      isDrag: false,
      defaultInputInfo: {
        appearType: 0,
        delaySeconds: 0,
        index: 0,
        panelType: 3,
        stepExtend: "",
        stepObjectJson: JSON.stringify({
          type: 5,
          medias: [
            {
              type: 1, //多媒体框
              index: 0, //此模板中json数据中 多媒体文件在列表中的index
              value: null,
              tag: "0",
              title: "",
              content: "",
              webNeedSaveFlag: false,
              filePath: null,
              thumbnailPath: null, //video和图片有缩略图
            },
          ],
          texts: [
            {
              type: 0, //文本框
              index: 0, //此模板中json数据中 文本文件在列表中的index
              value: null,
              tag: "0",
              title: "",
              content: "",
              webNeedSaveFlag: false,
              filePath: null,
            },
          ],
        }),
      },
      canvasPosition: null,
      selectScale: 100,
      scaleList: [
        {
          value: 200,
        },
        {
          value: 150,
        },
        {
          value: 120,
        },
        {
          value: 100,
        },
        {
          value: 80,
        },
        {
          value: 50,
        },
        {
          value: 20,
        },
      ],
      doHistory: null,
      defaultNodeSize: {
        width: 160,
        height: 64,
      },
      minSize: {
        width: 80,
        height: 32,
      },
      needSave: false,
      saveHistoryInterval: null,
      rightClickPosition: "",
      logicFlowLoading: false,
      copyList: [],
      // shapeItem: "",
      readOnly: false,
      readOnlyRouterList: ["/webGL"],
      flowChartLoading: false,
      dropdownList: [],
      selectLine: false,
      highlightList: [],
      // selectModel: {
      //   nodes: [],
      //   edges: [],
      // },
      selectNodes: null,
      renderObj: {
        nodes: [],
        edges: [],
      },
      onLineNodes: [],
      config: {
        isSilentMode: !this.isCreator, //只读模式
        edgeType: "polyline",
        hideAnchors: !this.isCreator,
        edgeSelectedOutline: this.isCreator,
        edgeTextEdit: this.isCreator,
        adjustEdge: this.isCreator,
        adjustNodePosition: this.isCreator,
        nodeTextEdit: false,
        history: this.isCreator,
        // adjustEdgeStartAndEnd: this.isCreator,
        adjustEdge: this.isCreator,
        grid: {
          size: 16, //step移动步长
          type: "dot", //dot点 mesh网格
          visible: true,
          config: {
            color: "#ddd", //网格线颜色
            // thickness: 1, //网格线宽度
          },
        },
        background: {
          // backgroundImage: "url(../../../../assets/login/background.svg)",
          backgroundRepeat: "repeat",
          backgroundColor: "#F8FAFB",
        },
        //快捷键
        keyboard: {
          enabled: this.isCreator,
          shortcuts: [
            {
              keys: ["backspace", "delete"],
              callback: this.deleteSelectedElements,
            },
            {
              keys: ["ctrl+d", "cmd+d"],
              callback: this.cloneNodes,
            },
            {
              keys: ["ctrl+c", "cmd+c"], // cmd+c for mac
              callback: this.copyNodes,
            },
            {
              keys: ["ctrl+v", "cmd+v"], // cmd+v for mac
              callback: this.keyBoardPaste,
            },
            {
              keys: ["ctrl+s", "cmd+s"],
              callback: this.keyBoardSaveAll,
            },
            //已绑定在组件"taskDetailNew.vue上作为全局事件",不作为流程图的快捷键
            // {
            //   keys: ["ctrl+alt+s"],
            //   callback: this.noAutoSaveHistory2,
            // },
            
          ],
        },
      },
      //step默认数据模板
      propertiesTemplateStep: {
        chartJson: "{}",
        description: null,
        instanceShapes: [],
        isInstance: null,
        isTask: null,
        refTaskGuid: null,
        sourceStepGuid: null,
        stepAssets: [],
        stepGuid: "",
        stepName: null,
        taskGuid: "",
      },
      //link默认数据模板
      propertiesTemplateLink: {
        chartJson: "{}",
        description: null,
        endStepGuid: "",
        startStepGuid: "",
        linkGuid: "",
        linkName: "",
        taskGuid: "",
      },
    };
  },
  computed: {
    isSelectShape() {
      return this.$store.state.TaskDetail.selectShape;
    },
    isShowHistory() {
      return this.$store.state.TaskDetail.showHistory;
    },
    isShowProjectInfo() {
      return this.$store.state.TaskDetail.showProjectInfo;
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      console.log(this.projectInfo);
      console.log(this.isCreator);
      // this.loadShape();
      if (this.readOnlyRouterList.includes(this.$route.path)) {
        this.readOnly = true;
      } else {
        this.readOnly = false;
        // this.config.keyboard = true;
        // this.config.keyboard.shortcuts = this.shortcuts;
      }
      if (!this.projectInfo) {
        return;
      }
      lf = new LogicFlow({
        plugins: [DndPanel, Menu, SelectionSelect],
        container: this.$refs.container,
        ...this.config,
      });
      // const { editConfigModel } = lf.graphModel;
      // editConfigModel.updateEditConfig({
      //   adjustEdgeMiddle: true,
      //   edgeTextDraggable: this.isCreator,
      // });
      //注册自定义形状
      lf.register(Temp);
      lf.register(StartShape);
      lf.register(ActionShape);
      lf.register(TaskShape);
      lf.register(JudgeShape);
      lf.register(CloneActionShape);
      lf.register(CloneJudgeShape);
      lf.register(InputShape);
      lf.register(CloneInputShape);
      lf.register(PolylineConfig);
      //给创作者绑定各种回调
      //鼠标抬起事件，用来区分是否是画布拖动事件
      lf.on("blank:mousedown", this.canvasMousedown);
      lf.on("blank:mouseup", this.canvasClick);
      lf.on("node:click", this.selectElement);
      lf.on("blank:dragstart", this.blankDragstart);
      lf.on("blank:drag", this.blankDrag);
      // lf.on("blank:drop", this.blankDrop);
      lf.setTheme({
        nodeText: {
          color: "#000000",
          overflowMode: "autoWrap",
          lineHeight: 1.5,
          fontSize: 14,
          color: "#fff",
        },
        arrow: {
          offset: 4, // 箭头垂线长度
          verticalLength: 2, // 箭头底线长度
        },
      });
      if (this.isCreator) {
        //监听logicflow事件
        lf.on("edge:dbclick", this.edgeDbClick);
        lf.on("anchor:drop", this.drawSuccess);
        lf.on("edge:delete", this.deleteEdgesSuccess);
        lf.on("selection:selected", this.finishSelect);
        lf.on("edge:click", this.lineClick);
        lf.on("node:delete", this.deleteSuccess);
        lf.on("blank:contextmenu", this.canvasRightClick);
        //节点缩放结束松开鼠标
        lf.on("node:resize", this.nodeResize);
        lf.on("history:change", this.canvasChange);
        lf.on("edge:adjust", this.stratEndChange);
        //画布缩放
        lf.on("graph:transform", this.zoomChange);
        //画布node文案更新
        lf.on("text:update", this.textChange);
        //needsave事件
        //节点拖拽结束
        lf.on("node:dragstart", this.dragStart);
        //边拖拽调整
        lf.on("edge:adjust", this.edgeDrag);
        //调整变得起点、终点
        lf.on("edge:exchange-node", this.edgeChangeExtremePoint);
        //选取开始拖拽事件
        lf.on("selection:drag", this.selectionDrag);
        //边连接成功
        lf.on("edge:add", this.edgeAddSuccess);
        lf.on("nodeCopy", this.onNodeCopy);
        lf.on("nodeClone", this.onNodeClone);
        lf.on("nodeSetStart", this.onNodeSetStart);
        lf.on("nodeDelete", this.onNodeDelete);
        //拖拽菜单
        // lf.extension.dndPanel.setPatternItems([
        //   {
        //     type: "action",
        //     text: "",
        //     label: "操作步骤",
        //     icon: require("../../../../assets/task-detail/ordinaryStep.png"),
        //   },
        //   {
        //     type: "judge",
        //     text: "",
        //     label: "判断步骤",
        //     icon: require("../../../../assets/task-detail/judgeStep.png"),
        //     callback: this.test,
        //   },
        // ]);
        //text样式

        //右键菜单
        lf.extension.menu.setMenuByType(
          {
            type: "polyline",
            menu: [
              {
                className: "delete",
                text: this.$t("taskChart.delete") + " Delete",
                callback: this.deleteSelectedElements,
              },
            ],
          },
          {
            type: "lf:defaultSelectionMenu",
            menu: [
              {
                className: "delete",
                text: this.$t("taskChart.delete") + " Delete",
                callback: this.deleteSelectedElements,
              },
            ],
          }
        );
        lf.extension.menu.setMenuByType({
          type: "lf:defaultSelectionMenu",
          menu: [
            {
              className: "items",
              // 复制属性
              text: this.$t("taskChart.copy") + " Ctrl + C",
              callback: this.onNodeCopy,
            },
            {
              className: "items",
              // 克隆步骤
              text: this.$t("taskChart.clone") + " Ctrl + D",
              callback: this.onNodeClone,
            },
            {
              // 删除
              text: this.$t("taskChart.delete") + " Delete",
              callback: this.deleteSelectedElements,
            },
          ],
        });
        lf.extension.menu.addMenuConfig({
          graphMenu: [
            {
              className: "items",
              text: this.$t("taskChart.paste"),
              callback: this.canvasRightPaste,
            },
          ],
        });
      }
      lf.render();
      lf.setZoomMaxSize(this.setZoomMaxSize);
      window.addEventListener("keydown", (e) => {
        // console.log('keydown', e);
        if (
          (e.keyCode == 68 && e.ctrlKey) ||
          (e.keyCode == 83 && e.ctrlKey) ||
          (e.keyCode == 46 && e.ctrlKey) 
        ) {
          e.preventDefault();
        }
      });
      this.$store.commit("SET_PROJECTINFO", true);
      if (this.isCreator && !this.readOnly) {
        this.saveHistoryInterval = setInterval(async () => {
          // console.log();
          if (this.needSave || this.templateNeedSave) {
            console.log("保存历史版本");
            await this.saveAll(false, false);
            this.$emit("saveTemplate");
            this.saveHistoryWrap(1);
          }
        }, 10 * 60 * 1000);
        // }, 10000);
      }
    });
  },
  unmounted() {
    clearInterval(this.saveHistoryInterval);
    this.saveHistoryInterval = null;
    window.removeEventListener("keydown", (e) => {
      if ((e.keyCode == 68 && e.ctrlKey) || (e.keyCode == 83 && e.ctrlKey)) {
        e.preventDefault();
      }
    });
  },
  watch: {
    // selectedNode: {
    //   handler(newVal, oldVal) {
    //     console.log(newVal);
    //     if (newVal) {
    //       if (newVal.type === "action" || newVal.type === "CloneActionShape") {

    //       }
    //     } else {
    //       this.defaultNodeSize = {
    //         width: 160,
    //         height: 64,
    //       };
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    projectInfo: {
      async handler(newVal, oldVal) {
        console.log(newVal, oldVal);
        await this.filterSelf(this.taskGuid, newVal.tasks);
      },
    },
    taskGuid: {
      async handler(newVal, oldVal) {
        removeSelectedGuid();
        // await this.getTaskInfoById(newVal);
        this.$store.commit("SET_PROJECTINFO", true);
        this.$store.commit("SET_SHOWHISTORY", false);
        this.$store.commit("SET_SELECTSHAPE", false);
        let res = await this.getTaskInfoById(newVal);
        this.$nextTick(async () => {
          //ui更新之后，检查此任务中是否存在开始步骤
          //加载流程的时候如果没有开始步骤，就自动创建一个，并自动保存一下
          if (!res.beginStepGuid) {
            //不存在开始步骤，save一个开始步骤重新加载
            let dom = this.$refs.container;
            let containerWidth = dom.offsetWidth;
            let containerHieght = dom.offsetHeight;
            //画布正中心的位置
            let screenCenter = {
              x: containerWidth / 2,
              y: containerHieght / 2,
            };
            let id = uuid.v4();
            let chartJsonObj = {
              type: "action",
              bbox: {
                ...screenCenter,
                ...this.defaultNodeSize,
              },
            };
            let params = {
              chartJson: chartJsonObj,
              links: [],
              stepAssets: [],
              stepGuid: id,
              taskGuid: newVal,
            };
            let taskCommitOptions = {
              beginStepGuid: id,
              links: [],
              steps: [params],
              taskGuid: newVal,
            };
            try {
              await saveTaskSteps(taskCommitOptions);
            } catch (e) {
              // "开始步骤创建失败！请重新进入此任务"
              ElMessage.error(this.$t("taskChart.tip14"));
            }
          }
          console.log(newVal);
          await this.loadShape();

          //头部按钮显示可拖动状态
          let modelData = lf.graphModel.modelToGraphData();
          console.log(modelData);
          console.log(lf);
          this.startTask(6);
        });
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async clearStepObject(id) {
      let res = await this.apiGetNodeById(id);
      console.log(res);
      let option = res.properties.stepInfo;
      delete option.stepObject;
      if (res) {
        lf.setProperties(id, {
          stepInfo: option,
        });
      }
      let res1 = await this.apiGetNodeById(id);
      console.log(res1);
    },
    //更新dropdownList
    async updateDropdownList(tasks) {
      console.log(tasks);
      console.log(this.projectInfo.tasks);
      this.refreshDown = false;
      await this.filterSelf(this.taskGuid, tasks);
      console.log(this.dropdownList);
      this.refreshDown = true;
      let modelData = lf.graphModel.modelToGraphData();
      modelData.nodes.forEach(async (item) => {
        if (item.properties.stepInfo.refTaskGuid) {
          try {
            let taskInfo = await this.getTaskInfoById(
              item.properties.stepInfo.refTaskGuid
            );
            lf.updateText(item.id, taskInfo.taskName);
          } catch (e) {
            console.log(e);
          }
        }
      });
    },
    //计算两个点的距离
    calculateDistance(x1, y1, x2, y2) {
      const dx = x2 - x1;
      const dy = y2 - y1;
      const distance = Math.sqrt(dx * dx + dy * dy);
      return distance;
    },
    //输出节点本身的四个锚点的坐标
    printAnchor(node) {
      console.log(node);
      let width = node.properties.nodeSize.width;
      let height = node.properties.nodeSize.height;
      let anchorTop = {
        x: node.x,
        y: node.y - height / 2,
      };
      let anchorBottom = {
        x: node.x,
        y: node.y + height / 2,
      };
      let anchorLeft = {
        x: node.x - width / 2,
        y: node.y,
      };
      let anchorRight = {
        x: node.x + width / 2,
        y: node.y,
      };
      //输入步骤的左右两点位置经过计算，所以需要微调
      if (node.type == "InputShape" || node.type == "CloneInputShape") {
        // let anchorTop = {x:,y:,}
        anchorLeft = {
          x: node.x - width / 2 + (0.3 * width) / 2 + 5,
          y: node.y,
        };
        anchorRight = {
          x: node.x - width / 2 - (0.3 * width) / 2 - 5,
          y: node.y,
        };
      }
      return {
        anchorTop,
        anchorBottom,
        anchorLeft,
        anchorRight,
      };
    },
    //连接线调整改变了起始点或终点
    stratEndChange(data) {
      console.log(data);
      //获取链接线前后的节点
      let prevNode = this.apiGetNodeById(data.data.sourceNodeId);
      let nextNode = this.apiGetNodeById(data.data.targetNodeId);
      //计算两个节点的四个锚点位置
      let prevAnchors = this.printAnchor(prevNode);
      let nextAnchors = this.printAnchor(nextNode);
      console.log(prevAnchors);
      console.log(data);
      //比较移动的链接线是否是起点或终点的链接线
      let isStart = false;
      for (let key in prevAnchors) {
        if (prevAnchors.hasOwnProperty(key)) {
          console.log(prevAnchors[key]);
          console.log(data.data.startPoint);
          if (
            prevAnchors[key].x === data.data.startPoint.x &&
            prevAnchors[key].y === data.data.startPoint.y
          ) {
            isStart = true;
          }
        }
      }
      console.log(isStart);
      //拿到链接线起始和末尾的两个点
      let sourceAnchor = data.data.pointsList[0];
      let targetAnchor = data.data.pointsList[data.data.pointsList.length - 1];
      //计算起始位置的链接线端点和起始节点四个锚点的位置 计算最短的锚点位置
      let instanceList = [];
      for (let key in prevAnchors) {
        if (prevAnchors.hasOwnProperty(key)) {
          instanceList.push({
            type: key,
            value: this.calculateDistance(
              prevAnchors[key].x,
              prevAnchors[key].y,
              sourceAnchor.x,
              sourceAnchor.y
            ),
          });
        }
      }
      console.log(prevAnchors);
      console.log(instanceList);
      // instanceList.push(
      //   this.calculateDistance(
      //     prevAnchors.anchorBottom.x,
      //     prevAnchors.anchorBottom.y,
      //     sourceAnchor.x,
      //     sourceAnchor.y
      //   )
      // );
      // instanceList.push(
      //   this.calculateDistance(
      //     prevAnchors.anchorLeft.x,
      //     prevAnchors.anchorLeft.y,
      //     sourceAnchor.x,
      //     sourceAnchor.y
      //   )
      // );
      // instanceList.push(
      //   this.calculateDistance(
      //     prevAnchors.anchorRight.x,
      //     prevAnchors.anchorRight.y,
      //     sourceAnchor.x,
      //     sourceAnchor.y
      //   )
      // );
      let finalList = instanceList.sort((a, b) => {
        return a.value - b.value;
      });
      console.log(finalList);
      // let
      //计算结束位置的链接线断电和结束节点四个锚点的位置 计算最短的锚点位置
      //吸附开始位置和结束位置到最近的一个锚点上
    },
    //选中缓存列表中被选中的元素们
    seleceCache() {
      this.cacheSelect.nodes.forEach((node) => {
        lf.graphModel.selectElementById(node.id, true);
      });
      this.cacheSelect.edges.forEach((edge) => {
        lf.graphModel.selectElementById(edge.id, true);
      });
    },
    //鼠标落下 缓存当前选中的所有元素 同时把"是否拖动"设置为false
    canvasMousedown() {
      let modeData = lf.graphModel.getSelectElements(true);
      console.log('canvasMousedown', modeData);
      this.cacheSelect = modeData;
      this.isDrag = false;
    },
    //拖动途中 把"是否拖动"设置为true 同时选中缓存列表中的元素
    blankDrag() {
      this.isDrag = true;
      this.seleceCache();
    },
    //拖动开始 把"是否拖动"设置为true 同时选中缓存列表中的元素
    blankDragstart() {
      this.isDrag = true;
      this.seleceCache();
    },
    //删除操作历史 参数 条数
    deleteHistory(num) {
      for (let i = 0; i < num; i++) {
        lf.history.undos.pop();
      }
    },
    //缩小倍率\改变
    scaleChange(value) {
      let x = this.canvasPosition.centerX + this.canvasPosition.left;
      let y = this.canvasPosition.centerY + this.canvasPosition.top / 2;
      lf.fitView(100, 100);
      lf.zoom(value / 100, [x, y]);
    },
    //画布缩放
    zoomChange(data) {
      let scale = data.transform.SCALE_X;
      this.selectScale = parseInt(scale.toFixed(2) * 100);

      // lf.zoom(data.scale, { x, y });
    },
    //画布变动
    canvasChange(dataList) {
      console.log(dataList);
      console.log(lf);
    },
    //node文案更新
    textChange(data) {
      let linkInfo = lf.getDataById(data.id);
      // this.$refs.editStep.init({ data: linkInfo });
      this.$emit("textChange", { data: linkInfo });
      this.dataNeedSave(true);
    },
    //关闭步骤配置项
    closeStepEdit() {
      this.$refs.editStep.init(false);
    },
    //连线被双击，隐藏右侧连接线属性面板
    edgeDbClick() {
      // this.$refs.editStep.init(false);
      // this.setStoreStatus(false, false, true);
    },
    //快捷键保存
    keyBoardSaveAll() {
      if (this.needSave) {
        this.saveAll(true);
      }
    },
    //接口：外部调用返回开始步骤的GUID
    apiGetStartNodeGuid() {
      let modelData = lf.graphModel.modelToGraphData();
      let nodes = modelData.nodes;
      let startNode = nodes.find((item) => {
        return item.type === "StartShape";
      });
      if (startNode) {
        return startNode;
      } else {
        if (nodes.length > 0) {
          return nodes[0];
        } else {
          return null;
        }
      }
    },
    //接口：外部调用 获取id对应的节点详情
    apiGetNodeById(nodeId) {
      console.log(nodeId);
      console.log(lf.graphModel.modelToGraphData());
      let dataSource = lf.graphModel.modelToGraphData();
      let targetNode = dataSource.nodes.find((item) => item.id === nodeId);
      console.log(targetNode);
      return targetNode;
    },
    //接口 外部调用 根据节点ID获取其所有链接边的信息
    apiGetEdgeByNodeId(nodeId) {
      let dataSource = lf.graphModel.modelToGraphData();
      let res = {
        prev: [],
        next: [],
      };
      dataSource.edges.filter((item) => {
        if (item.targetNodeId === nodeId) {
          let node = this.apiGetNodeById(item.sourceNodeId);
          console.log(node);
          res.prev.push(node);
          // res.prev.push(item);
        }
        if (item.sourceNodeId === nodeId) {
          res.next.push(item);
        }
      });
      return res;
    },
    //接口：外部调用 选中步骤
    apiSelectById(nodeId) {
      lf.graphModel.selectElementById(nodeId, false);
    },
    noAutoSaveHistory2() {
      this.noAutoSaveHistory(0);
    },
    //组件传回保存历史版本 手动保存
    noAutoSaveHistory(method) {
      console.log(11111);
      this.saveAll(false, false);
      this.saveHistoryWrap(method);
    },
    //实时保存历史记录 type === 0:自动保存 type === 1:手动保存
    saveHistoryWrap(type) {
      let date = new Date();
      this.saveHistory(
        this.projectInfo.projectGuid,
        moment(date).format("L") + " " + moment(date).format("LTS"),
        type
      );
    },
    //快捷键粘贴
    keyBoardPaste() {
      let XList = [];
      let YList = [];
      console.log('keyboradpaste', this.copyList);
      this.copyList.forEach((item) => {
        XList.push(item.x);
        YList.push(item.y);
      });
      XList.sort((a, b) => {
        return a - b;
      });
      YList.sort((a, b) => {
        return a - b;
      });
      let minx = XList[0];
      let miny = YList[0];
      let options = {
        x: minx + 25,
        y: miny + 25,
      };
      this.pasteNode(options);
    },
    //右键粘贴
    canvasRightPaste() {
      let position = lf.graphModel.getPointByClient({
        x: this.rightClickPosition.e.x,
        y: this.rightClickPosition.e.y,
      });
      let options = {
        x: position.canvasOverlayPosition.x,
        y: position.canvasOverlayPosition.y,
      };
      this.pasteNode(options);
    },
    //右键菜单
    canvasRightClick(e) {
      console.log(e);
      this.rightClickPosition = e;
    },
    //克隆节点源节点和克隆节点的文字颜色白黑色切换
    //静默模式下，选中状态
    //自动保存，十分钟保存一次

    //文字框随外框大小变动
    //保存历史版本
    async saveHistory(projectGuid, historyName, isAuto) {
      let options = {
        projectGuid,
        historyName,
        generateType: isAuto ? 0 : 1,
      };
      // console.log(this.$store.state);
      // console.log(this.$store.state);
      await generateHistory(options);
      if (this.$store.state.TaskDetail.showHistory) {
        // this.$refs.editStep.refreashHistoryList();
        this.$emit("refreashHistoryList");
      }
    },
    //自定义事件 删除节点
    onNodeDelete(node) {
      this.deleteSelectedElements();
    },
    //自定义事件 设为开始步骤
    onNodeSetStart(node) {
      this.setStartNode(node);
    },
    //自定义事件克隆node
    onNodeClone(node) {
      this.cloneNodes();
    },
    //自定义事件删除node
    onNodeCopy(node) {
      this.copyNodes();
    },
    //设为起始步骤
    setStartNode(node) {
      console.log(node);
      let modelData = lf.graphModel.modelToGraphData();
      let oldStartShape = modelData.nodes.find(
        (node) => node.type === "StartShape"
      );
      lf.graphModel.changeNodeType(oldStartShape.id, "action");
      lf.graphModel.changeNodeType(node.id, "StartShape");
      //删除连向起始步骤的线
      let deleteEdges = lf.graphModel.getNodeIncomingEdge(node.id);
      let deleteItem = {
        edges: [...deleteEdges],
        nodes: [],
      };
      this.deleteModelItem(deleteItem);
      this.checkIsOnLinkLine();
    },
    //保存逻辑 保存所有节点和连线
    async saveAll(isReRender, showTip) {
      this.logicFlowLoading = true;
      let modelData = lf.graphModel.modelToGraphData();
      console.log(modelData);
      let StartShape = modelData.nodes.find(
        (node) => node.type === "StartShape"
      );
      let options = {
        beginStepGuid: StartShape.id,
        links: [],
        steps: [],
        taskGuid: this.taskGuid,
      };
      //处理edges的数据为可提交数据
      modelData.edges.forEach((edge) => {
        //给edges生成json
        let rowDirection = "";
        let temp = edge.pointsList;
        let directionPointa = [temp[temp.length - 2], temp[temp.length - 1]];
        if (directionPointa[0].x === directionPointa[1].x) {
          //是纵向箭头
          if (directionPointa[0].y - directionPointa[1].y > 0) {
            //向上
            rowDirection = "t";
          } else {
            //向下
            rowDirection = "b";
          }
        } else {
          //是横向箭头
          if (directionPointa[0].x - directionPointa[1].x > 0) {
            //向左
            rowDirection = "l";
          } else {
            //向右
            rowDirection = "r";
          }
        }
        let chartJsonObj = {
          ...(edge.properties.linkInfo
            ? JSON.parse(edge.properties.linkInfo.chartJson)
            : {}),
          type: edge.type,
          pointsList: edge.pointsList,
          startPoint: edge.startPoint,
          endPoint: edge.endPoint,
          text: edge.text,
          dirNext: rowDirection,
        };
        //生成linksItem
        let linkItemOptions = {
          ...(edge.properties.linkInfo ? edge.properties.linkInfo : {}),
          linkGuid: edge.id,
          chartJson: JSON.stringify(chartJsonObj),
          description: "",
          linkName: edge.text
            ? edge.text.value
            : edge.properties.linkInfo
            ? edge.properties.linkInfo.linkName
            : "",
          endStepGuid: edge.targetNodeId,
          startStepGuid: edge.sourceNodeId,
          taskGuid: this.taskGuid,
        };
        options.links.push(linkItemOptions);
      });
      //处理nodes的数据为可提交数据
      modelData.nodes.forEach(async (node) => {
        //给node生成json
        let chartJsonObj = {
          ...(node.properties.stepInfo
            ? JSON.parse(node.properties.stepInfo.chartJson)
            : {}),
          type: node.type,
          bbox: {
            x: node.x,
            y: node.y,
            width: node.properties.nodeSize
              ? node.properties.nodeSize.width
              : this.defaultNodeSize.width,
            height: node.properties.nodeSize
              ? node.properties.nodeSize.height
              : this.defaultNodeSize.height,
          },
        };
        //生成可提交stepItem
        let stepOptions = {
          ...(node.properties.stepInfo ? node.properties.stepInfo : {}),
          chartJson: JSON.stringify(chartJsonObj),
          links: [],
          stepAssets: [],
          stepGuid: node.id,
          taskGuid: this.taskGuid,
        };
        delete stepOptions.instanceShapes;
        //如果是复制出来的步骤 那么就需要saveStepInfo进行保存 node的StepInfo中会出现stepObject的字段

        options.steps.push(stepOptions);
      });
      // console.log(modelData);
      console.log(options);
      try {
        await saveTaskSteps(options);
        await modelData.nodes.forEach(async (node) => {
          if (node.properties.stepInfo && node.properties.stepInfo.stepObject) {
            let temp = node.properties.stepInfo.stepObject;
            let updateOption = {
              taskGuid: this.taskGuid,
              stepGuid: node.id,
              panelType: temp.panelType,
              appearType: 0,
              index: 0,
              delaySeconds: 0,
              stepObjectJson: temp.stepObjectJson,
            };
            let res1 = await updateStepInfo(updateOption);
            this.clearStepObject(node.id);
          }
        });
        this.dataNeedSave(false);
        if (showTip) {
          ElMessage.success({
            //保存成功
            message: this.$t("taskChart.saveSuccess"),
            type: "success",
          });
        }

        if (isReRender) {
          this.loadShape();
        }
      } catch (e) {
        if (showTip) {
          ElMessage.error(this.$t("taskChart.saveError"));
        }
      }
      this.logicFlowLoading = false;
    },
    //节点删除成功
    deleteSuccess() {
      this.dataNeedSave(true);
      this.checkIsOnLinkLine();
    },
    //边连接成功
    edgeAddSuccess(data, e) {
      console.log(data, e);
      lf.setProperties(data.data.id, {
        linkInfo: this.propertiesTemplateLink,
      });
      // this.checkIsOnLinkLine();
      this.dataNeedSave(true);
    },
    //节点大小改变
    nodeResize({ oldNodeSize, newNodeSize }) {
      let nodeData = this.apiGetNodeById(newNodeSize.id);
      this.selectedNode = nodeData;
      this.dataNeedSave(true);
    },
    //选取开始拖拽
    selectionDrag() {
      this.dataNeedSave(true);
    },
    //边的起点、终点调整
    edgeChangeExtremePoint() {
      this.dataNeedSave(true);
    },
    //边拖拽调整
    edgeDrag() {
      this.dataNeedSave(true);
      console.log(lf.graphModel.modelToGraphData());
    },
    //节点开始拖拽
    dragStart() {
      this.dataNeedSave(true);
    },
    //给流程图设置"是否需要保存"的状态
    //needSave
    dataNeedSave(data) {
      console.log("needSave", data);
      this.$emit("needSave", data);
      this.needSave = data;
    },
    //克隆节点
    cloneNodes(e) {
      console.log("克隆步骤快捷键");
      if (!this.isCreator || this.readOnly) {
        return;
      }
      let pasteList = [];
      // window.preventDefault();
      let elements = lf.getSelectElements();

      let selectNodes = elements.nodes;
      console.log(selectNodes);
      selectNodes.forEach((item) => {
        if (item.type !== "StartShape" && item.type !== "pointer") {
          let id = uuid.v4();
          let options = {
            ...item,
            id,
          };
          switch (options.type) {
            case "action":
              options.type = "CloneActionShape";
              break;
            case "judge":
              options.type = "CloneJudgeShape";
              break;
            case "InputShape":
              options.type = "CloneInputShape";
              break;
            case "CloneActionShape":
              options.type = "CloneActionShape";
              break;
            case "CloneJudgeShape":
              options.type = "CloneJudgeShape";
              break;
            case "CloneInputShape":
              options.type = "CloneInputShape";
              break;
            default:
              break;
          }
          //把复制出来的图形相比于上次向右平移一个图形的宽+20px
          let leftWidth = options.properties.nodeSize.width + 20;
          options.text ? (options.text.x = options.text.x + leftWidth) : "";
          options.x = options.x + leftWidth;
          let sourceStepInfo = item.properties.stepInfo;
          //重设stepInfo的数据，防止同源
          let stepInfoOptions = {
            chartJson: "{}",
            description: sourceStepInfo.description,
            instanceShapes: [],
            isInstance: true,
            isTask: null,
            refTaskGuid: null,
            sourceStepGuid: sourceStepInfo.sourceStepGuid
              ? sourceStepInfo.sourceStepGuid
              : item.id,
            stepAssets: [],
            stepGuid: id,
            stepName: sourceStepInfo.stepName,
            taskGuid: this.taskGuid,
          };
          options.properties.stepInfo = stepInfoOptions;
          options.properties.cloneNodeActive = false;
          options.properties.onLinkLine = false;
          pasteList.push(options);
          // item.properties.stepInfo.instanceShapes.push(options);

          console.log(item);
          if (
            options.type === "action" ||
            options.type === "CloneActionShape"
          ) {
            this.addCloneActioon(options);
          } else if (
            options.type === "judge" ||
            options.type === "CloneJudgeShape"
          ) {
            this.addCloneJudge(options);
          } else if (
            options.type === "InputShape" ||
            options.type === "CloneInputShape"
          ) {
            this.addCloneInput(options);
          }
          //重新设置源步骤中克隆步骤的列表
          //寻找源步骤
          let modelData = lf.graphModel.modelToGraphData();
          let sourceStep = modelData.nodes.find(
            (item) => item.id === stepInfoOptions.sourceStepGuid
          );

          let stepInfoTemp = sourceStep.properties;
          stepInfoTemp.stepInfo.instanceShapes.push(options);
          //给克隆源步骤的克隆列表设置新成员
          lf.setProperties(
            sourceStepInfo.sourceStepGuid
              ? sourceStepInfo.sourceStepGuid
              : item.id,
            stepInfoTemp
          );
          //取消被赋值的元素的选中状态
          lf.clearSelectElements();
          //把复制出来的元素框选出来方便直接进行操作
          pasteList.forEach((item) => {
            lf.selectElementById(item.id, true, true);
          });
        }
      });
    },
    //复制节点 仅限判断节点，普通节点可复制
    copyNodes() {
      if (!this.isCreator || this.readOnly) {
        return;
      }
      // console.log('copy node')
      let selectElement = lf.getSelectElements();
      let selectNodes = selectElement.nodes;
      this.copyList = selectNodes;
      console.log(this.copyList);
    },
    //粘贴节点
    async pasteNode(position) {
      console.log("粘贴步骤快捷键");
      console.log(position);
      if (!this.isCreator) {
        return;
      }
      let pasteList = [];
      for (let i = 0; i < this.copyList.length; i++) {
        const item = this.copyList[i];
        if (item.type !== "StartShape" && item.type !== "pointer") {
          let id = uuid.v4();
          let options = {
            ...item,
            id,
          };
          switch (options.type) {
            case "action":
              options.type = "action";
              break;
            case "judge":
              options.type = "judge";
              break;
            case "CloneActionShape":
              options.type = "action";
              break;
            case "CloneJudgeShape":
              options.type = "judge";
              break;
            case "CloneInputShape":
              options.type = "InputShape";
              break;
            case "InputShape":
              options.type = "InputShape";
              break;
            default:
              break;
          }
          //把复制出来的图形相比于上次像右下方平移半个图形的宽高
          let leftWidth = options.properties.nodeSize.width / 2.5;
          let bottomHeight = options.properties.nodeSize.height / 2.5;
          if (!position) {
            options.text ? (options.text.x = options.text.x + leftWidth) : "";
            options.text
              ? (options.text.y = options.text.y + bottomHeight)
              : "";
            options.x = options.x + leftWidth;
            options.y = options.y + bottomHeight;
          } else {
            let XList = [];
            let YList = [];
            this.copyList.forEach((value) => {
              XList.push(value.x);
              YList.push(value.y);
            });
            XList.sort((a, b) => {
              return a - b;
            });
            YList.sort((a, b) => {
              return a - b;
            });
            let minx = XList[0];
            let miny = YList[0];
            let step = this.config.grid.size;
            // options.text
            //   ? (options.text.x =
            //       Math.round(
            //         (position.x + leftWidth + (options.x - minx)) / step
            //       ) * step)
            //   : "";
            // options.text
            //   ? (options.text.y =
            //       Math.round(
            //         (position.y + bottomHeight + (options.y - miny)) / step
            //       ) * step)
            //   : "";
            options.x =
              Math.round((position.x + leftWidth + (options.x - minx)) / step) *
              step;
            options.y =
              Math.round(
                (position.y + bottomHeight + (options.y - miny)) / step
              ) * step;
          }

          let sourceStepInfo = item.properties.stepInfo;
          //重设stepInfo的数据，防止同源
          let stepInfoOptions = {
            chartJson: "{}",
            description: sourceStepInfo.description,
            instanceShapes: [],
            isInstance: null,
            isTask: null,
            refTaskGuid: null,
            sourceStepGuid: null,
            stepAssets: [],
            stepGuid: id,
            stepName: sourceStepInfo.stepName,
            taskGuid: this.taskGuid,
          };
          console.log(item.properties.stepInfo);
          let step = item.properties.stepInfo;
          let checkPoints = item.properties.stepInfo.checkpoints;
          if (checkPoints) {
            stepInfoOptions.checkpoints = checkPoints;
          } else {
            try {
              let checkPointsList = await getStepCheckpoint(step.stepGuid);
              stepInfoOptions.checkpoints = checkPointsList.checkpoints;
            } catch (e) {
              stepInfoOptions.checkpoints = [];
            }
          }
          options.properties.stepInfo = stepInfoOptions;
          options.properties.cloneNodeActive = false;
          options.properties.onLinkLine = false;
          // if (options.type === "InputShape") {
          //2D模板复制素材(配置内容)
          if (item.properties.stepInfo.stepObject) {
            stepInfoOptions.stepObject = item.properties.stepInfo.stepObject;
          } else {
            console.log(stepInfoOptions);
            console.log(item);
            try {
              let res = await getStepInfo(
                stepInfoOptions.sourceStepGuid
                  ? stepInfoOptions.sourceStepGuid
                  : item.id
              );
              stepInfoOptions.stepObject = res;
            } catch (e) {
              console.log(e);
              stepInfoOptions.stepObject = this.defaultInputInfo;
            }
          }
          // }
          console.log(options);
          pasteList.push(options);
          if (
            options.type === "action" ||
            options.type === "CloneActionShape"
          ) {
            this.addActioon(options);
          } else if (
            options.type === "judge" ||
            options.type === "CloneJudgeShape"
          ) {
            this.addJudge(options);
          } else if (
            options.type === "InputShape" ||
            options.type === "CloneInputShape"
          ) {
            this.addInput(options);
          }
        }
        //取消被赋值的元素的选中状态
        lf.clearSelectElements();
        //把复制出来的元素框选出来方便直接进行操作
        if (pasteList.length > 1) {
          pasteList.forEach((item) => {
            lf.selectElementById(item.id, true, true);
          });
        } else {
          // lf.clearSelectElements();
          // this.canvasClick();
          this.selectedNode = null;
          this.$emit("editStepSetPanel", null);
        }
      }
      // this.copyList.forEach(async (item) => {

      // });
      this.copyList = pasteList;
    },
    //设置步骤名称
    setStepName(data) {
      // let node = lf.getModelById(data.id);
      // console.log(node);
      // console.log(lf.graphModel.textEditElement);
      // console.log(lf.graphModel.textEditElement());
      let options = {
        ...data.properties,
      };
      options.stepInfo.stepName = data.text;
      lf.updateText(data.id, data.text);
      lf.setProperties(data.id, options);

      if (data.properties.stepInfo.instanceShapes.length > 0) {
        //是克隆体源步骤
        data.properties.stepInfo.instanceShapes.forEach((item) => {
          let op = {
            ...item.properties,
          };
          op.stepInfo.stepNmae = data.text;
          lf.updateText(item.id, data.text);
          lf.setProperties(item.id, op);
        });
      }
    },
    //设置步骤详情
    setDescription(data) {
      let options = {
        ...data.properties,
      };
      options.stepInfo.description = data.text;
      lf.setProperties(data.id, options);
      if (data.properties.stepInfo.instanceShapes.length > 0) {
        //是克隆体源步骤
        data.properties.stepInfo.instanceShapes.forEach((item) => {
          let op = {
            // ...item.properties,
            stepInfo: item.properties.stepInfo,
          };
          op.stepInfo.description = data.text;
          lf.setProperties(item.id, op);
        });
      }
    },
    //设置连线名称
    setLinkName(data) {
      let options = {
        ...data.properties,
      };
      options.linkInfo.linkName = data.text;
      lf.updateText(data.id, data.text);
      lf.setProperties(data.id, options);
    },
    //新增"任务步骤"
    selectTaskItem(commad) {
      if (commad === "createTask") {
        this.$emit("onCreateTask", null);
      } else {
        console.log(commad);

        // let dom = this.$refs.container;
        // let containerWidth = dom.offsetWidth;
        // let containerHieght = dom.offsetHeight;
        // let positionInfo = lf.getPointByClient(
        //   containerWidth / 2,
        //   containerHieght / 2
        // );
        // console.log(positionInfo);
        // console.log([dom]);

        let id = uuid.v4();
        let x = this.canvasPosition.centerX + this.canvasPosition.left;
        let y = this.canvasPosition.centerY + this.canvasPosition.top / 2;
        let params = {
          id,
          x,
          y,
          text: commad.taskName,
          properties: {
            stepInfo: {
              ...this.propertiesTemplateStep,
              stepName: commad.taskName,
              isTask: true,
              refTaskGuid: commad.taskGuid,
              description: commad.description,
            },
          },
        };
        params.properties.stepInfo.stepGuid = id;
        params.properties.stepInfo.taskGuid = this.taskGuid;
        let pointerNodeId = this.addTask(params);

        lf.focusOn({
          coordinate: {
            x,
            y,
          },
        });
        console.log(lf.getNodeModelById(pointerNodeId));
        lf.selectElementById(pointerNodeId);
      }
    },
    //过滤"任务步骤"列表中的当前任务
    filterSelf(taskGuid, taskList) {
      return new Promise((reslove, reject) => {
        console.log(taskGuid);
        console.log(taskList);
        let arr = [];
        taskList.forEach((value, index) => {
          if (value.taskGuid !== taskGuid) {
            // taskList.splice(index, 1);
            arr.push(value);
          }
        });
        // return arr;
        console.log(arr);
        this.dropdownList = arr;
        reslove();
      });
    },
    test(data, e, e1) {
      console.log(data, e, e1);
    },
    //页面上端五个按钮点击事件
    startTask(select, e) {
      console.log(select);
      // if (this.selectLine === select) {
      //   this.selectLine = false;
      // } else {
      this.selectLine = select;
      // }
      //
      if (this.selectLine === 5) {
        this.openSelectionSelect();
      }
    },
    //从projectinfo中获取当前选中的task信息
    getTaskInfoByProjectInfo(taskGuid) {
      return this.projectInfo.tasks.find((item) => item.taskGuid === taskGuid);
    },
    //获取任务数据 如果没有开始步骤，会自动创建一个开始步骤并保存
    async getTaskInfoById(taskGuid) {
      let res;
      if (this.auditReleaseMode) {
        res = await getTaskById(
          taskGuid,
          this.projectInfo.auditReleaseGuid
            ? this.projectInfo.auditReleaseGuid
            : null
        );
      } else {
        res = await getTaskById(
          taskGuid,
          this.projectInfo.releaseGuid ? this.projectInfo.releaseGuid : null
        );
      }

      this.$emit("refreshTaskInfo", res);
      return res;
    },

    //获取数据处理数据兼容旧版本
    async loadShape() {
      this.logicFlowLoading = true;
      let taskInfo = await this.getTaskInfoById(this.taskGuid);
      let deleteRefStep = [];
      console.log(taskInfo);
      let model = {
        edges: [],
        nodes: [],
      };
      this.filterSelf(this.taskGuid, this.projectInfo.tasks);
      taskInfo.steps.forEach((item) => {
        item.instanceShapes = [];
      });
      for (let i = 0; i < taskInfo.steps.length; i++) {
        const item = taskInfo.steps[i];
        let chartJsonToObj = JSON.parse(item.chartJson);
        //初始化step信息
        let nodeOptions = {
          id: item.stepGuid,
          type: this.getTypeByStep(chartJsonToObj.type),
          x: chartJsonToObj.bbox.x,
          y: chartJsonToObj.bbox.y,
          properties: {
            nodeSize: {
              width: chartJsonToObj.bbox.width,
              height: chartJsonToObj.bbox.height,
            },
            stepInfo: item,
            language: {
              copy: this.$t("taskChart.copy"),
              clone: this.$t("taskChart.clone"),
              setStartStep: this.$t("taskChart.setStartStep"),
              delete: this.$t("taskChart.delete"),
            },
          },
          text: {
            x: chartJsonToObj.bbox.x,
            y: chartJsonToObj.bbox.y,
            value: item.stepName,
          },
        };
        //设置开始步骤
        if (taskInfo.beginStepGuid === item.stepGuid) {
          nodeOptions.type = "StartShape";
        }
        //把克隆步骤领出来重新设置其nodeType
        if (item.sourceStepGuid) {
          console.log(item);
          if (nodeOptions.type === "judge") {
            nodeOptions.type = "CloneJudgeShape";
          } else if (nodeOptions.type === "action") {
            nodeOptions.type = "CloneActionShape";
          }
        }
        //属于克隆步骤的步骤在其克隆源步骤下建立列表
        if (item.sourceStepGuid) {
          let sourceNode = taskInfo.steps.find(
            (value) => value.stepGuid === item.sourceStepGuid
          );
          //把源步骤的信息同步在自己身上；
          sourceNode.instanceShapes.push(nodeOptions);
          item.stepName = sourceNode.stepName;
          item.description = sourceNode.description;
          nodeOptions.text.value = sourceNode.stepName;
        }
        //如果这是一个任务步骤，那么就把这个任务步骤的名字找来自己用
        if (item.refTaskGuid) {
          try {
            let taskInfo = await this.getTaskInfoById(item.refTaskGuid);
            console.log(taskInfo);
            nodeOptions.text.value = taskInfo.taskName;
          } catch (e) {
            deleteRefStep.push(nodeOptions);
          }
        }
        model.nodes.push(nodeOptions);
      }
      for (let i = 0; i < taskInfo.links.length; i++) {
        const item = taskInfo.links[i];
        let chartJsonToObj = JSON.parse(item.chartJson);
        let edgeOptions = {
          id: item.linkGuid,
          type: this.config.edgeType,
          targetNodeId: item.endStepGuid,
          sourceNodeId: item.startStepGuid,
          properties: {
            linkInfo: {
              ...item,
            },
          },
          text: {
            value: item.linkName,
          },
        };
        //兼容旧版本 旧版本(chartJsonToObj.points包含起始两个点) 新版本(chartJsonToObj.pointsList包含这条折线的所有拐点)
        if (chartJsonToObj.pointsList) {
          //新版本
          console.log(chartJsonToObj);
          edgeOptions.pointsList = chartJsonToObj.pointsList;
        } else {
          //旧版本
        }
        //旧版本 旧版本需要重新计算折线的起始点 减去二分之一图形的宽高

        //寻找源图形
        let sourceStep = taskInfo.steps.find(
          (value) => value.stepGuid === item.startStepGuid
        );
        let sourceStepInfo = JSON.parse(sourceStep.chartJson);
        let needSubtraction = this.computeStartPolyline(sourceStepInfo);
        //兼容旧版本 旧版本(chartJsonToObj.points包含连线的起始点和结束点两个点) 新版本(chartJsonToObj.startPoint)(chartJsonToObj.endPoint)分别对应起始点和结束点
        if (chartJsonToObj.startPoint) {
          //新版本
          edgeOptions.startPoint = chartJsonToObj.startPoint;
        } else {
          //旧版本 旧版本需要重新计算折线的起始点

          //重新计算折线的起始点=[原宽-起始图形的1/2宽，原高-起始图形的1/2高]
          edgeOptions.startPoint = {
            x: chartJsonToObj.points[0][0] - needSubtraction.width,
            y: chartJsonToObj.points[0][1] - needSubtraction.height,
          };
        }
        //文字位置
        if (chartJsonToObj.text) {
          //新版本
          edgeOptions.text = chartJsonToObj.text;
        } else {
          //旧版本
        }
        if (chartJsonToObj.endPoint) {
          //新版本的结束点
          edgeOptions.endPoint = chartJsonToObj.endPoint;
        } else {
          //旧版本的结束点 重新计算
          edgeOptions.endPoint = {
            x: chartJsonToObj.points
              ? chartJsonToObj.points[chartJsonToObj.points.length - 1][0] -
                needSubtraction.width
              : null,
            y: chartJsonToObj.points
              ? chartJsonToObj.points[chartJsonToObj.points.length - 1][1] -
                needSubtraction.height
              : null,
          };
        }
        model.edges.push(edgeOptions);
      }
      console.log(model);
      lf.render(model);
      if (deleteRefStep.length > 0) {
        this.deleteModelItem({ nodes: deleteRefStep, edges: [] });
        //这一步不可被撤回，从撤回列表中删除此条记录
        this.deleteHistory(1);
        this.saveAll(false, false);
      }
      lf.fitView(100, 100);
      this.canvasPosition = this.findPosition();
      console.log(this.canvasPosition);
      // this.initNodePosition(model);
      await this.checkIsOnLinkLine();
      this.logicFlowLoading = false;
      this.$emit("onLogicFlowLoaded", lf);
      if (this.isCreator) {
        //清除历史记录的缓存
        lf.history.redos = [];
        lf.history.undos = [];
      }
      this.apiSelectById(getSelectedGuid());
    },
    //寻找画布容器距离浏览器顶部的距离 和 画布中心点坐标
    findPosition() {
      // var dom = document.getElementById('container');
      let dom = this.$refs.container;
      let iTop = 0;
      let iLeft = 0;
      do {
        iTop += dom.offsetTop; //如果是左侧就是offsetLeft
        iLeft += dom.offsetLeft;
        dom = dom.parentNode; //如果DOM 节点 的parentNode存在，把当前的节点赋予成parentNode；
      } while (dom.parentNode);
      // let result = {}
      let dom1 = this.$refs.container;
      let containerWidth = dom1.offsetWidth;
      let containerHieght = dom1.offsetHeight;
      let positionInfo = lf.getPointByClient(
        containerWidth / 2,
        containerHieght / 2
      );
      // console.log(positionInfo);
      // console.log([dom]);

      let x = positionInfo.canvasOverlayPosition.x;
      let y = positionInfo.canvasOverlayPosition.y;
      return {
        top: iTop,
        left: iLeft,
        centerX: x,
        centerY: y,
      };
    },
    //把所有node移动到屏幕中央并根据情况缩放画布 已弃用
    initNodePosition(modelData) {
      let XList = [];
      let YList = [];
      modelData.nodes.forEach((item) => {
        XList.push(item.x);
        YList.push(item.y);
      });
      XList.sort((a, b) => {
        return a - b;
      });
      YList.sort((a, b) => {
        return a - b;
      });
      console.log(XList);
      console.log(YList);
      //获取当前窗口的大小
      let dom = this.$refs.container;
      let containerWidth = dom.offsetWidth;
      let containerHieght = dom.offsetHeight;
      //当前窗口中线的坐标
      let containerCenterX = containerWidth / 2;
      let containerCenterY = containerHieght / 2;
      //外包裹的宽高
      let wrapBoxWidth = XList[XList.length - 1] - XList[0];
      let wrapBoxHeight = YList[YList.length - 1] - YList[0];
      //外包裹一半宽/高的值
      let wrapBoxCenterWidth = wrapBoxWidth / 2;
      let wrapBoxCenterHieght = wrapBoxHeight / 2;
      //外包裹中线的坐标
      let wrapBoxCenterPositionX = XList[0] + wrapBoxCenterWidth;
      let wrapBoxCenterPositionY = YList[0] + wrapBoxCenterHieght;
      //需要移动的位移
      let moveX = wrapBoxCenterPositionX - containerCenterX;
      let moveY = wrapBoxCenterPositionY - containerCenterY;

      console.log(moveX, moveY);
      modelData.nodes.forEach((item) => {
        lf.graphModel.moveNode(item.id, -moveX, -moveY, true);
      });
      //外包裹盒子宽或高超过了外部容器的宽或高
      //
      if (wrapBoxWidth > containerWidth || wrapBoxHeight > containerHieght) {
        //宽高差
        let widthDifference = wrapBoxWidth - containerWidth;
        let heightDifference = wrapBoxHeight - containerHieght;
        //定义比例尺
        let scale = 1;
        if (widthDifference > heightDifference) {
          //以宽为最终基准进行缩放
          //左右留存100px
          scale = containerWidth / (wrapBoxWidth + 100 * 2);
        } else if (widthDifference <= heightDifference) {
          //以高为基准缩放
          //上下留存100px
          scale = containerHieght / (wrapBoxHeight + 100 * 2);
        }
        lf.zoom(scale, [containerWidth / 2, containerHieght / 2]);
      }
    },
    //旧版本兼容
    computeStartPolyline(stepInfo) {
      // console.log(stepInfo);
      return {
        width: stepInfo.bbox.width / 2,
        height: stepInfo.bbox.height / 2,
      };
    },
    //兼容旧版本type
    getTypeByStep(typeStr) {
      let stepType = "action";
      switch (typeStr) {
        case "StartShape":
          stepType = "StartShape";
          break;
        case "action":
          stepType = "action";
          break;
        case "judge":
          stepType = "judge";
          break;
        case "pointer":
          stepType = "pointer";
          break;
        case "CloneActionShape":
          stepType = "CloneActionShape";
          break;
        case "CloneJudgeShape":
          stepType = "CloneJudgeShape";
          break;
        case "InputShape":
          stepType = "InputShape";
          break;
        case "CloneInputShape":
          stepType = "CloneInputShape";
          break;
      }
      return stepType;
    },
    finishSelect(data) {
      console.log(data);
      this.closeSelectionSelect();
      //头部显示可拖动
      this.startTask(6);
    },
    //开启多选框功能
    openSelectionSelect() {
      lf.openSelectionSelect();
    },
    //关闭多选框功能
    closeSelectionSelect() {
      lf.closeSelectionSelect();
      this.selectLine = false;
    },

    deleteEdgesSuccess() {
      this.checkIsOnLinkLine();
      this.dataNeedSave(true);
    },
    //画布被单机，模拟"取消选中"的动作 鼠标抬起事件
    async canvasClick(e) {
      console.log(e);
      this.$emit("selectTaskStep", null);
      if (this.isCreator) {
        let position = lf.graphModel.getPointByClient({
          x: e.e.x,
          y: e.e.y,
        });
        console.log(position);
        if (this.selectLine === 2) {
          let id = uuid.v4();
          let params = {
            id: id,
            ...position.canvasOverlayPosition,
            text: "",
            properties: {
              cloneNodeActive: false,
              onLinkLine: false,
              stepInfo: {
                ...this.propertiesTemplateStep,
              },
              nodeSize: this.defaultNodeSize,
            },
          };
          //如果当前存在被选中的元素
          if (this.selectedNode) {
            //存在选中的元素，先把基础尺寸向被选中的元素看齐
            params.properties.nodeSize.width = this.selectedNode.properties.nodeSize.width;
            params.properties.nodeSize.height = this.selectedNode.properties.nodeSize.height;

            if (
              this.selectedNode.type === "InputShape" ||
              this.selectedNode.type === "CloneInputShape"
            ) {
              //普通步骤横向比输入步骤少两个步长
              params.properties.nodeSize.width =
                params.properties.nodeSize.width - 2 * this.config.grid.size;
              //如果输入步骤的宽度经过计算之后小于最小宽度
              if (params.properties.nodeSize.width < this.minSize.width) {
                params.properties.nodeSize.width = this.minSize.width;
              }
            } else if (
              this.selectedNode.type === "judge" ||
              this.selectedNode.type === "CloneJudgeShape"
            ) {
              //普通步骤比判断步骤横向少四个步长，纵向少两个步长
              params.properties.nodeSize.width =
                params.properties.nodeSize.width - this.config.grid.size * 4;
              params.properties.nodeSize.height =
                params.properties.nodeSize.height - this.config.grid.size * 2;
              //如果输入步骤的高度经过计算之后小于最小高度
              if (params.properties.nodeSize.height < this.minSize.height) {
                params.properties.nodeSize.height = this.minSize.height;
              }
              //如果输入步骤的宽度经过计算之后小于最小宽度
              if (params.properties.nodeSize.width < this.minSize.width) {
                params.properties.nodeSize.width = this.minSize.width;
              }
            }
          }
          params.properties.stepInfo.taskGuid = this.taskGuid;
          params.properties.stepInfo.stepGuid = id;
          this.addActioon(params);
        } else if (this.selectLine === 3) {
          let id = uuid.v4();
          let params = {
            id: id,
            ...position.canvasOverlayPosition,
            text: "",
            properties: {
              cloneNodeActive: false,
              onLinkLine: false,
              stepInfo: {
                ...this.propertiesTemplateStep,
              },
              nodeSize: {
                width: this.defaultNodeSize.width + this.config.grid.size * 4,
                height: this.defaultNodeSize.height + this.config.grid.size * 2,
              },
            },
          };
          //如果当前存在被选中的元素
          if (this.selectedNode) {
            //存在选中的元素，先把基础尺寸向被选中的元素看齐
            params.properties.nodeSize.width = this.selectedNode.properties.nodeSize.width;
            params.properties.nodeSize.height = this.selectedNode.properties.nodeSize.height;

            if (
              this.selectedNode.type === "action" ||
              this.selectedNode.type === "CloneActionShape" ||
              this.selectedNode.type === "StartShape" ||
              this.selectedNode.type === "pointer"
            ) {
              //判断步骤横向比普通步骤多出四个步长 纵向多出两个步长
              params.properties.nodeSize.width =
                params.properties.nodeSize.width + 4 * this.config.grid.size;
              params.properties.nodeSize.height =
                params.properties.nodeSize.height + 2 * this.config.grid.size;
            } else if (
              this.selectedNode.type === "InputShape" ||
              this.selectedNode.type === "CloneInputShape"
            ) {
              //判断步骤比输入步骤横向多两个步长，纵向多两个步长
              params.properties.nodeSize.width =
                params.properties.nodeSize.width + this.config.grid.size * 2;
              params.properties.nodeSize.height =
                params.properties.nodeSize.height + this.config.grid.size * 2;
              //这里不存在图形最小值的判断
              //
            }
          }
          params.properties.stepInfo.taskGuid = this.taskGuid;
          params.properties.stepInfo.stepGuid = id;
          this.addJudge(params);
        } else if (this.selectLine === 7) {
          //输入步骤
          let id = uuid.v4();
          let params = {
            id: id,
            ...position.canvasOverlayPosition,
            text: "",
            properties: {
              cloneNodeActive: false,
              onLinkLine: false,
              stepInfo: {
                ...this.propertiesTemplateStep,
              },
              nodeSize: {
                //输入步骤尺寸
                width: this.defaultNodeSize.width + 2 * this.config.grid.size,
                height: this.defaultNodeSize.height,
              },
            },
          };
          if (this.selectedNode) {
            //存在选中的元素，先把基础尺寸向被选中的元素看齐
            params.properties.nodeSize.width = this.selectedNode.properties.nodeSize.width;
            params.properties.nodeSize.height = this.selectedNode.properties.nodeSize.height;

            if (
              this.selectedNode.type === "action" ||
              this.selectedNode.type === "CloneActionShape" ||
              this.selectedNode.type === "StartShape" ||
              this.selectedNode.type === "pointer"
            ) {
              //输入步骤横向比普通步骤多出两个步长
              params.properties.nodeSize.width =
                params.properties.nodeSize.width + 2 * this.config.grid.size;
              // params.properties.nodeSize.height = params.properties.nodeSize.height;
            } else if (
              this.selectedNode.type === "judge" ||
              this.selectedNode.type === "CloneJudgeShape"
            ) {
              //输入步骤比判断步骤横向少两个步长，纵向少两个步长
              params.properties.nodeSize.width =
                params.properties.nodeSize.width - this.config.grid.size * 2;
              params.properties.nodeSize.height =
                params.properties.nodeSize.height - this.config.grid.size * 2;
              //如果输入步骤的高度经过计算之后小于最小高度
              if (params.properties.nodeSize.height < this.minSize.height) {
                params.properties.nodeSize.height = this.minSize.height;
              }
              //如果输入步骤的宽度经过计算之后小于最小宽度
              if (params.properties.nodeSize.width < this.minSize.width) {
                params.properties.nodeSize.width = this.minSize.width;
              }
            }
          }

          params.properties.stepInfo.taskGuid = this.taskGuid;
          params.properties.stepInfo.stepGuid = id;
          //保存输入步骤的缺省数据
          let op = {
            appearType: 0,
            delaySeconds: 0,
            index: 0,
            panelType: 5,
            stepExtend: "",
            stepObjectJson: JSON.stringify({
              medias: [
                {
                  description: "",
                  fileType: 1,
                  imgUrl: null,
                  index: 0,
                  path: null,
                  title: "",
                },
              ],
              texts: [],
            }),
          };
          params.properties.stepInfo.stepObject = op;
          this.addInput(params);
        } else {
          this.deleteHistory(1);
        }
        //如果当前是在拖动状态 选中缓存列表中的所有元素
        if (this.isDrag) {
          this.seleceCache();
        } else {
          //如果当前不是在拖动状态 情调缓存 并移除本地存储中的选中元素
          this.cachesSelect = {
            nodes: [],
            edges: [],
          };
          removeSelectedGuid();
          //取消克隆体高亮状态
          this.cancelHighlight();
        }
        // e.stopPropagation();
        // let selectModel = lf.getSelectElements();

        //不做历史记录

        this.startTask(6);
        this.checkIsOnLinkLine();
        //用作监听，模拟取消选中的事件
        // this.selectModel = selectModel;
      } else {
        //webgl页面必然会选择一个节点
        // let selectedNodeGuid = getSelectedGuid();
        // if (selectedNodeGuid) {
        //   lf.graphModel.selectElementById(selectedNodeGuid, false);
        // } else {
        //   console.log(111);
        //   let startNode = this.apiGetStartNodeGuid();
        //   lf.graphModel.selectElementById(startNode.id, false);
        // }
      }
      // this.$refs.editStep.init(false);
      // this.shapeItem = false;
      //如果是在拖动状态，右下角的小视窗就不会消失
      if (this.isDrag) {
        return;
      }
      //把右侧编辑控件修改为缺省状态(项目设置页)
      this.$emit("editStepSetPanel", false);
      if (this.isSelectShape || this.isShowHistory || this.isShowProjectInfo) {
        // console.log("1111");
        this.setStoreStatus(false, false, true);
        // this.shapeItem = false;
      } else {
        // console.log("2222");
        this.setStoreStatus(false, false, false);
        // this.shapeItem = false;
      }
      this.defaultNodeSize = {
        width: 160,
        height: 64,
      };
      this.selectedNode = null;
    },
    //控制右侧边栏的显示与否 以及其内容的板块显示
    setStoreStatus(selectShape, showHistory, showProjectInfo) {
      this.$store.commit("SET_PROJECTINFO", showProjectInfo);
      this.$store.commit("SET_SHOWHISTORY", showHistory);
      this.$store.commit("SET_SELECTSHAPE", selectShape);
    },
    //取消克隆体高亮状态
    cancelHighlight() {
      let modelData = lf.graphModel.modelToGraphData();
      modelData.nodes.forEach((item) => {
        lf.setProperties(item.id, {
          ...item.properties,
          cloneNodeActive: false,
        });
      });
    },
    //连线被点击
    lineClick(data, e) {
      this.cancelHighlight();
      console.log(data);
      // this.$refs.editStep.init(data);
      if (data && data.data.properties.canEditEdgeText) {
        this.$emit("editStepSetPanel", data);
      } else {
        this.$emit("editStepSetPanel", false);
      }

      // this.shapeItem = data;
    },
    //节点被点击
    selectElement(data, e) {
      //保存选中图形的大小信息
      console.log(data);
      if (data) {
        // this.defaultNodeSize.width = data.data.properties.nodeSize.width;
        // this.defaultNodeSize.height = data.data.properties.nodeSize.height;
        //设定当前被选中的步骤
        this.selectedNode = data.data;
      }
      //如果选中了任务步骤，就向上层广播这个步骤的信息
      // if (data && data.data.type === "pointer") {
      this.$emit("selectTaskStep", data.data);
      // }
      // let nowNodeId = getSelectedGuid();
      // if (this.templateNeedSave && nowNodeId) {
      //   let nowNode = this.apiGetNodeById(getSelectedGuid());
      //   this.$emit("askIsSave", {
      //     nowNode: nowNode,
      //     targetNode: data,
      //   });
      //   return;
      // }
      // console.log("不选中");
      // return
      console.log(data);
      setSelectedGuid(data.data.id);
      this.$emit("sendData", data.data.id);
      // if (!this.readOnly) {
      //打开右侧边栏
      // this.$refs.editStep.init(data);
      //向上传递打开右侧侧边栏
      this.$emit("editStepSetPanel", data);

      // this.shapeItem = data;
      //取消所有高亮状态
      this.cancelHighlight();
      let selectModel = lf.getSelectElements();
      this.selectModel = selectModel;

      //节点被选中
      //是否是克隆节点
      if (data.data.properties.stepInfo.sourceStepGuid) {
        //点击选中的是克隆节点
        // lf.setProperties(data.data.id, {
        //   cloneNodeActive: true,
        //   ...data.data.properties,
        // });
        let modelData = lf.graphModel.modelToGraphData();
        let cloneMember = [];
        modelData.nodes.forEach((item) => {
          if (
            item.properties.stepInfo.stepGuid ===
              data.data.properties.stepInfo.sourceStepGuid ||
            item.properties.stepInfo.sourceStepGuid ===
              data.data.properties.stepInfo.sourceStepGuid
          ) {
            cloneMember.push(item);
          }
        });
        console.log(cloneMember);
        cloneMember.forEach((item) => {
          lf.setProperties(item.id, {
            ...item.properties,
            cloneNodeActive: true,
          });
        });
        //不做历史记录
        this.deleteHistory(1);
        // this.highlightList = cloneMember;
      } else {
        //点击选中的不是克隆节点
        //如果是克隆源步骤
        if (data.data.properties.stepInfo.instanceShapes.length > 0) {
          lf.setProperties(data.data.id, {
            ...data.data.properties,
            cloneNodeActive: true,
          });
          data.data.properties.stepInfo.instanceShapes.forEach((item) => {
            lf.setProperties(item.id, {
              ...item.properties,
              cloneNodeActive: true,
            });
          });
          //不作为历史记录
          this.deleteHistory(1);
        }
      }
      //向上传递 画出步骤中的模板缩略图
      // if (this.projectInfo?.editType === 1) {
      //   this.$emit("logicflowSelectNode", data);
      // }
      // }
    },
    //删除当前选中的元素
    deleteSelectedElements() {
      if (!this.isCreator || this.readOnly) {
        return;
      }
      let elements = lf.getSelectElements(true);
      //如果存在开始步骤，就不允许删除
      let startNode = elements.nodes.find((node) => node.type === "StartShape");
      if (startNode) {
        lf.clearSelectElements();
        ElMessage.error(this.$t("taskChart.tip6"));
        return;
      }
      // console.log(elements);
      lf.clearSelectElements();
      //根据id删除元素 官方文档api(有bug)

      // let modelData = lf.graphModel.modelToGraphData();
      // 删除的如果是克隆节点，就给出提示，把与其相关的节点全部删除
      // 遍历删除的节点列表中是否存在克隆源步骤
      let sourceNodeList = [];
      elements.nodes.forEach((item) => {
        if (
          item.properties.stepInfo.instanceShapes.length > 0 &&
          !item.properties.stepInfo.sourceStepGuid
        ) {
          //是克隆源步骤
          sourceNodeList = sourceNodeList.concat(
            item.properties.stepInfo.instanceShapes
          );
        }
      });

      //列表中存在克隆源步骤
      if (sourceNodeList.length > 0) {
        this.$confirm(
          // 此步骤有关联的克隆体,继续操作会导致其所有克隆体都被删除,是否继续?
          this.$t("taskChart.tip9"), // 删除步骤
          this.$t("taskChart.tip8"),
          {
            // 确定
            confirmButtonText: this.$t("taskChart.ok"),
            // 取消
            cancelButtonText: this.$t("taskChart.cancel"),
            customClass: "deleteBox",
          }
        )
          .then(() => {
            //手动删除元素，然后重新渲染
            elements.nodes = elements.nodes.concat(sourceNodeList);
            this.deleteModelItem(elements);
            this.dataNeedSave(true);
            // this.deleteItem(elements, modelData);
          })
          .catch(() => {
            // this.$message({
            //   type: "info",
            //   message: "已取消删除",
            // });
            this.cancelHighlight();
            // this.$refs.editStep.init(false);
            this.$emit("closeEditer");
          });
      } else {
        this.deleteModelItem(elements);
        this.dataNeedSave(true);
      }
    },
    deleteModelItem(modelData) {
      console.log(modelData);
      //删除线
      modelData.edges.forEach((edge) => {
        lf.deleteEdge(edge.id);
      });
      //删除步骤
      modelData.nodes.forEach((node) => {
        if (node.type !== "StartShape") {
          try {
            //如果是克隆源步骤，就把指向源步骤的所有步骤删除
            if (node.properties.stepInfo.instanceShapes.length > 0) {
              node.properties.stepInfo.instanceShapes.forEach((nodeItem) => {
                lf.deleteNode(nodeItem.id);
              });
            }
            console.log(node);
            //如果是克隆步骤
            if (node.properties.stepInfo.sourceStepGuid) {
              //寻找源步骤，并从源步骤中的克隆列表删除此步骤
              let sourceStepGuid = node.properties.stepInfo.sourceStepGuid;
              let targetSourceNode = this.apiGetNodeById(sourceStepGuid);
              let targetNodeinstanceShapes =
                targetSourceNode.properties.stepInfo.instanceShapes;
              let nodeList = [];
              for (let i = 0; i < targetNodeinstanceShapes.length; i++) {
                const value = targetNodeinstanceShapes[i];
                if (value.id !== node.id) {
                  nodeList.push(targetNodeinstanceShapes[i]);
                }
              }
              // console.log(nodeList);
              lf.setProperties(targetSourceNode.id, {
                stepInfo: {
                  ...targetSourceNode.properties.stepInfo,
                  instanceShapes: nodeList,
                },
                cloneNodeActive: nodeList.length > 0 ? true : false,
              });
            }
            lf.deleteNode(node.id);
          } catch (e) {
            console.log(e);
          }
        }
      });
      this.selectedNode = null;
      this.defaultNodeSize = {
        width: 160,
        height: 64,
      };
      this.checkIsOnLinkLine();
      this.$emit("editStepSetPanel", false);
    },
    getSelectElements() {
      let selectElement = lf.getSelectElements(true);
      console.log(selectElement);
      console.log(lf.graphModel.modelToGraphData());
    },
    lfChange(data) {
      console.log("画布信息改变", data);
    },
    drawSuccess(data, e, edgeModel, nodeModel) {
      console.log(data);
      console.log(lf.graphModel.modelToGraphData());
      this.checkIsOnLinkLine();
    },
    //给"开始步骤"连接树上的所有图形做active
    checkIsOnLinkLine() {
      this.onLineNodes = [];
      let list = lf.graphModel.modelToGraphData();
      let startStep = list.nodes.find((item) => item.type == "StartShape");
      console.log(startStep);
      if (!startStep) {
        return;
      }
      this.onLineRecurrence(startStep.id);
      list.nodes.forEach((value) => {
        lf.setProperties(value.id, {
          ...value.properties,
          onLinkLine: false,
          // isAuthor: true,
        });
      });
      this.onLineNodes.forEach((value) => {
        lf.setProperties(value.id, {
          ...value.properties,
          onLinkLine: true,
        });
      });
      let judgeIdList = [];
      list.nodes.forEach((item, index) => {
        if (item.type === "judge" || item.type === "CloneJudgeShape") {
          judgeIdList.push(item.id);
        }
      });
      console.log(judgeIdList);
      let targetLines = [];
      list.edges.forEach((item, index) => {
        // item.properties.canEditEdgeText = false;
        lf.setProperties(item.id, {
          ...item.properties,
          canEditEdgeText: false,
        });
        // lf.getEdgeModelById(item.id).setProperties({
        //   canEditEdgeText: false,
        // });
        if (judgeIdList.includes(item.sourceNodeId)) {
          targetLines.push(item);
          lf.setProperties(item.id, {
            ...item.properties,
            canEditEdgeText: true,
          });
          // lf.getEdgeModelById(item.id).setProperties({
          //   canEditEdgeText: true,
          // });
          console.log(item);
        }
        console.log(item);
      });

      //不做历史记录
      // this.deleteHistory(1);
    },
    onLineRecurrence(sourceNodeId) {
      let list = lf.graphModel.modelToGraphData();
      for (let i = 0; i < list.edges.length; i++) {
        const value = list.edges[i];
        if (value.sourceNodeId === sourceNodeId) {
          //value就是sourceNodeId的node的下级节点
          let target = list.nodes.find(
            (item) => item.id === value.targetNodeId
          );
          //该节点是否已经被记录过 防止链接树连接成环形导致无限递归
          let isHad = this.onLineNodes.find((item) => item.id === target.id);
          if (target) {
            this.onLineNodes.push(target);
            if (!isHad) {
              this.onLineRecurrence(target.id);
            }
          }
        }
      }
    },
    addJudge(params) {
      let options = {
        type: "judge",
        ...params,
      };
      options.properties.language = {
        copy: this.$t("taskChart.copy"),
        clone: this.$t("taskChart.clone"),
        setStartStep: this.$t("taskChart.setStartStep"),
        delete: this.$t("taskChart.delete"),
      };
      // this.renderObj.nodes.push(options);
      //   lf.graphModel.graphDataToModel();
      //   lf.render(this.renderObj);
      // console.log(lf.graphModel);
      lf.graphModel.addNode(options);
      this.dataNeedSave(true);
      return options.id;
    },
    addCloneJudge(params) {
      let options = {
        type: "CloneJudgeShape",
        ...params,
      };
      options.properties.language = {
        copy: this.$t("taskChart.copy"),
        clone: this.$t("taskChart.clone"),
        setStartStep: this.$t("taskChart.setStartStep"),
        delete: this.$t("taskChart.delete"),
      };
      // this.renderObj.nodes.push(options);
      //   lf.graphModel.graphDataToModel();
      //   lf.render(this.renderObj);
      // console.log(lf.graphModel);
      lf.graphModel.addNode(options);
      this.dataNeedSave(true);
      return options.id;
    },
    addTask(params) {
      let options = {
        type: "pointer",
        ...params,
      };
      options.properties.language = {
        copy: this.$t("taskChart.copy"),
        clone: this.$t("taskChart.clone"),
        setStartStep: this.$t("taskChart.setStartStep"),
        delete: this.$t("taskChart.delete"),
      };
      // this.renderObj.nodes.push(options);
      //   lf.graphModel.graphDataToModel();
      //   lf.render(this.renderObj);
      console.log(lf.graphModel);
      lf.graphModel.addNode(options);
      this.dataNeedSave(true);
      return options.id;
    },
    addStart(params) {
      let options = {
        type: "StartShape",
        ...params,
      };
      options.properties.language = {
        copy: this.$t("taskChart.copy"),
        clone: this.$t("taskChart.clone"),
        setStartStep: this.$t("taskChart.setStartStep"),
        delete: this.$t("taskChart.delete"),
      };
      // this.renderObj.nodes.push(options);
      //   lf.graphModel.graphDataToModel();
      //   lf.render(this.renderObj);
      console.log(lf.graphModel);
      lf.graphModel.addNode(options);
      this.dataNeedSave(true);
      return options.id;
    },
    addActioon(params) {
      let options = {
        type: "action",
        ...params,
      };
      options.properties.language = {
        copy: this.$t("taskChart.copy"),
        clone: this.$t("taskChart.clone"),
        setStartStep: this.$t("taskChart.setStartStep"),
        delete: this.$t("taskChart.delete"),
      };
      // this.renderObj.nodes.push(options);
      //   lf.graphModel.graphDataToModel();
      //   lf.render(this.renderObj);
      console.log(lf.graphModel);
      lf.graphModel.addNode(options);
      this.dataNeedSave(true);
      return options.id;
    },
    addInput(params) {
      let options = {
        type: "InputShape",
        ...params,
      };
      options.properties.language = {
        copy: this.$t("taskChart.copy"),
        clone: this.$t("taskChart.clone"),
        setStartStep: this.$t("taskChart.setStartStep"),
        delete: this.$t("taskChart.delete"),
      };
      // this.renderObj.nodes.push(options);
      //   lf.graphModel.graphDataToModel();
      //   lf.render(this.renderObj);
      console.log(lf.graphModel);
      lf.graphModel.addNode(options);
      this.dataNeedSave(true);
      return options.id;
    },
    addCloneInput(params) {
      let options = {
        type: "CloneInputShape",
        ...params,
      };
      options.properties.language = {
        copy: this.$t("taskChart.copy"),
        clone: this.$t("taskChart.clone"),
        setStartStep: this.$t("taskChart.setStartStep"),
        delete: this.$t("taskChart.delete"),
      };
      // this.renderObj.nodes.push(options);
      //   lf.graphModel.graphDataToModel();
      //   lf.render(this.renderObj);
      console.log(lf.graphModel);
      lf.graphModel.addNode(options);
      this.dataNeedSave(true);
      return options.id;
    },
    addCloneActioon(params) {
      let options = {
        type: "CloneActionShape",
        ...params,
      };
      options.properties.language = {
        copy: this.$t("taskChart.copy"),
        clone: this.$t("taskChart.clone"),
        setStartStep: this.$t("taskChart.setStartStep"),
        delete: this.$t("taskChart.delete"),
      };
      // this.renderObj.nodes.push(options);
      //   lf.graphModel.graphDataToModel();
      //   lf.render(this.renderObj);
      console.log(lf.graphModel);
      lf.graphModel.addNode(options);
      this.dataNeedSave(true);
      return options.id;
    },
  },
};
</script>

<style scoped lang="scss">
.logicflowWrap {
  width: 100%;
  // width: calc(100% - 43rem);
  height: calc(100vh - 3.2rem);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .canvasCover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: #ccc;
    z-index: 10;
    display: none;
  }
  > .coverActive {
    display: block;
  }
  .containerWrap {
    display: flex;
    justify-content: flex-start;
    height: calc(100% - 3.5rem);
    .logicflowBody {
      width: 100%;
      // width: calc(100% - 28rem);
      // height: calc(100% - 3.5rem);

      :deep(.lf-graph) {
        // z-index: 10;
        .lf-tool-overlay {
          .lf-dndpanel {
            margin: 0;
            // padding: 0;
            border-radius: 0;
            border-left: 1px solid #ccc;
            top: 0rem;
            left: 0;
            display: flex;
            box-shadow: none;
            justify-content: flex-start;
            .lf-dnd-item {
              margin: 0 1.5rem;
              // .lf-dnd-shape {
              // }
              .lf-dnd-text {
                color: #333;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  .startStep {
    :deep(div) {
      .lf-graph {
        > .lf-drag-able {
          cursor: url("~@/assets/curs/start.cur"), auto !important;
        }
      }
    }
  }

  .defaultStep {
    :deep(div) {
      .lf-graph {
        > .lf-drag-able {
          cursor: url("~@/assets/curs/step.cur"), auto !important;
        }
      }
    }
  }

  .judgeStep {
    :deep(div) {
      .lf-graph {
        > .lf-drag-able {
          cursor: url("~@/assets/curs/judge.cur"), auto !important;
        }
      }
    }
  }
  .inputStep {
    :deep(div) {
      .lf-graph {
        > .lf-drag-able {
          cursor: url("~@/assets/curs/input.cur"), auto !important;
        }
      }
    }
  }
  .buttons {
    position: absolute;
    top: 0;
    left: 0;
  }
  .ctrlWarps {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    // position: absolute;
    // top: 1px;
    // left: 0;
    width: 100%;
    height: 3.5rem;
    background: #fff;
    border-bottom: 1px solid #ccc;
    // border-left: 1px solid #ccc;
    box-sizing: border-box;
    padding-left: 1rem;
    .scaleDropdown {
      position: absolute;
      right: 15px;
    }
    .selfLine {
      width: 2px;
      height: 2rem;
      background: #e4ebf6;
      margin-right: 1rem;
    }

    .lines,
    .ctrls {
      display: flex;
      justify-content: left;
      align-items: center;
      > button,
      > div,
      > div > button {
        border: 1px solid rgba(0, 0, 0, 0);
        outline: none;
        background: #f5f7fa;
        cursor: pointer;
        width: 3.2rem;
        height: 2.2rem;
        border-radius: 5px;
        margin-right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        > img {
          width: 100%;
        }
      }
      > div {
        padding: 0 0.5rem;
      }
      > div > .myTaskBtn {
        width: 4.3rem;
        font-size: 16px;
        > .line {
          height: 40%;
          border-left: 1px solid #ccc;
          margin-right: 3px;
          margin-left: 1px;
        }
        // > i {
        //   border-left: 1px solid #ccc;
        // }
        > img {
          width: 80%;
        }
      }
      > button[disabled],
      > div > button[disabled] {
        border: 1px solid #ccc !important;
        filter: grayscale(100%);
        cursor: not-allowed;
      }

      > button:hover {
        // border: 1px solid #4582ff;
        background: #d3e1ff;
      }

      .select {
        background: #4582ff;
      }

      .active {
        // border: 1px solid #4582ff;
        background: #4582ff !important;
      }

      .active1 {
        border: 1px solid #4582ff;
      }

      .disabled {
        border: 1px solid #ccc !important;
        filter: grayscale(100%);
        cursor: not-allowed;
      }

      > div > button {
        margin-right: 0;
      }

      > div > button:hover {
        // border: 1px solid #4582ff;
        background: #d3e1ff;
      }
    }
  }
}
.readOnlyLogicflowWrap {
  width: 100%;
  height: calc(100vh - 30rem);
}

:deep(.lf-menu) {
  min-width: 10rem;
  background: #fff;
  padding: 0.5rem;
  border-radius: 5px;
  margin: 0;
  box-shadow: 0px 2px 18px 1px rgba(6, 26, 46, 0.09);
  .lf-menu-item {
    padding: 0.3rem 0.8rem;
    display: block;
    outline: none;
    border: none;
    width: 100%;
    background: #fff;
    text-align: left;
    color: #f94a4a;
    font-size: 0.95rem;
    border-radius: 3px;
    cursor: pointer;
    line-height: 2rem;
    box-sizing: border-box;
  }
  .lf-menu-item:hover {
    background: #ffecec;
  }
  .items {
    background: #fff;
    border-radius: 4px 4px 4px 4px;
    color: #274e78;
    white-space: nowrap;
  }
  .items:hover {
    background: #ecf2ff;
  }
}
:deep(.lf-node-text-auto-wrap-content) {
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 8%;
  > .lf-node-text--auto-wrap-inner {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
:deep(.lf-node) {
  box-shadow: 0px 2px 18px 1px rgba(6, 26, 46, 0.09);
}
</style>
