<template>
  <div class="userInfoWarp">
    <div class="headImg">
      <div class="imgWarp">
        <div>
          <img v-if="avatarInfo.data" :src="avatarInfo.data" alt="" />
          <img
            v-else-if="$store.state.User.profilePhotoUrl"
            :src="$store.state.User.profilePhotoUrl"
            alt=""
          />
          <img v-else src="@/assets/login/default-head.png" alt="" />
          <!-- <img v-else src="@/assets/login/default-head.png" alt=""> -->
        </div>
      </div>
      <!-- 更换头像 -->
      <div class="changeAvatar" @click="changeAvatar">
        {{ $t("userInfo.changeAvatar") }}
      </div>
      <!-- <p>最大尺寸为512×512</p>
      <p>文件大小小于1MB</p> -->
    </div>

    <div class="userInfos">
      <!-- <el-form
        ref="editForm"
        :model="ruleForm"
        :rules="rules"
        label-position="left"
      >
        <el-form-item prop="displayName">
          <template #label>
            <span>用户名</span><span>(最多20个字)</span>
          </template>
          <el-input
            size="small"
            ref="focusedItem"
            v-model="ruleForm.displayName"
            placeholder="用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="introduction">
          <el-input
            size="small"
            v-model="ruleForm.introduction"
            placeholder="描述"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="绑定邮箱" prop="email">
          <el-input
            size="small"
            ref="focusedItem"
            v-model="ruleForm.email"
            placeholder="邮箱"
          ></el-input>
        </el-form-item>
      </el-form> -->
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-position="left"
      >
        <el-form-item prop="displayName">
          <template #label>
            <!-- 用户名   最多20个字 -->
            <span>{{ $t("userInfo.username") }}</span
            ><span>{{ $t("userInfo.tip") }}</span>
          </template>
          <el-input
            v-model="ruleForm.displayName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="email"
          :rules="[
            { required: true, message: '请输入邮箱地址', trigger: 'blur' },
            {
              type: 'email',
              message: '请输入正确的邮箱地址',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <template #label>
            <!-- 邮箱 -->
            <span>{{ $t("userInfo.email") }}</span>
          </template>
          <el-input v-model="ruleForm.email" autocomplete="off"></el-input>
        </el-form-item>
        <!-- 描述 -->
        <el-form-item :label="$t('userInfo.description')" prop="checkPass">
          <el-input
            type="textarea"
            v-model="ruleForm.introduction"
            autocomplete="off"
            :autosize="{ minRows: 4 }"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- <div class="formItem">
        <div class="title"><span>用户名</span><span>(最多20个字)</span></div>
        <div class="formInput">
          <el-input
            size="small"
            v-model="ruleForm.displayName"
            placeholder="在此输入用户名"
          ></el-input>
        </div>
      </div>
      <div class="formItem">
        <div class="title"><span>描述</span></div>
        <div class="formInput">
          <el-input
            type="textarea"
            size="small"
            v-model="ruleForm.description"
            placeholder="在此输入描述"
          ></el-input>
        </div>
      </div>
      <div class="formItem">
        <div class="title"><span>绑定邮箱</span></div>
        <div class="formInput">
          <el-input
            size="small"
            v-model="ruleForm.email"
            placeholder="在此输入用户名"
          ></el-input>
        </div>
      </div>
      <div class="checkCode">
        <div class="checkCodeInput">
          <el-input
            size="small"
            v-model="code"
            placeholder="输入验证码"
          ></el-input>
        </div>
        <div class="getCheckCodeBtn">
          <button>获取验证码</button>
        </div>
      </div> -->
      <div class="submitBtnWarp">
        <!-- 更新 -->
        <button @click="submitForm('ruleForm', $event)">
          {{ $t("userInfo.update") }}
        </button>
      </div>
    </div>
    <ChangeAvatar
      @echopic="getDataUrlAvatar"
      :dialogVisible="isShowAvatar"
      ref="changeAvatar"
    />
  </div>
</template>

<script>
import { saveMyUser } from "../../../api/myProfile";
import { mapGetters } from "vuex";

import ChangeAvatar from "./avatar/avatar.vue";
import { getUploadSas, saveMyProfilePhoto } from "@/api/workflow";
const { BlockBlobClient, AnonymousCredential } = require("@azure/storage-blob");
import { dataURLtoBlob } from "@/utils/dataURLtoBlob";
import { ElMessage } from "element-plus";
import { uuid } from "vue-uuid";
export default {
  components: { ChangeAvatar },
  data() {
    return {
      rules: {
        displayName: [
          { required: true, message: "", trigger: "blur" },
          {
            min: 1,
            max: 20,
            // 长度在 1 到 20 个字符
            message: this.$t("userInfo.tip1"),
            trigger: "blur",
          },
        ],
      },
      isShowAvatar: false,
      ruleForm: {
        displayName: "",
        introduction: "",
        email: "",
      },
      code: "",
      avatarInfo: {},
    };
  },
  async created() {
    let data = await this.$store.dispatch("getInfo");
    this.ruleForm.displayName = data.displayName;
    this.ruleForm.introduction = data.introduction;
    this.ruleForm.email = data.email;
  },
  mounted() {},
  computed: {},
  watch: {
    "$store.state.User.user": {
      async handler(newVal, oldVal) {
        console.log(newVal);
        if (!newVal) {
          return;
        }
        this.$store.commit("SET_USERINFO", newVal);
        this.ruleForm.displayName = newVal.displayName;
        this.ruleForm.introduction = newVal.introduction;
        this.ruleForm.email = newVal.email;
      },
      immediate: true,
      deep: true,
    },
  },
  unmounted() {
    this.avatarInfo = null;
  },
  methods: {
    uploadAvatar() {},
    getDataUrlAvatar(data) {
      this.avatarInfo = data;
      console.log(data);
    },
    changeAvatar() {
      console.log(111);
      this.$refs.changeAvatar.open(true);
      // this.isShowAvatar = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (
            this.ruleForm.displayName.length < 1 ||
            this.ruleForm.displayName.length > 20
          ) {
            return;
          }
          await saveMyUser(this.ruleForm);
          // console.log(this.avatarInfo.data);
          if (this.avatarInfo.data) {
            await this.uploadAvatar(this.avatarInfo);
          }
          ElMessage.success({
            message: this.$t("userInfo.tip2"),
            type: "success",
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // async submitForm(formName) {
    // e.preventDefault();
    // this.$refs[formName].validate(async (valid) => {
    //   console.log(valid);
    //   if (valid) {
    //     await saveMyUser(this.ruleForm);
    //     // alert("submit!");
    //     console.log(this.avatarInfo.data);
    //     if (this.avatarInfo.data) {
    //       await this.uploadAvatar(this.avatarInfo.data, this.avatarInfo.type);
    //     }
    //     ElMessage.success({
    //       message: "已保存",
    //       type: "success",
    //     });
    //   } else {
    //     console.log("error submit!!");
    //     return false;
    //   }
    // });

    // },
    async uploadAvatar(avatarDataUrl) {
      // let blobName = uuid.v4();
      let result = await this.uploadStageFile(avatarDataUrl.file);
      await saveMyProfilePhoto(result);
      ElMessage.success({
        // 保存成功！
        message: this.$t("userInfo.tip2"),
        type: "success",
      });
      await this.$store.dispatch("getInfo");
    },
    async uploadStageFile(file) {
      if (!file) {
        return "";
      }
      try {
        const { sas, path } = await getUploadSas(
          file.name || `${uuid.v4()}.png`
        );

        const blobClient = new BlockBlobClient(sas, new AnonymousCredential());
        await blobClient.uploadData(file, {
          blockSize: 512 * 1024,
          concurrency: 2,
        });

        // await saveMyProfilePhoto(path)
        return path;
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.userInfoWarp {
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  height: calc(100% - 4rem);
  .headImg {
    width: 20%;
    box-sizing: border-box;
    padding-top: 4rem;
    .imgWarp {
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #4a85ff;
      > div {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .changeAvatar {
      margin-top: 1.5rem;
      color: #6a9bff;
      cursor: pointer;
      margin-bottom: 1.5rem;
    }
    .changeAvatar:hover {
      text-decoration: underline 1px solid #6a9bff;
    }
    > p {
      line-height: 1.2rem;
      font-size: 0.8rem;
      margin: 0;
      color: #7b93ad;
    }
  }
  .userInfos {
    width: 18rem;
    margin-left: 4.5rem;
    padding-top: 2rem;
    > .el-form {
      .el-form-item {
        margin-bottom: 20px;
        > .el-form-item__label {
          line-height: 28px;
          font-size: 1rem;
          color: #406389;
          > span:nth-child(1) {
            line-height: 28px;
            font-size: 1rem;
            color: #406389;
          }
          > span:nth-child(2) {
            margin-left: 0.5rem;
            font-size: 0.7rem;
            color: #a0b2c4;
          }
        }
      }
    }
    > .formItem {
      width: 100%;
      margin-top: 1.5rem;
      > .title {
        text-align: left;
        margin-bottom: 0.5rem;
        > span:nth-child(1) {
          font-size: 1rem;
          color: #406389;
        }
        > span:nth-child(2) {
          margin-left: 0.5rem;
          font-size: 0.7rem;
          color: #a0b2c4;
        }
      }
    }
    > .checkCode {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .checkCodeInput {
        width: 60%;
      }
      .getCheckCodeBtn {
        width: 38%;
        > button {
          cursor: pointer;
          outline: none;
          border: none;
          background: #deb573;
          color: #fff;
          height: 2rem;
          font-size: 0.9rem;
          padding: 0.5rem 1rem;
          border-radius: 1rem;
        }
      }
    }
    .submitBtnWarp {
      > button {
        cursor: pointer;
        outline: none;
        border: none;
        color: #fff;
        background: #4582ff;
        width: 100%;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        margin-top: 1rem;
        border-radius: 4px;
        font-size: 0.8rem;
        transition: background 0.1s;
      }
      > button:hover {
        background: #8fb5ff;
      }
    }
  }
}
</style>
