<template id="layout">
  <el-container>
    <!-- <el-header v-if="$route.meta.showHeader">
      <Header />
    </el-header> -->
    <!-- <el-main :style="$route.meta.showHeader ? { height: '100vh' } : ''"> -->
    <el-main id="scrollMainBody" style="height: 100vh">
      <!-- body部分
      <el-button type="success" @click="goNew('/account/new')">新建</el-button>
      <el-button type="success" @click="goNew('/')">回主页</el-button> -->
      <router-view
        v-if="isRouterAlive && !$store.state.User.changeLang"
        v-slot="{ Component }"
      >
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </el-main>
    <!-- <el-footer>Footer</el-footer> -->
    <el-backtop @click="backTop" target="#scrollMainBody"></el-backtop>
  </el-container>
</template>
<script>
// import Breadcrumb from "@/components/breadcrumb.vue";
// import MainMenu from "@/components/mainmenu.vue";
import Header from "./components/header/header.vue";
export default {
  components: { Header },
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      title: "",
      isCollapse: true,
      breadcrumb: [],
      permissions: [],
      unreadNum: 0,
      timer: null,
      isRouterAlive: true,
    };
  },
  created: async function() {
    // this.$i18n.locale = this.lang;
    // this.$i18n.locale = langs[0].value;
    // let lang = getLanguage();
    // if (lang) {
    //   this.$i18n.locale = lang;
    // } else {
    //   this.$i18n.locale = langs[0].value;
    //   localStorage.setItem("language_type", langs[0].value);
    // }
    // if (getToken()) {
    //   this.$store.commit("SET_TOKEN", getToken());
    //   await this.$store.dispatch("getInfo");
    //   console.log(this.$store.state.User);
    // }
    // console.log(this.$route.meta);
    // console.log(this.$route.meta.showHeader);
  },
  unmounted() {
    if (this.timer !== null) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function() {
        this.isRouterAlive = true; //再打开
      });
    },
    backTop() {
      document.getElementById("scrollMainBody").className = "el-main";
    },
    goNew(route) {
      this.$router.push(route);
    },
    handleLogout() {
      this.$auth.logout();
    },
  },
  watch: {
    // $route: {
    //   immediate: true,
    //   handler() {
    //     this.$store.state.account.permissions;
    //     this.$route;
    //   },
    // },
  },
};
</script>
<style lang="scss">
@import "@/styles/el-dialog.scss";
.el-container {
  position: relative;
  height: 100vh;
}
.el-header,
.el-footer {
  background-color: #ffffff;
  color: var(--el-text-color-primary);
  text-align: center;
  line-height: 60px;
  height: 4rem;
}

.el-main {
  background-color: #e9eef3;
  color: var(--el-text-color-primary);
  text-align: center;
  // line-height: 160px;
  height: 86%;
  padding: 0;
  scroll-behavior: smooth;
  // white-space: nowrap;
}
// .scrolls {
//   scroll-behavior: smooth;
// }

.el-aside {
  background-color: #d3dce6;
  color: var(--el-text-color-primary);
  text-align: center;
  line-height: 200px;
}

.el-header {
  // position: absolute;
  // top: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  // F0F9FD
  background: -webkit-linear-gradient(#f0f9fd, #ebf5fd);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(#f0f9fd, #ebf5fd);
  /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(#f0f9fd, #ebf5fd);
  /* Firefox 3.6 - 15 */
  background: linear-gradient(#f0f9fd, #ebf5fd);
}

.el-main {
  height: calc(100vh - 8rem);
  // max-width: 1920px;
  overflow: overlay;
}

.el-footer {
  width: 100%;
}

body > .el-container {
  margin-bottom: 40px;
  position: relative;
}

.el-main::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
.el-main::-webkit-scrollbar-corner {
  background-color: transparent;
}
.el-main::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  background-clip: padding-box;
  border: solid transparent;
  border-radius: 10px;
}
.el-main::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid transparent;
  border-width: 1px;
}
.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.el-message-box {
  width: 520px;
  border-radius: 12px;
  padding-bottom: 15px;
  .el-message-box__header {
    padding: 20px 30px 5px;
    .el-message-box__title {
      color: #4582ff;
    }
    .el-message-box__headerbtn {
      top: 20px;
    }
  }
  .el-message-box__content {
    padding: 15px 30px;
    > .el-message-box__input {
      > .el-textarea {
        > .el-textarea__inner {
          min-height: 104px !important;
          color: #1f1f1f;
          background: #f6f7fa;
          border: 1px solid #dde2f1;
          resize: none;
        }
        > .el-textarea__inner::-webkit-scrollbar {
          width: 14px;
          height: 14px;
        }
        > .el-textarea__inner::-webkit-scrollbar-corner {
          background-color: transparent;
        }
        > .el-textarea__inner::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.1);
          background-clip: padding-box;
          border: solid transparent;
          border-radius: 10px;
        }
        > .el-textarea__inner::-webkit-scrollbar-track {
          background-clip: padding-box;
          border: solid transparent;
          border-width: 1px;
        }
      }
      > .el-message-box__errormsg {
        padding: 16px 0;
        text-align: center;
        border-bottom: 1px solid #f6f7fa;
        font-size: 14px;
      }
    }
  }
  .el-message-box__btns {
    > button {
      width: 120px;
      height: 32px;
      border-radius: 4px;
      transition: background 0.1s;
      border: none;
    }
    > button:nth-child(1) {
      background: #a8b7ca;
      color: #ffffff;
    }
    > button:nth-child(1):hover {
      background: #b8c2d2;
    }
    > button:nth-child(2) {
      background: #4582ff;
      color: #ffffff;
    }
    > button:nth-child(2):hover {
      background: #8fb5ff;
    }
  }
}

.deleteBox {
  width: 520px;
  .el-message-box__header {
    padding: 20px 30px 5px;
    .el-message-box__title {
      color: #ea6767;
    }
    .el-message-box__headerbtn {
      top: 20px;
    }
  }
  .el-message-box__content {
    padding: 15px 30px;
  }
  .el-message-box__btns {
    > button {
      width: 120px;
      height: 32px;
      border-radius: 4px;
      transition: background 0.1s;
      border: none;
    }
    > button:nth-child(1) {
      background: #a8b7ca;
      color: #ffffff;
    }
    > button:nth-child(1):hover {
      background: #b8c2d2;
    }
    > button:nth-child(2) {
      background: #ea6767;
      color: #ffffff;
    }
    > button:nth-child(2):hover {
      background: #ff8e8e;
    }
  }
}
</style>
