<template>
  <div>
    <div class="exploreHeader">
      <Header @getSearchStr="getSearchStr" @refreshList="refreshList" />
      <div>
        <!-- 天下无难事 -->
        <p class="exploreTitle">{{ $t("explore.word1") }}</p>
        <!-- 以全新虚实结合的形式创作、管理、分享您的工作流程和知识 -->
        <p class="exploreTitle1">
          {{ $t("explore.word2") }}
        </p>
      </div>
    </div>
    <div class="exploreSelect">
      <div>
        <div class="searchWarp">
          <!-- 请选择 -->
          <el-select
            v-if="categories"
            class="selfStyle_search"
            popper-class="dropDown"
            v-model="queryOptions.selectedCategoryId"
            :placeholder="$t('explore.word3')"
            @change="searchItemByApi"
          >
            <el-option
              v-for="item in categories"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :style="{
                margin: '0 0.5rem',
                borderRadius: '3px',
                textAlign: 'center',
              }"
            >
            </el-option>
            <template #prefix>
              <img
                style="margin: 0 0.5rem; width: 18px; height: 18px"
                src="../../assets/explore/qrCode.png"
                alt=""
              />
            </template>
          </el-select>
          <!-- 请选择 -->
          <el-select
            class="selfStyle_search"
            popper-class="dropDown"
            v-model="queryOptions.selectedPeriod"
            :placeholder="$t('explore.word3')"
            @change="searchItemByApi"
          >
            <el-option
              v-for="item in periods"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :style="{
                margin: '0 0.5rem',
                borderRadius: '3px',
                textAlign: 'center',
              }"
              class="selfDropDown"
            >
            </el-option>
            <template #prefix>
              <img
                style="margin: 0 0.5rem; width: 18px; height: 18px"
                src="../../assets/explore/clock.png"
                alt=""
              />
            </template>
          </el-select>
        </div>
        <div class="sortWarp">
          <!-- 排序方式 -->
          <p>{{ $t("explore.word4") }}</p>
          <el-dropdown trigger="click">
            <span class="exploreDropdownTitle">
              <!-- {{ dropdownShowInfo ? dropdownShowInfo.label : ""
              }} -->
              {{ orderCodes[queryOptions.selectedOrder].label }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
              <div class="taskCtrlListWarp">
                <el-dropdown-menu>
                  <el-dropdown-item
                    class="btnWarp"
                    @click.prevent="selectDrop(item, index)"
                    v-for="(item, index) in orderCodes"
                    :key="index"
                  >
                    <button>{{ item.label }}</button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </div>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div
      class="exploreBody"
      v-if="gotProjects.data && gotProjects.data.length !== 0"
    >
      <div v-loading="loading">
        <div
          class="myProjectItem"
          v-for="(item, index) in gotProjects.data"
          :key="index"
        >
          <div>
            <div class="expImgWarp" @click="enterProject(item)">
              <div>
                <!-- <img
                  v-if="!item.enableObjectAnchorModel"
                  src="@/assets/home/position1.png"
                  alt=""
                />
                <img
                  v-if="item.enableObjectAnchorModel"
                  src="@/assets/home/position2.png"
                  alt=""
                /> -->
                <div v-if="item.editType === 0" class="threeD">
                  <img src="@/assets/home/threeD.png" alt="" />
                </div>
                <div v-if="item.editType === 1" class="twoD">
                  <img src="@/assets/home/twoD.png" alt="" />
                </div>
              </div>
              <!-- <img src="" alt="" /> -->
              <img v-if="item.coverImageUrl" :src="item.coverImageUrl" alt="" />
              <img v-else src="@/assets/home/defaultCover.png" alt="" />
            </div>
            <p>{{ item.projectName ? item.projectName : "0" }}</p>
            <div class="operation">
              <span
                ><img src="@/assets/myTask/thumbnail.png" alt="" />
                {{
                  item.likeCount
                    ? item.likeCount > 99
                      ? "99+"
                      : item.likeCount
                    : "0"
                }}
              </span>
              <span
                ><img src="@/assets/myTask/star.png" alt="" />
                {{
                  item.favourateCount
                    ? item.favourateCount > 99
                      ? "99+"
                      : item.favourateCount
                    : "0"
                }}
              </span>
              <span
                ><img src="@/assets/myTask/eye.png" alt="" />
                {{
                  item.previewCount
                    ? item.previewCount > 99
                      ? "99+"
                      : item.previewCount
                    : "0"
                }}
              </span>
              <span
                ><img src="@/assets/myTask/share.png" alt="" />
                {{
                  item.forwardCount
                    ? item.forwardCount > 99
                      ? "99+"
                      : item.forwardCount
                    : "0"
                }}
              </span>
            </div>
          </div>
        </div>
        <div class="pages">
          <el-pagination
            background
            layout="prev, pager, next ,jumper"
            :total="gotProjects.total"
            @current-change="currentChange"
            :page-size="pageSize"
          >
            <!-- 跳转到 -->
            <template #layout> {{ $t("explore.word5") }} </template>
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="enpty" v-else>
      <div>
        <img src="@/assets/myTask/defaultEnpty.png" alt="" />
        <!-- 当前无可浏览项目 -->
        <div>{{ $t("explore.word6") }}</div>
      </div>
    </div>
    <Footer v-if="language !== 'en'" />
  </div>
</template>

<script>
import Header from "@/views/components/header/header.vue";
import Footer from "@/views/components/footer/footer.vue";
import {
  getAllReleases,
  getAllCategories,
  getAllReleasesForUser,
} from "@/api/workflow.js";
// import { getLanguage } from "@/utils/getLanguage.js";
// import LangManager from "../../utils/langManager";
import { removeTaskGuid } from "@/utils/setTaskGuid";
import moment from "moment";
import { mapGetters } from "vuex/dist/vuex.cjs.js";
export default {
  props: {},
  data() {
    return {
      // language: LangManager.getLanguage().value,
      pageSize: 12,
      loading: false,
      gotProjects: {},
      showList: null,
      categories: null,

      periods: [
        // 全部时间
        { value: 0, label: this.$t("explore.word7") },
        // 今天
        { value: 1, label: this.$t("explore.word8") },
        // 本周
        { value: 2, label: this.$t("explore.word9") },
        // 本月
        { value: 3, label: this.$t("explore.word10") },
      ],

      orderCodes: [
        {
          value: 0,
          // 获取次数
          label: this.$t("explore.word11"),
        },
        {
          value: 1,
          // 预览次数
          label: this.$t("explore.word12"),
        },
        {
          value: 2,
          // 点赞次数
          label: this.$t("explore.word13"),
        },
        {
          value: 3,
          // 最新
          label: this.$t("explore.word14"),
        },
      ],
      value: "",
      queryOptions: {
        selectedCategoryId: null,
        selectedPeriod: 0,
        selectedOrder: 3,
        searchStr: "",
      },
      dropdownShowInfo: null,
    };
  },
  computed: {
    ...mapGetters(['language'])
  },
  async created() {
    this.setPageSizeByScreen();
    console.log(this.$route.query.q);
    if (this.$route.query.q) {
      this.queryOptions.searchStr = this.$route.query.q;
    }

    this.dropdownShowInfo = this.orderCodes[3];

    await this.getAllCategories()    
    
    await this.searchItemByApi();
    console.log(this.$store.state.User);
    // this.showList = this.searchItem(
    //   JSON.parse(JSON.stringify(this.gotProjects.data)),
    //   this.queryOptions.selectedCategoryId,
    //   this.queryOptions.selectedPeriod,
    //   this.queryOptions.selectedOrder
    // );
  },
  mounted() {
    window.addEventListener("resize", this.setPageSizeByScreen);
  },
  watch: {
    async pageSize(newVal, oldVal) {
      // console.log(newVal,oldVal);
      if (newVal != oldVal) {
      }
    },
    "queryOptions.searchStr": {
      async handler(newVal, oldVal) {
        console.log(newVal);
        await this.searchItemByApi();
      },
      deep: true,
    },
    '$i18n.locale'(newValue, oldValue) {
      this.getAllCategories()
    }
  },
  methods: {
    async getAllCategories(){
      const categories = await getAllCategories(this.language);
      // 全部
      this.categories = [
        { value: false, label: this.$t("explore.word15") },
      ].concat(
        categories.map((x) => {
          return { value: x.categoryId, label: x.categoryName };
        })
      );
      this.queryOptions.selectedCategoryId = this.categories[0].value;

      this.periods = [
        // 全部时间
        { value: 0, label: this.$t("explore.word7") },
        // 今天
        { value: 1, label: this.$t("explore.word8") },
        // 本周
        { value: 2, label: this.$t("explore.word9") },
        // 本月
        { value: 3, label: this.$t("explore.word10") },
      ];

      this.orderCodes = [
        {
          value: 0,
          // 获取次数
          label: this.$t("explore.word11"),
        },
        {
          value: 1,
          // 预览次数
          label: this.$t("explore.word12"),
        },
        {
          value: 2,
          // 点赞次数
          label: this.$t("explore.word13"),
        },
        {
          value: 3,
          // 最新
          label: this.$t("explore.word14"),
        }];
    },

    refreshList() {
      this.searchItemByApi();
    },
    setPageSizeByScreen() {
      let width = document.body.offsetWidth;
      if (width > 1919) {
        this.pageSize = 15;
      } else {
        this.pageSize = 12;
      }
    },
    getSearchStr(str) {
      this.queryOptions.searchStr = str;
    },
    async searchData(page, size, startDate, endDate, categories, orderCode) {
      // this.showList = this.searchItem(
      //   JSON.parse(JSON.stringify(this.gotProjects.data)),
      //   this.queryOptions.selectedCategoryId,
      //   this.queryOptions.selectedPeriod,
      //   this.queryOptions.selectedOrder
      // );
      if (this.$store.state.User.accountId) {
        this.gotProjects = await getAllReleasesForUser(
          page,
          size,
          startDate,
          endDate,
          categories,
          orderCode
        );
      } else {
        this.gotProjects = await getAllReleases(
          page,
          size,
          startDate,
          endDate,
          categories,
          orderCode
        );
      }
    },
    getWeekEndDate() {
      var now = new Date();
      var nowDayOfWeek = now.getDay();
      var nowYear = now.getFullYear(); //当前年
      var nowMonth = now.getMonth(); //当前月
      var nowDay = now.getDate(); //当前日
      var weekEndDate = new Date(
        nowYear,
        nowMonth,
        nowDay + (6 - nowDayOfWeek) + 2
      ); //因为本周结束日期日历认为是周六，所以结束日期加了一天，
      return new Date(weekEndDate);
    },
    async searchItemByApi() {
      let startTimeToEndTime = null;
      if (this.queryOptions.selectedPeriod === 1) {
        startTimeToEndTime = this.getStartAndEndTime("day");
      } else if (this.queryOptions.selectedPeriod === 2) {
        startTimeToEndTime = this.getStartAndEndTime("week");
      } else if (this.queryOptions.selectedPeriod === 3) {
        startTimeToEndTime = this.getStartAndEndTime("month");
      }
      // let options = {
      //   startDate: new Date(startTimeToEndTime.startTime),
      //   endDate: new Date(startTimeToEndTime.endTime),
      //   orderCode: selectedCategoryId,
      //   // searchStr: "111",
      //   size: 12,
      //   page: this.gotProjects.size,
      // };
      if (this.$store.state.User.accountId) {
        this.gotProjects = await getAllReleasesForUser(
          this.gotProjects.page,
          this.pageSize,
          startTimeToEndTime && startTimeToEndTime.startTime
            ? new Date(startTimeToEndTime.startTime)
            : null,
          startTimeToEndTime && startTimeToEndTime.endTime
            ? new Date(startTimeToEndTime.endTime)
            : null,
          this.queryOptions.selectedCategoryId
            ? [this.queryOptions.selectedCategoryId]
            : null,
          this.queryOptions.selectedOrder,
          this.queryOptions.searchStr
        );
      } else {
        this.gotProjects = await getAllReleases(
          this.gotProjects.page,
          this.pageSize,
          startTimeToEndTime && startTimeToEndTime.startTime
            ? new Date(startTimeToEndTime.startTime)
            : null,
          startTimeToEndTime && startTimeToEndTime.endTime
            ? new Date(startTimeToEndTime.endTime)
            : null,
          this.queryOptions.selectedCategoryId
            ? [this.queryOptions.selectedCategoryId]
            : null,
          this.queryOptions.selectedOrder,
          this.queryOptions.searchStr
        );
      }
    },
    // searchItem(dataList, selectedCategoryId, selectedPeriod, selectedOrder) {
    //   let tempArr = [];
    //   if (selectedCategoryId !== 0) {
    //     dataList.forEach((value, index) => {
    //       if (value.categoryId === selectedCategoryId) {
    //         console.log(value);
    //         tempArr.push(value);
    //       }
    //     });
    //   } else {
    //     tempArr = dataList;
    //   }

    //   var result = null;
    //   console.log(this.getWeekEndDate());
    //   if (selectedPeriod === 1) {
    //     result = this.getStartAndEndTime("day");
    //   } else if (selectedPeriod === 2) {
    //     result = this.getStartAndEndTime("week");
    //   } else if (selectedPeriod === 3) {
    //     result = this.getStartAndEndTime("month");
    //   }
    //   console.log(result);
    //   if (result) {
    //     console.log(
    //       moment(result.startTime).format("l") +
    //         " " +
    //         moment(result.startTime).format("LTS")
    //     );
    //     console.log(
    //       moment(result.endTime).format("l") +
    //         " " +
    //         moment(result.endTime).format("LTS")
    //     );
    //   }

    //   if (result) {
    //     tempArr = tempArr.filter((value, index) => {
    //       let releaseDate = new Date(value.releasedAt);
    //       console.log(
    //         moment(value.releasedAt).format("l") +
    //           " " +
    //           moment(value.releasedAt).format("LTS") +
    //           "   " +
    //           value.projectName
    //       );
    //       return (
    //         releaseDate > new Date(result.startTime) &&
    //         releaseDate < new Date(result.endTime)
    //       );
    //     });
    //   }
    //   if (selectedOrder === 0) {
    //     tempArr.sort((a, b) => {
    //       return b.ownCount - a.ownCount;
    //     });
    //   } else if (selectedOrder === 1) {
    //     tempArr.sort((a, b) => {
    //       return b.previewCount - a.previewCount;
    //     });
    //   } else if (selectedOrder === 2) {
    //     tempArr.sort((a, b) => {
    //       return b.likeCount - a.likeCount;
    //     });
    //   } else if (selectedOrder === 3) {
    //     tempArr.sort((a, b) => {
    //       return new Date(b.releasedAt) - new Date(a.releasedAt);
    //     });
    //   }
    //   return tempArr;
    // },
    getStartAndEndTime(type) {
      let nowTime = +new Date(moment().format("l")) + 24 * 60 * 60 * 1000;
      var startTime;
      var endTime;
      if (type === "day") {
        startTime = nowTime - 24 * 60 * 60 * 1000;
        endTime = nowTime;
      } else if (type === "week") {
        endTime = +new Date(new Date(this.getWeekEndDate()));
        startTime = endTime - 7 * 24 * 60 * 60 * 1000;
      } else if (type === "month") {
        let todayDate = moment(nowTime - 24 * 60 * 60 * 1000).format("l");

        let dataArr = todayDate.split("/");
        dataArr.forEach((value, index) => {
          dataArr[index] = Number(value);
        });
        dataArr[2] = 1;
        startTime = +new Date(dataArr.join("/"));
        if (dataArr[1] + 1 > 12) {
          dataArr[1] = 1;
          dataArr[0] += 1;
        } else {
          dataArr[1] += 1;
        }
        let monthEndDate = dataArr.join("/");
        console.log(monthEndDate);

        endTime = +new Date(monthEndDate);
      }
      return { startTime, endTime };
    },
    async selectDrop(item, index) {
      console.log(item);
      this.dropdownShowInfo = item;
      this.queryOptions.selectedOrder = item.value;
      await this.searchItemByApi();
      // this.showList = this.searchItem(
      //   JSON.parse(JSON.stringify(this.gotProjects.data)),
      //   this.queryOptions.selectedCategoryId,
      //   this.queryOptions.selectedPeriod,
      //   this.queryOptions.selectedOrder
      // );
    },
    enterProject(project) {
      // setProjectInfo(JSON.stringify(project));
      // console.log(this.$store.state.User.user);
      // this.$router.push(`/taskDetail/${project.releaseGuid}`);
      // this.$router.push(`/taskDetail/${project.releaseGuid}/release/true/*`);
      // this.$router.push(`/taskDetail/${project.releaseGuid}/*/true`); //release/projectGuid
      // this.$router.push(
      //   `/taskDetail?releaseGuid=${project.releaseGuid}&projectGuid=*&isRelease=true`
      // );
      console.log(!this.$store.state.User.accountId);
      if (!this.$store.state.User.accountId) {
        this.$store.commit("SET_SHOWlOGIN", true);
        this.$store.commit("SET_ACCOUNTSIGN", "login");
        return;
      }
      this.$router.push({
        path: "/taskDetail",
        query: {
          releaseGuid: project.releaseGuid,
          projectGuid: project.projectGuid,
        },
      });
      removeTaskGuid();
    },
    async currentChange(page) {
      console.log(page - 1);
      this.gotProjects.page = page - 1;
      this.loading = true;
      await this.searchItemByApi();
      this.loading = false;
    },
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped lang="scss">
.exploreHeader {
  background: -webkit-linear-gradient(#ebf5fd, #cbe4fe);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(#ebf5fd, #cbe4fe);
  /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(#ebf5fd, #cbe4fe);
  /* Firefox 3.6 - 15 */
  background: linear-gradient(#ebf5fd, #cbe4fe);
  // height: 8rem;
  padding: 0 2rem 2rem 2rem;

  > div {
    > p {
      margin: 0 0 1rem 0;
    }
    .exploreTitle {
      font-size: 2rem;
      line-height: 3.3rem;
      color: #4984f5;
      font-weight: 600;
      margin-bottom: 1rem;
      letter-spacing: 1px;
    }
    .exploreTitle1 {
      font-size: 1.2rem;
      line-height: 1.5rem;
      margin-bottom: 1rem;
      color: #999;
    }
  }
}
.exploreSelect {
  // margin-top: 2rem;
  padding-top: 2vw;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  padding-bottom: 1vw;
  > div {
    display: flex;
    justify-content: space-between;
    width: 78.5%;

    :deep(.el-input__inner) {
      border-color: #b5bec8;
      border-radius: 11px;
      color: #274e78;
    }
    > .searchWarp {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      > div {
        margin-right: 2rem;
      }
    }
    > .sortWarp {
      > p {
        margin: 0;
        text-align: left;
        font-size: 1rem;
        color: #274e78;
        margin-bottom: 0.2rem;
      }
      > :deep(.el-dropdown) {
        cursor: pointer;
        width: 7rem;
        text-align: left;
        .exploreDropdownTitle {
          font-size: 1.2rem;
          color: #274e78;
          font-weight: 600;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    :deep(.selfStyle_input) {
      width: 15%;
    }
    :deep(.selfStyle_search) {
      width: 30%;
    }
  }
}
.enpty {
  background-color: #fff;
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.exploreBody {
  width: 100%;

  background-color: #fff;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 21.5rem);
  flex-wrap: wrap;
  // min-height: 75vh;
  // position: relative;
  @media (min-width: 1920px) {
    > div {
      width: 80%;
      // display: flex;
      // justify-content: flex-start;
      // flex-wrap: wrap;
      padding: 2rem 2rem 4rem 2rem;
      position: relative;
      .myProjectItem {
        width: 18%;
        height: 0;
        // padding: 1rem;
        text-align: left;
        box-sizing: border-box;
        position: relative;
        padding-bottom: 16.5%;
        margin-right: 1%;
        margin-left: 1%;
        float: left;
        > div {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          .expImgWarp {
            border: none;
            background-color: #ccc;
            width: 100%;
            overflow: hidden;
            border-radius: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60%;
            cursor: pointer;
            position: relative;
            > div {
              width: 15%;
              position: absolute;
              bottom: 6%;
              left: 4%;
              // background: #fff;
              border-radius: 14%;
              display: flex;
              justify-content: center;
              align-items: center;
              > div {
                width: 100%;
                > img {
                  width: 100%;
                }
              }
            }
            > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            img {
              width: 100%;
              // height: 5rem;
              border: none;
            }
          }
          > p {
            font-size: 0.7vw;
            font-weight: 600;
            line-height: 1.05vw;
            height: 2vw;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin-top: 5px;
            margin-bottom: 5px;
            color: #262626;
          }
          .operation {
            font-size: 0.7vw;
            color: #888;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 2%;
            :deep(.dropDown) {
              width: 2rem;
              height: 1rem;
              position: absolute;
              right: 0;
            }
            > span {
              // margin-right: 1.5vw;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              // width: 2vw;
              // height: 1vw;
              padding-right: 0.85vw;
              line-height: 1vw;
              > img {
                width: 0.7vw;
                height: 0.7vw;
                margin-right: 0.3vw;
              }
            }
            .more {
              margin: 0;
              position: absolute;
              right: 0;
              display: flex;
              justify-content: right;
              align-items: center;
              font-size: 0.9vw;
              cursor: pointer;
              top: -26%;
              > i {
                transform: rotate(-90deg);
              }
            }
          }
        }
      }
      .pages {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        position: absolute;
        bottom: 1rem;
        :deep(.el-pagination) {
          display: flex;
          justify-content: space-around;
          align-items: center;
          > button {
            background: #fff;
            color: #8fb5ff;
            border: 1px solid #dae6ff;
            border-radius: 10px;
            width: 60px;
            height: 38px;
          }
          > button:hover {
            border: 1px solid #4582ff;
          }
          > button[disabled] {
            border: 1px solid #dae6ff;
          }
          .el-pager {
            display: flex;
            justify-content: space-around;
            align-items: center;
            > li {
              height: 36px;
              padding: 0 1rem;
              background: #ffffff;
              border-radius: 10px;
              opacity: 1;
              border: 1px solid #dae6ff;
              color: #224b77;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 400;
            }
            > li:hover {
              border: 1px solid #4582ff;
            }
            .active {
              border: 1px solid #4582ff;
              background: #4582ff;
              color: #ffffff;
            }
          }
          > span {
            height: auto;
            > div {
              width: auto;
              height: auto;
              > input {
                background: #eff3fc;
                width: 60px;
                height: 36px;
                border-radius: 28px;
                color: #224b77;
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 0px) and (max-width: 1919px) {
    > div {
      width: 80%;
      // display: flex;
      // justify-content: flex-start;
      // flex-wrap: wrap;
      padding: 2rem 2rem 4rem 2rem;
      position: relative;
      .myProjectItem {
        width: 23%;
        height: 0;
        // padding: 1rem;
        text-align: left;
        box-sizing: border-box;
        position: relative;
        padding-bottom: 21.5%;
        margin-right: 1%;
        margin-left: 1%;
        float: left;
        > div {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          .expImgWarp {
            border: none;
            background-color: #ccc;
            width: 100%;
            overflow: hidden;
            border-radius: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60%;
            cursor: pointer;
            position: relative;
            > div {
              width: 15%;
              position: absolute;
              bottom: 6%;
              left: 4%;
              // background: #fff;
              border-radius: 14%;
              display: flex;
              justify-content: center;
              align-items: center;
              > div {
                width: 100%;
                > img {
                  width: 100%;
                }
              }
            }
            > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            img {
              width: 100%;
              // height: 5rem;
              border: none;
            }
          }
          > p {
            font-size: 0.85vw;
            font-weight: 600;
            line-height: 1.1vw;
            height: 2.5vw;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin-top: 5px;
            margin-bottom: 5px;
            color: #262626;
          }
          .operation {
            font-size: 0.9vw;
            color: #888;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 2%;
            :deep(.dropDown) {
              width: 2rem;
              height: 1rem;
              position: absolute;
              right: 0;
            }
            > span {
              // margin-right: 1.5vw;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              // width: 2vw;
              // height: 1vw;
              padding-right: 0.85vw;
              line-height: 1vw;
              > img {
                width: 1vw;
                height: 1vw;
                margin-right: 0.3vw;
              }
            }
            .more {
              margin: 0;
              position: absolute;
              right: 0;
              display: flex;
              justify-content: right;
              align-items: center;
              font-size: 1.2vw;
              cursor: pointer;
              > i {
                transform: rotate(-90deg);
              }
            }
          }
        }
      }
      .pages {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        position: absolute;
        bottom: 1rem;
        :deep(.el-pagination) {
          display: flex;
          justify-content: space-around;
          align-items: center;
          > button {
            background: #fff;
            color: #8fb5ff;
            border: 1px solid #dae6ff;
            border-radius: 10px;
            width: 60px;
            height: 38px;
          }
          > button:hover {
            border: 1px solid #4582ff;
          }
          > button[disabled] {
            border: 1px solid #dae6ff;
          }
          .el-pager {
            display: flex;
            justify-content: space-around;
            align-items: center;
            > li {
              height: 36px;
              padding: 0 1rem;
              background: #ffffff;
              border-radius: 10px;
              opacity: 1;
              border: 1px solid #dae6ff;
              color: #224b77;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 400;
            }
            > li:hover {
              border: 1px solid #4582ff;
            }
            .active {
              border: 1px solid #4582ff;
              background: #4582ff;
              color: #ffffff;
            }
          }
          > span {
            height: auto;
            > div {
              width: auto;
              height: auto;
              > input {
                background: #eff3fc;
                width: 60px;
                height: 36px;
                border-radius: 28px;
                color: #224b77;
              }
            }
          }
        }
      }
    }
    /* 当屏幕宽度在 0 到 1200 像素之间时应用的样式 */
    @media screen and (max-width: 1200px) {
      > div {
        > .myProjectItem {
          > div {
            > .expImgWarp {
              border-radius: 0.4rem;
              > div {
                width: 25%;
              }
            }
            > p {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              font-size: 13px;
              line-height: 2.5vw;
            }
            > .operation {
              font-size: 10px;
              > span {
                > img {
                  width: 10px;
                  height: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
/* 当屏幕宽度在 0 到 1200 像素之间时应用的样式 */
@media screen and (max-width: 1200px) {
  .exploreHeader{
    >div:nth-child(2){
      >p:nth-child(1){
        font-size: 22px;
      }
      >p:nth-child(2){
        font-size: 15px;
      }
    }
  }
  .exploreBody {
    > div {
      width: 90%;
    }
  }
}
.dropDown {
  padding: 1rem;
  :deep(.el-select-dropdown__item):hover {
    color: #4582ff;
  }
}
.taskCtrlListWarp {
  padding: 0px 10px 0px 10px;
  // width: 10rem;
  line-height: 2rem;
  .btnWarp {
    padding: 0;
    button {
      padding: 0.3rem 0.8rem;
      display: block;
      outline: none;
      border: none;
      width: 100%;
      background: #fff;
      text-align: left;
      color: #274e78;
      font-size: 0.8rem;
      border-radius: 3px;
      cursor: pointer;
      line-height: 1.5rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      > span {
        font-size: 0.9rem;
        color: #8fb5ff;
      }
      > img {
        width: 1.5rem;
        line-height: 2rem;
        margin-right: 0.5rem;
      }
    }
    button[disabled] {
      // background: #ccc!important;
      color: #ccc !important;
      cursor: not-allowed;
    }
    button:hover {
      color: #4582ff;
      background: #f5f7fa;
    }
  }

  // .btnWarp:last-child {
  //   button {
  //     background: #fff;
  //     border-radius: 4px 4px 4px 4px;
  //     color: #f94a4a;
  //   }
  //   button:hover {
  //     background: #ffecec;
  //   }
  // }
}
.el-select-dropdown__item {
  color: #274e78;
}
.el-select-dropdown__item:hover {
  color: #4582ff;
  font-weight: 400;
}
.selected {
  color: #4582ff;
  font-weight: 400;
}
</style>
