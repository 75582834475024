<template>
  <div id="headerWarp">
    <div
      id="headerFat"
      :class="$route.path === '/enterprisePage' ? 'componyHeaderFat' : ''"
    >
      <div
        id="header"
        :class="$route.path === '/enterprisePage' ? 'componyHeader' : ''"
      >
        <div class="logo" @click="goIndex">
          <img
            v-if="$route.path == '/enterprisePage'"
            src="@/assets/home/logoYellow.png"
            alt=""
          />
          <img v-else src="@/assets/home/logoBlue.png" alt="" />
        </div>
        <div
          :class="
            $route.path === '/enterprisePage'
              ? 'classMenu componyClassMenu'
              : 'classMenu'
          "
        >
          <div
            @click="explore"
            :class="$route.path == '/explore' ? 'active' : ''"
          >
            <!-- 探索 -->
            {{ $t("header.explore") }}
          </div>
          <div
            @click="enterprise"
            :class="$route.path == '/enterprisePage' ? 'active' : ''"
          >
            <!-- 企业用户 -->
            {{ $t("header.enterprisePage") }}
          </div>
          <!-- 产品下载 -->
          <div @click="download">{{ $t("header.download") }}</div>
        </div>
        <div class="searchInput">
          <!-- 输入要搜索的内容 -->
          <el-input
            :class="
              $route.path === '/enterprisePage'
                ? 'searchGlobal componySearchGlobal'
                : 'searchGlobal'
            "
            size="mini"
            v-model="searchStr"
            type="text"
            :placeholder="$t('header.serchTip')"
            @keyup.enter="searchGloval"
          >
            <template #suffix>
              <i class="el-icon-search" @click="searchGloval"> </i>
            </template>
          </el-input>
        </div>
        <div class="language">
          <el-select
            v-model="lang"
            size="mini"
            placeholder="Select"
            @change="langChange"
          >
            <el-option
              v-for="[index, item] in langs"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div
          :class="
            $route.path === '/enterprisePage'
              ? 'loginStatus componyLoginStatus'
              : 'loginStatus'
          "
        >
          <div v-if="!$store.state.User.accountId" @click="loginClick">
            <!-- 登录 -->
            {{ $t("header.login") }}
          </div>
          <div v-if="!$store.state.User.accountId" @click="registerClick">
            <!-- 注册 -->
            {{ $t("header.regist") }}
          </div>

          <span v-if="$store.state.User.accountId">
            <!-- 创建项目 -->
            <el-dropdown trigger="click">
              <button class="sendTask">{{ $t("header.createProject") }}</button>
              <template #dropdown>
                <div class="taskCtrlListWarp">
                  <el-dropdown-menu>
                    <el-dropdown-item class="btnWarp" @click.prevent="select2D">
                      <button>{{ $t("header.twoD") }}</button>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                  <el-dropdown-menu>
                    <el-dropdown-item class="btnWarp" @click.prevent="select3D">
                      <button>{{ $t("header.threeD") }}</button>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </div>
              </template>
            </el-dropdown>
          </span>
          <p
            :class="
              $route.path === '/myAccount' || $route.path === '/myTasks'
                ? 'active'
                : ''
            "
          >
            <el-dropdown
              v-if="$store.state.User.accountId"
              @command="handleCommand"
              trigger="click"
              class="dropdown"
            >
              <span class="el-dropdown-link" id="nameStr">
                <!-- <el-image :src="profilePhotoUrl">
                  <template #error>
                    <div class="image-slot">
                      <img src="@/assets/login/default-head.png" alt="" />
                    </div>
                  </template>
                </el-image> -->
                <img v-if="profilePhotoUrl" :src="profilePhotoUrl" alt="" />
                <img v-else src="@/assets/login/default-head.png" alt="" />
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <!-- 我的账户 -->
                  <el-dropdown-item command="myAccount" id="history">{{
                    $t("header.myAccount")
                  }}</el-dropdown-item>
                  <!-- 数据统计 -->
                  <el-dropdown-item command="dataCharts" id="charts">{{
                    $t("header.dataChart")
                  }}</el-dropdown-item>
                  <!-- 我的项目 -->
                  <el-dropdown-item command="myTasks" id="taskSet">{{
                    $t("header.myProject")
                  }}</el-dropdown-item>
                  <!-- 审核项目 -->
                  <el-dropdown-item
                    v-show="$store.state.User.roles.includes(68)"
                    command="auditProject"
                    id="auditProject"
                    >{{ $t("header.auditProject") }}</el-dropdown-item
                  >
                  <!-- 登出 -->
                  <el-dropdown-item command="logout" id="deleteMission">{{
                    $t("header.logout")
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </p>
          <!-- <div v-if="$store.state.User.userToken" @click="userInfo">
        个人中心(移除)
      </div> -->
        </div>
        <!-- <Login ref="login" /> -->
      </div>
    </div>
    <div class="accounts">
      <el-dialog
        v-if="$store.state.User.showLogin"
        v-model="$store.state.User.showLogin"
        width="30rem"
        :style="{ 'border-radius': '1rem' }"
        custom-class="account"
      >
        <template #title>
          <span v-if="$store.state.User.accountSign === 'login'">
            <!-- .登录 -->
            <p class="title">{{ $t("header.login") }}</p>
          </span>
          <span v-if="$store.state.User.accountSign === 'register'">
            <!-- 注册 -->
            <p class="title">{{ $t("header.regist") }}</p>
          </span>
          <span v-if="$store.state.User.accountSign === 'forgetPass'">
            <p class="title">
              <!-- 忘记密码 -->
              <i @click="backLogin" class="el-icon-back"></i>
              {{ $t("header.forgetPass") }}
            </p>
            <!-- 请输入手机号和验证码找回密码 -->
            <p class="tip">{{ $t("header.tips1") }}</p>
          </span>
          <span v-if="$store.state.User.accountSign === 'resetSuccess'">
            <p class="title">
              <i @click="goForgetPass" class="el-icon-back"></i>
            </p>
          </span>
        </template>
        <Login
          @goRegister="registerClick"
          @goForgetPass="goForgetPass"
          @refreshList="refreshList"
          v-if="$store.state.User.accountSign === 'login'"
        />
        <Register
          @goLogin="backLogin"
          v-if="$store.state.User.accountSign === 'register'"
        />
        <ForgetPass
          @goLogin="backLogin"
          v-if="$store.state.User.accountSign === 'forgetPass'"
        />
        <ResetPassSuccess
          @goLogin="backLogin"
          v-if="$store.state.User.accountSign === 'resetSuccess'"
        />
      </el-dialog>
    </div>
    <div id="createProject">
      <!-- 创建项目 -->
      <el-dialog
        v-if="createProjectSwitch"
        :title="$t('header.createProject')"
        v-model="createProjectSwitch"
        width="30rem"
        custom-class="createProjectDialog"
      >
        <CreateProject
          ref="createProject"
          :selectType="selectType"
          @closeCreate="closeCreateProject"
        />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Login from "./login/login.vue";
import Register from "./register/register.vue";
import ForgetPass from "./forgetPass/forgetPass.vue";
import ResetPassSuccess from "./resetPassSuccess/resetPassSuccess.vue";
import CreateProject from "./createProject/createProject.vue";
import LangManager from "../../../utils/langManager";
// import { removeUserInfo } from "@/utils/setLocalstorage";
export default {
  components: { Login, Register, CreateProject, ForgetPass, ResetPassSuccess },
  props: {},
  data() {
    return {
      selectType: 0, //0 3D流程  1 2D流程
      createProjectSwitch: false,
      searchStr: "",
      accountSwitch: false,
      // accountSign: "login",
      // langs: [...langs],
      langs: LangManager.Languages,
      lang: LangManager.getLanguage().value,
    };
  },
  computed: {
    // ...mapGetters(['language']),
    profilePhotoUrl() {
      return this.$store.state.User.profilePhotoUrl;
    },
  },
  created() {
    // let lang = localStorage.getItem("language_type");
    // this.lang = lang;
    // if (lang) {
    //   this.$i18n.locale = lang;
    //   this.lang = lang;
    // } else {
    //   this.lang = langs[0].value;
    //   this.$i18n.locale = langs[0].value;
    //   localStorage.setItem("language_type", langs[0].value);
    // }
    console.log("header ok", this.langs, this.lang);
    // this.$store.dispatch("getInfo");
    console.log(this.$route.path);
    console.log(this.$store.state.User.userInfo);
    if (this.$route.path == "/explore") {
      this.searchStr = this.$route.query.q;
    }
  },
  mounted() {
    let mothod = Number(this.$route.query.openMethod);
    if (mothod == 0) {
      //登录
      this.$store.commit("SET_SHOWlOGIN", true);
      this.$store.commit("SET_ACCOUNTSIGN", "login");
    } else if (mothod == 1) {
      //注册
      this.$store.commit("SET_SHOWlOGIN", true);
      this.$store.commit("SET_ACCOUNTSIGN", "register");
    } else if (mothod == 2) {
      //忘记密码
      this.$store.commit("SET_SHOWlOGIN", true);
      this.$store.commit("SET_ACCOUNTSIGN", "forgetPass");
    } else if (mothod == 3) {
      //重置密码成功
      this.$store.commit("SET_SHOWlOGIN", true);
      this.$store.commit("SET_ACCOUNTSIGN", "resetSuccess");
    }
  },
  watch: {},
  methods: {
    langChange() {
      console.log(this.lang);
      this.$store.dispatch('setLanguage', this.lang);
      // this.$store.commit("SET_LANGUAGE", this.lang);
      // localStorage.setItem("language_type", this.lang);
      // this.$i18n.locale = this.lang;
      // this.$router.go(0);
    },
    refreshList() {
      this.$emit("refreshList");
    },
    goForgetPass() {
      this.$store.commit("SET_SHOWlOGIN", true);
      this.$store.commit("SET_ACCOUNTSIGN", "forgetPass");
    },
    closeCreateProject() {
      this.createProjectSwitch = false;
    },
    scrollPage(domName) {
      //index与循环的index对应可自由设置动态获取
      const PageId = document.querySelector(domName);
      PageId.scrollIntoView();
    },
    download() {
      if (this.$route.path !== "/") {
        this.$router.push("/?page=download");
      } else {
      }
      this.$nextTick(() => {
        console.log(document.getElementById("scrollMainBody"));
        // document.getElementById("scrollMainBody").scrollTop = 99999999;
        this.scrollPage("#download");
        this.$router.replace("/");
        // scroller.scrollTo(0, scroller.scrollHeight);
      });
    },
    handleCommand(command) {
      console.log(command);
      if (command === "logout") {
        console.log(111);
        this.$store.dispatch("logout");
        this.$store.commit("SET_USERLOGOUT");
        // localStorage.removeItem("workflow_userInfo")
        // removeUserInfo();
        this.$emit("refreshList");
        console.log(this.$route.path === "/myAccount");
        if (
          this.$route.path === "/myAccount" ||
          this.$route.path === "/myTasks" ||
          this.$route.path === "/dataCharts" ||
          this.$route.path === "/auditProject"
        ) {
          this.$router.push("/");
        }
      } else if (command === "myTasks") {
        this.$router.push("/myTasks");
      } else if (command === "myAccount") {
        this.$router.push("/myAccount");
      } else if (command === "dataCharts") {
        this.$router.push("/dataCharts?tab=myCreated");
      } else if (command === "auditProject") {
        this.$router.push("/auditProject");
      }
    },

    backLogin() {
      this.$store.commit("SET_SHOWlOGIN", true);
      this.$store.commit("SET_ACCOUNTSIGN", "login");
      // this.accountSign = "login";
    },
    registerClick() {
      console.log(222);
      // this.accountSwitch = true;
      this.$store.commit("SET_SHOWlOGIN", true);
      this.$store.commit("SET_ACCOUNTSIGN", "register");
      // this.accountSign = "register";
    },
    loginClick() {
      // this.accountSwitch = true;
      this.$store.commit("SET_SHOWlOGIN", true);
      this.$store.commit("SET_ACCOUNTSIGN", "login");
      // this.accountSign = "login";
    },
    select2D() {
      this.selectType = 1;
      this.userInfo();
    },
    select3D() {
      this.selectType = 0;
      this.userInfo();
    },
    userInfo() {
      // this.$router.push("/taskDetail");
      this.createProjectSwitch = true;
    },
    searchGloval() {
      console.log(this.searchStr);
      console.log(this.$router);
      console.log(this.$route);
      if (!this.searchStr) {
        this.$emit("getSearchStr", "");
        return;
      }
      this.$router.push(`/explore?q=${this.searchStr}`);
      this.$emit("getSearchStr", this.searchStr);
    },
    enterprise() {
      this.$router.push("/enterprisePage");
    },
    explore() {
      this.$router.push("/explore");
    },
    goIndex() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped lang="scss">
#headerWarp {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#headerFat {
  width: 75%;
  min-width: 1200px;
  background: linear-gradient(135deg, #c5ddfa 0%, rgba(202, 228, 255, 0) 100%);
  padding: 4px;
  box-shadow: 0px 10px 26px 1px rgba(39, 99, 222, 0.1599999964237213);
  margin-top: 1.75rem;
  border-radius: calc(1.25rem + 4px);
  margin-bottom: 1.75rem;
  overflow: hidden;
  position: relative;
  z-index: 10;
}
#header {
  width: 100%;
  display: flex;
  justify-content: space-around;
  // border: 4px solid #c5ddfa;
  background: linear-gradient(135deg, #cae4ff 0%, rgba(202, 228, 255, 0) 100%);
  box-shadow: 0px 10px 26px 1px rgba(39, 99, 222, 0.1599999964237213);

  height: 2.5rem;
  // margin-top: 1.75rem;
  border-radius: 1.25rem;
  padding: 0 5px;
  box-sizing: border-box;
  // margin-bottom: 1.75rem;
  // border-image: linear-gradient(-270deg, #c5ddfa, rgba(0, 0, 0, 0)) 4 4;
  // background-clip: content-box, border-box;
  // background-origin: border-box;
  .logo {
    width: 20%;
    margin-right: 1rem;
    max-width: 200px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 85%;
    }
  }
  .classMenu {
    width: 40%;
    max-width: 330px;
    min-width: 240px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
    > div {
      padding: 0.5rem 1rem;
      cursor: pointer;
      border-radius: 4px;
      color: #274e78;
    }
    > div:hover {
      background: #deefff;
    }
    .active {
      background: #deefff;
    }
  }
  .componyClassMenu {
    width: 25%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 0.8rem;
    > div {
      padding: 0.5rem 1rem;
      cursor: pointer;
    }
    > div:hover {
      background: #fff2e3;
    }
    .active {
      background: #fff2e3;
    }
  }
  .searchInput {
    width: 35%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .language {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 141px;
    :deep(.el-select) {
      .select-trigger {
        .el-input {
          .el-input__inner {
            background: rgba(0, 0, 0, 0);
            border: 1px solid #dcdfe6;
          }
        }
      }
    }
  }
  .searchGlobal {
    width: 80%;
    overflow: hidden;
    :deep(.el-input__inner) {
      background: #cee5fc;
      border-radius: 1.5rem;
      color: #47505d;
      border: none;

      &::placeholder {
        color: #98bae5;
      }
    }
    // :deep(.el-input__inner)::placeholder {
    //   color: #98bae5;
    // }
    :deep(.el-input__suffix) {
      right: 10px;
      cursor: pointer;
      transition: color 0.2s;
      color: cornflowerblue;
      :hover {
        color: cornflowerblue;
      }
    }
  }
  .componySearchGlobal {
    width: 80%;
    overflow: hidden;
    :deep(.el-input__inner) {
      background: #fff;
      border-radius: 1.5rem;
      color: #47505d;

      &::placeholder{
        color: #181818;
        // color: red;
      }
    }
    // :deep(.el-input__inner)::placeholder {
    //   // color: #deb573;
    //   color: #181818;
    //   color: red;
    // }
    :deep(.el-input__suffix) {
      right: 10px;
      cursor: pointer;
      transition: color 0.2s;
      color: #deb573;
      :hover {
        color: #deb573;
      }
    }
  }

  .loginStatus {
    width: 20%;
    margin-left: 5%;
    max-width: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 0.8rem;

    > span {
      .sendTask {
        outline: none;
        border: none;
        background: #6a9bff;
        color: #fff;
        height: 2rem;
        border-radius: 1rem;
        text-align: center;
        line-height: 2rem;
        width: 6rem;
        font-size: 0.8rem;
        cursor: pointer;
        transition: background 0.3s;
      }
      .sendTask:hover {
        background: #8fb5ff;
      }
    }
    > div {
      padding: 0.5rem 1rem;
      cursor: pointer;
    }

    > div:hover {
      background: #deefff;
    }
    .active {
      background: #deefff;
    }
  }
  .componyLoginStatus {
    > span {
      > button {
        background: #deb573 !important;
        transition: background 0.3s;
      }
      > button:hover {
        background: #e7c796 !important;
      }
    }
    // > div:nth-child(1) {
    //   padding: 0;
    // }
    > div:hover {
      background: #fff2e3;
    }
  }
}
.componyHeaderFat {
  // background: linear-gradient(
  //   135deg,
  //   #ffe4b8 0%,
  //   rgba(202, 228, 255, 0) 100%
  // ) !important;
  // box-shadow: 0px 10px 13px 1px rgba(150, 82, 19, 0.1599999964237213) !important;
  background: #f6f6f6 !important;
  box-shadow: 0px 10px 13px 0px rgba(93, 93, 93, 0.16) !important;
}
.componyHeader {
  // background: linear-gradient(
  //   135deg,
  //   #ffe4b8 0%,
  //   rgba(255, 228, 202, 0) 100%
  // ) !important;
  background: #f6f6f6 !important;
  // box-shadow: 0px 10px 13px 1px rgba(150, 82, 19, 0.1599999964237213);
}
.accounts {
  :deep(.el-dialog__header) {
    // color: #4582ff;
    // background: #4582ff;
    line-height: 20px;
    padding: 15px;
    text-align: left;
    > span {
      > .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        color: #4582ff;
        font-size: 1rem;
        padding-left: 1rem;
        > i {
          cursor: pointer;
          font-size: 28px;
          margin-right: 22px;
        }
      }
      .tip {
        color: #a2a2a2;
        font-size: 12px;
        margin: 0;
        padding-left: 66px;
      }
    }
    .el-icon-close {
      color: #4582ff;
      top: 15px;
    }
    .el-icon-close:hover {
      color: #4582ff;
    }
  }
  :deep(.el-dialog__body) {
    padding: 10px 20px 1.5rem 20px;
  }
}
#nameStr {
  cursor: pointer;
  width: 2rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  background: #ccc;
  > img {
    width: 100%;
  }
}
#deleteMission:hover {
  background: #ffecec;
  color: #f94a4a;
}
:deep(.el-dropdown-menu__item) {
  margin: 0.5rem;
  border-radius: 5px;
  text-align: center;
}
:deep(.account) {
  border-radius: 10px;
  > .el-dialog__body {
    word-break: break-word;
  }
}
#createProject {
  :deep(.createProjectDialog) {
    border-radius: 10px;
  }
  :deep(.el-dialog__header) {
    line-height: 20px;
    padding: 15px;
    text-align: left;
    .el-dialog__title {
      color: #4582ff;
      font-size: 1rem;
      padding-left: 1rem;
    }
    .el-icon-close {
      color: #4582ff;
      top: 15px;
    }
    .el-icon-close:hover {
      color: #4582ff;
    }
  }
  :deep(.el-dialog__body) {
    padding: 0 20px 30px 20px;
  }
}
:deep(.el-dropdown-menu) {
  padding: 0;
}
.dropDown {
  padding: 1rem;
  :deep(.el-select-dropdown__item):hover {
    color: #4582ff;
  }
}
.taskCtrlListWarp {
  padding: 0px 10px 0px 10px;
  // width: 10rem;
  line-height: 2rem;
  .btnWarp {
    padding: 0 0.5rem;
    margin: 0.5rem 0;
    button {
      padding: 0.3rem 0.8rem;
      display: block;
      outline: none;
      border: none;
      width: 100%;
      background: #fff;
      text-align: left;
      color: #274e78;
      font-size: 0.8rem;
      border-radius: 3px;
      cursor: pointer;
      line-height: 1.5rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      > span {
        font-size: 0.9rem;
        color: #8fb5ff;
      }
      > img {
        width: 1.5rem;
        line-height: 2rem;
        margin-right: 0.5rem;
      }
    }
    button[disabled] {
      // background: #ccc!important;
      color: #ccc !important;
      cursor: not-allowed;
    }
    button:hover {
      color: #274e78;
      background: #ecf2ff;
    }
  }

  // .btnWarp:last-child {
  //   button {
  //     background: #fff;
  //     border-radius: 4px 4px 4px 4px;
  //     color: #f94a4a;
  //   }
  //   button:hover {
  //     background: #ffecec;
  //   }
  // }
}
/* 当屏幕宽度在 0 到 1200 像素之间时应用的样式 */
@media screen and (max-width: 1200px) {
  #headerFat {
    min-width: 750px;
  }
  #header {
    > .logo {
      margin-right: 0;
      > img {
        width: 100%;
      }
    }
    .loginStatus {
      margin-left: 1.5%;
      > div {
        padding: 0;
      }
      > div:nth-child(1) {
        margin-right: 14.5%;
      }
      > span {
        margin-right: 1rem;
        .sendTask {
          line-height: 1.8rem;
          height: 1.8rem;
        }
      }
    }
  }
  #nameStr {
    width: 1.8rem;
    height: 1.8rem;
  }
}
</style>
