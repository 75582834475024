<template>
  <div class="webGLWarp">
    <div class="betweenWarp">
      <iframe
        id="unity-infame"
        ref="frame"
        :src="$store.state.TaskDetail.headerPath + 'static/index.html'"
        frameborder="0"
        scrolling="no"
      ></iframe>
      <EditInput
        v-if="editInputIsShow"
        :relationship="relationship"
        class="editCop"
        ref="editInput"
        :showMap="showTask"
        :taskInfo="taskInfo"
        :isEdit="isEdit"
        :shapeItem="selectNode"
        @nextNode="nextNode"
        @prevNode="prevNode"
        @toggleMap="toggleMap"
        @backRouter="backRouter"
        @stepConfigInfoNeedSaveStatus="stepConfigInfoNeedSaveStatus"
      />
      <!-- sandbox="allow-scripts allow-same-origin" -->
    </div>
    <div class="taskLinkWarp" :class="showTask ? 'taskActive' : ''">
      <!-- <TaskChart
        ref="taskChart"
        :taskGuid="selectTaskGuid"
        :projectInfo="projectInfo"
        @sendData="getTaskStepData"
        @closeMap="closeMap"
        @getTaskInfo="getTaskInfo"
        :shortcutKey="false"
      /> -->
      <LogicflowChartVue
        ref="taskChart"
        v-if="projectInfo && webGLLoaded"
        :taskGuid="selectTaskGuid"
        :projectInfo="projectInfo"
        :stepConfigInfoNeedSave="stepConfigInfoNeedSave"
        @sendData="sendData"
        @closeMap="closeMap"
        @switchPointerStep="switchChart"
        @getTaskInfo="getTaskInfo"
        @onLogicFlowLoaded="onLogicFlowLoaded"
        @stepConfigInfoNeedSaveStatus="stepConfigInfoNeedSaveStatus"
        @saveStepInfoFromChart="saveStepInfoFromChart"
        @setSelectNode="setSelectNode"
        :shortcutKey="false"
      />
    </div>

    <!-- <unity
     
      width="1000"
      height="600"
      unityLoader="static/Unity/Build/APP.loader.js"
      ref="unityvue"
      class="main-unity"
    ></unity> -->
    <!-- <button @click="CallUnity(data)">sendMessage</button> -->
    <UploadAsset
      v-if="projectInfo && projectInfo.projectGuid"
      ref="uploadAsset"
      @onClose="addAsset"
      @preViewModel="preViewModel"
      :projectGuid="projectInfo.projectGuid"
    ></UploadAsset>
    <ModelLoader ref="modelLoader" @getThumbnail="getThumbnail" />
    <!-- <ModelLoading
      :scale="0.5"
      :class="!webGLLoaded ? 'importWarp zIndex16' : 'importWarp'"
      v-if="!webGLLoaded"
    /> -->
    <WebGLLOading
      :scale="0.35"
      :progress="progress"
      :class="!webGLLoaded ? ' zIndex16' : ''"
      :tip="loadingTip"
    />
  </div>
</template>

<script>
// import Unity from "vue-unity-webgl";

import { useRouter } from "vue-router";
import UploadAsset from "@/views/uploadAsset/uploadAsset.vue";
import ModelLoader from "@/views/components/modelLoader/modelLoader.vue";
import ModelLoading from "@/views/components/animation/modelLoading.vue";
import WebGLLOading from "@/views/components/animation/webGLLoading.vue";
import EditInput from "./components/editInput.vue";
// import TaskChart from "@/views/taskDetail/components/taskChart/taskChart.vue";
import { getLanguage } from "@/utils/getLanguage.js";
import {
  getSelectedGuid,
  setSelectedGuid,
  removeSelectedGuid,
} from "../taskDetail/components/taskChart/setSelectShape.js";
// import { getProjectInfo } from "@/utils/setLocalstorage";
import {
  getTaskById,
  getReleaseById,
  getProjectById,
  getStepInfo,
} from "@/api/workflow.js";
import LogicFlow from "@logicflow/core";
import LogicflowChartVue from "../taskDetail/components/logicflowChart/logicflow_webgl.vue";
import LangManager from "../../utils/langManager.js";
export default {
  components: {
    // TaskChart,
    UploadAsset,
    ModelLoader,
    ModelLoading,
    WebGLLOading,
    LogicflowChartVue,
    EditInput,
  },
  props: {},
  data() {
    return {
      selectNode: null,
      webGlStepConfigInfoNeedSave: false,
      stepConfigInfoNeedSave: false,
      editInputIsShow: false,
      webglSrc: "static/index.html",
      // 正在进入配置空间
      loadingTip: "正在进入配置空间...",
      webGLLoaded: false,
      iframeLoaded: false,
      logicFlowLoaded: false,
      projectInfo: "",
      selectTaskGuid: this.$route.query.taskGuid,
      showTask: false,
      iframWidth: 0,
      iframHeight: 0,
      taskInfo: null,
      isEdit: false,
      progress: 0,
      prevRouter: null,
      canBackRouter: true,
      relationship: {
        prev: [],
        next: [],
      },
      // data:{
      //   type: "open",
      //   value: {
      //     name: "2121",
      //     value: +new Date(),
      //   },
      // }
      selectStepInfo: {
        appearType: 0,
        delaySeconds: 0,
        index: 0,
        // panelType: 0,
        stepExtend: "",
        stepObjectJson: null,
      },
    };
  },
  watch: {
    // projectInfo(newVal,oldVal){
    //   if (newVal) {
    //     this.$refs.taskChart.loadShape();
    //   }
    // },
    // webGLLoaded: {
    //   async handler(newVal, oldVal) {
    //     console.log(newVal, oldVal);
    //     console.log(this.iframeLoaded);
    //     if (newVal && this.iframeLoaded) {
    //       this.init();
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    webGLLoaded(val, oldVal) {
      //普通的watch监听
      if (val) {
        console.log(val);
        this.init();
      }
    },
    // iframeLoaded: {
    //   async handler(newVal, oldVal) {
    //     console.log(newVal,oldVal);
    //     console.log(this.webGLLoaded);
    //     if (newVal && this.webGLLoaded) {
    //       this.init();
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },

  computed: {
    // loadingTip(){
    //   return
    // },
  },
  async created() {
    const Router = useRouter();
    // console.log(Router);
    // console.log(this.$route);
    this.prevRouter = this.$route.fullPath;
    if (this.$route.query.isEdit == "false") {
      // 正在进入预览空间...
      this.loadingTip = this.$t("webGL.word2");
    } else if (this.$route.query.isEdit == "true") {
      // 正在进入配置空间...
      this.loadingTip = this.$t("webGL.word1");
    }
    window["getInstruction"] = (type, bool) => {
      console.log(type, bool);
    };
  },
  async mounted() {
    const iframe = document.querySelector("#unity-infame");
    // 处理兼容行问题
    if (iframe.attachEvent) {
      iframe.attachEvent("onload", function() {
        // iframe加载完毕以后执行操作
        // console.log("iframe已加载完毕");
        this.iframeLoaded = true;
      });
    } else {
      iframe.onload = function() {
        // iframe加载完毕以后执行操作
        // console.log("iframe已加载完毕");
        this.iframeLoaded = true;
        console.log(this.iframeLoaded);
      };
    }
    window.onclick = (e) => {
      let targetDom = document.getElementById("svgBodyWarp");
      let eventDom = this.$refs.taskChart;
      if (targetDom) {
        let isSelf = targetDom.contains(e.target);
        if (isSelf) {
          // console.log("点到了");
          eventDom.bandingWindowEvent();
        } else {
          // console.log("没点到");
          eventDom.unbandingWindowEvent();
        }
      }
    };
    // this.$refs.frame.onload = () => {
    //   console.log(this.$refs.taskChart);
    //   // this.$refs.taskChart.
    // };
    // if (this.workflowChart.shapes.get(getSelectedGuid())) {
    //           this.workflowChart.shapes.get(getSelectedGuid()).Select();
    //           this.$emit("sendData", this.workflowChart.shapes.get(getSelectedGuid()));
    //         }
    window.addEventListener("message", this.receiveMessage);

    window.addEventListener("resize", () => {
      this.iframWidth = window.innerWidth;
      this.iframHeight = window.innerHeight;
    });
    //  window.addEventListener("keydown", this.keydown);

    // await this.$refs.taskChart.createWorkflowChart();
    // await this.$refs.taskChart.loadShapes(this.$route.query.taskGuid);
  },

  methods: {
    setSelectNode(data) {
      this.selectNode = data;
    },
    saveStepInfoFromChart() {
      this.$refs.editInput.saveTemplateInfo();
    },
    stepConfigInfoNeedSaveStatus(data) {
      this.stepConfigInfoNeedSave = data;
    },
    backRouter() {
      if (this.canBackRouter) {
        this.canBackRouter = false;
        this.$router.back();
      }
    },
    toggleMap(bool) {
      this.ctrlMap(bool);
    },
    nextNode(stepGuid) {
      // this.$emit("nextNode", stepGuid);
      this.switchChart(stepGuid, true);
    },
    prevNode(stepGuid) {
      // this.$emit("prevNode", stepGuid);
      this.switchChart(stepGuid, true);
    },
    getTaskInfo(item) {
      // console.log(item);
      // this.taskInfo = item;
    },
    async init() {
      console.log(this.$refs.taskChart);

      console.log(this.$route.query);
      if (this.$route.query.isEdit === "false") {
        // this.loadingTip = "正在进入预览空间...";
        this.isEdit = false;
        let options = {
          type: "Identity",
          value: "1",
        };
        this.CallUnity(options);
        if (this.$route.query.auditReleaseGuid) {
          // let res = await getAuditById(this.$route.query.auditReleaseGuid);
          // if (res.code == 200) {
          //   this.projectInfo = res.result;
          // }
          this.projectInfo = await getReleaseById(
            this.$route.query.auditReleaseGuid
          );
        } else if (this.$route.query.releaseGuid) {
          this.projectInfo = await getReleaseById(
            this.$route.query.releaseGuid
          );
        } else {
          this.projectInfo = await getProjectById(
            this.$route.query.projectGuid
          );
        }
      } else if (this.$route.query.isEdit === "true") {
        this.isEdit = true;
        this.projectInfo = await getProjectById(this.$route.query.projectGuid);
        let options = {
          type: "Identity",
          value: "0",
        };
        console.log('isEdit CallUnity')
        this.CallUnity(options);
      }
      // this.ctrlMap(true)
      if (this.$route.query.auditReleaseGuid) {
        this.taskInfo = await getTaskById(
          this.$route.query.taskGuid,
          this.$route.query.auditReleaseGuid
        );
      } else {
        this.taskInfo = await getTaskById(
          this.$route.query.taskGuid,
          this.projectInfo.releaseGuid
        );
      }

      this.selectTaskGuid = this.$route.query.taskGuid;
      this.giveToken();
      // console.log(this.projectInfo);
      // console.log(this.$store.state.User);
      // console.log(this.$refs.taskChart);
      // let selectedNodeGuid = getSelectedGuid();
      // if (selectedNodeGuid) {
      //   this.$refs.taskChart.apiSelectById(selectedNodeGuid);
      //   this.getTaskStepData(selectedNodeGuid);
      // } else {
      //   let showSelectShape = this.$refs.taskChart.apiGetStartNodeGuid();

      //   this.$refs.taskChart.apiSelectById(showSelectShape.id);
      // }
    },
    onLogicFlowLoaded(startStepGuid) {
      this.targetStepGuid = startStepGuid;
      this.logicFlowLoaded = true;
      let targetStep = getSelectedGuid();
      if (targetStep) {
        let node = this.$refs.taskChart.apiGetNodeById(targetStep);
        if (node) {
          this.switchChart(targetStep, true);
        } else {
          let start = this.$refs.taskChart.apiGetStartNodeGuid();
          this.switchChart(start.id, true);
        }
      } else {
        this.switchChart(startStepGuid, true);
      }
    },
    // onLogicFlowLoaded(lf) {
    //   console.log("logicflow加载完成");
    //   let selectedNodeGuid = getSelectedGuid();
    //   if (selectedNodeGuid) {
    //     let selectNode = this.$refs.taskChart.apiGetNodeById({
    //       data: selectedNodeGuid,
    //     });
    //     if (selectNode) {
    //       this.$refs.taskChart.apiSelectById(selectedNodeGuid);
    //       this.$refs.taskChart.selectElement({ data: selectNode });
    //       this.getTaskStepData(selectedNodeGuid);
    //     } else {
    //       let showSelectShape = this.$refs.taskChart.apiGetStartNodeGuid();

    //       this.$refs.taskChart.apiSelectById(showSelectShape.id);
    //       this.$refs.taskChart.selectElement({ data: showSelectShape });
    //     }
    //   } else {
    //     let showSelectShape = this.$refs.taskChart.apiGetStartNodeGuid();

    //     this.$refs.taskChart.apiSelectById(showSelectShape.id);
    //     this.$refs.taskChart.selectElement({ data: showSelectShape });
    //   }
    // },
    addAsset(data) {
      this.$refs.uploadAsset.setSwitch(false);
      let options = {
        type: "DisableUploadFileWindow",
        value: false,
      };
      this.CallUnity(options);
    },
    preViewModel(data) {
      console.log(data);
      this.$refs.modelLoader.setShow(true, "localUrl", data);
    },
    getThumbnail(data) {
      console.log(data);
      this.$refs.uploadAsset.thumbnailData(data);
    },
    closeMap(bool) {
      this.ctrlMap(bool);
      let options = {
        type: "DisableTaskList",
        value: "",
      };
      this.CallUnity(options);
    },
    ctrlMap(bool) {
      console.log(bool);
      this.showTask = bool;
    },
    giveToken() {
      console.log("projectInfo", this.projectInfo);
      console.log("taskInfo", this.taskInfo);
      let token = localStorage.getItem("JTWorkflow-Token");
      // console.log(JSON.parse(getProjectInfo()));
      let projectGuid = this.projectInfo.projectGuid;
      let releaseGuid = this.projectInfo.releaseGuid;

      console.log(projectGuid);
      let options = {
        type: "GiveInitialInfo",
        value: JSON.stringify({
          projectGuid,
          releaseGuid,
          auditReleaseGuid: this.$route.query.auditReleaseGuid
            ? this.$route.query.auditReleaseGuid
            : null,
          token,
          taskName: this.taskInfo.taskName,
          baseModelPath: this.projectInfo.baseModelUrl,
        }),
      };
      // if (this.projectInfo.auditReleaseGuid) {
      //   let auditReleaseGuid = this.projectInfo.auditReleaseGuid;
      //   options = {
      //     type: "GiveInitialInfo",
      //     value: JSON.stringify({
      //       projectGuid,
      //       releaseGuid,
      //       auditReleaseGuid,
      //       token,
      //       taskName: this.taskInfo.taskName,
      //       baseModelPath: this.projectInfo.baseModelUrl,
      //     }),
      //   };
      // }
      this.CallUnity(options);
    },
    async sendData(data) {
      this.getTaskStepData(data);
      console.log('get task step', data);
      let node = this.$refs.taskChart.apiGetNodeById(data);
      console.log('apiGetNodeById', node);
      if (node.type === "InputShape" || node.type === "CloneInputShape") {
        console.log("InputShape or CloneInputShape", node);
        //3D输入步骤
        //如果当前被编辑的是输入步骤，就覆盖webgl页面并弹出单独的输入步骤的编辑页面
        if (!this.webGlStepConfigInfoNeedSave) {
          //webGL内部的所有改动都保存完毕，webGlStepConfigInfoNeedSave为false的时候才允许切换
          this.editInputIsShow = true;
          this.relationship = this.$refs.taskChart.apiGetEdgeByNodeId(data);
          // console.log(this.relationship);
          // this.$nextTick(async () => {
          try {
            let res;
            if (node.type === "CloneInputShape") {
              res = await getStepInfo(node.properties.stepInfo.sourceStepGuid);
            } else {
              res = await getStepInfo(data);
            }
            console.log(res);
            // this.selectStepInfo = res.result;
            this.$refs.editInput.initData(res);
          } catch (e) {
            console.log(e);
            this.$refs.editInput.initData(this.selectStepInfo);
          }
          // });
        }
      } else {
        this.editInputIsShow = false;
      }
      setSelectedGuid(data);
    },
    getTaskStepData(id) {
      let options = {
        type: "SwitchStep",
        value: id,
      };
      this.CallUnity(options);
    },
    CallUnity(data) {
      //调用unity的方法
      console.log('aaaaa')
      console.log('this.$refs', this.$refs)
      this.$refs.frame.contentWindow.send(data);
      // console.log("postMessage to iframe");
      // window.parent.postMessage(data, "*");
      // unityInstance.SendMessage('JSScriptObject', 'JSCallUnity', 'MyString');
    },

    //切换成为任务步骤并自动加载任务步骤的内容，切换流程图
    async switchChart(id, isClick) {
      if (this.$refs.taskChart) {
        let node = this.$refs.taskChart.apiGetNodeById(id);
        if (node && node.type !== "pointer") {
          //编辑普通步骤
          this.editInputIsShow = false;
          this.$refs.taskChart.apiSelectById(node.id);
          console.log("选中元素");
          console.log(node);
          this.$refs.taskChart.selectElement({
            data: node,
            isNotEmit: true, //true 不会向父组件发送事件  false向父组件发送事件
            isConfirmSave: true, //loginflow_webgl页面是否不需要确认保存
          });

          if (node.type === "InputShape" || node.type === "CloneInputShape") {
            //3D输入步骤
            //如果当前被编辑的是输入步骤，就覆盖webgl页面并弹出单独的输入步骤的编辑页面
            this.editInputIsShow = true;
            this.relationship = this.$refs.taskChart.apiGetEdgeByNodeId(id);
            console.log(this.relationship);
            try {
              let res;
              if (node.type === "CloneInputShape") {
                if (this.isEdit)
                  this.$messageBox({title: this.$t("webGL.tip2"), message: this.$t('webGL.refStepNote'), type: 'info'})
                res = await getStepInfo(
                  node.properties.stepInfo.sourceStepGuid
                );
              } else {
                res = await getStepInfo(id);
              }

              console.log(res);
              // this.selectStepInfo = res.result;
              console.log(this.$refs);
              console.log(this.editInputIsShow);
              this.$nextTick(() => {
                this.$refs.editInput.initData(res);
              });
            } catch (e) {
              console.log(this.$refs);
              console.log(e);
              this.$nextTick(() => {
                this.$refs.editInput.initData(this.selectStepInfo);
              });
            }
          }
          if (isClick) {
            //true发送时间  false不发送事件
            this.getTaskStepData(id);
          }
          setSelectedGuid(id);
        } else {
          this.editInputIsShow = false;
          try {
            let targetTaskGuid = node.properties.stepInfo.refTaskGuid;
            this.$router.replace({
              path: "/webGL",
              query: {
                ...this.$route.query,
                taskGuid: targetTaskGuid,
              },
            });
            this.selectTaskGuid = targetTaskGuid;
            this.init();
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    async receiveMessage(event) {
      //unity通知web开关流程图
      if (event.data.type === "ControllerFlowChat") {
        console.log("ControllerFlowChat");
        if (event.data.value === "False") {
          console.log("关闭流程图");
          this.ctrlMap(false);
        } else if (event.data.value === "True") {
          console.log("打开流程图");
          this.ctrlMap(true);
        }
      }
      //unity向web索要Token
      if (event.data.type === "GetToken") {
        console.log(process.env.NODE_ENV);

        this.giveToken();
      }
      //unity告诉web已经加载完成
      if (event.data.type === "LoadDone") {
        console.log(process.env.NODE_ENV);
        let options1 = {
          type: "GiveUrl",
          value: process.env.VUE_APP_WORKFLOW_API_BASE,
        };
        this.CallUnity(options1);

        let options = {
          type: "Language",
          value: "ZH_CN",
        };
        // ZH_CN, //中文简体      0
        // EN_US, //英语（美国）  1
        // KO_KR, //韩语          2
        // JA_JP; //日语          3
        let lang = LangManager.getLanguage().value;
        console.log(lang);
        if (lang === "zh") {
          options.value = "0";
        } else if (lang === "kr") {
          options.value = "2";
        } else if (lang === "en") {
          options.value = "1";
        } else {
          options.value = "1";
        }
        this.CallUnity(options);

        this.webGLLoaded = true;
      }
      if (event.data.type === "UploadFile") {
        if (!this.isEdit) {
          console.log(this.projectInfo);
          console.log(this.$store.state.User);
          return;
        }
        this.$refs.uploadAsset.setSwitch(true);
        this.$refs.taskChart.unbandingWindowEvent();
      }
      if (event.data.type === "Return") {
        // this.$router.push(this.prevRouter);
        if (this.canBackRouter) {
          this.canBackRouter = false;
          this.$router.back();
        }
      }
      if (event.data.type === "SwitchStep") {
        this.switchChart(event.data.value, false);
      }
      if (event.data.type === "loadingProgress") {
        this.progress = event.data.value;
      }
      if (event.data.type === "NeedSave") {
        if (event.data.value === "true") {
          this.webGlStepConfigInfoNeedSave = true;
        } else if (event.data.value === "false") {
          this.webGlStepConfigInfoNeedSave = false;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.webGLWarp {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .taskLinkWarp {
    position: absolute;
    width: 20vw;
    height: 65vh;
    min-width: 450px;
    background: #fff;
    top: 8%;
    left: 1%;
    overflow: hidden;
    z-index: 9;
    opacity: 0;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
  }
  .taskActive {
    z-index: 20;
    opacity: 1;
  }
  .betweenWarp {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 15;
    iframe {
      width: 100%;
      // height: calc(100vh - 8rem);
      height: 100.2%;
    }
    .editCop {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100vw;
      height: 100vh;
      z-index: 9;
      background: url("~@/assets/task-detail/shodowBg.png") no-repeat center
        center;
      background-size: 100% 100%;
    }
  }
}

// iframe::-webkit-scrollbar {
//   width: 5px;
//   height: 5px;
// }
// iframe::-webkit-scrollbar-thumb {
//   background-color: #999;
//   -webkit-border-radius: 5px;
//   border-radius: 5px;
// }
// iframe::-webkit-scrollbar-thumb:vertical:hover {
//   background-color: #666;
// }
// iframe::-webkit-scrollbar-thumb:vertical:active {
//   background-color: #333;
// }
// iframe::-webkit-scrollbar-button {
//   display: none;
// }
// iframe::-webkit-scrollbar-track {
//   background-color: #f1f1f1;
// }
.importWarp {
  width: 100%;
  height: 100%;
  background: #fff;
}
.zIndex16 {
  z-index: 16;
}
</style>
