<template>
  <div class="media">
    <div class="mediaBody">
      <div class="img1">
        <div class="bottomDiv">
          <!-- {{ stepObjectJson?.content }} -->
          <el-input
            disabled
            autosize
            resize='none'
            type="textarea"
            placeholder="请输入内容"
            v-model="stepObjectJson.content"
          ></el-input>
        </div>
        <div class="topDiv">
          <!-- <img v-if="imgUrl" :src="imgUrl" alt="" /> -->
          <div class="imgWrap" v-if="fileType === 1">
            <img crossorigin="anonymous" :src="imgUrl" v-if="imgUrl" alt="" />
          </div>

          <div class="videoWrap" v-if="fileType === 2">
            <video
              crossorigin="anonymous"
              :id="videoRef"
              controls
              :src="imgUrl"
            ></video>
          </div>

          <div class="videoWrap" v-if="fileType === 3">
            <div class="imgWrap">
              <div class="img">
                <img
                  class="pdfdefault"
                  @click="preViewPdf"
                  src="@/assets/project/pdfDefault.png"
                  alt=""
                />
                <PdfView ref="pdfView" />
              </div>
              <p>{{ fileName }}</p>
            </div>
          </div>
          <!-- 暂未上传 -->
          <span v-if="!imgUrl">{{ $t("prevTemplate2D.media.noUpload") }}</span>
        </div>
      </div>
      <div class="img2">
        <!-- <img v-if="imgUrl1" :src="imgUrl1" alt="" /> -->
        <div class="imgWrap" v-if="fileType1 === 1">
          <img crossorigin="anonymous" :src="imgUrl1" v-if="imgUrl" alt="" />
        </div>

        <div class="videoWrap" v-if="fileType1 === 2">
          <video
            crossorigin="anonymous"
            :id="videoRef"
            controls
            :src="imgUrl1"
          ></video>
        </div>

        <div class="videoWrap" v-if="fileType1 === 3">
          <div class="imgWrap">
            <div class="img">
              <img
                class="pdfdefault"
                @click="preViewPdf1"
                src="@/assets/project/pdfDefault.png"
                alt=""
              />
              <PdfView ref="pdfView1" />
            </div>
            <p>{{ fileName1 }}</p>
          </div>
        </div>
        <!-- 暂未上传 -->
        <span v-if="!imgUrl1">{{ $t("prevTemplate2D.media.noUpload") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getAssetById } from "@/api/workflow";
import { getFileTypeByFileName } from "@/utils/getFileType.js";
import PdfView from "@/views/components/pdfView/pdfView.vue";
export default {
  props: ["data"],
  data() {
    return {
      imgUrl: null,
      imgUrl1: null,
    };
  },
  computed: {
    stepObjectJson() {
      return this.data?.texts[0];
    },
  },
  async created() {
    // console.log(this.data);
    // if (this.data) {
    //   let data = JSON.parse(this.data);
    //   let targetMedia = data.medias[0];
    //   let res = await getAssetById(targetMedia.assetGuid);
    //   this.imgUrl = res.result.fileUrl;
    //   let targetMedia1 = data.medias[1];
    //   let res1 = await getAssetById(targetMedia1.assetGuid);
    //   this.imgUrl1 = res1.result.fileUrl;
    // }
  },
  mounted() {},
  watch: {
    data: {
      async handler(newVal, oldVal) {
        if (this.data) {
          //   let data = JSON.parse(this.data);
          let data = this.data;
          let targetMedia = data.medias[0];
          // let res = await getAssetById(targetMedia.assetGuid);
          this.imgUrl = targetMedia.fileUrl;
          this.fileType = getFileTypeByFileName(targetMedia.assetName);
          if (this.fileType === 3) {
            this.fileName = targetMedia.assetName;
          }

          let targetMedia1 = data.medias[1];
          // let res1 = await getAssetById(targetMedia1.assetGuid);
          this.imgUrl1 = targetMedia1.fileUrl;
          this.fileType1 = getFileTypeByFileName(targetMedia1.assetName);
          if (this.fileType1 === 3) {
            this.fileName1 = targetMedia1.assetName;
          }
        } else {
          this.imgUrl = null;
          this.fileType = 65535;
          this.imgUrl1 = null;
          this.fileType1 = 65535;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    preViewPdf() {
      this.$refs.pdfView.setSwitch(true, this.imgUrl);
    },
    preViewPdf1() {
      this.$refs.pdfView1.setSwitch(true, this.imgUrl1);
    },
  },
  components: { PdfView },
};
</script>

<style scoped lang="scss">
.media {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  .mediaBody {
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    > div {
      height: 100%;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .img1 {
      width: 62%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      > div {
        width: 100%;
        border: 1px #ccc solid;
        border-radius: 10px;
        box-sizing: border-box;
        overflow: hidden;
      }
      .topDiv {
        width: 100%;
        height: 63%;
        display: flex;
        justify-content: center;
        align-items: center;
        .imgWrap {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          overflow: hidden;
          background: #e9eef3;
          > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .videoWrap {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          overflow: hidden;
          > video {
            width: 100%;
            height: 100%;
            object-fit: contain;
            background: #000;
          }
          .imgWrap {
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            background: #e9eef3;
            > .img {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              > img {
                width: 20rem;
              }
              .pdfdefault {
                cursor: pointer;
              }
            }
            > p {
              text-align: center;
              color: #ccc;
              font-size: 1.5rem;
              position: absolute;
              bottom: 1rem;
              left: 0;
              right: 0;
            }
          }
        }
        > span {
          font-size: 34px;
          color: #ccc;
          font-weight: bold;
        }
        > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .bottomDiv {
        height: 35%;
        width: 100%;
        box-sizing: border-box;
        font-size: 28px;
        text-align: left;
        word-wrap: break-word;
        line-height: 40px;
        overflow: auto;
        :deep(.el-textarea) {
          font-size: 28px;
          line-height: 40px;
          // height: 100%;
          // overflow: auto;
          .el-textarea__inner {
            color: #333;
            background: #fff;
            padding: 1.5rem 2.5rem;
            border: none;
            cursor: default;
            box-sizing: border-box;
          }
        }
      }

      .bottomDiv::-webkit-scrollbar {
        width: 14px;
        height: 14px;
      }
      .bottomDiv::-webkit-scrollbar-corner {
        background-color: transparent;
      }
      .bottomDiv::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.1);
        background-clip: padding-box;
        border: solid transparent;
        border-radius: 10px;
      }
      .bottomDiv::-webkit-scrollbar-track {
        background-clip: padding-box;
        border: solid transparent;
        border-width: 1px;
      }
    }
    .img2 {
      width: 35%;
      overflow: hidden;
      border: 1px solid #ccc;
      border-radius: 10px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      .imgWrap {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
        background: #e9eef3;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .videoWrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
        video {
          width: 100%;
          height: 100%;
          object-fit: contain;
          background: #000;
        }
        .imgWrap {
          border-radius: 10px;
          overflow: hidden;
          position: relative;
          background: #e9eef3;
          .img {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 20rem;
            }
            .pdfdefault {
              cursor: pointer;
            }
          }
          p {
            text-align: center;
            color: #ccc;
            font-size: 1.5rem;
            position: absolute;
            bottom: 1rem;
            left: 0;
            right: 0;
          }
        }
      }
      span {
        font-size: 34px;
        color: #ccc;
        font-weight: bold;
      }
    }
  }
}
</style>
