<template>
  <div class="registerWarp">
    <!-- <div class="login">密码登录</div> -->
    <div class="registe">
      <UserAgreement ref="userAgreement" />
      <!-- <div class="myForm">
        <el-input
          size="small"
          style="width: 20rem"
          placeholder=""
          v-model="form.displayName"
        >
          <template #prepend>昵&nbsp;&nbsp;&nbsp;称</template>
        </el-input>
      </div> -->
      <div class="myForm">
        <el-input
          size="small"
          style="width: 20rem"
          placeholder=""
          v-model="form.tel"
        >
          <!-- 手机号 -->
          <template #prepend>{{ $t("regirte.tel") }}</template>
        </el-input>
      </div>

      <div class="myForm passwordAndTip">
        <el-input
          size="small"
          style="width: 20rem"
          placeholder=""
          :show-password="false"
          :type="[flag ? 'text' : 'password']"
          v-model="form.newPass"
          @keyup.enter="login"
        >
          <!-- 密&nbsp;&nbsp;&nbsp;码 -->
          <template #prepend>{{ $t("regirte.password") }}</template>
          <template #suffix>
            <img
              v-if="flag"
              @click="flag = !flag"
              src="@/assets/login/eyes.png"
              alt=""
            />
            <img
              v-if="!flag"
              @click="flag = !flag"
              src="@/assets/login/noEye.png"
              alt=""
            />
          </template>
        </el-input>
        <div class="passwordTip">
          <!-- 至少8位密码,字母大小写、数字、及特殊标点符号组合 -->
          {{ $t("regirte.passwordRule") }}
        </div>
      </div>
      <div class="myForm">
        <el-input
          size="small"
          style="width: 20rem"
          placeholder=""
          :show-password="false"
          :type="[reFlag ? 'text' : 'password']"
          v-model="form.reNewPass"
          @blur="rePassBlur"
        >
          <!-- 重&nbsp;&nbsp;&nbsp;复 -->
          <template #prepend>{{ $t("regirte.rePass") }}</template>
          <template #suffix>
            <img
              v-if="reFlag"
              @click="reFlag = !reFlag"
              src="@/assets/login/eyes.png"
              alt=""
            />
            <img
              v-if="!reFlag"
              @click="reFlag = !reFlag"
              src="@/assets/login/noEye.png"
              alt=""
            />
          </template>
        </el-input>
      </div>
      <div class="myForm flexContainer">
        <el-input
          size="small"
          style="width: 13rem"
          placeholder=""
          v-model="form.authCode"
        >
          <!-- 验证码 -->
          <template #prepend>{{ $t("regirte.authCode") }}</template>
        </el-input>
        <button
          class="authCode"
          :disabled="isCountDown"
          @click.prevent="getAuthCode"
        >
          <!-- 获取验证码 -->
          {{ isCountDown ? countDownNum + "s" : $t("regirte.getAuthCode") }}
        </button>
      </div>
      <div class="noUsername">
        <!-- 已有帐号？   去登录 -->
        {{ $t("regirte.hasUsername")
        }}<span @click="backLogin">{{ $t("regirte.goLogin") }}</span>
      </div>
      <div class="tip">{{ submitTip }}</div>
      <div class="lines"></div>
      <div class="registerWordWarp">
        <div class="agreement">
          <div>
            <el-checkbox
              class="labelCtrl"
              v-model="userAgreement"
              text-color="#4582ff"
            >
            </el-checkbox>
            <span class="agree" @click="checkOne">
              <!-- 同意 -->
              {{ $t("regirte.agree")
              }}<sapn class="agreementItem">
                <!-- 《境腾用户协议及隐私协议》 -->
                <span>{{ $t("regirte.agreeItem1") }}</span>
                <span class="href" @click.stop="agreeJingTeng1(1)">{{
                  $t("regirte.agreeItem1Href1")
                }}</span>
                <span>{{ $t("regirte.and") }}</span>
                <span class="href" @click.stop="agreeJingTeng1(2)">{{
                  $t("regirte.agreeItem1Href2")
                }}</span>
                <span>{{ $t("regirte.agreeEnd") }}</span>
              </sapn>
              <!-- 和<span class="agreementItem" @click.stop="agreeJingTeng2"
                >《隐私协议》</span
              > -->
            </span>
          </div>
          <div>
            <el-checkbox
              class="labelCtrl"
              v-model="receiveMail"
              text-color="#4582ff"
            >
            </el-checkbox>
            <span class="agree" @click="checkTwo">
              <!-- 同意接受产品更新和社区通知信息邮件 -->
              {{ $t("regirte.agreeItem2") }}
            </span>
          </div>
        </div>
        <div class="loginBtnWarp">
          <button class="loginBtn" @click="registe">
            <!-- 注册 -->
            <span v-if="!loading">{{ $t("regirte.registe") }}</span
            ><i v-if="loading" class="el-icon-loading"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- <ReceiveMail ref="receiveMail" /> -->
  </div>
</template>

<script>
import { sendTrialAuthCode, createTrialUser } from "@/api/user.js";
import UserAgreement from "./agreements/userAgreement.vue";
// import ReceiveMail from "./agreements/ReceiveMail.vue";
import { ElMessage } from "element-plus";
export default {
  components: { UserAgreement },
  data() {
    return {
      reFlag: false,
      flag: false,
      userAgreement: false,
      receiveMail: false,
      form: {
        displayName: "",
        tel: "",
        newPass: "",
        reNewPass: "",
        authCode: "",
        appType: 0,
      },
      pageSwitch: false,
      submitTip: "",
      loading: false,
      isCountDown: false,
      countDownNum: 60,
      countDownTimer: null,
    };
  },
  created() {
    let sendTime = localStorage.getItem("JTWorkflow_registeTime");
    if (sendTime) {
      let diffMs = this.testTimeDiff(Number(sendTime));
      console.log(diffMs);
      if (diffMs >= 60000) {
        localStorage.removeItem("JTWorkflow_registeTime");
      } else {
        let s = parseInt(diffMs / 1000);
        let count = 60 - s;
        this.isCountDown = true;
        this.countDownNum = count;
        this.countDownTimer = setInterval(() => {
          this.countDownNum--;
          if (this.countDownNum < 1) {
            clearInterval(this.countDownTimer);
            this.countDownTimer = null;
            this.isCountDown = false;
            localStorage.removeItem("JTWorkflow_registeTime");
            return;
          }
        }, 1000);
      }
    }
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    checkTwo() {
      this.receiveMail = !this.receiveMail;
    },
    checkOne() {
      this.userAgreement = !this.userAgreement;
    },
    agreeJingTeng1(type) {
      // console.log("境腾用户协议");
      this.$refs.userAgreement.init(true, type);
    },
    agreeJingTeng2() {
      // console.log("隐私协议");
    },
    testTimeDiff(time, diff) {
      let saveTime = +new Date(time);
      let nowTime = +new Date();
      let diffMs = nowTime - saveTime;
      return diffMs;
    },
    async registe() {
      let tel_result = this.testTel(this.form.tel);
      if (!tel_result.flag) {
        this.submitTip = tel_result.message;
        return;
      }
      if (!this.form.authCode) {
        // 验证码不能为空
        this.submitTip = this.$t("regirte.tip1");
        return;
      }
      let pass_result = this.testPass(this.form.newPass);
      if (!pass_result.flag) {
        this.submitTip = pass_result.message;
        return;
      }
      let rePass_result = this.testRePass(
        this.form.newPass,
        this.form.reNewPass
      );
      if (!rePass_result.flag) {
        this.submitTip = rePass_result.message;
        return;
      }
      if (!this.userAgreement || !this.receiveMail) {
        // 请勾选下方协议项
        this.submitTip = this.$t("regirte.tip2");
        return;
      }
      this.submitTip = "";
      //执行注册
      try {
        let result = await createTrialUser(
          this.form.tel,
          "",
          this.form.tel,
          this.form.authCode,
          this.form.newPass,
          0
        );

        console.log("执行注册");
        console.log(result);
        ElMessage.success({
          // 注册成功!去登录吧!
          message: this.$t("regirte.tip3"),
          type: "success",
        });
        this.backLogin();
      } catch (e) {
        console.log(e);
        console.log(e.data.code);
        if (e.data.code === 20108) {
          // 验证码不正确
          this.submitTip = this.$t("regirte.tip4");
        }
        if (e.data.code === 20110) {
          // 该手机号已被注册
          this.submitTip = this.$t("regirte.tip5");
        }
      }
    },
    rePassBlur() {
      if (this.form.reNewPass !== this.form.newPass) {
        // 两次密码不一致
        this.submitTip = this.$t("regirte.tip6");
      } else {
        this.submitTip = "";
      }
    },
    async getAuthCode() {
      let tel_result = this.testTel(this.form.tel);
      if (tel_result.flag) {
        this.submitTip = "";
        let result = await sendTrialAuthCode(this.form.tel);
        // console.log(result);
        localStorage.setItem("JTWorkflow_registeTime", +new Date());
        this.isCountDown = true;
        this.countDownNum = 60;
        this.countDownTimer = setInterval(() => {
          this.countDownNum--;
          if (this.countDownNum < 1) {
            clearInterval(this.countDownTimer);
            this.countDownTimer = null;
            this.isCountDown = false;
            localStorage.removeItem("JTWorkflow_registeTime");
            return;
          }
        }, 1000);
      } else if (!tel_result.flag) {
        this.submitTip = tel_result.message;
      }
    },
    testRePass(pass, rePass) {
      if (pass === rePass) {
        return {
          flag: true,
          message: "",
        };
      } else {
        return {
          flag: false,
          // 两次密码不一致
          message: this.$t("regirte.tip6"),
        };
      }
    },
    testPass(str) {
      if (!str) {
        return {
          flag: false,
          // 密码不能为空
          message: this.$t("regirte.tip7"),
        };
      }
      let reg_pass = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,}$/;
      let isPass = reg_pass.test(str);
      if (!isPass) {
        return {
          flag: false,
          // 密码格式不对
          message: this.$t("regirte.tip8"),
        };
      } else {
        return {
          flag: true,
          message: "",
        };
      }
    },
    testTel(tel) {
      if (!tel) {
        return {
          flag: false,
          // 手机号不能为空
          message: this.$t("regirte.tip9"),
        };
      }
      // let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      let reg_tel = /^(1[3-9]\d{9})$|^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)$/;
      let isTel = reg_tel.test(tel);
      if (isTel) {
        return {
          flag: true,
          message: "",
        };
      } else {
        return {
          flag: false,
          // 手机号格式错误
          message: this.$t("regirte.tip10"),
        };
      }
    },
    backLogin() {
      this.$emit("goLogin", true);
    },
    init(bool) {
      this.pageSwitch = bool;
    },
    toggleFun(bool) {
      this.pageSwitch = bool;
    },
  },
};
</script>
<style scoped lang="scss">
.registerWarp {
  display: flex;
  justify-content: center;
  align-items: center;
  > .registe {
    width: 20rem;
  }
}
.login {
  text-align: center;
  line-height: 20px;
  margin-bottom: 1rem;
}
.tip {
  color: #f55;
  text-align: center;
  line-height: 18px;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
}
.lines {
  width: 120%;
  margin-left: -10%;
  height: 1px;
  background: #efefef;
}
.myForm {
  height: 2.8rem;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  :deep(.el-input) {
    > .el-input-group__prepend {
      width: 70px;
    }
  }
  .passwordTip {
    color: #7590b2;
    font-size: 0.7rem;
    margin: 0.5rem 0;
    text-align: left;
  }
}
.passwordAndTip {
  height: 5rem;
}
.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.authCode {
  width: 100px;
  height: 32px;
  background: #4582ff;
  border-radius: 4px 4px 4px 4px;
  outline: none;
  border: none;
  background: #4582ff;
  color: #fff;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  transition: background 0.1s;
}
.authCode:hover {
  background: #8fb5ff;
}
.authCode[disabled] {
  background: #f0f0f0;
  color: #7590b2;
  cursor: not-allowed;
}
.agreement {
  // margin-top: 1rem;
  text-align: left;
  box-sizing: border-box;
  // padding-left: 0.5rem;
  transform: scale(0.75);
  // margin-left: -10%;
  .labelCtrl {
    color: #9e9e9e;
    font-size: 0.8rem;
  }
  .agree {
    padding-left: 15px;
    color: #9e9e9e;
    cursor: pointer;
    transition: color 0.3s;
    > .agreementItem {
      word-wrap: break-word !important;
      > .href {
        color: #4582ff;
        word-wrap: break-word;
        word-break: break-all;
      }
      > .href:hover {
        text-decoration: 1px underline #4582ff;
      }
    }
  }
  .agree:hover {
    color: #7e7e7e;
  }
  > div {
    margin-top: 0.2rem;
    width: 123%;
    position: relative;
    left: -1.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 22px;
  }
}
// .loginBtnWarp{

// }
.registerWordWarp {
  width: 110%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 1rem;
  position: relative;
  left: -7%;
}
.loginBtn {
  width: 120px;
  height: 32px;
  background: #4582ff;
  border-radius: 4px 4px 4px 4px;
  outline: none;
  border: none;
  background: #4582ff;
  color: #fff;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  transition: background 0.1s;
}
.loginBtn:hover {
  background: #8fb5ff;
}
.noUsername {
  text-align: left;
  padding-left: 0.5rem;
  color: #ccc;
  > span {
    color: #81aaff;
    cursor: pointer;
  }
  > span:hover {
    text-decoration: underline 1px;
  }
}
:deep(.el-input__suffix) {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  > .el-input__suffix-inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
