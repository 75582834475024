import axios from 'axios';
import { getToken } from '@/utils/setLocalstorage';
// import app from "../main.js";
// import zh from "@/langs/zh.js";
// import en from "@/langs/en.js";
// import kr from "@/langs/kr.js";
// import { getLanguage } from "@/utils/getLanguage.js";

import store from '../store';
import router from '../router';
import LangManager from './langManager';
import { ElMessageBox } from 'element-plus';

let cancel; // 用于保存取消函数

// let language = getLanguage();
// let useLanguage = en;
// if (language === "zh") {
//     useLanguage = zh;
// } else if (language === "kr") {
//     useLanguage = kr;
// } else if (language === "en") {
//     useLanguage = en;
// }



const TIMEOUT = 45000; // 45秒

function longPolling() {
    // 先取消之前的长轮询请求
    cancelLongPolling();


    // 发起新的长轮询请求
    const token = getToken();
    if (token) {
        axios.post(process.env.VUE_APP_WORKFLOW_API_STAT + '/api/register', { equip: 'web' }, {
            timeout: TIMEOUT,
            headers: {
                'Authorization': `Bearer ${token}` // 设置 Authorization 头部信息
            },
            cancelToken: new axios.CancelToken(function executor(c) {
                // executor 函数接收一个 cancel 函数作为参数
                cancel = c;
            }),
            timeoutErrorMessage: 'timeout' // 设置超时后不抛出错误
        })
            .then(response => {
                // 处理收到的响应数据
                console.log(response.data);
                // const Vue = app.config.globalProperties;
                store.dispatch("logout");
                router.push("/");
                ElMessageBox.alert(LangManager.i18n.global.t('longPolling.logoutContent'), 
                    LangManager.i18n.global.t('longPolling.logoutTip'), {
                    confirmButtonText: LangManager.i18n.global.t('longPolling.close'),
                    callback: (action) => {
                    },
                });
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    // 如果是手动取消请求，则不处理
                    return;
                }
                

                if (error.message === 'timeout') {
                    // 如果是超时错误，则重新发起长轮询请求
                    console.log('longpoll timeout, restart');
                    
                    longPolling();
                } else {
                    // 如果是其他错误，则等待一段时间（一个timeout的时间）后再重新发起请求
                    console.error('longpoll error:', error);
                    console.log('restart after a while');
                    setTimeout(longPolling, TIMEOUT);
                }
            });
    } else {
        cancelLongPolling();
    }
}

// 取消长轮询请求的方法
function cancelLongPolling() {
    cancel && cancel(); // 调用取消函数 确保在调用 cancel 函数之前先检查它是否存在，以避免在 cancel 未定义时发生错误
}

export { longPolling, cancelLongPolling };
