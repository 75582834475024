<template>
  <div class="historyWarp">
    <div class="historyText">
      <div class="historyTextWarp">
        <!-- 历史版本 -->
        <span>{{ $t("historyEdition.title") }}</span>
        <div class="createTask">
          <!-- 添加历史版本 -->
          <el-tooltip
            class="item"
            effect="light"
            :content="$t('historyEdition.addHistory')"
            placement="bottom"
            :show-after="500"
          >
            <el-button size="mini" @click="saveHistory(1)">
              <!-- <img src="@/assets/task-detail/add.png" alt="" /> -->
              +
            </el-button>
          </el-tooltip>
        </div>
      </div>

      <!-- <i class="el-icon-close" @click="closeHoistory"></i> -->
    </div>
    <div class="historyContainer">
      <div class="historyTop">
        <!-- 手动保存  编辑时按Ctrl+Alt+S保存历史版本 -->
        <p>
          {{ $t("historyEdition.save") }}
          <span>{{ $t("historyEdition.word1") }}</span>
        </p>
        <div class="historyItemWarp">
          <div
            class="historyItem"
            v-for="(item, index) in manualList"
            :key="index"
          >
            <div class="date">
              {{ item.historyName }}
              <!-- 请输入内容 -->
              <el-input
                :id="item.historyGuid"
                v-if="item.editing"
                class="editInput"
                :ref="item.historyGuid"
                v-model="item.historyName"
                size="mini"
                :placeholder="$t('historyEdition.word2')"
              ></el-input>
            </div>
            <!-- <div class="time">14:48:30</div> -->
            <div class="editIcon">
              <!-- 重命名 -->
              <el-tooltip
                class="item"
                effect="light"
                :content="$t('historyEdition.word3')"
                placement="bottom"
                :show-after="500"
              >
                <button @click="editionCtrl('edit', item)">
                  <i class="el-icon-edit-outline"></i>
                </button>
              </el-tooltip>
              <!-- 修复到此历史版本 -->
              <el-tooltip
                class="item"
                effect="light"
                :content="$t('historyEdition.word4')"
                placement="bottom"
                :show-after="500"
              >
                <button @click="editionCtrl('refresh', item)">
                  <i class="el-icon-refresh-right"></i>
                </button>
              </el-tooltip>
              <!-- 删除此历史版本 -->
              <el-tooltip
                class="item"
                effect="light"
                :content="$t('historyEdition.word5')"
                placement="bottom"
                :show-after="500"
              >
                <button @click="editionCtrl('delete', item, index, 'manual')">
                  <i class="el-icon-delete"></i>
                </button>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="historyBottom">
        <!-- 自动保存 -->
        <p>{{ $t("historyEdition.saveAuto") }}</p>
        <div class="historyItemWarp">
          <div
            class="historyItem"
            v-for="(item, index) in autoList"
            :key="index"
          >
            <div class="date">
              {{ item.historyName }}
              <!-- 请输入内容 -->
              <el-input
                :id="item.historyGuid"
                v-if="item.editing"
                :ref="item.historyGuid"
                class="editInput"
                v-model="item.historyName"
                size="mini"
                :placeholder="$t('historyEdition.enterContent')"
              ></el-input>
            </div>
            <!-- <div class="time">14:48:30</div> -->
            <div class="editIcon">
              <button @click="editionCtrl('edit', item)">
                <i class="el-icon-edit-outline"></i>
              </button>
              <button @click="editionCtrl('refresh', item)">
                <i class="el-icon-refresh-right"></i>
              </button>
              <button @click="editionCtrl('delete', item, index, 'auto')">
                <i class="el-icon-delete"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import moment from "moment";
import {
  getHistoriesByProject,
  generateHistory,
  saveHistory,
  restoreHistory,
  getOprSessionByRelease,
} from "@/api/workflow.js";
import { getProjectInfo } from "@/utils/setLocalstorage";
export default {
  inject: ["reload"],
  props: ["projectInfo"],
  components: {},
  data() {
    return {
      autoList: [],
      manualList: [],
      historyList: [],
    };
  },
  async created() {
    console.log("history commponents init");
    // this.historyList = await getHistoriesByProject(this.projectInfo.projectGuid);
    await this.refreashList();
  },
  mounted() {},
  computed: {},
  watch: {
    // "$store.state.TaskDetail.showHistory": {
    //   async handler(newVal, oldVal) {
    //     console.log(newVal);
    //     if (newVal) {
    //       console.log(this.$route.params.id);
    //       this.historyList = await getHistoriesByProject(this.projectInfo.projectGuid);
    //       console.log(this.historyList);
    //       this.autoList = this.dealDataList(this.historyList, 0);
    //       this.manualList = this.dealDataList(this.historyList, 1);
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },
  methods: {
    async refreashList() {
      console.log("刷新列表");
      this.historyList = await getHistoriesByProject(
        this.projectInfo.projectGuid
      );
      this.autoList = this.dealDataList(this.historyList, 0);
      this.manualList = this.dealDataList(this.historyList, 1);
      // let res = await getHistoriesByProject(this.projectInfo.projectGuid, 0);
      // this.autoList = res.autoHistory;
      // this.manualList = res.manuHistory;
    },
    dealDataList(list, method) {
      let tempList = [];
      if (method === 1) {
        tempList = list.filter((value, index) => {
          return value.generateType === 1;
        });
      }
      if (method === 0) {
        tempList = list.filter((value, index) => {
          // console.log(value);
          return value.generateType === 0;
        });
      }
      console.log(tempList);
      tempList.sort((a, b) => {
        return b.modifiedAt - a.modifiedAt;
      });
      return tempList;
    },
    async saveHistory(method) {
      //0 auto
      // let date = new Date();
      console.log("historyEditiong");
      this.$emit("saveHistory", method);
      // console.log(JSON.parse(getProjectInfo()).projectGuid);
      // let options = {
      //   projectGuid: this.projectInfo.projectGuid,
      //   historyName:
      //     moment(date).format("L") + " " + moment(date).format("LTS"),
      //   generateType: method,
      // };
      // let result = await generateHistory(options);
      // console.log("historyEditiong");
      // console.log(result);
      // if (method === 0) {
      //   this.autoList.push(result);
      // }
      // if (method === 1) {
      //   this.manualList.push(result);
      // }
      // this.historyList.unshift(result);
      // this.autoList = this.dealDataList(this.historyList, 0);
      // this.manualList = this.dealDataList(this.historyList, 1);
      this.refreashList();
    },
    deleteHistoryFromList(dataList, item) {
      dataList.forEach((value, index) => {
        if (value.historyGuid === item.historyGuid) {
          dataList.splice(index, 1);
        }
      });
    },
    editionCtrl(ctrl, item, index, listType) {
      if (ctrl === "edit") {
        item.editing = true;
        this.$nextTick(() => {
          this.$refs[item.historyGuid].focus();
          console.log(this.$refs);
          let temp = item.historyName;
          let dom = document.getElementById(item.historyGuid);
          dom.onblur = async () => {
            console.log("12121");
            item.editing = false;
            if (item.historyName == "") {
              item.historyName = temp;
            }
            let options = {
              historyGuid: item.historyGuid,
              historyName: item.historyName,
              isDeleted: false,
            };

            await saveHistory(options);
          };
        });
      } else if (ctrl === "refresh") {
        // ElMessage.warning({
        //   message: ctrl,
        //   type: "warning",
        // });
        // 您当前的工作内容会回退到此版本，是否继续 提示
        this.$confirm(
          this.$t("historyEdition.tip1"),
          this.$t("historyEdition.tip2"),
          {
            // 确定
            confirmButtonText: this.$t("historyEdition.ok"),
            // 取消
            cancelButtonText: this.$t("historyEdition.cancel"),
            type: "warning",
          }
        )
          .then(async () => {
            await restoreHistory(item.historyGuid);
            this.$message({
              type: "success",
              // 版本已回退
              message: this.$t("historyEdition.tip3"),
            });
            console.log("reload");
            this.reload();
          })
          .catch((e) => {
            console.log(e);
            // 版本回退失败
            ElMessage.error(this.$t("historyEdition.tip4"));
          });
      } else if (ctrl === "delete") {
        // ElMessage.error(ctrl);
        // 确认删除此版本 提示
        this.$confirm(
          this.$t("historyEdition.tip5"),
          this.$t("historyEdition.tip6"),
          {
            // 确定
            confirmButtonText: this.$t("historyEdition.ok"),
            // 取消
            cancelButtonText: this.$t("historyEdition.cancel"),
            customClass: "deleteBox",
            type: "warning",
          }
        )
          .then(async () => {
            console.log(3232);
            let options = {
              historyGuid: item.historyGuid,
              isDeleted: true,
            };
            await saveHistory(options);
            if (listType === "auto") {
              this.deleteHistoryFromList(this.historyList, item);
              this.autoList.splice(index, 1);
            } else if (listType === "manual") {
              this.deleteHistoryFromList(this.historyList, item);
              this.manualList.splice(index, 1);
            }
            this.$message({
              type: "success",
              // 删除成功
              message: this.$t("historyEdition.tip7"),
            });
          })
          .catch(() => {});
      }
    },
    closeHoistory() {
      console.log(111111);
      // this.$emit("closeHistory", false);
      this.$store.commit("SET_SHOWHISTORY", false);
    },
  },
};
</script>
<style scoped lang="scss">
.historyWarp {
  box-sizing: border-box;

  height: calc(100vh - 7rem);
  .historyText {
    padding: 1rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // text-align: left;
    font-size: 1rem;
    color: #4582ff;
    border-bottom: 1px solid #ccc;
    height: 3.5rem;
    box-sizing: border-box;
    > i {
      color: #333;
      cursor: pointer;
    }
    .historyTextWarp {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .createTask {
        width: 20%;
        > button {
          width: 1.6rem;
          height: 1.6rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 1.2rem;
          background: #e7c796;
          color: #fff;
          border: none;
          font-size: 1.6rem;
          > span {
            width: 100%;
            height: 100%;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            > img {
              display: block;
              width: 1.2rem;
              height: 1.2rem;
            }
          }
        }
      }
    }
  }
}
.historyContainer {
  width: 100%;
  box-sizing: border-box;
  height: 52rem;
  height: calc(100vh - 10.5rem);
  > div {
    width: 100%;
    height: 48%;
    padding: 0 3rem;
    box-sizing: border-box;
    overflow: auto;
    > p {
      text-align: left;
      font-size: 1rem;
      font-weight: 400;
      color: #224b77;
      > span {
        font-size: 12px;
        color: #224b77;
      }
    }
    .historyItemWarp::-webkit-scrollbar {
      width: 14px;
      height: 14px;
    }
    .historyItemWarp::-webkit-scrollbar-corner {
      background-color: transparent;
    }
    .historyItemWarp::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.1);
      background-clip: padding-box;
      border: solid transparent;
      border-radius: 10px;
    }
    .historyItemWarp::-webkit-scrollbar-track {
      background-clip: padding-box;
      border: solid transparent;
      border-width: 1px;
    }
    .historyItemWarp {
      height: 88%;
      overflow: auto;
      .historyItem {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1.8rem;
        background: #f6f7fa;
        margin: 0.8rem 0;
        position: relative;

        .date {
          width: 55%;
          font-size: 0.8rem;
          color: #1f1f1f;
          text-align: left;
          padding-left: 1rem;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          > .editInput {
            position: absolute;
            top: 0px;
            left: 0px;
            width: calc(55% + 1rem);
            // line-height: 1.8rem;
          }
        }
        .time {
          width: 30%;
          font-size: 0.8rem;
          color: #1f1f1f;
        }
        .editIcon {
          width: 40%;
          text-align: right;
          display: flex;
          justify-content: flex-end;
          > button {
            width: 1.5rem;
            height: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            outline: none;
            border: none;
            background: #f6f7fa;
            cursor: pointer;
            margin-left: 0.5rem;
            line-height: 1.5rem;
          }
          > button:hover {
            background: #ecf0f2;
          }
          > button:last-child:hover {
            background: #ffecec;
          }
        }
      }
    }
  }
  .historyTop {
    border-bottom: 1px solid #ccc;
  }
  .historyBottom::-webkit-scrollbar,
  .historyTop::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }
  .historyBottom::-webkit-scrollbar-corner,
  .historyTop::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  .historyBottom::-webkit-scrollbar-thumb,
  .historyTop::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    background-clip: padding-box;
    border: solid transparent;
    border-radius: 10px;
  }
  .historyBottom::-webkit-scrollbar-track,
  .historyTop::-webkit-scrollbar-track {
    background-clip: padding-box;
    border: solid transparent;
    border-width: 1px;
  }
  // .historyBottom {
  //   width: 100%;
  //   height: 26.5rem;
  // }
}
</style>
