<template>
  <div class="registerWarp">
    <!-- <div class="login">密码登录</div> -->
    <div>
      <div class="myForm">
        <el-input
          size="small"
          style="width: 20rem"
          placeholder=""
          v-model="form.tel"
        >
        <!-- 手机号 -->
          <template #prepend>{{$t('forgetPass.tel')}}</template>
        </el-input>
      </div>

      <div class="myForm passwordAndTip">
        <el-input
          size="small"
          style="width: 20rem"
          placeholder=""
          :show-password="false"
          :type="[flag ? 'text' : 'password']"
          v-model="form.newPass"
          @keyup.enter="login"
        >
        <!-- 新密码 -->
          <template #prepend>{{$t('forgetPass.newPass')}}</template>
          <template #suffix>
            <img
              v-if="flag"
              @click="flag = !flag"
              src="@/assets/login/eyes.png"
              alt=""
            />
            <img
              v-if="!flag"
              @click="flag = !flag"
              src="@/assets/login/noEye.png"
              alt=""
            />
          </template>
        </el-input>
        <!-- 至少8位密码,字母大小写、数字、及特殊标点符号组合 -->
        <div class="passwordTip">
          {{$t('forgetPass.passTip')}}
        </div>
      </div>
      <div class="myForm">
        <el-input
          size="small"
          style="width: 20rem"
          placeholder=""
          :show-password="false"
          :type="[reFlag ? 'text' : 'password']"
          v-model="form.reNewPass"
          @blur="rePassBlur"
        >
        <!-- 重&nbsp;&nbsp;&nbsp;复 -->
          <template #prepend>{{$t('forgetPass.repeat')}}</template>
          <template #suffix>
            <img
              v-if="reFlag"
              @click="reFlag = !reFlag"
              src="@/assets/login/eyes.png"
              alt=""
            />
            <img
              v-if="!reFlag"
              @click="reFlag = !reFlag"
              src="@/assets/login/noEye.png"
              alt=""
            />
          </template>
        </el-input>
      </div>
      <div class="myForm flexContainer">
        <el-input
          size="small"
          style="width: 13rem"
          placeholder=""
          v-model="form.authCode"
        >
        <!-- 验证码 -->
          <template #prepend>{{$t('forgetPass.authCode')}}</template>
        </el-input>
        <button
          class="authCode"
          :disabled="isCountDown"
          @click.prevent="getAuthCode"
        >
        <!-- 获取验证码 -->
          {{ isCountDown ? countDownNum + "s" : $t('forgetPass.getAuthCode') }}
        </button>
      </div>
      <!-- <div class="noUsername">
        已有帐号？<span @click="backLogin">去登录</span>
      </div> -->
      <div class="tip">{{ submitTip }}</div>
      <div class="lines"></div>
      <div class="registerWordWarp">
        <div class="agreement">
          <i class="el-icon-warning-outline"></i>
          <!-- 如未绑定手机号,请联系管理员 -->
          {{$t('forgetPass.tip1')}}
        </div>
        <div class="loginBtnWarp">
          <button class="loginBtn" @click="nextStep">
            <!-- 下一步 -->
            <span v-if="!loading">{{$t('forgetPass.next')}}</span
            ><i v-if="loading" class="el-icon-loading"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendAuthCode, resetPassByAuthcode } from "@/api/user.js";
import { ElMessage } from "element-plus";
export default {
  components: {},
  data() {
    return {
      flag: false,
      reFlag: false,
      userAgreement: false,
      receiveMail: false,
      form: {
        username: null,
        tel: null,
        newPass: null,
        reNewPass: null,
        authCode: null,
      },
      pageSwitch: false,
      submitTip: "",
      loading: false,
      isCountDown: false,
      countDownNum: 60,
      countDownTimer: null,
    };
  },
  created() {
    let sendTime = localStorage.getItem("JTWorkflow_forgetTime");
    if (sendTime) {
      let diffMs = this.testTimeDiff(Number(sendTime));
      console.log(diffMs);
      if (diffMs >= 60000) {
        localStorage.removeItem("JTWorkflow_forgetTime");
      } else {
        let s = parseInt(diffMs / 1000);
        let count = 60 - s;
        this.isCountDown = true;
        this.countDownNum = count;
        this.countDownTimer = setInterval(() => {
          this.countDownNum--;
          if (this.countDownNum < 1) {
            clearInterval(this.countDownTimer);
            this.countDownTimer = null;
            this.isCountDown = false;
            localStorage.removeItem("JTWorkflow_forgetTime");
            return;
          }
        }, 1000);
      }
    }
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    testTimeDiff(time, diff) {
      let saveTime = +new Date(time);
      let nowTime = +new Date();
      let diffMs = nowTime - saveTime;
      return diffMs;
    },
    async nextStep() {
      let tel_result = this.testTel(this.form.tel);
      if (!tel_result.flag) {
        this.submitTip = tel_result.message;
        return;
      }
      if (!this.form.authCode) {
        // 验证码不能为空
        this.submitTip = this.$t('forgetPass.tip2');
        return;
      }
      let pass_result = this.testPass(this.form.newPass);
      if (!pass_result.flag) {
        this.submitTip = pass_result.message;
        return;
      }
      let rePass_result = this.testRePass(
        this.form.newPass,
        this.form.reNewPass
      );
      if (!rePass_result.flag) {
        this.submitTip = rePass_result.message;
        return;
      }
      //   if (!this.userAgreement || !this.receiveMail) {
      //     this.submitTip = "请勾选下方协议项!";
      //     return;
      //   }
      this.submitTip = "";
      //执行重置密码
      try {
        let result = await resetPassByAuthcode(
          this.form.tel,
          this.form.tel,
          this.form.authCode,
          this.form.newPass
        );
        this.submitTip = "";
        // 修改密码
        console.log(this.$t('forgetPass.tip3'));
        console.log(result);
        this.$store.commit("SET_SHOWlOGIN", true);
        this.$store.commit("SET_ACCOUNTSIGN", "resetSuccess");
        // ElMessage.success({
        //   message: "",
        //   type: "success",
        // });
        // this.backLogin();
      } catch (e) {
        console.log(e);
        // 验证码错误
        this.submitTip = this.$t('forgetPass.codeError');
        // console.log(e.data.code);
        // if (e.data.code === 20108) {
        //   this.submitTip = "验证码不正确!";
        // }
        // if (e.data.code === 20110) {
        //   this.submitTip = "该手机号已被注册!";
        // }
      }
    },
    rePassBlur() {
      if (this.form.reNewPass !== this.form.newPass) {
        // 两次密码不一致
        this.submitTip = this.$t('forgetPass.rePass');
      } else {
        this.submitTip = "";
      }
    },
    async getAuthCode() {
      let tel_result = this.testTel(this.form.tel);
      if (tel_result.flag) {
        this.submitTip = "";
        try {
          let result = await sendAuthCode(this.form.tel, this.form.tel);
          //   console.log(result);
          localStorage.setItem("JTWorkflow_forgetTime", +new Date());
          this.isCountDown = true;
          this.countDownNum = 60;
          this.countDownTimer = setInterval(() => {
            this.countDownNum--;
            if (this.countDownNum < 1) {
              clearInterval(this.countDownTimer);
              this.countDownTimer = null;
              this.isCountDown = false;
              localStorage.removeItem("JTWorkflow_forgetTime");
              return;
            }
          }, 1000);
        } catch (e) {
          console.log(e);
          if (e && e.data && e.data.code === 20105) {
            // 该手机号还未注册此应用
            this.submitTip = this.$t('forgetPass.telErr');
          }
        }
      } else if (!tel_result.flag) {
        this.submitTip = tel_result.message;
      }
    },
    testRePass(pass, rePass) {
      if (pass === rePass) {
        return {
          flag: true,
          message: "",
        };
      } else {
        return {
          flag: false,
          // 两次密码不一致
          message: this.$t('forgetPass.tip4'),
        };
      }
    },
    testPass(str) {
      if (!str) {
        return {
          flag: false,
          // 新密码不能为空
          message: this.$t('forgetPass.newPassError1'),
        };
      }
      let reg_pass =
        /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,}$/;
      let isPass = reg_pass.test(str);
      if (!isPass) {
        return {
          flag: false,
          // 新密码格式不对
          message: this.$t('forgetPass.newPassError2'),
        };
      } else {
        return {
          flag: true,
          message: "",
        };
      }
    },
    testTel(tel) {
      if (!tel) {
        return {
          flag: false,
          // 手机号不能为空
          message: this.$t('forgetPass.telNull'),
        };
      }
      let reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      let isTel = reg_tel.test(tel);
      if (isTel) {
        return {
          flag: true,
          message: "",
        };
      } else {
        return {
          flag: false,
          // 手机号格式错误
          message: this.$t('forgetPass.telError'),
        };
      }
    },
    backLogin() {
      this.$emit("goLogin", true);
    },
    init(bool) {
      this.pageSwitch = bool;
    },
    toggleFun(bool) {
      this.pageSwitch = bool;
    },
  },
};
</script>
<style scoped lang='scss'>
.registerWarp {
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: 20rem;
  }
}
.login {
  text-align: center;
  line-height: 20px;
  margin-bottom: 1rem;
}
.tip {
  color: #f55;
  text-align: center;
  line-height: 18px;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
}
.lines {
  width: 120%;
  margin-left: -10%;
  height: 1px;
  background: #efefef;
}
.myForm {
  height: 2.8rem;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  :deep(.el-input) {
    .el-input-group__prepend {
      width: 70px;
    }
  }
  .passwordTip {
    color: #7590b2;
    font-size: 0.7rem;
    margin: 0.5rem 0;
    text-align: left;
  }
}
.passwordAndTip {
  height: 5rem;
}
.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.authCode {
  width: 100px;
  height: 32px;
  background: #4582ff;
  border-radius: 4px 4px 4px 4px;
  outline: none;
  border: none;
  background: #4582ff;
  color: #fff;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  transition: background 0.1s;
}
.authCode:hover {
  background: #8fb5ff;
}
.authCode[disabled] {
  background: #F0F0F0;
  color: #7590B2;
  cursor: not-allowed;
}
.agreement {
  // margin-top: 1rem;
  width: 57%;
  text-align: left;
  box-sizing: border-box;
  // padding-left: 0.5rem;
  font-size: 12px;
  // margin-left: -10%;
  > i {
    font-size: 14px;
  }
  .labelCtrl {
    color: #9e9e9e;
    font-size: 0.8rem;
  }
  > div {
    margin-top: 0.2rem;
  }
}
// .loginBtnWarp{

// }
.registerWordWarp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 0.5rem 0;
  padding-top: 1rem;
}
.loginBtn {
  width: 120px;
  height: 32px;
  background: #4582ff;
  border-radius: 4px 4px 4px 4px;
  outline: none;
  border: none;
  background: #4582ff;
  color: #fff;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  transition: background 0.1s;
}
.loginBtn:hover{
  background: #8FB5FF;
}
.noUsername {
  text-align: left;
  padding-left: 0.5rem;
  color: #ccc;
  > span {
    color: #81aaff;
    cursor: pointer;
  }
  > span:hover {
    text-decoration: underline 1px;
  }
}
:deep(.el-input-group__prepend) {
  width: 39px;
}
:deep(.el-input__suffix) {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  > .el-input__suffix-inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>