<template>
  <div class="material">
    <el-dialog
      title="素材选择"
      v-model="show"
      width="60%"
      :before-close="handleClose"
      @opened="opened"
    >
      <div class="materialLibraryWrap">
        <div class="assetHeader">
          <button
            :class="assetSelectTag === 'all' ? 'active' : ''"
            @click="select('all')"
          >
            <!-- 全部 -->
            {{ $t("assetPicker.all") }}
          </button>
          <button
            :class="assetSelectTag === 'picture' ? 'active' : ''"
            @click="select('picture')"
          >
            <!-- 图片 -->
            {{ $t("assetPicker.picture") }}
          </button>
          <button
            :class="assetSelectTag === 'video' ? 'active' : ''"
            @click="select('video')"
          >
            <!-- 视频 -->
            {{ $t("assetPicker.video") }}
          </button>
          <button
            :class="assetSelectTag === 'PDF' ? 'active' : ''"
            @click="select('PDF')"
          >
            <!-- PDF -->
            {{ $t("assetPicker.PDF") }}
          </button>
        </div>
        <div class="assetBody">
          <div class="assetItem" v-for="(item, index) in showList" :key="index">
            <div
              class="assetItemContent"
              :class="
                activeAssets && activeAssets.assetGuid === item.assetGuid
                  ? 'active'
                  : ''
              "
              @click="selectAsset(item)"
            >
              <el-image
                v-if="getFileTypeByFileName(item.originFileName)"
                :src="item.thumbnailUrl"
                fit="cover"
              >
                <template #error>
                  <div class="image-slot">
                    <img src="@/assets/task-detail/defaultPDF.png" />
                  </div>
                </template>
              </el-image>
            </div>
          </div>
          <div class="enpty" v-if="showList.length === 0">
            <div>
              <img src="@/assets/myTask/defaultEnpty.png" alt="" />
              <!-- 当前无可浏览素材 -->
              <div>{{ $t("assetPicker.noAsset") }}</div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" class="btn cancal-btn" @click="show = false"
            >取 消</el-button
          >
          <el-button
            size="small"
            class="btn"
            type="primary"
            @click="submitSelect"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getProjectAssets } from "@/api/workflow";
export default {
  props: ["projectGuid", "isCreator"],
  data() {
    return {
      show: false,
      showList: [],
      activeAssets: null,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    handleClose() {
      this.activeAssets = null;
      this.show = false;
    },
    submitSelect() {
      this.$emit("selectedItem", this.activeAssets);
      this.show = false;
    },
    selectAsset(item) {
      this.activeAssets = item;
    },
    opened() {
      this.getAssetList(this.projectGuid, 0, 999999);
    },
    select(content) {
      this.assetSelectTag = content;
      console.log(content);
      if (content === "all") {
        let list = this.filterAssetType(this.assetInfo.data);
        this.showList = [...list.picture, ...list.video, ...list.PDF];
      } else if (content === "picture") {
        this.showList = this.filterAssetType(this.assetInfo.data).picture;
        // this.nums = 8;
      } else if (content === "video") {
        this.showList = this.filterAssetType(this.assetInfo.data).video;
        // this.nums = 5;
      } else if (content === "PDF") {
        this.showList = this.filterAssetType(this.assetInfo.data).PDF;
        // this.nums = 7;
      }
    },
    async getAssetList(projectId, page, size) {
      this.assetInfo = await getProjectAssets(projectId, page, size);
      console.log(this.assetInfo.data);
      console.log(this.filterAssetType(this.assetInfo.data));
      this.select("all");
    },
    filterAssetType(dataList) {
      let picture = [];
      let pictureTypes = [".jpg", ".jpeg", ".png", ".tif", ".bmp"];
      let video = [];
      let videoTypes = [
        ".mp4",
        ".rmvb",
        ".rm",
        ".asf",
        ".divx",
        ".mpg",
        ".mpeg",
        ".wmv",
        ".mkv",
        ".vob",
      ];
      let PDF = [];
      let PDFTypes = ["PDF", "pdf"];
      //   dataList.forEach((value, index) => {
      //     // return modelTypes.includes(value.originFileName);
      //     modelTypes.forEach((v, i) => {
      //       if (value.originFileName && value.originFileName.includes(v)) {
      //         model.push(value);
      //       }
      //     });
      //   });
      dataList.forEach((value, index) => {
        // return pictureTypes.includes(value.originFileName);
        pictureTypes.forEach((v, i) => {
          if (value.originFileName && value.originFileName.includes(v)) {
            picture.push(value);
          }
        });
      });
      dataList.forEach((value, index) => {
        // return videoTypes.includes(value.originFileName);
        videoTypes.forEach((v, i) => {
          if (value.originFileName && value.originFileName.includes(v)) {
            video.push(value);
          }
        });
      });
      dataList.forEach((value, index) => {
        // return PDFTypes.includes(value.originFileName);
        PDFTypes.forEach((v, i) => {
          if (value.originFileName && value.originFileName.includes(v)) {
            PDF.push(value);
          }
        });
      });
      return { picture, video, PDF };
    },
    getFileTypeByFileName(fileName) {
      //   let modelTypes = [".fbx", ".obj", ".glb", ".gltf", ".stl"];
      let pictureTypes = [".jpg", ".jpeg", ".png", ".tif", ".bmp", "gif"];
      let videoTypes = [
        ".mp4",
        ".rmvb",
        ".rm",
        ".asf",
        ".divx",
        ".mpg",
        ".mpeg",
        ".wmv",
        ".mkv",
        ".vob",
      ];
      let PDFTypes = ["PDF", "pdf"];
      let type = false;
      pictureTypes.forEach((value, index) => {
        if (fileName.indexOf(value) !== -1) {
          type = "picture";
        }
      });
      videoTypes.forEach((value, index) => {
        if (fileName.indexOf(value) !== -1) {
          type = "video";
        }
      });
      PDFTypes.forEach((value, index) => {
        if (fileName.indexOf(value) !== -1) {
          type = "PDF";
        }
      });
      return type;
    },
    init(data, bool) {
      this.show = bool;
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
.material {
  .assetBody {
    //   float: left;
    //   justify-content: flex-start;

    //   align-items: ;

    flex-wrap: wrap;
    width: 100%;
    height: 95%;
    overflow: auto;
    padding: 0rem 0.5rem;
    box-sizing: border-box;
    > div {
      float: left;
      margin: 0 0.4rem 0 0.4rem;
      width: 23.5%;
      height: 0;
      padding-bottom: 23.5%;
      position: relative;
      background: #fff;
      margin-top: 1rem;
      overflow: hidden;
      .assetItemContent {
        border: 2px solid #fff;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        > .el-image {
          width: 100%;
          height: 100%;
          .img {
            width: 100%;
            height: 100%;
          }
        }
        > img {
          width: 100%;
        }
        .contentCover {
          width: 100%;
          height: 27%;
          // background-image: linear-gradient(
          //   to bottom,
          //   rgba(0, 0, 0, 0),
          //   rgba(0, 0, 0, 100)
          // );

          position: absolute;
          bottom: 0px;
          color: #fff;
          text-align: left;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 0.5rem;
          padding: 0 4px;
          visibility: visible;
          background: rgba(0, 0, 0, 0.6);
          box-sizing: border-box;
          > div {
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            line-height: 198%;
          }
        }
        .deleteIcon {
          position: absolute;
          bottom: 0px;
          right: 0px;
          border: 1px solid #b50202;
          color: #b50202;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.3rem;
          font-size: 1.2rem;
          visibility: hidden;
          background: rgba(181, 2, 2, 0.3);
        }
      }
      .assetItemContent:hover {
        border: 2px solid #4582ff;
      }
      .active {
        border: 2px solid #4582ff;
      }
    }
    .enpty {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      width: 100%;
      height: 95%;
      padding: 0;
      > div {
        .createBtn {
          margin-top: 1rem;
          > button {
            width: 120px;
            height: 32px;
            background: #4582ff;
            border-radius: 4px 4px 4px 4px;
            outline: none;
            border: none;
            background: #4582ff;
            color: #fff;
            line-height: 32px;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }
  .assetBody::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }
  .assetBody::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  .assetBody::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    background-clip: padding-box;
    border: solid transparent;
    border-radius: 10px;
  }
  .assetBody::-webkit-scrollbar-track {
    background-clip: padding-box;
    border: solid transparent;
    border-width: 1px;
  }
  .assetHeader {
    width: 100%;
    //   height: 2.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    > button {
      outline: none;
      border: none;
      background: #fff;
      width: 6.8rem;
      height: 1.8rem;
      font-size: 0.85rem;
      cursor: pointer;
      border-radius: 3px;
      // padding: 0.2rem 0.4rem;
    }
    > button:hover {
      background: #edf3ff;
    }
    .addAsset {
      width: 26px;
      height: 26px;
      min-width: auto;
      min-height: auto;
      // line-height: 1.5rem;
      // text-align: center;
      background: #deb573;
      color: #fff;
      border-radius: 50%;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      font-size: 16px;
      padding: 0;
    }
    .addAsset:hover {
      background: #deb573;
    }
    .active {
      background: #4582ff !important;
      color: #fff;
    }
  }
  .materialLibraryWrap {
    width: 100%;
    height: 45vh;
    background: #fff;
    overflow: auto;
  }
  .materialLibraryWrap::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }
  .materialLibraryWrap::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  .materialLibraryWrap::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    background-clip: padding-box;
    border: solid transparent;
    border-radius: 10px;
  }
  .materialLibraryWrap::-webkit-scrollbar-track {
    background-clip: padding-box;
    border: solid transparent;
    border-width: 1px;
  }
  :deep(.el-dialog) {
    border-radius: 12px;
    .el-dialog__header {
      padding: 20px 40px 10px;
      text-align: left;

      .el-dialog__title {
        color: #4582ff;
        font-size: 1.1rem;
      }
    }
    .el-dialog__footer {
      .btn {
        width: 7.25rem;
        height: 1.8rem;
        opacity: 1;
        background: #4582ff;
        border-radius: 0.25rem;
        color: #ffffff;
        font-size: 0.88rem;
        padding: 0;

        &:disabled {
          opacity: 0.6;
        }

        &.cancal-btn {
          background-color: white;
          border: 0.06rem solid #bbbbbb;
          color: #242424;
          border: 0.06rem solid #ececec;
        }
      }
    }
  }
}
</style>
