<template>
  <div class="accountInfo">
    <!-- 重设密码 -->
    <div class="title">{{$t("accountInfo.title")}}</div>
    <!-- <div class="formWarp">
      <el-input
        size="small"
        v-model="formData.new"
        placeholder="输入新密码"
      ></el-input>
      <el-input
        size="small"
        v-model="formData.reNew"
        placeholder="重复新密码"
      ></el-input>
    </div>
    <div class="submitWarp">
      <button>确认修改</button>
    </div> -->
    <el-form
      :model="formData"
      status-icon
      :rules="rules"
      ref="resetPassword"
      label-width="0"
      class="demo-ruleForm"
    >
      <el-form-item label="" prop="old" :error="oldErrorMsg">
        <!-- 输入旧密码 -->
        <el-input
          type="password"
          v-model="formData.old"
          autocomplete="off"
          :placeholder="$t('accountInfo.inputOldPassword')"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="new">
        <!-- 输入新密码 -->
        <el-input
          type="password"
          v-model="formData.new"
          autocomplete="off"
          :placeholder="$t('accountInfo.inputNewPassword')"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="reNew">
        <!-- 重复新密码 -->
        <el-input
          type="password"
          v-model="formData.reNew"
          autocomplete="off"
          :placeholder="$t('accountInfo.reINputNewPassword')"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <div class="submitWarp">
          <!-- 确认修改 -->
          <button @click="submitForm($event, 'resetPassword')">{{$t('accountInfo.submit')}}</button>
        </div>
        <!-- <el-button
          type="primary"
          class="resetPasswordBtn"
          size="small"
          @click="submitForm('resetPassword')"
          >确认修改</el-button
        > -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { changePass } from "@/api/user";
import { ElMessage } from "element-plus";
export default {
  components: {},
  data() {
    var validateOldPass = (rule, value, callback) => {
      if (value === "") {
        // 请输入旧密码
        callback(new Error(this.$t('accountInfo.tip1')));
      } else {
        // if (this.formData.new !== "") {
        //   this.$refs.resetPassword.validateField("reNew");
        // }
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        // 请输入新密码
        callback(new Error(this.$t('accountInfo.tip2')));
      } else {
        let reg = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,}$/;
        let flag = reg.test(value);
        if (flag) {
          callback();
        } else {
          callback(
            //   密码需同时包含大小写字符、数字和特殊符号，长度8位及以上
            new Error(this.$t('accountInfo.tip3'))
          );
        }
        // if (this.formData.new !== "") {
        //   this.$refs.resetPassword.validateField("reNew");
        // }
        // callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      console.log(value);
      if (value === "") {
        // 请再次输入新密码
        callback(new Error(this.$t('accountInfo.tip4')));
      } else if (value !== this.formData.new) {
        // 两次输入密码不一致
        callback(new Error(this.$t('accountInfo.tip5')));
      } else {
        callback();
      }
    };
    return {
      oldErrorMsg: "",
      formData: {
        new: "",
        reNew: "",
        old: "",
      },
      rules: {
        old: [{ validator: validateOldPass, trigger: "blur" }],
        new: [{ validator: validatePass, trigger: "blur" }],
        reNew: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    submitForm(e, formName) {
      e.preventDefault();
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // alert("submit!");
          try {
            let data = await changePass(this.formData.old, this.formData.new);
            // if (data.result == "ok") {
              ElMessage.success({
                // 修改成功,请重新登录
                message: this.$t('accountInfo.tip6'),
                type: "success",
              });
              setTimeout(() => {
                this.$store.dispatch("logout");
                this.$router.push("/");
              }, 1000);
            // }
          } catch (e) {
            console.log(e);
            if (e && e.data && e.data.code === 40104) {
              console.log(this.$refs.resetPassword.fields);
              console.log(this.$refs[formName].validateField);
              // this.$refs[formName].validateField('old',(valid)=>{

              // })
              // this.$refs[formName].fields[0].error="旧密码不正确";
              this.oldErrorMsg = "";
              setTimeout(() => {
                // 旧密码不正确
                this.oldErrorMsg = this.$t('accountInfo.tip7');
              }, 100);

              console.log(this.$refs);
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scope lang='scss'>
.accountInfo {
  width: 20rem;
  text-align: left;
  margin-top: 2rem;
  .title {
    color: #42658b;
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .formWarp {
    margin-top: 1rem;
    > div {
      margin-bottom: 1rem;
    }
  }
  .submitWarp {
    text-align: center;
    > button {
      width: 100%;
      height: 2rem;
      line-height: 2rem;
      border-radius: 4px;
      cursor: pointer;
      outline: none;
      border: none;
      color: #fff;
      background: #4582ff;
      font-size: 0.9rem;
      transition: background 0.1s;
    }
    > button:hover {
      background: #8fb5ff;
    }
  }
}
:deep(.resetPasswordBtn) {
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  border-radius: 1rem;
  cursor: pointer;
  outline: none;
  border: none;
  color: #fff;
  background: #6a9bff;
  font-size: 0.9rem;
}
</style>