
import { PolylineEdge, PolylineEdgeModel } from "@logicflow/core";

class SequenceModel extends PolylineEdgeModel {
    setAttributes() {
        //只允许指定bool值的线可编辑
        if (this.properties.canEditEdgeText) {
            this.text.editable = true;
        } else {
            this.text.editable = false;
        }
        this.offset = this.graphModel.gridSize;
        // this.offset = Math.floor(this._height / this.graphModel.gridSize) * this.graphModel.gridSize / 2;
    }
    //自定义折线边的样式
    getEdgeStyle() {
        const style = super.getEdgeStyle();
        const { properties } = this;
        if (properties.isActived) {
            style.strokeDasharray = "4 4";
        }
        style.strokeWidth = 1;
        style.stroke = "#000";
        return style;
    }
    //自定义折线上文本的样式
    getTextStyle() {
        const style = super.getTextStyle();
        // style.color = "#3451F1";
        // style.fontSize = 30;
        style.background.fill = "#F8FAFB";
        return style;
    }
    //自定义折线外边框的样式
    getOutlineStyle() {
        const style = super.getOutlineStyle();
        // style.stroke = "red";
        // style.hover.stroke = "red";
        return style;
    }
    //禁止某些边的编辑
    getEdge() {
        const { model } = this.props;
        console.log("modelText", model);
    }
    //计算文字位置
    getTextPosition() {
        // 在文案为空的情况下，文案位置为双击位置
        const textValue = this.text?.value;
        // console.log();
        this.customTextPosition = true;
        if (this.dbClickPosition && !textValue) {
            const { x, y } = this.dbClickPosition;
            return {
                x, y,
            };
        }
        // console.log("打印this", this);
        let pointsList = this.pointsList;
        let points;
        if (pointsList.length === 1) {
            points = [pointsList[0], pointsList[0]];
        } else if (pointsList.length >= 2) {
            pointsList = this.pointsList;
            let res = [];
            pointsList.forEach((item, index) => {
                let prevPoint = item;
                let nextPoint = pointsList[index + 1];
                if (nextPoint && prevPoint.x === nextPoint.x) {
                    let distance = Math.abs(prevPoint.y - nextPoint.y);
                    let options = {
                        distance,
                        prevPoint,
                        nextPoint,
                    }
                    res.push(options);
                } else if (nextPoint && prevPoint.y === nextPoint.y) {
                    let distance = Math.abs(prevPoint.x - nextPoint.x);
                    let options = {
                        distance,
                        prevPoint,
                        nextPoint,
                    }
                    res.push(options);
                }
            })
            res.sort((a, b) => {
                return a.distance - b.distance;
            })
            // console.log("打印排序后的线段列表", res);
            // console.log("打印最长距离对象", res[res.length - 1]);
            let resObj = {
                x: (res[res.length - 1].prevPoint.x + res[res.length - 1].nextPoint.x) / 2,
                y: (res[res.length - 1].prevPoint.y + res[res.length - 1].nextPoint.y) / 2,
            }
            // console.log(resObj);
            // console.log(this.customTextPosition);
            return resObj
        }
        const [p1, p2] = points;
        return {
            x: (p1.x + p2.x) / 2,
            y: (p1.y + p2.y) / 2,
        };
        // return {
        //     // x: this.points[1].x,
        //     // y: this.points[1].y,
        //     x: 2,
        //     y: 3
        // }
        // const currentPositionList = points2PointsList(this.points);
        // const [p1, p2] = getLongestEdge(currentPositionList);

    }
}

export default {
    type: "polyline",
    view: PolylineEdge,
    model: SequenceModel,
};
