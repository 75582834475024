<template>
  <div id="videoViewWarp">
    <!-- 视频预览 -->
    <el-dialog
      v-if="showSwitch"
      :title="$t('videoView.title')"
      v-model="showSwitch"
      width="50%"
      :before-close="handleClose"
      :destroy-on-close="true"
    >
      <!-- <span>这是一段信息</span> -->
      <video class="video" controls muted :src="src" alt="" />
      <template #footer>
        <span class="dialog-footer">
          <!-- 取 消 -->
          <el-button @click="showSwitch = false">{{
            $t("videoView.cancel")
          }}</el-button>
          <!-- 关闭 -->
          <el-button type="primary" @click="showSwitch = false">{{
            $t("videoView.close")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      showSwitch: false,
      dataInfo: null,
      src: null,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    setSwitch(bool, src) {
      this.showSwitch = bool;
      this.src = src;
    },
    handleClose() {
      console.log("handleClose");
      this.showSwitch = false;
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
#videoViewWarp {
  :deep(.el-dialog) {
    border-radius: 12px;
    > .el-dialog__header {
      padding: 20px 30px 10px;
      text-align: left;
      > .el-dialog__title {
        color: #4582ff;
        text-align: left;
      }
    }
    > .el-dialog__body {
      padding: 20px 30px 10px;
    }
    > .el-dialog__footer {
      padding: 10px 30px 20px;
      > .dialog-footer {
        button {
          width: 120px;
          height: 32px;
          border-radius: 4px;
          transition: background 0.1s;
          border: none;
          padding: 9px 15px;
          min-height: auto;
        }
        > button:nth-child(1) {
          background: #a8b7ca;
          color: #ffffff;
          display: none;
        }
        > button:nth-child(1):hover {
          background: #b8c2d2;
        }
        > button:nth-child(2) {
          background: #4582ff;
          color: #ffffff;
        }
        > button:nth-child(2):hover {
          background: #8fb5ff;
        }
      }
    }
  }
}
.video {
  width: 100%;
}
</style>
