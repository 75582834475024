import router from './router'
import store from "./store";
// import NProgress from 'nprogress' // progress bar
// import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/setLocalstorage' // get token from cookie
import app from "./main.js"
// NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/', '/explore', '/auth-redirect', "/enterprisePage"] // no redirect whitelist , '/auth-redirect'

router.beforeEach(async (to, from, next) => {
  // start progress bar
  // NProgress.start()

  // set page title
  // document.title = getPageTitle(to.meta.title)
  document.title = 'JTWorkflow' + (process.env.NODE_ENV === 'production' ? '' : `-${process.env.NODE_ENV}`);


  console.log('to path', to.path, to.params, to.query, store, store.state.User)
  // determine whether the user has logged in
  const hasToken = getToken()
  if (hasToken) {
    // if (to.path === '/login') {
    //   // if is logged in, redirect to the home page
    //   next({ path: '/' })
    //   // NProgress.done() // 
    // } else {
    // determine whether the user has obtained his permission roles through getInfo

    // const hasApps = store.getters.apps && store.getters.apps.length > 0
    let userValid = store.state.User.accountId

    if (userValid) {
      next()
    } else {
      try {
        // await userStore().getUser();
        try {
          await store.dispatch("getInfo");
          
          next({ ...to, replace: true })
        } catch (e) {
          console.log(e);
          // if (e.data.code === 40107) {
            console.log("token过期");
            await store.dispatch("logout");
            next({ ...to, replace: true })
          // }
        }

      } catch (error) {
        console.log('elmessagebox alert', error, to.path);
        // ElMessageBox.alert(error || 'Has Error')
        // next(`/login?redirect=${to.path}`)
        // next('/explore')
        // const Vue = app.config.globalProperties;
        store.commit("SET_SHOWlOGIN", true);
        // NProgress.done()
      }
      // }
    }
  } else {
    /* has no token*/
    if (to.path === "/taskDetail") {
      console.log(to);
      // const Vue = app.config.globalProperties;
      store.commit("SET_SHOWlOGIN", true);
      store.commit("SET_CACHESHAREURL", to.fullPath);
      // NProgress.done()
    }
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly

      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      // next(`/login?redirect=${to.path}`)
      next('/explore')
      // NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  // NProgress.done()
})
