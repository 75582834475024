<template>
  <div id="dialogWarp">
    <el-dialog
      :title="$t('userAgreement.title')"
      v-if="show"
      v-model="show"
      width="500px"
    >
      <div class="contant">
        <iframe
          :src="iframeSrc"
          sandbox="allow-scripts allow-same-origin allow-forms"
          allow="microphone;camera;display-capture"
          frameborder="0"
          ref="iframe"
          style="width: 100%;height: 99%;border: none;overflow: auto;"
        />
        <!-- <p class="title">隐私政策</p>
        <p>
          本应用及主体公司：上海境腾信息科技有限公司尊重并保护所有使用服务用户的个人隐私权。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本应用不会将这些信息对外披露或向第三方提供。本应用会不时更新本隐私权政策。
          您在同意本应用服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本应用服务使用协议不可分割的一部分。
        </p>
        <p class="title">应用权限</p>
        <p>
          (a)
          基于您的授权，我们可能会获取您的相机，麦克风等权限为了可以进行音视频通讯。
        </p>
        <p class="title">适用范围</p>
        <p>(a) 在您注册本应用帐号时，您根据本应用要求提供的个人注册信息；</p>
        <p>
          (b)
          在您使用本应用网络服务，或访问本应用平台网页时，本应用自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；
        </p>
        <p>(c) 本应用通过合法途径从商业伙伴处取得的用户个人数据。</p>
        <p>您了解并同意，以下信息不适用本隐私权政策：</p>
        <p>(a) 您在使用本应用平台提供的搜索服务时输入的关键字信息；</p>
        <p>
          (b)
          本应用收集到的您在本应用发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；
        </p>
        <p>(c) 违反法律规定或违反本应用规则行为及本应用已对您采取的措施。</p>
        <p class="title">信息使用</p>
        <p>
          (a)本应用不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和本应用（含本应用关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。
        </p>
        <p>
          (b)
          本应用亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本应用平台用户如从事上述活动，一经发现，本应用有权立即终止与该用户的服务协议。
        </p>
        <p class="title">信息披露</p>
        <p>
          在如下情况下，本应用将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：
        </p>
        <p>(a) 经您事先同意，向第三方披露；</p>
        <p>(b) 为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；</p>
        <p>
          (c)
          根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
        </p>
        <p>
          (d)
          如您出现违反中国有关法律、法规或者本应用服务协议或相关规则的情况，需要向第三方披露；
        </p>
        <p>
          (e)
          如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；
        </p>
        <p>
          (f)
          在本应用平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，本应用有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。
        </p>
        <p>(g) 其它本应用根据法律、法规或者网站政策认为合适的披露。</p>
        <p class="title">信息存储和交换</p>
        <p>
          本应用收集的有关您的信息和资料将保存在本应用及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本应用收集信息和资料所在地的境外并在境外被访问、存储和展示。
        </p>
        <p class="title">信息安全</p>
        <p>
          (a)
          本应用帐号均有安全保护功能，请妥善保管您的用户名及密码信息。本应用将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。
        </p>
        <p class="title">本隐私政策的更改</p>
        <p>
          (a)如果决定更改隐私政策，我们会在本政策中、本公司网站中以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。
        </p>
        <p>
          (b)本公司保留随时修改本政策的权利，因此请经常查看。如对本政策作出重大更改，本公司会通过网站通知的形式告知。
        </p> -->
      </div>
      <template #footer>
        <span class="dialog-footer">
          <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
          <el-button type="primary" @click="show = false">{{
            $t("userAgreement.close")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
// import { getLanguage } from "@/utils/getLanguage.js";
// import { mapGetters } from "vuex/dist/vuex.cjs.js";
import LangManager from "../../../../../utils/langManager";
export default {
  props: {},
  data() {
    return {
      show: false,
      iframeSrc: "",
      privacySrc: "https://www.jingtengtech.com/privacy.html",
      privacyENSrc: "https://www.jingtengtech.com/privacyEn.html",

      EULASrc: "https://www.jingtengtech.com/EULA.html",
      EULAENSrc: "https://www.jingtengtech.com/EULAEn.html",
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    init(bool, type) {
      let lang = LangManager.getLanguage().value;
      this.show = bool;
      if (type === 1) {
        if (lang === "zh") {
          this.iframeSrc = this.EULASrc;
        } else {
          this.iframeSrc = this.EULAENSrc;
        }
      } else if (type === 2) {
        if (lang === "zh") {
          this.iframeSrc = this.privacySrc;
        } else {
          this.iframeSrc = this.privacyENSrc;
        }
      }
      console.log(this.iframeSrc);
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
#dialogWarp {
  :deep(.el-dialog) {
    border-radius: 12px;

    > .el-dialog__header {
      padding: 20px 30px 15px;
      text-align: left;
      > .el-dialog__title {
        color: #4582ff;
        text-align: left;
      }
    }
    > .el-dialog__body {
      word-break: break-word !important;
      padding: 0px 30px 10px;
      > .contant {
        margin: 0;
        width: 100%;
        height: 60vh;
        overflow: auto;

        > p {
          text-align: left;
          margin: 0;
          font-size: 14px;
          line-height: 21px;
        }
      }
      .title {
        font-weight: bold;
        font-size: 16px;
      }
      .contant::-webkit-scrollbar {
        width: 14px;
        height: 14px;
      }
      .contant::-webkit-scrollbar-corner {
        background-color: transparent;
      }
      .contant::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.1);
        background-clip: padding-box;
        border: solid transparent;
        border-radius: 10px;
      }
      .contant::-webkit-scrollbar-track {
        background-clip: padding-box;
        border: solid transparent;
        border-width: 1px;
      }
    }
    > .el-dialog__footer {
      > .dialog-footer {
        button {
          width: 120px;
          height: 32px;
          border-radius: 4px;
          transition: background 0.1s;
          border: none;
          padding: 9px 15px;
          min-height: auto;
        }
        > button:nth-child(1) {
          background: #a8b7ca;
          color: #ffffff;
        }
        > button:nth-child(1):hover {
          background: #b8c2d2;
        }
        > button:nth-child(2) {
          background: #4582ff;
          color: #ffffff;
        }
        > button:nth-child(2):hover {
          background: #8fb5ff;
        }
      }
    }
  }
}
</style>
