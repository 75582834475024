// import { message } from 'ant-design-vue';
// import Vue from 'vue'
import { createI18n } from 'vue-i18n';

// import elzh from 'element-ui/lib/locale/lang/zh-CN'
// import elen from 'element-ui/lib/locale/lang/en'
// import elja from 'element-ui/lib/locale/lang/ja'
// import elkr from 'element-ui/lib/locale/lang/ko'

class _langManager {
  i18n;
  Languages = new Map();

  loadFiles() {
    const files = require.context('@/langs', true, /\.js$/).keys()
    this.Languages = new Map()
    const messages = new Map()
    for (const file of files) {
      const lang = (/[^.\/][A-Za-z]*[^.js]/.exec(file))[0]
      const message = require(`@/langs/${lang}.js`)
      this.Languages.set(lang, { value: lang, label: message.languageName })
      messages.set(lang, message)
    }
    return Object.fromEntries(messages)
  }

  constructor() {
    // Vue.use(VueI18n)

    const messages = this.loadFiles()

    this.i18n = createI18n({
      locale: 'en',
      messages,
      silentTranslationWarn: true
    })

    const initLang = this.getLanguage()
    this.i18n.global.locale = initLang.value
  }

  getLocaleLanguage() {
    let language = navigator.language.toLocaleLowerCase()
    if (language === 'zh-CN' || language === 'zh-cn' ||language === 'zh') {
      language = 'zh'
    } else if ((language === 'ja')) {
      language = 'ja'
    } else if (language === 'kr') {
      language = 'kr'
    } else {
      language = 'en'
    }

    return language
  }

  setLanguage(language) {
    localStorage.setItem('language', language)
    this.i18n.global.locale = language
    // locale.use(this.Languages.get(language).elLang);
  }

  getLanguage() {
    let language = localStorage.getItem('language')
    if (!language || !this.Languages.has(language)) {
      language = this.getLocaleLanguage()
    }
    return this.Languages.get(language)
  }
}

const LangManager = new _langManager()

export default LangManager
