// import { createStore } from "vuex";
// import { login, getFeatures } from '@/api/user';
// import { getUserInfo } from '@/api/workflow.js';
// import { setToken, removeToken, getToken } from '@/utils/ctrlToken.js'
// import Store from "@/store/index.js"
const User = {
    state: () => ({
        showHistory: false,
        showProjectInfo: false,
        selectShape: false,
        projectTaskResult: null,
        taskDetailInfo: {},
        selectedTaskItem: {},
        cacheShareUrl: null,
        headerPath: process.env.VUE_APP_STATIC_PATH,
    }),
    mutations: {
        SET_SHOWHISTORY(state, data) {
            state.showHistory = data;
        },
        SET_PROJECTINFO(state, data) {
            state.showProjectInfo = data;
        },
        SET_SELECTSHAPE(state, data) {
            state.selectShape = data;
        },
        SET_PROJECTTASKRESULT(state, data) {
            state.projectTaskResult = data;
        },
        SET_SELECTEDTASKITEM(state, data) {
            state.selectedTaskItem = data;
        },
        SET_CACHESHAREURL(state, data) {
            state.cacheShareUrl = data;
        },
    },
    actions: {

    },
    getters: {}
}
export default User;