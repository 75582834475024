

import request from '@/utils/request'
// import getRequestData from '@/utils/wrapResult'

//获取对于单个项目，我的状态
export function getProjectStatusById(projectGuid) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/mine/getProjectStatusById`, { projectGuid })
}

//获取"我收藏的流程"
export function getMyFavourateProjects(page, size) {
    // console.log('getMyFavourateProjects')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/mine/getMyFavourateProjects`, { page, size })
}

//获取"已拥有(购买)的流程"
export function getMyOwnProjects(page, size) {
    // console.log('getMyOwnProjects')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/mine/getMyOwnProjects`, { page, size })
}

//获取"已创建的流程列表"
export function getMyCreatedProjects(page, size) {
    // console.log('getMyCreatedProjects')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/mine/getMyCreatedProjects`, { page, size })
    //bew
    // return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/getMyCreatedProjects`, { page, size })
}

//获取"已发布的流程"
export function getMyReleaseProjects(page, size) {
    // console.log('getMyReleaseProjects')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/mine/getMyReleaseProjects`, { page, size })
}


//获取账号本人的待审核流程
export async function getAuditList(page, size, auditStatus) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/getAuditList`, { page, size, auditStatus });
    // return getRequestData(res);
}

//获取账号本人待审核流程的数量
export async function getAuditStat() {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/getAuditStat`, {});
    // return getRequestData(res);
}

//获取我创建的项目(包含审核状态)
export async function getMyProjectsWithAudit(page, size, auditStatus) {
    // console.log('getMyProjectsWithAudit', auditStatus)
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/getMyProjectsWithAudit`, { page, size, auditStatus });
    // return getRequestData(res);
}

//获取我发布的项目中企业公开部分
export async function getAllReleasesForFirm(page, size, auditStatus) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/getAllReleasesForFirm`, { page, size, auditStatus });
    // return getRequestData(res);
}

//获取该项目审核历史记录列表
export async function getAuditHistoryList(projectGuid, page, size) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/getAuditHistoryList`, { page, size, projectGuid });
    // return getRequestData(res);
}

//取消已提交的审核
export async function cancelAudit(projectGuid) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/cancelAudit`, { projectGuid });
    // return getRequestData(res);
}

//审核员获取审核列表
export async function getAudittingList(page, size, auditStatus) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/getAudittingList`, { page, size, auditStatus });
    // return getRequestData(res);
}

//审核员拒绝通过审核
export async function rejectAudit(auditReleaseGuid, reason) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/rejectAudit`, { auditReleaseGuid, reason });
    // return res;
}

//审核员通过审核 发布项目
export async function publishProject(projectGuid, isPublic) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/publishProject`, { projectGuid, isPublic })
    // return res;
}

//保存收藏
// export function saveFavourate(projectGuid, isDeleted) {
//     return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/mine/saveFavourate`, { projectGuid, isDeleted })
// }
export async function saveFavourate(projectGuid, isDeleted) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/saveFavourate`, { projectGuid, isDeleted })
    // return getRequestData(res)
}

//保存购买
// export function saveOwnProject(projectGuid, releaseGuid, isDeleted) {
//     if (releaseGuid) {
//         return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/mine/saveOwnProject`, { releaseGuid, isDeleted })
//     } else {
//         return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/mine/saveOwnProject`, { projectGuid, isDeleted })
//     }

// }
export async function saveOwnProject(projectGuid, releaseGuid, isDeleted) {
    if (releaseGuid) {
        return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/saveOwnProject`, { releaseGuid, isDeleted })
        // return getRequestData(res);
    } else {
        return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/saveOwnProject`, { projectGuid, isDeleted })
        // return getRequestData(res);
    }

}

//保存点赞
// export function saveLikeProject(projectGuid, isDeleted) {
//     return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/mine/saveLikeProject`, { projectGuid, isDeleted })
// }
export async function saveLikeProject(projectGuid, isDeleted) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/saveLikeProject`, { projectGuid, isDeleted })
    // return getRequestData(res)
}

//增加预览次数
// export function addPreviewCount(projectGuid) {
//     return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/mine/addPreviewCount`, { projectGuid })
// }
export async function addPreviewCount(projectGuid) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/addPreviewCount`, { projectGuid })
    // return getRequestData(res)
}

//增加转发次数
// export function addForwardCount(projectGuid) {
//     return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/mine/addForwardCount`, { projectGuid })
// }
export async function addForwardCount(projectGuid) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/addForwardCount`, { projectGuid })
    // return getRequestData(res)
}

//提交审核
export function auditProject(projectGuid, isPublic, content) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/auditProject`, { projectGuid, isPublic, content })
}


