<template>
  <div class="createProjectWarp">
    <div class="form-container">
      <el-form
        ref="editForm"
        class="createProjectForm"
        :model="projectInfo"
        :rules="rules"
        label-position="top"
      >
        <!-- 名称 -->
        <el-form-item
          :label="$t('createProject.projectName')"
          prop="projectName"
        >
          <el-input
            size="small"
            ref="focusedItem"
            v-model="projectInfo.projectName"
            :placeholder="$t('createProject.projectName')"
          ></el-input>
        </el-form-item>
        <!-- 是否允许离线 -->
        <el-form-item
          :label="$t('createProject.enableOfflineMode')"
          v-if="selectType === 0"
        >
          <div class="offlineModeWrap">
            <el-checkbox v-model="projectInfo.enableOfflineMode"></el-checkbox>
            <!-- 允许用户下载到本地离线使用 -->
            <span class="checkboxLabel">{{
              $t("createProject.enableOfflineModeTip")
            }}</span>
          </div>
        </el-form-item>
        <!-- 选择项目类型 -->
        <el-form-item :label="$t('createProject.categoryId')" prop="categoryId">
          <!-- 选择项目类型 -->
          <el-select
            size="small"
            v-model="projectInfo.categoryId"
            :placeholder="$t('createProject.categoryId')"
            popper-class="selectWarp"
          >
            <el-option
              v-for="item in categories"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              class="provinces_select"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 选择项目语音 -->
        <el-form-item
          :label="$t('createProject.projectLanguage')"
          prop="categoryId"
        >
          <!-- 选择项目语音 -->
          <el-select
            size="small"
            v-model="projectInfo.language"
            :placeholder="$t('createProject.projectLanguage')"
            popper-class="selectWarp"
          >
            <el-option
              v-for="item in projectLanguage"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              class="provinces_select"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="positionMethods cover">
          <!-- 封面 -->
          <span>{{ $t("createProject.cover") }}</span>

          <el-upload
            class="change-btn"
            action=""
            accept=".jpg,.jpeg,.png"
            :show-file-list="false"
            :multiple="false"
            :auto-upload="false"
            :on-change="onUploadChange"
          >
            <img src="@/assets/task-detail/addFloder.png" alt="" />
          </el-upload>
        </div>
        <div class="uploadedModel">
          <div class="uploadLoadBody">
            <!-- <img :src="coverImage" alt="" /> -->
            <el-image
              v-loading="compressImgLoading"
              ref="compressImg"
              style="width: 100%; height: 100%"
              :src="coverImage"
              :preview-src-list="srcList"
              fit="contain"
              @load="compressImgLoaded($event)"
            >
              <template #error>
                <div>
                  <div class="image-slot">
                    <img src="@/assets/task-detail/defaultPicture.png" alt="" />
                    <!-- 点击上传 -->
                    <p class="clickUpload">
                      <el-upload
                        class="change-btn"
                        action=""
                        accept=".jpg,.jpeg,.png"
                        :show-file-list="false"
                        :multiple="false"
                        :auto-upload="false"
                        :on-change="onUploadChange"
                      >
                        <span>{{ $t("projectInfo.clickUpload") }}</span>
                      </el-upload>
                    </p>
                    <!-- 上传封面 -->
                    <p>{{ $t("projectInfo.noCover") }}</p>
                  </div>
                </div>
              </template>
            </el-image>
          </div>
        </div>
        <div></div>
        <div class="positionMethods" v-if="selectType === 0">
          <!-- 定位方式 -->
          <span>{{ $t("createProject.positionMethods") }}</span>
          <!-- <img src="@/assets/task-detail/help.png" alt="" /> -->
        </div>
        <div class="seleter" v-if="selectType === 0">
          <!-- 二维码定位 -->
          <!-- aoa -->
          <el-select
            size="small"
            v-model="enableObjectAnchorModel"
            popper-class="selfClass"
            disabled
          >
            <!-- @change="enableObjectAnchorModelChange" -->
            <el-option
              v-for="item in positionType"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
              class="provinces_select"
            >
            </el-option>
          </el-select>
          <!-- aoa -->
          <!-- <el-button type="warning"
            ><img src="@/assets/task-detail/printer.png" alt=""
          /></el-button> -->
        </div>
        <div class="assetHead cover" v-if="selectType === 0">
          <!-- 基础模型  必选 可选-->
          <span
            >{{ $t("createProject.baseModel") }}({{
              enableObjectAnchorModel
                ? $t("createProject.noSelect")
                : $t("createProject.canSelect")
            }})</span
          >
          <!-- <img
            @click="uploadModel"
            src="@/assets/task-detail/addFloder.png"
            alt=""
          /> -->
          <!-- <el-upload
            class="change-btn"
            action=""
            accept=".fbx,.obj,.glb,.gltf,.stl"
            :show-file-list="false"
            :multiple="false"
            :auto-upload="false"
            :on-change="onUploadMoldelChange"
          >
            <img src="@/assets/task-detail/addFloder.png" alt="" />
          </el-upload> -->
          <img
            src="@/assets/task-detail/addFloder.png"
            @click="setModelInfo"
            alt=""
          />
          <!-- <ModelViewer /> -->
        </div>
        <div class="uploadedModel" v-if="selectType === 0">
          <div class="uploadLoadBody">
            <el-image
              style="width: 100%; height: 100%"
              :src="preViewModelCover"
              :preview-src-list="srcList"
              fit="contain"
            >
              <template #error>
                <div>
                  <div class="image-slot">
                    <img src="@/assets/task-detail/noModel.png" alt="" />
                    <!-- 点击上传 -->
                    <p class="clickUpload">
                      <span @click="setModelInfo">{{
                        $t("projectInfo.clickUpload")
                      }}</span>
                    </p>
                    <!-- 上传基础模型 -->
                    <p v-if="!baseModelFile">
                      {{ $t("projectInfo.word2") }}
                    </p>
                    <p v-if="baseModelFile && !preViewModelCover">
                      <!-- (已有模型)请生成模型封面 -->
                      {{ $t("createProject.hasModel") }}
                    </p>
                  </div>
                </div>
              </template>
            </el-image>
            <!-- <div class="modelWarp"></div> -->
            <!-- <div class="words">上传基础模型</div> -->
          </div>
        </div>
        <div
          class="assetHead cover"
          v-if="!enableObjectAnchorModel && selectType === 0"
        >
          <!-- 二维码物体的相对位置照片/视频 -->
          <span>{{ $t("createProject.tip1") }}</span>
          <div class="icons">
            <img
              v-if="qrcodePreviewUrl"
              class="deleteIcon"
              src="@/assets/task-detail/delete.png"
              alt=""
              @click="deleteQRInfo"
            />
            <el-upload
              class="change-btn"
              action=""
              accept=".jpg, .png, .jpeg"
              :show-file-list="false"
              :multiple="false"
              :auto-upload="false"
              :on-change="onChangeQRInfo"
            >
              <img src="@/assets/task-detail/addFloder.png" alt="" />
            </el-upload>
          </div>
        </div>
        <div
          class="uploadedModel"
          v-if="!enableObjectAnchorModel && selectType === 0"
        >
          <div class="uploadLoadBody">
            <el-image
              style="width: 100%; height: 100%"
              :src="qrcodePreviewUrl"
              :preview-src-list="srcList"
              fit="contain"
            >
              <template #error>
                <div>
                  <div class="image-slot">
                    <img src="@/assets/task-detail/noModel.png" alt="" />
                    <!-- 上传参照图/视频 -->
                    <p class="clickUpload">
                      <el-upload
                        class="change-btn"
                        action=""
                        accept=".jpg, .png, jpeg"
                        :show-file-list="false"
                        :multiple="false"
                        :auto-upload="false"
                        :on-change="onChangeQRInfo"
                      >
                        <span>{{ $t("projectInfo.clickUpload") }}</span>
                      </el-upload>
                    </p>
                    <p>{{ $t("projectInfo.word4") }}</p>
                  </div>
                </div>
              </template>
            </el-image>
            <!-- <div class="modelWarp"></div> -->
            <!-- <div class="words">上传基础模型</div> -->
          </div>
        </div>

        <!-- aoa -->
        <!-- <el-form-item
          label="模型使用尺寸"
          prop="modelSize"
          v-if="enableObjectAnchorModel"
        >
          <el-select
            size="small"
            v-model="projectInfo.baseModelUnit"
            placeholder="模型使用尺寸"
            popper-class="selectWarp"
          >
            <el-option
              v-for="item in baseModelUnitList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              class="provinces_select"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="模型重力方向"
          prop="modelG"
          v-if="enableObjectAnchorModel"
        >
          <el-select
            size="small"
            v-model="selectKey"
            placeholder="模型重力方向"
            popper-class="selectWarp"
            @change="selectChange"
          >
            <el-option
              v-for="item in baseModelGravityList"
              :key="item.key"
              :label="item.label"
              :value="item.key"
              class="provinces_select"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- aoa -->
      </el-form>
    </div>
    <div class="buttonWarp">
      <!-- 取消 -->
      <button @click="cancel">{{ $t("createProject.cancel") }}</button>
      <!-- 确定 -->
      <button type="primary" @click="submit('editForm')">
        {{ $t("createProject.confirm") }}
      </button>
    </div>
    <ModelLoader ref="modelLoader" @getThumbnail="getThumbnail" />
    <ModelSetter
      ref="modelSetter"
      @getPositionInfo="getPositionInfo"
      :projectInfo="projectInfo"
      :baseModelTransform="baseModelTransformStr"
    />
    <video ref="videoContainer" class="videoContainer" :src="videoSrc"></video>
    <canvas ref="canvas" class="videoCanvas"></canvas>
    <canvas ref="compress" class="compress"></canvas>
  </div>
</template>

<script>
import { getBlobReadSas, uploadAsset } from "@/utils/upload";
import { uuid } from "vue-uuid";
import { ElMessage } from "element-plus";
import { removeTaskGuid } from "@/utils/setTaskGuid";
// import ModelViewer from "@/views/modelViewer/modelViewer.vue";
import ModelLoader from "@/views/components/modelLoader/modelLoader.vue";
import ModelSetter from "@/views/components/modelInfoSetter/modelInfoSetter.vue";
import { dataURLtoBlob } from "@/utils/dataURLtoBlob";
import { getLanguage } from "@/utils/getLanguage.js";
import {
  getUploadSas,
  saveProject,
  saveTask,
  getAllCategories,
  saveProjectModelPosition,
} from "@/api/workflow";
import { BlockBlobClient, AnonymousCredential } from "@azure/storage-blob";
import {
  baseModelGravityList,
  positionType,
  baseModelUnitList,
  fileMaxSize,
  videoMaxSize,
  projectLanguage,
} from "@/utils/asset";
import {
  compressImg,
  dataURLtoFile,
  convertBase64UrlToBlob,
} from "@/utils/compress.js";
import { mapGetters } from "vuex/dist/vuex.cjs.js";
export default {
  components: { ModelLoader, ModelSetter },
  props: ["selectType"],
  data() {
    return {
      tip5: "",
      compressImgLoading: false,
      videoSrc: null,
      categories: null,
      preViewModelCover: null,
      coverImage: null,
      coverImageFile: null, //暫存封面文件
      coverImagePath: null,
      baseModelFile: null, //暂存模型文件
      baseModelPath: null,

      qrcodePreviewUrl: null, //预览图
      qrcodeDescMediaPath: null, //path
      descMediaPath: null, //文件

      baseModelUnitList: baseModelUnitList,
      rules: {
        projectName: [{ required: true, message: "", trigger: "blur" }],
        categoryId: [{ required: true, message: "", trigger: "blur" }],
        modelSize: [{ required: true, message: "", trigger: "blur" }],
        modelG: [{ required: true, message: "", trigger: "blur" }],
      },
      projectInfo: {
        projectGuid: uuid.v4(),
        projectName: "",
        description: "",
        modelDescription: "",
        categoryId: 1,
        baseModelUnit: 1,
        enableOfflineMode: false,
        language: "en", //第一优先为英文
      },
      enableObjectAnchorModel: false,
      positionType: positionType,
      baseModelGravityList: baseModelGravityList,
      selectKey: 4,
      oaoGravity: { x: 0, y: -1, z: 0 },
      thumbnailDatas: {},
      baseModelTransform: null,
      baseModelTransformStr: null,
      projectLanguage: [...projectLanguage],
    };
  },
  computed: {
    ...mapGetters(['language'])
  },
  async created() {
    this.tip5 = this.$t("createProject.tip5");
    console.log(baseModelGravityList, positionType, baseModelUnitList);

    const categories = await getAllCategories(this.language);
    this.categories = categories.map((x) => {
      return { value: x.categoryId, label: x.categoryName };
    });
    // this.projectInfo.language = this.detectLanguage();
    // console.log(this.detectLanguage());
  },
  mounted() {},
  watch: {},
  methods: {
    //根据屏幕上方选的语言类型设置创建新项目的语言
    getInitLanguage() {
      if (this.language === "zh") {
        return "cn";
      } else {
        return "en";
      }
    },
    //根据电脑的语言环境判断当前语言
    // detectLanguage() {
    //   const language = navigator.language.toLowerCase();
    //   console.log(language);
    //   if (language == "zh") {
    //     return "cn";
    //   } else {
    //     return "en";
    //   }
    // },
    async compressImgLoaded(e) {
      console.log(e);
      const imageCanvas = this.$refs.compress;

      let maxSize = 640;

      let maxWidth = maxSize;
      let height;
      let scales = 16 / 9;
      if (
        this.$refs.compressImg.imgWidth > maxSize ||
        this.$refs.compressImg.imgHeight > maxSize
      ) {
        if (
          this.$refs.compressImg.imgWidth > this.$refs.compressImg.imgHeight
        ) {
          scales =
            this.$refs.compressImg.imgHeight / this.$refs.compressImg.imgWidth;
          height = maxWidth * scales;
        } else if (
          this.$refs.compressImg.imgHeight > this.$refs.compressImg.imgWidth
        ) {
          height = maxSize;
          scales =
            this.$refs.compressImg.imgWidth / this.$refs.compressImg.imgHeight;
          maxWidth = height * scales;
        }
      } else {
        maxWidth = this.$refs.compressImg.imgWidth;
        height = this.$refs.compressImg.imgHeight;
      }

      const imageSize = {
        width: maxWidth,
        height: height,
      };
      imageCanvas.height = imageSize.height;
      imageCanvas.width = imageSize.width;
      const imageContext = imageCanvas.getContext("2d");

      imageContext.drawImage(
        // e.path[0],
        this.$refs.compressImg.container.children[0],
        0,
        0,
        imageSize.width,
        imageSize.height
      );
      this.compressImgLoading = true;
      const aaa = await this.getBlobFromCanvas(imageCanvas);
      console.log(aaa);
      let result = await uploadAsset(aaa);
      // let options = {
      //   projectGuid: this.projectInfo.projectGuid,
      //   coverImagePath: result.path,
      // };
      //不能回填url，否则会一直循环像后端传输图片
      // this.coverImage = result.sas;
      this.coverImagePath = result.path;
      this.compressImgLoading = false;
      // await saveProject(options);
      // await this.loadProject();
      // this.$store.commit("SET_PROJECTINFO", true);
    },
    enableObjectAnchorModelChange() {
      this.setModelInfo();
    },
    setModelInfo() {
      let source = {
        baseModelFile: this.baseModelFile,
        baseModelPath: this.projectInfo.baseModelPath,
        modelCoverUrl: this.preViewModelCover,
        modelCoverPath: this.projectInfo.baseModelPreviewPath,
        QRCodePositionUrl: this.qrcodePreviewUrl,
        QRCodePositionPath: this.qrcodeDescMediaPath,
      };
      this.$refs.modelSetter.setShow(
        true,
        "localUrl",
        this.baseModelFile ? true : false,
        this.enableObjectAnchorModel,
        source
      );
    },
    async getPositionInfo(data) {
      console.log(data);
      if (data.modelNeedSave) {
        if (data.baseModelFile) {
          this.baseModelFile = data.baseModelFile;
          this.baseModelPath = await this.uploadStageFile(data.baseModelFile);
        } else {
          // console.log("没有模型文件");
          this.baseModelFile = null;
          this.baseModelPath = "";
        }
      }
      if (data.modelCoverNeedSave) {
        this.thumbnailDatas = {
          path: data.modelCoverPath,
          sas: data.modelCoverUrl,
        }; //模型缩略图path
      }
      if (data.QRCodeCoverNeedSave) {
        this.qrcodeDescMediaPath = data.QRCodePositionPath; //path
        this.descMediaPath = data.QRCodePositionPath; //文件path
      }
      let result = this.baseModelGravityList.find((value, index) => {
        return value.key === data.baseModelGravityKey;
      });
      this.oaoGravity = result;
      this.enableObjectAnchorModel = data.enableObjectAnchorModel;
      this.baseModelUnit = data.baseModelUnit;
      // this.baseModelPath = data.baseModelPath; //模型文件path
      this.preViewModelCover = data.modelCoverUrl; //模型缩略图url
      this.baseModelTransform = data.SaveTransformInfo; // 模型相对位置数据
      this.baseModelTransformStr = data.SaveTransformInfoStr;
      this.qrcodePreviewUrl = data.QRCodePositionUrl; //预览图
    },
    deleteQRInfo() {
      this.qrcodePreviewUrl = null;
      // imageCanvas.width = 0;
      this.qrcodeDescMediaPath = null;
      this.descMediaPath = null;
    },
    async onChangeQRInfo(f) {
      console.log(f.raw);
      this.QRCodeInfoLoading = true;
      let maxSize = fileMaxSize;
      let fileTypeTemp = this.getFileTypeByFileName(f.raw.name);
      if (fileTypeTemp !== "video" && f.raw.size > maxSize) {
        //文件 最大20MB
        ElMessage.error(this.$t("uploadAsset.sizeError"));
        return;
      }
      switch (fileTypeTemp) {
        case "model":
          // 代码块
          break;
        case "picture":
          console.log("picture");
          let file = await compressImg(f.raw, 0.95);
          let fileThumbnail = await compressImg(f.raw, 0.8);
          this.qrcodePreviewUrl = await this.getBase64(fileThumbnail.blob);
          this.descMediaPath = await this.uploadStageFile(fileThumbnail.blob);
          this.qrcodeDescMediaPath = await this.uploadStageFile(file.blob);
          break;
        // case "video":
        //   // this.$refs.videoView.setSwitch(true, item.fileUrl);
        //   this.videoSrc = URL.createObjectURL(f.raw);
        //   console.log(this.videoSrc);
        //   this.$nextTick(() => {
        //     let video = this.$refs.videoContainer;
        //     video.currentTime = 1;
        //     video.addEventListener("loadeddata", async (e) => {
        //       //加载完成事件，调用函数
        //       this.$refs.canvas.width = video.offsetWidth;
        //       this.$refs.canvas.height = video.offsetHeight;
        //       this.$refs.canvas
        //         .getContext("2d")
        //         .drawImage(video, 0, 0, video.offsetWidth, video.offsetHeight);
        //       const aaa = await this.getBlobFromCanvas(this.$refs.canvas);
        //       aaa.name = "aaa.jpg";
        //       this.qrcodePreviewUrl = await this.getBase64(aaa);
        //       // imageCanvas.width = 0;
        //       this.qrcodeDescMediaPath = await this.uploadStageFile(aaa);
        //       this.descMediaPath = await this.uploadStageFile(f.raw);

        //       // this.descMediaPath = QRCodeFile
        //       // let options = {
        //       //   projectGuid: this.projectInfo.projectGuid,
        //       //   qrcodeDescMediaPath: QRCodeThumbnail,
        //       //   descMediaPath: QRCodeFile,
        //       // };
        //       // console.log(options);

        //       // console.log(aaa);
        //       // this.$store.commit("SET_PROJECTINFO", true);
        //     });
        //     // }
        //   });
        //   // 代码块
        //   break;
        case "PDF":
          break;
        default:
          this.QRCodeInfoLoading = false;
          ElMessage.error("请检查文件格式，需图片");
      }
      // this.projectInfo.coverImageUrl = URL.createObjectURL(f.raw);
      // let qrcodeDescMediaPath = await this.uploadStageFile(f.raw);
      // let options = {
      //   projectGuid: this.projectInfo.projectGuid,
      //   qrcodeDescMediaPath: qrcodeDescMediaPath,
      // };
      // let tempProjectInfo = { ...options };
      // await saveProject(tempProjectInfo);
      // // this.$store.state.TaskDetail.showProjectInfo
      // this.$store.commit("SET_PROJECTINFO", true);
    },
    async getBlobFromCanvas(imageCanvas) {
      return new Promise((res, rej) => {
        imageCanvas.toBlob((blob) => {
          res(blob);
        });
      });
    },
    selectChange(key) {
      this.selectKey = key;
      // console.log(value,value2);
      let result = this.baseModelGravityList.find((value, index) => {
        return value.key === key;
      });
      this.oaoGravity = result.value;
      console.log(this.oaoGravity);
    },
    getThumbnail(data) {
      console.log(data);
      this.thumbnailDatas = data;
      this.preViewModelCover = data.value.sas;
      // console.log(dataURLtoBlob(this.preViewModelCover));
    },
    onUploadMoldelChange(f, fileList) {
      console.log(f);
      let urlData = URL.createObjectURL(f.raw);
      this.baseModelFile = f.raw;
      let data = {
        bool: true,
        type: "localUrl",
        fileName: f.raw.name,
        fileData: urlData,
      };
      this.$refs.modelLoader.setShow(true, "localUrl", data);
    },
    async onUploadChange(f, fileList) {
      console.log("file f:", f);
      let file = await compressImg(f.raw, 0.95);
      // this.coverImage = await this.getBase64(f.raw);
      this.coverImage = file.base64;
      // this.coverImageFile = f.raw;
      // this.coverImagePath = await this.uploadStageFile(f.raw);
      // console.log(this.coverImage);
      // console.log(dataURLtoBlob(this.coverImage));
      // this.coverImage = URL.createObjectURL(f.raw);
      // console.log(this.coverImage);
    },
    getBase64(file) {
      return new Promise(function(resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function() {
          imgResult = reader.result;
        };
        reader.onerror = function(error) {
          reject(error);
        };
        reader.onloadend = function() {
          resolve(imgResult);
        };
      });
    },
    cancel() {
      this.$emit("closeCreate");
    },
    async submit(formName) {
      let that = this;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (!this.projectInfo.projectName) {
            return;
          }
          const newProject = {
            language: this.projectInfo.language,
            projectName: this.projectInfo.projectName,
            description: this.projectInfo.description,
            categoryId: this.projectInfo.categoryId,
            enableObjectAnchorModel: this.enableObjectAnchorModel,
            // baseModelGravity: this.oaoGravity,
            // baseModelUnit: this.projectInfo.baseModelUnit,
            enableOfflineMode: this.projectInfo.enableOfflineMode,
            projectGuid: this.projectInfo.projectGuid,
            coverImagePath: this.coverImagePath ? this.coverImagePath : null,
            baseModelPath: this.baseModelPath ? this.baseModelPath : null,
            qrcodeDescMediaPath: this.qrcodeDescMediaPath
              ? this.qrcodeDescMediaPath
              : null,
            descMediaPath: this.descMediaPath ? this.descMediaPath : null,
            editType: this.selectType,
          };
          // if (this.coverImagePath)
          // newProject.coverImagePath = this.coverImagePath;
          // console.log("1");
          // if (this.baseModelPath) {
          // newProject.baseModelPath = this.baseModelPath;
          if (this.enableObjectAnchorModel) {
            newProject.baseModelGravity = this.oaoGravity;
            newProject.baseModelUnit = this.projectInfo.baseModelUnit;
          }
          // } else if (!this.baseModelPath) {
          if (this.enableObjectAnchorModel) {
            // 请上传'基础模型
            ElMessage.error(that.$t("createProject.tip2"));
            return;
          }
          // }
          // if (this.enableObjectAnchorModel) {
          //   if (this.baseModelFile) {
          //     newProject.baseModelPath = await this.uploadStageFile(
          //       this.baseModelFile
          //     );
          //   } else {
          //     ElMessage.error("请上传'基础模型'");
          //     return;
          //   }
          // }

          // if (this.preViewModelCover) {
          //   const previewModelFile = dataURLtoBlob(this.preViewModelCover);
          //   previewModelFile.name = "aa.png";
          //   newProject.baseModelPreviewPath = await this.uploadStageFile(
          //     previewModelFile
          //   );
          // }
          if (this.thumbnailDatas && this.thumbnailDatas.path) {
            newProject.baseModelPreviewPath = this.thumbnailDatas.path;
          }

          let project = await saveProject(newProject);
          console.log(project);
          // try {
          console.log(this.baseModelTransform);

          // } catch (e) {
          // ElMessage.error("保存位置信息失败");
          // }

          console.log("save ok");
          // 缺省任务

          await this.createTask(
            that.$t("createProject.tip3"),
            this.projectInfo.projectGuid
          );
          removeTaskGuid();
          console.log("add task ok");
          this.$emit("closeCreate");
          if (this.baseModelTransform) {
            try {
              this.baseModelTransform.projectGuid = project.projectGuid;
              await saveProjectModelPosition(this.baseModelTransform);
            } catch (e) {
              console.log(e);
              // 位置信息保存失败
              ElMessage.error(that.$t("createProject.tip4"));
            }
          }
          ElMessage.success({
            // 创建成功！
            message: this.tip5,
            type: "success",
          });
          this.$router.push(`/taskDetail?projectGuid=${project.projectGuid}`);
        }
        // else {
        //   console.log("error submit!!");
        //   return false;
        // }
      });
    },

    async uploadStageFile(file) {
      if (!file) {
        return "";
      }
      try {
        const { sas, path } = await getUploadSas(
          file.name || `${uuid.v4()}.png`
        );

        const blobClient = new BlockBlobClient(sas, new AnonymousCredential());
        await blobClient.uploadData(file, {
          blockSize: 512 * 1024,
          concurrency: 2,
        });

        // await saveMyProfilePhoto(path)
        return path;
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async createTask(name, projectGuid) {
      // return new Promise(async (resolve, reject) => {
      let options = {
        taskName: name,
        isDeleted: false,
        projectGuid: projectGuid,
        taskGuid: uuid.v4(),
        description: "",
      };
      try {
        await saveTask(options);
      } catch (e) {
        // 缺省任务创建失败，请手动创建
        ElMessage.error(this.$t("createProject.tip6"));
        Promise.reject(e);
      }
      // });
    },
    getFileTypeByFileName(fileName) {
      let modelTypes = [".fbx", ".obj", ".glb", ".gltf", ".stl"];
      let pictureTypes = [".jpg", ".jpeg", ".png", ".tif", ".bmp"];
      let videoTypes = [
        ".mp4",
        ".rmvb",
        ".rm",
        ".asf",
        ".divx",
        ".mpg",
        ".mpeg",
        ".wmv",
        ".mkv",
        ".vob",
      ];
      let PDFTypes = ["PDF", "pdf"];
      let type = false;
      modelTypes.forEach((value, index) => {
        if (fileName.includes(value)) {
          type = "model";
        }
      });
      pictureTypes.forEach((value, index) => {
        if (fileName.includes(value)) {
          type = "picture";
        }
      });
      videoTypes.forEach((value, index) => {
        if (fileName.includes(value)) {
          type = "video";
        }
      });
      PDFTypes.forEach((value, index) => {
        if (fileName.includes(value)) {
          type = "PDF";
        }
      });
      return type;
    },
  },
  //   components: { ModelViewer },
};
</script>

<style scoped lang="scss">
.form-container {
  max-height: 50vh;
  overflow: auto;
}
.form-container::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
.form-container::-webkit-scrollbar-corner {
  background-color: transparent;
}
.form-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  background-clip: padding-box;
  border: solid transparent;
  border-radius: 10px;
}
.form-container::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid transparent;
  border-width: 1px;
}
:deep(.el-form-item) {
  margin-bottom: 15px;
  text-align: left;
  .el-form-item__label {
    padding-bottom: 0;
    color: #224b77;
    line-height: 28px;
  }
  .el-form-item__content {
    .el-select {
      width: 100%;
    }
  }
}
.provinces_select {
  padding: 6px 6px 6px 10px;
  height: auto;
  line-height: 22px;
  margin: 6px 10px;
  border-radius: 4px;
}
.form-container {
  border-bottom: 1px solid #ccc;
  padding: 0 3rem 1.5rem 3rem;
}
:deep(.selfClass) {
  .el-select-dropdown__list {
    padding: 0.5rem;
  }
}
// ::v-deep .el-select-dropdown__list {
//   padding: 0.5rem;
// }
.buttonWarp {
  margin-top: 1.5rem;
  text-align: right;
  > button {
    border: none;
    outline: none;
    cursor: pointer;
    width: 8rem;
    height: 2rem;
    // padding: 0.5rem  1.5rem;
    color: #fff;
    border-radius: 0.3rem;
  }
  > button:nth-child(1) {
    background: #a4b2c9;
    margin-right: 1.5rem;
    transition: background 0.1s;
  }
  > button:nth-child(1):hover {
    background: #b8c2d2;
  }
  > button:nth-child(2) {
    background: #4582ff;
    transition: background 0.1s;
  }
  > button:nth-child(2):hover {
    background: #8fb5ff;
  }
}
.assetHead {
  > img {
    cursor: pointer;
  }
}

.uploadLoadBody {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8f9fb;
  > .el-image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    :deep(.el-image__inner) {
      cursor: pointer;
    }
    > div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      > .image-slot {
        width: 100%;
        height: 100%;
        > p {
          margin: 0;
          position: relative;
          top: -15px;
          color: rgba($color: #000000, $alpha: 0.25);
          font-size: 14px;
          padding: 0 7.5%;
          line-height: 22px;
        }
        > .clickUpload {
          color: #4582ff;
          font-size: 14px;
          position: relative;
          top: -23px;
          cursor: pointer;
        }
        > img {
          width: 160px;
          height: 90px;
        }
      }
    }
  }
}
.uploadedModel {
  margin-bottom: 1rem;
}
.seleter {
  width: 100%;
  margin-top: 1rem;
  > .el-select {
    width: 100%;
  }
}
.positionMethods {
  color: #224b77;
  font-weight: 400;
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  > img {
    width: 0.9rem;
    height: 0.9rem;
    margin-left: 0.5rem;
  }
}
.assetHead {
  color: #224b77;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  margin-top: 0.7rem;
  font-weight: bold;
  > img {
    width: 2rem;
    height: 2rem;
  }
}
.cover {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  .icons {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  img {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }
  .deleteIcon {
    cursor: pointer;
    transition: background 0.2s;
    margin-right: 5px;
    border-radius: 2px 2px 2px 2px;
  }
  .deleteIcon:hover {
    background: #ffecec;
  }
  .setIcon {
    cursor: pointer;
    transition: background 0.2s;
    margin-right: 5px;
    border-radius: 2px 2px 2px 2px;
  }
  .setIcon:hover {
    background: #ecf2ff;
  }
  .change-btn {
    cursor: pointer;
    transition: background 0.2s;
    border-radius: 2px 2px 2px 2px;
    height: 1.5rem;
  }
  .change-btn:hover {
    background: #ecf2ff;
  }
}
.uploadedModel,
.uploadedPicture {
  width: 100%;
  // height: 10rem;
  background: #f8f9fb;
  // box-sizing: border-box;
  // padding: 1.5rem 4rem 2.5rem 4rem;
  padding-bottom: 55%;
  position: relative;
  // border: 1px dashed #4582ff;
  border: 1px solid transparent;
  background: linear-gradient(#fff, #fff) padding-box,
    repeating-linear-gradient(
      -45deg,
      #4582ff 0,
      #4582ff 0.4em,
      #fff 0,
      #fff 1em
    );
  .uploadLoadBody {
    background: #f8f9fb;
    // position: relative;
    width: 100%;
    // padding-bottom: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    // padding: 1.5rem 5rem 3.5rem 5rem;
    box-sizing: border-box;
    .modelWarp,
    .pictureWarp {
      width: 100%;
      height: 100%;
      background: #333;
    }
    .words {
      color: #9e9e9e;
      line-height: 2rem;
      font-size: 0.9rem;
    }
  }
}
.el-select-dropdown__item {
  color: #274e78;
}
.el-select-dropdown__item:hover {
  color: #4582ff;
  font-weight: 400;
}
.selected {
  color: #4582ff;
  font-weight: 400;
}
.videoContainer {
  width: 100%;
  visibility: hidden;
  position: absolute;
}
.videoCanvas {
  width: 100%;
  visibility: hidden;
  position: absolute;
  display: none;
}
.compress {
  visibility: hidden;
  position: absolute;
  display: none;
}
.offlineModeWrap {
  background: #f8f9fb;
  color: #222222;
  box-sizing: border-box;
  padding-left: 10px;
  margin-top: 10px;
  .checkboxLabel {
    margin-left: 10px;
    font-size: 14px;
  }
}
</style>
