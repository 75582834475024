<template>
  <div class="headCover">
    <!-- 头像裁剪 -->
    <el-dialog
      v-if="isShow"
      :title="$t('avatar.title')"
      v-model="isShow"
      width="500px"
      @open="opend"
      @close="handleClose"
    >
      <div>
        <!-- <div class="header">
        <h2>Vue CropperJS</h2>
      </div>
      <hr /> -->

        <!-- <input
        ref="input"
        type="file"
        name="image"
        accept="image/*"
        @change="setImage"
      /> -->

        <div class="content">
          <section v-if="!cropImg" class="cropper-area">
            <div class="img-cropper">
              <vue-cropper
                v-show="imgSrc"
                ref="cropper"
                :aspect-ratio="1"
                :imgStyle="{ width: '400px', height: '400px' }"
                :containerStyle="{ width: '400px', height: '400px' }"
                :src="imgSrc"
                :outputSize="0.7"
              />
              <div class="defaultContainer" v-show="!imgSrc">
                <div>
                  <div>
                    <img src="@/assets/task-detail/defaultPicture.png" alt="" />
                  </div>
                  <div>
                    <el-upload
                      class="change-btn"
                      action=""
                      accept=".jpg, .png, jpeg"
                      :show-file-list="false"
                      :multiple="false"
                      :auto-upload="false"
                      :on-change="setImage"
                    >
                      <!-- 选择图片 -->
                      <el-button class="selectPic" size="mini" type="primary">{{
                        $t("avatar.selectPicture")
                      }}</el-button>
                    </el-upload>
                  </div>
                </div>
              </div>
              <!-- preview=".preview" -->
            </div>
            <div class="actions">
              <div>
                <!-- <a href="#" role="button" @click.prevent="zoom(0.2)"> 放大 </a> -->
                <!-- <el-button @click.prevent="zoom(0.2)" size="medium" plain
                ><i class="el-icon-zoom-in"></i
              ></el-button> -->
                <!-- <a href="#" role="button" @click.prevent="zoom(-0.2)"> 缩小 </a> -->
                <!-- <el-button @click.prevent="zoom(-0.2)" size="medium" plain
                ><i class="el-icon-zoom-out"></i
              ></el-button> -->
                <!-- <a href="#" role="button" @click.prevent="rotate(90)"> 旋转90 </a> -->
                <!-- <el-button @click.prevent="rotate(90)" size="medium" plain
                ><i class="el-icon-refresh-right"></i
              ></el-button> -->
                <!-- <a href="#" role="button" @click.prevent="rotate(-90)"> 旋转-90 </a> -->
                <!-- <el-button @click.prevent="rotate(-90)" size="medium" plain
                ><i class="el-icon-refresh-left"></i
              ></el-button> -->
              </div>
              <div>
                <!-- <a href="#" role="button" @click.prevent="move(-10, 0)"> 左移 </a> -->
                <!-- <el-button @click.prevent="move(-10, 0)" size="medium" plain
                ><i class="el-icon-back"></i
              ></el-button> -->
                <!-- <a href="#" role="button" @click.prevent="move(10, 0)"> 右移 </a> -->
                <!-- <el-button @click.prevent="move(10, 0)" size="medium" plain
                ><i class="el-icon-right"></i
              ></el-button> -->
                <!-- <a href="#" role="button" @click.prevent="move(0, -10)"> 上移 </a> -->
                <!-- <el-button @click.prevent="move(0, -10)" size="medium" plain
                ><i class="el-icon-top"></i
              ></el-button> -->
                <!-- <a href="#" role="button" @click.prevent="move(0, 10)"> 下移 </a> -->
                <!-- <el-button @click.prevent="move(0, 10)" size="medium" plain
                ><i class="el-icon-bottom"></i
              ></el-button> -->
              </div>

              <!-- <a ref="flipX" href="#" role="button" @click.prevent="flipX">
              纵向翻转
            </a> -->
              <!-- <el-button size="mini" plain
              ><i @click.prevent="flipX" class="el-icon-zoom-in"></i
            ></el-button> -->
              <!-- <a ref="flipY" href="#" role="button" @click.prevent="flipY">
              横向翻转
            </a> -->
              <!-- <el-button size="mini" plain
              ><i @click.prevent="flipY" class="el-icon-zoom-in"></i
            ></el-button> -->
              <!-- <a href="#" role="button" @click.prevent="cropImage"> 确认 </a> -->
              <div class="bottomBtn">
                <el-upload
                  class="change-btn"
                  action=""
                  accept=".jpg, .png, .jpeg"
                  :show-file-list="false"
                  :multiple="false"
                  :auto-upload="false"
                  :on-change="setImage"
                >
                  <!-- 重选头像 -->
                  <el-button v-if="imgSrc" size="mini" type="primary"
                    ><i class="el-icon-refresh"></i>
                    {{ $t("avatar.reSelect") }}</el-button
                  >
                </el-upload>
                <!-- 确认 -->
                <el-button
                  size="mini"
                  type="success"
                  @click.prevent="cropImage"
                  >{{ $t("avatar.ok") }}</el-button
                >
              </div>

              <!-- <a href="#" role="button" @click.prevent="reset"> 重置 </a> -->
              <!-- <el-button size="mini" @click.prevent="reset" type="warning"
              >重置</el-button
            > -->
              <!-- <a href="#" role="button" @click.prevent="getData"> Get Data </a>
            <a href="#" role="button" @click.prevent="setData"> Set Data </a> -->
              <!-- <a href="#" role="button" @click.prevent="getCropBoxData">
              Get CropBox Data
            </a> -->
              <!-- <a href="#" role="button" @click.prevent="setCropBoxData">
              Set CropBox Data
            </a> -->
              <!-- <a href="#" role="button" @click.prevent="showFileChooser">
              Upload Image
            </a> -->
            </div>

            <!-- <textarea v-model="data" /> -->
          </section>
          <section
            v-if="cropImg"
            :class="cropImg ? 'preview-area animateAction' : 'preview-area'"
          >
            <div class="cropped-image">
              <el-image
                v-if="cropImg"
                ref="compressImg"
                class="preview-image"
                :src="cropImg"
                fit="contain"
                @load="compressImgLoaded($event)"
              >
                <template #error>
                  <div class="image-slot">
                    <img src="@/assets/task-detail/defaultPicture.png" alt="" />
                  </div>
                </template>
              </el-image>
              <!-- <img  :src="cropImg" alt="Cropped Image" /> -->
              <div v-else class="crop-placeholder" />
            </div>
            <div></div>
          </section>
          <div class="backTop" v-if="cropImg">
            <!-- 返回上一步 -->
            <el-button plain size="small" @click="backToCrop">{{
              $t("avatar.back")
            }}</el-button>
            <!-- 确认 -->
            <el-button size="small" type="success" @click.prevent="echoPic">{{
              $t("avatar.ok")
            }}</el-button>
            <!-- <el-button size="small" type="success" @click.prevent="ok"
            >确认</el-button
          > -->
          </div>
        </div>
      </div>
      <!-- <template #footer>
      <span class="dialog-footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="isShow = false">确 定</el-button>
      </span>
    </template> -->
      <canvas ref="compress" class="compress"></canvas>
    </el-dialog>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { getUploadSas, saveMyProfilePhoto } from "@/api/workflow";
const { BlockBlobClient, AnonymousCredential } = require("@azure/storage-blob");
import { dataURLtoBlob } from "@/utils/dataURLtoBlob";
import { ElMessage } from "element-plus";
import { uuid } from "vue-uuid";
export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      isShow: false,
      imgSrc: "",
      cropImg: "",
      data: null,
      imgData: null,
      imgBlob: null,
    };
  },
  created() {},
  methods: {
    async compressImgLoaded(e) {
      console.log(e);
      const imageCanvas = this.$refs.compress;

      let maxSize = 400;

      let maxWidth = maxSize;
      let height;
      let scales = 1;
      if (
        this.$refs.compressImg.imgWidth > maxSize ||
        this.$refs.compressImg.imgHeight > maxSize
      ) {
        maxWidth = maxSize;
        height = maxSize;
      } else {
        maxWidth = this.$refs.compressImg.imgWidth;
        height = this.$refs.compressImg.imgHeight;
      }

      const imageSize = {
        width: maxWidth,
        height: height,
      };
      imageCanvas.height = imageSize.height;
      imageCanvas.width = imageSize.width;
      const imageContext = imageCanvas.getContext("2d");
      // console.log(e);
      console.log(this.$refs.compressImg);
      imageContext.drawImage(
        this.$refs.compressImg.container.children[0],
        // e.path[0],
        0,
        0,
        imageSize.width,
        imageSize.height
      );
      this.imgBlob = await this.getBlobFromCanvas(imageCanvas);
      console.log(this.imgBlob);
    },
    async getBlobFromCanvas(imageCanvas) {
      return new Promise((res, rej) => {
        imageCanvas.toBlob((blob) => {
          res(blob);
        });
      });
    },
    echoPic() {
      console.log(this.imgBlob);
      let temp = this.imgData.name.split(".");
      //   console.log(temp[temp.length - 1]);
      let endName = temp[temp.length - 1];
      // let url = null;
      // const binaryData = [];
      // binaryData.push(this.imgBlob);
      // url = window.URL.createObjectURL(new Blob(binaryData));
      this.$emit("echopic", {
        type: endName,

        data: URL.createObjectURL(this.imgBlob),
        // data: url,
        file: this.imgBlob,
      });
      this.isShow = false;
    },
    async ok() {
      //单独上传
      //   console.log(this.imgData);
      let temp = this.imgData.name.split(".");
      //   console.log(temp[temp.length - 1]);
      let endName = temp[temp.length - 1];
      let blobName = uuid.v4();
      //   console.log(getUploadSas(blobName));
      const { sas, path } = await getUploadSas(blobName + "." + endName);
      const blobClient = new BlockBlobClient(sas, new AnonymousCredential());
      await blobClient.uploadData(dataURLtoBlob(this.cropImg), {
        blockSize: 512 * 1024,
        concurrency: 2,
      });
      await saveMyProfilePhoto(path);
      console.log(dataURLtoBlob(this.cropImg));
      this.isShow = false;
      ElMessage.success({
        // 头像已上传
        message: this.$t("avatar.tip1"),
        type: "success",
      });
      await this.$store.dispatch("getInfo");
    },

    opend() {
      this.imgSrc = null;
      this.cropImg = null;
      this.data = null;
    },
    backToCrop() {
      this.cropImg = null;
    },
    open(bool) {
      this.isShow = bool;
      this.imgSrc = null;
      this.cropImg = null;
      this.data = null;
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      // this.cropImg = this.$refs.cropper.getCroppedCanvas();
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
      this.cropImg = null;
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setImage(f) {
      console.log(f);
      this.imgData = f;
      const file = f.raw;
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      // let blob = new Blob(Array,options)
      this.imgSrc = URL.createObjectURL(file);
      console.log(this.imgSrc);
      // if (typeof FileReader === "function") {
      //   const reader = new FileReader();
      //   reader.onload = (event) => {
      //     this.imgSrc = event.target.result;
      //     // rebuild cropperjs with the updated source
      this.$refs.cropper.replace(this.imgSrc);
      //   };
      //   reader.readAsDataURL(file);
      // } else {
      //   alert("Sorry, FileReader API not supported");
      // }
    },
    // showFileChooser() {
    //   this.$refs.input.click();
    // },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
body {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  margin: 0 auto;
}
input[type="file"] {
  display: none;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 5px 0;
}
.header h2 {
  margin: 0;
}
.header a {
  text-decoration: none;
  color: black;
}
.content {
  .preview-area {
    margin-left: 20px;
    .cropped-image {
      width: 100%;
      height: 100%;
      .el-image {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.cropper-area {
  //   width: 614px;

  > .img-cropper {
    margin-left: 20px;

    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: #ccc;
    > div {
      width: 100%;
      > .cropper-hidden {
        width: 400px;
        height: 400px;
      }
    }
    .defaultContainer {
      width: 400px;
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dashed #ccc;
      border-radius: 5px;
    }
  }
}
.actions {
  margin-top: 1rem;
  > div {
    margin-bottom: 1rem;
    button {
      font-size: 1rem;
      margin: 0 0 0 1rem;
    }
  }
}
.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
textarea {
  width: 100%;
  height: 100px;
}
.preview-area {
  width: 400px;
  height: 400px;
  border: 1px solid #4a85ff;
  background: #ccc;
  margin-left: 30px;
  overflow: hidden;
  transition: all 1.5s;
}
.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}
.preview-area p:last-of-type {
  margin-top: 1rem;
}
.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}
.cropped-image img {
  max-width: 100%;
}

.animateAction {
  border-radius: 50%;
  animation-name: borderRadius;
  animation-duration: 1.5s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-delay: 0.5s;
  animation-fill-mode: both;
  img {
    width: 100%;
  }
}
@keyframes borderRadius {
  0% {
    border-radius: 0%;
  }
  100% {
    border-radius: 50%;
  }
}
.backTop {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  text-align: right;
  button {
    width: 120px;
    height: 32px;
    border-radius: 4px;
    transition: background 0.1s;
    border: none;
    padding: 9px 15px;
    min-height: auto;
  }
  > button:nth-child(1) {
    background: #a8b7ca;
    color: #ffffff;
  }
  > button:nth-child(1):hover {
    background: #b8c2d2;
  }
  > button:nth-child(2) {
    background: #4582ff;
    color: #ffffff;
  }
  > button:nth-child(2):hover {
    background: #8fb5ff;
  }
}
.bottomBtn {
  display: flex;
  justify-content: right;
  padding-right: 20px;
  > button {
    width: 120px;
    height: 32px;
    background: #4582ff;
    border-radius: 4px 4px 4px 4px;
    outline: none;
    border: none;
    background: #4582ff;
    color: #fff;
    text-align: center;
    cursor: pointer;
    margin-right: 1.5rem;
    transition: background 0.1s;
  }
  > button:hover {
    background: #8fb5ff;
  }
}
.headCover {
  :deep(.el-dialog) {
    border-radius: 12px;
    > .el-dialog__header {
      padding: 20px 30px 10px;
      text-align: left;
      > .el-dialog__title {
        color: #4582ff;
        text-align: left;
      }
    }
    > .el-dialog__body {
      padding: 20px 30px 10px;
      .compress {
        display: none;
        // position: absolute;
      }
    }
    > .el-dialog__footer {
      > .dialog-footer {
        button {
          width: 120px;
          height: 32px;
          border-radius: 4px;
          transition: background 0.1s;
          border: none;
          padding: 9px 15px;
          min-height: auto;
        }
        > button:nth-child(1) {
          background: #a8b7ca;
          color: #ffffff;
        }
        > button:nth-child(1):hover {
          background: #b8c2d2;
        }
        > button:nth-child(2) {
          background: #4582ff;
          color: #ffffff;
        }
        > button:nth-child(2):hover {
          background: #8fb5ff;
        }
      }
    }
  }
}
.selectPic {
  background: #4582ff;
  border: none;
  transition: background 0.1s;
}
.selectPic:hover {
  background: #8fb5ff;
}
</style>
