<template>
  <div>
    <div class="head">
      <!-- 编辑步骤 -->
      <!-- <div class="main-title">编辑步骤</div> -->
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick"> -->
      <!-- 属性 -->
      <!-- <el-tab-pane :label="$t('projectInfo.title')" name="editStepTag"> -->
      <div v-if="projectInfo" class="form-container">
        <el-form
          ref="editForm"
          :model="projectInfo"
          :rules="rules"
          label-position="top"
        >
          <!-- 名称 -->
          <el-form-item
            class="formItemWarp"
            :label="$t('projectInfo.name')"
            prop="stepName"
          >
            <!-- 名称 -->
            <el-input
              :disabled="!isCreator || auditReleaseMode"
              ref="focusedItem"
              v-model="projectInfo.projectName"
              :placeholder="$t('projectInfo.name')"
              @blur="saveName"
              @focus="nameFocus"
            ></el-input>
          </el-form-item>
          <!-- 描述 -->
          <el-form-item
            class="formItemWarp"
            :label="$t('projectInfo.description')"
            prop="description"
          >
            <!-- 描述 -->
            <el-input
              :disabled="!isCreator || auditReleaseMode"
              v-model="projectInfo.description"
              :placeholder="$t('projectInfo.description')"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 10 }"
              @blur="saveDescription"
              maxlength="500"
              show-word-limit
            ></el-input>
          </el-form-item>
          <!-- 选择项目类型 -->
          <el-form-item
            class="formItemWarp"
            :label="$t('projectInfo.type')"
            prop="categoryId"
          >
            <!-- 选择项目类型 -->
            <el-select
              :disabled="!isCreator || auditReleaseMode"
              v-model="projectInfo.categoryId"
              :placeholder="$t('projectInfo.type')"
              popper-class="selectWarp"
              @change="categoryIdChange"
            >
              <el-option
                v-for="item in categories"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                class="provinces_select"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 选择项目类型 -->
          <el-form-item
            class="formItemWarp"
            :label="$t('projectInfo.projectLanguage')"
            prop="categoryId"
          >
            <!-- 选择项目类型 -->
            <el-select
              :disabled="!isCreator || auditReleaseMode"
              v-model="projectInfo.language"
              :placeholder="$t('projectInfo.projectLanguage')"
              popper-class="selectWarp"
              @change="languageChange"
            >
              <el-option
                v-for="item in projectLanguage"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                class="provinces_select"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 是否允许离线 -->
          <!--  v-if="projectInfo?.editType === 0" -->
          <el-form-item
            :label="$t('projectInfo.enableOfflineMode')"
            class="formItemWarp"
          >
            <div class="offlineModeWrap">
              <el-checkbox
                :disabled="!isCreator || auditReleaseMode"
                @change="enableOfflineModeChange"
                v-model="projectInfo.enableOfflineMode"
              ></el-checkbox>
              <!-- 允许用户下载到本地离线使用 -->
              <span class="checkboxLabel">{{
                $t("projectInfo.enableOfflineModeTip")
              }}</span>
            </div>
          </el-form-item>
          <!-- 素材 -->
          <!-- <el-form-item>
                    <template #label>
                        <div class="assetHead">
                            <span>基础模型(可选)</span>
                            <i class="el-icon-circle-plus-outline"></i>
                        </div>
                    </template> -->

          <!-- 删除 -->
          <!-- <AssetThumbnail :assetList="stepItem.stepAssets" 
                        :itemSize="6" :selectMode="true" :pickTitle="$t('taskDetail.editStep.delete')" 
                        :pickIcon="pickIcon" :nameColor="'#446378'"
                        @onPick="removeAsset" ></AssetThumbnail> -->
          <!-- </el-form-item> -->
          <div class="assetHead">
            <!-- 项目封面 -->
            <span>{{ $t("projectInfo.projectCover") }}</span>
            <div class="icons">
              <img
                v-if="
                  isCreator && projectInfo.coverImageUrl && !auditReleaseMode
                "
                class="deleteIcon"
                src="@/assets/task-detail/delete.png"
                alt=""
                @click="deleteCover"
              />
              <el-upload
                ref="uploadPrice"
                v-if="isCreator && !auditReleaseMode"
                class="change-btn"
                action=""
                accept="image/*"
                :show-file-list="false"
                :multiple="false"
                :auto-upload="false"
                :on-change="onChangeCover"
              >
                <img src="@/assets/task-detail/addFloder.png" alt="" />
              </el-upload>
            </div>
          </div>
          <div class="uploadedModel">
            <div class="uploadLoadBody">
              <div>
                <el-image
                  fit="cover"
                  :src="projectInfo.coverImageUrl"
                  @click="prevViewCoverImageUrl"
                >
                  <template #error>
                    <div>
                      <div class="image-slot">
                        <img
                          src="@/assets/task-detail/defaultPicture.png"
                          alt=""
                        />
                        <!-- 点击上传 -->
                        <p
                          class="clickUpload"
                          v-if="isCreator && !auditReleaseMode"
                        >
                          <el-upload
                            class="change-btn"
                            action=""
                            accept="image/*"
                            :show-file-list="false"
                            :multiple="false"
                            :auto-upload="false"
                            :on-change="onChangeCover"
                          >
                            <span>{{ $t("projectInfo.clickUpload") }}</span>
                          </el-upload>
                        </p>
                        <!-- 无封面 -->
                        <p>{{ $t("projectInfo.noCover") }}</p>
                      </div>
                    </div>
                  </template>
                </el-image>
              </div>
              <!-- <div v-else class="modelWarp"></div> -->
              <!-- <div class="words">上传基础模型</div> -->
            </div>
          </div>

          <div class="positionMethods" v-if="projectInfo?.editType == 0">
            <!-- 定位方式 -->
            <span>{{ $t("projectInfo.positionType") }}</span>
            <!-- <img src="@/assets/task-detail/help.png" alt="" /> -->
          </div>
          <!-- <div style="display: none">
                <QRCode :text="projectInfo.projectGuid" />
              </div> -->
          <div class="seleter" v-if="projectInfo?.editType == 0">
            <!-- 二维码定位 -->
            <!-- aoa -->
            <el-select disabled v-model="projectInfo.enableObjectAnchorModel">
              <!-- @change="positionTypeChange" -->
              <el-option
                v-for="item in positionType"
                :key="item.value"
                :label="$t(item.label)"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- aoa -->
            <!-- '#qrcode-' + projectInfo.projectGuid -->
            <el-dropdown
              trigger="click"
              v-if="projectInfo && !projectInfo.enableObjectAnchorModel"
            >
              <span class="el-dropdown-link">
                <el-button type="warning"
                  ><img src="@/assets/task-detail/printer.png" alt=""
                /></el-button>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-print="printObj1" @click="printType = 0"
                    >Apple Vision Pro</el-dropdown-item
                  >
                  <el-dropdown-item v-print="printObj" @click="printType = 1">{{
                    $t("projectInfo.tip8")
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="assetHead" v-if="projectInfo?.editType == 0">
            <!-- 基础模型 -->
            <span>{{ $t("projectInfo.baseModel") }}</span>
            <div class="icons">
              <!-- <img
                    v-if="isCreator && projectInfo.baseModelPreviewUrl"
                    class="deleteIcon"
                    src="@/assets/task-detail/delete.png"
                    alt=""
                    @click="deleteModel"
                  /> -->
              <img
                v-if="
                  isCreator && projectInfo.baseModelUrl && !auditReleaseMode
                "
                class="setIcon"
                src="@/assets/task-detail/set.png"
                alt=""
                @click="setModelInfo"
              />
              <img
                v-if="
                  isCreator && !projectInfo.baseModelUrl && !auditReleaseMode
                "
                class="setIcon"
                @click="setModelInfo"
                src="@/assets/task-detail/addFloder.png"
                alt=""
              />
              <!-- <el-upload
                    v-if="isCreator && !projectInfo.baseModelPreviewUrl"
                    class="change-btn"
                    action=""
                    accept=".fbx,.obj,.glb,.gltf,.stl"
                    :show-file-list="false"
                    :multiple="false"
                    :auto-upload="false"
                    :on-change="onChangeModel"
                  >
                    <img src="@/assets/task-detail/addFloder.png" alt="" />
                  </el-upload> -->
            </div>
          </div>
          <div class="uploadedModel" v-if="projectInfo?.editType == 0">
            <div class="uploadLoadBody">
              <div>
                <el-image
                  fit="contain"
                  :src="projectInfo.baseModelPreviewUrl"
                  @click="prevViewBaseModel"
                >
                  <template #error>
                    <div>
                      <div class="image-slot">
                        <img src="@/assets/task-detail/noModel.png" alt="" />
                        <!-- 点击上传 -->
                        <p
                          class="clickUpload"
                          v-if="isCreator && !auditReleaseMode"
                        >
                          <span @click="setModelInfo">{{
                            $t("projectInfo.clickUpload")
                          }}</span>
                        </p>
                        <!-- (已有模型)无基础模型封面 -->
                        <!-- 无基础模型 -->
                        <p>
                          {{
                            projectInfo.baseModelUrl
                              ? $t("projectInfo.word1")
                              : $t("projectInfo.word2")
                          }}
                        </p>
                      </div>
                    </div>
                  </template>
                </el-image>
              </div>
              <!-- <div v-else class="modelWarp"></div> -->
              <!-- <div class="words">上传基础模型</div> -->
            </div>
          </div>
          <div
            class="assetHead"
            v-if="
              projectInfo &&
                !projectInfo.enableObjectAnchorModel &&
                projectInfo?.editType == 0
            "
          >
            <!-- 二维码物体的相对位置照片/视频 -->
            <span>{{ $t("projectInfo.word3") }}</span>
            <div class="icons">
              <img
                v-if="
                  isCreator &&
                    projectInfo.qrcodeDescMediaPathUrl &&
                    !auditReleaseMode
                "
                class="deleteIcon"
                src="@/assets/task-detail/delete.png"
                alt=""
                @click="deleteQRInfo"
              />
              <el-upload
                v-if="isCreator && !auditReleaseMode"
                class="change-btn"
                action=""
                accept=".jpg, .png, jpeg"
                :show-file-list="false"
                :multiple="false"
                :auto-upload="false"
                :on-change="onChangeQRInfo"
              >
                <img src="@/assets/task-detail/addFloder.png" alt="" />
              </el-upload>
            </div>
          </div>
          <div
            class="uploadedModel"
            v-if="
              projectInfo &&
                !projectInfo.enableObjectAnchorModel &&
                projectInfo?.editType == 0
            "
          >
            <div class="uploadLoadBody">
              <div>
                <el-image
                  fit="contain"
                  :src="projectInfo.qrcodeDescMediaPathUrl"
                  @click="prevViewQrcodeDesc"
                  v-loading="QRCodeInfoLoading"
                >
                  <template #error>
                    <div>
                      <div class="image-slot">
                        <img
                          src="@/assets/task-detail/defaultPicture.png"
                          alt=""
                        />
                        <!-- 点击上传 -->
                        <p
                          class="clickUpload"
                          v-if="isCreator && !auditReleaseMode"
                        >
                          <el-upload
                            class="change-btn"
                            action=""
                            accept=".jpg, .png, .jpeg"
                            :show-file-list="false"
                            :multiple="false"
                            :auto-upload="false"
                            :on-change="onChangeQRInfo"
                          >
                            <span>{{ $t("projectInfo.clickUpload") }}</span>
                          </el-upload>
                        </p>
                        <!-- 无参照图象 -->
                        <p>{{ $t("projectInfo.word4") }}</p>
                      </div>
                    </div>
                  </template>
                </el-image>
              </div>
              <!-- <div v-else class="modelWarp"></div> -->
              <!-- <div class="words">上传基础模型</div> -->
            </div>
          </div>
          <div
            class="qrcodeTip"
            v-if="
              projectInfo &&
                !projectInfo.enableObjectAnchorModel &&
                projectInfo?.editType == 0
            "
          >
            <!-- 请文字描述二维码张贴的位置与物体的关系 -->
            {{ $t("projectInfo.word5") }}
          </div>
          <div
            class="qrcodeTextarea"
            v-if="
              projectInfo &&
                !projectInfo.enableObjectAnchorModel &&
                projectInfo?.editType == 0
            "
          >
            <!-- 在此描述位置关系 -->
            <el-input
              class="textArea"
              :disabled="!isCreator || auditReleaseMode"
              v-model="projectInfo.qrcodeDescription"
              :placeholder="$t('projectInfo.word6')"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 10 }"
              @blur="saveQRCodeDescription"
              maxlength="500"
              show-word-limit
            ></el-input>
          </div>
          <!-- 模型使用尺寸 -->
          <el-form-item
            :label="$t('projectInfo.word7')"
            prop="modelSize"
            class="formItemWarp"
            v-if="
              projectInfo &&
                projectInfo.enableObjectAnchorModel &&
                projectInfo?.editType == 0
            "
          >
            <!-- 模型使用尺寸 -->
            <el-select
              :disabled="!isCreator || auditReleaseMode"
              size="small"
              v-model="projectInfo.baseModelUnit"
              :placeholder="$t('projectInfo.word7')"
              popper-class="selectWarp"
              @change="positionTypeChange"
            >
              <el-option
                v-for="item in baseModelUnitList"
                :key="item.value"
                :label="$t(item.label)"
                :value="item.value"
                class="provinces_select"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 模型重力方向 -->
          <el-form-item
            class="formItemWarp"
            :label="$t('projectInfo.word8')"
            prop="modelG"
            v-if="
              projectInfo &&
                projectInfo.enableObjectAnchorModel &&
                projectInfo?.editType == 0
            "
          >
            <!-- 模型重力方向 -->
            <el-select
              :disabled="!isCreator || auditReleaseMode"
              size="small"
              v-model="selectKey"
              :placeholder="$t('projectInfo.word8')"
              popper-class="selectWarp"
              @change="positionTypeChange"
            >
              <el-option
                v-for="item in baseModelGravityList"
                :key="item.key"
                :label="$t(item.label)"
                :value="item.key"
                class="provinces_select"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <div class="qrcodeTip2">
                <span>二维码物体的相对位置照片/视频</span>
                <img src="@/assets/task-detail/addFloder.png" alt="" />
              </div>
              <div class="uploadedPicture">
                <div class="uploadLoadBody">
                  <div class="pictureWarp"></div>
                </div>
              </div> -->
          <div class="ctrlProject" v-if="canBuy && !isMyOwn">
            <!-- <button
                  v-if="!own && !isCreator"
                  class="primary"
                  @click.prevent="ownProject"
                  type="primary"
                >
                  <img src="@/assets/task-detail/getOwn.png" alt="" /> 获取
                </button>
                <button v-else class="had" @click.prevent="">已拥有</button> -->
            <!-- <button class="getThis" @click="ownProject()">获取</button>
                <button class="gotThis">已拥有</button> -->
          </div>
          <!-- <el-form-item> 
                    <div class="btn-bar">
                        <el-button size="mini" @click="onSubmit('editForm')" type="primary" plain>确认</el-button>
                        <el-button size="mini" type="info" plain  @click="onReset()">重置</el-button>
                        <el-button size="mini" type="danger" plain @click="onDel()">删除</el-button>
                    </div>
                </el-form-item> -->
        </el-form>

        <!-- <UserPicker ref="UserPicker" :userPickerShow.sync="userPickerShow" @closeUserPicker="onUserPickerClosed"></UserPicker> -->
      </div>
      <!-- </el-tab-pane> -->
      <!-- 素材库 -->
      <!-- <el-tab-pane
          :label="$t('projectInfo.word9')"
          name="assets"
          v-if="isCreator"
        >
          <AssetPicker
            :isCreator="isCreator"
            :projectInfo="projectInfo"
            ref="assetPicker"
          />
        </el-tab-pane> -->
      <!-- </el-tabs> -->
    </div>
    <ModelLoader ref="modelLoader" @getThumbnail="getThumbnail" />
    <ModelSetter
      ref="modelSetter"
      @getPositionInfo="getPositionInfo"
      @getThumbnail="getThumbnailByType"
      :projectInfo="projectInfo"
    />
    <div class="printPosition" ref="printBody" id="printView">
      <PrintReview :projectInfo="projectInfo" :printType="printType" />
    </div>
    <PictureView ref="pictureView" />
    <VideoView ref="videoView" />
    <video ref="videoContainer" class="videoContainer" :src="videoSrc"></video>
    <canvas ref="canvas" class="videoCanvas"></canvas>
    <canvas ref="compress" class="compress"></canvas>
    <el-image
      ref="compressImg"
      class="preview-image"
      :src="compressImgUrl"
      fit="contain"
      @load="compressImgLoaded($event)"
    >
      <template #error>
        <div class="image-slot">
          <img src="@/assets/task-detail/defaultPicture.png" alt="" />
        </div>
      </template>
    </el-image>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import { uuid } from "vue-uuid";
import QRCode from "@/components/qrcode.vue";
import print from "vue3-print-nb";
import { getProjectInfo } from "@/utils/setLocalstorage";
import AssetPicker from "../assetPickerNew/assetPicker.vue";
import { saveOwnProject } from "@/api/mine";

import { compressImg } from "@/utils/compress.js";
import {
  getProjectById,
  getReleaseById,
  getAllCategories,
} from "@/api/workflow";
import {
  saveProject,
  getUploadSas,
  saveProjectModelPosition,
  getProjectQRCode,
} from "@/api/workflow";
import { BlockBlobClient, AnonymousCredential } from "@azure/storage-blob";
import { dataURLtoBlob } from "@/utils/dataURLtoBlob";
import ModelLoader from "@/views/components/modelLoader/modelLoader.vue";
import ModelSetter from "@/views/components/modelInfoSetter/modelInfoSetter.vue";
import PictureView from "@/views/components/pictureView/pictureView.vue";
import VideoView from "@/views/components/videoView/videoView.vue";
import {
  baseModelGravityList,
  positionType,
  baseModelUnitList,
  fileMaxSize,
  videoMaxSize,
  projectLanguage,
} from "@/utils/asset";
import PrintReview from "./printReview/printReview.vue";
import { getLanguage } from "@/utils/getLanguage.js";
import LangManager from "../../../../utils/langManager";
export default {
  components: {
    AssetPicker,
    ModelLoader,
    QRCode,
    PrintReview,
    PictureView,
    VideoView,
    ModelSetter,
  },
  props: ["isCreator", "projectInfo", "own", "auditReleaseMode"],
  directives: {
    print,
  },
  data() {
    return {
      printType: "", //0:打印二维码 1:打印OS码
      compressImgUrl: null,
      printObj: {
        id: "printView", // 这里是要打印元素的ID
        popTitle: "", // 打印的标题
        extraCss: "", // 打印可引入外部的一个 css 文件
        extraHead: "", // 打印头部文字
        // beforeOpenCallback(vue) {
        //   console.log("打开之前");
        //   let dom = document.getElementById("printView");
        //   dom.className = "printPosition printPositionBlock";
        //   console.log(vue);
        // },
        // openCallback(vue) {
        //   console.log("执行了打印");
        // },
        // closeCallback(vue) {
        //   console.log("关闭了打印工具");
        //   let dom = document.getElementById("printView");
        //   dom.className = "printPosition";
        // },
      },
      printObj1: {
        id: "printView", // 这里是要打印元素的ID
        popTitle: "", // 打印的标题
        extraCss: "", // 打印可引入外部的一个 css 文件
        extraHead: "", // 打印头部文字
      },
      categoryId: null,
      projectGuid: "",
      releaseGuid: "",
      releaseMode: true,
      // isCreator: false,
      preViewModelCover: null,
      activeName: "editStepTag",
      projectInfo: null,
      positionType: positionType,
      baseModelGravityList: baseModelGravityList,
      baseModelUnitList: baseModelUnitList,
      categories: null,
      selectKey: null,
      videoSrc: null,
      QRCodeInfoLoading: false,
      nameTemp: "",
      qrcodeSrc: null,
      projectLanguage: [],
      // selectUnitValue: null,
    };
  },
  async created() {
    // const projectInfo = JSON.parse(getProjectInfo());
    this.projectGuid = this.$route.query.projectGuid;
    this.releaseGuid = this.$route.query.releaseGuid;
    this.releaseMode =
      this.$route.query.releaseGuid || this.$route.query.releaseMode;
    this.auditReleaseGuid = this.$route.query.auditReleaseGuid;
    // console.log('create param111', this.$route.query);

    let temp = await this.loadProject();
    // let temp;

    // console.log(this.qrcodeSrc);
    // if (this.$route.query.isRelease === "true") {
    //   if (this.$route.query.releaseGuid !== "*") {
    //     temp = await getReleaseById(this.$route.query.releaseGuid, null);
    //   } else if (this.$route.query.projectGuid !== "*") {
    //     temp = await getReleaseById(null, this.$route.query.projectGuid);
    //   }
    // } else {
    //   temp = await getProjectById(this.$route.query.projectGuid);
    // }
    console.log(temp);
    this.$store.commit("SET_PROJECTTASKRESULT", temp);
    this.projectInfo = temp;
    const categories = await getAllCategories(LangManager.getLanguage().value);
    this.categories = categories.map((x) => {
      return { value: x.categoryId, label: x.categoryName };
    });
    if (this.projectInfo.enableObjectAnchorModel) {
      // if (projectInfo && projectInfo.baseModelUnit) {
      //   let result = this.baseModelUnitList.find((value, index) => {
      //     return value.value === projectInfo.baseModelUnit;
      //   });
      //   this.selectUnitValue = result.value;
      // }
      if (this.projectInfo && this.projectInfo.baseModelGravity) {
        let result = this.baseModelGravityList.find((value, index) => {
          return (
            // JSON.parse(value.value) ===
            // JSON.parse(this.projectInfo.baseModelGravity)
            value.value.x === this.projectInfo.baseModelGravity.x &&
            value.value.y === this.projectInfo.baseModelGravity.y &&
            value.value.z === this.projectInfo.baseModelGravity.z
          );
        });
        this.selectKey = result.key;
      }
    }
    this.projectLanguage = projectLanguage;
    // this.projectInfo = await getProjectById(projectInfo.projectGuid);
  },
  mounted() {},
  unmounted() {
    this.$nextTick(() => {
      //退出页面时要隐藏被打印的元素
      let dom = document.getElementById("printView");
      if (dom) {
        dom.className = "printPosition printPositionNone";
      }
    });
  },
  computed: {
    isMyOwn() {
      return (
        this.projectInfo &&
        this.projectInfo.createdBy &&
        this.$store.state.User &&
        this.projectInfo.createdBy == this.$store.state.User.userGuid
      );
    },
    canBuy() {
      return this.projectInfo && this.projectInfo.releaseGuid;
    },
    // selectKey() {
    //   return this.baseModelGravityList.find((value, index) => {
    //     return value.value == this.projectInfo.baseModelGravity;
    //   });
    // },
  },
  watch: {},
  methods: {
    async getPositionInfo(data) {
      console.log(data);
      let result = this.baseModelGravityList.find((value, index) => {
        return value.key === data.baseModelGravityKey;
      });
      // let pathResult = null;
      // if (data.baseModelPath) {
      //   pathResult = data.baseModelPath;
      // } else {
      //   pathResult = await this.uploadStageFile(data.baseModelFile);
      // }
      // console.log(pathResult);
      let options = {
        enableObjectAnchorModel: data.enableObjectAnchorModel,
        projectGuid: this.projectInfo.projectGuid,
        baseModelGravity: result.value,
        baseModelUnit: data.baseModelUnit,
        // baseModelPath: data.baseModelPath,
        // baseModelPreviewPath: data.modelCoverPath ? data.modelCoverPath : null,//模型封面图
        // qrcodeDescMediaPath: data.QRCodePositionPath
        //   ? data.QRCodePositionPath
        //   : null, //二维码封面图
        // descMediaPath: data.QRCodeFilePath ? data.QRCodeFilePath : null, //二维码文件
      };
      if (data.modelNeedSave) {
        if (data.baseModelFile) {
          console.log("有模型文件");
          let path = await this.uploadStageFile(data.baseModelFile);
          options.baseModelPath = path;
        } else {
          console.log("没有模型文件");
          options.baseModelPath = "";
        }
      }
      if (data.modelCoverNeedSave) {
        options.baseModelPreviewPath = data.modelCoverPath
          ? data.modelCoverPath
          : "";
      }
      if (data.QRCodeCoverNeedSave) {
        options.qrcodeDescMediaPath = data.QRCodePositionPath
          ? data.QRCodePositionPath
          : ""; //二维码封面图
        options.descMediaPath = data.QRCodeFilePath ? data.QRCodeFilePath : ""; //二维码文件
      }
      try {
        await saveProject(options);
        await saveProjectModelPosition(data.SaveTransformInfo);
        await this.loadProject();
      } catch (e) {
        console.log(e);
      }
    },
    getThumbnailByType(data) {
      console.log(data);
    },
    setModelInfo() {
      console.log("setModelInfo", this.projectInfo);

      let options = {
        fileUrl: this.projectInfo.baseModelUrl,
        filePath: this.projectInfo.baseModelPath,
        isDelete: false,
        assetCategory: 1001,
        assetType: 2,
        originFileName: this.projectInfo.baseModelPath.split("/")[2],
      };
      let source = {
        baseModelFile: this.projectInfo.baseModelUrl ? true : false,
        baseModelPath: this.projectInfo.baseModelPath,
        modelCoverUrl: this.projectInfo.baseModelPreviewUrl,
        modelCoverPath: this.projectInfo.baseModelPreviewPath,
        QRCodePositionUrl: this.projectInfo.qrcodeDescMediaPathUrl,
        QRCodePositionPath: this.projectInfo.descMediaPath,
        QRCodeFileUrl: this.projectInfo.qrcodeDescMediaPathUrl,
        QRCodeFilePath: this.projectInfo.qrcodeDescMediaPath + "jpg",
      };
      this.$refs.modelSetter.setShow(
        true,
        "netUrl",
        this.projectInfo.baseModelUrl ? true : false,
        this.projectInfo.enableObjectAnchorModel,
        source
      );
    },
    async positionTypeChange(type) {
      console.log(type);
      // if (type) {
      // if (!this.projectInfo.baseModelPreviewUrl) {
      //   // ElMessage.error("请先上传'基础模型'");
      //   this.setModelInfo(true);
      //   this.projectInfo.enableObjectAnchorModel = false;
      // } else {
      this.setModelInfo();

      // if (!this.selectKey) {
      //   this.selectKey = 4;
      // }
      // if (!this.projectInfo.baseModelUnit) {
      //   this.projectInfo.baseModelUnit = 1;
      // }
      // let result = this.baseModelGravityList.find((value, index) => {
      //   return value.key === this.selectKey;
      // });
      // let options = {
      //   projectGuid: this.projectInfo.projectGuid,
      //   enableObjectAnchorModel: true,
      //   baseModelGravity: result.value,
      //   baseModelUnit: this.projectInfo.baseModelUnit,
      // };
      // await saveProject(options);
      // }
      // } else {
      //   let options = {
      //     projectGuid: this.projectInfo.projectGuid,
      //     enableObjectAnchorModel: false,
      //   };
      //   await saveProject(options);
      //   this.projectInfo.enableObjectAnchorModel = false;
      // }
    },
    prevViewCoverImageUrl() {
      console.log("预览封面");
      // projectInfo.coverImageUrl
      this.$refs.pictureView.setSwitch(true, this.projectInfo.coverImageUrl);
    },
    prevViewBaseModel() {
      console.log("预览基础模型");
      console.log(this.projectInfo.baseModelPath.split("/")[3]);

      let options = {
        fileUrl: this.projectInfo.baseModelUrl,
        filePath: this.projectInfo.baseModelPath,
        isDelete: false,
        assetCategory: 1001,
        assetType: 2,
        originFileName: this.projectInfo.baseModelPath.split("/")[2],
      };
      this.$refs.modelLoader.setShow(true, "netUrl", options);
    },
    prevViewQrcodeDesc() {
      console.log("预览二维码材料");
      switch (this.getFileTypeByFileName(this.projectInfo.descMediaPath)) {
        case "picture":
          // 代码块
          this.$refs.pictureView.setSwitch(
            true,
            this.projectInfo.descMediaPathUrl
          );
          break;
        case "video":
          this.$refs.videoView.setSwitch(
            true,
            this.projectInfo.descMediaPathUrl
          );
          // 代码块
          break;
      }
    },
    // getOwnProject() {
    //   console.log("getOwnProject");
    // },
    getFileTypeByFileName(fileName) {
      let modelTypes = [".fbx", ".obj", ".glb", ".gltf", ".stl"];
      let pictureTypes = [".jpg", ".jpeg", ".png", ".tif", ".bmp"];
      let videoTypes = [
        ".mp4",
        ".rmvb",
        ".rm",
        ".asf",
        ".divx",
        ".mpg",
        ".mpeg",
        ".wmv",
        ".mkv",
        ".vob",
      ];
      let PDFTypes = ["PDF", "pdf"];
      let type = false;
      modelTypes.forEach((value, index) => {
        if (fileName.includes(value)) {
          type = "model";
        }
      });
      pictureTypes.forEach((value, index) => {
        if (fileName.includes(value)) {
          type = "picture";
        }
      });
      videoTypes.forEach((value, index) => {
        if (fileName.includes(value)) {
          type = "video";
        }
      });
      PDFTypes.forEach((value, index) => {
        if (fileName.includes(value)) {
          type = "PDF";
        }
      });
      return type;
    },
    async selectModelUnitChange(val) {
      console.log(val);
      let options = {
        enableObjectAnchorModel: true,
        projectGuid: this.projectInfo.projectGuid,
        baseModelUnit: this.projectInfo.baseModelUnit,
      };
      await saveProject(options);
    },
    async selectGravityChange(val) {
      console.log(val);
      let result = this.baseModelGravityList.find((value, index) => {
        return value.key === val;
      });
      let options = {
        enableObjectAnchorModel: true,
        projectGuid: this.projectInfo.projectGuid,
        baseModelGravity: result.value,
      };
      await saveProject(options);
      console.log(result);
    },
    async categoryIdChange(val) {
      console.log(val);
      // let obj = this.categories.find((value,index)=>{
      //   return value.value === val
      // })
      let options = {
        projectGuid: this.projectInfo.projectGuid,
        categoryId: this.projectInfo.categoryId,
      };
      await saveProject(options);
    },
    async languageChange(val) {
      console.log(val);
      let options = {
        projectGuid: this.projectInfo.projectGuid,
        language: this.projectInfo.language,
      };
      await saveProject(options);
      this.$emit("languageChange", val);
    },
    async loadProject() {
      if (this.auditReleaseMode) {
        return await getReleaseById(this.auditReleaseGuid);
        // return res.result;
      } else if (this.releaseMode) {
        return await getReleaseById(this.releaseGuid, this.projectGuid);
      } else if (this.projectGuid) {
        return (this.projectInfo = await getProjectById(this.projectGuid));
        // this.myProjectStatus = await getProjectStatusById(this.projectGuid);
        // await this.refreshTaskList();
      } else {
        return;
      }

      // if (this.projectInfo.tasks.length > 0)
      //   this.selectedTask = this.projectInfo.tasks[0];
    },
    async getThumbnail(data) {
      console.log(data);

      let options = {
        projectGuid: this.projectInfo.projectGuid,
        baseModelPreviewPath: null,
        baseModelPath: null,
      };

      // this.preViewModelCover = data;
      // let modelCover = dataURLtoBlob(data);
      // modelCover.name = "aa.png";
      // options.baseModelPreviewPath = await this.uploadStageFile(modelCover);
      options.baseModelPreviewPath = data.value.path;
      options.baseModelPath = await this.uploadStageFile(this.baseModelFile);
      let tempProjectInfo = { ...this.projectInfo, ...options };
      await saveProject(tempProjectInfo);
      this.projectInfo = await getProjectById(this.projectInfo.projectGuid);
    },
    async saveQRCodeDescription() {
      let options = {
        projectGuid: this.projectInfo.projectGuid,
        qrcodeDescription: this.projectInfo.qrcodeDescription,
      };
      await saveProject(options);
    },
    async enableOfflineModeChange() {
      let options = {
        projectGuid: this.projectInfo.projectGuid,
        enableOfflineMode: this.projectInfo.enableOfflineMode,
      };
      await saveProject(options);
    },
    async saveDescription() {
      // projectGuid
      console.log(this.projectInfo.description);
      let options = {
        projectGuid: this.projectInfo.projectGuid,
        description: this.projectInfo.description,
      };
      await saveProject(options);
    },
    nameFocus() {
      this.nameTemp = this.projectInfo.projectName;
    },
    async saveName() {
      console.log(this.projectInfo);
      let options = {
        projectGuid: this.projectInfo.projectGuid,
        projectName: this.projectInfo.projectName
          ? this.projectInfo.projectName
          : this.nameTemp,
      };
      if (!this.projectInfo.projectName) {
        this.projectInfo.projectName = this.nameTemp;
      }
      let result = await saveProject(options);
      this.$emit("projectNameChange", result.projectName);
      console.log(result);
    },
    //删除基础模型
    async deleteModel() {
      // 确认删除'基础模型'?  基础模型
      this.$confirm(
        this.$t("projectInfo.word10"),
        this.$t("projectInfo.word11"),
        {
          distinguishCancelAndClose: true,
          // 删除
          confirmButtonText: this.$t("projectInfo.delete"),
          // 取消
          cancelButtonText: this.$t("projectInfo.cancel"),
          customClass: "deleteBox",
        }
      )
        .then(async () => {
          let options = {
            projectGuid: this.projectInfo.projectGuid,
            baseModelPreviewPath: "",
            baseModelPath: "",
            baseModelPreviewUrl: "",
            baseModelUrl: "",
          };
          let tempProjectInfo = { ...options };
          await saveProject(tempProjectInfo);
          this.loadProject();
        })
        .catch((action) => {});
    },
    onChangeModel(f) {
      console.log(f);

      console.log(this.$refs.assetPicker.$refs.modelLoader);
      // this.$refs.assetPicker.$refs.modelLoader.setShow(true, "localUrl", f.raw);
      let urlData = URL.createObjectURL(f.raw);
      this.baseModelFile = f.raw;
      let data = {
        bool: true,
        type: "localUrl",
        fileName: f.raw.name,
        fileData: urlData,
      };
      this.$refs.modelLoader.setShow(true, "localUrl", data);
      this.$store.commit("SET_PROJECTINFO", true);
    },
    async getBlobFromCanvas(imageCanvas) {
      return new Promise((res, rej) => {
        imageCanvas.toBlob((blob) => {
          res(blob);
        });
      });
    },
    //删除二维码相关信息
    async deleteQRInfo() {
      this.$confirm(
        // 确认删除'二维码物体的相对位置照片/视频'?
        this.$t("projectInfo.tip1"),
        // 二维码定位信息
        this.$t("projectInfo.tip2"),
        {
          distinguishCancelAndClose: true,
          // 删除
          confirmButtonText: this.$t("projectInfo.delete"),
          // 取消
          cancelButtonText: this.$t("projectInfo.cancel"),
          customClass: "deleteBox",
        }
      )
        .then(async () => {
          let options = {
            projectGuid: this.projectInfo.projectGuid,
            qrcodeDescMediaPath: "",
            descMediaPath: "",
          };
          try {
            await saveProject(options);
          } catch (e) {
            // 删除失败
            ElMessage.error(this.$t("projectInfo.tip3"));
          }
          this.loadProject();
        })
        .catch((action) => {});
    },
    async onChangeQRInfo(f) {
      console.log(f.raw);
      let maxSize = fileMaxSize;
      let fileTypeTemp = this.getFileTypeByFileName(f.raw.name);
      if (fileTypeTemp !== "video" && f.raw.size > maxSize) {
        //文件 最大20MB
        ElMessage.error(this.$t("uploadAsset.sizeError"));
        return;
      }
      if (f.raw.size > maxSize) {
        // 文件大小超出限制
        ElMessage.error(this.$t("projectInfo.tip4"));
        return;
      }
      this.QRCodeInfoLoading = true;
      switch (this.getFileTypeByFileName(f.raw.name)) {
        case "model":
          // 代码块
          break;
        case "picture":
          // test
          console.log("asdasd");
          let thumbnail = await compressImg(f.raw, 0.6);
          let file = await compressImg(f.raw, 0.9);
          // console.log(url);
          // this.QRCodeInfoLoading = false;
          this.projectInfo.qrcodeDescMediaPathUrl = thumbnail.blob;
          console.log(file);
          // const aaa = await this.getBlobFromCanvas(thumbnail.canvas);
          // console.log(aaa);
          // const bbb = await this.getBlobFromCanvas(file.canvas);
          // console.log(bbb);
          let QRCodeThumbnail = await this.uploadStageFile(thumbnail.blob);
          let QRCodeFile = await this.uploadStageFile(file.blob);
          // let canvasWrap = document.getElementById("testCanvas");
          // canvasWrap.appendChild(file.canvas);
          // test

          // this.$refs.pictureView.setSwitch(true, item.fileUrl);
          // let QRCodeThumbnail = await this.uploadStageFile(f.raw);
          // let QRCodeFile = await this.uploadStageFile(f.raw);
          let options = {
            projectGuid: this.projectInfo.projectGuid,
            qrcodeDescMediaPath: QRCodeThumbnail,
            descMediaPath: QRCodeFile,
          };
          try {
            await saveProject(options);
            this.projectInfo.qrcodeDescMediaPathUrl = URL.createObjectURL(
              f.raw
            );
            this.QRCodeInfoLoading = false;
            this.loadProject();
          } catch (e) {
            console.log(e);
          }
          this.$store.commit("SET_PROJECTINFO", true);
          // console.log("picture");
          // 代码块
          break;
        // case "video":
        //   // this.$refs.videoView.setSwitch(true, item.fileUrl);
        //   this.videoSrc = URL.createObjectURL(f.raw);
        //   console.log(this.videoSrc);
        //   this.$nextTick(() => {
        //     let video = this.$refs.videoContainer;
        //     video.currentTime = 1;
        //     video.addEventListener("loadeddata", async (e) => {
        //       //加载完成事件，调用函数
        //       this.$refs.canvas.width = video.offsetWidth;
        //       this.$refs.canvas.height = video.offsetHeight;
        //       this.$refs.canvas
        //         .getContext("2d")
        //         .drawImage(video, 0, 0, video.offsetWidth, video.offsetHeight);
        //       const aaa = await this.getBlobFromCanvas(this.$refs.canvas);
        //       aaa.name = "aaa.jpg";

        //       // imageCanvas.width = 0;
        //       let QRCodeThumbnail = await this.uploadStageFile(aaa);
        //       let QRCodeFile = await this.uploadStageFile(f.raw);
        //       let options = {
        //         projectGuid: this.projectInfo.projectGuid,
        //         qrcodeDescMediaPath: QRCodeThumbnail,
        //         descMediaPath: QRCodeFile,
        //       };
        //       console.log(options);

        //       try {
        //         await saveProject(options);
        //         // this.projectInfo.qrcodeDescMediaPathUrl =
        //         //   URL.createObjectURL(aaa);
        //         this.QRCodeInfoLoading = false;
        //         this.loadProject();
        //       } catch (e) {
        //         console.log(e);
        //       }

        //       console.log(aaa);
        //       this.$store.commit("SET_PROJECTINFO", true);
        //     });
        //     // }
        //   });
        //   // 代码块
        //   break;
        case "PDF":
          break;
        default:
          this.QRCodeInfoLoading = false;
          ElMessage.error("请检查文件格式，需图片");
      }
      // this.projectInfo.coverImageUrl = URL.createObjectURL(f.raw);
      // let qrcodeDescMediaPath = await this.uploadStageFile(f.raw);
      // let options = {
      //   projectGuid: this.projectInfo.projectGuid,
      //   qrcodeDescMediaPath: qrcodeDescMediaPath,
      // };
      // let tempProjectInfo = { ...options };
      // await saveProject(tempProjectInfo);
      // // this.$store.state.TaskDetail.showProjectInfo
      // this.$store.commit("SET_PROJECTINFO", true);
    },
    async onChangeCover(f, fileList) {
      console.log(f);
      let maxSize = fileMaxSize;
      if (f.raw.size > maxSize) {
        // 文件大小超出限制
        ElMessage.error(this.$t("projectInfo.tip4"));
        return;
      }

      // let result = this.getCompressImg(f.raw);
      this.compressImgUrl = URL.createObjectURL(f.raw);
      // this.projectInfo.coverImageUrl = URL.createObjectURL(result);
      // let coverImageFile = await this.uploadStageFile(result);
      // let options = {
      //   projectGuid: this.projectInfo.projectGuid,
      //   coverImagePath: coverImageFile,
      // };
      // let tempProjectInfo = { ...options };
      // await saveProject(tempProjectInfo);
      // this.$store.commit("SET_PROJECTINFO", true);

      // let fileResult = null;
      // this.$refs.uploadPrice.clearFiles();
      // let _this = this;
      // let obj = {
      //   imgUrl: "",
      //   name: "",
      // };
      // let fileListData = [];
      // for (let x = 0; x < fileList.length; x++) {
      //   let item1 = fileList[x];
      //   compress(item1.raw, async (val) => {
      //     let newfile = new window.File([val], file.name, { type: file.type });
      //     newfile.uid = file.uid;
      //     fileListData.push(newfile);

      //     this.projectInfo.coverImageUrl = URL.createObjectURL(newfile);
      //     let coverImageFile = await this.uploadStageFile(newfile);
      //     let options = {
      //       projectGuid: this.projectInfo.projectGuid,
      //       coverImagePath: coverImageFile,
      //     };
      //     let tempProjectInfo = { ...options };
      //     await saveProject(tempProjectInfo);
      //     this.$store.commit("SET_PROJECTINFO", true);
      //   });
      //   var file = item1.raw;
      //   obj.name = item1.name;
      //   var reader = new FileReader();
      //   reader.onload = function (e) {
      //     //转base64
      //     obj.imgUrl = e.target.result;
      //     fileResult = obj;
      //   };
      //   reader.readAsDataURL(file);
      // }

      // console.log(fileListData);
    },
    getCompressImg(file) {
      let obj = {
        imgUrl: "",
        name: file.name,
      };
      var reader = new FileReader();
      reader.onload = function(e) {
        //转base64
        obj.imgUrl = e.target.result;
        const imageCanvas = this.$refs.canvas;
      };
      reader.readAsDataURL(file);
    },
    async compressImgLoaded(e) {
      console.log(e);
      const imageCanvas = this.$refs.compress;

      let maxSize = 640;

      let maxWidth = maxSize;
      let height;
      let scales = 16 / 9;
      if (
        this.$refs.compressImg.imgWidth > maxSize ||
        this.$refs.compressImg.imgHeight > maxSize
      ) {
        if (
          this.$refs.compressImg.imgWidth > this.$refs.compressImg.imgHeight
        ) {
          scales =
            this.$refs.compressImg.imgHeight / this.$refs.compressImg.imgWidth;
          height = maxWidth * scales;
        } else if (
          this.$refs.compressImg.imgHeight > this.$refs.compressImg.imgWidth
        ) {
          height = maxSize;
          scales =
            this.$refs.compressImg.imgWidth / this.$refs.compressImg.imgHeight;
          maxWidth = height * scales;
        }
      } else {
        maxWidth = this.$refs.compressImg.imgWidth;
        height = this.$refs.compressImg.imgHeight;
      }

      const imageSize = {
        width: maxWidth,
        height: height,
      };
      imageCanvas.height = imageSize.height;
      imageCanvas.width = imageSize.width;
      const imageContext = imageCanvas.getContext("2d");

      imageContext.drawImage(
        // e.path[0],
        this.$refs.compressImg.container.children[0],
        0,
        0,
        imageSize.width,
        imageSize.height
      );
      const aaa = await this.getBlobFromCanvas(imageCanvas);
      console.log(aaa);
      let result = await this.uploadStageFile(aaa);
      let options = {
        projectGuid: this.projectInfo.projectGuid,
        coverImagePath: result,
      };
      await saveProject(options);
      await this.loadProject();
      this.$store.commit("SET_PROJECTINFO", true);
    },
    async getBlobFromCanvas(imageCanvas) {
      return new Promise((res, rej) => {
        imageCanvas.toBlob((blob) => {
          res(blob);
        });
      });
    },
    //删除项目封面
    async deleteCover() {
      // 确认删除'项目封面'?   删除项目
      this.$confirm(this.$t("projectInfo.tip5"), this.$t("projectInfo.tip6"), {
        distinguishCancelAndClose: true,
        // 删除
        confirmButtonText: this.$t("projectInfo.delete"),
        // 取消
        cancelButtonText: this.$t("projectInfo.cancel"),
        customClass: "deleteBox",
      })
        .then(async () => {
          let options = {
            projectGuid: this.projectInfo.projectGuid,
            coverImagePath: "",
          };
          try {
            await saveProject(options);
          } catch (e) {
            // 删除失败
            ElMessage.error(this.$t("projectInfo.tip3"));
          }
          this.loadProject();
        })
        .catch((action) => {});
    },
    async handleClick(command, option) {
      console.log(command.props.name);
      console.log("12a21s5a4s654as654as6a4s");
      if (command.props.name === "assets") {
        // console.log(JSON.parse(getProjectInfo()));
        // this.projectInfo = await getProjectById(projectGuid);
        // let projectInfo = JSON.parse(getProjectInfo());
        // let options = {
        //   projectGuid: projectInfo.projectGuid,
        //   size: 36,
        //   page: 0,
        // };
        // let result = await getProjectAssets(projectInfo.projectGuid,0,36)
        // console.log(result);
        this.$nextTick(async () => {
          await this.$refs.assetPicker.getAssetList(
            this.projectInfo.projectGuid,
            0,
            10000
          );
        });
      }
    },

    async ownProject() {
      console.log("own", this.projectInfo);
      await saveOwnProject(
        this.projectInfo.projectGuid,
        this.projectInfo.releaseGuid,
        false
      );
      this.$emit("setOwn", true);
      // 以获取此项目,可在'我的项目->已拥有项目'中查看
      this.$alert(this.$t("projectInfo.tip7"));
    },
    async uploadStageFile(file) {
      if (!file) {
        return "";
      }
      try {
        const { sas, path } = await getUploadSas(
          file.name || `${uuid.v4()}.png`
        );

        const blobClient = new BlockBlobClient(sas, new AnonymousCredential());
        await blobClient.uploadData(file, {
          blockSize: 512 * 1024,
          concurrency: 2,
        });

        // await saveMyProfilePhoto(path)
        return path;
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.step-main {
  background-color: #fff;
  border-radius: 0 0 0.25rem 0.25rem;
  // position: absolute;
  // left: 0;
  // bottom: 0;
  // top: 0;
  // right: 0;
  width: 100%;
  margin: auto;
  // margin: 4rem 1.2rem;

  .head {
    height: 3.5rem;
    opacity: 1;
    // background: #cedeea;
    border-radius: 0.25rem 0.25rem 0 0;
    // padding: 1rem 3rem 0 3rem;
    .main-title {
      margin-left: 1.5rem;
      opacity: 1;
      font-size: 0.81rem;
      font-weight: 400;
      text-align: LEFT;
      color: #426881;
      line-height: 3rem;
    }
    .form-container {
      padding-bottom: 1.5rem;
    }
  }

  .btn-bar {
    margin-top: 3rem;
    text-align: center;
  }
}
:deep(.el-form-item) {
  margin-bottom: 5px;
}
.assetHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  margin-top: 0.7rem;
  margin-bottom: 0.4rem;
  // color: #333;
  color: #224b77;
  position: relative;
  z-index: 10;
  font-weight: bold;
  img {
    width: 1.5rem;
    height: 1.5rem;
  }
  .icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .deleteIcon {
      cursor: pointer;
      transition: background 0.2s;
      margin-right: 5px;
      border-radius: 2px 2px 2px 2px;
    }
    .deleteIcon:hover {
      background: #ffecec;
    }
    .setIcon {
      cursor: pointer;
      transition: background 0.2s;
      margin-right: 5px;
      border-radius: 2px 2px 2px 2px;
    }
    .setIcon:hover {
      background: #ecf2ff;
    }
    .change-btn {
      cursor: pointer;
      transition: background 0.2s;
      border-radius: 2px 2px 2px 2px;
      height: 1.5rem;
    }
    .change-btn:hover {
      background: #ecf2ff;
    }
  }
}
.uploadedModel,
.uploadedPicture {
  width: 100%;
  // height: 10rem;
  background: #f8f9fb;
  // box-sizing: border-box;
  // padding: 1.5rem 4rem 2.5rem 4rem;
  padding-bottom: 70%;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  // border-radius: 6px;
  border: 1px solid transparent;
  background: linear-gradient(#fff, #fff) padding-box,
    repeating-linear-gradient(
      -45deg,
      #4582ff 0,
      #4582ff 0.4em,
      #fff 0,
      #fff 1em
    );
  .uploadLoadBody {
    background: #f8f9fb;
    // position: relative;
    width: 100%;
    // padding-bottom: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    // padding: 1.5rem 5rem 3.5rem 5rem;
    box-sizing: border-box;
    > div {
      width: 100%;
      height: 100%;
      .el-image {
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        :deep(.el-image__inner) {
          cursor: pointer;
        }
        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          > .image-slot {
            width: 100%;
            height: 100%;
            > p {
              margin: 0;
              position: relative;
              top: -15px;
              color: rgba($color: #000000, $alpha: 0.25);
              font-size: 14px;
              padding: 0 7.5%;
              line-height: 22px;
            }
            > .clickUpload {
              color: #4582ff;
              font-size: 14px;
              position: relative;
              top: -23px;
              cursor: pointer;
              > .change-btn {
                text-align: center;
              }
            }
            > img {
              width: 160px;
              height: 90px;
            }
          }
        }
      }
    }
    .modelWarp,
    .pictureWarp {
      width: 100%;
      height: 100%;
      background: #333;
    }
    .words {
      color: #9e9e9e;
      line-height: 2rem;
      font-size: 0.9rem;
    }
  }
}
.ctrlProject {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  > button {
    outline: none;
    border: none;
    cursor: pointer;

    width: 8rem;
    height: 2.5rem;
    padding: 0.5rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      width: 1.5rem;
      margin-right: 0.5rem;
    }
  }
  .primary {
    background: #409eff;
    color: #fff;
  }
  .had {
    background: #d0d0d2;
    color: #fff;
  }
  // >.primary:hover{

  // }
  .getThis {
    background: #e6a23c;
  }
  .gotThis {
    background: #d0d0d0;
  }
}
.positionMethods {
  margin-top: 0.7rem;
  color: #224b77;
  font-weight: 400;
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  > img {
    width: 0.9rem;
    height: 0.9rem;
    margin-left: 0.5rem;
  }
}
.seleter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  > div {
    width: 76%;
  }
  > button {
    width: 4rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
      > img {
        width: 1.8rem;
        // height: ;
      }
    }
  }
  :deep(.el-dropdown) {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    .el-dropdown-link {
      width: 48px;
      height: 40px;
      button {
        width: 48px;
        height: 40px;
        padding: 0;
        span {
          img {
            width: 36px;
            height: 32px;
          }
        }
      }
    }
  }
}
.qrcodeTip {
  font-size: 0.9rem;
  color: #224b77;
  text-align: left;
  margin-top: 1rem;
  font-weight: bold;
}
.qrcodeTextarea {
  margin-top: 0.5rem;
  // margin-bottom: 1rem;
  .textArea {
    :deep(.el-textarea__inner) {
      padding: 5px 0px 5px 10px;
    
      &::-webkit-scrollbar {
        width: 14px;
        height: 14px;
      }
      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.1);
        background-clip: padding-box;
        border: solid transparent;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        background-clip: padding-box;
        border: solid transparent;
        border-width: 1px;
      }
    }
  }
}
.qrcodeTip2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  margin-top: 0.7rem;
  color: #7a92ac;
  > img {
    width: 2rem;
    height: 2rem;
  }
}
:deep(.formItemWarp) {
  text-align: left;
  .el-form-item__label {
    color: #224b77;
    margin: 0;
    padding: 0;
  }
  .el-form-item__content {
    .el-select {
      width: 100%;
    }
  }
}
.provinces_select {
  padding: 6px 6px 6px 10px;
  height: auto;
  line-height: 22px;
  margin: 6px 10px;
  border-radius: 4px;
}
.printPosition {
  width: 85%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // top: -100%;
  z-index: -1;
  background: #fff;
  // border: 1px solid #ccc;
  display: block;
}
.printPositionNone {
  display: none;
}
.videoContainer {
  width: 100%;
  visibility: hidden;
}
.videoCanvas {
  width: 100%;
  visibility: hidden;
}
.compress {
  visibility: hidden;
}
.preview-image {
  visibility: hidden;
}
.el-select-dropdown__item {
  color: #274e78;
}
.el-select-dropdown__item:hover {
  color: #4582ff;
  font-weight: 400;
}
.selected {
  color: #4582ff;
  font-weight: 400;
}
.offlineModeWrap {
  background: #f8f9fb;
  color: #222222;
  box-sizing: border-box;
  padding-left: 10px;
  .checkboxLabel {
    margin-left: 10px;
    font-size: 14px;
  }
}
</style>
