

import request from '@/utils/request'


//获取上传图片的url
export function getStaginProfilePhotoUrl() {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/myProfile/getStaginProfilePhotoUrl`, { })
}

// 保存图片
export function saveMyProfilePhoto(profilePhoto) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/myProfile/saveMyProfilePhoto`, { item: {profilePhoto} })
}


// 保存我得信息
export function saveMyUser(item) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/mine/saveMyUser`, { item })

    //item: displayName, tel, email, introduction
}

//保存auth服务器上的信息
export function saveUser(item) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/tenantAdmin/saveUser`, { item })

    //item: displayName, tel, email, introduction
}

