import { h } from "@logicflow/core";
import { RectResize } from "@logicflow/extension";
class HexagonModel extends RectResize.model {
    setAttributes() {
        this.text.x = this.x;
        this.text.y = this.y;
        this.menu = [
            {
                className: "items",
                // 复制属性
                text: `${this.properties.language.copy} Ctrl + C`,
                callback: (node) => {
                    this.graphModel.eventCenter.emit("nodeCopy", node);
                },
            },
            {
                className: "items",
                // 克隆步骤
                text: `${this.properties.language.clone} Ctrl + D`,
                callback: (node) => {
                    this.graphModel.eventCenter.emit("nodeClone", node);
                },
            },
            {
                // 删除
                text: `${this.properties.language.delete} Delete`,
                callback: (node) => {
                    this.graphModel.eventCenter.emit("nodeDelete", node);
                },
            },
        ];
    }
    initNodeData(data) {
        super.initNodeData(data);
        this.width = 160;
        this.height = 64;
        const rule1 = {
            message: "不允许连接到'开始步骤'",
            validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
                if (targetNode.type === "StartShape") {
                    return false
                } else {
                    return true
                }
            }
        }
        const rule2 = {
            message: "'普通步骤'只允许有一个出口",
            validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
                let edgesList = sourceNode.graphModel.edges;
                let hasEdges = edgesList.find((item) => {
                    return item.sourceNode.id === sourceNode.id;
                });
                if (hasEdges) {
                    return false
                } else {
                    return true
                }
            }
        }
        const rule3 = {
            message: "不允许连接到自己",
            validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
                if (sourceNode.id === targetNode.id) {
                    return false
                } else {
                    return true
                }
            }
        }
        this.sourceRules.push(rule1, rule2, rule3);
    }
    //重写节点文本样式
    getTextStyle() {
        const style = super.getTextStyle();
        style.color = "#333333"
        // if (this.properties.stepInfo.sourceStepGuid) {
        //     if (this.properties.cloneNodeActive || this.isSelected) {
        //         style.color = "#333333"
        //     } else {
        //         style.color = "#ffffff"
        //     }
        // } else {
        //     style.color = "#ffffff"
        // }
        return style;
    }
    //重写节点样式
    getNodeStyle() {
        const style = super.getNodeStyle();
        style.strokeWidth = 2;
        if (this.properties.onLinkLine) {
            style.strokeDasharray = "0,0";
            //克隆体高亮状态
            if (this.properties.cloneNodeActive || this.isSelected) {
                style.fill = "#E1EBFF";
                style.stroke = "#4582ff"
                style.strokeDasharray = "0";
                if ((this.properties.isWebGl || this.properties.isAuthor) && this.isSelected) {
                    style.stroke = "#4582ff"
                } else {
                    style.stroke = "#4582ff"
                }
            } else {

                style.fill = "#E1EBFF";
                style.stroke = "#000"
            }
        } else {
            style.strokeDasharray = "4,4";
            //克隆体高亮
            if (this.properties.cloneNodeActive || this.isSelected) {
                style.stroke = "#4582ff"
                style.fill = "#E1EBFF";
                style.strokeDasharray = "0";
                if ((this.properties.isWebGl || this.properties.isAuthor) && this.isSelected) {
                    //克隆步骤的外边框在缺省状态下有两种颜色
                    style.stroke = "#32ff32"
                } else {
                    style.stroke = "#4582ff"
                }
            } else {
                style.fill = "#E1EBFF";
                style.stroke = "#000"
            }
        }
        //     stroke: "#000000",
        //   strokeWidth: 1,
        //   strokeDasharray: "3,3",
        //"开始步骤"链接树高亮状态"
        // if (this.properties.onLinkLine) {
        //     style.fill = "#8FB5FF";

        // } else {
        //     style.fill = "#C5C5C5";
        // }
        //克隆节点高亮状态
        // if (this.properties.cloneNodeActive) {
        //     style.strokeDasharray = "0,0";
        //     style.stroke = "#ffffff"
        //     style.fill = "#ffffff";
        // } else {
        //     style.strokeDasharray = "3,3";
        //     style.stroke = "#ffffff";
        //     style.fill = "#C5C5C5";
        // }

        //节点被选中的高亮状态
        // if (this.isSelected) {
        //     style.strokeDasharray = "0,0";
        //     style.stroke = "#ffffff"
        //     style.fill = "#ffffff";
        // } else {
        //     style.strokeDasharray = "3,3";
        //     style.stroke = "#4582FF";
        //     style.fill = "#C5C5C5";
        // }


        return style;
    }
    //重写锚点样式
    getAnchorStyle(anchorInfo) {
        const style = super.getAnchorStyle(anchorInfo);
        // style.fill = "#32ff32";
        // style.stroke = "#32ff32";
        // style.fill = "#4582FF";
        // if (this.properties.cloneNodeActive && !this.isSelected) {
        //     style.r = 0;
        // } else {
        //     style.r = 6;
        // }
        style.r = 8;
        style.strokeWidth = 2;
        style.stroke = "#4582FF";
        style.hover.fill = "transparent";
        style.hover.stroke = "transpanrent";
        style.className = "lf-hide-default";
        return style;
    }
    //重写节点外边框样式
    getResizeOutlineStyle() {
        return {
            strokeWidth: 2,
            // stroke: "#32ff32",
            stroke: "#4582FF",
        }
    }
    //重写节点缩放四个点样式
    getControlPointStyle() {
        if (this.properties.cloneNodeActive && !this.isSelected) {
            return {
                width: 0,
                height: 0,
                fill: "#FFFFFF",
                // stroke: "#32ff32",
                stroke: "#4582FF",
            };
        } else {
            return {
                strokeWidth: 2,
                width: 14,
                height: 14,
                fill: "#FFFFFF",
                // stroke: "#32ff32",
                stroke: "#4582FF",
            };
        }

    }

}

class HexagonView extends RectResize.view { }

export default {
    type: "CloneActionShape",
    view: HexagonView,
    model: HexagonModel
};
