<template>
  <div>
    <el-form
      :model="formData"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="enterpriseRuleForm"
      label-position="top"
    >
      <!-- 标题 -->
      <el-form-item :label="$t('form.title')" prop="name">
        <el-input size="small" v-model="formData.name"></el-input>
      </el-form-item>
      <!-- 邮箱 -->
      <el-form-item :label="$t('form.email')" prop="email">
        <el-input size="small" v-model="formData.email"></el-input>
      </el-form-item>
      <!-- 电话 -->
      <el-form-item :label="$t('form.tel')" prop="tel">
        <el-input size="small" v-model="formData.tel"></el-input>
      </el-form-item>
      <!-- 公司名称 -->
      <el-form-item :label="$t('form.companyName')" prop="company">
        <el-input size="small" v-model="formData.company"></el-input>
      </el-form-item>
      <!-- 感兴趣的XR产品和方案 -->
      <el-form-item class="checkBox" :label="$t('form.label5')" prop="type">
        <el-checkbox-group v-model="formData.likes">
          <!-- <el-checkbox label="Metaflow" name="1"></el-checkbox>
          <el-checkbox label="JTRA" name="2"></el-checkbox>
          <el-checkbox label="BeamLink" name="3"></el-checkbox>
          <el-checkbox label="其它" name="4"></el-checkbox> -->
          <el-checkbox
            v-for="(item, index) in checkBoxList"
            v-model="item.value"
            :key="index"
            :label="item.label"
          ></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <div>
        <el-input type="textarea" v-model="formData.node"></el-input>
      </div>

      <div class="submitWarp">
        <!-- <el-button type="primary" @click="submitForm('ruleForm')"
          >立即创建</el-button
        > -->
        <!-- 提交 -->
        <button @click="submitForm($event, 'ruleForm')" :disabled="isDisabled">
          {{ countDownNum > 0 ? countDownNum : "" }}
          {{ $t("form.submit")
          }}<img src="@/assets/enterprise/goTo.png" alt="" />
        </button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { submitUserForm } from "@/api/callUs.js";
import { ElMessage } from "element-plus";
export default {
  components: {},
  data() {
    return {
      countDownTimer: null,
      countDownNum: 0,
      checkBoxList: [
        {
          label: "JTWorkflow",
          value: {
            id: 1,
          },
        },
        {
          label: "JTRA",
          value: {
            id: 2,
          },
        },
        {
          label: "BeamLink",
          value: {
            id: 3,
          },
        },
        {
          // 其它
          label: this.$t("form.other"),
          value: {
            id: 4,
          },
        },
      ],
      rules: {
        name: [
          // 请输入邮件标题
          { required: true, message: this.$t("form.tip1"), trigger: "blur" },
          //   {
          //     min: 3,
          //     max: 5,
          //     message: "长度在 3 到 5 个字符",
          //     trigger: "blur",
          //   },
        ],
        // 请输入您的邮箱
        email: [
          { required: true, message: this.$t("form.tip2"), trigger: "blur" },
        ],
        // 请输入您的电话
        tel: [
          { required: true, message: this.$t("form.tip3"), trigger: "blur" },
        ],
        // company: [
        //   { required: true, message: "请输入您的公司名称", trigger: "blur" },
        // ],
      },
      formData: {
        name: "",
        email: "",
        tel: "",
        company: "",
        likes: [],
        node: "",
      },
    };
  },
  created() {
    let sendTime = localStorage.getItem("JTWorkflow_callUs");
    if (sendTime) {
      let diffMs = this.testTimeDiff(Number(sendTime));
      console.log(diffMs);
      if (diffMs >= 300000) {
        localStorage.removeItem("JTWorkflow_callUs");
      } else {
        let s = parseInt(diffMs / 1000);
        let count = 300 - s;
        // this.isCountDown = true;
        clearInterval(this.countDownTimer);
        this.countDownTimer = null;
        this.countDownNum = count;
        this.countDownTimer = setInterval(() => {
          this.countDownNum--;
          if (this.countDownNum < 1) {
            clearInterval(this.countDownTimer);
            this.countDownTimer = null;
            // this.isCountDown = false;
            localStorage.removeItem("JTWorkflow_callUs");
            return;
          }
        }, 1000);
      }
    }
  },
  mounted() {},
  computed: {
    isDisabled() {
      return this.countDownNum > 0;
    },
  },
  watch: {},
  methods: {
    testTimeDiff(time, diff) {
      let saveTime = +new Date(time);
      let nowTime = +new Date();
      let diffMs = nowTime - saveTime;
      return diffMs;
    },
    submitForm(e, formName) {
      e.preventDefault();
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(this.formData);
          let resultLikes = [];
          this.formData.likes.forEach((value, index) => {
            this.checkBoxList.forEach((v, i) => {
              if (v.label === value) {
                resultLikes.push(v.value);
              }
            });
          });
          console.log(resultLikes);
          let options = {
            name: this.formData.name,
            tel: this.formData.tel,
            email: this.formData.email,
            company: this.formData.company,
            nodel: this.formData.node,
            products: resultLikes,
          };
          console.log(options);
          try {
            if (this.countDownNum > 0) {
              return;
            }
            // let result = await submitUserForm(options);
            localStorage.setItem("JTWorkflow_callUs", +new Date());
            // 信息已发送至我们的邮箱,我们的工作人员将会在收到邮件之后与您联系!
            // 提交结果
            this.$alert(this.$t("form.tip4"), this.$t("form.tip5"), {
              // 确定
              confirmButtonText: this.$t("form.tip6"),
              callback: (action) => {},
            });

            // ElMessage.success({
            //   message: "我们的工作人员已收到您的邮件,请耐心等待",
            //   type: "success",
            // });
            clearInterval(this.countDownTimer);
            this.countDownTimer = null;
            this.countDownNum = 300;
            this.countDownTimer = setInterval(() => {
              this.countDownNum--;
              if (this.countDownNum < 1) {
                clearInterval(this.countDownTimer);
                this.countDownTimer = null;
                localStorage.removeItem("JTWorkflow_registeTime");
                return;
              }
            }, 1000);
          } catch (e) {}

          // console.log(result);
          // alert("submit!");s
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
:deep(.is-checked) {
  color: #f7b74f !important;
  // .is-checked + .el-checkbox__label {
  //   color: #f7b74f;
  // }
  > .el-checkbox__input {
    color: #f7b74f;
    > .el-checkbox__inner {
      color: #f7b74f;
      background-color: #f7b74f;
      border-color: #f7b74f;
    }
  }
  .is-focus {
    border: #f7b74f !important;
    > .el-checkbox__inner {
      border-color: #f7b74f;
    }
  }
  > .el-checkbox__label {
    color: #f7b74f;
  }
}
.enterpriseRuleForm {
  > .el-form-item {
    text-align: left;
    > label {
      padding: 0;
      line-height: 24px;
      color: #7c5f32;
    }
  }
  > .checkBox {
    margin-bottom: 0;
    .el-form-item__content {
      .el-checkbox-group {
        > .el-checkbox {
          color: #f7b74f;
        }
        > .is-checked {
          color: #f7b74f !important;
          // .is-checked + .el-checkbox__label {
          //   color: #f7b74f;
          // }
          > .el-checkbox__input {
            color: #f7b74f;
            > .el-checkbox__inner {
              color: #f7b74f;
              background-color: #f7b74f;
              border-color: #f7b74f;
            }
          }
          .is-focus {
            border: #f7b74f !important;
            > .el-checkbox__inner {
              border-color: #f7b74f;
            }
          }
        }
      }
    }
  }
  > .submitWarp {
    text-align: right;
    position: absolute;
    bottom: 3rem;
    right: 3rem;
    > button {
      width: 120px;
      height: 38px;
      background: #deb573;
      border-radius: 32px 32px 32px 32px;
      opacity: 1;
      outline: none;
      cursor: pointer;
      border: none;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background 0.3s;
      > img {
        width: 1.5rem;
      }
    }
    > button:hover {
      background: #e7c796;
    }
    > button[disabled] {
      color: #fff;
      background: #b6b6b6;
    }
  }
}
/* 当屏幕宽度在 0 到 1200 像素之间时应用的样式 */
@media screen and (max-width: 1200px) {
  .enterpriseRuleForm {
    font-size: 1.2rem;
    :deep(.el-form-item) {
      // margin: 0;
      > .el-form-item__label {
        padding: 0;
        font-size: 0.8rem;
        line-height: 12px;
        padding: 0;
      }
    }
    > .submitWarp {
      bottom: 1rem;
      right: 1.5rem;
      > button {
        font-size: 14px;
      }
    }
  }
}
</style>
