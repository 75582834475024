const elLang = require('element-plus/lib/locale/lang/zh-cn');
const daysLang = require('dayjs/locale/zh-cn');

module.exports = {
    languageName: "中文简体",
  ...elLang.default,
  ...daysLang.default,
    routers: {
        mainPage: "首页",
        unauthorized: "无权限访问",
        settings: "系统设置",
        account: "我的账号",
        explore: "探索",
        modelView: "视图",
        enterprise: "企业用户",
        myAccount: "我的账户",
        myTasks: "我的流程",
        webGl: "模型",
        taskDetail: "任务详情",
        accounts: "用户账号管理",
        newAccount: "新建用户账号",
        updateAccount: "编辑用户账号",
        dataCharts: "数据图表",
        auditProject: "审核项目",
        publishRoles: "禁止发布的内容",
    },
    store: {
        tip1: "密码强度不够",
    },
    asset: {
        word1: "X轴正方向",
        word2: "X轴负方向",
        word3: "Y轴正方向",
        word4: "Y轴负方向",
        word5: "Z轴正方向",
        word6: "Z轴负方向",
        word7: "二维码定位",
        word8: "物体识别定位",
        word9: "米",
        word10: "分米",
        word11: "厘米",
        word12: "全部",
        word13: "HoloLens",
        word14: "iOS",
        word15: "全部",
        word16: "工业设备",
        word17: "科研设备",
        word18: "医疗器械",
        word19: "其它",
        word20: "文本",
        word21: "多媒体",
        word22: "文本+多媒体",
        word23: "多媒体+多媒体",
        word24: "文本+多媒体+多媒体",
    },
    request: {
        tip1: "登录状态已过期,请重新登录",
        tip2: "请重新登录",
        tip3: "登录状态已过期,请重新登录",
    },
    animation: {
        loading: "正在加载...",
    },
    modelLoading: {
        loading: "正在加载",
    },
    createProject: {
        projectName: "名称",
        enableOfflineMode: "是否允许离线",
        enableOfflineModeTip: "允许用户下载到本地离线使用",
        categoryId: "选择项目类型",
        cover: "封面",
        uploadCover: "上传封面",
        positionMethods: "定位方式",
        baseModel: "基础模型",
        canSelect: "可选",
        noSelect: "必选",
        uploadBaseModel: "上传基础模型",
        hasModel: "(已有模型)请生成模型封面",
        tip1: "二维码物体的相对位置照片",
        uploadPicAndVid: "上传参照图/视频",
        cancel: "取消",
        confirm: "确定",
        tip2: "请上传'基础模型'",
        tip3: "缺省任务",
        tip4: "位置信息保存失败!",
        tip5: "创建成功!",
        tip6: "缺省任务创建失败，请手动创建",
        projectLanguage: "选择项目语音",
    },
    forgetPass: {
        tel: "手机号",
        newPass: "新密码",
        passTip: "至少8位密码,字母大小写、数字、及特殊标点符号组合",
        repeat: "重复",
        authCode: "验证码",
        getAuthCode: "获取验证码",
        tip1: "如未绑定手机号,请联系管理员",
        next: "下一步",
        tip2: "验证码不能为空!",
        tip3: "修改密码",
        codeError: "验证码错误",
        rePass: "两次密码不一致",
        telErr: "该手机号还未注册此应用!",
        tip4: "两次密码不一致!",
        newPassError1: "新密码不能为空!",
        newPassError2: "新密码格式不对!",
        telNull: "手机号不能为空!",
        telError: "手机号格式错误!",
    },
    login: {
        username: "账号",
        password: "密码",
        moUsername: "还没有账号？",
        goRegiste: "去注册",
        forgetPass: "忘记密码",
        login: "登录",
        tip1: "账号或密码不能为空",
        tip2: "用户名或密码错误",
        tip3: "用户无使用本应用的权限",
        tip4: "账号已被冻结一段时间,请稍后重试",
        tip5: "未知错误",
    },
    regirte: {
        tel: "手机号",
        password: "密码",
        passwordRule: "至少8位密码,字母大小写、数字、及特殊标点符号组合",
        rePass: "重复",
        authCode: "验证码",
        getAuthCode: "获取验证码",
        hasUsername: "已有帐号?",
        goLogin: "去登录",
        agree: "同意",
        agreeItem1: "《境腾",
        agreeItem1Href1: "用户协议",
        and: "及",
        agreeItem1Href2: "隐私协议",
        agreeEnd: "》",
        agreeItem2: "同意接受产品更新和社区通知信息邮件",
        registe: "注册",
        tip1: "验证码不能为空!",
        tip2: "请勾选下方协议项!",
        tip3: "注册成功!去登录吧!",
        tip4: "验证码不正确!",
        tip5: "该手机号已被注册!",
        tip6: "两次密码不一致!",
        tip7: "密码不能为空!",
        tip8: "密码格式不对!",
        tip9: "手机号不能为空!",
        tip10: "手机号格式错误!",
    },
    userAgreement: {
        title: "境腾科技用户协议",
        close: "关闭",
    },
    resetPass: {
        resetResult: "密码已重置",
        resetPasswordRes: "恭喜您,您的密码已重置成功!",
        go: "开始使用",
    },
    header: {
        explore: "探索",
        enterprisePage: "企业用户",
        download: "产品下载",
        serchTip: "输入要搜索的内容",
        login: "登录",
        regist: "注册",
        createProject: "创建项目",
        myAccount: "我的账户",
        dataChart: "数据统计",
        myProject: "我的项目",
        auditProject: "审核项目",
        logout: "登出",
        forgetPass: "忘记密码",
        tips1: "请输入手机号和验证码找回密码",
        twoD: "2D流程",
        threeD: "3D流程",
    },
    modelSetter: {
        model: "二维码与基础模型",
        change: "更换",
        upload: "上传",
        delete: "删除",
        modelSize: "模型使用尺寸",
        gravityArrow: "模型重力方向",
        noModel: "无基础模型",
        getCover: "生成基础模型封面",
        qrcodeCover: "无位置参考图",
        gwtQRCodeCover: "生成二维码位置参考图",
        noModel: "暂未上传基础模型",
        cancel: "取消",
        ok: "确定",
        sizeError: "文件大小超出限制!",
    },
    modelLoader: {
        modelPreview: "模型预览",
        tip1: "鼠标右键控制模型移动",
        tip2: "鼠标左键控制模型旋转",
        tip3: "鼠标滚轮控制模型缩放",
        close: "关闭",
        ok: "确定",
    },
    pdfView: {
        pdfView: "PDF预览",
        prevPage: "上一页",
        nextPage: "下一页",
        cancel: "取消",
        close: "关闭",
    },
    pcitureView: {
        title: "图片预览",
        cancel: "取消",
        close: "关闭",
    },
    videoView: {
        title: "视频预览",
        cancel: "取消",
        close: "关闭",
    },
    enterprise: {
        word1: "不怕有新人",
        word2: "复杂的操作流程变得所见即所得",
        word3: "个人免费使用",
        word4: "企业用户计划",
        word5: "企业专属的XR流程指导平台",
        word6: "技术的知识分享与交流",
        word7: "轻松创建标准化流程指导",
        word8: "轻松创建虚实结合指导流程",
        word9: "自由",
        word10: "轻松",
        word11: "支持全球头部企业级XR设备",
        word12: "全球范围眼镜设备覆盖",
        word13: "Hololens2",
        word14: "虚实结合的可视化指导,答案就在问题边上",
        word15: "流程问题，即时解决",
        word16: "无忧",
        word17: "流畅",
        word18: "支持手机设备AR查看",
        word19: "掏出手机便可使用",
        word20: "便捷",
        word21: "iOS",
        word22: "Android",
        word23: "超过10w+全家可用素材",
        word24: "轻松创建虚实结合指导流程",
        word25: "自主",
        word26: "分类",
        word27: "收藏",
        word28: "兼容VR设备的虚拟操作流程",
        word29: "虚拟现实呈现全流程解决方案",
        word30: "VR",
        word31: "让用户手册不再生涩难懂",
        word32: "企业可以使用JTWorkflow面向终端用户发布产品的XR流程指导，提升用户对产品的接收程度",
        word33: "马上体验",
        word34: "为全球各大企业所信任",
        word35: "已服务过全球各行业客户",
        word36: "联系我们获取企业专属解决方案",
        word37: "支持MR、AR、VR多平台客户端使用，无拘无束",
        word38: "合作企业",
        word39: "Apple Vision Pro",
        word40: "Pico 4",
    },
    banner: {
        word1: "区内仓储（除危险品）、分拨业务以及技术支持等",
        word2: "通过使用5G+MR技术，解决了Vantage产品抽象难以讲解的疑难问题，降低了销售经理与客户的沟通成本，提高了与客户沟通的效率。",
        word3: "开发、设计、生产高技术过程控制仪表、控制系统",
        word4: "通过使用5G+MR技术，解决艾默生在产品设计和装配过程中遇到的疑难，提高与国外专家沟通的效率，帮助用户更好、更快地解决现场遇到的问题。",
        word5: "研制、开发、生产、加工牙膏及销售水处理产品",
        word6: "基于MR技术的操作流程指导可以使培训更加直观、清晰，更容易理解，从而达到更好的培训效果。同时也可用于岗位标准化操作指导，降低误操作率。",
        word7: "罗氏诊断",
        word8: "艾默生",
        word9: "联合利华",
    },
    form: {
        title: "标题",
        email: "邮箱",
        tel: "电话",
        companyName: "公司名称",
        label5: "感兴趣的XR产品和方案",
        submit: "提交",
        other: "其它",
        tip1: "请输入邮件标题",
        tip2: "请输入您的邮箱",
        tip3: "请输入您的电话",
        tip4: "信息已发送至我们的邮箱,我们的工作人员将会在收到邮件之后与您联系!",
        tip5: "提交结果",
        tip6: "确定",
    },
    explore: {
        word1: "天下无难事",
        word2: "以全新虚实结合的形式创作、管理、分享您的工作流程和知识",
        word3: "请选择",
        word4: "排序方式",
        word5: "跳转到",
        word6: "当前无可浏览项目",
        word7: "全部时间",
        word8: "今天",
        word9: "本周",
        word10: "本月",
        word11: "获取次数",
        word12: "预览次数",
        word13: "点赞次数",
        word14: "最新",
        word15: "全部",
    },
    modelViewer: {
        modelLoadingTitle: "模型加载中",
        modelCover: "设置模型封面",
        word1: "将模型调整为易于辨认的方向展示",
        word2: "鼠标左键控制模型旋转",
        word3: "鼠标右键控制模型移动",
        word4: "鼠标滚轮控制模型缩放",
        ok: "确定",
        close: "关闭",
        modelDownloadError: "模型下载失败!",
        modelOpenError: "模型文件打开失败，请重新选择模型文件",
    },
    accountInfo: {
        title: "重设密码",
        inputOldPassword: "输入旧密码",
        inputNewPassword: "输入新密码",
        reINputNewPassword: "重复新密码",
        submit: "确认修改",
        tip1: "请输入旧密码",
        tip2: "请输入新密码",
        tip3: "需包含大小写字符、数字和特殊符号,长度8位及以上",
        tip4: "请再次输入新密码",
        tip5: "两次输入密码不一致!",
        tip6: "修改成功,请重新登录",
        tip7: "旧密码不正确",
    },
    avatar: {
        title: "头像裁剪",
        selectPicture: "选择图片",
        reSelect: "重选头像",
        ok: "确认",
        back: "返回上一步",
        tip1: "头像已上传",
    },
    userInfo: {
        changeAvatar: "更换头像",
        username: "用户名",
        tip: "(最多20个字)",
        description: "描述",
        update: "更新",
        tip1: "长度在 1 到 20 个字符",
        tip2: "保存成功！",
        email: "邮箱",
    },
    myAccount: {
        userInfo: "个人信息",
        accountSetting: "账户设置",
        tab1: "编辑个人信息",
    },
    myTask: {
        editUserInfo: "编辑个人信息",
        word1: "我收藏的项目：",
        word2: "我拥有项目：",
        word3: "我创建的项目：",
        word4: "我发布项目",
        word5: "取消收藏",
        word6: "删除项目",
        word7: "下架版本",
        word8: "当前无可浏览项目",
        word9: "创建项目",
        word10: "下架项目",
        word11: "审核记录",
        word12: "当前无待审核项目",
        tip1: "将同时下架该项目,删除后无法恢复,确认删除该项目吗?",
        tip2: "删除项目",
        tip3: "删除",
        tip4: "取消",
        tip5: "已经获取该版本的用户将不能使用该版本，如有上一个版本，还可继续使用上一个版本",
        tip6: "下架项目最新版本",
        tip7: "下架",
        tip8: "下架所有版本",
        tip9: "已经获取该版本的用户将不能使用该版本,所有版本都会被下架",
        tip10: "已下架",
        tip11: "下架失败!",
        tip12: "列表刷新失败",
        tip13: "该项目还没有发布过的版本",
    },
    assetPicker: {
        all: "全部",
        model: "模型",
        picture: "图片",
        video: "视频",
        PDF: "PDF",
        uploadAsset: "上传素材",
        delete: "删除",
        noAsset: "当前无可浏览素材",
        createProject: "创建项目",
        tip1: "是否删除该素材？",
        tip2: "删除素材",
        tip3: "确定",
        tip4: "取消",
        tip5: "删除成功!",
        tip6: "该素材已在某个流程步骤中被使用,不可删除",
        tip7: "提示",
        tip8: "强制删除",
        tip9: "取消",
        tip10: "删除成功!",
    },
    createTask: {
        createTask: "创建任务",
        taskName: "填写流程名",
        cancel: "取消",
        ok: "确认",
        tip1: "请填写流程名称！",
        tip2: "创建成功！",
        tip3: "创建失败",
    },
    deleteProject: {
        title: "删除项目",
        word1: "项目永久删除后将无法恢复，确认删除吗？",
        cancel: "取消",
        delete: "删除",
        tip1: "删除成功!",
    },
    editLink: {
        title: "设置连线文字内容",
        placeholder: "设置条件",
    },
    editStep: {
        word1: "属性",
        name: "名称",
        description: "描述",
        assetList: "素材库",
        word2: "设置连线文字内容",
        word3: "设置条件",
        tip1: "步骤名称",
        projectInfo: "项目设置",
        templateSelect: "模板选择",
    },
    historyEdition: {
        title: "历史版本",
        addHistory: "添加历史版本",
        save: "手动保存",
        word1: "编辑时按Ctrl+Alt+S保存历史版本",
        word2: "请输入内容",
        word3: "重命名",
        word4: "修复到此版本",
        word5: "删除此历史版本",
        saveAuto: "自动保存",
        enterContent: "请输入内容",
        tip1: "您当前的工作内容会回退到此版本，是否继续?",
        tip2: "提示",
        ok: "确定",
        cancel: "取消",
        tip3: "版本已回退!",
        tip4: "版本回退失败!",
        tip5: "确认删除此版本?",
        tip6: "提示",
        tip7: "删除成功!",
    },
    printReview: {
        componyUrl: "jtworkflow.jingtengtech.com",
        word1: "未上传参考图片",
        word2: "二维码参考位置",
    },
    projectInfo: {
        title: "属性",
        name: "名称",
        description: "描述",
        type: "选择项目类型",
        enableOfflineMode: "是否允许离线",
        enableOfflineModeTip: "允许用户下载到本地离线使用",
        projectCover: "项目封面",
        // noCover: "无封面",
        noCover: "建议图片尺寸不小于640*360，使用横板无透明背景图片，非16 : 9的图片会被填充剪裁，仅支持JPG/PNG/JPEG格式",
        clickUpload: "点击上传",
        positionType: "定位方式",
        baseModel: "二维码与基础模型",
        word1: "(已有模型)无基础模型封面",
        // word2: "无基础模型",
        word2: "模型建议FBX/GLB格式，大小不超过50M",
        word3: "二维码物体的相对位置照片",
        // word4: "无参照图象",
        word4: "请保证二维码与参照物完整出现在画面中；图片建议JPG格式",
        word5: "请文字描述二维码张贴的位置与物体的关系",
        word6: "在此描述位置关系",
        word7: "模型使用尺寸",
        word8: "模型重力方向",
        word9: "素材库",
        word10: "确认删除'基础模型'?",
        word11: "基础模型",
        delete: "删除",
        cancel: "取消",
        tip1: "确认删除'二维码物体的相对位置照片'?",
        tip2: "二维码定位信息",
        tip3: "删除失败!",
        tip4: "文件大小超出限制!",
        tip5: "确认删除'项目封面'?",
        tip6: "删除项目",
        tip7: "已获取此项目,可在'我的项目->已拥有项目'中查看",
        tip8: "其他客户端",
        tip9: "Apple Vision Pro",
        projectLanguage: "选择项目语音",
    },
    releaseProject: {
        title: "发布项目",
        word1: "发布此项目让其他用户（取决于以下可见范围选择）可以按照您设计的流程进行操作，更高效地解决问题。",
        word2: "项目发布之后，您还可以对您的流程/项目进行修改，您的修改不会影响到已经发布的项目，直到您把修改后的流程/项目再次发布出去。",
        word3: "可见范围",
        word4: "所有人可见",
        word5: "仅限我的企业（企业用户专用）",
        word6: "取消",
        word7: "发布",
        word8: "项目正式上架之前会对您发布项目进行后台审核，如有",
        word9: "禁止发布的内容",
        word10: "存在，发布的项目将不会通过。",
        tip1: "您不是企业用户,不可选择此选项",
        tip2: "提示",
        tip3: "确定",
        tip4: "已提交审核",
        tip5: "提交审核失败",
        tip6: "项目正在审核中，请前往'我的项目-->审核记录'中查看",
        auditTitle: "提交审核",
        inputDes: "填写备注",
        submit: "提交",
        tip7: "此项目已在审核中，请耐心等待...",
        tip8: "发布成功！",
        tip9: "发布失败！",
    },
    share: {
        word1: "获得链接的用户均可访问此项目",
        word2: "复制链接",
    },
    taskChart: {
        inputStep: "输入步骤",
        taskStep: "任务步骤",
        word1: "框选",
        drag: "拖动",
        word2: "操作步骤",
        word3: "判定条件",
        newTask: "新建任务",
        select: "请选择",
        saving: "正在保存...",
        copy: "复制属性",
        clone: "克隆步骤",
        paste: "粘贴步骤",
        setStartStep: "设为起始步骤",
        delete: "删除",
        tip1: "这个流程图中没有任何步骤,是否保存?",
        tip2: "提示",
        ok: "确定",
        cancel: "取消",
        saveSuccess: "保存成功!",
        saveError: "保存失败!",
        tip3: "不能复制'开始步骤'、'任务步骤'",
        tip4: "获取资源失败,复制失败",
        tip5: "不能克隆'开始步骤'、'任务步骤'",
        tip6: "不能删除'开始步骤',请重新选择",
        tip7: "删除列表中存在被克隆的步骤,继续操作会导致其所有克隆体都被删除,是否继续?",
        tip8: "删除步骤",
        tip9: "此步骤有关联的克隆体,继续操作会导致其所有克隆体都被删除,是否继续?",
        tip10: "只有常规步骤可设置为开始步骤",
        tip11: "将此步骤设置为'开始步骤'会导致此步骤的所有克隆体被删除,是否执行此操作?",
        tip12: "请注意",
        tip13: "任务步骤和开始步骤不可被克隆",
        tip14: "开始步骤创建失败！请重新进入此任务",
    },
    taskNewDatail: {
        back: "后退",
        save: "保存",
        history: "历史版本",
        projectSetter: "项目设置",
        delete: "删除",
        get: "获取",
        got: "已拥有",
        config: "配置",
        preview: "预览",
        release: "发布",
        share: "分享",
        task: "任务",
        search: "搜索",
        newTask: "新建任务",
        pleaseEnterContent: "请输入内容",
        moveUp: "上移",
        moveDown: "下移",
        resetTaskName: "重命名",
        tip1: "提示",
        tip2: "检测到流程图有未保存的内容,是否保存?",
        cancel: "取消",
        noSave: "不保存",
        tip3: "以获取此项目,可在'我的项目->已拥有项目'中查看",
        tip4: "每个项目中至少需要包含一个任务!",
        tip5: "此操作将永久删除该任务, 是否继续?",
        tip6: "提示",
        ok: "确定",
        deleted: "已删除",
        tip7: "检测到流程图有未保存的内容,是否保存?",
        tip8: "提示",
        tip9: "请先给当前任务内添加步骤并保存!",
        tip10: "将同时下架该项目,删除后无法恢复,确认删除该项目吗?",
        tip11: "删除项目",
        tip12: "删除成功！",
        toggleView: "切换视图",
        tip13: "检测到未保存的步骤配置信息，是否在切换步骤之前前保存修改？",
        tip14: "切换步骤",
    },
    uploadAsset: {
        title: "上传素材",
        uploadGile: "上传文件",
        fileName: "文件名",
        getCover: "截图",
        changeFile: "更换文件",
        uploadThubnail: "上传缩略图",
        changeThubnail: "更换缩略图",
        cancel: "取消",
        ok: "确认",
        tip1: "请输入名称",
        tip2: "请选择文件",
        sizeError: "文件大小超出限制!",
        uploadError: "文件上传错误，请重试",
        pleaseSelectFile: "请选择文件",
        tip3: "上传成功!",
        tip4: "建议上传视频MP4格式分辨率≥720P；图片JPG/PNG/JPEG格式分辨率≥1080P；PDF文件；模型FBX/GLB格式Y轴向上,大小不超过50M",
        tip5: "其中视频大小不超过200M，其他文件不超过20M",
        tip6: "上传模型、图片、视频文件会自动生成缩略图",
        tip7: "手动上传的图片大小不超过20M，仅支持JPG/PNG/JPEG格式，非1:1的图片会被填充剪裁",
        tip8: "此项目不支持3D素材类型",
    },
    webGL: {
        word1: "正在进入配置空间...",
        word2: "正在进入预览空间...",
        tip1: "检测到有未保存的内容,是否保存?",
        tip2: "提示",
        ok: "确认",
        cancel: "取消",
        tip3: "图片",
        tip4: "视频",
        tip5: "文字",
        tip6: "再次输入内容标题",
        tip7: "此处为使用者填写",
        tip8: "不可清空模板",
        save: "保存",
        noSave: "不保存",
        refStepNote: '当前步骤为克隆步骤，如若修改将会修改本体以及所有克隆体的内容。'
    },
    home: {
        word1: "天下无难事",
        word2: "以全新虚实结合的全新形式创作、管理、分享标准化的工作经验和可视化的知识",
        word3: "个人免费使用",
        word4: "企业用户计划",
        word5: "全球首个企业元宇宙流程指导平台",
        word6: "基于XR(VR/AR/MR)技术的知识分享与交流",
        word7: "轻松创建标准化流程指导",
        word8: "轻松创建虚实结合指导流程",
        word9: "自由",
        word10: "轻松",
        word11: "支持全球头部企业级XR设备",
        word12: "全球范围眼镜设备覆盖",
        word13: "Hololens2",
        word14: "虚实结合的可视化指导,答案就在问题边上",
        word15: "流程问题，即时解决",
        word16: "无忧",
        word17: "流畅",
        word18: "支持手机设备AR查看",
        word19: "掏出手机便可使用",
        word20: "便捷",
        word21: "iOS",
        word22: "Android",
        word23: "超过10w+全家可用素材",
        word24: "轻松创建虚实结合指导流程",
        word25: "自主",
        word26: "分类",
        word27: "收藏",
        word28: "兼容VR设备的虚拟操作流程",
        word29: "虚拟现实呈现全流程解决方案",
        word30: "VR",
        word31: "强大且方便的编辑工具",
        word32: "多种平台，多种交互方式的表达与创作",
        word33: "马上体验",
        word34: "分享你的流程和知识",
        word35: "多种方式的分享你的经验",
        word36: "马上加入",
        word37: "为全球各大企业所信任",
        word38: "已服务过全球各行业客户",
        word39: "获取企业用户专属计划",
        word40: "多平台支持的客户端",
        word41: "支持MR、AR、VR多平台客户端使用，无拘无束",
        word42: "HoloLens2客户端下载",
        word43: "HoloLens",
        word44: "版本号：4.0",
        word45: "2022-9-20",
        word46: "转到商店",
        word47: "iOS客户端下载",
        word48: "Pico Neos客户端下载",
        word49: "Pico",
        word50: "请到Pico B端商店进行下载安装",
        word51: "Apple Vision Pro",
        word52: "Pico 4",
    },
    dataCharts: {
        myCreate: "我创建的",
        myUsed: "我使用的",
        word1: "项目创建数量",
        word2: "总使用人数",
        word3: "总使用次数",
        word4: "总使用时长",
        word5: "总点赞数",
        word6: "总收藏数",
        nodata: "暂无数据",
        createPro: "去创建",
        goRelease: "去探索",
        word7: "项目名称",
        word8: "操作次数",
        word9: "平均每次花费时长",
        word10: "最后一次操作时长",
        word11: "总操作项目数",
        word12: "总操作次数",
    },
    createView: {
        projectName: "项目名称",
        userCount: "使用者数量",
        word1: "使用次数",
        word2: "完成率",
        word3: "总操作时长(H)",
        word4: "平均操作时长(H)",
        word5: "转发数",
        word6: "收藏数",
        word7: "点赞数",
        word8: "版本列表",
    },
    exitChart: {
        word1: "流程断点图",
        word2: "次数",
    },
    topDurationChart: {
        word1: "流程难点图",
        word2: "时间(分钟)",
    },
    prevViewThumbnail: {
        word1: "预览视窗",
        word2: "双击进入任务步骤",
    },
    inputTemplate: {
        word1: "在此输入内容标题",
        word2: "此处为使用者填写",
        word3: "图片",
        word4: "视频",
        word5: "文字",
    },
    textVue: {
        word1: "请输入内容",
        word2: "你还没有输入任何内容呢！",
    },
    mediaVue: {
        word1: "拼命加载中...",
        word2: "点此上传",
        word3: "或",
        word4: "素材库",
        word5: "可上传JPG/PNG/JPEG格式图片、MP4格式视频、PDF文件，文件不超过20M",
        word6: "确认删除此文件？",
        word7: "提示",
        word8: "确定",
        word9: "取消",
        word10: "删除成功！",
        word11: "文件大小超出限制（20MB）",
        word12: "文件大小超出限制（200MB）",
        word13: "文件格式暂不支持,请重新上传",
    },
    templateChose: {
        word1: "请选择模板",
        word2: "选择模板",
    },
    templateDialog: {
        word1: "模板选择",
        word2: "取 消",
        word3: "确 定",
    },
    prevTemplate2D: {
        noDes: "暂无描述",
        word1: "即将跳转到该任务中，是否确认跳转?",
        tip: "提示",
        word2: "跳转",
        word3: "留在此处",
        media: {
            noUpload: "暂未上传",
        },
    },
    materialLibrary: {
        title: "素材选择",
        cancal: "取消",
        submit: "确定",
    },
    myPublished: {
        all: "全部",
        enterpriceOnly: '企业公开',
        draft: "草稿",
        unsubmitted: "未提交",
        audit: "待审核",
        auditPass: "审核通过",
        auditFail: "审核拒绝",
        reSubmit: "重新提交",
        reSubmitTip: "确认重新提交审核此项目?",
        confirm: "确定",
        cancel: "取消",
        submited: "已提交",
        summitErrorTitle: '提交失败',
        submitError: "提交失败,请检查网络或服务",
        submitErrorAuditing: "已有正在审核中的版本，请先撤回审核中的版本后再提交",
        auditting: "审核中",
        noPass: "未通过",
        showReson: "查看原因",
        withdraw: "撤回",
        offline: "下架",
        goEdit: "去修改",
        allProcess: "全部公开",
        pass: "已通过",
        reason: "拒绝原因",
        know: "知道了",
        withdrawTip: "确认撤回已提交的审核?",
        deleteSuccess: "删除成功",
        deleteFail: "删除失败",
        version: "版本: ",
        nearlyTime: "最近编辑时间: ",
        auditHistory: "审核记录",
        deleteProject: "删除项目",
        edit: "编辑",
        offlineTip: "下架",
        viewCurrentVersion: "查看当前版本",
        oldProjectName: "原项目名称：",
        deleteFromDrafts: "从草稿箱删除",
    },
    cancelPublish: {
        title: "下架",
        coTitle: "选择范围",
        currentVersion: "下架当前版本",
        currentVersionDescription: "已经获取该版本的用户将不能使用该版本，如有上一个版本，还可继续使用上一个版本",
        allVersions: "下架整个项目",
        allVersionDescription: "已经获取该版本的用户将不能使用该版本，所有版本（包括审核中版本）都会被下架",
        confirm: "下架",
        cancel: "取消",
    },
    auditProject: {
        auditProject: "待审核项目",
        pass: "通过",
        reject: "拒绝",
        allProcess: "全部流程",
        auditting: "审核中",
        noPass: "未通过",
        passed: "已通过",
        reviewResultCommitted: "审核结果提交成功",
        reviewResultCommitFailed: "审核结果提交失败",
        confirm: "确定",
        cancel: "取消",
        tip3: "请填写拒绝的原因",
        errorTip: "内容不能为空",
        tip4: "审核结果已提交",
        showRemarks: "查看备注",
        tip5: "提示",
        tip6: "确认此项目通过审核？",
    },
    auditHistory: {
        auditLog: "审核记录",
        projectName: "项目名称",
        version: "版本: ",
        auditTime: "审核时间: ",
        submitRemarks: "提交备注",
        reason: "拒绝原因",
        word1: "暂无审核记录",
        submitTime: "提交时间: ",
        withdrawTime: "撤回时间: ",
    },
    longPolling: {
        logoutTip: "下线提示",
        logoutContent: "您的账号在其他设备登录，您已被迫下线",
        close: "关闭",
    },
}