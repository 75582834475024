import { getAllReleases, saveAsset, saveAssetNew, getShareLevels, getUploadSas } from "@/api/workflow";
import {
  AssetCategory, AssetType, fileMaxSize,
  videoMaxSize, modelMaxSize
} from "@/utils/asset"
import { getBlobReadSas, uploadAsset } from "@/utils/upload";
import { BlockBlobClient, AnonymousCredential } from "@azure/storage-blob";
import { uuid } from "vue-uuid";
import { dataURLtoBlob } from "@/utils/dataURLtoBlob"
// import ModelLoader from "@/views/components/modelLoader/modelLoader.vue"
import { ElMessage } from 'element-plus'
import { compressImg, dataURLtoFile, convertBase64UrlToBlob } from "@/utils/compress.js";
export default {
  name: 'UploadAsset',
  // model: {
  //   prop: 'itemData',
  //   event: 'itemChanged'
  // },
  // components: { ModelLoader },

  props: {
    show: { type: Boolean },
    projectGuid: { type: String },
    projectInfo: { type: Object }
    //   itemData: null
    //   userTitle: {type: String}
  },
  data() {
    return {
      fileType: 65535,
      tempSrc: null,
      showSwitch: false,
      // assetGuid: uuid.v4(),
      fileItem: {
        fileToUpload: null,
        thumbnail: null,
        assetName: null,
        description: null,
        // isPublic: true
        // thumbnailUrl: null,
      },
      shareLevels: [],
      projects: [],
      imagePreview: null,
      thumbnailPreview: null,
      showModelViewer: false,
      rules: {
        assetName: [
          // 请输入名称
          { required: true, message: this.$t('uploadAsset.tip1'), trigger: 'blur' },
        ],
        // description: [
        //   { required: true, message: '请输入描述', trigger: 'blur' },
        // ],
        // thumbnail: [
        //   { required: true, message: '请选择缩略图', trigger: 'blur' },
        // ],
        fileToUpload: [
          // 请选择文件
          { required: true, message: this.$t('uploadAsset.tip2'), trigger: 'blur' },
        ],
      },
      uploading: false,
      thumbnailDatas: null,
      videoSrc: null,
      accept3D: ".fbx,.obj,.glb,.gltf,.stl,video/mp4,image/*,application/pdf,.FBX,.GLB,.GLTF,.STL,VIDEO/MP4,APPLICATION/PDF",
      accept2D: "video/mp4,image/*,application/pdf,VIDEO/MP4,APPLICATION/PDF",
    }
  },
  // watch:{
  //   joinMeetingShow(val){
  //     console.log('joinMeetingShow watch:', val)
  //     this.isShow = val;
  //   }
  // },
  methods: {
    // preViewModel(){

    // },
    async thumbnailData(image) {
      console.log(image);
      // console.log(this.dataURLtoBlob(image));
      // this.tempSrc = image.value;
      // console.log([this.$refs.tempImage]);
      this.thumbnailDatas = image;
      this.thumbnailPreview = this.thumbnailDatas.sas;
      this.fileItem.thumbnail = null;
      // this.$refs.tempImage.onload = () => {
      //   const imageCanvas = this.$refs.canvas
      //   const imageSize = { width: this.$refs.tempImage.width, height: this.$refs.tempImage.height }
      //   imageCanvas.height = imageSize.height;
      //   imageCanvas.width = imageSize.width;
      //   const imageContext = imageCanvas.getContext('2d')
      //   imageContext.drawImage(this.$refs.tempImage,
      //     0, 0, imageSize.width, imageSize.height,
      //     0, 0, imageSize.width, imageSize.height)
      //   let aaa = dataURLtoBlob(image);
      //   console.log(aaa);
      //   // const aaa = await this.getBufferFromDataUrl(image);
      //   aaa.name = 'aaa.png';
      //   this.fileItem.thumbnail = aaa;
      //   this.thumbnailPreview = null;
      // }
    },
    close() {
      console.log("handleClose");
      this.showSwitch = false
      window.removeEventListener("message", this.receiveMessage);
      this.fileItem = {
        fileToUpload: null,
        thumbnail: null,
        assetName: null,
        description: null,
      }
      this.thumbnailDatas = null;
      this.imagePreview = null;
      this.thumbnailPreview = null;
      this.videoSrc = null;
    },
    setSwitch(bool) {
      this.showSwitch = bool;
    },
    async open() {
      // if (existAssetGuid)
      //   this.assetGuid = existAssetGuid
      // else

      console.log('uploadasset open', this.projectGuid)

      this.assetGuid = uuid.v4();

      this.shareLevels = await getShareLevels();
      this.projects = (await getAllReleases(0, 1000)).data;
      this.fileItem = {
        fileToUpload: null,
        thumbnail: null,
        assetName: null,
        description: null,
        // shareLevel: this.shareLevels.filter(x => { return x.shareLevel == 1 })[0].shareLevel
        shareLevel: 2
      };

      if (this.projectGuid) {
        // this.fileItem.shareLevel = 2;
        this.fileItem.projectGuid = this.projectGuid;
      }

      this.imagePreview = null;
      this.thumbnailPreview = null;
      this.showModelViewer = false;


      // console.log('this.projects', this.projects)

      // console.log('this.fileItem', this.fileItem.isPublic)
    },

    getBufferFromDataUrl(dataUrl) {
      console.log(dataUrl);
      const based64str = dataUrl.split(',')[1]
      return Buffer.from(based64str, 'base64')
    },

    async getDataUrlFromBuffer(buffer) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = (e) => {
          console.log('on load', reader, e)
          resolve(reader.result)
        }
        reader.onerror = (e) => {
          reject(e)
        }

        reader.onabort = (e) => {
          reject(e)
        }

        reader.readAsDataURL(new Blob([buffer]))
      })
    },

    closeModelViewer(data) {
      this.showModelViewer = false;
      if (data) {
        this.fileItem.thumbnail = data.thumbnailFile;
        // this.fileItem.thumbnailUrl = this.fileItem.imageFileUrl;

        console.log('closeModelViewer', data)

        this.thumbnailPreview = URL.createObjectURL(data.thumbnailFile);
      }
    },

    async getBlobFromCanvas(imageCanvas) {
      return new Promise((res, rej) => {
        imageCanvas.toBlob((blob) => {
          res(blob)
        })
      })
    },

    async uploadImageLoaded(e, sourceRef) {
      if (this.thumbnailDatas && this.thumbnailDatas.path) {
        return;
      }
      const imageCanvas = this.$refs.canvas
      console.log(e);
      console.log(sourceRef);
      const imageSize = { width: this.$refs[sourceRef].imgWidth, height: this.$refs[sourceRef].imgHeight }
      // const imageSize = {
      //   width:256,
      //   height
      // }


      let maxSize = 300;
      let scales = 16 / 9
      let final_size = {
        width: imageSize.width,
        height: imageSize.height
      }

      if (
        imageSize.width > maxSize ||
        imageSize.height > maxSize
      ) {
        if (
          imageSize.width > imageSize.height
        ) {

          scales =
            imageSize.height / imageSize.width;
          final_size.height = maxSize * scales;
          final_size.width = maxSize
        } else if (
          imageSize.height > imageSize.width
        ) {
          final_size.height = maxSize;
          scales =
            imageSize.width / imageSize.height;
          final_size.width = final_size.height * scales;
        }
      } else {
        final_size.width = imageSize.width;
        final_size.height = imageSize.height;
      }


      console.log(imageCanvas);
      imageCanvas.height = final_size.height;
      imageCanvas.width = final_size.width;
      const imageContext = imageCanvas.getContext('2d')
      imageContext.drawImage(
        // e.path[0],
        this.$refs[sourceRef].container.children[0],
        0,
        0,
        final_size.width,
        final_size.height)



      /*
      if (imageSize.width / 4 * 3 < imageSize.height){
        imageContext.drawImage(e.path[0], 
          0, (imageSize.height - imageSize.width / 4 * 3) / 2, imageSize.width, imageSize.width / 4 * 3,
          0, 0, imageSize.width, imageSize.height, );
      }
      else{
        imageContext.drawImage(e.path[0], 
          (imageSize.width - imageSize.height / 3 * 4) / 2, 0, imageSize.height / 3 * 4, imageSize.height,
          0, 0, imageSize.width, imageSize.height,) 
      }
      */
      let file_base64 = imageCanvas.toDataURL('jpeg', 0.9);
      let file_blob = dataURLtoFile(file_base64, 'jpeg');

      // const aaa = await this.getBlobFromCanvas(imageCanvas);
      // aaa.name = 'aaa.jpeg';

      // imageCanvas.width = 0;

      this.fileItem.thumbnail = file_blob;
      // this.fileItem.thumbnailUrl = this.fileItem.imageFileUrl;

      this.thumbnailPreview = null;
      console.log('imageContext', file_blob)
    },
    getFileTypeByFileName(fileName) {
      let modelTypes = [".fbx", ".obj", ".glb", ".gltf", ".stl"];
      let pictureTypes = [".jpg", ".jpeg", ".png", ".tif", ".bmp"];
      let videoTypes = [
        ".mp4",
        ".rmvb",
        ".rm",
        ".asf",
        ".divx",
        ".mpg",
        ".mpeg",
        ".wmv",
        ".mkv",
        ".vob",
      ];
      let PDFTypes = ["PDF", "pdf"];
      let type = false;
      modelTypes.forEach((value, index) => {
        if (fileName.includes(value)) {
          type = "model";
        }
      });
      pictureTypes.forEach((value, index) => {
        if (fileName.includes(value)) {
          type = "picture";
        }
      });
      videoTypes.forEach((value, index) => {
        if (fileName.includes(value)) {
          type = "video";
        }
      });
      PDFTypes.forEach((value, index) => {
        if (fileName.includes(value)) {
          type = "PDF";
        }
      });
      return type;
    },
    async onUploadChange(f, fileList) {
      console.log(f);
      console.log(AssetCategory);
      let maxSize = fileMaxSize;
      let videoMax = videoMaxSize;
      let modelMax = modelMaxSize;
      let fileTypeTemp = this.getItemType(f.raw);
      console.log(fileTypeTemp);
      if (fileTypeTemp === AssetCategory.Video && f.raw.size > videoMax) {
        //视频文件 最大200MB
        console.log(fileTypeTemp);

        ElMessage.error(this.$t('uploadAsset.sizeError'))
        return
      }
      if ((fileTypeTemp === AssetCategory.Image || fileTypeTemp === AssetCategory.PDF) && f.raw.size > maxSize) {
        // 文件大小超出限制
        console.log(fileTypeTemp);
        ElMessage.error(this.$t('uploadAsset.sizeError'))
        return
      }
      if (fileTypeTemp === AssetCategory.ThreeD && f.raw.size > modelMax) {
        // 3D模型文件 最大50MB
        console.log(fileTypeTemp);
        ElMessage.error(this.$t('uploadAsset.sizeError'))
        return
      }
      this.thumbnailDatas = null;
      this.videoSrc = null;
      // console.log(f);
      // console.log(fileList);
      f.raw.itemType = fileTypeTemp;
      this.fileType = fileTypeTemp;
      // this.fileItem.fileToUpload.itemType = this.getItemType(f.raw);
      console.log(this.fileType);
      this.fileItem.fileToUpload = f.raw;


      // let testDom = document.getElementById("testCanvas");
      // console.log(testDom);
      // testDom.appendChild(file.canvas)
      // const bbb = await this.getBlobFromCanvas(file.canvas);
      // console.log(bbb);

      // bbb.name = f.raw.name;
      // console.log(bbb);
      // this.fileItem.fileToUpload = bbb;

      this.fileItem.thumbnail = null;
      this.fileItem.assetName = f.raw.name;
      if (f.raw.itemType == AssetCategory.Image) {
        let file = await compressImg(f.raw, 0.95);
        console.log(file);
        console.log(file.canvas);
        file.blob.name = f.raw.name;
        this.fileItem.fileToUpload = file.blob;
        this.imagePreview = URL.createObjectURL(f.raw)

      }
      else if (f.raw.itemType == AssetCategory.ThreeD) {
        if (this.projectInfo?.editType === 1) {
          this.fileItem = {
            fileToUpload: null,
            thumbnail: null,
            assetName: null,
            description: null,
          }
          ElMessage.error(this.$t("uploadAsset.tip8"))
          return
        }
        // this.$refs.modelViewer.open();
        this.showModelViewer = true;
        this.imagePreview = null
        let urlData = URL.createObjectURL(f.raw)
        // this.$refs.modelLoader.setShow(true, "localUrl", {
        //   fileName: f.raw.name,
        //   fileData: urlData
        // })
        this.$emit("preViewModel", {
          bool: true,
          type: "localUrl",
          fileName: f.raw.name,
          fileData: urlData
        })
      } else if (f.raw.itemType === AssetCategory.Video) {

        this.videoSrc = URL.createObjectURL(f.raw);
        console.log(this.videoSrc);
        this.$nextTick(() => {

          let video = this.$refs.videoContainer;
          video.currentTime = 1
          video.addEventListener("loadeddata", async (e) => {//加载完成事件，调用函数

            this.$refs.canvas.width = video.offsetWidth;
            this.$refs.canvas.height = video.offsetHeight;
            this.$refs.canvas.getContext("2d").drawImage(video, 0, 0, video.offsetWidth, video.offsetHeight);
            const aaa = await this.getBlobFromCanvas(this.$refs.canvas);
            aaa.name = 'aaa.jpeg';

            // imageCanvas.width = 0;

            this.fileItem.thumbnail = aaa;
          })
        })
      }
      else {

        let pdfDefaultImg = this.$refs.pdfDefaultImg;
        this.$refs.canvas.width = pdfDefaultImg.offsetWidth;
        this.$refs.canvas.height = pdfDefaultImg.offsetHeight;
        this.$refs.canvas.getContext("2d").drawImage(pdfDefaultImg, 0, 0, pdfDefaultImg.offsetWidth, pdfDefaultImg.offsetHeight);
        const aaa = await this.getBlobFromCanvas(this.$refs.canvas);
        aaa.name = 'aaa.jpeg';

        // imageCanvas.width = 0;

        this.fileItem.thumbnail = aaa;
        this.imagePreview = null
      }

    },
    cutPng() {

      let video = this.$refs.videoContainer;
      this.$refs.canvas.width = video.offsetWidth;
      this.$refs.canvas.height = video.offsetHeight;
      this.$refs.canvas.getContext("2d").drawImage(video, 0, 0, video.offsetWidth, video.offsetHeight)
    },
    // handleFileUpload(event){
    //   console.log(event.target.baseURI);
    //   console.log([this.$refs.test]);
    //   console.log(this.$refs.test.value);
    //   console.log(this.$refs.test.files);
    // },
    async onUploadThumbnail(f, fileList) {

      this.thumbnailPreview = URL.createObjectURL(f.raw)

    },

    async customUpload() {
      try {

        console.log('this.fileItem', this.fileItem);
        let result = await this.uploadStageFile(this.fileItem.fileToUpload);

        let thumbnailResult = null;
        if (this.fileItem.thumbnail) {
          thumbnailResult = await this.uploadStageFile(this.fileItem.thumbnail);
        }
        //   this.previewUrl = result.sas;
        let fileItem = {
          assetGuid: this.assetGuid,
          assetType: AssetType.Custom,
          assetName: this.fileItem.assetName,
          description: this.fileItem.description,
          fileSize: this.fileItem.fileToUpload.size,
          thumbnailPath: thumbnailResult ? thumbnailResult : this.thumbnailDatas.path ? this.thumbnailDatas.path : null,
          filePath: result,
          assetCategory: this.fileType,
          originFileName: this.fileItem.fileToUpload.name,
          shareLevel: 2,
          projectGuid: this.projectGuid,
          isDeleted: false
        }

        return await saveAssetNew(fileItem);

      }
      catch (e) {
        // 文件上传错误，请重试
        this.$message({ type: "error", message: this.$t('uploadAsset.uploadError') })
        console.log(e);
      }
    },

    getItemType(file) {

      // console.log(file, 'fbx,obj,gltf,glb,ply,stl'.includes('glb'));
      const result = file.name.split(".");
      const ext = result ? result[result.length - 1] : '';

      let itemType = AssetCategory.Others
      if (file.type == "video/mp4") {
        itemType = AssetCategory.Video
      }
      else if (file.type == "application/pdf") {
        itemType = AssetCategory.PDF
      }
      else if ("image/jpeg,image/gif,image/png".includes(file.type.toLowerCase())) {
        itemType = AssetCategory.Image;
      }

      if ('fbx,obj,gltf,glb,ply,stl'.includes(ext.toLowerCase())) {
        itemType = AssetCategory.ThreeD;
      }

      // console.log('upload file type:', itemType, ext)
      return itemType;

      // await this.customUpload(f, itemType)
    },


    async ok(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            if (!this.fileItem.fileToUpload)
              // 请选择文件
              throw this.$t('uploadAsset.pleaseSelectFile')

            this.uploading = true;
            const result = await this.customUpload();
            this.uploading = false;

            // await saveAsset()
            // this.$alert('操作完成', '素材上传成功',
            //   {
            //     confirmButtonText: '确定',
            //     type: 'success',
            //   });
            ElMessage({
              showClose: true,
              // 上传成功
              message: this.$t('uploadAsset.tip3'),
              type: 'success',
            })
            this.$emit('onClose', result.result)

          }
          catch (e) {
            console.log(e);
            this.$message(e)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
      //   this.userPickerShow = false;

      // this.$refs.multipleTable.clearSelection();
    },

    cancel() {
      // this.show = false;
      // this.$message({ type: 'info', message: 'okok' })
      console.log("取消i");
      this.$emit('onClose', null)
    },
    async uploadStageFile(file) {
      if (!file) {
        return "";
      }
      try {
        const { sas, path } = await getUploadSas(
          file.name || `${uuid.v4()}.jpeg`
        );

        const blobClient = new BlockBlobClient(sas, new AnonymousCredential());
        await blobClient.uploadData(file, {
          blockSize: 512 * 1024,
          concurrency: 2,
        });

        // await saveMyProfilePhoto(path)
        return path;
      } catch (e) {
        return Promise.reject(e);
      }
    },
  }
}