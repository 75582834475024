import { h } from "@logicflow/core";
import { RectResize } from "@logicflow/extension";
class CustomDiamondModel extends RectResize.model {
    setAttributes() {
        this.text.x = this.x;
        this.text.y = this.y;
        this.menu = [
            {
                className: "items",
                // 复制属性
                text: `${this.properties.language.copy} Ctrl + C`,
                callback: (node) => {
                    this.graphModel.eventCenter.emit("nodeCopy", node);
                },
            },
            {
                className: "items",
                // 克隆步骤
                text: `${this.properties.language.clone} Ctrl + D`,
                callback: (node) => {
                    this.graphModel.eventCenter.emit("nodeClone", node);
                },
            },
            {
                // 删除
                text: `${this.properties.language.delete} Delete`,
                callback: (node) => {
                    this.graphModel.eventCenter.emit("nodeDelete", node);
                },
            },
        ];
    }
    initNodeData(data) {
        super.initNodeData(data);
        this.width = 160;
        this.height = 64;
        const rule1 = {
            message: "不允许连接到'开始步骤'",
            validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
                if (targetNode.type === "StartShape") {
                    return false
                } else {
                    return true
                }
            }
        }
        const rule3 = {
            message: "不允许连接到自己",
            validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
                if (sourceNode.id === targetNode.id) {
                    return false
                } else {
                    return true
                }
            }
        }
        this.sourceRules.push(rule1, rule3);
    }
    //重写节点文本样式
    getTextStyle() {
        const style = super.getTextStyle();
        style.color = "#333333"
        style.padding = "0 25%";
        // if (this.properties.stepInfo.instanceShapes && this.properties.stepInfo.instanceShapes.length > 0) {
        // 1.0.0
        // if (this.properties.cloneNodeActive) {
        //     style.color = "#333333"
        // } else {
        //     style.color = "#ffffff"
        // }
        //1.0.0
        // } else {
        //     if (this.properties.cloneNodeActive) {
        //         style.color = "#333333"
        //     } else {
        //         style.color = "#ffffff"
        //     }
        // }
        return style;
    }
    //重写节点样式
    getNodeStyle() {
        const style = super.getNodeStyle();
        style.strokeWidth = 2;
        if (this.properties.onLinkLine) {
            style.strokeDasharray = "0,0";
            //克隆源步骤高亮状态
            if (this.properties.cloneNodeActive || this.isSelected) {
                style.strokeDasharray = "0";
                // style.fill = "#ffffff";
                // style.stroke = "#4582ff"
                style.fill = "#ffffff";
                style.stroke = "#4582FF"
            } else {
                // style.fill = "#4582ff";
                // style.stroke = "#4582ff"
                style.fill = "#ffffff";
                style.stroke = "#000"
            }
            //webgl页面被选中的外边框高亮状态
            if (this.properties.isWebGl || this.properties.isAuthor) {
                if (this.isSelected) {
                    // style.stroke = "#32ff32"
                    style.stroke = "#4582FF"
                } else {
                    // style.stroke = "#4582ff"
                    style.stroke = "#000"
                }
            }
        } else {
            style.strokeDasharray = "4,4";
            //是克隆源步骤
            if (this.properties.cloneNodeActive || this.isSelected) {
                style.strokeDasharray = "0";
                // style.stroke = "#4582ff"
                // style.fill = "#ffffff";
                style.stroke = "#4582FF"
                style.fill = "#fff";
            } else {
                // style.fill = "#C5C5C5";
                // style.stroke = "#C5C5C5"
                style.fill = "#fff";
                style.stroke = "#000"
            }
            //webgl页面被选中的外边框高亮状态
            if (this.properties.isWebGl || this.properties.isAuthor) {
                if (this.isSelected) {
                    // style.stroke = "#32ff32"
                    style.stroke = "#4582FF"
                } else {
                    // style.stroke = "#C5C5C5"
                    style.stroke = "#000"
                }
            }
        }
        // if (this.properties.onLinkLine) {
        //     style.fill = "#4582ff";
        //     style.stroke = "#4582ff"
        // } else {
        //     style.fill = "#C5C5C5";
        //     style.stroke = "#C5C5C5"
        // }

        return style;
    }
    //重写锚点样式
    getAnchorStyle(anchorInfo) {
        const style = super.getAnchorStyle(anchorInfo);
        // style.fill = "#32ff32";
        // style.stroke = "#32ff32";
        // style.fill = "#4582FF";
        style.stroke = "#4582FF";
        style.r = 8;
        style.strokeWidth = 2;
        style.hover.fill = "transparent";
        style.hover.stroke = "transpanrent";
        style.className = "lf-hide-default";
        return style;
    }
    //重写节点外边框样式
    getResizeOutlineStyle() {
        return {
            strokeWidth: 2,
            // stroke: "#32ff32",
            stroke: "#4582FF",
        }
    }
    //重写节点缩放四个点样式
    getControlPointStyle() {
        return {
            strokeWidth: 2,
            width: 14,
            height: 14,
            fill: "#FFFFFF",
            // stroke: "#32ff32",
            stroke: "#4582FF",
        };
    }
}
class DiamondNode extends RectResize.view {
    getResizeShape() {
        const { x, y, width, height } = this.props.model;
        const style = this.props.model.getNodeStyle();
        const pointList = [
            [x + 0.5 * width, y],
            [x, y + 0.5 * height],
            [x - 0.5 * width, y],
            [x, y - 0.5 * height]
        ];
        const points = pointList.map((item) => {
            return `${item[0]},${item[1]}`;
        });
        const attrs = {
            ...style,
            x,
            y,
            width,
            height,
            points: points.join(" ")
        };

        return h("g", {}, [h("polygon", { ...attrs })]);
    };
}
export default {
    type: "judge",
    view: DiamondNode,
    model: CustomDiamondModel
};
