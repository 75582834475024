<template>
  <div v-if="linkItem" class="main">
    <!-- <el-form ref="form" > -->
    <!-- 设置连接文字 -->
    <!-- 设置连线文字内容 -->
    <div class="linkText">{{$t('editLink.title')}}</div>
    <!-- 设置条件 -->
    <el-input
      ref="focusedItem"
      v-model="linkName"
      :placeholder="$t('editLink.placeholder')"
      :style="{ width:'7rem' }"

    >
    </el-input>
    <!-- </el-form>       -->
  </div>
</template>

<script>
export default {
  name: "EditLink",
  data() {
    return {
      linkName: "",
      doingInit: false,
      linkItem: null,
      // test: ''
    };
  },

  props: {
    // show: null,
    // linkItem: {type: Object},
    lineSelectData: Object,
  },

  created() {
    // console.log('link create', this.linkItem, this.linkName)
    // this.linkName = this.linkItem.data.linkName;
  },

  beforeUnmount() {
    this.setName();

    this.linkItem = null;
    this.linkName = "";
  },

  mounted() {
    // this.linkName = this.linkItem.data.linkName;
    this.$nextTick(() => {
      if (this.$refs.focusedItem) this.$refs.focusedItem.focus();
    });
  },

  watch: {
    lineSelectData: {
      handler(newVal, oldVal) {
        console.log(newVal);
        this.init(newVal);
      },
      immediate: true,
      deep: true,
    },
    linkName(val) {
      if (!this.doingInit) {
        this.setName();
      }
    },
  },
  methods: {
    setNeedSave(val) {
      this.$emit("onNeedSave", true);
    },

    init(linkItem) {
      console.log('5555555555555',linkItem);
      this.doingInit = true;
      if (linkItem) {
        this.linkItem = linkItem;
        this.linkName = linkItem.data.linkName;
      } else {
        this.linkItem = null;
        this.linkName = "";
      }
      this.$nextTick(() => {
        this.doingInit = false;
      });
    },

    setName() {
      if (this.linkItem && this.linkItem.data) {
        this.linkItem.data.linkName = this.linkName;
        this.linkItem.setContent(this.linkName);

        console.log("link set name");
        this.setNeedSave(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .el-dialog 
.linkText{
  color: #224B77;
}
.main {
  // width: 10.625rem;
  height: 2rem;
  padding: 1rem 3rem;
  // background-color: #f6f6f6;
  border-radius: 0.19rem;
  // padding: 4rem 0.5rem;
}

:deep(.el-input__inner) {
  // width: 10.625rem;
  height: 2rem;
  min-width: 6rem !important;
  background: #f6f6f6;
  border: 0.06rem solid #00de24;
  font-size: 0.875rem;
}
</style>