import LangManager from '../../utils/langManager'

const App = {
  state: () => ({
    language: LangManager.getLanguage().value
  }),

  mutations: {
  
    SET_LANGUAGE: (state, language) => {
      state.language = language
    }
  },

  actions: {
  
    setLanguage({ commit }, language) {
      console.log('setLang', language)
      LangManager.setLanguage(language)
      // document.title = LangManager.i18n.global.t('login.title')
      // console.log('setLang', LangManager.i18n.global.t('login.title'))
      // this.$i18n.locale = language
      // locale.use(Languages[language].elLang);
      commit('SET_LANGUAGE', language)
    }
  },
  getters: {}
}
export default App