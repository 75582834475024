
import request from '@/utils/request'
import { uuid } from 'vue-uuid';
const { BlockBlobClient, AnonymousCredential } = require("@azure/storage-blob");

export async function uploadAsset(file, progress, containerName)
{

    try
    {
        const container = containerName || 'assets';
       
        const ext = /\.[^\.]+$/.exec(file.name);
        const blobName = `${uuid.v4()}${ext}`;
        const resp = await request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/getUploadSas`, {blobName : blobName, container: container});

        const sas = resp.sas;
        // console.log('sas: ' + sas, blobName, resp, file);
        // new AnonymousCredential()
        const blobClient = new BlockBlobClient(sas, new AnonymousCredential());
        // console.log("blobClient", blobClient);
        await blobClient.uploadData(file, {
            blockSize: 512 * 1024,
            concurrency: 2,
            onProgress: progress
        });

        return resp;
    }
    catch(e){
        console.error('upload failed', e);
        return Promise.reject(e);
    }
}

export async function getBlobReadSas(path){
    return request.post('/api/getBlobReadSas', {path: path})
}
    