<template>
  <div>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  created: function() {
    let router = this.$router;
    // this.$store.subscribe((mutation, state) => {
    //   console.log(mutation);
    //   if (mutation.type === "setAccount") {
    //     if (state.account.isAuthorized) {
    //       if (window.location.pathname.toLowerCase() === "/login") {
    //         router.replace({ path: "/" });
    //       } else {
    //         const path = window.location.pathname;
    //         router.replace({ path: path });
    //       }
    //     } else {
    //       router.replace({ path: "/login" });
    //     }
    //   }
    // });
    // console.log(1111);
    // const user = JSON.parse(localStorage.getItem("user"));
    // if (user && user.isAuthorized) {
    //   this.$store.commit("setAccount", user);
    // } else {
    //   this.$auth.logout();
    // }
  },
  befureUnmount() {
    this.unsubscribe();
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.el-menu-item {
  font-size: 1rem;
}

.el-menu-item [class^="el-icon-"] {
  font-size: 1rem;
  vertical-align: baseline;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
<style lang="scss">
@import "@/styles/el-popover.scss";
.myTooltip {
  padding: 15px;
  border: none !important;
  width: 300px;
  font-size: 14px;
  line-height: 21px;

  > p {
    margin: 0;
    color: #aaaaaa;
  }
  > .content {
    padding: 0.8rem 1.6rem;

    color: #aaaaaa !important;
  }

  // > .el-popper__arrow::before {
  //   background: #b1bdd3 !important;
  //   border: none !important;
  // }
}
.messageWrap {
  .messageWord {
    color: #4582ff;
  }
}
</style>
