<template>
  <div class="banners">
    <!-- <swiper
      :slides-per-view="3"
      :space-between="50"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
      class="swiper-container"
    >
      <swiper-slide class="swiper-slide">
        <div class="bannerItem_logo">
          <img src="@/assets/enterprise/logo.png" alt="" />
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <div class="bannerItem_logo">
          <img src="@/assets/enterprise/logo.png" alt="" />
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <div class="bannerItem_logo">
          <img src="@/assets/enterprise/logo.png" alt="" />
        </div>
      </swiper-slide>
    </swiper> -->

    <div>
      <transition name="logo">
        <div class="bannerItem_logo" v-if="showIndexLogo === 0">
          <img src="@/assets/enterprise/pic_1.png" alt="" />
        </div>

        <div class="bannerItem_logo" v-else-if="showIndexLogo === 1">
          <img src="@/assets/enterprise/pic_2.png" alt="" />
        </div>

        <div class="bannerItem_logo" v-else-if="showIndexLogo === 2">
          <img src="@/assets/enterprise/pic_3.png" alt="" />
        </div>
      </transition>
    </div>

    <div>
      <transition name="content">
        <div class="bannerItem_contant" v-if="showIndexContent === 0">
          <!-- 区内仓储（除危险品）、分拨业务以及技术支持等 -->
          <p>{{$t('banner.word1')}}</p>
          <!-- 通过使用5G+MR技术，解决了Vantage产品抽象难以讲解的疑难问题，降低了销售经理与客户的沟通成本，提高了与客户沟通的效率。 -->
          <p>
            {{$t('banner.word2')}}
          </p>
        </div>

        <div class="bannerItem_contant" v-else-if="showIndexContent === 1">
          <!-- 开发、设计、生产高技术过程控制仪表、控制系统 -->
          <p>{{$t('banner.word3')}}</p>
          <!-- 通过使用5G+MR技术，解决艾默生在产品设计和装配过程中遇到的疑难，提高与国外专家沟通的效率，帮助用户更好、更快地解决现场遇到的问题。 -->
          <p>
            {{$t('banner.word4')}}
          </p>
        </div>

        <div class="bannerItem_contant" v-else-if="showIndexContent === 2">
          <!-- 研制、开发、生产、加工牙膏及销售水处理产品 -->
          <p>{{$t('banner.word5')}}</p>
          <!-- 基于MR技术的操作流程指导可以使培训更加直观、清晰，更容易理解，从而达到更好的培训效果。同时也可用于岗位标准化操作指导，降低误操作率。 -->
          <p>
            {{$t('banner.word6')}}
          </p>
        </div>
      </transition>
    </div>
    <div>
      <transition name="componyName">
        <div class="bannerItem_componyName" v-if="showIndexComponyName === 0">
          <div class="line"></div>
          <!-- 罗氏诊断 -->
          <div class="name">{{$t('banner.word7')}}</div>
        </div>

        <div
          class="bannerItem_componyName"
          v-else-if="showIndexComponyName === 1"
        >
          <div class="line"></div>
          <!-- 艾默生 -->
          <div class="name">{{$t('banner.word8')}}</div>
        </div>

        <div
          class="bannerItem_componyName"
          v-else-if="showIndexComponyName === 2"
        >
          <div class="line"></div>
          <!-- 联合利华 -->
          <div class="name">{{$t('banner.word9')}}</div>
        </div>
      </transition>
    </div>
    <!-- <button @click="addOne">test</button> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      showIndexLogo: 0,
      showIndexContent: 0,
      showIndexComponyName: 0,
      maxLength: 3,
    };
  },
  created() {},
  mounted() {
    setInterval(() => {
      this.addOne();
    }, 6000);
  },
  computed: {},
  watch: {},
  methods: {
    addOne() {
      if (this.showIndexLogo < this.maxLength - 1) {
        //元素消失
        this.hiddenElement(300, 600);
        //元素重现
        this.showElement(300, 600, 1000);
      } else {
        this.showIndexLogo = -0.5;
        setTimeout(() => {
          this.showIndexContent = -0.5;
        }, 300);
        setTimeout(() => {
          this.showIndexComponyName = -0.5;
        }, 600);
        //元素重现
        this.showElement(300, 600, 1000);
      }
    },
    hiddenElement(time1, time2) {
      //元素消失
      this.showIndexLogo += 0.5;
      setTimeout(() => {
        this.showIndexContent += 0.5;
      }, time1);
      setTimeout(() => {
        this.showIndexComponyName += 0.5;
      }, time2);
    },
    showElement(time1, time2, showTime) {
      //元素重现
      setTimeout(() => {
        this.showIndexLogo += 0.5;
        setTimeout(() => {
          this.showIndexContent += 0.5;
        }, time1);
        setTimeout(() => {
          this.showIndexComponyName += 0.5;
        }, time2);
      }, showTime);
    },
  },
};
</script>
<style scoped lang='scss'>
.banners {
  > div {
    position: relative;
    
  }
  > div:nth-child(1) {
    width: 100%;
    height: 3rem;
  }
  > div:nth-child(2) {
    width: 100%;
    height: 11rem;
  }
  > div:nth-child(3) {
    width: 100%;
    height: 3.5rem;
  }
  width: 53%;
  text-align: left;
  //   background: #ccc;
  .bannerItem_logo {
    width: 130px;
    height: 3rem;
    > img {
      width: 100%;
    }
  }
  .bannerItem_contant {
    height: 11rem;
    // margin-top: 3rem;
    > p {
      margin: 0;
      padding-top: 2rem;
    }
    > p:nth-child(1) {
      font-size: 24px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #383838;
      line-height: 34px;
    }
    > p:nth-child(2) {
      color: #9b9b9b;
      font-weight: 400;
      color: #9b9b9b;
      line-height: 25px;
    }
  }
  .bannerItem_componyName {
    display: flex;
    justify-content: right;
    align-items: center;
    padding-top: 1.5rem;
    width: 100%;
    .line {
      width: 5rem;
      height: 2px;
      background: #878787;
    }
    .name {
      font-size: 1.1rem;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #212121;
      line-height: 1.7rem;
    }
  }
}
// .logo-enter-active {
//   animation: goIn ;
//   animation-direction: 0.5s;
// }
.logo-enter-active,
.logo-leave-active {
  transition: all 2s;
}

.logo-enter-from /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(40vw);
  opacity: 0;
}
.logo-enter-to {
  opacity: 1;
  transform: translateX(0rem);
}
.logo-leave-to {
  position: absolute;
  transform: translateX(-40vw);
  opacity: 0;
}

.content-enter-active,
.content-leave-active {
  transition: all 2s;
}
.content-enter-from /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(40vw);
  opacity: 0;
}
.content-enter-to {
  transform: translateX(0rem);
}
.content-leave-to {
  position: absolute;
  transform: translateX(-40vw);
  opacity: 0;
}

.componyName-enter-active,
.componyName-leave-active {
  transition: all 2s;
}
.componyName-enter-from /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(40vw);
  opacity: 0;
}
.componyName-leave-to {
  position: absolute;
  transform: translateX(-40vw);
  opacity: 0;
}
@keyframes goIn {
  0% {
    transform: translateX(40vw);
  }
  100% {
    transform: translateX(0vw);
  }
}
/* 当屏幕宽度在 0 到 1200 像素之间时应用的样式 */
@media screen and (max-width: 1200px) {
.banners {
  .bannerItem_contant {
    height: 11rem;
    // margin-top: 3rem;
    > p {
      margin: 0;
      padding-top: 1rem;
    }
    > p:nth-child(1) {
      font-size: 14px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #383838;
      line-height: 21px;
    }
    > p:nth-child(2) {
      color: #9b9b9b;
      font-weight: 400;
      color: #9b9b9b;
      line-height: 16px;
    }
  }
  .bannerItem_componyName {
    .name {
      font-size: 16px;
    }
  }
}
}
</style>