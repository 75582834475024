//时间处理函数
export const dealTimeToHMS = function (targetTime) {
    // targetTime = Math.round(targetTime);
    // console.log(targetTime);
    if (targetTime > 3599999) {
        return "999:59:59";
    }
    let tempTime = "00:00:00";
    tempTime =
        String(
            parseInt(targetTime / 60 / 60) >= 10
                ? parseInt(targetTime / 60 / 60)
                : parseInt(targetTime / 60 / 60) == 0
                    ? "00"
                    : "0" + parseInt(targetTime / 60 / 60)
        ) +
        ":" +
        String(
            parseInt((targetTime / 60) % 60) >= 10
                ? parseInt((targetTime / 60) % 60)
                : parseInt((targetTime / 60) % 60) == 0
                    ? "00"
                    : "0" + parseInt((targetTime / 60) % 60)
        ) +
        ":" +
        String(
            parseInt(targetTime % 60) >= 10
                ? parseInt(targetTime % 60)
                : parseInt(targetTime % 60) == 0
                    ? "00"
                    : "0" + parseInt(targetTime % 60)
        );
    // targetObj[targetStr] = tempTime;
    return tempTime;
}
//定时器函数,参数列表(目标training,（open or close）))
export const timer = function (training, control) {
    if (training.timer) {
        clearInterval(training.timer);
        training.timer = null;
    } else {
        if (control === "open") {
            training.timer = setInterval(() => {
                training.items[training.selectIndex].totalInterval += 1;
                // let targetTime = ;
                dealTimeToHMS(
                    training.items[training.selectIndex],
                    "itemNowTime",
                    training.items[training.selectIndex].totalInterval
                );
                // this.initModelData();
            }, 1000);
        } else if (control === "close") {
            if (!training.timer) {
                return
            }
            clearInterval(training.timer);
            training.timer = null;
        }
    }
}

//正则表达式去掉除了中文、英文、数字、标点符号之外的所有字符
export const removeNonAlphanumeric = function (str) {
    return str.replace(/[^\u4e00-\u9fa5a-zA-Z0-9\s\p{P}]/gu, "");
}