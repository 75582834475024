<template>
  <div class="templateWrap">
    <div class="textTemplateWrap">
      <div class="textWrap">
        <!-- {{ stepObjectJson?.content }} -->
        <el-input
          disabled
          autosize
          resize="none"
          type="textarea"
          placeholder="请输入内容"
          v-model="stepObjectJson.content"
        ></el-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {};
  },
  computed: {
    stepObjectJson() {
      return this.data?.texts[0];
    },
  },
  created() {
    console.log(this.data);
    // console.log(JSON.parse(this.data));
  },
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss">
.templateWrap {
  width: 100%;
  height: 100%;
  .textTemplateWrap {
    width: 100%;
    height: 100%;
    .textWrap {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      font-size: 32px;
      text-align: left;
      word-wrap: break-word;
      line-height: 40px;
      color: #212121;
      overflow: auto;
      :deep(.el-textarea) {
        font-size: 28px;
        line-height: 40px;
        // height: 100%;
        // overflow: auto;
        .el-textarea__inner {
          color: #333;
          background: #fff;
          padding: 2.5rem 3.5rem;
          border: none;
          cursor: default;
          box-sizing: border-box;
        }
      }
    }
    .textWrap::-webkit-scrollbar {
      width: 14px;
      height: 14px;
    }
    .textWrap::-webkit-scrollbar-corner {
      background-color: transparent;
    }
    .textWrap::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.1);
      background-clip: padding-box;
      border: solid transparent;
      border-radius: 10px;
    }
    .textWrap::-webkit-scrollbar-track {
      background-clip: padding-box;
      border: solid transparent;
      border-width: 1px;
    }
  }
}
</style>
