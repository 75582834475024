<template>
  <div>
    <el-dialog
      :title="$t('cancelPublish.title')"
      v-model="isShow"
      width="500px"
      :before-close="handleClose"
      custom-class="myDialog deleteStyle"
    >
      <div class="dialogBody">
        <div class="body">
          <div class="title">{{ $t('cancelPublish.coTitle') }}</div>
          <div class="selectList">
            <el-radio-group v-model="cancelAll">
              <div class="selectListWrap">
                <div class="selectItem">
                  <div class="select">
                    <el-radio :label="false">{{$t('cancelPublish.currentVersion')}}</el-radio>
                  </div>
                  <div class="description">
                    {{$t('cancelPublish.currentVersionDescription')}}
                  </div>
                </div>
                <div class="selectItem">
                  <div class="select">
                    <el-radio :label="true">{{$t('cancelPublish.allVersions')}}</el-radio>
                  </div>
                  <div class="description">
                    {{$t('cancelPublish.allVersionDescription')}}
                  </div>
                </div>
              </div>
            </el-radio-group>
          </div>
        </div>
        <div class="footer">
          <div class="btnWrap">
            <button class="cancel" @click="handleClose">{{$t('cancelPublish.cancel')}}</button>
            <button class="delete" @click="cancelRelease">{{$t('cancelPublish.confirm')}}</button>
          </div>
        </div>
      </div>
      <template #footer> </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      isShow: false,
      model: null,
      dataList: [],
      cancelAll: false,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    cancelRelease() {
      this.$emit("cancelRelease", this.cancelAll, this.model);
      this.isShow = false;
    },
    open(bool, data) {
      this.cancelAll = false;
      this.isShow = bool;
      this.model = data;
      console.log(data);
    },
    handleClose() {
      this.isShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
.dialogBody {
  > .body {
    padding: 0 40px;
    > .title {
      text-align: left;
      color: #224b77;
      line-height: 40px;
      font-size: 16px;
      margin-bottom: 5px;
    }
    > .selectList {
      text-align: left;
      > .el-radio-group {
        width: 100%;
        > .selectListWrap {
          width: 100%;
          > .selectItem {
            background: #f6f7fa;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid #e3e4e9;
            margin-bottom: 16px;
            > .select {
              border-bottom: 1px solid #e3e4e9;
              > .el-radio {
                line-height: 36px;
                width: 100%;
                padding: 0 26px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
              }
            }
            > .description {
              font-size: 14px;
              color: #858585;
              padding: 11px 23px;
              line-height: 20px;
              word-wrap: break-word;;
            }
          }
        }
      }
    }
  }
}
</style>
