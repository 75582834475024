<template>
  <div>
    <FixHeader ref="fixHeader" :type="selectItem === 0 ? 'create' : 'viewer'" />
    <div class="accountHeader">
      <Header ref="header" />
    </div>
    <div class="itemType">
      <div class="contantWarp">
        <!-- 我创建的 -->
        <div :class="selectItem === 0 ? 'active' : ''" @click="select(0)">
          {{ $t("dataCharts.myCreate") }}
          <!-- <span v-if="numList[0] && numList[0].total !== null">{{
            createDataTotal
          }}</span> -->
          <div class="line"></div>
        </div>
        <!-- 我使用的 -->
        <div :class="selectItem === 1 ? 'active' : ''" @click="select(1)">
          {{ $t("dataCharts.myUsed") }}
          <!-- <span v-if="numList[1] && numList[1].total !== null">{{
            viewDataTotal
          }}</span> -->
          <div class="line"></div>
        </div>
        <div>
          <!-- <button @click="test">test</button
          ><button @click="test2">test2</button> -->
        </div>
      </div>
    </div>
    <div class="accountBody">
      <div class="mainBody">
        <div class="dataMessageWrap">
          <div class="dataMessageItem imgs">
            <div class="messageWrap imgWrap">
              <!-- <img src="@/assets/myTask/defaultEnpty.png" alt="" /> -->
              <img
                src="@/assets/dataChart/myCreate.png"
                alt=""
                v-if="selectItem === 0"
              />
              <img
                src="@/assets/dataChart/myUsed.png"
                alt=""
                v-if="selectItem === 1"
              />
            </div>
          </div>
          <div>
            <div class="dataMessageItem" v-if="selectItem === 0">
              <div class="messageWrap">
                <!-- 项目创建数量 -->
                <div class="title">{{ $t("dataCharts.word1") }}</div>
                <div class="messageValue">
                  {{
                    headerData
                      ? headerData.project_count > 999
                        ? "999+"
                        : headerData.project_count
                      : "0"
                  }}
                </div>
              </div>
            </div>
            <div class="dataMessageItem" v-if="selectItem === 1">
              <div class="messageWrap">
                <!-- 项目创建数量 -->
                <div class="title">{{ $t("dataCharts.word11") }}</div>
                <div class="messageValue">
                  {{
                    headerData
                      ? headerData.project_count > 999
                        ? "999+"
                        : headerData.project_count
                      : "0"
                  }}
                </div>
              </div>
            </div>
            <div class="dataMessageItem" v-if="selectItem === 0">
              <div class="messageWrap">
                <!-- 总使用人数 -->
                <div class="title">{{ $t("dataCharts.word2") }}</div>
                <div class="messageValue">
                  {{
                    headerData
                      ? headerData.user_count > 999
                        ? "999"
                        : headerData.user_count
                      : "0"
                  }}
                </div>
              </div>
            </div>
            <div class="dataMessageItem" v-if="selectItem === 0">
              <div class="messageWrap">
                <!-- 总使用次数 -->
                <div class="title">{{ $t("dataCharts.word3") }}</div>
                <div class="messageValue">
                  {{
                    headerData
                      ? headerData.use_times > 999
                        ? "999"
                        : headerData.use_times
                      : "0"
                  }}
                </div>
              </div>
            </div>
            <div class="dataMessageItem" v-if="selectItem === 1">
              <div class="messageWrap">
                <!-- 总使用次数 -->
                <div class="title">{{ $t("dataCharts.word12") }}</div>
                <div class="messageValue">
                  {{
                    headerData
                      ? headerData.use_times > 999
                        ? "999"
                        : headerData.use_times
                      : "0"
                  }}
                </div>
              </div>
            </div>
            <div class="dataMessageItem" v-if="selectItem === 0">
              <div class="messageWrap">
                <!-- 总点赞数 -->
                <div class="title">{{ $t("dataCharts.word5") }}</div>
                <div class="messageValue">
                  {{ headerData ? headerData.like_count : "0" }}
                </div>
              </div>
            </div>
            <div class="dataMessageItem" v-if="selectItem === 0">
              <div class="messageWrap">
                <!-- 总收藏数 -->
                <div class="title">{{ $t("dataCharts.word6") }}</div>
                <div class="messageValue">
                  {{ headerData ? headerData.favourate_count : "0" }}
                </div>
              </div>
            </div>
            <div class="dataMessageItem">
              <div class="messageWrap">
                <!-- 总是用时长(H) -->
                <div class="title">
                  {{ $t("dataCharts.word4") }}
                </div>
                <div class="messageValue">
                  {{
                    headerData ? getTimeMs(headerData.all_duration / 1000) : "0"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="searchWarp">
          <!-- 请选择 -->
          <el-select
            class="selfStyle_search searchDropdown"
            popper-class="dropDown"
            v-model="queryOptions.selectedCategoryId"
            :placeholder="$t('explore.word3')"
            @change="searchGloval"
          >
            <el-option
              v-for="item in category"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
              :style="{
                margin: '0 0.5rem',
                borderRadius: '3px',
                textAlign: 'center',
              }"
            >
            </el-option>
            <template #prefix>
              <img
                style="margin: 0 0.5rem; width: 18px; height: 18px"
                src="@/assets/explore/qrCode.png"
                alt=""
              />
            </template>
          </el-select>
          <!-- 请选择 -->
          <el-select
            class="selfStyle_search searchDropdown"
            popper-class="dropDown"
            v-model="queryOptions.deviceType"
            :placeholder="$t('explore.word3')"
            @change="searchGloval"
          >
            <el-option
              v-for="item in deviceType"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
              :style="{
                margin: '0 0.5rem',
                borderRadius: '3px',
                textAlign: 'center',
              }"
              class="selfDropDown"
            >
            </el-option>
            <template #prefix>
              <img
                style="margin: 0 0.5rem; width: 18px; height: 18px"
                src="@/assets/explore/deviceIcon.png"
                alt=""
              />
            </template>
          </el-select>
          <el-input
            class="searchGlobal searchInput"
            size="mini"
            v-model="queryOptions.searchStr"
            type="text"
            :placeholder="$t('header.serchTip')"
            @keyup.enter="searchGloval"
            clearable
          >
            <template #suffix>
              <i class="el-icon-search" @click="searchGloval"> </i>
            </template>
          </el-input>
        </div>

        <div
          class="dataBody"
          id="dataBody"
          v-if="selectItem === 0 || selectItem === 1"
        >
          <CreateView
            ref="createView"
            :dataList="dataList"
            :queryOptions="queryOptions"
            @returnData="returnData"
            v-if="selectItem === 0"
          />
          <UserView
            ref="userView"
            :dataList="dataList"
            :queryOptions="queryOptions"
            @returnData="returnData"
            v-if="selectItem === 1"
          />
        </div>
      </div>
    </div>
    <Footer v-if="language !== 'en'" />
  </div>
</template>

<script>
import FixHeader from "./components/fixHeader.vue";
import { dealTimeToHMS } from "@/utils/utils.js";
import Header from "@/views/components/header/header.vue";
import Footer from "@/views/components/footer/footer.vue";
import CreateView from "./components/createrView.vue";
import UserView from "./components/userView.vue";
import { deviceType, category } from "@/utils/asset.js";
import { getLanguage } from "@/utils/getLanguage.js";
import {
  getOprAllProjectInfo,
} from "@/api/workflow.js";
import LangManager from "../../utils/langManager";
export default {
  components: { Header, Footer, CreateView, UserView, FixHeader },
  props: {},
  data() {
    return {
      language: LangManager.getLanguage().value,
      deviceType: [...deviceType],
      category: [...category],
      createDataList: [],
      createDataTotal: 0,
      viewDataList: [],
      viewDataTotal: 0,
      headerData: null,
      activeNames: "",
      dataList: [],
      queryOptions: {
        selectedCategoryId: 0,
        deviceType: 0,
        searchStr: "",
      },
      selectItem: null,
      numList: [
        {
          total: 0,
          value: 0,
        },
        {
          total: 0,
          value: 1,
        },
      ],
    };
  },
  computed: {
    profilePhotoUrl() {
      return this.$store.state.User.profilePhotoUrl;
    },
  },
  async created() {
    if (this.$route.query.tab === "myUsed") {
      this.select(1);
    } else {
      this.select(0);
    }
    // this.selectItem = 0;
    // await this.getData();
    // await this.getHeaderData(this.selectItem + 1);
  },
  mounted() {
    let targetDom = document.getElementById("scrollMainBody");
    targetDom.addEventListener("scroll", this.scrollEvent);
  },
  unmounted() {
    let targetDom = document.getElementById("scrollMainBody");
    targetDom.removeEventListener("scroll", this.scrollEvent);
  },
  watch: {},
  methods: {
    searchGloval() {
      if (this.selectItem === 0) {
        this.$refs.createView.searchGloval();
      } else if (this.selectItem === 1) {
        this.$refs.userView.searchGloval();
      }
    },
    scrollEvent(e) {
      // console.log(e);
      if (
        (this.selectItem === 0 && this.createDataTotal !== 0) ||
        (this.selectItem === 1 && this.viewDataTotal !== 0)
      ) {
        let targetDom = document.getElementById("dataBody");
        // console.log(targetDom.offsetTop);
        let showHight = targetDom.offsetTop;
        if (e.target.scrollTop > showHight) {
          // console.log("open");
          this.$refs.fixHeader.open(true);
        } else if (e.target.scrollTop < showHight) {
          // console.log("close");
          this.$refs.fixHeader.open(false);
        }
      }
      // console.log(e.target.scrollTop);
    },
    async getHeaderData(type) {
      let options = {
        fetch_type: type,
      };
      let result = await getOprAllProjectInfo(options);
      console.log(result);
      this.headerData = result;
    },
    getTimeMs(value) {
      return dealTimeToHMS(value);
    },
    // refreshOpenData(data, index) {
    //   this.dataList[index].history = data;
    //   // console.log(this.dataList);
    // },
    returnData(data) {
      if (data.type === "create") {
        this.createDataTotal = data.total;
      } else if (data.type === "view") {
        this.viewDataTotal = data.total;
      }
    },
    // async getData() {
    //   let options = {
    //     fetch_type: 1, //管理员0 创建者1 使用者2
    //     equip_type: this.queryOptions.deviceType,
    //     category_id: this.queryOptions.selectedCategoryId,
    //     page_num: 0,
    //     page_size: 10,
    //   };
    //   let options1 = {
    //     fetch_type: 2, //管理员0 创建者1 使用者2
    //     equip_type: this.queryOptions.deviceType,
    //     category_id: this.queryOptions.selectedCategoryId,
    //     page_num: 0,
    //     page_size: 10,
    //   };
    //   let res = await getOprSessionByProject(options);
    //   let res1 = await getOprSessionByProject(options1);
    //   this.createDataTotal = res.result.total;
    //   this.viewDataTotal = res1.result.total;
    //   console.log(res);
    // },
    goCreateProject() {
      console.log(111);
      this.$refs.header.userInfo();
    },
    goRelease() {
      this.$router.push("/explore");
    },
    async select(num) {
      //防止多次点击
      if (this.selectItem === num) {
        return;
      }
      this.selectItem = num;
      if (num === 0) {
        //我创建的
        // this.showInfo = await this.getList(0, 0, this.pageSize);
        this.$router.replace(`/dataCharts?tab=myCreated`);
        // this.getData();
        await this.getHeaderData(num + 1);
      } else if (num === 1) {
        //我使用的
        // this.showInfo = await this.getList(1, 0, this.pageSize);
        this.$router.replace(`/dataCharts?tab=myUsed`);
        // this.getData();
        await this.getHeaderData(num + 1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.accountHeader {
  background: -webkit-linear-gradient(#ebf5fd, #cbe4fe);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(#ebf5fd, #cbe4fe);
  /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(#ebf5fd, #cbe4fe);
  /* Firefox 3.6 - 15 */
  background: linear-gradient(#ebf5fd, #cbe4fe);
  // height: 12rem;
  padding-bottom: 7rem;
  // display: flex;
  // justify-content: center;
  position: relative;
  overflow: hidden;
  #headerWarp {
    position: absolute;
    z-index: 3;
  }
}
.itemType {
  width: 100%;
  height: 3rem;
  background-color: #f0f5fd;
  display: flex;
  justify-content: center;
  align-items: center;
  .contantWarp {
    margin-top: 0.85rem;
    width: 79%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > div {
      cursor: pointer;
      height: 1.7rem;
      font-size: 0.8rem;
      color: #9096a5;
      position: relative;
      margin-right: 2.5rem;
      .line {
        width: 50%;
        height: 0.2rem;
        background-color: #5380ed;
        border-radius: 0.25rem;
        display: none;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    > div:hover {
      color: #4582ff;
    }
    .active {
      color: #4582ff;
      font-weight: 500;
      .line {
        display: block;
      }
    }
  }
}
.accountBody {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 12.5rem);
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-bottom: 1rem;
  .mainBody {
    width: 80%;
    .dataMessageWrap {
      margin-top: 1rem;
      border: 1px solid #dde2e5;
      border-radius: 12px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      // align-items: center;
      > div {
        width: calc(100% - 220px);
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
      }
      > .dataMessageItem {
        max-width: 186px;
      }
      .dataMessageItem {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .messageWrap {
          min-width: 120px;
          height: 120px;
          text-align: left;
          .title {
            padding-left: 20px;
            font-size: 14px;
            color: #787d89;
            margin-top: 29px;
          }
          .messageValue {
            padding-left: 20px;
            font-size: 32px;
            color: #1a1a1a;
            font-weight: 700;
            margin-top: 11px;
          }
        }
        .imgWrap {
          width: 150px;
          height: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          // margin-left: 1.5rem;
          > img {
            width: 120px;
          }
        }
      }
      .imgs {
        margin: 0 35px;
      }
    }
    .searchWarp {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 2rem;
      > div {
        margin-right: 2rem;
      }
      > .searchDropdown {
        > :deep(.select-trigger) {
          > .el-input {
            > .el-input__inner {
              border-radius: 10px;
              color: #274e78;
            }
          }
        }
      }
      .searchGlobal {
        width: 320px;
        overflow: hidden;
        > :deep(.el-input__inner) {
          background: #cee5fc;
          border-radius: 1.5rem;
          color: #47505d;

          &::placeholder {
            color: #9fa4aa;
          }
        }
        > :deep(.el-input__suffix) {
          right: 10px;
          cursor: pointer;
          transition: color 0.2s;
          :hover {
            color: cornflowerblue;
          }
        }
      }
      .searchInput {
        :deep(.el-input__inner) {
          // width: 300px;
          height: 40px;
          background: #fff;
          border-radius: 10px;
        }
        :deep(.el-input__suffix) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      // ::v-deep .searchDropdown {
      //   > .select-trigger {
      //     > .el-input {
      //       > .el-input__inner {
      //         height: 30px;
      //       }
      //     }
      //   }
      // }
    }
  }
}
/* 当屏幕宽度在 0 到 1200 像素之间时应用的样式 */
@media screen and (max-width: 1200px) {
}
</style>
