<template>
  <div class="template2D">
    <div class="templateBody">
      <div class="templateChose">
        <div class="templateImg">
          <!-- 请选择模板 -->
          <el-input
            v-if="templateInfo"
            :placeholder="$t('templateChose.word1')"
            v-model="templateName"
            disabled
            size="medium"
          >
          </el-input>
        </div>
        <div class="templateButton">
          <el-button
            v-if="shapeItem"
            @click="selectTemplate"
            :disabled="
              !isCreator ||
                (shapeItem && !!shapeItem.data.properties.stepInfo.sourceStepGuid)
            "
            size="medium"
            type="primary"
          >
            <!-- 选择模板 -->
            {{ $t("templateChose.word2") }}</el-button
          >
        </div>
        <TemplateDialog
          ref="templateDialog"
          :templateInfo="templateInfo"
          @selectedTemplate="selectedTemplate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TemplateDialog from "../templateDialog/templateDialog.vue";
export default {
  components: { TemplateDialog },
  props: ["templateInfo", "isCreator", "shapeItem"],
  data() {
    return {
      modelData: null,
      // templateName: "",
    };
  },
  computed: {
    templateName() {
      return this.$t(this.templateInfo.name);
    },
  },
  created() {
    console.log(this.$t(this.templateInfo.name));
    console.log(this.shapeItem);
    console.log(this.isCreator);
  },
  mounted() {},
  watch: {},
  methods: {
    selectedTemplate(data) {
      console.log(data);
      this.$emit("selectedTemplate", data);
    },
    selectTemplate() {
      this.$refs.templateDialog.init(this.templateInfo, true);
    },
  },
};
</script>

<style scoped lang="scss">
.template2D {
  .templateBody {
    .templateChose {
      // padding-top: 1rem;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      // .templateImg{

      // }
      .templateButton {
        margin-left: 0.5rem;
        > button {
          background: #4582ff;
        }
      }
    }
  }
}
</style>
