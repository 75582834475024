import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import 'dayjs/locale/zh-cn'
import "normalize.css/normalize.css";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import {getLanguage} from "@/utils/getLanguage.js"
// import Auth from "@/plugins/Auth.js";
// import Config from "@/plugins/Config.js";
// import Util from "@/plugins/Util.js";
// import SignalR from "@/plugins/SignalR.js";
import "@/styles/index.scss"
import moment from 'moment';
//import ECharts from 'vue-echarts';
import QRCode from 'qrcode-vue3';
import 'animate.css';
// var langUrl = 'en';
// console.log(store.state.User.lang);
// let langStr = getLanguage();
// if (langStr === "en") {
//     langUrl = "en"
// } else if (langStr === "kr") {
//     langUrl = "ko"
// } else if (langStr === "zh") {
//     langUrl = "zh-cn"
// } else {
//     langUrl = "en"
// }
// import lang_kr from "element-plus/lib/locale/lang/ko";
// import lang_zhCn from "element-plus/lib/locale/lang/zh-cn";
// import lang_en from "element-plus/lib/locale/lang/en";
const app = createApp(App);
import './permission'
/* create app */
// import { createI18n } from 'vue-i18n';
// console.log(createI18n);
console.log(store.state.User);
// const i18n = createI18n({
//     locale: langStr,
//     messages: {
//         zh: require('./langs/zh.js'),
//         kr: require('./langs/kr.js'),
//         en: require('./langs/en.js')
//     },
//     silentTranslationWarn: true,
//     globalInjection: true
// })
// // router.beforeEach((to, from, next) => {
//   console.log(to);
//   if (to.path === "/login") {
//     next();
//   } else if (to.meta.permissions) {
//     if (to.meta.permissions.length === 0) {
//       next();
//     } else if (store.state.account.isAuthorized) {
//       const checkPermission = store.state.account.permissions.some((item) =>
//         to.meta.permissions.includes(item)
//       );
//       if (checkPermission) {
//         next();
//       } else {
//         next("/unauthorized");
//       }
//     } else {
//       next("/login");
//     }
//   } else {
//     next();
//   }
// });
import LangManager from '@/utils/langManager'

app.use(router).use(store);
// app.use(i18n);
app.use(LangManager.i18n)
// app.use(ElementPlus, { locale: langUrl === "en" ? lang_en : langUrl === "zh-cn" ? lang_zhCn : lang_kr }, { zIndex: 999 });
app.use(ElementPlus, { locale: LangManager.getLanguage().value }, { zIndex: 999 });
// app.use(Config);

// app.use(moment.locale('zh-cn'));
// moment.locale('zh-cn')
app.component('v-qrcode', QRCode);
//app.component('v-chart', ECharts);
app.mount("#app");
export default app;