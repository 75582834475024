
import longPolling from "@/utils/longPolling.js";
// console.log(longPolling);
// longPolling();
const User = {
    state: () => ({
    }),
    mutations: {
    },
    actions: {

    },
    getters: {}
}
export default User;