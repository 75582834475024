<template>
  <div class="animateWarp">
    <!-- :style="{ width: scale * 100 + '%', height: scale * 100 + '%' }" -->
    <div ref="animation" class="animate"></div>
    <!-- <p class="title">正在加载</p> -->
  </div>
</template>

<script>
import lottie from "lottie-web";
export default {
  props: ["scale"],
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {
    this.loadAnimation();
  },
  watch: {},
  methods: {
    loadAnimation() {
      this.animation = lottie.loadAnimation({
        container: this.$refs.animation, // the dom element that will contain the animation
        renderer: "svg",
        loop: true,
        autoplay: false,
        // animationData: animationData
        // path: 'animation/cartoonData.json'
        path:
          process.env.VUE_APP_STATIC_PATH +
          "animate/enterpriseBanner/business.json", // the path to the animation json
      });
      // this.animation.addEventListener('anmation', lottie.play());
      lottie.play();
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
.animateWarp {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  position: relative;
  .animate {
    width: 75%;
    height: 54%;
  }
  .title {
    color: #f7b74f;
    font-size: 100%;
    position: absolute;
    top: 57%;
    letter-spacing: 2px;
  }
}
</style>
