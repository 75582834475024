<template>
  <div>
    <div class="stepTitle">
      <div class="centerWrap">
        <el-tooltip
          :manual="true"
          class="item"
          effect="light"
          :disabled="relationship.prev.length <= 1"
          v-model="showPrev"
          placement="left"
        >
          <template #content>
            <div class="itemWrap">
              <div
                class="item"
                v-for="(item, index) in relationship.prev"
                :key="index"
              >
                <button @click="goItemByNodeInfo(item)" class="itemBtn">
                  {{ item?.text?.value }}
                </button>
              </div>
            </div>
          </template>
          <button
            @click="prevStep"
            :disabled="relationship.prev.length <= 0"
            class="front"
          >
            <img src="@/assets/task-detail/front.png" alt="" />
          </button>
        </el-tooltip>
        <span v-if="relationship.prev.length <= 0"></span>
        <span>{{ stepName }}</span>
        <el-tooltip
          :manual="true"
          v-model="showNext"
          class="item"
          effect="light"
          :disabled="relationship.next.length <= 1"
          placement="right"
        >
          <template #content>
            <div class="itemWrap">
              <div
                class="item"
                v-for="(item, index) in relationship.next"
                :key="index"
              >
                <div>
                  <button @click="goItemByEdgeInfo(item)" class="itemBtn">
                    {{ item?.text?.value }}
                  </button>
                </div>
              </div>
            </div>
          </template>
          <button
            @click="nextStep"
            :disabled="relationship.next.length <= 0"
            class="nextStep"
          >
            <img src="@/assets/task-detail/next.png" alt="" />
          </button>
        </el-tooltip>
        <span v-if="relationship.next.length <= 0"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["relationship"],
  data() {
    return {
      showPrev: false,
      showNext: false,
      //   relationship: {
      //     prev: [],
      //     next: [],
      //   },
    };
  },
  computed: {
    stepName() {
      return this.relationship?.self?.properties.stepInfo.stepName;
    },
  },
  created() {
    console.log(this.relationship);
  },
  mounted() {},
  watch: {},
  methods: {
    setShowPrev(bool) {
      this.showPrev = bool;
    },
    setShowNext(bool) {
      this.showNext = bool;
    },
    //点击上一步的item
    goItemByNodeInfo(item) {
      this.$emit("prevNode", item.id);
      this.showPrev = !this.showPrev;
      console.log(item);
    },
    //上一步 item是上一步的nodeinfo
    prevStep() {
      console.log(this.relationship);
      this.showNext = false;
      if (this.relationship.prev.length === 1) {
        //线性步骤 不存在判断步骤 不存在分叉路经
        let prevNodeID = this.relationship.prev[0].id;
        this.$emit("prevNode", prevNodeID);
        console.log(prevNodeID);
      } else {
        //非线性步骤 存在多个"上一步"
        this.showPrev = !this.showPrev;
      }
    },
    //下一步 item是下一步的nodeinfo
    nextStep() {
      this.showPrev = false;
      console.log(this.relationship);
      if (this.relationship.next.length === 1) {
        //线性步骤 不存在判断步骤 不存在分叉路经
        let nextNodeID = this.relationship.next[0].id;
        this.$emit("nextNode", nextNodeID);
        console.log(nextNodeID);
      } else {
        //非线性步骤 存在多个"上一步"
        this.showNext = !this.showNext;
      }
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
.stepTitle {
  height: 100%;
  width: 16rem;
  overflow: hidden;
  background: #4582ff;
  display: flex;
  justify-content: center;
  align-items: center;
  .centerWrap {
    width: 16rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.95rem;
    > button {
      border-radius: 5px;
      cursor: pointer;
      width: 2.5rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      border: none;
      border: 1px solid #4582ff;
      outline: none;
      background: #4582ff;
      color: #fff;
      transition: all 0.3s;
      > img {
        width: 2rem;
        margin: 0;
        // height: 100%;
      }
    }
    > button:hover {
      background: #80a9ff;
      border: 1px solid #80a9ff;
    }
    > button[disabled] {
      // background: rgba($color: #1c3466, $alpha: 0.3);
      display: none;
    }
    > span {
      margin: 0 1rem;
      color: #fff;
    }
    > .active {
      background: #80a9ff;
      border: 1px solid #80a9ff;
    }
  }
}
.itemWrap {
  .item {
    .itemBtn {
      height: 32px;
      padding: 0.4rem 1.5rem;
      border: none;
      outline: none;
      background: #e5edfc;
      font-size: 1rem;
      text-align: center;
      width: 100%;
      cursor: pointer;
      margin: 0.2rem 0;
      border-radius: 5px;
      color: #333;
      // transition: all 0.3s;
    }
    .itemBtn:hover {
      background: #dce4f2;
    }
  }
}
</style>
