<template>
  <div>
    <div class="nodataBody" v-if="list.length === 0">
      <div>
        <img class="nodataImg" src="@/assets/myTask/defaultEnpty.png" alt="" />
        <!-- 您还未产生任何数据 -->
        <div class="nodataTip">{{ $t("dataCharts.nodata") }}</div>
        <div class="createBtn">
          <!-- 创建项目 -->
          <button @click="goCreateProject" v-if="selectItem === 0">
            {{ $t("dataCharts.createPro") }}
          </button>
          <button @click="goRelease" v-if="selectItem === 1">
            {{ $t("dataCharts.goRelease") }}
          </button>
        </div>
      </div>
    </div>
    <div class="dataBody" v-if="list.length !== 0">
      <div class="dataListWrap" ref="dataListWrap">
        <div class="dataListHeader">
          <!-- 项目名称 -->
          <div class="proName fontweight">
            {{ $t("createView.projectName") }}
          </div>
          <div class="endWrap">
            <!-- <div class="time">版本创建日期</div> -->
            <!-- 使用者数量 -->
            <div class="dataItem">{{ $t("createView.userCount") }}</div>
            <!-- 使用次数 -->
            <div class="dataItem">{{ $t("createView.word1") }}</div>
            <!-- 完成率 -->
            <div class="dataItem">{{ $t("createView.word2") }}</div>
            <!-- 总操作时长(H) -->
            <div class="dataItem lang">{{ $t("createView.word3") }}</div>
            <!-- 平均操作时长(H) -->
            <div class="dataItem lang">{{ $t("createView.word4") }}</div>
            <!-- 转发数 -->
            <div class="dataItem">{{ $t("createView.word5") }}</div>
            <!-- 收藏数 -->
            <div class="dataItem">{{ $t("createView.word6") }}</div>
            <!-- 点赞数 -->
            <div class="dataItem">{{ $t("createView.word7") }}</div>
          </div>
        </div>
        <div class="dataListBody" v-if="!searchLoading">
          <!-- <FixHeader  /> -->
          <el-collapse
            v-model="activeName"
            :accordion="true"
            @change="collapseChange"
            v-for="(item, index) in list"
            :key="index"
          >
            <el-collapse-item :name="item.project_guid">
              <template #title>
                <div class="dataListHeader">
                  <div class="arrowWrap">
                    <i
                      :class="
                        activeName === item.project_guid
                          ? 'el-icon-caret-right active'
                          : 'el-icon-caret-right'
                      "
                    ></i>
                  </div>
                  <div class="proName">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="item.project_name"
                      placement="bottom"
                    >
                      <span> {{ item.project_name }}</span>
                    </el-tooltip>
                  </div>

                  <div class="endWrap">
                    <!-- <div class="time">版本创建日期</div> -->
                    <div class="dataItem">{{ item.user_count }}</div>
                    <div class="dataItem">{{ item.use_times }}</div>
                    <div class="dataItem">{{ item.finish_percent }}%</div>
                    <div class="dataItem lang">
                      {{ getTimeMs(item.all_duration / 1000) }}
                    </div>
                    <div class="dataItem lang">
                      {{ getTimeMs(item.avg_duration / 1000) }}
                    </div>
                    <div class="dataItem">{{ item.forward_count }}</div>
                    <div class="dataItem">{{ item.favourate_count }}</div>
                    <div class="dataItem">{{ item.like_count }}</div>
                  </div>
                </div>
              </template>
              <div class="contentWrap">
                <div class="dashed">
                  <!-- 版本列表 -->
                  <div class="word">{{ $t("createView.word8") }}</div>
                  <div class="dashedDiv"></div>
                  <!-- <div>{{ item.history }}</div> -->
                </div>
                <div class="right">
                  <div
                    :class="
                      item.activeIndex === i
                        ? 'dataTableItem active'
                        : 'dataTableItem'
                    "
                    @click="selectItemChange(item, index, v, i)"
                    v-for="(v, i) in item.history"
                    :key="i"
                  >
                    <div class="proName">{{ getTime(v.released_at) }}</div>
                    <div class="endWrap">
                      <div class="dataItem">{{ v.user_count }}</div>
                      <div class="dataItem">{{ v.use_times }}</div>
                      <div class="dataItem">{{ v.finish_percent }}%</div>
                      <div class="dataItem lang">
                        {{ getTimeMs(v.all_duration / 1000) }}
                      </div>
                      <div class="dataItem lang">
                        {{ getTimeMs(v.latest_duration / 1000) }}
                      </div>
                      <!-- <div class="dataItem">转发数</div>
                          <div class="dataItem">收藏数</div>
                          <div class="dataItem">点赞数</div> -->
                    </div>
                  </div>
                </div>
                <div class="dataChartsWrap">
                  <div class="dataChart">
                    <div class="leftChart">
                      <ExitChart
                        :ref="'exitChart' + index"
                        v-if="activeName === item.project_guid"
                      />
                      <!-- @exitDataChange="exitChartDataChange" -->
                    </div>
                    <div class="rightChart">
                      <TopDurationChart
                        :ref="'topDurationChart' + index"
                        v-if="activeName === item.project_guid"
                      />
                      <!-- @topDurationDataChange="topDurationChartDataChange" -->
                    </div>
                  </div>
                </div>
              </div>
            </el-collapse-item>
            <!-- <el-collapse-item title="反馈 Feedback" name="2">
                  <div>
                    控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；
                  </div>
                  <div>
                    页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。
                  </div>
                </el-collapse-item> -->
          </el-collapse>
        </div>
      </div>
    </div>
    <div class="pages" v-if="dataPage.total > dataPage.pageSize">
      <el-pagination
        background
        layout="prev, pager, next ,jumper"
        :total="dataPage.total"
        @current-change="currentChange"
        :page-size="dataPage.pageSize"
      >
        <!-- 跳转到 -->
        <template #layout> {{ $t("explore.word5") }} </template>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ExitChart from "./exitChart.vue";
import TopDurationChart from "./topDurationChart.vue";
import { dealTimeToHMS } from "@/utils/utils.js";
import { deviceType, category } from "@/utils/asset.js";
// import FixHeader from "./fixHeader.vue";
import {
  getcacheActive,
  setcacheActive,
  removecacheActive,
  getcacheActiveArr,
  setcacheActiveArr,
  removecacheActiveArr,
} from "@/utils/setLocalstorage.js";
import {
  getOprSessionByRelease,
  getOprSessionByProject,
  getMostExitStep,
  getTopDurationStep,
} from "@/api/workflow.js";
export default {
  components: { ExitChart, TopDurationChart },
  props: ["dataList", "queryOptions"],
  data() {
    return {
      optionsExitStep: null,
      optionsDurationStep: null,
      openArr: [],
      // deviceType: [...deviceType],
      // category: [...category],
      dataPage: {
        total: 0,
        page: 1,
        pageSize: 10,
      },
      list: [],
      activeName: "",
      searchLoading: false,
      // queryOptions: {
      //   selectedCategoryId: 0,
      //   deviceType: 0,
      //   searchStr: "",
      // },
      selectItem: null,
    };
  },
  computed: {},
  async created() {
    console.log(1);
    await this.getData();
  },
  mounted() {},
  unmounted() {
    removecacheActive();
  },
  watch: {
    // dataList: {
    //   handler(newVal, oldVal) {
    //     this.list = newVal;
    //     console.log(this.list);
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  methods: {
    searchItemByApi() {
      this.getData();
      console.log(this.list);
    },
    currentChange(page) {
      this.dataPage.page = page;
      this.getData();
      // removecacheActiveArr();
      removecacheActive();
      // this.openArr = ;
      this.activeName = "";
    },
    async getData() {
      return new Promise(async (resolve, reject) => {
        console.log("getData");
        let options = {
          fetch_type: 1,
          // fetch_type: this.selectItem === 0 ? 1 : 2, //管理员0 创建者1 使用者2
          equip_type: this.queryOptions.deviceType,
          category_id: this.queryOptions.selectedCategoryId,
          search_content: this.queryOptions.searchStr,
          page_num: this.dataPage.page - 1,
          page_size: this.dataPage.pageSize,
        };
        let res = await getOprSessionByProject(options);
        let dataList = res.data;
        dataList.forEach((item) => {
          item.activeIndex = 0;
          item.history = [];
        });
        this.$emit("returnData", { type: "create", total: res.total });
        this.list = dataList;
        this.dataPage.total = res.total;
        resolve(dataList);
      });
    },
    async searchGloval() {
      this.cacheActiveArr();
      this.searchLoading = true;
      await this.getData();
      this.searchLoading = false;
      // this.initOpenArr();
      // console.log(this.list);
      // console.log(this.openArr);
      let cacheData = getcacheActive();
      if (cacheData) {
        cacheData.forEach((item) => {
          let project_guid = item.project_guid;
          this.list.forEach((value, index) => {
            if (value.project_guid === project_guid) {
              value.activeIndex = item.activeIndex;
            }
          });
        });
      }
      // for (let i = 0; i < this.openArr.length; i++) {
      const value = this.activeName;
      let index = 0;
      this.list.forEach((v, i) => {
        if (v.project_guid === value) {
          // console.log(this.list[index].project_name);
          index = i;
        }
      });
      // if (typeof value === "number" && this.list.length > 0) {
      let options = {
        // fetch_type: 1, //管理员0 创建者1 使用者2
        project_guid: value,
        equip_type: this.queryOptions.deviceType,
      };
      let result = await getOprSessionByRelease(options);
      let history = result.data;
      let temp = JSON.parse(JSON.stringify(this.list[index]));
      temp.history = history;
      this.list[index] = temp;
      if (this.list[index].history.length > 0) {
        this.$nextTick(async () => {
          this.getChartData(
            this.list[index].history[this.list[index].activeIndex],
            // this.list[index].activeIndex
            index
          );
        });
      }
      // }
      // }

      // this.openArr.forEach((value, index) => {
      //   this.openArr.pop();
      // });
      // console.log(this.openArr);
    },
    getTimeMs(value) {
      return dealTimeToHMS(value);
    },
    getTime(value) {
      return moment(value).format("L");
    },
    async selectItemChange(item, index, v, i) {
      if (this.list[index].activeIndex !== i) {
        this.list[index].activeIndex = i;
        await this.getChartData(v, index);
        this.cacheActiveList();
      }
    },
    async getChartData(release_guid, index) {
      return new Promise(async (resolve, reject) => {
        if (this.$refs["exitChart" + index]) {
          await this.$refs["exitChart" + index].initChart(release_guid);
        }
        if (this.$refs["topDurationChart" + index]) {
          await this.$refs["topDurationChart" + index].initChart(release_guid);
        }
        resolve();
      });
      // let options = {
      //   release_guid,
      // };
      // this.$nextTick(() => {
      //   console.log("exitChart" + index);
      //   console.log(this.$refs["exitChart" + index]);

      // });
      // setTimeout(() => {
      // console.log(index);
      // this.$refs["exitChart" + index].initChart(release_guid);
      // this.$refs["topDurationChart" + index].initChart(release_guid);
      // }, 100);
    },
    async collapseChange(value, value2) {
      // if (this.openArr.length < value.length) {
      console.log(value, value2);
      let targetPro_guid = value;
      let index = 0;
      this.list.forEach((v, i) => {
        if (targetPro_guid === v.project_guid) {
          index = i;
        }
      });
      let options = {
        // fetch_type: 1, //管理员0 创建者1 使用者2
        project_guid: this.list[index].project_guid,
        equip_type: this.queryOptions.deviceType,
      };
      let result = await getOprSessionByRelease(options);
      
      this.list[index].history = result.data;

      //collapse展开的时候渲染两次，解决echarts渲染机制导致resize报错的问题
      if (this.list[index].history[this.list[index].activeIndex].release_guid) {
        console.log(111);
        this.$nextTick(async () => {
          this.getChartData(
            this.list[index].history[this.list[index].activeIndex],
            index
          );
        });
      }
      this.cacheActiveList();
      //collapse展开的时候渲染两次，解决echarts渲染机制导致resize报错的问题
      // }
      // this.openArr = value;
      this.activeName = value;
    },
    cacheActiveList() {
      let cacheActiveList = [];
      this.list.forEach((value, index) => {
        // console.log(value);
        let options = {
          project_guid: value.project_guid,
          activeIndex: value.activeIndex,
        };
        cacheActiveList.push(options);
      });
      setcacheActive(cacheActiveList);
    },
    cacheActiveArr() {
      setcacheActiveArr(this.openArr);
    },
    initOpenArr() {
      let openIdList = getcacheActiveArr();
      this.openArr = [];
      if (openIdList) {
        this.list.forEach((value, index) => {
          if (openIdList.indexOf(value.project_guid) !== -1) {
            this.openArr.push(index);
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.nodataBody {
  width: 100%;
  height: calc(100vh - 26rem);
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    > .nodataImg {
      width: 200px;
    }
    .createBtn {
      margin-top: 1rem;
      > button {
        width: 120px;
        height: 32px;
        background: #4582ff;
        border-radius: 4px 4px 4px 4px;
        outline: none;
        border: none;
        background: #4582ff;
        color: #fff;
        line-height: 32px;
        text-align: center;
        cursor: pointer;
      }
    }
    .nodataTip {
      margin: 10px 0 20px 0;
      font-size: 14px;
    }
  }
}
.dataBody {
  margin-top: 1rem;
  .searchWarp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > div {
      margin-right: 2rem;
    }
    // > .searchDropdown {
    //   > ::v-deep .select-trigger {
    //     > .el-input {
    //       > ::v-deep .el-input__inner {
    //         height: 30px;
    //       }
    //       > ::v-deep .el-input__suffix {
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //       }
    //     }
    //   }
    // }
    .searchGlobal {
      width: 320px;
      overflow: hidden;
      > :deep(.el-input__inner) {
        background: #cee5fc;
        border-radius: 1.5rem;
        color: #47505d;

        &::placeholder {
          color: #47505d;
        }
      }
      > :deep(.el-input__suffix) {
        right: 10px;
        cursor: pointer;
        transition: color 0.2s;
        :hover {
          color: cornflowerblue;
        }
      }
    }
    .searchInput {
      :deep(.el-input__inner) {
        // width: 300px;
        height: 40px;
        background: #fff;
      }
      :deep(.el-input__suffix) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    // ::v-deep .searchDropdown {
    //   > .select-trigger {
    //     > .el-input {
    //       > .el-input__inner {
    //         height: 30px;
    //       }
    //     }
    //   }
    // }
  }
  .dataListWrap {
    width: 100%;
    .dataListHeader {
      width: 97%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 30px 1.5% 15px 1.5%;
      // padding: 0 20px;
      box-sizing: border-box;
      > div {
        white-space: nowrap;
        /* 内容会被修剪，并且其余内容是不可见的。 */
        overflow: hidden;
        /* 显示省略符号来代表被修剪的文本。 */
        text-overflow: ellipsis;
        font-size: 14px;
        color: #787d89;
        width: 10%;
        text-align: center;
        font-weight: 400;
      }
      .proName {
        width: 22%;
        text-align: left;
        box-sizing: border-box;
        padding-left: 2%;
        font-weight: 600;
      }
      .fontweight {
        font-weight: 400;
      }
      .endWrap {
        width: 78%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .time {
          width: 17%;
        }
        .dataItem {
          width: 10.5%;
          text-align: right;
        }
        .lang {
          width: 16%;
        }
      }
    }

    .dataListBody {
      > :deep(.el-collapse) {
        border: none;
        margin-bottom: 12px;
        .el-collapse-item {
          border: 1px solid #dde2e5;
          border-radius: 12px;
          overflow: hidden;
          min-height: 68px;
          // padding: 0 20px;
          position: relative;
          > div {
            .el-collapse-item__header {
              border: none;
              position: relative;
              height: 68px;
              .el-collapse-item__arrow {
                position: absolute;
                left: 20px;
                display: none;
              }
              .dataListHeader {
                width: 97%;
                margin: 0 1.5%;
                // margin: 0;
                padding: 0;
                > div {
                  font-size: 16px;
                  color: #353535;
                }
                .arrowWrap {
                  position: absolute;
                  top: 1rem;
                  left: 1rem;
                  width: 5%;
                  height: 36px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  > i {
                    transition: all 0.3s;
                    color: #224b77;
                  }
                  .active {
                    transform: rotateZ(90deg);
                  }
                }
              }
            }
          }
        }
        .el-collapse-item__wrap {
          border: none;
          .el-collapse-item__content {
            padding-bottom: 1%;
            .contentWrap {
              width: 100%;
              .dashed {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 5px;
                .word {
                  width: 10%;
                  color: #274e78;
                  font-size: 12px;
                  text-align: left;
                  padding-left: 3.5%;
                  box-sizing: border-box;
                  font-weight: bold;
                }
                .dashedDiv {
                  width: 89%;
                  border: none;
                  border-bottom: 1px dashed #cbced3;
                }
              }
              // .left {
              //   width: 17%;
              //   height: 138px;
              // }
              .right {
                width: 100%;
                // position: absolute;
                // top: 6px;
                z-index: 10;
                // left: 17%;
                background: #fff;
                max-height: 140px;
                overflow: overlay;
                .dataTableItem {
                  box-sizing: border-box;
                  display: flex;
                  justify-content: flex-start;
                  transition: all 0.3s;
                  width: 97%;
                  margin: 0 1.5%;
                  cursor: pointer;
                  line-height: 36px;
                  margin-bottom: 8px;
                  > div {
                    font-size: 14px;
                    color: #353535;
                    width: 10%;
                    text-align: center;
                    font-weight: 400;
                  }
                  .proName {
                    width: 22%;
                    text-align: left;
                    box-sizing: border-box;
                    padding-left: 2%;
                  }
                  .endWrap {
                    width: 78%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .time {
                      width: 17%;
                    }
                    .dataItem {
                      width: 10.5%;
                      text-align: right;
                    }
                    .lang {
                      width: 16%;
                    }
                  }
                }
                .dataTableItem:hover {
                  background: #f5f7fc;
                }
                .active {
                  background: #ecf2ff !important;
                }
              }
              .right::-webkit-scrollbar {
                width: 14px;
                height: 14px;
                position: absolute;
              }
              .right::-webkit-scrollbar-corner {
                background-color: transparent;
              }
              .right::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.1);
                background-clip: padding-box;
                border: solid transparent;
                border-radius: 10px;
              }
              .right::-webkit-scrollbar-track {
                background-clip: padding-box;
                border: solid transparent;
                border-width: 1px;
              }
              .dataChartsWrap {
                margin-top: 10px;
                width: 100%;
                padding-bottom: 29%;
                position: relative;
                .dataChart {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  box-sizing: border-box;
                  padding: 0 1%;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  > div {
                    width: 49.5%;
                    height: 100%;
                    border-radius: 12px;
                    border: 1px solid #f9fafd;
                    overflow: hidden;
                    padding-top: 1%;
                    box-sizing: border-box;
                    background: #f9fafd;
                  }
                  > .rightChart {
                    margin-left: 1%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.pages {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  :deep(.el-pagination) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    > button {
      background: #fff;
      color: #8fb5ff;
      border: 1px solid #dae6ff;
      border-radius: 10px;
      width: 60px;
      height: 38px;
    }
    > button:hover {
      border: 1px solid #4582ff;
    }
    > button[disabled] {
      border: 1px solid #dae6ff;
    }
    .el-pager {
      display: flex;
      justify-content: space-around;
      align-items: center;
      > li {
        height: 36px;
        padding: 0 1rem;
        background: #ffffff;
        border-radius: 10px;
        opacity: 1;
        border: 1px solid #dae6ff;
        color: #224b77;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
      }
      > li:hover {
        border: 1px solid #4582ff;
      }
      .active {
        border: 1px solid #4582ff;
        background: #4582ff;
        color: #ffffff;
      }
    }
    > span {
      height: auto;
      > div {
        width: auto;
        height: auto;
        > input {
          background: #eff3fc;
          width: 60px;
          height: 36px;
          border-radius: 28px;
          color: #224b77;
        }
      }
    }
  }
}
</style>
