const elLang = require('element-plus/lib/locale/lang/en')
const daysLang = require('dayjs/locale/en');


module.exports = {
    languageName: "English",
    ...elLang.default,
    ...daysLang.default,
    routers: {
        mainPage: "Main Page",
        unauthorized: "Unauthorized Access",
        settings: "System Settings",
        account: "My Account",
        explore: "Explore",
        modelView: "View",
        enterprise: "Enterprise User",
        myAccount: "My Account",
        myTasks: "My Tasks",
        webGl: "Model",
        taskDetail: "Task Detail",
        accounts: "User Account Management",
        newAccount: "Create New User Account",
        updateAccount: "Edit User Account",
        dataCharts: "Data Charts",
        auditProject: "Review Projects",
        publishRoles: "prohibited contents",
    },
    store: {
        tip1: "Password strength insufficient",
    },
    asset: {
        word1: "Positive direction of X-axis",
        word2: "Negative direction of X-axis",
        word3: "Positive direction of Y-axis",
        word4: "Negative direction of Y-axis",
        word5: "Positive direction of Z-axis",
        word6: "Negative direction of Z-axis",
        word7: "QR Code Positioning",
        word8: "Object Recognition Positioning",
        word9: "Meter",
        word10: "Decimeter",
        word11: "Centimeter",
        word12: "All",
        word13: "HoloLens",
        word14: "iOS",
        word15: "All",
        word16: "Industrial Equipment",
        word17: "Research Equipment",
        word18: "Medical Devices",
        word19: "Other",
        word20: "Text",
        word21: "Multimedia",
        word22: "Text + Multimedia",
        word23: "Multimedia + Multimedia",
        word24: "Text + Multimedia + Multimedia",
    },
    request: {
        tip1: "Login status expired, please log in again",
        tip2: "Please log in again",
        tip3: "Login status expired, please log in again",
    },
    animation: {
        loading: "Loading...",
    },
    modelLoading: {
        loading: "Loading",
    },
    createProject: {
        projectName: "Name",
        enableOfflineMode: "Enable Offline Mode",
        enableOfflineModeTip: "Allow users to download for offline use",
        categoryId: "Select Project Type",
        cover: "Cover",
        uploadCover: "Upload Cover",
        positionMethods: "Positioning Methods",
        baseModel: "Base Model",
        canSelect: "Optional",
        noSelect: "Mandatory",
        uploadBaseModel: "Upload Base Model",
        hasModel: "(Model Available) Please generate model cover",
        tip1: "Photo of QR code's relative position to the object",
        uploadPicAndVid: "Upload Reference Image/Video",
        cancel: "Cancel",
        confirm: "Confirm",
        tip2: "Please upload 'Base Model'",
        tip3: "Default Task",
        tip4: "Failed to save location information!",
        tip5: "Creation Successful!",
        tip6: "Default task creation failed, please create manually",
        projectLanguage: "Select Project Language",
    },
    forgetPass: {
        tel: " Tel/Email",
        newPass: "New Password",
        passTip: "Minimum 8 characters, a combination of uppercase and lowercase letters, numbers, and special punctuation",
        repeat: "Repeat",
        authCode: "Verification Code",
        getAuthCode: "Send",
        tip1: "If you have not bound a  Tel/Email, please contact the administrator",
        next: "Next Step",
        tip2: "Verification code cannot be empty!",
        tip3: "Change Password",
        codeError: "Verification code error",
        rePass: "Passwords do not match",
        telErr: "This  Tel/Email has not registered this app!",
        tip4: "Passwords do not match!",
        newPassError1: "New password cannot be empty!",
        newPassError2: "Incorrect format for new password!",
        telNull: "Tel/Email cannot be empty!",
        telError: "Tel/Email format error!",
    },
    login: {
        username: "Username",
        password: "Password",
        moUsername: "Don't have an account?",
        goRegiste: "Sign Up",
        forgetPass: "Forgot Password",
        login: "Login",
        tip1: "Username or password cannot be empty",
        tip2: "Username or password incorrect",
        tip3: "User does not have permission to use this application",
        tip4: "Account has been frozen for a while, please try again later",
        tip5: "Unknown error",
    },
    regirte: {
        tel: "Tel/Email",
        password: "Password",
        passwordRule: "Minimum 8 characters, a combination of uppercase and lowercase letters, numbers, and special punctuation",
        rePass: "Repeat",
        authCode: "Verification Code",
        getAuthCode: "Send",
        hasUsername: "Already have an account?",
        goLogin: "Login",
        agree: "Agree",
        agreeItem1: "《Jingteng ",
        agreeItem1Href1: "user agreement",
        and: " and",
        agreeItem1Href2: " privacy policy",
        agreeEnd: "》",
        agreeItem2: "Agree to receive product updates and community notification emails",
        registe: "Sign Up",
        tip1: "Verification code cannot be empty!",
        tip2: "Please tick the agreement below!",
        tip3: "Registration successful! Go to log in!",
        tip4: "Verification code incorrect!",
        tip5: "This Tel/Email has been registered!",
        tip6: "Passwords do not match!",
        tip7: "Password cannot be empty!",
        tip8: "Incorrect password format!",
        tip9: "Tel/Email cannot be empty!",
        tip10: "Tel/Email format error!",
    },
    userAgreement: {
        title: "Jingteng Technology User Agreement",
        close: "Close",
    },
    resetPass: {
        resetResult: "Password Reset",
        resetPasswordRes: "Congratulations, your password has been successfully reset!",
        go: "Start Using",
    },
    header: {
        explore: "Explore",
        enterprisePage: "Enterprise",
        download: "Download",
        serchTip: "Search",
        login: "Login",
        regist: "Sign Up",
        createProject: "New Project",
        myAccount: "My Account",
        dataChart: "Data Statistics",
        myProject: "My Projects",
        auditProject: "Review Projects",
        logout: "Log Out",
        forgetPass: "Forgot Password",
        tips1: "Enter Tel/Email and verification code to retrieve password",
        twoD: "2D Process",
        threeD: "3D Process",
    },
    modelSetter: {
        model: "QR Code and Base Model",
        change: "Change",
        upload: "Upload",
        delete: "Delete",
        modelSize: "Model Usage Size",
        gravityArrow: "Model Gravity Direction",
        noModel: "No Base Model",
        getCover: "Generate Base Model Cover",
        qrcodeCover: "No Position Reference Image",
        gwtQRCodeCover: "Generate QR Code Position Reference Image",
        noModel: "No Base Model Uploaded",
        cancel: "Cancel",
        ok: "Confirm",
        sizeError: "File size exceeds limit!",
    },
    modelLoader: {
        modelPreview: "Model Preview",
        tip1: "Right-click to move model",
        tip2: "Left-click to rotate model",
        tip3: "Mouse wheel to zoom model",
        close: "Close",
        ok: "Confirm",
    },
    pdfView: {
        pdfView: "PDF Preview",
        prevPage: "Previous Page",
        nextPage: "Next Page",
        cancel: "Cancel",
        close: "Close",
    },
    pcitureView: {
        title: "Image Preview",
        cancel: "Cancel",
        close: "Close",
    },
    videoView: {
        title: "Video Preview",
        cancel: "Cancel",
        close: "Close",
    },
    enterprise: {
        word1: "Revolutionize your business workflow with the power of mixed reality",
        word2: "Complex operation processes become what you see is what you get",
        word3: "Free use",
        word4: "Enterprise Solution",
        word5: "Enterprise-exclusive XR process guidance platform",
        word6: "Technical knowledge sharing and exchange",
        word7: "Easily create standardized process guidance",
        word8: "Easily create combined virtual and real guidance processes",
        word9: "Freedom",
        word10: "Ease",
        word11: "Supports global top enterprise-level XR devices",
        word12: "Global coverage of glasses devices",
        word13: "Hololens2",
        word14: "Visual guidance combining virtual and real, the answer is right next to the problem",
        word15: "Immediate solution to process problems",
        word16: "Worry-free",
        word17: "Smooth",
        word18: "Supports viewing AR on mobile devices",
        word19: "Use it as soon as you take out your phone",
        word20: "Convenient",
        word21: "iOS",
        word22: "Android",
        word23: "More than 10w+ materials available for the whole family",
        word24: "Easily create combined virtual and real guidance processes",
        word25: "Autonomy",
        word26: "Classification",
        word27: "Collection",
        word28: "Compatible with VR device virtual operation processes",
        word29: "Virtual reality presents a full-process solution",
        word30: "VR",
        word31: "Make user manuals no longer obscure and difficult to understand",
        word32: "Enterprises can use JTWorkflow to publish XR process guidance for end users, enhancing users' reception of products",
        word33: "Experience now",
        word34: "Trusted by global enterprises",
        word35: "Has served customers in industries worldwide",
        word36: "Contact us for enterprise-exclusive solutions",
        word37: "Supports the use of MR, AR, VR multi-platform clients, unbounded",
        word38: "Cooperative enterprises",
        word39: "Apple Vision Pro",
        word40: "Pico 4",
    },
    banner: {
        word1: "Warehouse (excluding dangerous goods), distribution business, and technical support, etc., within the area",
        word2: "By using 5G+MR technology, the difficult problem of explaining the abstract Vantage product was solved, reducing the communication cost between sales managers and customers and improving efficiency.",
        word3: "Development, design, production of high-tech process control instruments, control systems",
        word4: "By using 5G+MR technology, Emerson solved the difficult problems encountered in product design and assembly, improved communication efficiency with foreign experts, and helped users solve problems on-site more quickly and effectively.",
        word5: "Research, development, production, processing of toothpaste, and sales of water treatment products",
        word6: "Operation process guidance based on MR technology makes training more intuitive and clear, easier to understand, thereby achieving better training effects. It can also be used for standardized operation guidance for positions, reducing the rate of misoperation.",
        word7: "Roche Diagnostics",
        word8: "Emerson",
        word9: "Unilever",
    },
    form: {
        title: "Title",
        email: "Email",
        tel: "Telephone",
        companyName: "Company Name",
        label5: "Interested XR products and solutions",
        submit: "Submit",
        other: "Other",
        tip1: "Please enter email title",
        tip2: "Please enter your email",
        tip3: "Please enter your telephone",
        tip4: "Information has been sent to our email, our staff will contact you after receiving the email!",
        tip5: "Submission Result",
        tip6: "Confirm",
    },
    explore: {
        word1: "Enhance everyday tasks with the magic of mixed reality",
        word2: "Create, manage, and share your workflows and knowledge in a new way that combines virtual and real",
        word3: "Please select",
        word4: "Sort by",
        word5: "Jump to",
        word6: "No projects available for browsing currently",
        word7: "All Time",
        word8: "Today",
        word9: "This Week",
        word10: "This Month",
        word11: "Acquisitions",
        word12: "Previews",
        word13: "Likes",
        word14: "Latest",
        word15: "All",
    },
    modelViewer: {
        modelLoadingTitle: "Model Loading",
        modelCover: "Set Model Cover",
        word1: "Adjust the model to an easily recognizable direction for display",
        word2: "Left-click to rotate model",
        word3: "Right-click to move model",
        word4: "Mouse wheel to zoom model",
        ok: "Confirm",
        close: "Close",
        modelDownloadError: "Model download failed!",
        modelOpenError: "Model file opening failed, please re-select the model file",
    },
    accountInfo: {
        title: "Reset Password",
        inputOldPassword: "Enter Old Password",
        inputNewPassword: "Enter New Password",
        reINputNewPassword: "Repeat New Password",
        submit: "Confirm Change",
        tip1: "Please enter old password",
        tip2: "Please enter new password",
        tip3: "Must include uppercase and lowercase characters, numbers, and special symbols, 8 characters or more",
        tip4: "Please re-enter new password",
        tip5: "The two entered passwords do not match!",
        tip6: "Modification successful, please log in again",
        tip7: "Old password is incorrect",
    },
    avatar: {
        title: "Avatar Cropping",
        selectPicture: "Select Picture",
        reSelect: "Re-select Avatar",
        ok: "OK",
        back: "Return to Previous Step",
        tip1: "Avatar uploaded",
    },
    userInfo: {
        changeAvatar: "Change Avatar",
        username: "Username",
        tip: "(Up to 20 characters)",
        description: "Description",
        update: "Update",
        tip1: "Length must be between 1 and 20 characters",
        tip2: "Saved successfully!",
        email: "Email",
    },
    myAccount: {
        userInfo: "Personal Information",
        accountSetting: "Account Settings",
        tab1: "Edit Personal Information",
    },
    myTask: {
        editUserInfo: "Edit Personal Information",
        word1: "Favorited:",
        word2: "Owned:",
        word3: "Mine:",
        word4: "Published",
        word5: "Unfavorite",
        word6: "Delete Project",
        word7: "Unpublish Version",
        word8: "No projects",
        word9: "New Project",
        word10: "Unpublish Project",
        word11: "Review Records",
        word12: "No pending projects currently",
        tip1: "Unpublishing this project will also remove it. Deletion cannot be undone. Are you sure you want to delete this project?",
        tip2: "Delete Project",
        tip3: "Delete",
        tip4: "Cancel",
        tip5: "Users who have obtained this version will not be able to use this version. If there is a previous version, they can continue to use the previous version",
        tip6: "Unpublish the latest version of the project",
        tip7: "Unpublish",
        tip8: "Take Down All Versions",
        tip9: "After version takedown, existing users won't be able to use it. All versions will be taken down",
        tip10: "Delisted",
        tip11: "Delisting failed!",
        tip12: "List refresh failed",
        tip13: "The version of this project that has not been released yet",
    },
    assetPicker: {
        all: "All",
        model: "Model",
        picture: "Picture",
        video: "Video",
        PDF: "PDF",
        uploadAsset: "Upload Asset",
        delete: "Delete",
        noAsset: "No assets available for browsing currently",
        createProject: "New Project",
        tip1: "Are you sure you want to delete this asset?",
        tip2: "Delete Asset",
        tip3: "Confirm",
        tip4: "Cancel",
        tip5: "Deleted successfully!",
        tip6: "This asset has been used in a process step and cannot be deleted",
        tip7: "Notice",
        tip8: "Force Delete",
        tip9: "Cancel",
        tip10: "Deleted successfully!",
    },
    createTask: {
        createTask: "Create Task",
        taskName: "Enter Process Name",
        cancel: "Cancel",
        ok: "OK",
        tip1: "Please enter a process name!",
        tip2: "Created successfully!",
        tip3: "Creation failed",
    },
    deleteProject: {
        title: "Delete Project",
        word1: "Project will be permanently deleted and cannot be recovered. Are you sure you want to delete?",
        cancel: "Cancel",
        delete: "Delete",
        tip1: "Deleted successfully!",
    },
    editLink: {
        title: "Set Link Text Content",
        placeholder: "Set Condition",
    },
    editStep: {
        word1: "Attributes",
        name: "Name",
        description: "Description",
        assetList: "Asset Library",
        word2: "Set Link Text Content",
        word3: "Set Condition",
        tip1: "Step Name",
        projectInfo: "Project Settings",
        templateSelect: "Template Selection",
    },
    historyEdition: {
        title: "History Versions",
        addHistory: "Add History Version",
        save: "Manual Save",
        word1: "Save history version by pressing Ctrl+Alt+S during editing",
        word2: "Please enter content",
        word3: "Rename",
        word4: "Restore to this version",
        word5: "Delete this history version",
        saveAuto: "Auto Save",
        enterContent: "Please enter content",
        tip1: "Your current work content will revert to this version, do you want to continue?",
        tip2: "Notice",
        ok: "Confirm",
        cancel: "Cancel",
        tip3: "Version reverted!",
        tip4: "Version revert failed!",
        tip5: "Confirm to delete this version?",
        tip6: "Notice",
        tip7: "Deleted successfully!",
    },
    printReview: {
        componyUrl: "jtworkflow.jingtengtech.com",
        word1: "Reference picture not uploaded",
        word2: "QR code reference position",
    },
    projectInfo: {
        title: "Attributes",
        name: "Name",
        description: "Description",
        type: "Select Project Type",
        enableOfflineMode: "Enable Offline Mode",
        enableOfflineModeTip: "Allow users to download for offline use",
        projectCover: "Project Cover",
        // noCover: "No Cover",
        noCover: "Suggested image size not less than 640*360, use landscape non-transparent background image, non-16:9 images will be filled or cropped, supports JPG/PNG/JPEG format",
        clickUpload: "Click to Upload",
        positionType: "Positioning Methods",
        baseModel: "QR Code and Base Model",
        word1: "(Model Available) No base model cover",
        // word2: "No Base Model",
        word2: "Model recommended in FBX/GLB format, size not exceeding 20M",
        word3: "Photo of QR code's relative position to the object",
        // word4: "No reference image",
        word4: "Ensure QR code and reference object appear completely in the frame; images recommended in JPG format",
        word5: "Please describe the relationship between the QR code's placement and the object",
        word6: "Describe the location relationship here",
        word7: "Model Usage Size",
        word8: "Model Gravity Direction",
        word9: "Asset Library",
        word10: "Confirm to delete 'Base Model'?",
        word11: "Base Model",
        delete: "Delete",
        cancel: "Cancel",
        tip1: "Confirm to delete 'Photo of QR code's relative position to the object'?",
        tip2: "QR Code Positioning Information",
        tip3: "Deletion failed!",
        tip4: "File size exceeds limit!",
        tip5: "Confirm to delete 'Project Cover'?",
        tip6: "Delete Project",
        tip7: "This project has been acquired, you can view it under 'My Projects -> Owned Projects'",
        tip8: "Other Devices",
        tip9: "Apple Vision Pro",
        projectLanguage: "Select Project Language",
    },
    releaseProject: {
        title: "Publish Project",
        word1: "Publish this project so other users (depending on the visibility range selected below) can operate according to your designed process, solving problems more efficiently.",
        word2: "After the project is published, you can still modify your process/project, and your modifications will not affect the already published project until you publish the modified process/project again.",
        word3: "Visibility Range",
        word4: "Visible to everyone",
        word5: "Only my enterprise (exclusive to enterprise users)",
        word6: "Cancel",
        word7: "Publish",
        word8: "Before your project is officially launched, it will undergo backend review. If there are",
        word9: "prohibited contents",
        word10: "found, the project will not be approved for publication.",
        tip1: "You are not an enterprise user, this option is not available",
        tip2: "Notice",
        tip3: "Confirm",
        tip4: "Submitted for review",
        tip5: "Submission review failed",
        tip6: "The project is currently under review, please check 'My Projects -> Pending projects'",
        auditTitle: "Submit for Review",
        inputDes: "Enter Note",
        submit: "Submit",
        tip7: "This project is under review. Please be patient...",
        tip8: "Published Successfully!",
        tip9: "Publishing Failed!",
    },
    share: {
        word1: "Users who get the link can access this project",
        word2: "Copy link",
    },
    taskChart: {
        inputStep: "Input Steps",
        taskStep: "Task Steps",
        word1: "Select",
        drag: "Drag",
        word2: "Operation Steps",
        word3: "Judgment Conditions",
        newTask: "New Task",
        select: "Please Select",
        saving: "Saving...",
        copy: "Copy Attributes",
        clone: "Clone Step",
        paste: "Paste Step",
        setStartStep: "Set as Start Step",
        delete: "Delete",
        tip1: "There are no steps in this flowchart, save anyway?",
        tip2: "Notice",
        ok: "OK",
        cancel: "Cancel",
        saveSuccess: "Saved successfully!",
        saveError: "Save failed!",
        tip3: "Cannot copy 'Start Step', 'Task Step'",
        tip4: "Failed to retrieve resources, copy failed",
        tip5: "Cannot clone 'Start Step', 'Task Step'",
        tip6: "Cannot delete 'Start Step', please re-select",
        tip7: "Deleting list contains cloned steps, continuing will delete all its clones, continue?",
        tip8: "Delete Step",
        tip9: "This step has associated clones, continuing will delete all its clones, continue?",
        tip10: "Only regular steps can be set as start steps",
        tip11: "Setting this step as 'Start Step' will delete all its clones, proceed?",
        tip12: "Please note",
        tip13: "Task steps and start steps cannot be cloned",
        tip14: "Start step creation failed! Please re-enter this task",
    },
    taskNewDatail: {
        back: "Back",
        save: "Save",
        history: "History Versions",
        projectSetter: "Project Settings",
        delete: "Delete",
        get: "Get",
        got: "Owned",
        config: "Configure",
        preview: "Preview",
        release: "Publish",
        share: "Share",
        task: "Task",
        search: "Search",
        newTask: "New Task",
        pleaseEnterContent: "Please enter content",
        moveUp: "Move Up",
        moveDown: "Move Down",
        resetTaskName: "Rename",
        tip1: "Notice",
        tip2: "Detected unsaved content in the flowchart, save?",
        cancel: "Cancel",
        noSave: "Don't Save",
        tip3: "This project has been acquired, you can view it under 'My Projects -> Owned Projects'",
        tip4: "At least one task is required in each project!",
        tip5: "This operation will permanently delete the task, continue?",
        tip6: "Notice",
        ok: "OK",
        deleted: "Deleted",
        tip7: "Detected unsaved content in the flowchart, save?",
        tip8: "Notice",
        tip9: "Please add steps to the current task and save first!",
        tip10: "Unpublishing this project will also remove it. Deletion cannot be undone. Are you sure you want to delete this project?",
        tip11: "Delete Project",
        tip12: "Deleted successfully!",
        toggleView: "Toggle View",
        tip13: "Unsaved step configuration information detected. Would you like to save the changes before switching steps?",
        tip14: "Switch Steps",
    },
    uploadAsset: {
        title: "Upload Asset",
        uploadGile: "Upload File",
        fileName: "File Name",
        getCover: "Screenshot",
        changeFile: "Change File",
        uploadThubnail: "Upload Thumbnail",
        changeThubnail: "Change Thumbnail",
        cancel: "Cancel",
        ok: "OK",
        tip1: "Please enter a name",
        tip2: "Please select a file",
        sizeError: "File size exceeds limit!",
        uploadError: "File upload error, please retry",
        pleaseSelectFile: "Please select a file",
        tip3: "Upload successful!",
        tip4: "Recommended to upload videos in MP4 format resolution ≥720P; images in JPG/PNG/JPEG format resolution ≥1080P; PDF files; models in FBX/GLB format with Y-axis up,Size not exceed 50M",
        tip5: "Where video size does not exceed 200M, other files do not exceed 20M",
        tip6: "Thumbnails will be automatically generated for uploaded models, images, and video files",
        tip7: "Manually uploaded images should not exceed 20M, supports only JPG/PNG/JPEG format, non-1:1 images will be filled or cropped",
        tip8: "3D asset types are not supported in this project",
    },
    webGL: {
        word1: "Entering configuration space...",
        word2: "Entering preview space...",
        tip1: "Detected unsaved content, save?",
        tip2: "Notice",
        ok: "OK",
        cancel: "Cancel",
        tip3: "Image",
        tip4: "Video",
        tip5: "Text",
        tip6: "Re-enter content title",
        tip7: "This is for the user to fill in",
        tip8: "Cannot clear template",
        save: "Save",
        noSave: "No",
        refStepNote: 'The current step is a clone step; modifying will change both the original and all clones.'
    },
    home: {
        word1: "Enhance everyday tasks with the magic of mixed reality",
        word2: " Create and share standardized work process and knowledge in a new form that combines virtual and reality",
        word3: "Free Use",
        word4: "Enterprise Solution",
        word5: "The world's first mixed reality process guidance platform",
        word6: "Knowledge sharing and exchange based on XR (VR/AR/MR) technology",
        word7: "Easily create standardized process guidance",
        word8: "Easily create combined virtual and real guidance processes",
        word9: "Freedom",
        word10: "Ease",
        word11: "Supports global top enterprise-level XR devices",
        word12: "Global coverage of glasses devices",
        word13: "Hololens2",
        word14: "Visual guidance combining virtual and real, the answer is right next to the problem",
        word15: "Immediate solution to process problems",
        word16: "Worry-free",
        word17: "Smooth",
        word18: "Supports viewing AR on mobile devices",
        word19: "Use it as soon as you take out your phone",
        word20: "Convenient",
        word21: "iOS",
        word22: "Android",
        word23: "More than 10w+ materials available for the whole family",
        word24: "Easily create combined virtual and real guidance processes",
        word25: "Autonomy",
        word26: "Classification",
        word27: "Collection",
        word28: "Compatible with VR device virtual operation processes",
        word29: "Virtual reality presents a full-process solution",
        word30: "VR",
        word31: "Powerful and convenient editing tools",
        word32: "Multiple platforms, multiple interaction modes for expression and creation",
        word33: "Experience now",
        word34: "Share your process and knowledge",
        word35: "Various ways to share your experience",
        word36: "Join now",
        word37: "Trusted by global enterprises",
        word38: "Has served customers in industries worldwide",
        word39: "Get an exclusive plan for enterprise users",
        word40: "Client support on multiple platforms",
        word41: "Supports the use of MR, AR, VR multi-platform clients, unbounded",
        word42: "HoloLens2 client download",
        word43: "HoloLens",
        word44: "Version number: 4.0",
        word45: "2022-9-20",
        word46: "Go to store",
        word47: "iOS client download",
        word48: "Pico Neos client download",
        word49: "Pico",
        word50: "Please download and install from the Pico B-side store",
        word51: "Apple Vision Pro",
        word52: "Pico 4",
    },
    dataCharts: {
        myCreate: "Created",
        myUsed: "Used",
        word1: "Project Creation Quantity",
        word2: "Users",
        word3: "Uses",
        word4: "Total Duration of Use",
        word5: "Likes",
        word6: "Favorites",
        nodata: "No Data",
        createPro: "Go Create",
        goRelease: "Explore",
        word7: "Project Name",
        word8: "Operation Times",
        word9: "Average Time Spent per Use",
        word10: "Last Time of Operation Duration",
        word11: "Projects Operated",
        word12: "Operations",
    },
    createView: {
        projectName: "Project Name",
        userCount: "Users",
        word1: "Usage",
        word2: "Completion Rate",
        word3: "Total Operation Time (H)",
        word4: "Average Operation Time (H)",
        word5: "Shares",
        word6: "Favorites",
        word7: "Likes",
        word8: "Version List",
    },
    exitChart: {
        word1: "Process Breakpoint Chart",
        word2: "Times",
    },
    topDurationChart: {
        word1: "Process Difficulty Chart",
        word2: "Time (Minutes)",
    },
    prevViewThumbnail: {
        word1: "Preview Window",
        word2: "Double-click to enter task steps",
    },
    inputTemplate: {
        word1: "Enter content title here",
        word2: "This is for the user to fill in",
        word3: "Image",
        word4: "Video",
        word5: "Text",
    },
    textVue: {
        word1: "Please enter content",
        word2: "You haven't entered any content yet!",
    },
    mediaVue: {
        word1: "Loading desperately...",
        word2: "Click to upload",
        word3: "Or",
        word4: "Asset Library",
        word5: "Can upload JPG/PNG/JPEG format images, MP4 format videos, PDF files, files not exceeding 20M",
        word6: "Confirm to delete this file?",
        word7: "Notice",
        word8: "Confirm",
        word9: "Cancel",
        word10: "Deleted successfully!",
        word11: "File size exceeds limit (20MB)",
        word12: "File size exceeds limit (200MB)",
        word13: "File format not supported, please re-upload",
    },
    templateChose: {
        word1: "Please select a template",
        word2: "Select Template",
    },
    templateDialog: {
        word1: "Template Selection",
        word2: "Cancel",
        word3: "Confirm",
    },
    prevTemplate2D: {
        noDes: "No description",
        word1: "About to jump to this task, confirm?",
        tip: "Notice",
        word2: "Jump",
        word3: "Stay Here",
        media: {
            noUpload: "Not uploaded",
        },
    },
    materialLibrary: {
        title: "Material Selection",
        cancal: "Cancel",
        submit: "Submit",
    },
    myPublished: {
        all: "All",
        enterpriceOnly: 'Enterprice Only',
        draft: "Draft",
        unsubmitted: "Unsubmitted",
        audit: "Pending Review",
        auditPass: "Approved",
        auditFail: "Rejected",
        reSubmit: "Re-submit",
        reSubmitTip: "Confirm re-submitting this project?",
        confirm: "Confirm",
        cancel: "Cancel",
        submited: "Submitted",
        submitError: "Submission failed, please check network or service",
        auditting: "In review",
        noPass: "Rejected",
        showReson: "View Reason",
        withdraw: "Withdraw",
        offline: "Unpublish",
        goEdit: "Edit",
        allProcess: "All",
        pass: "Approved",
        reason: "Reason for Rejection",
        know: "OK",
        withdrawTip: "Confirm withdraw of submitted review?",
        deleteSuccess: "Deleted Successfully",
        deleteFail: "Deletion Failed",
        version: "Version: ",
        nearlyTime: "Last edited time: ",
        auditHistory: "Review Records",
        deleteProject: "Delete Project",
        edit: "Edit",
        offlineTip: "Unpublish",
        viewCurrentVersion: "Current Version",
        oldProjectName: "Old Name",
        deleteFromDrafts: "Delete from Drafts",
    },
    cancelPublish: {
        title: "Unpublish",
        coTitle: "Choose Scope",
        currentVersion: "Unpublish Current Version",
        currentVersionDescription: "Users who have already obtained this version will no longer be able to use it. If there is a previous version, users can continue using it.",
        allVersions: "Unpublish Entire Project",
        allVersionDescription: "Users who have obtained this version will no longer be able to use it. All versions (including those in review) will be unpublished.",
        confirm: "Unpublish",
        cancel: "Cancel",
    },
    auditProject: {
        auditProject: "Pending Review Projects",
        pass: "Approved",
        reject: "Rejected",
        allProcess: "All Processes",
        auditting: "In review",
        noPass: "Rejected",
        passed: "Approved",
        reviewResultCommitted: "Review result committed successfully",
        reviewResultCommitFailed: "Review result commit failed",
        confirm: "Confirm",
        cancel: "Cancel",
        tip3: "Please provide the reason for the rejection.",
        errorTip: "Content cannot be empty",
        tip4: "Review result submitted",
        showRemarks: "View Remarks",
        tip5: "Tip",
        tip6: "Have you confirm that this version has been approved?",
    },
    auditHistory: {
        auditLog: "Review Log",
        projectName: "Project Name",
        version: "Version: ",
        auditTime: "Review Time: ",
        submitRemarks: "Submit Remarks",
        reason: "Reason for Rejection",
        word1: "No review records yet",
        submitTime: "Submission Time: ",
        withdrawTime: "Withdrawal Time: ",
    },
    longPolling: {
        logoutTip: "Logout Notification",
        logoutContent: "Your account has been logged out due to being logged in on another device.",
        close: "Close",
    },
}