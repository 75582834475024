

import request from '@/utils/request'
// import getRequestData from '@/utils/wrapResult'
// import { get } from 'jquery'

export function getAssets(page, size, projectGuid) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/project/getAssets`, { page, size, projectGuid })
}

export function getShareLevels() {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/getShareLevels`)
}

export function getAllCategories(langCode) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/published/getAllCategories`, { langCode })
}

// export function getAllReleases(page, size, startDate, endDate, categories, orderCode, searchStr) {
//     return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/published/getAllReleases`,
//         { page, size, startDate, endDate, categories, orderCode, searchStr })
// }
export async function getAllReleases(page, size, startDate, endDate, categories, orderCode, searchStr) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/getAllReleases`,
        { page, size, startDate, endDate, categories, orderCode, searchStr })
    // return getRequestData(res);
}

// export function getAllReleasesForUser(page, size, startDate, endDate, categories, orderCode, searchStr) {
//     return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/published/getAllReleasesForUser`,
//         { page, size, startDate, endDate, categories, orderCode, searchStr, onlyTenant: false })
// }
export async function getAllReleasesForUser(page, size, startDate, endDate, categories, orderCode, searchStr) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/getAllReleasesForUser`,
        { page, size, startDate, endDate, categories, orderCode, searchStr, onlyTenant: false })
    // return getRequestData(res);
}

export async function getReleaseById(releaseGuid, projectGuid) {

    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/getReleaseById`, {
        releaseGuid, projectGuid
    })
    // return getRequestData(res);
}

// export function getAuditById(auditReleaseGuid) {
//     // let res = request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/getAuditById`, {
//     return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/getReleaseById`, {
//         releaseGuid: auditReleaseGuid
//     })
//     // return res;
// }

export function getProjectById(projectGuid) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/project/getProjectById`, {
        projectGuid
    })
    //new
    // return request.post(`${process.env.VUE_APP_WORKFLOW_API}/project/api/getProjectById`, {
    //     projectGuid
    // })
}


export function getProjectTasks(projectGuid) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/getProjectTasks`, {
        projectGuid
    })
}


export function getTaskById(taskGuid, releaseGuid) {
    if (releaseGuid) {
        return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/published/getTaskById`, {
            taskGuid, releaseGuid,
        })
    }
    else {
        return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/project/getTaskById`, {
            taskGuid,
        })
    }
}

export function getUserInfo() {

    return request({
        url: `${process.env.VUE_APP_WORKFLOW_API}/api/getUserInfo`,
        method: 'post'
    })
}

export function getMyUserInfo() {

    return request({
        url: `${process.env.VUE_APP_LOGIN_SERVER}/api/getMyUserInfo`,
        method: 'post'
    })
}

// export function saveProject(item) {
//     console.log('saveProject', item)
//     return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/project/saveProject`, item);
// }

export async function saveProject(item) {
    console.log('saveProject', item)
    // let res = await request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/saveProject`, item);
    // return getRequestData(res)
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/saveProject`, item);
}

export function saveProjectModel(item) {
    console.log('saveProjectBaseModel', item)
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/project/saveProjectModel`, item);
}

export function saveTask(item) {
    console.log('saveProject', item)
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/saveTask`, item);
}

export function setTaskStartStep(taskGuid, stepGuid) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/setTaskStartStep`, { taskGuid: taskGuid, beginStepGuid: stepGuid });
}

export function saveStep(item) {
    console.log('saveStep', item)
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/saveStep`, item);
}

// export function saveTaskSteps(item) {
//     console.log('saveTaskSteps', item)
//     return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/saveTaskSteps`, item);
// }

export async function saveTaskSteps(item) {
    console.log('saveTaskSteps', item)
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_TASK}/api/saveTaskSteps
    `, item);
    // return getRequestData(res);
}


export function saveLink(item) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/saveLink`, item);
}


export function saveAsset(item) {
    console.log('saveAsset', item)
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/project/saveAsset`, item);
}

export function saveAssetNew(item) {
    console.log('saveAsset', item)
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_ASSET}/api/saveAsset`, item);
}

export function getPractices(page, size) {
    console.log('getPractices', page, size)
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/getPracticeLog`, { page, size })
}

export function getPracticeDetail(practiceGuid) {
    console.log('getPracticeDetail')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/getPracticeDetail`, { practiceGuid })
}

//获取历史版本列表
export function getHistoriesByProject(projectGuid, generateType) {
    console.log('getHistoriesByProject')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/history/getHistoriesByProject`, { projectGuid, generateType })
}

//保存历史版本
export function generateHistory(options) {
    console.log(options);
    console.log('generateHistory')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/history/generateHistory`, { projectGuid: options.projectGuid, historyName: options.historyName, generateType: options.generateType })
}

//编辑历史版本
export function saveHistory(item) {
    console.log(item);
    console.log('saveHistory')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/history/saveHistory`, { item })
}

//编辑历史版本
export function restoreHistory(historyGuid) {
    console.log(historyGuid);
    console.log('restoreHistory')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/history/restoreHistory`, { historyGuid })
}

//获取素材总列表
export function getProjectAssets(projectGuid, page, size) {
    console.log('getHistoriesByProject')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/project/getAssets`, { projectGuid, page, size })
}
// export function getHistoriesByProject(projectGuid) {
//     console.log('getHistoriesByProject')
//     return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/history/getHistoriesByProject`, { projectGuid })
// }

// export function getHistoriesByProject(projectGuid) {
//     console.log('getHistoriesByProject')
//     return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/history/getHistoriesByProject`, { projectGuid })
// }


//发布项目
export function publishProject(projectGuid, isPublic) {
    console.log('getHistoriesByProject')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/published/publishProject`, { projectGuid, isPublic })
}

//取消发布
// export function removeRelease(releaseGuid) {
//     console.log('getHistoriesByProject')
//     return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/published/removeRelease`, { releaseGuid })
// }

//获取图片sas
export function getUploadSas(blobName) {
    console.log('getUploadSas')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/getUploadSas`, { blobName })
}

//获取图片sas
export function getUploadSasNew(blobName) {
    console.log('getUploadSas')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/getUploadSas`, { blobName })
}

//上传图片
export function saveMyProfilePhoto(profilePhoto) {
    console.log('saveMyProfilePhoto')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/mine/saveMyProfilePhoto`, { item: { profilePhoto } })
}

//查询历史版本
export function getProjectReleases(projectGuid) {
    console.log('getProjectReleases')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/published/getProjectReleases`, { projectGuid })
}

//查询历史版本
export function getStepCheckpoint(stepGuid) {
    console.log('getStepCheckpoint')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/project/getStepCheckpoint`, { stepGuid })
}

//强制删除素材
export function deleteCheckpointByAsset(assetGuid) {
    console.log('deleteCheckpointByAsset')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/project/deleteCheckpointByAsset`, { assetGuid })
}

//上传定位信息
export function saveProjectModelPosition(options) {
    console.log('saveProjectModelPosition')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API}/api/saveProjectModelPosition`, options)
}

//获取二维码图片
// export function getProjectQRCode(projectGuid) {
//     console.log('getProjectQRCode')
//     return request.get(`${process.env.VUE_APP_WORKFLOW_API}/api/project/getProjectQRCode?projectGuid=${projectGuid}`)
// }

//获取全部信息
export function getOprAllProjectInfo(options) {
    console.log('getOprAllProjectInfo')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_STAT}/api/getOprAllProjectInfo`, options)
}

//获取外部列表信息
export function getOprSessionByProject(options) {
    console.log('getOprSessionByProject')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_STAT}/api/getOprSessionByProject`, options)
}

//获取版本列表信息
export function getOprSessionByRelease(options) {
    console.log('getOprSessionByRelease')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_STAT}/api/getOprSessionByRelease`, options)
}

//获取流程断点图
export function getMostExitStep(options) {
    console.log('getMostExitStep')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_STAT}/api/getMostExitStep`, options)
}

//获取流程难点图
export function getTopDurationStep(options) {
    console.log('getTopDurationStep')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_STAT}/api/getTopDurationStep`, options)
}

//编辑步骤(新)
export async function updateStepInfo(item) {
    console.log('updateStepInfo', item)
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_TASK}/api/step/updateStepInfo`, item);
    // return res;
}
export async function getStepInfo(stepGuid) {
    // let res = await request.post(`${process.env.VUE_APP_NEW_BASE_API}/step/api/getStepInfo`, {
    //     stepGuid,
    // })
    // return getRequestData(res);
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_TASK}/api/step/getStepInfo`, {
        stepGuid,
    })
    // if (res.code === 200) {
    //     return res;
    // } else {
    //     throw new Error(res);
    // }
}

//获取素材
export async function getAssetById(assetGuid) {
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_ASSET}/api/getAssetById`, { assetGuid });
    // return res;
}

//获取语音token
export async function getSpeechToken(assetGuid) {
    return request.get(`${process.env.VUE_APP_LOGIN_SERVER}/api/advanced/getSpeechToken`, { assetGuid });
    // return res;
}

//下架本项目所有版本的发布
export async function removeAllReleaseByProject(projectGuid) {
    console.log('removeAllReleaseByProject', projectGuid)
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/removeAllReleaseByProject`, { projectGuid });
    // return res;
}

//取消发布
export function removeRelease(releaseGuid) {
    // console.log('getHistoriesByProject')
    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/removeRelease`, { releaseGuid })
}

//获取草稿箱中的项目
export async function getMyProjectsInEdit(page, size) {
    // console.log('getHistoriesByProject')

    return request.post(`${process.env.VUE_APP_WORKFLOW_API_PROJECT}/api/getMyProjectsInEdit`, { page, size })
    // return getRequestData(res);
}