<template>
  <div class="createTaskWarp">
    <!-- 创建任务 -->
    <el-dialog
      
      :title="$t('createTask.createTask')"
      v-model="createTask"
      width="500px"
      :before-close="handleClose"
    >
      <el-form
        @submit.prevent
        ref="taskFrom"
        :model="rulefrom"
        :rules="rules"
        label-position="left"
      >
        <el-form-item prop="taskName">
          <!-- 填写流程名 -->
          <el-input
            ref="taskNameInput"
            size="small"
            type="text"
            v-model="rulefrom.taskName"
            :placeholder="$t('createTask.taskName')"
            @keyup.enter="ok('taskFrom')"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer" v-if="createTask">
          <!-- 取 消 -->
          <el-button size="middle" @click="createTask = false">{{
            $t("createTask.cancel")
          }}</el-button>
          <!-- 确 定 -->
          <el-button size="middle" type="primary" @click="ok('taskFrom')">{{
            $t("createTask.ok")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import { saveTask } from "@/api/workflow";
import { ElMessage } from "element-plus";
export default {
  props: ["newTaskIndex"],
  data() {
    return {
      rulefrom: {
        taskName: "",
      },
      rules: {
        taskName: [{ required: true, message: "", trigger: "blur" }],
      },
      createTask: false,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    init(bool, data) {
      this.createTask = bool;
      console.log(this.newTaskIndex);
      this.rulefrom.taskName = "";
      this.$nextTick(() => {
        this.$refs.taskNameInput.focus();
      });
    },
    async ok(formName) {
      if (!this.rulefrom.taskName) {
        // 请填写流程名称！
        ElMessage.error(this.$t("createTask.tip1"));
        return;
      }
      let options = {
        taskName: this.rulefrom.taskName,
        isDeleted: false,
        projectGuid: this.$route.query.projectGuid,
        taskGuid: uuid.v4(),
        description: "",
        taskOrder: this.newTaskIndex,
      };
      try {
        let result = await saveTask(options);
        this.createTask = false;
        ElMessage.success({
          // 创建成功！
          message: this.$t("createTask.tip2"),
          type: "success",
        });
        this.$emit("refreshTaskList", result);
      } catch (e) {
        // 创建失败
        ElMessage.error(this.$t("createTask.tip3"));
      }
    },
  },
  components: {},
};
</script>

<style scoped lang='scss'>
.createTaskWarp {
  :deep(.el-dialog) {
    border-radius: 12px;
    .el-dialog__header {
      padding: 20px 30px 10px;
      text-align: left;
      .el-dialog__title {
        color: #4582ff;
        text-align: left;
      }
    }
    .el-dialog__body {
      padding: 20px 30px 0;
    }
    .el-dialog__footer {
      .dialog-footer {
        button {
          width: 120px;
          height: 32px;
          border-radius: 4px;
          transition: background 0.1s;
          border: none;
          padding: 9px 15px;
          min-height: auto;
        }
        button:nth-child(1) {
          background: #a8b7ca;
          color: #ffffff;
        }
        button:nth-child(1):hover {
          background: #b8c2d2;
        }
        button:nth-child(2) {
          background: #4582ff;
          color: #ffffff;
        }
        button:nth-child(2):hover {
          background: #8fb5ff;
        }
      }
    }
  }
}
</style>
