<template>
  <div>
    <el-dialog
      :title="$t('auditHistory.auditLog')"
      v-model="isShow"
      width="630px"
      :before-close="handleClose"
    >
      <div class="wrap">
        <div class="header" v-if="model">
          <!-- 项目名称 -->
          {{ $t("auditHistory.projectName") + ": " + model.projectName }}
        </div>
        <div class="body">
          <div class="listWrap">
            <div class="list" v-if="dataList.length > 0">
              <div
                class="item"
                v-for="(item, index) in dataList"
                :key="item.auditHistoryGuid"
              >
                <div
                  class="itemWrap"
                  :class="index === dataList.length - 1 ? 'noBottom' : ''"
                >
                  <div class="item_header">
                    <div class="version">
                      <div class="imgPlay">
                        <img
                          v-if="item.auditStatus === auditStatusType.rejected"
                          src="@/assets/myTask/reject.png"
                          alt=""
                        />
                        <img
                          v-else-if="item.auditStatus === auditStatusType.passed"
                          src="@/assets/myTask/passed1.png"
                          alt=""
                        />
                        <img
                          v-if="item.auditStatus === auditStatusType.tobeAudit"
                          src="@/assets/myTask/submitAudit.png"
                          alt=""
                        />
                        <img
                          v-if="item.auditStatus === auditStatusType.withdraw"
                          src="@/assets/myTask/back.png"
                          alt=""
                        />
                      </div>
                      <!-- 版本： -->
                      <!-- <span>{{
                        $t("auditHistory.version") +
                          formatDateTimeVue(item.versionAt)
                      }}</span> -->
                      <span>{{
                        $t("auditHistory.version") + item.version
                      }}</span>
                    </div>
                    <div class="auditTime">
                      <span v-if="item.auditStatus === auditStatusType.tobeAudit">
                        {{ $t("auditHistory.submitTime") }}</span>
                      <span v-else-if="item.auditStatus === auditStatusType.withdraw">
                        {{ $t("auditHistory.withdrawTime")  }}</span>
                      <span v-else>{{
                        $t("auditHistory.auditTime")}}</span>
                      <span>{{formatDateTimeVue(item.createdAt)}}</span>
                    </div>
                  </div>
                  <div class="item_body">
                    <div class="item_body_wrap">
                      <div
                        class="submit_remarks"
                        v-if="item.content && item.auditStatus === auditStatusType.tobeAudit"
                      >
                        <div class="submit_remarks_title">
                          <!-- 提交备注 -->
                          {{ $t("auditHistory.submitRemarks") }}
                        </div>
                        <div class="content">{{ item.content }}</div>
                      </div>
                      <div
                        class="submit_reason"
                        v-if="item.reason && item.auditStatus === auditStatusType.rejected"
                      >
                        <div class="submit_reason_title">
                          <!-- 拒绝原因 -->
                          {{ $t("auditHistory.reason") }}
                        </div>
                        <div class="content">{{ item.reason }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="noData">
              {{ $t("auditHistory.word1") }}
            </div>
          </div>
        </div>
      </div>
      <template #footer> </template>
    </el-dialog>
  </div>
</template>

<script>
import { getAuditHistoryList } from "@/api/mine.js";
import { formatDateTime } from "@/utils/time.js";
import { AuditStatusType, PublicScopeType } from "@/utils/common";

export default {
  components: {},
  props: {},
  data() {
    return {
      isShow: false,
      model: null,
      dataList: [],
      auditStatusType: AuditStatusType
    };
  },
  computed: {

  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    formatDateTimeVue(time) {
      let res = formatDateTime(time);
      console.log(res);
      return res;
    },
    async open(bool, data) {
      this.isShow = bool;
      this.model = data;
      let res = await getAuditHistoryList(data.projectGuid, 0, 999999);
      console.log(res);
      this.dataList = res.data;
    },
    submit() {},
    handleClose() {
      this.isShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.el-dialog) {
  border-radius: 12px;
  > .el-dialog__body {
    padding: 0;
  }
}
:deep(.el-dialog__header) {
  padding: 20px 40px 10px;
  text-align: left;

  .el-dialog__title {
    color: #4582ff;
    font-size: 1.1rem;
  }
}
:deep(.el-dialog__footer) {
  > .dialog-footer {
    button {
      width: 120px;
      height: 32px;
      border-radius: 4px;
      transition: background 0.1s;
      border: none;
      padding: 9px 15px;
      min-height: auto;
    }
    > button:nth-child(1) {
      background: #4582ff;
      color: #ffffff;
    }
    > button:nth-child(1):hover {
      background: #8fb5ff;
    }
  }
}
.wrap {
  width: 100%;
  > .header {
    text-align: left;
    padding: 0 40px;
    box-sizing: border-box;
    color: #a3a3a3;
    font-size: 12px;
  }
  > .body {
    padding: 10px 40px;
    height: 64vh;
    > .listWrap {
      width: 100%;
      height: 100%;
      > .list {
        width: 100%;
        height: 100%;
        overflow: overlay;
        > .item {
          font-size: 14px;
          > .itemWrap {
            border-bottom: 1px solid #dde2e5;
            padding-bottom: 10px;
            > .item_header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              line-height: 36px;
              margin-top: 8px;
              > .version {
                color: #474747;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                > .imgPlay {
                  width: 22px;
                  height: 22px;
                  margin-right: 5px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  > img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
              > .auditTime {
                color: #7f7f7f;
              }
            }
            > .item_body {
              > .item_body_wrap {
                > div {
                  margin-top: 8px;
                  background: #f1f1f1;
                  border-radius: 6px 6px 6px 6px;
                  padding: 14px 17px;
                  > div {
                    font-size: 14px;
                    text-align: left;
                  }
                  > .submit_remarks_title {
                    color: #4582ff;
                  }
                  > .submit_reason_title {
                    color: #f94a4a;
                  }
                  > .content {
                    font-size: 14px;
                    color: #686868;
                    line-height: 21px;
                    margin-top: 8px;
                  }
                }
              }
            }
          }
          > .noBottom {
            border: 1px solid #fff;
          }
        }
      }
      > .noData {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
      }
      > .list::-webkit-scrollbar {
        width: 14px;
        height: 14px;
      }
      > .list::-webkit-scrollbar-corner {
        background-color: transparent;
      }
      > .list::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.1);
        background-clip: padding-box;
        border: solid transparent;
        border-radius: 10px;
      }
      > .list::-webkit-scrollbar-track {
        background-clip: padding-box;
        border: solid transparent;
        border-width: 1px;
      }
    }
  }
}
</style>
