import LangManager from "./langManager"

export function getLanguage() {
    return LangManager.getLanguage().value;
    // let lang = localStorage.getItem("language_type");
    // if (lang === "zh") {
    //     return lang
    // } else if (lang === "kr") {
    //     return "kr"
    // } else if (lang === "en") {
    //     return "en"
    // } else {
    //     return "en"
    // }
}
export function getLanguageToSpeak(lang) {
    if (lang === "zh" || lang === "cn") {
        return "zh-CN"
    } else if (lang === "kr" || lang === "ko") {
        return "ko-KR"
    } else if (lang === "en") {
        return "en-US"
    } else {
        return "en-US"
    }
}