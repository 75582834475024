<template>
  <div>
    <div class="assetHeader">
      <button
        :class="assetSelectTag === 'all' ? 'active' : ''"
        @click="select('all')"
      >
        <!-- 全部 -->
        {{ $t("assetPicker.all") }}
      </button>
      <button
        v-if="projectInfo?.editType === 0"
        :class="assetSelectTag === 'model' ? 'active' : ''"
        @click="select('model')"
      >
        <!-- 模型 -->
        {{ $t("assetPicker.model") }}
      </button>
      <button
        :class="assetSelectTag === 'picture' ? 'active' : ''"
        @click="select('picture')"
      >
        <!-- 图片 -->
        {{ $t("assetPicker.picture") }}
      </button>
      <button
        :class="assetSelectTag === 'video' ? 'active' : ''"
        @click="select('video')"
      >
        <!-- 视频 -->
        {{ $t("assetPicker.video") }}
      </button>
      <button
        :class="assetSelectTag === 'PDF' ? 'active' : ''"
        @click="select('PDF')"
      >
        <!-- PDF -->
        {{ $t("assetPicker.PDF") }}
      </button>
      <!-- v-if="!fileItem.fileToUpload" -->
      <!-- <el-upload
        class="upload-btn"
        action=""
        accept=".fbx,.obj,.glb,.gltf,.stl,video/mp4,image/*,application/pdf,.FBX,.GLB,.GLTF,.STL,VIDEO/MP4,APPLICATION/PDF"
        :show-file-list="false"
        :multiple="false"
        :auto-upload="false"
        :on-change="onUploadChange"
      > -->
      <!-- <el-button class="btn">{{ $t('uploadAsset.selectFile') }}</el-button> -->
      <!-- 上传素材 -->
      <el-tooltip
        effect="light"
        :content="$t('assetPicker.uploadAsset')"
        placement="bottom"
        :show-after="500"
      >
        <el-button @click="openUpload" :disabled="!isCreator" class="addAsset"
          >+</el-button
        >
      </el-tooltip>

      <!-- <el-button slot="trigger" size="small" type="primary">选取文件</el-button> -->
      <!-- </el-upload> -->
    </div>
    <div class="assetBody">
      <div class="assetItem" v-for="(item, index) in showList" :key="index">
        <div class="assetItemContent" @click="preViewAsset(item)">
          <!-- <img
            :src="
              item.thumbnailUrl
                ? item.thumbnailUrl
                : '@/assets/task-detail/defaultPDF.png'
            "
            alt=""
          />
           -->
          <el-image
            v-if="getFileTypeByFileName(item.originFileName)"
            :src="item.thumbnailUrl"
            fit="cover"
          >
            <template #error>
              <div class="image-slot">
                <img src="@/assets/task-detail/defaultPDF.png" />
              </div>
            </template>
          </el-image>
          <div class="contentCover">
            <div>
              {{ item.assetName }}
            </div>
          </div>
          <!-- 删除 -->
          <el-tooltip
            :content="$t('assetPicker.delete')"
            placement="bottom"
            effect="light"
            show-after="500"
          >
            <div class="deleteIcon" @click.stop="deleteAsset(item, index)">
              <i class="el-icon-delete"></i>
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="enpty" v-if="showList.length === 0">
        <div>
          <img src="@/assets/myTask/defaultEnpty.png" alt="" />
          <!-- 当前无可浏览素材 -->
          <div>{{ $t("assetPicker.noAsset") }}</div>
          <div v-if="selectItem === 2" class="createBtn">
            <!-- 创建项目 -->
            <button @click="goCreateProject">
              {{ $t("assetPicker.createProject") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <UploadAsset
      v-if="projectInfo && projectInfo.projectGuid"
      ref="uploadAsset"
      @onClose="addAsset"
      @preViewModel="preViewModel"
      :projectGuid="projectInfo.projectGuid"
      :projectInfo="projectInfo"
    ></UploadAsset>
    <ModelLoader
      v-if="projectInfo?.editType === 0"
      ref="modelLoader"
      @getThumbnail="getThumbnail"
    />
    <PictureView ref="pictureView" />
    <VideoView ref="videoView" />
    <PdfView ref="pdfView" />
    <!-- <iframe
      id="modelView"
      ref="modelLoader"
      src="../../../../public/modelLoader/index.html"
    ></iframe> -->
  </div>
</template>

<script>
import UploadAsset from "@/views/uploadAsset/uploadAsset.vue";
import { getProjectInfo } from "@/utils/setLocalstorage";
import {
  getProjectAssets,
  saveAsset,
  deleteCheckpointByAsset,
} from "@/api/workflow";
import { ElMessage } from "element-plus";

import ModelLoader from "@/views/components/modelLoader/modelLoader.vue";
import PictureView from "@/views/components/pictureView/pictureView.vue";
import VideoView from "@/views/components/videoView/videoView.vue";
import PdfView from "@/views/components/pdfView/pdfView.vue";
export default {
  components: { UploadAsset, ModelLoader, PictureView, VideoView, PdfView },
  props: {
    // show: null,
    projectGuid: { type: String },
    isCreator: { type: Boolean },
    projectInfo: { type: Object },
  },
  data() {
    return {
      showUpload: false,
      assetSelectTag: "all",
      nums: 30,
      // projectInfo: JSON.parse(getProjectInfo()),
      assetInfo: {},
      showList: [],
    };
  },
  created() {
    console.log(this.projectInfo);
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    getThumbnail(data) {
      console.log(data);
      this.$refs.uploadAsset.thumbnailData(data);
    },
    preViewModel(data) {
      console.log(data);
      this.$refs.modelLoader.setShow(true, "localUrl", data);
    },
    preViewAsset(item) {
      console.log(item);
      console.log(this.$refs);
      // if (this.getFiltTypeByFileName(item.originFileName) === "model") {
      // }
      switch (this.getFileTypeByFileName(item.originFileName)) {
        case "model":
          this.$refs.modelLoader.setShow(true, "netUrl", item);
          // 代码块
          break;
        case "picture":
          this.$refs.pictureView.setSwitch(true, item.fileUrl);
          console.log("picture");
          // 代码块
          break;
        case "video":
          this.$refs.videoView.setSwitch(true, item.fileUrl);
          // 代码块
          break;
        case "PDF":
          console.log("PDF");
          this.$refs.pdfView.setSwitch(true, item.fileUrl);
          // 代码块
          break;
      }
    },
    // switchShowList(listIndex,dataList){
    //   //0全部  1模型 2图片 3视频 4PDF
    //   if (listIndex === 0) {
    //
    //   }
    // },
    getFileTypeByFileName(fileName) {
      let modelTypes = [".fbx", ".obj", ".glb", ".gltf", ".stl"];
      let pictureTypes = [".jpg", ".jpeg", ".png", ".tif", ".bmp", "gif"];
      let videoTypes = [
        ".mp4",
        ".rmvb",
        ".rm",
        ".asf",
        ".divx",
        ".mpg",
        ".mpeg",
        ".wmv",
        ".mkv",
        ".vob",
      ];
      let PDFTypes = ["PDF", "pdf"];
      let type = false;
      modelTypes.forEach((value, index) => {
        if (fileName.indexOf(value) !== -1) {
          type = "model";
        }
      });
      pictureTypes.forEach((value, index) => {
        if (fileName.indexOf(value) !== -1) {
          type = "picture";
        }
      });
      videoTypes.forEach((value, index) => {
        if (fileName.indexOf(value) !== -1) {
          type = "video";
        }
      });
      PDFTypes.forEach((value, index) => {
        if (fileName.indexOf(value) !== -1) {
          type = "PDF";
        }
      });
      return type;
    },
    filterAssetType(dataList) {
      let model = [];
      let modelTypes = [".fbx", ".obj", ".glb", ".gltf", ".stl"];
      let picture = [];
      let pictureTypes = [".jpg", ".jpeg", ".png", ".tif", ".bmp"];
      let video = [];
      let videoTypes = [
        ".mp4",
        ".rmvb",
        ".rm",
        ".asf",
        ".divx",
        ".mpg",
        ".mpeg",
        ".wmv",
        ".mkv",
        ".vob",
      ];
      let PDF = [];
      let PDFTypes = ["PDF", "pdf"];
      dataList.forEach((value, index) => {
        // return modelTypes.includes(value.originFileName);
        modelTypes.forEach((v, i) => {
          if (value.originFileName && value.originFileName.includes(v)) {
            model.push(value);
          }
        });
      });
      dataList.forEach((value, index) => {
        // return pictureTypes.includes(value.originFileName);
        pictureTypes.forEach((v, i) => {
          if (value.originFileName && value.originFileName.includes(v)) {
            picture.push(value);
          }
        });
      });
      dataList.forEach((value, index) => {
        // return videoTypes.includes(value.originFileName);
        videoTypes.forEach((v, i) => {
          if (value.originFileName && value.originFileName.includes(v)) {
            video.push(value);
          }
        });
      });
      dataList.forEach((value, index) => {
        // return PDFTypes.includes(value.originFileName);
        PDFTypes.forEach((v, i) => {
          if (value.originFileName && value.originFileName.includes(v)) {
            PDF.push(value);
          }
        });
      });
      return { model, picture, video, PDF };
    },
    deleteAsset(item, index) {
      console.log(item);
      // 是否删除该素材？  删除素材
      this.$confirm(this.$t("assetPicker.tip1"), this.$t("assetPicker.tip2"), {
        // 确定
        confirmButtonText: this.$t("assetPicker.tip3"),
        // 取消
        cancelButtonText: this.$t("assetPicker.tip4"),
        customClass: "deleteBox",
        type: "warning",
      })
        .then(async () => {
          let deleteIndex = null;
          this.assetInfo.data.forEach((value, index) => {
            if (value.assetGuid === item.assetGuid) {
              deleteIndex = index;
            }
          });
          let options = {
            assetGuid: item.assetGuid,
            isDeleted: true,
          };
          try {
            await saveAsset(options);
            console.log(this.assetInfo.data);

            this.assetInfo.data.splice(deleteIndex, 1);
            this.$message({
              type: "success",
              // 删除成功
              message: this.$t("assetPicker.tip5"),
            });
            this.select(this.assetSelectTag);
          } catch (e) {
            // ElMessage.error("删除失败，请重新删除！");
            console.log(e);
            if (e && e.data && e.data.code === 20304) {
              // 该素材已在某个流程步骤中被使用,不可删除  提示
              this.$confirm(
                this.$t("assetPicker.tip6"),
                this.$t("assetPicker.tip7"),
                {
                  // 强制删除
                  confirmButtonText: this.$t("assetPicker.tip8"),
                  // 取消
                  cancelButtonText: this.$t("assetPicker.tip9"),
                  customClass: "deleteBox",
                  type: "warning",
                }
              )
                .then(async () => {
                  //强制删除
                  try {
                    await deleteCheckpointByAsset(item.assetGuid);
                    await saveAsset(options);
                    console.log(this.assetInfo.data);
                    this.assetInfo.data.splice(deleteIndex, 1);
                    this.$message({
                      type: "success",
                      // 删除成功
                      message: this.$t("assetPicker.tip10"),
                    });
                    this.select(this.assetSelectTag);
                  } catch (e) {
                    console.log(e);
                  }
                })
                .catch(() => {});
            }
          }
        })
        .catch(() => {});
    },
    // addResult(data) {
    //   this.assetInfo.data.push(data);
    // },
    async getAssetList(projectId, page, size) {
      this.assetInfo = await getProjectAssets(projectId, page, size);
      console.log(this.assetInfo.data);
      console.log(this.filterAssetType(this.assetInfo.data));
      this.select("all");
    },
    addAsset(data) {
      // this.showUpload = false;
      if (data) {
        this.assetInfo.data.push(data);
        this.select(this.assetSelectTag);
      }
      this.$refs.uploadAsset.setSwitch(false);
    },
    openUpload() {
      console.log(this.projectInfo);
      this.showUpload = true;
      console.log(this.$refs.uploadAsset);
      this.$refs.uploadAsset.setSwitch(true);
    },
    select(content) {
      this.assetSelectTag = content;
      console.log(content);
      if (content === "all") {
        if (this.projectInfo?.editType === 0) {
          this.showList = this.assetInfo.data;
        } else {
          let list = this.filterAssetType(this.assetInfo.data);
          this.showList = [...list.picture, ...list.video, ...list.PDF];
        }
      } else if (content === "model") {
        this.showList = this.filterAssetType(this.assetInfo.data).model;
        // this.nums = 10;
      } else if (content === "picture") {
        this.showList = this.filterAssetType(this.assetInfo.data).picture;
        // this.nums = 8;
      } else if (content === "video") {
        this.showList = this.filterAssetType(this.assetInfo.data).video;
        // this.nums = 5;
      } else if (content === "PDF") {
        this.showList = this.filterAssetType(this.assetInfo.data).PDF;
        // this.nums = 7;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.assetHeader {
  width: 100%;
  //   height: 2.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  > button {
    outline: none;
    border: none;
    background: #fff;
    width: 2.8rem;
    height: 1.8rem;
    font-size: 0.85rem;
    cursor: pointer;
    border-radius: 3px;
    // padding: 0.2rem 0.4rem;
  }
  > button:hover {
    background: #edf3ff;
  }
  .addAsset {
    width: 26px;
    height: 26px;
    min-width: auto;
    min-height: auto;
    // line-height: 1.5rem;
    // text-align: center;
    background: #deb573;
    color: #fff;
    border-radius: 50%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    font-size: 16px;
    padding: 0;
  }
  .addAsset:hover {
    background: #deb573;
  }
  .active {
    background: #4582ff !important;
    color: #fff;
  }
}
.assetBody {
  //   float: left;
  //   justify-content: flex-start;

  //   align-items: ;

  flex-wrap: wrap;
  width: 100%;
  height: calc(100vh - 12rem);
  overflow: auto;
  padding: 0rem 0.5rem;
  box-sizing: border-box;
  > div {
    float: left;
    margin: 0 0.4rem 0 0.4rem;
    width: 28%;
    height: 0;
    padding-bottom: 28%;
    position: relative;
    background: #fff;
    margin-top: 1rem;
    overflow: hidden;
    .assetItemContent {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      > .el-image {
        width: 100%;
        height: 100%;
        .img {
          width: 100%;
          height: 100%;
        }
      }
      > img {
        width: 100%;
      }
      .contentCover {
        width: 100%;
        height: 27%;
        // background-image: linear-gradient(
        //   to bottom,
        //   rgba(0, 0, 0, 0),
        //   rgba(0, 0, 0, 100)
        // );

        position: absolute;
        bottom: 0px;
        color: #fff;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 0.5rem;
        padding: 0 4px;
        visibility: visible;
        background: rgba(0, 0, 0, 0.6);
        box-sizing: border-box;
        > div {
          width: 100%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          line-height: 198%;
        }
      }
      .deleteIcon {
        position: absolute;
        bottom: 0px;
        right: 0px;
        border: 1px solid #b50202;
        color: #b50202;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.3rem;
        font-size: 1.2rem;
        visibility: hidden;
        background: rgba(181, 2, 2, 0.3);
      }
    }
    .assetItemContent:hover {
      .contentCover {
        visibility: hidden;
      }
      .deleteIcon {
        visibility: visible;
      }
    }
  }
  .enpty {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 100%;
    height: 85%;
    padding: 0;
    > div {
      .createBtn {
        margin-top: 1rem;
        > button {
          width: 120px;
          height: 32px;
          background: #4582ff;
          border-radius: 4px 4px 4px 4px;
          outline: none;
          border: none;
          background: #4582ff;
          color: #fff;
          line-height: 32px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}
.assetBody::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
.assetBody::-webkit-scrollbar-corner {
  background-color: transparent;
}
.assetBody::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  background-clip: padding-box;
  border: solid transparent;
  border-radius: 10px;
}
.assetBody::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid transparent;
  border-width: 1px;
}
#modelViewWarp,
#pictureViewWarp,
#videoViewWarp {
  .el-dialog__body {
    padding: 0;
    > img {
      width: 100%;
    }
    > video {
      width: 100%;
    }
  }
}
#pdfViewWarp {
  .el-dialog__body {
    padding: 0;
    > .pdfWarp {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      > span {
        width: 800px;
      }
    }
  }
}
</style>
