<template>
  <!-- <div class="flex-container" >
    <div class="flex-item" ></div> -->
  <div
    class="step-main"
    v-if="
      $store.state.TaskDetail.selectShape ||
        $store.state.TaskDetail.showHistory ||
        $store.state.TaskDetail.showProjectInfo
    "
  >
    <div class="deleteIcon">
      <i class="el-icon-close" @click="closeTools"></i>
    </div>
    <!-- v-if="shapeItem && nodeType.includes(shapeItem.data.type)" -->
    <div
      v-if="(shapeItem && nodeType.includes(shapeItem.data.type)) || !shapeItem"
    >
      <div
        class="head"
        v-if="
          (shapeItem && nodeType.includes(shapeItem.data.type)) ||
            $store.state.TaskDetail.selectShape ||
            $store.state.TaskDetail.showHistory ||
            $store.state.TaskDetail.showProjectInfo
        "
      >
        <!-- 编辑步骤 -->
        <!-- <div class="main-title">编辑步骤</div> -->
        <el-tabs
          v-if="!$store.state.TaskDetail.showHistory"
          v-model="activeName"
          @tab-click="handleClick"
          :class="activeName === 'editStepTag' ? 'myTabs' : ''"
        >
          <!-- 属性 -->
          <el-tab-pane
            :label="$t('editStep.word1')"
            name="editStepTag"
            :disabled="!shapeItem"
          >
            <div class="containerWrap">
              <div class="form-container">
                <el-form
                  @submit.prevent
                  ref="editForm"
                  :model="stepItem"
                  :rules="rules"
                  label-position="left"
                >
                  <!-- 名称 -->
                  <el-form-item :label="$t('editStep.name')" prop="stepName">
                    <!-- 名称 -->
                    <el-input
                      :disabled="
                        !isCreator ||
                          isClone ||
                          (shapeItem && shapeItem.data.type === 'pointer')
                      "
                      ref="focusedItem"
                      v-model="stepName"
                      :placeholder="$t('editStep.name')"
                      @input="setStepName"
                    ></el-input>
                  </el-form-item>
                  <!-- 描述 -->
                  <el-form-item
                    v-if="!projectInfo?.editType"
                    :label="$t('editStep.description')"
                    prop="description"
                  >
                    <!-- 描述 -->
                    <el-input
                      :disabled="
                        !isCreator ||
                          isClone ||
                          (shapeItem && shapeItem.data.type === 'pointer')
                      "
                      v-model="description"
                      :placeholder="$t('editStep.description')"
                      type="textarea"
                      :autosize="{ minRows: 4 }"
                      maxlength="500"
                      show-word-limit
                      @input="setDescription"
                    ></el-input>
                  </el-form-item>
                </el-form>
                <el-form-item
                  v-if="
                    shapeItem &&
                      shapeItem.data.type !== 'pointer' &&
                      shapeItem.data.type !== 'InputShape' &&
                      shapeItem.data.type !== 'CloneInputShape' &&
                      isCreator &&
                      projectInfo.editType === 1
                  "
                >
                  <template #label>
                    {{ $t("editStep.templateSelect") }}
                  </template>
                  <TemplateChose
                    :shapeItem="shapeItem"
                    :isCreator="isCreator"
                    :templateInfo="templateInfo"
                    @selectedTemplate="selectedTemplate"
                  />
                </el-form-item>
              </div>
            </div>
          </el-tab-pane>
          <!-- 素材库 -->
          <el-tab-pane
            :label="$t('editStep.assetList')"
            name="assets"
            v-if="isCreator && !auditReleaseMode"
          >
            <AssetPicker
              :projectInfo="projectInfo"
              :isCreator="isCreator"
              ref="assetPicker"
            />
          </el-tab-pane>
          <el-tab-pane :label="$t('editStep.projectInfo')" name="projectInfo">
            <ProjectInfo
              class="projectInfo"
              refs="ProjectInfo"
              :isCreator="isCreator"
              :auditReleaseMode="auditReleaseMode"
              :projectInfo="projectInfo"
              :own="own"
              @setOwn="setOwn"
              @projectNameChange="projectNameChange"
              @languageChange="languageChange"
            />
          </el-tab-pane>
        </el-tabs>
        <Thumbnail
          ref="thumbnail"
          v-if="
            activeName === 'editStepTag' &&
              projectInfo?.editType === 1 &&
              isCreator &&
              !auditReleaseMode
          "
          :shapeItem="shapeItem"
          :workingType="workingType"
          @setWorkingType="setWorkingType"
          @thumbnailLoaddone="thumbnailLoaddone"
          @gotoPointer="gotoPointer"
          class="thumbnailComp"
        />
      </div>
    </div>
    <div
      v-if="shapeItem && !nodeType.includes(shapeItem.data.type)"
      class="editLinkWarp"
    >
      <!-- 设置连线文字内容 -->
      <div class="linkText">{{ $t("editStep.word2") }}</div>
      <!-- 设置条件 -->
      <el-input
        :disabled="!isCreator || !shapeItem.data.properties.canEditEdgeText"
        class="myTextarea"
        type="textarea"
        ref="focusedItem"
        v-model="linkName"
        :placeholder="$t('editStep.word3')"
        @input="setLinkName"
      >
      </el-input>
    </div>
    <div v-if="$store.state.TaskDetail.showHistory">
      <HistoryEdition
        ref="historyEdition"
        @closeHistory="setSwitch"
        @saveHistory="saveHistory"
        :projectInfo="projectInfo"
      />
    </div>
  </div>
  <!-- <div class="flex-item" ></div>
</div> -->
</template>
<script src="./editStep.js"></script>
<style lang="scss" scoped>
@import "./editStep.scss";
</style>
