<template>
  <div class="inputTemplateWrap" id="inputWrap">
    <div
      class="templatesWrap"
      :style="{ width: maxWidth + 'px', height: maxHeight + 'px' }"
    >
      <div class="inputTemplate">
        <!-- <div class="numCtrls">
          <div class="textInput">
            <div class="text">需要输入的多媒体数量</div>
            <div class="number">
              {{ mediaNum }}
            </div>
          </div>
          <div class="textInput text">
            <div class="text">需要输入的文本数量</div>
            <div class="number">
              {{ textNum }}
            </div>
          </div>
        </div> -->
        <div
          class="medias"
          v-if="mediaNum > 0"
          :style="{
            height: textStyle,
          }"
        >
          <div
            class="mediaItem"
            v-for="(item, index) in dataList.medias"
            :key="index"
          >
            <div class="input">
              <!-- <el-input
                placeholder="在此输入内容标题"
                v-model="item.title"
                @change="mediaTitleChange"
              ></el-input> -->
              {{ item?.title }}
            </div>
            <div class="picture">
              <!-- <img v-if="item.imgUrl" :src="item.imgUrl" alt="" /> -->
              <img
                :style="{ maxWidth: mediaNum * 20 + '%' }"
                src="@/assets/task-detail/camera.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div
          class="texts"
          v-if="textNum > 0"
          :style="{ height: `calc(100% - 4rem - 1rem)` }"
        >
          <div :class="mediaNum < 1 ? 'textWraps active' : 'textWraps'">
            <div
              class="textItem"
              v-for="(item, index) in dataList.texts"
              :key="index"
            >
              <div class="title">
                <!-- <el-input
                  placeholder="在此输入内容标题"
                  v-model="item.title"
                  @change="textTitleChange"
                ></el-input> -->
                {{ item.title }}
              </div>
              <div class="content">
                <!-- {{ item.content }} -->
                <div class="imgWrap">
                  <img src="@/assets/task-detail/edit.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSelectedGuid } from "@/views/taskDetail/components/taskChart/setSelectShape.js";
import { updateStepInfo, getStepInfo } from "@/api/workflow";
export default {
  props: ["data"],
  data() {
    return {
      textNum: 0,
      mediaNum: 0,
      maxWidth: 1280,
      maxHeight: 720,
      dataList: {
        type: 5,
        medias: [],
        texts: [],
      },
    };
  },
  computed: {
    textStyle2() {
      if (this.mediaNum < 1) {
        return `height:100%,marginTop:1rem`;
      } else {
        return ``;
      }
    },
    textStyle() {
      if (this.textNum < 1) {
        // return "calc(100% - 4rem)";
        return "100%";
      } else {
        // return `calc(100% - 4rem - 2rem - 10px - ${this.textNum * 50}px)`;
        return `calc(100% - 2rem - 10px - ${this.textNum * 50}px)`;
      }
    },
  },
  created() {},
  mounted() {
    this.resize();
    // let dom = document.getElementById("defaultTemplateWrap");
    window.addEventListener("resize", this.resize);
  },
  unmounted() {
    window.removeEventListener("resize", this.resize);
  },
  watch: {
    data: {
      async handler(newVal, oldVal) {
        console.log(newVal);
        if (this.data) {
          try {
            // let stepInfo = newVal;

            // console.log(stepInfo);
            // console.log(JSON.parse(stepInfo.stepObjectJson));
            this.dataList = newVal;
            this.textNum = this.dataList.texts.length;
            this.mediaNum = this.dataList.medias.length;
          } catch (e) {
            this.dataList = {
              type: 5,
              medias: [],
              texts: [],
            };
            this.textNum = 0;
            this.mediaNum = 0;
          }
        } else {
          this.dataList = {
            type: 5,
            medias: [],
            texts: [],
          };
          this.textNum = 0;
          this.mediaNum = 0;
        }
      },
      immediate: true,
      deep: true,
    },
    // textStyle(newVal,oldVal){
    //   console.log(newVal,oldVal);
    // },
  },
  methods: {
    async initData(stepInfos) {},
    mediaTitleChange() {
      this.needSave("needSave", true);
    },
    textTitleChange() {
      this.needSave("needSave", true);
    },
    needSave(bool) {
      console.log("needSave", bool);
      this.$emit("needSave", bool);
    },
    resize() {
      let dom1 = document.getElementById("inputWrap");
      //用外边框的宽计算高
      let shouldHeight = dom1.offsetWidth * 0.5625;
      if (shouldHeight > dom1.offsetHeight) {
        this.maxHeight = dom1.offsetHeight;
        this.maxWidth = dom1.offsetHeight / 0.5625;
      } else {
        this.maxWidth = dom1.offsetWidth;
        this.maxHeight = dom1.offsetWidth * 0.5625;
      }
    },
    //点击文本加号
    addTextNum() {
      if (this.textNum >= 4) {
        return;
      }
      let options = {
        index: this.textNum,
        title: "",
        content: "",
        description: "",
      };
      this.dataList.texts.push(options);
      this.textNum++;
      this.needSave(true);
      // console.log(this.dataList);
    },
    //点击文本减号
    reduceTextNum() {
      if (this.textNum <= 0) {
        return;
      }
      this.textNum--;
      this.dataList.texts.pop();
      this.needSave("needSave", true);
    },
    //点击多媒体加号
    addMediaNum() {
      if (this.mediaNum >= 3) {
        return;
      }
      let options = {
        index: this.mediaNum,
        title: "",
        description: "",
        path: null,
        imgUrl: null,
      };
      this.dataList.medias.push(options);
      this.mediaNum++;
      this.needSave("needSave", true);
      // console.log(this.dataList);
    },
    //点击多媒体减号
    reduceMediaNum() {
      if (this.mediaNum <= 0) {
        return;
      }
      this.mediaNum--;
      this.dataList.medias.pop();
      this.needSave("needSave", true);
    },
    handleChange(data) {
      console.log(data);
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
.inputTemplateWrap {
  width: 100%;
  height: 100%;
  // background: #000;
  background: #f2f5fa;
  // padding: 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  .templatesWrap {
    width: 100%;
    position: relative;
    background: #fff;
    transition: all 0.3s;
    border-radius: 10px;
    overflow: hidden;
    .inputTemplate {
      // padding: 1.5rem 2.5rem;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      border-radius: 10px;
      box-sizing: border-box;
      .numCtrls {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #f1f5fd;
        width: 100%;
        height: 4rem;
        padding: 0 2.5rem;
        box-sizing: border-box;
        .textInput {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .text {
            color: #224b77;
            font-size: 1.2rem;
          }
          .number {
            margin-left: 1.2rem;
            width: 6.5rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 22px;
            font-weight: bold;
            .btn {
              width: 26px;
              height: 26px;
              outline: none;
              border: none;
              min-width: auto;
              min-height: auto;
              background: #deb573;
              color: #fff;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 16px;
              padding: 0;
              cursor: pointer;
            }
            .btn:hover {
              background: #deb573;
            }
            :deep(.el-input-number) {
              width: 2rem;
              margin: 0 0.5rem;
              .el-input {
                .el-input__inner {
                  padding: 0 5px;
                }
              }
            }
          }
        }
        > .text {
          margin-left: 1.5rem;
        }
      }

      .medias {
        width: 100%;

        display: flex;
        justify-content: space-around;
        align-items: center;
        .mediaItem {
          width: 100%;
          height: 100%;
          padding: 1rem;
          box-sizing: border-box;
          .input {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            font-weight: bold;
            overflow: hidden;
            background: #eee;
            border-radius: 5px;
          }
          .picture {
            width: 100%;
            height: calc(100% - 40px - 1rem);
            background: #f1f5f9;
            border-radius: 10px;
            margin-top: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            > img {
              width: 60%;
              max-width: 400px;
            }
          }
        }
      }
      .texts {
        width: 100%;
        padding: 0rem 1rem 1rem;
        box-sizing: border-box;
        .textWraps {
          border-radius: 10px;
          background: #dce2e8;
          padding: 0.5rem 0.5rem calc(0.5rem + 10px);
          box-sizing: border-box;
          .textItem {
            height: 40px;
            background: #f1f5f9;
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 5px;
            overflow: hidden;
            .title {
              width: 20%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 20px;
              font-weight: bold;
              background: #fff;
            }
            .content {
              width: 79%;
              height: 100%;
              text-align: left;
              line-height: 40px;
              > .imgWrap {
                width: 2.5rem;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                > img {
                  width: 100%;
                }
              }
            }
          }
        }
        .active {
          height: 100%;
          margin-top: 1rem;
        }
      }
    }
  }
}
</style>
