<template>
  <div id="modelViewWarp">
    <!-- 模型预览 -->
    <el-dialog
      :title="$t('modelLoader.modelPreview')"
      v-model="showLoader"
      :before-close="handleClose"
      :destroy-on-close="true"
      referrerpolicy="same-origin no-referrer-when-downgrade"
      custom-class="modelViewDialog"
      ref="modelViewDialog"
      @open="opened"
      width="1260px"
    >
      <iframe
        v-if="showIframe"
        id="modelView"
        ref="modelLoader"
        sandbox="allow-scripts allow-same-origin"
        frameborder="0"
        scrolling="no"
        style="width: 1200px;height:675px; border-radius: 15px"
      ></iframe>

      <ModelLoading
        v-if="isLoading"
        :scale="0.5"
        :class="isLoading ? 'modelLoading' : 'modelLoading modelLiodingHidden'"
      />
      <template #footer>
        <span class="dialog-footer" v-if="showLoader">
          <!-- <el-button @click="showLoader = false">取 消</el-button>
          <el-button type="primary" @click="generateThumbnail">确 定</el-button> -->
          <!-- <button class="selfBtn cancel">取消</button> -->
          <button
            v-if="type === 'netUrl'"
            class="selfBtn confirm"
            @click="closeModelView"
          >
            <!-- 关闭 -->
            {{ $t("modelLoader.close") }}
          </button>
          <button
            v-else
            class="selfBtn confirm"
            :disabled="isLoading"
            @click="generateThumbnail"
          >
            <i v-if="isLoading" class="el-icon-loading"></i>
            <!-- 确定 -->
            {{ $t("modelLoader.ok") }}
          </button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
// });
import { uuid } from "vue-uuid";
import ModelLoading from "@/views/components/animation/modelLoading.vue";
import { getLanguage } from "@/utils/getLanguage.js";
import LangManager from "../../../utils/langManager";
export default {
  components: { ModelLoading },
  props: {},
  data() {
    return {
      iframeSrc:
        this.$store.state.TaskDetail.headerPath + "modelLoader/index.html",
      showIframe: false,
      type: null,
      modelInfo: {},
      showLoader: false,
      showTask: false,
      iframWidth: 0,
      iframHeight: 0,
      testBase64: null,
      webGLLoaded: false,
      isLoading: true,
    };
  },
  computed: {},
  created() {
    console.log(this.$route);
  },
  async mounted() {},
  watch: {
    webGLLoaded(newVal, oldVal) {
      console.log(this.webGLLoaded);
      console.log(newVal);
      // if (newVal) {
      //   this.init();
      // }
    },
  },
  methods: {
    init() {
      let options;
      console.log(this.type);
      options = {
        type:
          this.type === "netUrl"
            ? "LoadModelFormURL"
            : "LoadModelFormLocalFile",
        value: JSON.stringify(this.modelInfo),
      };
      console.log(this.modelInfo);
      let token = localStorage.getItem("JTWorkflow-Token");
      this.webGLLoaded = true;
      let tokenOptions = {
        type: "GiveToken",
        value: token,
      };
      let url = {
        type: "GiveUrl",
        value: process.env.VUE_APP_WORKFLOW_API,
      };
      let fileNameOptions = {
        type: "GiveName",
        value: uuid.v4() + ".png",
      };
      this.CallUnity(tokenOptions);
      this.CallUnity(url);
      this.CallUnity(fileNameOptions);
      this.CallUnity(options);
    },
    dataInit() {
      this.type = null;
      this.modelInfo = {};
      this.showLoader = false;
      this.showTask = false;
      this.iframWidth = 0;
      this.iframHeight = 0;
      this.testBase64 = null;
    },
    closeModelView() {
      this.showLoader = false;
    },
    generateThumbnail() {
      let options = {
        type: "ScreenPrint",
        value: "false",
      };
      console.log(this.type);
      if (this.type === "netUrl") {
        this.showLoader = false;
        return;
      }
      this.CallUnity(options);
    },
    handleClose() {
      this.showLoader = false;
      this.dataInit();
      window.removeEventListener("message", this.receiveMessage, false);
    },
    opened() {
      this.isLoading = true;
      this.webGLLoaded = false;
      // window["getInstruction"] = (type, bool) => {
      //   console.log(type, bool);
      // };
      console.log(this.$refs.modelViewDialog.dialogRef);
      console.log(this.$refs.modelViewDialog.children);
      this.showLoader = true;
      this.showIframe = true;
      window.addEventListener("message", this.receiveMessage, false);
      // :src="`../../../../modelLoader/index.html`"
      this.$nextTick(() => {
        let dom = document.getElementById("modelView");
        console.log(dom);
        dom.src = this.iframeSrc;
      });

      // dom.src = "../../../../modelLoader/index.html";
      // this.$nextTick(() => {
      //   let dialogWarp = document.getElementsByClassName("modelViewDialog")[0];
      //   console.log(dialogWarp);
      //   let dialogBody = dialogWarp.getElementsByClassName("el-dialog__body");
      //   let iframeBody = document.getElementById("modelView");
      //   iframeBody.style.width = dialogBody[0].offsetWidth + "px";
      //   iframeBody.style.height =
      //     (dialogBody[0].offsetWidth * 9) / 16 + 50 + "px";
      //   window.addEventListener("resize", () => {
      //     iframeBody.style.width = dialogBody[0].offsetWidth + "px";
      //     iframeBody.style.height = (dialogBody[0].offsetWidth * 9) / 16 + "px";
      //   });
      // });
    },
    setShow(bool, type, modelInfo) {
      this.showLoader = bool;
      this.modelInfo = modelInfo;
      this.type = type;
    },
    CallUnity(data) {
      this.$refs.modelLoader.contentWindow.send(data);
    },
    receiveMessage(event) {
      console.log(event.data);
      console.log(event);
      console.log(this.type);
      // if (!this.showLoader) {
      //   return;
      // }
      // if (!event.data.type) {
      //   this.testBase64 = event.data.img;
      //   console.log("unity return image", event.data);
      //   let base64Data = event.data.img.replace(/^data:image\/\w+;base64,/, "");
      //   let dataBuffer = Buffer.from(base64Data, "base64");
      //   console.log("buffer base64", dataBuffer);
      //   this.$emit("getThumbnail", event.data.img);
      //   this.showLoader = false;
      // }

      //unity告诉web已经加载完成
      if (event.data.type === "LoadDone") {
        this.init();
        let options = {
          type: "GiveLanguageType",
          value: "ZH_CN",
        };
        // ZH_CN, //中文简体      0
        // EN_US, //英语（美国）  1
        // KO_KR, //韩语          2
        // JA_JP; //日语          3
        let lang = LangManager.getLanguage().value;
        if (lang === "zh") {
          options.value = "0";
        } else if (lang === "kr") {
          options.value = "2";
        } else if (lang === "en") {
          options.value = "1";
        } else {
          options.value = "0";
        }
        this.CallUnity(options);
      }
      if (event.data.type === "GetScreenPrint") {
        console.log(event.data);
      }
      if (event.data.type === "GiveFilePath") {
        console.log(event.data);
        let data = event.data;
        if (!data.value.path) {
          data.value = JSON.parse(event.data.value)[0];
        }
        this.showLoader = false;
        this.$emit("getThumbnail", data.value);
      }
      if (event.data.type === "ModelLoadDone") {
        console.log(event.data);
        this.isLoading = false;
      }
      // if (event.data.type === "LoadDone") {
      //   console.log("发送");
      //   let options = {
      //     type: "LoadModelFormURL",
      //     value: this.modelInfo.fileUrl,
      //   };
      //   this.CallUnity(options);
      // }
    },
  },
};
</script>

<style scoped lang="scss">
.webGLWarp {
  position: relative;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  .taskLinkWapr {
    position: absolute;
    width: 30%;
    height: 80%;
    background: #fff;
    top: 8%;
    left: 1%;
    overflow: hidden;
    z-index: 10;
    opacity: 0;
  }
  .taskActive {
    z-index: 20;
    opacity: 1;
  }
  .betweenWapr {
    position: absolute;
    width: 100%;
    height: 50vh;
    overflow: hidden;
    z-index: 15;
    iframe {
      width: 100%;
      // height: calc(100vh - 8rem);
      height: 100.2%;
    }
  }
}

// iframe::-webkit-scrollbar {
//   width: 5px;
//   height: 5px;
// }
// iframe::-webkit-scrollbar-thumb {
//   background-color: #999;
//   -webkit-border-radius: 5px;
//   border-radius: 5px;
// }
// iframe::-webkit-scrollbar-thumb:vertical:hover {
//   background-color: #666;
// }
// iframe::-webkit-scrollbar-thumb:vertical:active {
//   background-color: #333;
// }
// iframe::-webkit-scrollbar-button {
//   display: none;
// }
// iframe::-webkit-scrollbar-track {
//   background-color: #f1f1f1;
// }
#modelView {
  width: 100%;
}
#modelViewWarp {
  :deep(.el-dialog__header) {
    padding: 20px 30px 10px;
    text-align: left;

    .el-dialog__title {
      color: #4582ff;
    }
  }
  :deep(.el-dialog__body) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
  }
  :deep(.dialog-footer) {
    .selfBtn {
      outline: none;
      border: none;
      width: 120px;
      height: 32px;
      background: #4582ff;
      border-radius: 4px 4px 4px 4px;
      color: #fff;
      margin-left: 20px;
      cursor: pointer;
      transition: background 0.1s;
    }
    .selfBtn:hover {
      background: #8fb5ff;
    }
    .selfBtn[disabled] {
      background: #8fb5ff;
    }
    // .cancel {
    // }
  }
  .modelViewDialog {
    width: 1200px!important;
    position: relative;
  }
}
.importWarp {
  width: 100%;
  height: 100%;
  background: #fff;
}
.zIndex16 {
  z-index: 16;
}
.modelLoading {
  position: absolute;
  width: 1200px;
  height: 675px;
  border-radius: 15px;
  background: #fff;
}
.modelLiodingHidden {
  z-index: -1;
}
.ctrlTipWarp {
  width: 150px;
  height: 260px;
  .tipItem {
    width: 100%;
    height: 30%;
    margin-bottom: 9%;
    background: #fafafa;
    border-radius: 4px;
    font-size: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      width: 90%;
      > p {
        margin: 0;
      }
    }
  }
}
</style>
