<template>
  <div id="pdfViewWarp">
    <!-- PDF预览 -->
    <el-dialog
      v-if="showSwitch"
      :title="$t('pdfView.pdfView')"
      v-model="showSwitch"
      width="50%"
      :before-close="handleClose"
      :destroy-on-close="true"
      top="3vh"
    >
      <p class="arrow">
        <button
          @click="changePdfPage(0)"
          class="turn"
          :class="{ grey: currentPage == 1 }"
        >
          <!-- 上一页 -->
          {{ $t("pdfView.prevPage") }}
        </button>
        {{ currentPage }} / {{ pageCount }}
        <button
          @click="changePdfPage(1)"
          class="turn"
          :class="{ grey: currentPage == pageCount }"
        >
          <!-- 下一页 -->
          {{ $t("pdfView.nextPage") }}
        </button>
      </p>
      <div class="pdfWarp">
        <Pdf
          :src="src"
          :page="currentPage"
          @num-pages="pageCount = $event"
        ></Pdf>
      </div>

      <!-- 31321 -->
      <template #footer>
        <span class="dialog-footer">
          <!-- 取 消 -->
          <el-button @click="showSwitch = false">{{
            $t("pdfView.cancel")
          }}</el-button>
          <!-- 关闭 -->
          <el-button type="primary" @click="showSwitch = false">{{
            $t("pdfView.close")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Pdf from "vue3-pdf";
export default {
  components: { Pdf },
  props: {},
  data() {
    return {
      showSwitch: false,
      currentPage: 1, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      src: "", // pdf文件地址
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    setSwitch(bool, src) {
      this.showSwitch = bool;
      this.src = Pdf.createLoadingTask(src);
      this.src.promise.then((pdf) => {
        this.pageCount = pdf.numPages;
      });
      console.log(this.src.promise.then());
      console.log(this.numPages);
    },
    handleClose() {
      console.log("handleClose");
      this.showSwitch = false;
    },
    changePdfPage(val) {
      // console.log(val)
      if (val === 0 && this.currentPage > 1) {
        this.currentPage--;
        // console.log(this.currentPage)
      }
      if (val === 1 && this.currentPage < this.pageCount) {
        this.currentPage++;
        // console.log(this.currentPage)
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.arrow {
  font-size: 1.2rem;
  .turn {
    background: rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;
  }
  .turn:hover {
    color: #4582ff;
  }
}
#pdfViewWarp {
  :deep(.el-dialog) {
    border-radius: 12px;
    .el-dialog__header {
      padding: 20px 30px 10px;
      text-align: left;
      .el-dialog__title {
        color: #4582ff;
        text-align: left;
      }
    }
    .el-dialog__body {
      padding: 20px 30px 10px;
    }
    .el-dialog__footer {
      padding: 10px 30px 20px;
      .dialog-footer {
        button {
          width: 120px;
          height: 32px;
          border-radius: 4px;
          transition: background 0.1s;
          border: none;
          padding: 9px 15px;
          min-height: auto;
        }
        button:nth-child(1) {
          background: #a8b7ca;
          color: #ffffff;
          display: none;
        }
        button:nth-child(1):hover {
          background: #b8c2d2;
        }
        button:nth-child(2) {
          background: #4582ff;
          color: #ffffff;
        }
        button:nth-child(2):hover {
          background: #8fb5ff;
        }
      }
    }
  }
}
</style>
