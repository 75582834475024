<template>
  <div class="defaultTemplateWrap" id="tempWrap">
    <div
      class="templatesWrap"
      id="templatesWrap1"
      :style="{ width: maxWidth + 'px', height: maxHeight + 'px' }"
    >
      <div class="templatesBody">
        <!-- <PrevNext
          :showPrev="showPrev"
          :showNext="showNext"
          :stepName="stepName"
          :relationship="relationship"
        /> -->
        <MediaText
          ref="mediaText"
          :projectGuid="projectGuid"
          :isCreator="isCreator"
          :taskGuid="taskGuid"
          :shapeItem="shapeItem"
          :projectInfo="projectInfo"
          @needSave="needSave"
          @initDone="initDone"
          v-if="templateInfo && templateInfo.type === 3 && show"
          @sourceLoaded="sourceLoaded"
        />
        <Media
          ref="media"
          :projectGuid="projectGuid"
          :taskGuid="taskGuid"
          :isCreator="isCreator"
          :shapeItem="shapeItem"
          @needSave="needSave"
          @saveTemplateInfo="saveTemplateInfo"
          @initDone="initDone"
          v-if="templateInfo && templateInfo.type === 0 && show"
          @sourceLoaded="sourceLoaded"
        />
        <MediaMedia
          ref="mediaMedia"
          :projectGuid="projectGuid"
          :taskGuid="taskGuid"
          :isCreator="isCreator"
          :shapeItem="shapeItem"
          @needSave="needSave"
          @initDone="initDone"
          v-if="templateInfo && templateInfo.type === 2 && show"
          @sourceLoaded="sourceLoaded"
        />
        <Text
          ref="text"
          :projectGuid="projectGuid"
          :taskGuid="taskGuid"
          :isCreator="isCreator"
          :shapeItem="shapeItem"
          :projectInfo="projectInfo"
          @needSave="needSave"
          @initDone="initDone"
          v-if="templateInfo && templateInfo.type === 1 && show"
          @sourceLoaded="sourceLoaded"
        />
        <TextMediaMedia
          ref="textMediaMedia"
          :projectGuid="projectGuid"
          :taskGuid="taskGuid"
          :isCreator="isCreator"
          :shapeItem="shapeItem"
          :projectInfo="projectInfo"
          @needSave="needSave"
          @initDone="initDone"
          v-if="templateInfo && templateInfo.type === 4 && show"
          @sourceLoaded="sourceLoaded"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getStepInfo } from "@/api/workflow.js";
import MediaText from "./defaultTemplates/mediaText.vue";
import Media from "./defaultTemplates/media.vue";
import MediaMedia from "./defaultTemplates/mediaMedia.vue";
import Text from "./defaultTemplates/text.vue";
import TextMediaMedia from "./defaultTemplates/textMediaMedia.vue";
import LogicflowChart from "@/views/taskDetail/components/logicflowChart/logicflowChart.vue";
import PrevNext from "./defaultTemplates/prevNext.vue";
import { nextTick } from "vue";
// type
// 0 单多媒体
// 1 单文本
// 2 多媒体+多媒体
// 3 多媒体+文本
// 4 多媒体+多媒体+文本
// 5 输入模板
export default {
  components: {
    MediaText,
    Media,
    MediaMedia,
    Text,
    TextMediaMedia,
    LogicflowChart,
    PrevNext,
  },
  props: [
    "isCreator",
    "projectGuid",
    "templateInfo",
    "taskGuid",
    "shapeItem",
    "projectInfo",
  ],
  data() {
    return {
      show: true,
      maxWidth: 1280,
      maxHeight: 720,
      showPrev: false,
      showNext: false,
      relationship: {
        prev: [],
        next: [],
      },
      // stepItem: null,
    };
  },
  computed: {
    stepName() {
      return this.shapeItem?.data?.properties?.stepInfo?.stepName;
    },
  },
  created() {},
  mounted() {
    this.resize();
    // let dom = document.getElementById("defaultTemplateWrap");
    window.addEventListener("resize", this.resize);
  },
  unmounted() {
    window.removeEventListener("resize", this.resize);
  },
  watch: {
    // taskGuid(newVal, oldVal) {
    //   if (newVal != oldVal) {
    //     console.log("change");
    //     this.relationship = this.$refs.logicflow2d.apiGetEdgeByNodeId(
    //       selectNodeGuid
    //     );
    //   }
    // },
    // stepItem: {
    //   handler(newVal, oldVal) {
    //     if (newVal != oldVal) {

    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    relationship: {
      handler(newVal, oldVal) {
        if (newVal.prev.length <= 1) {
          this.showPrev = false;
        }
        if (newVal.next.length <= 1) {
          this.showNext = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async goItemByEdgeInfo(item) {
      console.log(LogicflowChart);
      let targetNode = item.targetNodeId;
      // this.loadStepInfo(targetNode);
      let res = await getStepInfo(targetNode);
      this.initData(res.data);
    },
    // 上一步切换
    async goItemByNodeInfo(item) {
      // this.loadStepInfo(item.id);
      console.log(LogicflowChart);
      let res = await getStepInfo(targetNode);
      this.initData(res.data);
    },
    //上一步
    prevStep() {
      console.log(LogicflowChart);
      if (
        // this.selectStep.type !== "judge" &&
        // this.selectStep.type !== "CloneJudge"
        this.relationship.prev.length === 1
      ) {
        //线性步骤 不存在判断步骤 不存在分叉路经
        console.log(this.relationship.prev[0]);
        let prevNodeID = this.relationship.prev[0].id;

        console.log(prevNodeID);
        // this.loadStepInfo(prevNodeID);
        this.initData(prevNodeID);
      } else {
        //非线性步骤 存在多个"上一步"
        this.showPrev = !this.showPrev;
      }
    },
    //下一步
    nextStep() {
      console.log(LogicflowChart);
      if (
        // this.selectStep.type !== "judge" &&
        // this.selectStep.type !== "CloneJudge"
        this.relationship.next.length === 1
      ) {
        //线性步骤 不存在判断步骤 不存在分叉路经
        // console.log(this.relationship.prev[0]);
        let nextNodeID = this.relationship.next[0].targetNodeId;
        let selectNode = LogicflowChart.methods.apiGetNodeById(nextNodeID);
        console.log(selectNode);
        if (selectNode.type === "pointer") {
          //下一步是任务步骤
          this.$router.replace({
            path: "/prevTemplate2D",
            query: {
              ...this.$route.query,
              taskGuid: selectNode?.properties?.stepInfo?.refTaskGuid,
            },
          });
          removeSelectedGuid();
          this.selectTaskGuid = selectNode?.properties?.stepInfo?.refTaskGuid;

          // this.onLogicFlowLoaded();
        } else {
          // this.loadStepInfo(nextNodeID);
          this.initData(nextNodeID);
        }
        console.log(nextNodeID);
      } else {
        //非线性步骤 存在判断步骤 存在分叉路经
        this.showNext = !this.showNext;
      }
    },
    sourceLoaded() {
      this.$emit("sourceLoaded");
    },
    initDone() {
      this.$emit("initDone");
    },
    initData(stepInfo, stepItem, relationship) {
      if (!stepInfo) {
        this.show = false;
        this.$nextTick(() => {
          //页面渲染完毕 重新渲染组件 重绘缩略图
          this.show = true;
          this.$nextTick(() => {
            //页面渲染完毕再让父组件重绘，确保重绘的图不是空白
            this.$emit("initDone");
          });
        });
        return;
      }
      // this.tempData = stepInfo;
      // this.stepItem = stepItem;
      this.relationship = relationship;
      console.log(this.relationship);
      // this.relationship = this.$refs.logicflow2d.apiGetEdgeByNodeId(
      //   stepItem.data.id
      // );
      console.log(stepInfo);
      console.log(this.templateInfo);
      console.log(this.templateInfo.refName);
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
        this.$nextTick(() => {
          this.$refs[this.templateInfo.refName].initData(stepInfo);
        });
      });
    },
    resize() {
      let dom1 = document.getElementById("tempWrap");
      //用外边框的宽计算高
      //比例
      let proportion = 0.5625;
      let shouldHeight = dom1.offsetWidth * proportion;
      if (shouldHeight > dom1.offsetHeight) {
        this.maxHeight = dom1.offsetHeight;
        this.maxWidth = dom1.offsetHeight / proportion;
      } else {
        this.maxWidth = dom1.offsetWidth;
        this.maxHeight = dom1.offsetWidth * proportion;
      }
    },
    async saveTemplateInfo() {
      await this.$refs[this.templateInfo.refName].saveTemplateInfo();
      this.needSave(false);
    },
    needSave(data) {
      this.$emit("needSave", data);
    },
  },
};
</script>

<style scoped lang="scss">
.defaultTemplateWrap {
  width: 98%;
  height: 98%;
  box-sizing: border-box;
  // background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  .templatesWrap {
    width: 100%;
    position: relative;
    background: #fff;
    .templatesBody {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      .stepTitle {
        height: 6%;
        width: 100%;
        background: #1c3466;
        display: flex;
        justify-content: center;
        align-items: center;
        .centerWrap {
          width: 16rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 0.95rem;
          > button {
            border-radius: 5px;
            cursor: pointer;
            width: 2.5rem;
            height: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            border: none;
            border: 1px solid #1c3466;
            outline: none;
            background: #1c3466;
            color: #fff;
            transition: all 0.3s;
            > img {
              width: 2rem;
              margin: 0;
              // height: 100%;
            }
          }
          > button:hover {
            background: #607194;
            border: 1px solid #80a9ff;
          }
          > span {
            margin: 0 1rem;
            color: #fff;
          }
          > .active {
            background: #607194;
            border: 1px solid #80a9ff;
          }
        }
      }
    }
  }
}

.itemWrap {
  .item {
    .itemBtn {
      padding: 0.4rem 1.5rem;
      border: none;
      outline: none;
      background: #e5edfc;
      font-size: 1rem;
      text-align: center;
      width: 100%;
      cursor: pointer;
      margin: 0.2rem 0;
      border-radius: 5px;
      color: #333;
      // transition: all 0.3s;
    }
    .itemBtn:hover {
      background: #dce4f2;
    }
  }
}
</style>
