<template>
  <div class="shareWarp">
    <el-dialog :title="$t('taskNewDatail.share')" v-model="show" width="500px">
      <!-- 获得链接的用户均可访问此项目 -->
      <p v-if="show" class="tip">{{ $t("share.word1") }}</p>
      <a class="link">{{ shareUrl }}</a>
      <template #footer>
        <span class="dialog-footer" v-if="show">
          <!-- 复制链接 -->
          <el-button type="primary" v-if="show" @click="copyLink">{{
            $t("share.word2")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { addForwardCount } from "@/api/mine";
export default {
  props: ["projectInfo"],
  data() {
    return {
      show: false,
      shareUrl: null,
    };
  },
  computed: {},
  created() {
    this.shareUrl = window.location.origin + this.$route.fullPath;
  },
  mounted() {},
  watch: {},
  methods: {
    async copyLink() {
      try {
        navigator.clipboard.writeText(this.shareUrl).then(
          async () => {
            // alert("已复制到剪切板");
            await addForwardCount(this.$route.query.projectGuid);
            this.show = false;
          },
          async () => {
            // alert("复制错误");
            this.show = false;
          }
        );
      } catch {
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // 使text area不在viewport，同时设置不可见
        textArea.style.position = "absolute";
        textArea.style.opacity = 0;
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(this.shareUrl);
        textArea.focus();
        textArea.select();
        return new Promise(async (res, rej) => {
          // 执行复制命令并移除文本框
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
          await addForwardCount(this.$route.query.projectGuid);
          //   alert("已复制到剪切板");
          this.show = false;
        });
      }
    },
    init(bool) {
      this.show = bool;
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
.shareWarp {
  :deep(.el-dialog) {
    border-radius: 12px;
    .el-dialog__header {
      padding: 20px 30px 10px;
      text-align: left;
      .el-dialog__title {
        color: #4582ff;
        text-align: left;
      }
    }
    .el-dialog__body {
      padding: 0px 30px 20px;
      .tip {
        color: #a2a2a2;
        font-size: 12px;
        line-height: 12px;
        text-align: left;
      }
      .link {
        width: 440px;
        background: #f6f7fa;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        font-size: 14px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 400;
        color: #7b93ad;
        line-height: 20px;
        padding: 18px 20px;
        display: block;
        box-sizing: border-box;
      }
    }
    .el-dialog__footer {
      .dialog-footer {
        button {
          width: 120px;
          height: 32px;
          border-radius: 4px;
          transition: background 0.1s;
          border: none;
          padding: 9px 15px;
          min-height: auto;
        }
        button:nth-child(1) {
          background: #4582ff;
          color: #ffffff;
        }
        button:nth-child(1):hover {
          background: #8fb5ff;
        }
      }
    }
  }
}
</style>
