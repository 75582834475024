<template>
  <div class="pageWrap">
    <div class="accountHeader">
      <Header ref="header" />
    </div>
    <div class="bodyWrap"></div>
    <Footer v-if="language !== 'en'" />
  </div>
</template>

<script>
import Header from "@/views/components/header/header.vue";
import Footer from "@/views/components/footer/footer.vue";
export default {
  components: { Header, Footer },
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
};
</script>

<style scoped lang="scss">
.pageWrap {
  width: 100%;
  height: 100%;
  > .bodyWrap {
    width: 100%;
    height: calc(100% - 104px - 36px);
    background: #fff;
  }
}
</style>
