import { createStore } from "vuex";
import getters from './getters';
// import User from "./modules/user"
// import TaskDetail from "./modules/taskDetail";
// import LongPolling from "./modules/longPolling";

const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  
  const fileName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const moduleName = fileName.charAt(0).toUpperCase() + fileName.slice(1);
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

const store = createStore({modules, getters})

console.log('moudles', modules)
export default store

// export default createStore({
//   modules: {
//     User,
//     TaskDetail,
//     LongPolling
//   },
// });
