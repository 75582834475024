<template>
  <div class="media">
    <div class="mediaBody">
      <!-- <img v-if="imgUrl" :src="imgUrl" alt="" /> -->
      <div class="imgWrap" v-if="fileType === 1">
        <img crossorigin="anonymous" :src="imgUrl" v-if="imgUrl" alt="" />
      </div>

      <div class="videoWrap" v-if="fileType === 2">
        <video
          crossorigin="anonymous"
          :id="videoRef"
          controls
          :src="imgUrl"
        ></video>
      </div>

      <div class="videoWrap" v-if="fileType === 3">
        <div class="imgWrap">
          <div class="img">
            <img
              class="pdfdefault"
              @click="preViewPdf"
              src="@/assets/project/pdfDefault.png"
              alt=""
            />
          </div>
          <p>{{ fileName }}</p>
        </div>
      </div>
      <PdfView ref="pdfView" />
      <!-- 暂未上传 -->
      <span v-if="!imgUrl">{{ $t("prevTemplate2D.media.noUpload") }}</span>
    </div>
  </div>
</template>

<script>
import { getAssetById } from "@/api/workflow";
import { getFileTypeByFileName } from "@/utils/getFileType.js";
import PdfView from "@/views/components/pdfView/pdfView.vue";
export default {
  props: ["data"],
  data() {
    return {
      imgUrl: null,
      fileType: 65535,
      fileName: null,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {
    data: {
      async handler(newVal, oldVal) {
        console.log(newVal);
        if (this.data) {
          //   let data = JSON.parse(this.data);
          let data = this.data;
          let targetMedia = data.medias[0];
          //   let res = await getAssetById(targetMedia.assetGuid);
          this.imgUrl = targetMedia.fileUrl;
          this.fileType = getFileTypeByFileName(targetMedia.assetName);
          if (this.fileType === 3) {
            this.fileName = targetMedia.assetName;
          }
        } else {
          this.imgUrl = null;
          this.fileType = 65535;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    preViewPdf() {
      this.$refs.pdfView.setSwitch(true, this.imgUrl);
    },
  },
  components: { PdfView },
};
</script>

<style scoped lang="scss">
.media {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  .mediaBody {
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    .imgWrap {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      background: #e9eef3;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .videoWrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      > video {
        width: 100%;
        height: 100%;
        object-fit: contain;
        background: #000;
      }
      .imgWrap {
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        background: #e9eef3;
        > .img {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          > img {
            width: 20rem;
          }
          > .pdfdefault {
            cursor: pointer;
          }
        }
        > p {
          text-align: center;
          color: #ccc;
          font-size: 1.5rem;
          position: absolute;
          width: 100%;
          text-align: center;
          left: 0;
          bottom: 1rem;
          right: 0;
        }
      }
    }
    > span {
      font-size: 34px;
      color: #ccc;
      font-weight: bold;
    }
    // > img {
    //   width: 100%;
    //   height: 100%;
    //   object-fit: contain;
    // }
  }
}
</style>
