<template>
  <div class="stepTitle">
    <div class="centerWrap">
      <el-tooltip
        :manual="true"
        class="item"
        effect="light"
        :disabled="relationship.prev.length <= 1"
        v-model="showPrev"
        placement="left"
      >
        <template #content>
          <div class="itemWrap">
            <div
              class="item"
              v-for="(item, index) in relationship.prev"
              :key="index"
            >
              <button @click="goItemByNodeInfo(item)" class="itemBtn">
                {{ item?.text?.value }}
              </button>
            </div>
          </div>
        </template>
        <button
          @click="prevStep"
          :disabled="relationship.prev.length <= 0"
          class="front"
        >
          <img src="@/assets/task-detail/front.png" alt="" />
        </button>
      </el-tooltip>

      <span>{{ stepName }}</span>
      <el-tooltip
        :manual="true"
        v-model="showNext"
        class="item"
        effect="light"
        :disabled="relationship.next.length <= 1"
        placement="right"
      >
        <template #content>
          <div class="itemWrap">
            <div
              class="item"
              v-for="(item, index) in relationship.next"
              :key="index"
            >
              <button @click="goItemByEdgeInfo(item)" class="itemBtn">
                {{ item?.text?.value }}
              </button>
            </div>
          </div>
        </template>
        <button
          @click="nextStep"
          :disabled="relationship.next.length <= 0"
          class="nextStep"
        >
          <img src="@/assets/task-detail/next.png" alt="" />
        </button>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: ["showPrev", "showNext", "relationship", "stepName"],
  data() {
    return {
      //   showPrev: false,
      //   showNext: false,
      //   relationship: {
      //     prev: [],
      //     next: [],
      //   },
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss">
.stepTitle {
  height: 6%;
  width: 100%;
  background: #1c3466;
  display: flex;
  justify-content: center;
  align-items: center;
  .centerWrap {
    width: 16rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.95rem;
    > button {
      border-radius: 5px;
      cursor: pointer;
      width: 2.5rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      border: none;
      border: 1px solid #1c3466;
      outline: none;
      background: #1c3466;
      color: #fff;
      transition: all 0.3s;
      > img {
        width: 2rem;
        margin: 0;
        // height: 100%;
      }
    }
    > button:hover {
      background: #607194;
      border: 1px solid #80a9ff;
    }
    > button[disabled] {
      display: none;
      cursor: not-allowed;
      > img {
        opacity: 0.3;
      }
    }
    > span {
      margin: 0 1rem;
      color: #fff;
    }
    > .active {
      background: #607194;
      border: 1px solid #80a9ff;
    }
  }
}
</style>
