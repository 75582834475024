<template>
  <transition name="content">
    <div class="tableFixHeader" v-if="isShow">
      <div class="tableListHeader" v-if="type === 'viewer'">
        <div class="dataItem">
          <!-- 项目名称 -->
          <div class="proNameOutSide">{{ $t("dataCharts.word7") }}</div>
          <!-- 操作次数 -->
          <div class="datas">{{ $t("dataCharts.word8") }}</div>
          <!-- 平均每次花费时长 -->
          <div class="datas lang">{{ $t("dataCharts.word9") }}</div>
          <!-- 最后一次操作时长 -->
          <div class="datas lang">{{ $t("dataCharts.word10") }}</div>
        </div>
      </div>
      <div class="dataListHeader" v-if="type === 'create'">
        <div class="datas">
          <!-- 项目名称 -->
          <div class="proName">{{$t("createView.projectName")}}</div>
          <div class="endWrap">
            <!-- <div class="time">版本创建日期</div> -->
            <!-- 使用者数量 -->
            <div class="dataItem">{{$t("createView.userCount")}}</div>
            <!-- 使用次数 -->
            <div class="dataItem">{{$t("createView.word1")}}</div>
            <!-- 完成率 -->
            <div class="dataItem">{{$t("createView.word2")}}</div>
            <!-- 总操作时长(H) -->
            <div class="dataItem lang">{{$t("createView.word3")}}</div>
            <!-- 平均操作时长(H) -->
            <div class="dataItem lang">{{$t("createView.word4")}}</div>
            <!-- 转发数 -->
            <div class="dataItem">{{$t("createView.word5")}}</div>
            <!-- 收藏数 -->
            <div class="dataItem">{{$t("createView.word6")}}</div>
            <!-- 点赞数 -->
            <div class="dataItem">{{$t("createView.word7")}}</div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["type"],
  data() {
    return {
      targetTop: null,
      //   type: null,
      isShow: false,
    };
  },
  computed: {},
  created() {},
  mounted() {
    // window.addEventListener("scroll", this.scrolling);
    // let mainBody = document.getElementById("scrollMainBody");
    // mainBody.onscroll = this.handleScroll;
    // this.targetTop = document.querySelector("#scrollMainBody").offsetTop;
    // console.log(this.targetTop);
  },
  watch: {},
  methods: {
    open(bool) {
      this.isShow = bool;
    },
    // setViewType(val) {
    //   this.type = val;
    // },
    // scrolling(e) {
    //   console.log(e);

    //   let scrollTop =
    //     document.documentElement.scrollTop || document.body.scrollTop;
    //   if (scrollTop > this.targetTop) {
    //     // this.ifFixed = true;
    //     console.log("滚起来");
    //   } else {
    //     // this.ifFixed = false;
    //     console.log("我又不滚了");
    //   }
    // },
  },
  components: {},
};
</script>

<style scoped lang='scss'>
.tableFixHeader {
  background: #f1f4fc;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
  .tableListHeader {
    width: 100%;
    background: #f1f4fc;
    box-shadow: 0px 2px 6px 0px #d9dfee;
    display: flex;
    justify-content: center;
    align-items: center;
    .dataItem {
      width: 80%;
      font-size: 16px;
      color: #274e78;
      font-weight: 500;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      padding: 0 3%;
      height: 48px;

      > div {
        text-align: right;
      }
      .proNameOutSide {
        width: 10%;
        text-align: left;
      }
      .datas {
        width: 25%;
      }
      .lang {
        width: 32%;
      }
    }
  }
  .dataListHeader {
    width: 100%;
    background: #f1f4fc;
    box-shadow: 0px 2px 6px 0px #d9dfee;
    display: flex;
    justify-content: center;
    align-items: center;
    .datas {
      width: 80%;
      font-size: 16px;
      color: #274e78;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      height: 48px;

      > div {
        font-size: 16px;
        color: #274e78;
        width: 10%;
        text-align: center;
      }
      .proName {
        width: 22%;
        text-align: left;
        box-sizing: border-box;
        padding-left: 4%;
      }

      .endWrap {
        width: 78%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .time {
          width: 17%;
        }
        .dataItem {
          width: 10.5%;
          text-align: right;
        }
        .lang {
          width: 16%;
        }
      }
    }
  }
}
.content-enter-active,
.content-leave-active {
  transition: all 0.5s;
}
.content-enter-from /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(-100%);
  opacity: 0;
}
.content-enter-to {
  transform: translateY(0%);
}
.content-leave-to {
  position: absolute;
  transform: translateY(-100%);
  opacity: 0;
}
</style>
