<template>
  <div>
    <!-- 预览视窗 -->
    <div class="title">{{ $t("prevViewThumbnail.word1") }}</div>
    <div
      v-if="shapeItem.data.type !== 'pointer'"
      v-loading="loading"
      class="imgWrap"
      @dblclick="setWorkingType"
    >
      <img :src="src" alt="" />
    </div>
    <div v-if="shapeItem.data.type === 'pointer'" class="gotoPointer">
      <!-- 双击进入任务步骤 -->
      <button @dblclick="gotoPointer">
        {{ $t("prevViewThumbnail.word2") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["workingType", "shapeItem"],
  data() {
    return {
      src: "",
      loading: false,
    };
  },
  computed: {},
  created() {
    console.log('thumbnail', this.shapeItem);
    console.log("this.shapeItem", this.shapeItem);
  },
  mounted() {
    this.$emit("thumbnailLoaddone");
  },
  watch: {},
  methods: {
    gotoPointer() {
      console.log("进入任务步骤");
      console.log(this.shapeItem);
      this.$emit("gotoPointer", this.shapeItem);
    },
    setWorkingTypeThumbnail(data) {
      // console.log(data);
      this.src = data;
      this.loading = false;
    },
    setWorkingType() {
      // console.log(this.shapeItem);
      if (this.workingType) {
        this.$emit("setWorkingType", 0);
        this.loading = true;
      } else {
        this.$emit("setWorkingType", 1);
        this.loading = true;
      }
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
.title {
  width: 100%;
  height: 2.5rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #e8ecf1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #224b77;
  font-size: 20px;
  padding: 0 1.5rem;
  box-sizing: border-box;
  padding: 5px;
  overflow: hidden;
}
.imgWrap {
  position: absolute;
  top: 2.5rem;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8fafb;
  padding: 5px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
}
.gotoPointer {
  position: absolute;
  top: 2.5rem;
  width: 100%;
  height: calc(100% - 2.5rem);
  display: flex;
  justify-content: center;
  align-items: center;
  > button {
    width: 100%;
    height: 100%;
    background: #ccc;
    box-sizing: border-box;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: #333;
  }
}
</style>
