/* eslint-disable */
import request from '@/utils/request'
import axios from 'axios'
// import defaultSettings from '@/settings'
import qs from 'qs'
import { getToken } from '@/utils/setLocalstorage'

export async function login(data) {
  const params = {
    username: data.username,
    password: data.password,
    grant_type: 'password',
    client_id: process.env.VUE_APP_APPGUID,
    authcode: data.authcode
  }
  try {
    const instance = axios.create({
      baseURL: process.env.VUE_APP_LOGIN_SERVER, // defaultSettings.loginServer,//'https://account.jingtengtech.com', // url = base url + request url
      // withCredentials: true, // send cookies when cross-domain requests
      timeout: 5000 // request timeout
    })
    console.log('params', data, params)
    const result = await instance({
      url: '/api/token',
      method: 'post',
      data: qs.stringify(params)
    })
    const token = result.data.accessToken
    result.data.token = token
    // console.log('token:', token);
    request.defaults.headers.common['Authorization'] = `Bearer ${token}`
    return result.data
  } catch (e) {
    // console.log('login err', e, e.response.data)
    return Promise.reject(e.response.data)
  }
}



// export function getUserInfo() {
//   return request({
//     url: `${process.env.VUE_APP_WORKFLOW_API}/api/getUserInfo`,
//     method: 'post',
//   })
// }

// export function getFeatures() {
//   return request.post(`${process.env.VUE_APP_RA_API}/api/getFeatures`, {})
// }

export function saveMyUser(displayName) {
  return request.post('/api/saveMyUser', { item: { displayName } })
}

export function logout() {
}

// export async function changePass(originPass, newPass) {
//   try {
//     const instance = axios.create({
//       baseURL: process.env.VUE_APP_AUTH_API, // defaultSettings.loginServer,
//       // withCredentials: true, // send cookies when cross-domain requests
//       timeout: 5000
//     })

//     const token = getToken()
//     if (token) {
//       instance.defaults.headers.common['Authorization'] = `Bearer ${token}`

//       await instance.post('/api/changePass', {
//         originPass, newPass
//       })

//       return true
//     } else {
//       throw 'error'
//     }
//   } catch (e) {
//     return Promise.reject(e)
//   }
// }
export async function changePass(originPass, newPass) {
  return request.post(`${process.env.VUE_APP_LOGIN_SERVER}/api/changePass`, { originPass, newPass });
}

export async function changePassWithTempToken(tempToken, originPass, newPass) {
  // try {
  // const instance = axios.create({
  //   baseURL: process.env.VUE_APP_AUTH_API, 
  //   timeout: 5000 // request timeout
  // })
  console.log('params', data, params)
  return await axios.request({
    url: `${process.env.VUE_APP_LOGIN_SERVER}/api/changePass`,
    method: 'post',
    data: { originPass, newPass },
    headers: { Authorization: `Bearer ${tempToken}` }
  })
  //   return result.data
  // } catch (e) {
  //   // console.log('login err', e, e.response.data)
  //   return Promise.reject(e)
  // }
}

export function sendTrialAuthCode(tel) {
  // console.log("sendTrialAuthCode", tel);
  return request.post(`${process.env.VUE_APP_LOGIN_SERVER}/api/trial/sendTrialAuthCode`, { tel });
}

export function createTrialUser(tel, realName, displayName, authCode, newPass, appType) {
  // console.log("createTrialUser", tel);
  return request.post(`${process.env.VUE_APP_LOGIN_SERVER}/api/trial/createTrialUser`, { tel, realName, displayName, authCode, newPass, appType });
}

export function sendAuthCode(tel, username) {
  // console.log("sendAuthCode", tel);
  return request.post(`${process.env.VUE_APP_LOGIN_SERVER}/api/sendAuthCode`, { tel, username });
}

export function resetPassByAuthcode(tel, username, authcode, newPass) {
  // console.log("resetPassByAuthcode", tel);
  return request.post(`${process.env.VUE_APP_LOGIN_SERVER}/api/resetPassByAuthcode`, { tel, username, authcode, newPass });
}


