<template>
  <div class="template2DWrap">
    <DefaultTemplate
      ref="defaultTemplate"
      :projectGuid="projectGuid"
      :isCreator="isCreator"
      @needSave="needSave"
      @initDone="initDone"
      :templateInfo="templateInfo"
      :taskGuid="taskGuid"
      :shapeItem="shapeItem"
      :projectInfo="projectInfo"
      v-if="showType"
      @sourceLoaded="sourceLoaded"
    />
    <InputTemplate
      @needSave="needSave"
      @initDone="initDone"
      @clearStepObject="clearStepObject"
      :taskGuid="taskGuid"
      :projectGuid="projectGuid"
      :shapeItem="shapeItem"
      ref="inputTemplate"
      v-else
    />
  </div>
</template>

<script>
import DefaultTemplate from "./template/defaultTemplate.vue";
import InputTemplate from "./template/inputTemplate.vue";
import { getSelectedGuid } from "@/views/taskDetail/components/taskChart/setSelectShape.js";
export default {
  components: { DefaultTemplate, InputTemplate },
  props: [
    "shapeItem",
    "isCreator",
    "projectGuid",
    "templateInfo",
    "taskGuid",
    "showType",
    "projectInfo",
  ],
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.$emit("template2DLoaddone");
    });
  },
  watch: {
    shapeItem(newVal, oldVal) {
      console.log(newVal);
    },
  },
  methods: {
    clearStepObject(options) {
      this.$emit("clearStepObject", options);
    },
    initData(stepInfo, stepItem, relationship) {
      console.log(stepInfo);
      if (this.showType) {
        console.log(this.showType);
        //默认模板
        console.log("获取默认模板数据");
        this.$refs.defaultTemplate.initData(stepInfo, stepItem, relationship);
      } else {
        //输入步骤模板
        console.log("获取输入模板数据");
        this.$refs.inputTemplate.initData(stepInfo, stepItem, relationship);
      }
    },
    sourceLoaded() {
      this.$emit("initDone");
    },
    initDone() {
      this.$emit("initDone");
    },
    resize() {
      if (this.showType) {
        this.$refs.defaultTemplate.resize();
      } else {
        this.$refs.inputTemplate.resize();
      }
    },
    async saveAll() {
      if (this.showType) {
        //默认模板
        console.log("保存默认模板");
        await this.$refs.defaultTemplate.saveTemplateInfo();
      } else {
        //输入步骤模板
        console.log("保存输入步骤模板");
        await this.$refs.inputTemplate.saveTemplateInfo();
      }
    },
    needSave(data) {
      console.log(data);
      this.$emit("needSave", data);
    },
  },
};
</script>

<style scoped lang="scss">
.template2DWrap {
  // background: #000;
  border-left: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("~@/assets/task-detail/shodowBg.png") no-repeat center center;
  background-size: 100% 100%;
}
</style>
