<template>
  <div v-if="model">
    <div class="printWarp">
      <div class="p_head">
        <div><img src="@/assets/print/printLogo.png" alt="" /></div>
        <div class="netUrl">jtworkflow.jingtengtech.com</div>
      </div>
      <div class="p_projectName">
        <div class="projectName">{{ model.projectName }}</div>
        <p class="projectDescription">{{ model.description }}</p>
      </div>
      <div class="QRcode_position">
        <div class="QR_title">
          <!-- <img src="@/assets/print/QRcodeTip.png" alt="" /> -->
          {{$t("printReview.word2")}}
        </div>
        <div class="QR_content">
          <div class="QR_img">
            <!-- <el-img :src="model.qrcodeDescMediaPathUrl" alt="" /> -->
            <el-image :src="model.qrcodeDescMediaPathUrl" fit="scale-down">
              <!-- <el-image :src="qrcodeSrc" fit="scale-down"> -->
              <template #error>
                <!-- 未上传参考图片 -->
                <div class="image-slot">{{ $t("printReview.word1") }}</div>
              </template>
            </el-image>
          </div>
          <div class="QR_description">{{ model.qrcodeDescription }}</div>
        </div>
      </div>
    </div>
    <div class="QR_warp">
      <div class="QRCodeHead">
        <img src="@/assets/print/print2.png" alt="" />
      </div>
      <div class="QRCodeWarp">
        <div class="QRCodeBorder">
          <!-- <el-image
            class="title"
            :src="qrcodeSrc"
            fit="contain"
          /> -->
          <img :src="qrcodeSrc" alt="" />
          <!-- <QRCode :text="model" :width="256" :height="256" :correctLevel="1" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import print from "vue3-print-nb";
import QRCode from "@/components/qrcode.vue";
export default {
  components: { QRCode },
  directives: {
    print,
  },
  props: ["projectInfo", "printType"],
  data() {
    return {
      model: null,
      qrcodeSrc: null,
    };
  },
  computed: {},
  async created() {
    console.log(this.qrcodeSrc);
  },
  mounted() {},
  watch: {
    projectInfo: {
      async handler(newVal, oldVal) {
        this.model = newVal;
        console.log(this.model);
        if (this.projectInfo && this.projectInfo.projectGuid) {
          if (!this.$route.query.releaseGuid) {
            this.qrcodeSrc = `${process.env.VUE_APP_WORKFLOW_API}/api/project/getProjectQRCode?projectGuid=${this.projectInfo.projectGuid}`;
          } else {
            this.qrcodeSrc = `${process.env.VUE_APP_WORKFLOW_API}/api/project/getProjectQRCode?projectGuid=${this.projectInfo.projectGuid}&releaseGuid=${this.projectInfo.releaseGuid}`;
          }
          // let blob = new Blob(
          //   [await getProjectQRCode(this.projectInfo.projectGuid)],
          //   { type: "image/jpeg" }
          // );
          // console.log(this.qrcodeSrc);
          // this.qrcodeSrc = URL.createObjectURL(blob);
          // let temp1 = URL.createObjectURL(blob).split(":");
          // temp1.shift();
          // this.qrcodeSrc = temp1.join(":");
          // console.log(this.qrcodeSrc);
          // this.qrcodeSrc = await getProjectQRCode(this.projectInfo.projectGuid);
        }
      },
      immediate: true,
      deep: true,
    },
    printType: {
      handler(newVal, oldVal) {
        if (newVal === 1) {
          if (this.projectInfo && this.projectInfo.projectGuid) {
            if (!this.$route.query.releaseGuid) {
              this.qrcodeSrc = `${process.env.VUE_APP_WORKFLOW_API}/api/project/getProjectQRCode?projectGuid=${this.projectInfo.projectGuid}`;
            } else {
              this.qrcodeSrc = `${process.env.VUE_APP_WORKFLOW_API}/api/project/getProjectQRCode?projectGuid=${this.projectInfo.projectGuid}&releaseGuid=${this.projectInfo.releaseGuid}`;
            }
          }
        } else if (newVal === 0) {
          this.qrcodeSrc = require("@/assets/print/Os.png");
        }
      },
      immediate: true,
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.printWarp {
  padding: 0rem 0rem 1.5rem 0rem;
  border-bottom: 2px dashed #ccc;
  .p_head {
    margin-bottom: 2rem;
    > div {
      text-align: center;
    }
    .netUrl {
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #212121;
      line-height: 20px;
    }
  }
  .p_projectName {
    > div {
      text-align: left;
    }
    .projectName {
      color: #212121;
      text-align: left;
      font-size: 24px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #212121;
      line-height: 39px;
      margin-bottom: 5px;
    }
    .projectDescription {
      margin: 0px;
      width: 100%;
      min-height: 60px;
      color: #3a3a3a;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #3a3a3a;
      line-height: 27px;
      word-wrap: break-word;
      word-break: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
      text-align: left;
    }
  }
  .QRcode_position {
    > div {
      text-align: left;
    }
    .QR_title {
      // width: 150px;
      // height: 28px;
      background: #fff;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      color: #333;
      font-size: 18px;
      text-align: center;
      line-height: 28px;
      padding: 5px;
      padding-left: 0;
      display: flex;
      justify-content: left;
      font-weight: bold;
    }
    .QR_content {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      .QR_img {
        width: 50%;
        height: 200px;
        position: relative;
        display: inline-block;
        overflow: hidden;
        > div {
          width: 100%;
          height: 100%;
          .image-slot {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        > img {
          width: 100%;
          object-fit: contain;
        }
      }
      .QR_description {
        width: 38%;
        font-size: 18px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #3a3a3a;
        line-height: 27px;
        word-wrap: break-word;
        word-break: normal;
        text-align: left;
        margin: 0;
      }
    }
  }
}
.QR_warp {
  padding: 1.5rem 0rem;
  .QRCodeHead {
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 12cm;
    }
  }
  .QRCodeWarp {
    display: flex;
    justify-content: center;

    .QRCodeBorder {
      width: 12cm;
      border: 2px solid #121212;
      // padding: 25px;
      box-sizing: border-box;
      padding: 1cm;
      > img {
        width: 10cm;
      }
    }
  }
}
@page {
  size: auto; /* auto is the initial value */
  margin: 0mm; /* this affects the margin in the printer settings */
}

html {
  background-color: #ffffff;
  margin: 0px; /* this affects the margin on the html before sending to printer */
}

body {
  border: solid 1px blue;
  margin: 0mm 15mm 0mm 15mm; /* margin you want for the content */
}
</style>
