<template>
  <div class="pageWrap">
    <div class="accountHeader">
      <Header ref="header" />
    </div>
    <div class="bodyWrap">
      <div class="body">
        <div class="bodyContent">
          <div class="header">
            <div class="headerContent">
              <div class="line"></div>
              <!-- 待审核项目 -->
              <span>{{ $t("auditProject.auditProject") }}</span>
            </div>
          </div>
          <div class="listWrap">
            <div class="bodyWrap1">
              <div class="listWrap1" v-if="dataList && dataList.length > 0">
                <div class="list">
                  <div
                    class="itemWrap"
                    v-for="(item, index) in dataList"
                    :key="item.projectGuid"
                  >
                    <div class="item">
                      <div class="detailInfo">
                        <div class="imgWrap" @click="auditProject(item, index)">
                          <img
                            v-if="item.coverImageUrl"
                            :src="item.coverImageUrl"
                            alt=""
                          />
                          <img
                            v-else
                            src="@/assets/home/defaultCover.png"
                            alt=""
                          />
                          <div>
                            <div v-if="item.editType == 0" class="threeD">
                              <img src="@/assets/home/threeD.png" alt="" />
                            </div>
                            <div v-if="item.editType == 1" class="twoD">
                              <img src="@/assets/home/twoD.png" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="projectInfoWrap">
                          <div class="info">
                            <!-- <div class="proName">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="item.projectName"
                          placement="top"
                        >
                          <span> {{ item.projectName }}</span>
                        </el-tooltip>
                      </div> -->
                            <div
                              class="proName"
                              @click="auditProject(item, index)"
                            >
                              {{ item.projectName }}
                            </div>
                          </div>
                          <div class="time">
                            <div>
                              <div>
                                <span>
                                  <!-- {{ getVersion(item) }} -->
                                  <!-- {{
                                    $t("myPublished.version") +
                                      formatDateTimeVue(item.createdAt)
                                  }} -->
                                  {{ $t("myPublished.version") + item.version }}
                                </span>
                              </div>
                              <div class="status">
                                <el-popover
                                  placement="bottom"
                                  :append-to-body="false"
                                  popper-class="myPopover"
                                  :width="200"
                                  trigger="click"
                                  :content="item.content"
                                >
                                  <template #reference>
                                    <button class="showReason">
                                      <!-- 查看原因 -->
                                      {{ $t("auditProject.showRemarks") }}
                                    </button>
                                  </template>
                                </el-popover>
                              </div>
                            </div>
                          </div>
                          <div class="nearlyTime"></div>
                          <div class="operation">
                            <span>
                              {{ formatDateTimeVue(item.updatedAt) }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="ctrl">
                        <button
                          v-if="item.auditStatus === 1"
                          class="cancel"
                          @click="auditHistory(item, index)"
                        >
                          <!-- 审核记录 -->
                          {{ $t("myPublished.auditHistory") }}
                        </button>
                        <button
                          v-if="item.auditStatus === 1"
                          class="primary"
                          @click="passAudit(item, index)"
                        >
                          <!-- 通过 -->
                          {{ $t("auditProject.pass") }}
                        </button>
                        <button
                          v-if="item.auditStatus === 1"
                          class="delete"
                          @click="reject(item, index)"
                        >
                          <!-- 拒绝 -->
                          {{ $t("auditProject.reject") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="enpty" v-else>
                <div>
                  <img src="@/assets/myTask/defaultEnpty.png" alt="" />
                  <!-- 当前无可浏览项目 -->
                  <div>{{ $t("myTask.word12") }}</div>
                </div>
              </div>
            </div>
            <div class="pages" v-if="dataList.length !== 0">
              <el-pagination
                background
                :page-size="size"
                layout="prev, pager, next ,jumper"
                :total="total"
                @current-change="changePageIndex"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AuditHistory ref="auditHistory" />
    <!-- <Footer v-if="language !== 'en'" /> -->
  </div>
</template>

<script>
import Header from "@/views/components/header/header.vue";
import Footer from "@/views/components/footer/footer.vue";
import { getAudittingList, rejectAudit, publishProject } from "@/api/mine.js";
import { formatDateTime } from "@/utils/time.js";
// import { ElMessage } from "element-plus";
import AuditHistory from "@/views/myTasks/components/auditHistory.vue";
export default {
  components: { Header, Footer, AuditHistory },
  props: {},
  data() {
    return {
      dataList: [],
      page: 1,
      size: 10,
      total: 0,
      selectType: 0, // 0//无 1//待审核 2//审核通过 3//审核拒绝
      tabList: [
        {
          // 全部流程
          label: this.$t("auditProject.allProcess"),
          searchType: 0,
          value: 0,
        },
        {
          // 审核中
          label: this.$t("auditProject.auditting"),
          searchType: 1,
          value: 0,
        },
        {
          // 未通过
          label: this.$t("auditProject.noPass"),
          searchType: 3,
          value: 0,
        },
        {
          // 已通过
          label: this.$t("auditProject.pass"),
          searchType: 2,
          value: 0,
        },
      ],
    };
  },
  computed: {},
  async created() {
    await this.getListByType(this.selectType, this.page);
  },
  mounted() {},
  watch: {},
  methods: {
    auditHistory(item, index) {
      this.$refs.auditHistory.open(true, item);
    },
    getVersion(item) {
      if (item.auditStatus) {
        return (
          this.$t("myPublished.version") +
          this.formatDateTimeVue(item.auditStatus.updatedAt)
        );
      } else if (item.releaseInfo) {
        return (
          this.$t("myPublished.version") +
          this.formatDateTimeVue(item.releaseInfo.createdAt)
        );
      } else {
        return "";
      }
    },
    //去审核项目
    auditProject(item, index) {
      this.$router.push({
        path: "/taskDetail",
        query: {
          projectGuid: item.projectGuid,
          auditReleaseGuid: item.auditReleaseGuid,
        },
      });
    },
    //审核通过
    async passAudit(item, index) {
      // console.log(item);
      // 确认通过此项目的审核？ 提示
      this.$confirm(
          this.$t("auditProject.tip6") +
            `<p class='messageWrap'>
              <span class='messageWord'>(${item.projectName})</span>
            </p>
            `,
          this.$t("auditProject.tip5"),
          {
            confirmButtonText: this.$t("auditProject.confirm"),
            cancelButtonText: this.$t("auditProject.cancel"),
            dangerouslyUseHTMLString: true,
          }
        ).then(async () => {
          try {
            let res = await publishProject(item.projectGuid, item.isPublic);
            // if (res.code === 200) {
              this.$message({
                showClose: true,
                // 审核结果已通过
                message: this.$t("auditProject.reviewResultCommitted"),
              });
            // } else {
              
            // }
            await this.getListByType(this.selectType, this.page);
          } catch (e) {
            console.log('publish project error', e)

            // ElMessage({
            //     showClose: true,
            //     // 审核结果提交失败
            //     message: this.$t("auditProject.tip2"),
            //     type: "error",
            //   });
            this.$message({
              showClose: true,
              // 审核结果提交失败
              message: this.$t("auditProject.reviewResultCommitFailed"),
              type: "error",
            });
            console.log(e);
            await this.getListByType(this.selectType, this.page);          
        }})
        .catch(() => {});
    },
    //审核被拒绝
    async reject(item, index) {
      console.log(item);
      // 拒绝
      this.$prompt("", this.$t("auditProject.reject"), {
          // 确定
          confirmButtonText: this.$t("auditProject.confirm"),
          // 取消
          cancelButtonText: this.$t("auditProject.cancel"),
          inputPattern: /^(?!\s*$).+/,
          showInput: true,
          // 填写违规原因
          inputPlaceholder: this.$t("auditProject.tip3"),
          inputType: "textarea",
          // 内容不能为空
          inputErrorMessage: this.$t("auditProject.errorTip"),
          customClass: "deleteBox",
      }).then(async ({value, action}) => {
      // console.log('result', result)
        try {
          await rejectAudit(item.auditReleaseGuid, value);
          // if (res.code === 200) {
          this.dataList.splice(index, 1);
          this.$message({
            showClose: true,
            // 审核结果已提交
            message: this.$t("auditProject.reviewResultCommitted"),
          });
          await this.getListByType(this.selectType, this.page);          

        } catch (e) {
          console.log('reject error', e)
          this.$message({
              showClose: true,
              // 审核结果提交失败
              message: this.$t("auditProject.reviewResultCommitFailed"),
              type: "error",
            });
          
        }
    }).catch(e => {})
      
    },
    formatDateTimeVue(time) {
      let res = formatDateTime(time);
      console.log(res);
      return res;
    },
    changePageIndex(pageIndex) {
      this.getListByType(this.selectType, pageIndex);
    },
    //根据被选中的tab获取列表
    async getListByType(selectType, page) {
      this.selectType = selectType;
      let res = await getAudittingList(
        page - 1,
        this.size,
        selectType ? selectType : null
      );
      console.log(res);
      this.page = res.page + 1;
      this.size = res.size;
      this.total = res.total;
      this.dataList = res.data ? res.data : [];
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/el-popover.scss";
.accountHeader {
  background: -webkit-linear-gradient(#ebf5fd, #cbe4fe);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(#ebf5fd, #cbe4fe);
  /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(#ebf5fd, #cbe4fe);
  /* Firefox 3.6 - 15 */
  background: linear-gradient(#ebf5fd, #cbe4fe);
  position: relative;
  overflow: hidden;
}
.pageWrap {
  width: 100%;
  height: 100%;
}
.bodyWrap {
  width: 100%;
  height: calc(100% - 104px - 36px);
  background: #fff;
  > .body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > .bodyContent {
      width: 100%;
      height: 100%;
      > .header {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        > .headerContent {
          width: 75%;
          height: 40px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 50px 0 14px 0;
          > .line {
            width: 4px;
            height: 28px;
            background: #4582ff;
            margin-right: 12px;
          }
          > span {
            font-size: 24px;
            color: #0d0c22;
            font-weight: bold;
          }
        }
      }
      > .listWrap {
        width: 100%;
        min-height: calc(100% - 40px - 64px);
        position: relative;
        > .bodyWrap1 {
          width: 100%;
          height: 100%;
          padding-bottom: 64px;
          box-sizing: border-box;
          background: #fff;
          > .listWrap1 {
            height: 100%;
            width: 100%;
            background: #fff;
            box-sizing: border-box;
            overflow: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            > .list {
              width: 75%;
              height: 100%;
              > .itemWrap {
                border-bottom: 1px solid #dde2e5;
                > .item {
                  width: 100%;
                  height: 156px;
                  padding: 24px 0;
                  box-sizing: border-box;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  > .detailInfo {
                    width: 70%;
                    height: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    > .imgWrap {
                      width: 192px;
                      height: 108px;
                      border-radius: 12px;
                      overflow: hidden;
                      margin-right: 24px;
                      position: relative;
                      cursor: pointer;
                      > img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                      }
                      > div {
                        width: 22%;
                        position: absolute;
                        bottom: 6%;
                        left: 4%;
                        // background: #fff;
                        border-radius: 14%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        > div {
                          width: 100%;
                          > img {
                            width: 100%;
                          }
                        }
                      }
                    }
                    > .projectInfoWrap {
                      cursor: default;
                      width: calc(100% - 192px - 24px);
                      height: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: flex-start;
                      > .info {
                        width: 100%;
                        > .proName {
                          text-align: left;
                          font-weight: bold;
                          color: #262626;
                          font-size: 18px;
                          white-space: nowrap; /* 禁止换行 */
                          overflow: hidden; /* 隐藏超出容器的部分 */
                          text-overflow: ellipsis; /* 显示省略号 */
                          line-height: 25px;
                          cursor: pointer;
                          &:hover {
                            text-decoration: underline 1px;
                          }
                        }
                        > .status {
                          text-align: left;
                          margin-top: 10px;
                          > button {
                            padding: 4px 10px;
                            border-radius: 4px;
                            font-size: 14px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: none;
                            outline: none;
                            > img {
                              width: 16px;
                              height: 16px;
                            }
                          }
                          > .auditing {
                            background: #e3ecff;
                            color: #4582ff;
                          }
                          > .reject {
                            background: #ffecec;
                            color: #f94a4a;
                          }
                        }
                      }
                      > .time {
                        color: #474747;
                        font-size: 14px;
                        height: 24px;
                        margin-top: 10px;
                        > div {
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          > div:nth-child(1) {
                            margin-right: 1rem;
                            font-weight: bold;
                          }
                          > .status {
                            text-align: left;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            > button {
                              margin-right: 8px;
                              padding: 4px 10px;
                              border-radius: 4px;
                              font-size: 14px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              border: none;
                              outline: none;
                              > img {
                                width: 16px;
                                height: 16px;
                              }
                            }
                            > .showReason {
                              background: #ebeaea;
                              color: #3b3b3b;
                              cursor: pointer;
                              &:hover {
                                text-decoration: 1px underline;
                              }
                            }
                          }
                        }
                      }
                      > .nearlyTime {
                        height: 18px;
                        margin-top: 10px;
                        > span {
                          color: #818181;
                        }
                      }
                      .operation {
                        font-size: 14px;
                        color: #888;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin-top: 10px;
                        :deep(.dropDown){
                          width: 2rem;
                          height: 1rem;
                          position: absolute;
                          right: 0;
                        }
                        > span {
                          // margin-right: 1.5vw;
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          // width: 2vw;
                          // height: 1vw;
                          padding-right: 16px;
                          > img {
                            width: 14px;
                            height: 14px;
                            margin-right: 6px;
                          }
                        }
                        .more {
                          margin: 0;
                          position: absolute;
                          right: 0;
                          display: flex;
                          justify-content: right;
                          align-items: center;
                          font-size: 14px;
                          cursor: pointer;
                          top: -26%;
                          > i {
                            transform: rotate(-90deg);
                          }
                        }
                      }
                    }
                  }
                  > .ctrl {
                    width: 280px;
                    height: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    > button {
                      min-width: 160px;
                      padding: 10px 10px;
                      font-size: 14px;
                      border: none;
                      border-radius: 4px;
                      cursor: pointer;
                      margin-left: 16px;
                    }
                    > .delete {
                      color: #f94a4a;
                      background: #ffecec;
                    }
                    > .primary {
                      background: #ecf2ff;
                      color: #274e78;
                    }
                    > .cancel {
                      color: #49545f;
                      border: 1px solid #989ba2;
                      background: #ffffff;
                      box-sizing: border-box;
                    }
                  }
                }
              }
            }
          }
        }

        > .pages {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 2rem;
          position: absolute;
          bottom: 0.7rem;
          :deep(.el-pagination) {
            display: flex;
            justify-content: space-around;
            align-items: center;
            > button {
              background: #fff;
              color: #8fb5ff;
              border: 1px solid #dae6ff;
              border-radius: 10px;
              width: 60px;
              height: 38px;
            }
            > button:hover {
              border: 1px solid #4582ff;
            }
            > button[disabled] {
              border: 1px solid #dae6ff;
            }
            .el-pager {
              display: flex;
              justify-content: space-around;
              align-items: center;
              > li {
                height: 36px;
                padding: 0 1rem;
                background: #ffffff;
                border-radius: 10px;
                opacity: 1;
                border: 1px solid #dae6ff;
                color: #224b77;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 400;
              }
              > li:hover {
                border: 1px solid #4582ff;
              }
              .active {
                border: 1px solid #4582ff;
                background: #4582ff;
                color: #ffffff;
              }
            }
            > span {
              height: auto;
              > div {
                width: auto;
                height: auto;
                > input {
                  background: #eff3fc;
                  width: 60px;
                  height: 36px;
                  border-radius: 28px;
                  color: #224b77;
                }
              }
            }
          }
        }
      }
    }
  }
  //   > .listWrap {
  //     width: 100%;
  //     height: calc(100% - 40px - 64px);
  //     background: #fff;

  //   }
  .enpty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
