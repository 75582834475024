<template>
  <div class="templateWrap">
    <div class="mediaTemplateWrap">
      <Media
        :ref="'media' + templateNum.medias[0].index"
        :projectGuid="projectGuid"
        :isCreator="isCreator"
        :templateNum="templateNum.medias[0]"
        @needSave="needSave"
        @selectedItem="selectedItem"
        @uploadedFile="getuploadedFile"
        @initDone="initDone"
        @sourceLoaded="sourceLoaded"
      />
    </div>
  </div>
</template>

<script>
import { getFileTypeByFileName } from "@/utils/getFileType.js";
import Media from "./tenplateItem/media.vue";
import { uuid } from "vue-uuid";
import { BlockBlobClient, AnonymousCredential } from "@azure/storage-blob";
import {
  saveAssetNew,
  getUploadSasNew,
  updateStepInfo,
  getStepInfo,
  getAssetById,
} from "@/api/workflow";
import { getSelectedGuid } from "@/views/taskDetail/components/taskChart/setSelectShape.js";
export default {
  components: { Media },
  props: ["isCreator", "projectGuid", "taskGuid", "shapeItem"],
  data() {
    return {
      tempData: null,
      media1IsLoaded: false,
      // templateType: 0,
      templateNum: {
        type: 0,
        texts: [
          {
            type: 0, //文本框
            index: 0, //此模板中json数据中 文本文件在列表中的index
            tag: "0",
            title: "",
            content: null,
            webNeedSaveFlag: false,
            filePath: null,
          },
        ],
        medias: [
          {
            type: 1, //多媒体框
            index: 0, //此模板中json数据中 多媒体文件在列表中的index
            tag: "0",
            title: "",
            content: "",
            webNeedSaveFlag: false,
            filePath: null,
            thumbnailPath: null, //video和图片有缩略图
          },
        ],
      },
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    sourceLoaded(idInfo) {
      this.media1IsLoaded = true;
      this.$emit("sourceLoaded");
    },
    initDone() {
      this.$emit("initDone");
    },
    async initData(stepInfos) {
      try {
        this.tempData = stepInfos;
        let stepInfo = stepInfos;

        console.log(stepInfo);
        let temp = JSON.parse(stepInfo.stepObjectJson);
        // temp.texts.forEach(async (item, index) => {
        //   if (item.assetGuid) {
        //     let res = await getAssetById(item.assetGuid);
        //     temp.texts[index] = {
        //       ...item,
        //       ...res.result,
        //     };
        //   }
        // });
        // temp.medias.forEach(async (item, index) => {
        //   if (item.assetGuid) {
        //     let res = await getAssetById(item.assetGuid);
        //     temp.medias[index] = {
        //       ...item,
        //       ...res.result,
        //     };
        //   }
        // });

        this.templateNum = temp;
        console.log(this.templateNum);
      } catch (e) {
        this.templateNum = {
          type: 0,
          texts: [
            {
              type: 0, //文本框
              index: 0, //此模板中json数据中 文本文件在列表中的index
              tag: "0",
              title: "",
              webNeedSaveFlag: false,
              filePath: null,
            },
          ],
          medias: [
            {
              type: 1, //多媒体框
              index: 0, //此模板中json数据中 多媒体文件在列表中的index
              tag: "0",
              title: "",
              webNeedSaveFlag: false,
              filePath: null,
              thumbnailPath: null, //video和图片有缩略图
            },
          ],
        };
      }
    },
    //保存模板内容
    async saveTemplateInfo() {
      console.log(this.templateNum);
      // let options = {
      //   type: this.templateNum.type,
      //   texts: this.templateNum.texts,
      //   medias: this.templateNum.medias,
      // };
      // console.log(options);
      //纯多媒体 把文本列表置空
      this.templateNum.texts = [];
      for (let i = 0; i < this.templateNum.texts.length; i++) {
        let item = this.templateNum.texts[i];
        if (item.webNeedSaveFlag && !item.isDeleted) {
          let guid = uuid.v4();
          let options = {
            assetCategory: 2,
            assetType: 2,
            assetGuid: guid,
            assetName: item.assetName,
            description: null,
            filePath: item.filePath,
            originFileName: item.assetName,
            fileSize: null,
            isDeleted: false,
            projectGuid: null,
            shareLevel: 2,
            thumbnailPath: item.thumbnailPath,
            stepGuid:
              this.shapeItem?.data?.properties?.stepInfo?.sourceStepGuid ||
              getSelectedGuid(),
          };
          let res = await saveAssetNew(options);
          console.log(res);
          this.templateNum.texts[i] = {
            ...item,
            ...res,
          };
          this.templateNum.medias[i].webNeedSaveFlag = false;
        }
      }
      for (let i = 0; i < this.templateNum.medias.length; i++) {
        let item = this.templateNum.medias[i];
        if (item.webNeedSaveFlag && !item.isDeleted) {
          let guid = uuid.v4();
          let options = {
            assetCategory: getFileTypeByFileName(item.assetName),
            assetType: 2,
            assetGuid: guid,
            assetName: item.assetName,
            description: null,
            filePath: item.filePath,
            originFileName: item.assetName,
            fileSize: null,
            isDeleted: false,
            projectGuid: null,
            shareLevel: 2,
            thumbnailPath: item.thumbnailPath,
            stepGuid:
              this.shapeItem?.data?.properties?.stepInfo?.sourceStepGuid ||
              getSelectedGuid(),
          };
          let res = await saveAssetNew(options);
          console.log(res);
          this.templateNum.medias[i] = {
            ...item,
            ...res,
          };
          this.templateNum.medias[i].webNeedSaveFlag = false;
        }
      }
      console.log(this.templateNum.medias);
      // this.$emit("saveTemplateInfo", options);
      let updateOption = {
        taskGuid: this.taskGuid,
        stepGuid:
          this.shapeItem?.data?.properties?.stepInfo?.sourceStepGuid ||
          getSelectedGuid(),
        panelType: this.templateNum.type,
        appearType: 0,
        index: 0,
        delaySeconds: 0,
        stepObjectJson: JSON.stringify(this.templateNum),
      };
      let res1 = await updateStepInfo(updateOption);
      this.needSave(false);
    },
    //midea组件传回的上传之后的信息
    async getuploadedFile(data, indexInfo, isNull) {
      console.log(data);
      // this.templateNum.media[indexInfo.index].value = data;
      //选择文件后立即上传
      if (data.fileType === 3) {
        //pdf不需要上传缩略图
        let pdfRes = await this.uploadStageFile(data.file);
        console.log(pdfRes);
        this.templateNum.medias[indexInfo.index].webNeedSaveFlag = true;
        // this.templateNum.medias[indexInfo.index].value = data;
        this.templateNum.medias[indexInfo.index].filePath = pdfRes;
        this.templateNum.medias[indexInfo.index].assetName = data.file.name;
        this.templateNum.medias[indexInfo.index].fileSize = data.file.size;
        this.templateNum.medias[indexInfo.index].isDeleted = false;
        console.log(this.templateNum);
        this.$refs["media" + String(indexInfo.index)].setLoading(false);
        this.needSave(true);
      } else if (data.fileType === 1 || data.fileType === 2) {
        //图片或多媒体
        //文件
        let mediaFile = await this.uploadStageFile(data.file);
        //缩略图文件
        let mediaThumbnail = await this.uploadStageFile(data.fileThumbnail);
        this.templateNum.medias[indexInfo.index].webNeedSaveFlag = true;
        // this.templateNum.medias[indexInfo.index].value = data;
        this.templateNum.medias[indexInfo.index].filePath = mediaFile;
        this.templateNum.medias[indexInfo.index].thumbnailPath = mediaThumbnail;
        this.templateNum.medias[indexInfo.index].assetName = data.file.name;
        this.templateNum.medias[indexInfo.index].fileSize = data.file.size;
        this.templateNum.medias[indexInfo.index].isDeleted = false;
        console.log(this.$refs);
        this.$refs["media" + String(indexInfo.index)].setLoading(false);
        this.needSave(true);
        console.log(mediaFile);
        console.log(mediaThumbnail);
        console.log(this.templateNum);
      } else {
        //删除
        this.templateNum.medias[indexInfo.index].assetGuid = null;
        this.templateNum.medias[indexInfo.index].webNeedSaveFlag = true;
        this.templateNum.medias[indexInfo.index].filePath = null;
        this.templateNum.medias[indexInfo.index].thumbnailPath = null;
        this.templateNum.medias[indexInfo.index].assetName = null;
        this.templateNum.medias[indexInfo.index].fileSize = null;
        this.templateNum.medias[indexInfo.index].fileUrl = null;
        this.templateNum.medias[indexInfo.index].thumbnailUrl = null;
        this.templateNum.medias[indexInfo.index].assetType = null;
        this.templateNum.medias[indexInfo.index].assetCategory = null;
        this.templateNum.medias[indexInfo.index].originFileName = null;
        this.templateNum.medias[indexInfo.index].isDeleted = true;
        this.$refs["media" + String(indexInfo.index)].setLoading(false);
        if (isNull) {
          return;
        }
        this.needSave(true);
      }
    },
    selectedItem(data, indexInfo) {
      console.log(data);
      // this.templateNum.medias[indexInfo.index].fileUrl = data.fileUrl;

      this.templateNum.medias[indexInfo.index] = {
        ...this.templateNum.medias[indexInfo.index],
        ...data,
      };
      this.$emit("needSave", data);
    },
    needSave(data) {
      console.log("mediaNeedSave触发");
      this.$emit("needSave", data);
    },
    async uploadStageFile(file) {
      if (!file) {
        return "";
      }
      try {
        const res = await getUploadSasNew(file.name || `${uuid.v4()}.jpeg`);

        const blobClient = new BlockBlobClient(
          res.sas,
          new AnonymousCredential()
        );
        await blobClient.uploadData(file, {
          blockSize: 512 * 1024,
          concurrency: 2,
        });

        // await saveMyProfilePhoto(path)
        return res.path;
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.templateWrap {
  width: 100%;
  height: 94%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
  > div {
    background: #ededed;
    border-radius: 10px;
    height: 100%;
  }
  .mediaTemplateWrap {
    width: 100%;
  }
}
</style>
