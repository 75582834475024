<template>
  <div class="logicflowWrap">
    <div
      :class="logicFlowLoading ? 'canvasCover coverActive' : 'canvasCover'"
      v-loading="logicFlowLoading"
    ></div>
    <div class="ctrlWarps" v-if="!readOnly">
      <div class="ctrls">{{ taskInfo.taskName }}</div>
      <div class="scaleDropdown">
        <el-select
          size="mini"
          style="width:7rem"
          v-model="selectScale"
          placeholder=""
          @change="scaleChange"
        >
          <el-option
            v-for="item in scaleList"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="close" @click="closeMap">
        <i class="el-icon-close closeBtn"></i>
      </div>
    </div>
    <div class="containerWrap">
      <div ref="container" id="container" class="logicflowBody"></div>
    </div>

    <!-- v-loading="flowChartLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)" -->
    <!-- <div class="buttons">
      <el-button @click="addStart">新增开始步骤</el-button>
      <el-button @click="addActioon">新增普通步骤</el-button>
      <el-button @click="addTask">新增任务步骤</el-button>
      <el-button @click="addJudge">新增判断步骤</el-button>
      <el-button @click="getSelectElements">获取当前选中的元素</el-button>
      <el-button @click="openSelectionSelect">开启框选</el-button>
      <el-button @click="closeSelectionSelect">关闭框选</el-button>
    </div> -->
  </div>
</template>

<script>
//把logicflow挂载到vue实例外面
var lf;
import LogicFlow from "@logicflow/core";
import "@logicflow/core/dist/style/index.css";
import "@logicflow/extension/lib/style/index.css";
// import { DndPanel, SelectionSelect } from "@logicflow/extension";
// LogicFlow.use(SelectionSelect);
import StartShape from "./logicflow_ui_1/startShape.js";
import ActionShape from "./logicflow_ui_1/actionShape.js";
import CloneActionShape from "./logicflow_ui_1/cloneActionShape.js";
import TaskShape from "./logicflow_ui_1/taskShape.js";
import JudgeShape from "./logicflow_ui_1/judgeShape.js";
import CloneJudgeShape from "./logicflow_ui_1/cloneJudgeShape.js";
import InputShape from "./logicflow_ui_1/inputShape.js";
import CloneInputShape from "./logicflow_ui_1/cloneInputShape.js";
import PolylineConfig from "./logicflow_ui_1/polylineConfig.js";
import {
  getSelectedGuid,
  setSelectedGuid,
  removeSelectedGuid,
} from "../../components/taskChart/setSelectShape";
import { getTaskById, saveTaskSteps, generateHistory } from "@/api/workflow.js";
export default {
  components: {},
  props: ["projectInfo", "taskGuid", "isCreator", "stepConfigInfoNeedSave"],
  data() {
    return {
      canvasPosition: null,
      selectScale: 100,
      scaleList: [
        {
          value: 200,
        },
        {
          value: 150,
        },
        {
          value: 120,
        },
        {
          value: 100,
        },
        {
          value: 80,
        },
        {
          value: 50,
        },
        {
          value: 20,
        },
      ],
      taskInfo: "",
      needSave: false,
      saveHistoryInterval: null,
      rightClickPosition: "",
      logicFlowLoading: false,
      copyList: [],
      shapeItem: "",
      readOnly: false,
      readOnlyRouterList: ["/webGL"],
      flowChartLoading: false,
      dropdownList: [],
      selectLine: false,
      highlightList: [],
      // selectModel: {
      //   nodes: [],
      //   edges: [],
      // },
      selectNodes: null,
      selectNode: null,
      renderObj: {
        nodes: [],
        edges: [],
      },
      onLineNodes: [],
      config: {
        isSilentMode: true, //只读模式
        hideAnchors: true,
        edgeSelectedOutline: false,
        edgeTextEdit: false,
        adjustEdge: false,
        adjustNodePosition: false,
        nodeTextEdit: false,
        grid: {
          size: 1, //step移动步长
          type: "mesh",
          visible: false,
        },

        background: {
          backgroundImage: "url(./assets/login/eyes.png)",
          backgroundRepeat: "repeat",
          backgroundColor: "#F8FAFB",
        },
      },

      propertiesTemplateStep: {
        chartJson: null,
        description: null,
        instanceShapes: [],
        isInstance: null,
        isTask: null,
        refTaskGuid: null,
        sourceStepGuid: null,
        stepAssets: [],
        stepGuid: "",
        stepName: null,
        taskGuid: "",
      },
      propertiesTemplateLink: {
        chartJson: null,
        description: null,
        endStepGuid: "",
        startStepGuid: "",
        linkGuid: "",
        linkName: "",
        taskGuid: "",
      },
    };
  },
  computed: {
    isSelectShape() {
      return this.$store.state.TaskDetail.selectShape;
    },
    isShowHistory() {
      return this.$store.state.TaskDetail.showHistory;
    },
    isShowProjectInfo() {
      return this.$store.state.TaskDetail.showProjectInfo;
    },
  },
  created() {},
  mounted() {
    console.log(this.projectInfo);
    console.log(this.isCreator);
    if (!this.projectInfo) {
      return;
    }
    lf = new LogicFlow({
      plugins: [],
      container: this.$refs.container,
      ...this.config,
    });

    //注册自定义形状
    lf.register(StartShape);
    lf.register(ActionShape);
    lf.register(TaskShape);
    lf.register(JudgeShape);
    lf.register(CloneActionShape);
    lf.register(CloneJudgeShape);
    lf.register(InputShape);
    lf.register(CloneInputShape);
    lf.register(PolylineConfig);
    //给创作者绑定各种回调
    lf.on("blank:click", this.canvasClick);
    lf.on("node:click", this.selectElement);
    lf.on("edge:click", this.selectEdge);
    //画布缩放
    lf.on("graph:transform", this.zoomChange);
    lf.setTheme({
      nodeText: {
        color: "#000000",
        overflowMode: "autoWrap",
        lineHeight: 1.5,
        fontSize: 14,
        color: "#fff",
      },
      arrow: {
        offset: 4, // 箭头垂线长度
        verticalLength: 2, // 箭头底线长度
      },
    });
    lf.render();
    lf.setZoomMaxSize(2);
  },
  unmounted() {},
  watch: {
    taskGuid: {
      async handler(newVal, oldVal) {
        // await this.getTaskInfoById(newVal);
        this.$store.commit("SET_PROJECTINFO", true);
        console.log(newVal);
        await this.loadShape();
        //头部按钮显示可拖动状态
        let modelData = lf.graphModel.modelToGraphData();
        console.log(modelData);
        // this.sendSelectStepMsg();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    //接口 外部调用 根据节点ID获取其所有链接边的信息
    apiGetEdgeByNodeId(nodeId) {
      let dataSource = lf.graphModel.modelToGraphData();
      let res = {
        self: null,
        prev: [],
        next: [],
      };
      dataSource.edges.filter((item) => {
        if (item.targetNodeId === nodeId) {
          let node = this.apiGetNodeById(item.sourceNodeId);
          console.log(node);
          res.prev.push(node);
          // res.prev.push(item);
        }
        if (item.sourceNodeId === nodeId) {
          // res.next.push(item);
          let node = this.apiGetNodeById(item.targetNodeId);
          res.next.push(node);
        }
      });
      let selfNode = this.apiGetNodeById(nodeId);
      res.self = selfNode;
      return res;
    },
    //缩小倍率\改变
    scaleChange(value) {
      console.log(value);
      let x = this.canvasPosition.centerX + this.canvasPosition.left;
      let y = this.canvasPosition.centerY + this.canvasPosition.top / 2;
      lf.fitView(100, 100);
      lf.zoom(value / 100, [x, y]);
    },
    //画布缩放
    zoomChange(data) {
      let scale = data.transform.SCALE_X;
      this.selectScale = parseInt(scale.toFixed(2) * 100);

      // lf.zoom(data.scale, { x, y });
    },
    //向webgl发送选中某个步骤的消息
    sendSelectStepMsg() {
      let needSendStepGuid = "";

      let selectedNodeGuid = getSelectedGuid();
      if (selectedNodeGuid && selectedNodeGuid !== "undefined") {
        let selectNode = this.apiGetNodeById(selectedNodeGuid);
        if (selectNode) {
          this.apiSelectById(selectedNodeGuid);
          this.selectElement({ data: selectNode });
          // this.getTaskStepData(selectedNodeGuid);
          needSendStepGuid = selectedNodeGuid;
        } else {
          let showSelectShape = this.apiGetStartNodeGuid();

          this.apiSelectById(showSelectShape.id);
          this.selectElement(showSelectShape);
          needSendStepGuid = showSelectShape.id;
        }
      } else {
        let showSelectShape = this.apiGetStartNodeGuid();

        this.apiSelectById(showSelectShape.id);
        this.selectElement({ data: showSelectShape });
        needSendStepGuid = showSelectShape.id;
      }
      console.log("切换步骤");
      // this.$emit("sendData", needSendStepGuid);
      return needSendStepGuid;
    },
    //关掉webgl的流程图
    closeMap() {
      this.$emit("closeMap", false);
    },
    //过滤"任务步骤"列表中的当前任务
    filterSelf(taskGuid, taskList) {
      console.log(taskGuid);
      console.log(taskList);
      let arr = [];
      taskList.forEach((value, index) => {
        if (value.taskGuid !== taskGuid) {
          // taskList.splice(index, 1);
          arr.push(value);
        }
      });
      // return arr;
      this.dropdownList = arr;
    },
    //接口：外部调用返回开始步骤的GUID
    apiGetStartNodeGuid() {
      let modelData = lf.graphModel.modelToGraphData();
      let nodes = modelData.nodes;
      let startNode = nodes.find((item) => {
        return item.type === "StartShape";
      });
      if (startNode) {
        return startNode;
      } else {
        if (nodes.length > 0) {
          return nodes[0];
        } else {
          return null;
        }
      }
    },
    //接口：外部调用 获取id对应的节点详情
    apiGetNodeById(nodeId) {
      console.log(nodeId);
      console.log(lf.graphModel.modelToGraphData());
      let dataSource = lf.graphModel.modelToGraphData();
      let targetNode = dataSource.nodes.find((item) => item.id === nodeId);
      return targetNode;
    },
    //接口：外部调用 选中步骤
    apiSelectById(nodeId) {
      lf.graphModel.selectElementById(nodeId, false);
    },

    //从projectinfo中获取当前选中的task信息
    getTaskInfoByProjectInfo(taskGuid) {
      return this.projectInfo.tasks.find((item) => item.taskGuid === taskGuid);
    },
    //获取任务数据 如果没有开始步骤，会自动创建一个开始步骤并保存
    async getTaskInfoById(taskGuid) {
      console.log(this.projectInfo);
      console.log("getTaskById");
      let res;

      if (this.$route.query.auditReleaseGuid) {
        res = await getTaskById(
          taskGuid,
          this.projectInfo.auditReleaseGuid
            ? this.projectInfo.auditReleaseGuid
            : null
        );
      } else {
        res = await getTaskById(
          taskGuid,
          this.projectInfo.releaseGuid ? this.projectInfo.releaseGuid : null
        );
      }
      console.log("getTaskById");
      this.$emit("refreshTaskInfo", res);
      return res;
    },

    //获取数据处理数据兼容旧版本
    async loadShape() {
      this.logicFlowLoading = true;
      let taskInfo = await this.getTaskInfoById(this.taskGuid);
      console.log(taskInfo);
      this.taskInfo = taskInfo;
      let model = {
        edges: [],
        nodes: [],
      };
      let startStepGuid = null;
      this.filterSelf(this.taskGuid, this.projectInfo.tasks);
      taskInfo.steps.forEach((item) => {
        item.instanceShapes = [];
      });
      for (let i = 0; i < taskInfo.steps.length; i++) {
        const item = taskInfo.steps[i];
        let chartJsonToObj = JSON.parse(item.chartJson);
        //初始化step信息
        let nodeOptions = {
          id: item.stepGuid,
          type: this.getTypeByStep(chartJsonToObj.type),
          x: chartJsonToObj.bbox.x,
          y: chartJsonToObj.bbox.y,
          properties: {
            nodeSize: {
              width: chartJsonToObj.bbox.width,
              height: chartJsonToObj.bbox.height,
            },
            stepInfo: item,
            language: {
              copy: this.$t("taskChart.copy"),
              clone: this.$t("taskChart.clone"),
              setStartStep: this.$t("taskChart.setStartStep"),
              delete: this.$t("taskChart.delete"),
            },
          },
          text: {
            x: chartJsonToObj.bbox.x,
            y: chartJsonToObj.bbox.y,
            value: item.stepName,
          },
        };
        //设置开始步骤
        if (taskInfo.beginStepGuid === item.stepGuid) {
          nodeOptions.type = "StartShape";
          startStepGuid = nodeOptions.id;
        }
        //把克隆步骤领出来重新设置其nodeType
        if (item.sourceStepGuid) {
          console.log(item);
          if (nodeOptions.type === "judge") {
            nodeOptions.type = "CloneJudgeShape";
          } else if (nodeOptions.type === "action") {
            nodeOptions.type = "CloneActionShape";
          }
        }
        //属于克隆步骤的步骤在其克隆源步骤下建立列表
        if (item.sourceStepGuid) {
          let sourceNode = taskInfo.steps.find(
            (value) => value.stepGuid === item.sourceStepGuid
          );
          //把源步骤的信息同步在自己身上；
          sourceNode.instanceShapes.push(nodeOptions);
          item.stepName = sourceNode.stepName;
          item.description = sourceNode.description;
          nodeOptions.text.value = sourceNode.stepName;
        }
        model.nodes.push(nodeOptions);
      }
      for (let i = 0; i < taskInfo.links.length; i++) {
        const item = taskInfo.links[i];
        let chartJsonToObj = JSON.parse(item.chartJson);
        let edgeOptions = {
          id: item.linkGuid,
          type: "polyline",
          targetNodeId: item.endStepGuid,
          sourceNodeId: item.startStepGuid,
          properties: {
            linkInfo: {
              ...item,
            },
          },
          text: {
            value: item.linkName,
          },
        };
        //兼容旧版本 旧版本(chartJsonToObj.points包含起始两个点) 新版本(chartJsonToObj.pointsList包含这条折线的所有拐点)
        if (chartJsonToObj.pointsList) {
          //新版本
          console.log(chartJsonToObj);
          edgeOptions.pointsList = chartJsonToObj.pointsList;
        } else {
          //旧版本
        }
        //旧版本 旧版本需要重新计算折线的起始点 减去二分之一图形的宽高

        //寻找源图形
        let sourceStep = taskInfo.steps.find(
          (value) => value.stepGuid === item.startStepGuid
        );
        let sourceStepInfo = JSON.parse(sourceStep.chartJson);
        let needSubtraction = this.computeStartPolyline(sourceStepInfo);
        //兼容旧版本 旧版本(chartJsonToObj.points包含连线的起始点和结束点两个点) 新版本(chartJsonToObj.startPoint)(chartJsonToObj.endPoint)分别对应起始点和结束点
        if (chartJsonToObj.startPoint) {
          //新版本
          edgeOptions.startPoint = chartJsonToObj.startPoint;
        } else {
          //旧版本 旧版本需要重新计算折线的起始点

          //重新计算折线的起始点=[原宽-起始图形的1/2宽，原高-起始图形的1/2高]
          edgeOptions.startPoint = {
            x: chartJsonToObj.points[0][0] - needSubtraction.width,
            y: chartJsonToObj.points[0][1] - needSubtraction.height,
          };
        }
        //文字位置
        if (chartJsonToObj.text) {
          //新版本
          edgeOptions.text = chartJsonToObj.text;
        } else {
          //旧版本
        }
        if (chartJsonToObj.endPoint) {
          //新版本的结束点
          edgeOptions.endPoint = chartJsonToObj.endPoint;
        } else {
          //旧版本的结束点 重新计算
          edgeOptions.endPoint = {
            x: chartJsonToObj.points
              ? chartJsonToObj.points[chartJsonToObj.points.length - 1][0] -
                needSubtraction.width
              : null,
            y: chartJsonToObj.points
              ? chartJsonToObj.points[chartJsonToObj.points.length - 1][1] -
                needSubtraction.height
              : null,
          };
        }
        model.edges.push(edgeOptions);
      }
      console.log(model);
      //webGl设置
      //   model.nodes.forEach((item) => {
      //     lf.setProperties(item.id, {
      //       ...item.properties,
      //       isWebGl: true,
      //     });
      //   });
      lf.render(model);
      lf.fitView(100, 100);
      this.canvasPosition = this.findPosition();
      // this.initNodePosition(model);
      this.checkIsOnLinkLine();
      this.logicFlowLoading = false;
      setTimeout(() => {
        this.$emit("onLogicFlowLoaded", startStepGuid);
      }, 1000);
    },
    //寻找画布容器距离浏览器顶部的距离 和 画布中心点坐标
    findPosition() {
      // var dom = document.getElementById('container');
      let dom = this.$refs.container;
      let iTop = 0;
      let iLeft = 0;
      do {
        iTop += dom.offsetTop; //如果是左侧就是offsetLeft
        iLeft += dom.offsetLeft;
        dom = dom.parentNode; //如果DOM 节点 的parentNode存在，把当前的节点赋予成parentNode；
      } while (dom.parentNode);
      // let result = {}
      let dom1 = this.$refs.container;
      let containerWidth = dom1.offsetWidth;
      let containerHieght = dom1.offsetHeight;
      let positionInfo = lf.getPointByClient(
        containerWidth / 2,
        containerHieght / 2
      );
      // console.log(positionInfo);
      // console.log([dom]);

      let x = positionInfo.canvasOverlayPosition.x;
      let y = positionInfo.canvasOverlayPosition.y;
      return {
        top: iTop,
        left: iLeft,
        centerX: x,
        centerY: y,
      };
    },
    //把所有node移动到屏幕中央并根据情况缩放画布
    initNodePosition(modelData) {
      let XList = [];
      let YList = [];
      modelData.nodes.forEach((item) => {
        XList.push(item.x);
        YList.push(item.y);
      });
      XList.sort((a, b) => {
        return a - b;
      });
      YList.sort((a, b) => {
        return a - b;
      });
      console.log(XList);
      console.log(YList);
      //获取当前窗口的大小
      let dom = this.$refs.container;
      let containerWidth = dom.offsetWidth;
      let containerHieght = dom.offsetHeight;
      //当前窗口中线的坐标
      let containerCenterX = containerWidth / 2;
      let containerCenterY = containerHieght / 2;
      //外包裹的宽高
      let wrapBoxWidth = XList[XList.length - 1] - XList[0];
      let wrapBoxHeight = YList[YList.length - 1] - YList[0];
      //外包裹一半宽/高的值
      let wrapBoxCenterWidth = wrapBoxWidth / 2;
      let wrapBoxCenterHieght = wrapBoxHeight / 2;
      //外包裹中线的坐标
      let wrapBoxCenterPositionX = XList[0] + wrapBoxCenterWidth;
      let wrapBoxCenterPositionY = YList[0] + wrapBoxCenterHieght;
      //需要移动的位移
      let moveX = wrapBoxCenterPositionX - containerCenterX;
      let moveY = wrapBoxCenterPositionY - containerCenterY;

      console.log(moveX, moveY);
      modelData.nodes.forEach((item) => {
        lf.graphModel.moveNode(item.id, -moveX, -moveY, true);
      });
      //外包裹盒子宽或高超过了外部容器的宽或高
      //
      if (wrapBoxWidth > containerWidth || wrapBoxHeight > containerHieght) {
        //宽高差
        let widthDifference = wrapBoxWidth - containerWidth;
        let heightDifference = wrapBoxHeight - containerHieght;
        //定义比例尺
        let scale = 1;
        if (widthDifference > heightDifference) {
          //以宽为最终基准进行缩放
          //左右留存100px
          scale = containerWidth / (wrapBoxWidth + 100 * 2);
        } else if (widthDifference <= heightDifference) {
          //以高为基准缩放
          //上下留存100px
          scale = containerHieght / (wrapBoxHeight + 100 * 2);
        }
        lf.zoom(scale, [containerWidth / 2, containerHieght / 2]);
      }
    },
    //旧版本兼容
    computeStartPolyline(stepInfo) {
      // console.log(stepInfo);
      return {
        width: stepInfo.bbox.width / 2,
        height: stepInfo.bbox.height / 2,
      };
    },
    //兼容旧版本type
    getTypeByStep(typeStr) {
      let stepType = "action";
      switch (typeStr) {
        case "StartShape":
          stepType = "StartShape";
          break;
        case "action":
          stepType = "action";
          break;
        case "judge":
          stepType = "judge";
          break;
        case "pointer":
          stepType = "pointer";
          break;
        case "CloneActionShape":
          stepType = "CloneActionShape";
          break;
        case "CloneJudgeShape":
          stepType = "CloneJudgeShape";
          break;
        case "InputShape":
          stepType = "InputShape";
          break;
        case "CloneInputShape":
          stepType = "CloneInputShape";
          break;
      }
      return stepType;
    },

    //画布被单机，模拟"取消选中"的动作
    canvasClick(e) {
      console.log(e);
      //webgl页面必然会选择一个节点
      let selectedNodeGuid = getSelectedGuid();
      if (selectedNodeGuid) {
        lf.graphModel.selectElementById(selectedNodeGuid, false);
      } else {
        console.log(111);
        let startNode = this.apiGetStartNodeGuid();
        lf.graphModel.selectElementById(startNode.id, false);
      }
    },
    setStoreStatus(selectShape, showHistory, showProjectInfo) {
      this.$store.commit("SET_PROJECTINFO", showProjectInfo);
      this.$store.commit("SET_SHOWHISTORY", showHistory);
      this.$store.commit("SET_SELECTSHAPE", selectShape);
    },
    //取消克隆体高亮状态
    cancelHighlight() {
      let modelData = lf.graphModel.modelToGraphData();
      modelData.nodes.forEach((item) => {
        lf.setProperties(item.id, {
          ...item.properties,
          cloneNodeActive: false,
        });
      });
    },
    //连线被点击
    selectEdge() {
      console.log(111);
      console.log(lf.graphModel.getSelectElements());
      //   this.apiSelectById(getSelectedGuid());
      lf.graphModel.selectElementById(getSelectedGuid(), true);
    },
    //节点被点击
    selectElement(data, e) {
      console.log(data);

      if (!data.isConfirmSave) {
        if (this.stepConfigInfoNeedSave) {
          this.$confirm(
            this.$t("webGL.tip1"),
            this.$t("webGL.tip2"),

            {
              closeOnClickModal: false,
              distinguishCancelAndClose: true,
              // 确定
              confirmButtonText: this.$t("webGL.save"),
              // 取消
              cancelButtonText: this.$t("webGL.noSave"),
              // type: "warning",
            }
          )
            .then(async () => {
              //执行切换选中操作并保存

              this.selectNodesToHighlightAll(data);
              //保存刚刚的更改
              this.$emit("saveStepInfoFromChart");
              //执行切换选中
              setSelectedGuid(data.data.id);
              this.$emit("setSelectNode", data);
              //把"是否需要保存"状态重置为false
              this.$emit("stepConfigInfoNeedSaveStatus", false);
            })
            .catch((action) => {
              if (action === "cancel") {
                // this.$emit("nextNode", stepGuid);
                //不保存，仅执行切换选中操作
                setSelectedGuid(data.data.id);
                this.selectNodesToHighlightAll(data);
                this.$emit("setSelectNode", data);
                //把"是否需要保存"状态重置为false
                this.$emit("stepConfigInfoNeedSaveStatus", false);
              } else if (action === "close") {
                //关闭，什么也不做
                //切换回之前选中的节点 第二个参数代表 是否是多选
                if (getSelectedGuid()) {
                  lf.graphModel.selectNodeById(getSelectedGuid(), false);
                }
                return;
              }
            });
        } else {
          this.selectNodesToHighlightAll(data);
          this.$emit("setSelectNode", data);
          setSelectedGuid(data.data.id);
        }
      } else {
        this.selectNodesToHighlightAll(data);
        this.$emit("setSelectNode", data);
        setSelectedGuid(data.data.id);
        this.$emit("stepConfigInfoNeedSaveStatus", false);
      }
    },
    selectNodesToHighlightAll(data) {
      if (!data.isNotEmit) {
        this.$emit("sendData", data.data.id);
      }

      // if (data.data.type === "pointer") {
      //   this.$emit("switchPointerStep", data.data.id);
      // }

      this.cancelHighlight();
      // console.log(data);
      // console.log(data.data);
      // console.log(data.data.data);
      // console.log(data.data.data.properties);
      // console.log(data.data.properties);
      if (data.data.properties.stepInfo.sourceStepGuid) {
        //点击选中的是克隆节点
        // lf.setProperties(data.data.id, {
        //   cloneNodeActive: true,
        //   ...data.data.properties,
        // });
        let modelData = lf.graphModel.modelToGraphData();
        let cloneMember = [];
        modelData.nodes.forEach((item) => {
          if (
            item.properties.stepInfo.stepGuid ===
              data.data.properties.stepInfo.sourceStepGuid ||
            item.properties.stepInfo.sourceStepGuid ===
              data.data.properties.stepInfo.sourceStepGuid
          ) {
            cloneMember.push(item);
          }
        });
        console.log(cloneMember);
        cloneMember.forEach((item) => {
          lf.setProperties(item.id, {
            ...item.properties,
            cloneNodeActive: true,
          });
        });

        // this.highlightList = cloneMember;
      } else {
        //点击选中的不是克隆节点
        //如果是克隆源步骤
        if (data.data.properties.stepInfo.instanceShapes.length > 0) {
          lf.setProperties(data.data.id, {
            ...data.data.properties,
            cloneNodeActive: true,
          });
          data.data.properties.stepInfo.instanceShapes.forEach((item) => {
            lf.setProperties(item.id, {
              ...item.properties,
              cloneNodeActive: true,
            });
          });
        }
      }
    },
    getSelectElements() {
      let selectElement = lf.getSelectElements(true);
      console.log(selectElement);
      console.log(lf.graphModel.modelToGraphData());
    },
    drawSuccess(data, e, edgeModel, nodeModel) {
      console.log(data);
      console.log(lf.graphModel.modelToGraphData());
      this.checkIsOnLinkLine();
    },
    //给"开始步骤"连接树上的所有图形做active
    checkIsOnLinkLine() {
      this.onLineNodes = [];
      let list = lf.graphModel.modelToGraphData();
      let startStep = list.nodes.find((item) => item.type == "StartShape");
      console.log(startStep);
      if (!startStep) {
        return;
      }
      this.onLineRecurrence(startStep.id);
      list.nodes.forEach((value) => {
        lf.setProperties(value.id, {
          ...value.properties,
          onLinkLine: false,
          isWebGl: true,
        });
      });
      this.onLineNodes.forEach((value) => {
        lf.setProperties(value.id, {
          ...value.properties,
          onLinkLine: true,
        });
      });
    },
    onLineRecurrence(sourceNodeId) {
      let list = lf.graphModel.modelToGraphData();
      for (let i = 0; i < list.edges.length; i++) {
        const value = list.edges[i];
        if (value.sourceNodeId === sourceNodeId) {
          let target = list.nodes.find(
            (item) => item.id === value.targetNodeId
          );
          let isHad = this.onLineNodes.find((item) => item.id === target.id);
          if (target) {
            this.onLineNodes.push(target);
            if (!isHad) {
              this.onLineRecurrence(target.id);
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.logicflowWrap {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .canvasCover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: #ccc;
    z-index: 10;
    display: none;
  }
  > .coverActive {
    display: block;
  }
  .containerWrap {
    display: flex;
    justify-content: flex-start;
    height: 100%;
    .logicflowBody {
      width: 100%;
      // height: calc(100% - 3.5rem);

      :deep(.lf-graph) {
        // z-index: 10;
        .lf-tool-overlay {
          .lf-dndpanel {
            margin: 0;
            // padding: 0;
            border-radius: 0;
            border-left: 1px solid #ccc;
            top: 0rem;
            left: 0;
            display: flex;
            box-shadow: none;
            justify-content: flex-start;
            .lf-dnd-item {
              margin: 0 1.5rem;
              // .lf-dnd-shape {
              // }
              .lf-dnd-text {
                color: #333;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    .others {
      // position: absolute;
      top: 0px;
      right: 0px;
      width: 28rem;
      background: #fff;
      // padding: 1rem 3rem 1rem 3rem;
      height: calc(100vh - 6.8rem);
      box-sizing: border-box;
      overflow-y: auto;
      border-left: 1px solid #ccc;
    }
    .others::-webkit-scrollbar {
      width: 14px;
      height: 14px;
    }

    .others::-webkit-scrollbar-corner {
      background-color: transparent;
    }

    .others::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.1);
      background-clip: padding-box;
      border: solid transparent;
      border-radius: 10px;
    }

    .others::-webkit-scrollbar-track {
      background-clip: padding-box;
      border: solid transparent;
      border-width: 1px;
    }
  }

  .startStep {
    cursor: url("~@/assets/curs/start.cur"), auto !important;
  }

  .defaultStep {
    cursor: url("~@/assets/curs/step.cur"), auto !important;
  }

  .judgeStep {
    cursor: url("~@/assets/curs/judge.cur"), auto !important;
  }

  .buttons {
    position: absolute;
    top: 0;
    left: 0;
  }
  .ctrlWarps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    // position: absolute;
    // top: 1px;
    // left: 0;
    width: 100%;
    height: 3.65rem;
    background: #fff;
    border-bottom: 1px solid #ccc;
    // border-left: 1px solid #ccc;
    box-sizing: border-box;
    padding-left: 1rem;
    .scaleDropdown {
      position: absolute;
      right: 50px;
    }
    .selfLine {
      width: 2px;
      height: 2rem;
      background: #e4ebf6;
      margin-right: 1rem;
    }
    .close {
      .closeBtn {
        margin-right: 1.2rem;
        cursor: pointer;
      }
    }
    .lines,
    .ctrls {
      display: flex;
      justify-content: left;
      align-items: center;

      button,
      div,
      div button {
        border: 1px solid rgba(0, 0, 0, 0);
        outline: none;
        background: #f5f7fa;
        cursor: pointer;
        width: 3.2rem;
        height: 2.2rem;
        border-radius: 5px;
        margin-right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
        }
      }

      button[disabled],
      div button[disabled] {
        border: 1px solid #ccc !important;
        filter: grayscale(100%);
        cursor: not-allowed;
      }

      button:hover {
        border: 1px solid #4582ff;
      }

      .select {
        background: #4582ff;
      }

      .active {
        border: 1px solid #4582ff;
        background: #4582ff;
      }

      .active1 {
        border: 1px solid #4582ff;
      }

      .disabled {
        border: 1px solid #ccc !important;
        filter: grayscale(100%);
        cursor: not-allowed;
      }

      div button {
        margin-right: 0;
      }

      div button:hover {
        border: 1px solid #4582ff;
      }
    }
  }
}
.readOnlyLogicflowWrap {
  width: 100%;
  height: calc(100vh - 30rem);
}
:deep(.lf-menu) {
  width: 10rem;
  background: #fff;
  padding: 0.5rem;
  border-radius: 5px;
  margin: 0;
  .lf-menu-item {
    padding: 0.3rem 0.8rem;
    display: block;
    outline: none;
    border: none;
    width: 100%;
    background: #fff;
    text-align: left;
    color: #f94a4a;
    font-size: 0.95rem;
    border-radius: 3px;
    cursor: pointer;
    line-height: 2rem;
    box-sizing: border-box;
  }
  .lf-menu-item:hover {
    background: #ffecec;
  }
  .items {
    background: #fff;
    border-radius: 4px 4px 4px 4px;
    color: #274e78;
  }
  .items:hover {
    background: #ecf2ff;
  }
}
:deep(.lf-node-text-auto-wrap-content) {
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 8%;
  .lf-node-text--auto-wrap-inner {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
</style>
