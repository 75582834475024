<template>
  <el-row> 新建 </el-row>
</template>

<style lang="scss"></style>

<script>
const _path = "/account";
const _endpoint = "account";

export default {
  created: async function () {
    // http://139.196.242.85:3000/login/getNavInfo
    let data = await this.$auth.fetch(
      {
        endpoint: `login/getNavInfo`,
        method: "GET",
      }
    );
    console.log(data);
  },
  data() {
    const $this = this;

    return {};
  },
  methods: {},
};
</script>
