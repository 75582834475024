<template>
  <div class="uploadWrap">
    <div class="imgWrap" v-if="fileType === 1">
      <img
        v-loading="loading"
        @load="imgOnload"
        crossorigin="anonymous"
        :src="fileUrl"
        v-if="file"
        alt=""
      />
    </div>
    <!-- 拼命加载中... -->
    <div
      v-loading="loading"
      :element-loading-text="$t('mediaVue.word1')"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      class="videoWrap"
      v-if="fileType === 2"
    >
      <video
        crossorigin="anonymous"
        :id="videoRef"
        controls
        :src="fileUrl"
        @canplaythrough="videoCanplaythrough"
      ></video>
    </div>

    <div class="videoWrap" v-if="fileType === 3">
      <div class="imgWrap">
        <div class="img">
          <img
            @load="imgOnload"
            class="pdfdefault"
            @click="preViewPdf"
            ref="pdfRef"
            src="@/assets/project/pdfDefault.png"
            alt=""
          />
          <PdfView :ref="pdfRef" />
        </div>
        <p>{{ file.name }}</p>
      </div>
    </div>

    <div class="uploadBox" v-if="!file">
      <div class="localUpload" :style="sizeStyle">
        <div class="uploadImg">
          <img src="@/assets/task-detail/defaultPicture.png" alt="" />
          <div>
            <div class="link">
              <el-upload
                class="uploadWrap1"
                action=""
                :show-file-list="false"
                :multiple="false"
                :auto-upload="false"
                :on-change="onUploadChange"
              >
                <!-- 点此上传 -->
                {{ $t("mediaVue.word2") }}</el-upload
              >
            </div>
            <!-- 或 -->
            <div class="and">{{ $t("mediaVue.word3") }}</div>
            <!-- 素材库 -->
            <div class="link" @click="addAssets">
              {{ $t("mediaVue.word4") }}
            </div>
          </div>
        </div>
        <div class="textTip">
          <!-- 可上传JPG或PNG格式图片、MP4格式视频、PDF文件，文件不超过20M -->
          {{ $t("mediaVue.word5") }}
        </div>
      </div>

      <!-- <div class="assetsUpload" @click="addAssets">
        <div class="uploadImg">
          <img src="@/assets/task-detail/defaultPicture.png" alt="" />
          <p>素材库</p>
        </div>
        <div class="textTip"></div>
      </div> -->
    </div>
    <div class="controls" v-if="file">
      <el-upload
        class="change-btn"
        action=""
        :show-file-list="false"
        :multiple="false"
        :auto-upload="false"
        :on-change="onUploadChange"
      >
        <button class="updateFile">
          <div class="img"></div>
        </button>
      </el-upload>
      <button @click="addAssets" class="assets">
        <div class="img"></div>
      </button>
      <button @click="deleteMediaFile" class="deleteFile">
        <div class="img"></div>
      </button>
    </div>
    <MaterialLibrary
      :projectGuid="projectGuid"
      :isCreator="isCreator"
      ref="materialLibrary"
      @selectedItem="selectedItem"
    />
    <canvas class="canvas" :id="canvasRef"></canvas>
  </div>
</template>

<script>
import PdfView from "@/views/components/pdfView/pdfView.vue";
import { ElMessage } from "element-plus";
import { getFileTypeByFileName } from "@/utils/getFileType.js";
import { fileMaxSize, videoMaxSize } from "@/utils/asset.js";
import { compressImg } from "@/utils/compress.js";
import MaterialLibrary from "@/views/taskDetail/components/materialLibrary/materialLibrary.vue";
export default {
  components: { MaterialLibrary, PdfView },
  props: ["sizeStyle", "isCreator", "projectGuid", "templateNum", "readyOnly"],
  data() {
    return {
      loading: false,
      file: null,
      fileUrl: null,
      fileThumbnail: null,
      fileThumbnailUrl: null,
      fileType: 65535,
      timeout: null, //计时器，防止video重复广播时间导致重复上传
    };
  },
  computed: {
    pdfRef() {
      return "pdf" + String(this.templateNum.index);
    },
    videoRef() {
      return "videoContainer" + String(this.templateNum.index);
    },
    canvasRef() {
      return "canvas" + String(this.templateNum.index);
    },
  },
  created() {},
  mounted() {},
  watch: {
    templateNum(newVal, oldVal) {
      console.log(newVal);
      console.log(oldVal);
      if (newVal.filePath === oldVal.filePath) {
        return;
      }
      if (newVal && newVal.assetGuid) {
        this.fileUrl = newVal.fileUrl ? newVal.fileUrl : null;
        this.fileThumbnailUrl = newVal.fileThumbnailUrl
          ? newVal.fileThumbnailUrl
          : null;
        this.fileType = getFileTypeByFileName(
          newVal.assetName ? newVal.assetName : "111.jpg"
        );
        if (newVal.fileUrl) {
          this.file = true;
        }
        if (newVal.fileThumbnailUrl) {
          this.fileThumbnail = true;
        }
        // this.$nextTick(() => {
        //   setTimeout(() => {
        //     this.$emit("initDone");
        //   }, 1000);
        // });
      } else {
        this.fileType = 65535;
        this.file = null;
        this.fileUrl = null;
        this.fileThumbnail = null;
        this.fileThumbnailUrl = null;
        this.$emit("initDone");
        // this.$nextTick(() => {
        //   setTimeout(() => {
        //     this.$emit("initDone");
        //   }, 1000);
        // });
      }
    },
  },
  methods: {
    initData(newVal) {
      // if (newVal && newVal.assetGuid) {
      //   this.fileUrl = newVal.fileUrl ? newVal.fileUrl : null;
      //   this.fileThumbnailUrl = newVal.fileThumbnailUrl
      //     ? newVal.fileThumbnailUrl
      //     : null;
      //   this.fileType = getFileTypeByFileName(
      //     newVal.assetName ? newVal.assetName : "111.jpg"
      //   );
      //   if (newVal.fileUrl) {
      //     this.file = true;
      //   }
      //   if (newVal.fileThumbnailUrl) {
      //     this.fileThumbnail = true;
      //   }
      //   // this.$nextTick(() => {
      //   //   setTimeout(() => {
      //   //     this.$emit("initDone");
      //   //   }, 1000);
      //   // });
      // } else {
      //   this.fileType = 65535;
      //   this.file = null;
      //   this.fileUrl = null;
      //   this.fileThumbnail = null;
      //   this.fileThumbnailUrl = null;
      //   this.$emit("initDone");
      //   // this.$nextTick(() => {
      //   //   setTimeout(() => {
      //   //     this.$emit("initDone");
      //   //   }, 1000);
      //   // });
      // }
    },
    setLoading(data) {
      this.loading = data;
    },
    imgOnload(file) {
      console.log("图片加载完成");
      console.log(file);
      this.$emit("sourceLoaded", this.templateNum);
    },
    videoCanplaythrough(file) {
      console.log("视频加载完成");
      console.log(file);
      setTimeout(() => {
        this.$emit("sourceLoaded", this.templateNum);
      }, 300);
    },
    preViewPdf() {
      this.$refs[this.pdfRef].setSwitch(true, this.fileUrl);
    },
    selectedItem(data) {
      //选择了素材库的素材
      console.log(data);
      this.$emit("selectedItem", data, this.templateNum);
    },
    addAssets() {
      console.log(this.$refs.materialLibrary);
      this.$refs.materialLibrary.init(null, true);
    },
    deleteMediaFile() {
      // 确认删除此文件?   提示
      this.$confirm(this.$t("mediaVue.word6"), this.$t("mediaVue.word7"), {
        // 确定
        confirmButtonText: this.$t("mediaVue.word8"),
        // 取消
        cancelButtonText: this.$t("mediaVue.word9"),
      })
        .then(() => {
          this.fileType = 65535;
          this.file = null;
          this.fileUrl = null;
          this.fileThumbnail = null;
          this.fileThumbnailUrl = null;
          this.$emit("needsave", true, this.templateNum);
          this.emitData();
          this.$message({
            type: "success",
            // 删除成功!
            message: this.$t("mediaVue.word10"),
          });
        })
        .catch(() => {});
    },
    emitData(isNull) {
      console.log("广播事件");
      this.$emit(
        "uploadedFile",
        {
          file: this.file,
          fileUrl: this.fileUrl,
          fileThumbnail: this.fileThumbnail,
          fileThumbnailUrl: this.fileThumbnailUrl,
          fileType: this.fileType,
        },
        { ...this.templateNum },
        isNull
      );
    },
    returnFileInfo() {
      return {
        file: this.file,
        fileUrl: this.fileUrl,
        fileThumbnail: this.fileThumbnail,
        fileThumbnailUrl: this.fileThumbnailUrl,
        fileType: this.fileType,
      };
    },
    async onUploadChange(f) {
      console.log(f.raw);
      let fileType = getFileTypeByFileName(f.raw.name);
      if (fileType === 1 || fileType === 2 || fileType === 3) {
        if ((fileType === 1 || fileType === 3) && f.raw.size > fileMaxSize) {
          // "文件大小超出限制（20MB）"
          ElMessage.error(this.$t("mediaVue.word11"));
          return;
        }
        if (fileType === 2 && f.raw.size > videoMaxSize) {
          // "文件大小超出限制（200MB）"
          ElMessage.error(this.$t("mediaVue.word12"));
          return;
        }
        this.fileType = getFileTypeByFileName(f.raw.name);
        //生成预览图
        this.file = f.raw;
        this.fileUrl = URL.createObjectURL(f.raw);

        if (fileType === 2) {
          //视频
          this.setLoading(true);
          this.$nextTick(() => {
            try {
              console.log(this.templateNum);
              let video = document.getElementById(this.videoRef);
              video.pause();
              console.log(video);
              console.log([video]);
              video.currentTime = 1;
              video.addEventListener("loadeddata", async (e) => {
                //加载完成事件，调用函数
                let canvasRef = document.getElementById(this.canvasRef);
                canvasRef.width = video.videoWidth;
                canvasRef.height = video.videoHeight;
                canvasRef
                  .getContext("2d")
                  .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                const aaa = await this.getBlobFromCanvas(canvasRef);
                aaa.name = "aaa.jpeg";

                // imageCanvas.width = 0;

                this.fileThumbnailUrl = URL.createObjectURL(aaa);
                this.fileThumbnail = aaa;
                clearTimeout(this.timeout);
                this.emitData();
              });
            } catch (e) {
              this.setLoading(false);
            }
          });
        } else if (fileType === 3) {
          // this.$emit("needSave", true);
          console.log(f.raw);
          this.emitData();
          this.setLoading(true);
        } else {
          //图片
          //生成缩略图
          try {
            let tfile = await compressImg(f.raw, 0.8);
            console.log(tfile);
            console.log(tfile.canvas);
            this.fileThumbnail = tfile.blob;
            tfile.blob.name = f.raw.name;
            // this.fileItem.fileToUpload = file.blob;
            this.fileThumbnailUrl = URL.createObjectURL(f.raw);
            // this.$emit("needSave", true);
            this.fileUrl = URL.createObjectURL(f.raw);
            this.file = tfile.blob;
            console.log(f.raw);
            this.emitData();
            this.setLoading(true);
          } catch (e) {
            this.setLoading(false);
          }
        }
      } else {
        // "文件格式暂不支持,请重新上传"
        ElMessage.error(this.$t("mediaVue.word13"));
      }
    },
    async getBlobFromCanvas(imageCanvas) {
      return new Promise((res, rej) => {
        imageCanvas.toBlob((blob) => {
          res(blob);
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.uploadWrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: rgba(0, 0, 0, 0);
  overflow: hidden;
  .canvas {
    position: absolute;
    z-index: -1;
    opacity: 1;
  }
  .imgWrap {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .videoWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    > video {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background: #000;
    }
    .imgWrap {
      border-radius: 10px;
      overflow: hidden;
      > .img {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        > img {
          width: 20rem;
        }
        .pdfdefault {
          cursor: pointer;
        }
      }
      > p {
        text-align: center;
        color: #fff;
        font-size: 1.5rem;
      }
    }
  }
  .controls {
    position: absolute;
    bottom: 2rem;
    right: 1rem;
    padding: 0.3rem;
    background: #fff;
    border-radius: 0.2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    button {
      cursor: pointer;
      padding: 0.5rem 0.7rem;
      transition: all 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.2rem;

      border: none;
      outline: none;
    }
    .updateFile {
      background: #f5f7fa;
      .img {
        width: 2rem;
        height: 1.35rem;
        background: url("~@/assets/project/localUpload.png") no-repeat center
          center;
        background-size: 137%;
      }
    }
    .updateFile:hover {
      background: #144392;
      .img {
        background: url("~@/assets/project/localUpload1.png") no-repeat center
          center;
        background-size: 137%;
      }
    }
    .assets {
      margin-left: 0.5rem;
      background: #f5f7fa;
      .img {
        width: 2rem;
        height: 1.35rem;
        background: url("~@/assets/project/assetSelect.png") no-repeat center
          center;
        background-size: 137%;
      }
    }
    .assets:hover {
      background: #144392;
      .img {
        background: url("~@/assets/project/assetSelect1.png") no-repeat center
          center;
        background-size: 137%;
      }
    }
    .deleteFile {
      margin-left: 0.5rem;
      background: #ffecec;
      .img {
        width: 2rem;
        height: 1.35rem;
        background: url("~@/assets/project/delete1.png") no-repeat center center;
        background-size: 63%;
      }
    }
    .deleteFile:hover {
      background: #fd7a7a;
      .img {
        background: url("~@/assets/project/delete.png") no-repeat center center;
        background-size: 63%;
      }
    }
  }
  > img {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
  }
  .uploadBox {
    width: 18.5rem;
    // height: 12rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
    border: 1px dashed #aaaaaa;
    border-radius: 10px;
    > div {
      // background: #fff;
      width: 100%;
      height: 100%;
      padding: 2rem;
      box-sizing: border-box;
      > .uploadImg {
        > div {
          margin-top: 0;
          // color: #4582ff;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          top: -10px;
          > div {
            color: #4582ff;
            font-weight: bold;
          }
          .and {
            color: #242831;
            margin: 0 1rem;
          }
          .link {
            cursor: pointer;
          }
        }
      }
      > .textTip {
        font-size: 14px;
        line-height: 20px;
        color: #aaaaaa;
        text-align: left;
      }
    }
    > .uploadWrap1 {
      padding: 0;
      > :deep(.el-upload) {
        > div {
          // cursor: pointer;
          // background: #fff;
          width: 100%;
          height: 100%;
          padding: 2rem;
          box-sizing: border-box;
          border: 1px dashed #aaaaaa;
          border-radius: 10px;
          > .uploadImg {
            > div {
              margin-top: 0;
              // color: #4582ff;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              top: -10px;
              > div {
                color: #4582ff;
                font-weight: bold;
              }
              .and {
                color: #242831;
                margin: 0 1rem;
              }
              .link {
                cursor: pointer;
              }
            }
          }
          > .textTip {
            font-size: 14px;
            line-height: 20px;
            color: #aaaaaa;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
