/* eslint-disable */
const elLang = require('element-plus/lib/locale/lang/ko')
const daysLang = require('dayjs/locale/ko');

module.exports = {
    languageName: '한글.',
    ...elLang.default,
    ...daysLang.default,
    routers: {
        mainPage: "홈페이지",
        unauthorized: "접속 권한이 없습니다",
        settings: "시스템 설정",
        account: "내 계정",
        explore: "탐색",
        modelView: "뷰어",
        enterprise: "기업 사용자",
        myAccount: "내 계정",
        myTasks: "내 프로세스",
        webGl: "모델",
        taskDetail: "테스크 상세",
        accounts: "사용자 계정 관리",
        newAccount: "사용자 계정 생성",
        updateAccount: "사용자 계정 편집",
        dataCharts: "데이터 차트",
        auditProject: "프로젝트 감사",
        publishRoles: "금지된 내용",
    },
    store: {
        tip1: "암호의 보안성이 낮습니다.",
    },
    asset: {
        word1: "X축 정방향",
        word2: "X축 마이너스 방향",
        word3: "Y축 정방향",
        word4: "Y축 마이너스 방향",
        word5: "Z축 정방향",
        word6: "Z축 마이너스 방향",
        word7: "QR코드 위치 지정",
        word8: "물체 위치 지정",
        word9: "미터",
        word10: "데시미터",
        word11: "센티미터",
        word12: "전체",
        word13: "HoloLens",
        word14: "iOS",
        word15: "전체",
        word16: "제조 장비",
        word17: "연구 장비",
        word18: "의료 장비",
        word19: "기타",
        word20: "텍스트",
        word21: "멀티미디어",
        word22: "텍스트 + 멀티미디어",
        word23: "멀티미디어 + 멀티미디어",
        word24: "텍스트+멀티미디어+멀티미디어",
    },
    request: {
        tip1: "로그인 기한이 지났습니다. 다시 로그인하십시오.",
        tip2: "다시 로그인하십시오",
        tip3: "로그인 기한이 지났습니다. 다시 로그인하십시오.",
    },
    animation: {
        loading: "로딩 중",
    },
    modelLoading: {
        loading: "로딩 중",
    },
    createProject: {
        projectName: "명칭",
        enableOfflineMode: "오프라인 허락 여부",
        enableOfflineModeTip: "다운로드하여 오프라인으로 사용 허락",
        categoryId: "프로젝트 분류 선택",
        cover: "커버",
        uploadCover: "커버 업로드",
        positionMethods: "위치 지정 방식",
        baseModel: "기초 모형",
        canSelect: "옵션",
        noSelect: "필수 선택",
        uploadBaseModel: "기초 모델 업로드",
        hasModel: "(모델 이미 존재) 모델 커버 이미지를 생성하십시오.",
        tip1: "대상물의 QR코드 위치 이미지/영상",
        uploadPicAndVid: "참조 이미지/영상 업로드",
        cancel: "취소",
        confirm: "확인",
        tip2: "'기초 모델' 업로드",
        tip3: "디폴트 태스크",
        tip4: "위치 정보 저장 실패!",
        tip5: "생성 성공!",
        tip6: "디폴트 태스크 생성 실패합니다. 수동으로 생성하십시오.",
        projectLanguage: "프로젝트 언어 선택",
    },
    forgetPass: {
        tel: "전화 번호",
        newPass: "새 비밀번호",
        passTip: "비빌번호는 최소 8자리, 대소문자, 수자, 특수문자가 포함해야 됩니다.",
        repeat: "중복",
        authCode: "인증번호",
        getAuthCode: "인증번호를 요청하십시오",
        tip1: "핸드폰 번호가 연결 되지 않으면 관리자에게 연락하십시오.",
        next: "다음",
        tip2: "인증번호가 없습니다!",
        tip3: "비밀번호 수정",
        codeError: "인증번호가 틀렸습니다.",
        rePass: "두개의 비밀번호가 다릅니다.",
        telErr: "해당 핸드폰 번호가 등록되지 않습니다!",
        tip4: "두개의 비밀번호가 다릅니다!",
        newPassError1: "새 비밀번호가 없습니다.",
        newPassError2: "새 비밀번호의 규칙이 맞지 않습니다.",
        telNull: "핸드폰 번호가 없습니다.",
        telError: "핸드폰 번호가 맞지 않습니다.",
    },
    login: {
        username: "ID",
        password: "비밀번호",
        moUsername: "계정이 없습니까?",
        goRegiste: "등록",
        forgetPass: "비밀번호 찾기",
        login: "로그인",
        tip1: "ID 또는 비밀번호가 없습니다.",
        tip2: "사용자 ID 또는 비밀번호가 틀렸습니다.",
        tip3: "사용자가 권한이 없습니다.",
        tip4: "계정이 프리즈 상태입니다. 다시 해보십시오.",
        tip5: "알수 없는 오류",
    },
    regirte: {
        tel: "핸드폰 번호",
        password: "비밀번호",
        passwordRule: "비빌번호는 최소 8자리, 대소문자, 수자, 특수문자가 포함해야 됩니다",
        rePass: "중복",
        authCode: "인증번호",
        getAuthCode: "인증번호를 요청합니다.",
        hasUsername: "계정이 있습니까?",
        goLogin: "로그인",
        agree: "동의",
        agreeItem1: "《사용자 계약 ",
        agreeItem1Href1: "사용자 계약 ",
        and: "및 ",
        agreeItem1Href2: "개인 정보 보호 계약",
        agreeEnd: "》",
        agreeItem2: "제품 업데이트 정보 및 관련 정보 이메일을 수신 허락",
        registe: "등록",
        tip1: "인증번호가 없습니다.",
        tip2: "아래 동의서에 체크하십시오.",
        tip3: "등록 완료! 로그인하십시오.",
        tip4: "인증번호가 틀렸습니다.",
        tip5: "해당 핸드폰 번호가 이미 등록됐습니다.",
        tip6: "두개의 비밀번호가 다릅니다.",
        tip7: "비밀번호가 없습니다.",
        tip8: "비밀번호가 규칙이 맞지 않습니다.",
        tip9: "핸드폰 번호가 없습니다.",
        tip10: "핸드폰 번호가 맞지 않습니다.",
    },
    userAgreement: {
        title: "IN4U 사용자 동의서",
        close: "닫기",
    },
    resetPass: {
        resetResult: "비밀번호 재 설정",
        resetPasswordRes: "비밀번호 재 설정 성공.",
        go: "시작하기",
    },
    header: {
        explore: "검색",
        enterprisePage: "기업 사용자",
        download: "제품 다운로드",
        serchTip: "조회 내용을 입력하십시오.",
        login: "로그인",
        regist: "등록",
        createProject: "프로젝트 생성",
        myAccount: "내 계정",
        dataChart: "데이터 통계",
        myProject: "내 프로젝트",
        auditProject: "프로젝트 감사",
        logout: "로그아웃",
        forgetPass: "비밀번호 찾기",
        tips1: "핸드폰 번호 및 인증번호를 통해서 비밀번호 찾습니다.",
        twoD: "2D 프로세스",
        threeD: "3D 프로세스",
    },
    modelSetter: {
        model: "기초 모델",
        change: "변경",
        upload: "업로드",
        delete: "삭제",
        modelSize: "모델 사용 크기",
        gravityArrow: "모델 중력 방향",
        noModel: "기초 모델이 없습니다.",
        getCover: "기초 모델 커버 생성",
        qrcodeCover: "위치 참고도가 없습니다.",
        gwtQRCodeCover: "QR코드 위치 참고도를 생성합니다.",
        noModel: "업로드된 기초 모델이 없습니다.",
        cancel: "취소",
        ok: "확인",
        sizeError: "파일이 최대 크기가 초과했습니다.",
    },
    modelLoader: {
        modelPreview: "모델 미리보기",
        tip1: "마우스 오른쪽 버튼을 클릭하여 모델을 이동합니다.",
        tip2: "마우스 왼쪽 버튼을 클리하여 모델을 회전합니다.",
        tip3: "마우스 휠을 통해서 모델 크기를 조절합니다.",
        close: "닫기",
        ok: "확인",
    },
    pdfView: {
        pdfView: "PDF 미리보기",
        prevPage: "앞 페이지",
        nextPage: "다음 페이지",
        cancel: "취소",
        close: "닫기",
    },
    pcitureView: {
        title: "이미지 미리보기",
        cancel: "취소",
        close: "닫기",
    },
    videoView: {
        title: "영상 미리보기",
        cancel: "취소",
        close: "닫기",
    },
    enterprise: {
        word1: "신입 직원 교육",
        word2: "복잡한 비즈니스 프로세스가 간편해진다.",
        word3: "개인 무료 사용",
        word4: "기업 사용자 계획",
        word5: "기업 전용 XR 프로세스 교육 플랫폼",
        word6: "기술 지식 공유 및 교류",
        word7: "표준 비즈니스 프로세스를 편하게 생성",
        word8: "가상 및 현실  프로세스를 편하게 생성",
        word9: "자유",
        word10: "가볍다",
        word11: "다양한 XR 장비 지원",
        word12: "안경 장비가 글로벌 사용 가능",
        word13: "Hololens2",
        word14: "가상과 현실 결합 시각적으로 교육.",
        word15: "프로세스 문제, 바로 해결",
        word16: "걱정 없이",
        word17: "유창성",
        word18: "핸드폰 등 장비 AR으로 지원",
        word19: "언제든지 사용 가능",
        word20: "간편하다",
        word21: "iOS",
        word22: "Android",
        word23: "10w+ 소재 사용 가능",
        word24: "가상과 현실 결합 교육 프로세스를 간판하게 생성",
        word25: "셀프",
        word26: "분류",
        word27: "즐겨찾기",
        word28: "VR 장비 지원",
        word29: "가상 및 현실 전 프로세스 솔루션",
        word30: "VR",
        word31: "살아 있는 사용자 매뉴얼",
        word32: "신제품 출시에 대한 XR 프로세스 지도",
        word33: "바로 체험",
        word34: "수 많은 대기업의 인정",
        word35: "글로벌 많은 업종의 고객사한테 서비스 제공",
        word36: "더 자세한 정보가 저희한테 연락하십시오.",
        word37: "MR, AR, VR Multi 플랫폼 지원",
        word38: "합작 기업",
        word39: "Apple Vision Pro",
        word40: "Pico 4",
    },
    banner: {
        word1: "구간 창고 안에 저장(위험물 제외), 업무 구분 및 기술 지원 등",
        word2: "5G+MR 기술을 통해서 Vantage 설명하기 어려운 제품에 대해서 간편하게 설명, 세일즈와 고객 간의 켜뮤니게이션 비용을 절감한다.",
        word3: "개발, 디자인, 생산 등 관정의  계기판 제어, 시스템 제어",
        word4: "5G+MR 기술을 통해서 제품 디자인과 조립 과정 중에서 발생한 문제를 간편하게 해결한다. 해외 전문가와 실시간 켜뮤니게이션을 통해 현장의 문제를 쉽게 해결한다.",
        word5: "치약의 개발, 생산, 가공 및 제품 판매",
        word6: "MR 기술 기준으로 한 프로세스 교육이 더 직관적으로, 선명하게, 이해하기 쉽게 교육한다.",
        word7: "Roche Diagnostics",
        word8: "Emerson",
        word9: "Unilever",
    },
    form: {
        title: "제목",
        email: "이메일",
        tel: "전화번호",
        companyName: "회사 이름",
        label5: "관심이 있는 XR 제품 및 솔루션",
        submit: "제출",
        other: "기타",
        tip1: "이메일 제목을 입력하십시오",
        tip2: "이메일 주소를 입력하십시오",
        tip3: "전화번호를 입력하십시오",
        tip4: "이메일이 보내졌습니다. 빠른 시간 안에서 회신해드리겠습니다.",
        tip5: "결과 제출",
        tip6: "확인",
    },
    explore: {
        word1: "세상에 어려운 일이란 없다",
        word2: "가상과 현실 결합하여 업무 비즈니스와 지식을 생성, 관리, 공유한다",
        word3: "선택하십시오",
        word4: "서열 방식",
        word5: "건너 뛰기",
        word6: "현재 프로젝트가 없습니다.",
        word7: "전체 기간",
        word8: "오늘",
        word9: "이번 주",
        word10: "이번 달",
        word11: "구매 횟수",
        word12: "보기 횟수",
        word13: "좋아요 횟수",
        word14: "최신",
        word15: "전체 분류",
    },
    modelViewer: {
        modelLoadingTitle: "모델 로딩 중",
        modelCover: "모델 커버 설정",
        word1: "모델을 쉽게 볼 수 있는 방향으로 지정한다.",
        word2: "마우스 왼쪽 버튼을 클리하여 모델을 회전합니다.",
        word3: "마우스 오른쪽 버튼을 클릭하여 모델을 이동합니다.",
        word4: "마우스 휠을 통해서 모델 크기를 조절합니다.",
        ok: "확인",
        close: "닫기",
        modelDownloadError: "모델 다운로드 실패",
        modelOpenError: "모델 파일 로딩에 실패 했습니다. 모델을 다시 선택하십시오.",
    },
    accountInfo: {
        title: "비밀번호 재 설정",
        inputOldPassword: "현재 비밀번호를 입력하십시오.",
        inputNewPassword: "새 비밀번호를 입력하십시오.",
        reINputNewPassword: "새 비밀번호를 다시 입력하십시오.",
        submit: "수정 확인",
        tip1: "현재 비밀번호를 입력하십시오.",
        tip2: "새 비밀번호를 입력하십시오.",
        tip3: "문자 8자리 이상, 대소 문자, 숫자, 특수 문자를 포함합니다.",
        tip4: "새 비밀번호를 다시 입력하십시오.",
        tip5: "두 번 입력한 비밀번호가 다릅니다.",
        tip6: "수정 성공, 다시 로그인하십시오.",
        tip7: "현재 비밀번호가 틀립니다.",
    },
    avatar: {
        title: "프로필 이미지 편집",
        selectPicture: "프로필 이미지 선택",
        reSelect: "프로필 이미지 다시 선택",
        ok: "확인",
        back: "뒤로",
        tip1: "프로필 이미지 업로드",
        email: "이메일",
    },
    userInfo: {
        changeAvatar: "프로필 이미지 변경",
        username: "사용자 이름",
        tip: "(문자 최대 20자리)",
        description: "설명",
        update: "업데이트",
        tip1: "1~20개 문자",
        tip2: "저장 성공",
    },
    myAccount: {
        userInfo: "개인 정보",
        accountSetting: "계정 설정",
        tab1: "개인 정보 편집",
    },
    myTask: {
        editUserInfo: "개인 정보 편집",
        word1: "내 즐겨찾기 프로젝트：",
        word2: "보유한 프로젝트: ",
        word3: "생성된 프로젝트",
        word4: "게시한 프로젝트: ",
        word5: "즐겨찾기 취소",
        word6: "프로젝트 삭제",
        word7: "오프라인 버전",
        word8: "현재 프로젝트가 없습니다.",
        word9: "프로젝트 생성",
        word10: "오프라인 프로젝트",
        word11: "대기 중인 프로젝트:",
        word12: "현재 대기 중인 프로젝트가 없습니다",
        tip1: "삭제 후 해당 프로젝트는 복구 할 수 없습니다. 삭제하시겠습니까? ",
        tip2: "프로젝트 삭제",
        tip3: "삭제",
        tip4: "최소",
        tip5: "해당 프로젝트를 사용 허가 받은 사용자가 더 이상 사용 못합니다. 프로젝트를 게시를 취소 하시겠습니까?",
        tip6: "프로젝트 게시 취소",
        tip7: "게시 취소",
        tip8: "모든 버전 내리기",
        tip9: "버전 다운 후, 기존 사용자는 사용할 수 없습니다. 모든 버전이 다운됩니다",
        tip10: "하차됨",
        tip11: "하차 실패!",
        tip12: "목록 새로 고침 실패",
        tip13: "프로젝트가 아직 릴리즈되지 않은 버전",
    },
    assetPicker: {
        all: "전체 분류",
        model: "모델",
        picture: "이미지",
        video: "영상",
        PDF: "PDF",
        uploadAsset: "자산 업로드",
        delete: "삭제",
        noAsset: "현재 자산이 없습니다.",
        createProject: "프로젝트 생성",
        tip1: "해당 자산을 삭제하시겠습니까?",
        tip2: "자산 삭제",
        tip3: "확인",
        tip4: "취소",
        tip5: "삭제 성공",
        tip6: "해당 소재가 이미 다른 프로젝트에서 사용하고 있어서 삭제가 불가능합니다.",
        tip7: "알림",
        tip8: "강제 삭제",
        tip9: "취소",
        tip10: "삭제 성공",
    },
    createTask: {
        createTask: "태스크 생성",
        taskName: "프로세스 명",
        cancel: "취소",
        ok: "확인",
        tip1: "프로세스명을 입력하십시오.",
        tip2: "생성 완료",
        tip3: "생성 실패",
    },
    deleteProject: {
        title: "프로젝트 삭제",
        word1: "프로젝트 삭제후 복구 할 수 없습니다. 삭제하시겠습니까?",
        cancel: "취소",
        delete: "삭제",
        tip1: "삭제 성공",
        projectInfo: "프로젝트 설정",
    },
    editLink: {
        title: "연결 문자 내용 설정",
        placeholder: "설정 조건",
    },
    editStep: {
        word1: "프로젝트 속성",
        name: "명칭",
        description: "설명",
        assetList: "자산 라이브러리",
        word2: "연결 문자 내용 설정.",
        word3: "설정 조건",
        tip1: "단계 설명",
        templateSelect: "템플릿 선택",
    },
    historyEdition: {
        title: "이력 버전",
        addHistory: "이력 버전 추가",
        save: "수동 저장",
        word1: "편집 시 Ctrl+Alt+S 클릭해서 버전 저장합니다",
        word2: "내용을 입력하십시오.",
        word3: "리네임",
        word4: "해당 버전으로 복원한다",
        word5: "해당 버전을 삭제합니다.",
        saveAuto: "자동 저장",
        enterContent: "내용을 입력하십시오.",
        tip1: "진행 중 프로세스가 해당 버전으로 복원 합니다. 계속 하시겠습니까?",
        tip2: "알림",
        ok: "확인",
        cancel: "취소",
        tip3: "버전 이미 복원했습니다.",
        tip4: "버전 복원 실패합니다.",
        tip5: "해당 버전을 삭제하시겠습니까?",
        tip6: "알림",
        tip7: "삭제 성공",
    },
    printReview: {
        componyUrl: "workflow.in4ucloud.com",
        word1: "참고 이미지를 업로드 하지 않았습니다.",
        word2: "QR코드 참조 위치",
    },
    projectInfo: {
        title: "프로젝트 속성",
        name: "명칭",
        description: "설명",
        type: "프로젝트 타입 선택",
        enableOfflineMode: "오프라인 허락 여부",
        enableOfflineModeTip: "다운로드하여 오프라인으로 사용 허락",
        projectCover: "프로젝트 커버",
        // noCover: "커버가 없습니다.",
        noCover: "이미지의 제안은 640*360보다 크며, 배경은투명하지 않은 이미지를 사용 하고, 16 : 9가 아닌 이미지는 자동으로 변경 되고, 파일 포맷은 JPG/PNG/JPEG만 지원한다.",
        clickUpload: "업로드",
        positionType: "위치 확인 방식",
        baseModel: "기초 모델",
        word1: "(모델 존재)기초 모델 커버가 없습니다.",
        // word2: "기초 모델이 없습니다.",
        word2: "모델의 파일용량은 20Mb 이하, 파일 포맷은 FBX/GLB",
        word3: "QR코드 물체의 위치 이미지/영상",
        // word4: "참고 이미지가 없습니다.",
        word4: "QR 코드와 실물 비교용 이미지는 같은 화면에서 표시 되어야 한다. 이미지는 JPG 제안한다.",
        word5: "QR코드의 위치와 물체의 관계를 문자로 설명하십시오.",
        word6: "위치 관계를 설명하십시오.",
        word7: "모델 사용 크기",
        word8: "모델 중력 방향",
        word9: "Asset 라이브러리",
        word10: "'기초 모델' 삭제하시겠습니까?",
        word11: "기초 모델",
        delete: "삭제",
        cancel: "취소",
        tip1: "'QR코드 물체의 위치 이미지/영상' 삭제하십니까?",
        tip2: "QR코드 위치 정보",
        tip3: "삭제 실패",
        tip4: "파일 최대 크기 초과",
        tip5: "'프로젝트 커버'삭제하십니까?",
        tip6: "프로젝트 삭제",
        tip7: "해당 프로젝트를 사용 허가를 받았습니다. 내 프로젝트 -> 보유한 프로젝트에서 확인하십시오.",
        tip8: "추가 클라이언트",
        tip9: "Apple Vision Pro",
        projectLanguage: "프로젝트 언어 선택",
    },
    releaseProject: {
        title: "프로젝트 게시",
        word1: "해당 프로젝트를 게시하여 다른 사용자가(아래 선택한 권한 범위 안에서) 당신이 디자인한 프로세스로 진행합니다.",
        word2: "프로젝트를 게시 후에 프로세스/프로젝트를 수정 가능하며 수정하는 내용은 이미 게시한 프로젝트에 영향이 없습니다. 수정 완료 후 다시 발표할 수 있습니다.",
        word3: "권한 범위",
        word4: "모든 사람",
        word5: "내 기업만 (기업 사용자 전용)",
        word6: "취소",
        word7: "발표",
        word8: "프로젝트가 공식적으로 출시되기 전에는 백엔드 검토를 거쳐야 합니다. 만약",
        word9: "금지된 내용",
        word10: "이 발견된다면, 해당 프로젝트는 승인되지 않을 것입니다.",
        tip1: "기업 사용자 아닙니다. 선택할 수 없습니다.",
        tip2: "알림",
        tip3: "확인",
        tip4: "감사 제출",
        tip5: "심사 제출 실패",
        tip6: "프로젝트가 현재 심사 중입니다. '내 프로젝트 -> 심사 기록'을 확인하십시오.",
        auditTitle: "심사 제출",
        inputDes: "비고 입력",
        submit: "제출",
        tip7: "이 프로젝트는 심사 중입니다. 조금만 기다려주세요...",
        tip8: "발행 성공!",
        tip9: "발행 실패!",
    },
    share: {
        word1: "링크를 보유하는 사용자가 해당 프로젝트를 접속 가능합니다.",
        word2: "링크 복사",
    },
    taskChart: {
        inputStep: "입력 단계",
        taskStep: "작업 단계",
        word1: "선택",
        drag: "이동",
        word2: "사용 절차",
        word3: "판단 조건",
        newTask: "태스크 생성",
        select: "선택하십시오",
        saving: "저장 중",
        copy: "속성 복사",
        clone: "절차 복제",
        paste: "절차 붙여넣기",
        setStartStep: "시작 단계로 설정",
        delete: "삭제",
        tip1: "해당 프로세스에서 아무 Step이 없습니다. 저장하시겠습니까?",
        tip2: "알림",
        ok: "확인",
        cancel: "취소",
        saveSuccess: "저장 성공",
        saveError: "저장 실패",
        tip3: "'시작 단계', '태스크 절차' 복사가 불가능 합니다.",
        tip4: "자원 확보 실패, 복사 실패",
        tip5: "'시작 단계', '태스크 절차' 복사가 불가능 합니다.",
        tip6: "'시작 단계' 삭제 불가능 합니다. 다시 선택하십시오.",
        tip7: "리스트에서 복사한 절차를 삭제하면 다른 복사체도 같이 삭제 됩니다. 계속 삭제하시겠습니까?",
        tip8: "절차 삭제",
        tip9: "해당 절차가 관련한 복사체가 있습니다. 계속 진행하면 모든 복사체가 삭제 됩니다. 계속 진행하시겠습니까?",
        tip10: "일단 절차만 시작 단계로 설정 가능합니다.",
        tip11: "해당 절차가 '시작 단계'로 설정하면 해당 절차의 모든 복사체 삭제 됩니다. 계속 진행하시겠습니까?",
        tip12: "주의하십시오",
        tip13: "태스크 절차와 시작 단계가 복사 불가능합니다.",
        tip14: "시작 스텝 생성 실패! Task에 다시 해보세요.",
    },
    taskNewDatail: {
        back: "뒤로",
        save: "저장",
        history: "이력 버전",
        projectSetter: "프로젝트 설정",
        delete: "삭제",
        get: "확보",
        got: "보유",
        config: "Step 편집",
        preview: "Step 미리보기",
        release: "발표",
        share: "공유",
        task: "태스크",
        search: "조회",
        newTask: "태스크 생성",
        pleaseEnterContent: "내용을 입력하십시오.",
        moveUp: "위로",
        moveDown: "아래로",
        resetTaskName: "리네임",
        tip1: "알림",
        tip2: "저장하지 않은 프로세스 내용이 있습니다. 저장 하시겠습니까?",
        cancel: "취소",
        noSave: "저장 안 함",
        tip3: "해당 프로젝트 확보했습니다. 내 프로젝트 -> 보유한 프로젝트에서 확인하십시오.",
        tip4: "각 프로젝트에서 최소 태스크 하나 포함해야 됩니다.",
        tip5: "진행하고 있는 오퍼레이션이 해당 태스크를 삭제 합니다. 계속 진행 하시겠습니까?",
        tip6: "알림",
        ok: "확인",
        deleted: "삭제됨",
        tip7: "프로세스 안에서 저장하지 않은 내용이 있습니다. 저장 하시겠습니까?",
        tip8: "알림",
        tip9: "현재 태스크안에 추가한 절차를 먼저 저장하십시오.",
        tip10: "삭제 후 해당 프로젝트가 내리면서  회복 못합니다. 삭제하십니까? ",
        tip11: "프로젝트 삭제",
        tip12: "삭제 성공",
        toggleView: "보기 전환",
        tip13: "저장되지 않은 단계 구성 정보가 감지되었습니다. 단계를 전환하기 전에 수정 사항을 저장하시겠습니까?",
        tip14: "단계 전환",
    },
    uploadAsset: {
        title: "소재 업로드",
        uploadGile: "파일 업로드",
        fileName: "파일명",
        getCover: "화면 캡쳐",
        changeFile: "파일 교체",
        uploadThubnail: "썸네일 업로드",
        changeThubnail: "썸네일 교체",
        cancel: "취소",
        ok: "확인",
        tip1: "명칭을 입력하십시오.",
        tip2: "파일을 선택하십시오.",
        sizeError: "파일 최대 크기가 초과 했습니다.",
        uploadError: "파일 업로드 오류. 다시 하십시오.",
        pleaseSelectFile: "파일을 선택하십시오.",
        tip3: "업로드 성공",
        tip4: "업로드 영상은 MP4, ≥720P로 제안 하고, 이미지는 JPG/PNG/JPEG, ≥1080P로 제안합니다. PDF 파일 지원. 3D모델 파일은 FBX/GLB지원( Y축이 위로),50M 이하",
        tip5: "영상의 크기는 200M 이하로 제한. 기타 파일의 크기는 20M 이하로 제한.",
        tip6: "업로드하는 3D모델, 이미지, 영상 파일이 자동으로 미리보기 지원 한다. ",
        tip7: "수동 업로드 하는 이미지 크기가 20M 이하로 제안한다. 이미지는 JPG/PNG/JPEG만 지원한다. 1:1이 아닌 이미지는 자동 변경 된다. ",
        tip8: "이 항목은 3D 재료 유형을 지원하지 않습니다.",
    },
    webGL: {
        word1: "Step별 편집 공간 로딩 중 입니다.",
        word2: "미리보기 공간 입장 중입니다.",
        tip1: "저장되지 않은 콘텐츠가 감지되었습니다. 저장하시겠습니까?",
        tip2: "힌트",
        ok: "확인",
        cancel: "취소",
        tip3: "그림",
        tip4: "동영상",
        tip5: "단어",
        tip6: "콘텐츠 제목을 다시 입력하세요.",
        tip7: "사용자를 위해 여기에 입력",
        tip8: "템플릿을 비울 수 없습니다",
        save: "저장",
        noSave: "아니오",
        refStepNote: '현재 단계는 복제 단계로, 수정하면 본문 내용과 모든 클론이 수정됩니다.'
    },
    home: {
        word1: "세상에 어려운 일이란 없다",
        word2: "가상과 현실 결합의 새로운 방식으로 표준화 비즈니스를 창작, 관리, 공유하며 지식을 시각화합니다.",
        word3: "개인 무료 사용",
        word4: "기업 사용자 계획",
        word5: "기업 메타버스 프로세스 지도 플랫폼",
        word6: "XR(VR/AR/MR) 기술 기준으로 한 지식 공유 및 커뮤니케이션",
        word7: "표준화된 Workflow 가이드를 간편하게 생성",
        word8: "가상과 현실을 결합한 가이드 프로세스를 간편하게 생성",
        word9: "자유",
        word10: "가볍다",
        word11: "다양한 XR 장비 지원",
        word12: "글로벌 HMD 장비 사용 가능",
        word13: "Hololens2",
        word14: "가상과 현실을 결합 하여 직관적 시각 교육",
        word15: "프로세스, 문제점의 직관적 해결",
        word16: "걱정 없이",
        word17: "유창성",
        word18: "핸드폰 등 장비 AR으로 지원",
        word19: "언제든지 사용 가능",
        word20: "간편하다",
        word21: "iOS",
        word22: "Android",
        word23: "10w+ 소재 사용 가능",
        word24: "가상과 현실 결합 교육 프로세스를 간판하게 생성",
        word25: "셀프",
        word26: "분류",
        word27: "즐겨찾기",
        word28: "VR 장비 지원",
        word29: "가상 및 현실 전 프로세스 솔루션",
        word30: "VR",
        word31: "강력하고 편리한 편집 툴",
        word32: "다양한 플랫폼 지원 및 다양한 커뮤니케이션 방식의 창작",
        word33: "바로 체험",
        word34: "프로세스 및 지식을 공유한다",
        word35: "다양한 공유 방식",
        word36: "바로 가입",
        word37: "수 많은 대기업의 인정",
        word38: "글로벌 많은 업종의 고객사한테 서비스 제공",
        word39: "기업 사용자 전문 계획을 알아보기",
        word40: "다양한 클라이언트 플랫폼 지원",
        word41: "MR、AR、VR 다양한 플랫폰 클라이언트 지원",
        word42: "HoloLens2 클라이언트 다운로드",
        word43: "HoloLens",
        word44: "버전：4.0",
        word45: "2022-9-20",
        word46: "스토어 넘어가기",
        word47: "iOS 클라이언트 다운로드",
        word48: "Pico Neos 클라이언트 다운로드",
        word49: "Pico",
        word50: "Pico B-end 스토어로 이동하여 다운로드 및 설치하십시오.",
        word51: "Apple Vision Pro",
        word52: "Pico 4",
    },
    dataCharts: {
        myCreate: "생성됨",
        myUsed: "사용됨",
        word1: "프로젝트 생성 수량",
        word2: "총 사용 인원수",
        word3: "총 사용 횟수",
        word4: "총 사용 시간",
        word5: "총 좋아요 수",
        word6: "총 즐겨찾기 수",
        nodata: "데이터가 없음",
        createPro: "생성하기",
        goRelease: "탐색하기",
        word7: "프로젝트 명칭",
        word8: "실행 횟수",
        word9: "평균 시간",
        word10: "마지막 실행 시간",
        word11: "총 실행 프로젝트 수",
        word12: "총 실행 수",
    },
    createView: {
        projectName: "프로젝트 명칭",
        userCount: "사용자 수",
        word1: "사용 횟수",
        word2: "완료율",
        word3: "총 실행 시간(H)",
        word4: "평균 실행 시간(H)",
        word5: "리트윗 수",
        word6: "즐겨찾기 수",
        word7: "좋아요 수",
        word8: "버전 리스트",
    },
    exitChart: {
        word1: "Step 분석",
        word2: "횟수",
    },
    topDurationChart: {
        word1: "Step 실행 시간 분석",
        word2: "시간 (분)",
    },
    prevViewThumbnail: {
        word1: "미리보기 창",
        word2: "작업 단계를 입력하려면 두 번 클릭하십시오.",
    },
    inputTemplate: {
        word1: "여기에 콘텐츠 제목 입력",
        word2: "사용자를 위해 여기에 입력",
        word3: "그림",
        word4: "동영상",
        word5: "단어",
    },
    textVue: {
        word1: "내용을 입력해주세요",
        word2: "아직 아무것도 입력하지 않았습니다!",
    },
    mediaVue: {
        word1: "필사적으로로드...",
        word2: "업로드",
        word3: "또는",
        word4: "Asset 라이브러리",
        word5: "JPG/PNG/JPEG 형식의 이미지, MP4 형식의 동영상 및 PDF 파일을 업로드할 수 있으며 파일은 20M을 초과할 수 없습니다.",
        word6: "이 파일을 삭제하시겠습니까?",
        word7: "힌트",
        word8: "확인",
        word9: "취소",
        word10: "성공적으로 삭제되었습니다!",
        word11: "파일 크기가 한도(20MB)를 초과합니다.",
        word12: "파일 크기가 한도(200MB)를 초과함",
        word13: "현재 파일 형식이 지원되지 않습니다. 다시 업로드해 주세요.",
    },
    templateChose: {
        word1: "템플릿을 선택하세요.",
        word2: "템플릿 선택",
    },
    templateDialog: {
        word1: "템플릿 선택",
        word2: "취소",
        word3: "확인",
    },
    prevTemplate2D: {
        noDes: "아직 설명이 없습니다",
        word1: "점프 확인 여부?",
        tip: "힌트",
        word2: "도약",
        word3: "여기있어",
        media: {
            noUpload: "아직 업로드되지 않음",
        },
    },
    materialLibrary: {
        title: "소재 선택",
        cancal: "취소",
        submit: "확인",
    },
    myPublished: {
        all: "모두",
        enterpriceOnly: '기업 범위 공개',
        draft: "초안",
        unsubmitted: "커밋되지 않음",
        audit: "심사 대기",
        auditPass: "심사 통과",
        auditFail: "심사 거절",
        reSubmit: "재제출",
        reSubmitTip: "이 프로젝트를 재제출 하시겠습니까?",
        confirm: "확인",
        cancel: "취소",
        submited: "제출 됨",
        submitError: "제출 실패, 네트워크 또는 서비스를 확인하세요",
        auditting: "심사 중",
        noPass: "미통과",
        showReson: "사유 확인",
        withdraw: "철회",
        offline: "오프라인으로 전환",
        goEdit: "수정하러 가기",
        allProcess: "전체",
        pass: "통과됨",
        reason: "거절 사유",
        know: "확인",
        withdrawTip: "제출된 심사를 취소하시겠습니까?",
        deleteSuccess: "삭제 성공",
        deleteFail: "삭제 실패",
        version: "버전: ",
        nearlyTime: "최근 편집 시간: ",
        auditHistory: "심사 기록",
        deleteProject: "프로젝트 삭제",
        edit: "편집",
        offlineTip: "오프라인",
        viewCurrentVersion: "현재 버전",
        oldProjectName: "이전 이름",
        deleteFromDrafts: "초안함에서 삭제",
    },
    cancelPublish: {
        title: "하향",
        coTitle: "선택 범위",
        currentVersion: "현재 버전 하향",
        currentVersionDescription: "해당 버전을 이미 획득한 사용자는 더 이상 이 버전을 사용할 수 없습니다. 이전 버전이 있는 경우 이전 버전을 계속 사용할 수 있습니다.",
        allVersions: "전체 프로젝트 하향",
        allVersionDescription: "해당 버전을 이미 획득한 사용자는 더 이상 이 버전을 사용할 수 없습니다. 모든 버전(검토 중인 버전 포함)이 하향됩니다.",
        confirm: "하향",
        cancel: "취소",
    },
    auditProject: {
        auditProject: "심사 대기 프로젝트",
        pass: "통과",
        reject: "거절",
        allProcess: "전체 프로세스",
        auditting: "심사 중",
        noPass: "미통과",
        passed: "통과됨",
        reviewResultCommitted: "심사 결과가 성공적으로 제출되었습니다",
        reviewResultCommitFailed: "심사 결과 제출에 실패하였습니다",
        confirm: "확인",
        cancel: "취소",
        tip3: "위반 사유 입력",
        errorTip: "내용을 비울 수 없습니다",
        tip4: "심사 결과 제출됨",
        showRemarks: "비고 보기",
        tip5: "알림",
        tip6: "이 프로젝트의 심사를 통과하시겠습니까?",
    },
    auditHistory: {
        auditLog: "심사 기록",
        projectName: "프로젝트명",
        version: "버전: ",
        auditTime: "심사 시간: ",
        submitRemarks: "비고 제출",
        reason: "거부 이유",
        word1: "심사 기록 없음",
        submitTime: "제출 시간: ",
        withdrawTime: "철회 시간: ",
    },
    longPolling: {
        logoutTip: "로그아웃 알림",
        logoutContent: "다른 기기에서 계정이 로그인되어 로그아웃되었습니다.",
        close: "닫기",
    },
}