import { createRouter, createWebHashHistory } from "vue-router";
import Layout from "@/views/Layout.vue";
import Login from "@/views/Login.vue";
import Home from "@/views/Home.vue";
import Unauthorized from "@/views/Unauthorized.vue";

// 账号管理
import AccountIndex from "@/views/account/index.vue";
import AccountUpsert from "@/views/account/upsert.vue";
// 用户信息
// import UserAccount from "@/views/settings/index.vue";
//视图中心
// import MainView from "../views/viewCenter/mainView.vue";

import { template } from "lodash";

//路由模块化
import workflowRouters from './modules/workflow.js';
const routes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/",
    component: Layout,
    children: [
      {
        name: "home",
        path: "/",
        component: Home,
        meta: {
          // 首页
          name: "routers.mainPage",
          icon: "el-icon-s-home",
          isVisible: true,
          showHeader: true,
          isParent: true,
          hasChildren: false,
          permissions: [1, 2, 3, 4, 5, 6, 7],
        },
      },
      {
        name: "unauthorized",
        path: "/unauthorized",
        component: Unauthorized,
        meta: {
          // 无权限访问
          name: "routers.unauthorized",
          parent: "/",
          hasChildren: false,
          permissions: [],
        },
      },
      /*
       * 系统主菜单
       */
      // {
      //   name: "system-group",
      //   path: "/group-system",
      //   meta: {
      //     // 系统设置
      //     name: "routers.settings",
      //     isVisible: true,
      //     breadcrumbIgnore: true,
      //     icon: "el-icon-setting",
      //     parent: "/",
      //     isParent: true,
      //     hasChildren: true,
      //     permissions: [1, 2, 3, 4, 5, 6, 7],
      //   },
      // },
      // {
      //   name: "settings-account",
      //   path: "/settings/index",
      //   component: UserAccount,
      //   meta: {
      //     // 我的账号
      //     name: "routers.account",
      //     isVisible: true,
      //     icon: "el-icon-user",
      //     parent: "/group-system",
      //     activePath: "/settings/index",
      //     permissions: [1, 2, 3, 4, 5, 6, 7],
      //   },
      // },
      /*
       * 账号
       */
      // {
      //   name: "account",
      //   path: "/account",
      //   component: AccountIndex,
      //   meta: {
      //     name: "用户账号管理",
      //     isVisible: true,
      //     icon: "el-icon-user-solid",
      //     parent: "/group-system",
      //     activePath: "/account",
      //     permissions: [1, 2],
      //   },
      // },
      // {
      //   name: "account-create",
      //   path: "/account/new",
      //   component: AccountUpsert,
      //   meta: {
      //     name: "新建用户账号",
      //     parent: "/account",
      //     activePath: "/account",
      //     permissions: [1, 2],
      //   },
      // },
      // {
      //   name: "account-update",
      //   path: "/account/:id",
      //   component: AccountUpsert,
      //   meta: {
      //     name: "编辑用户账号",
      //     parent: "/account",
      //     activePath: "/account",
      //     permissions: [1, 2],
      //   },
      // },
      //视图中心
      // {
      //   name: "view-center",
      //   path: "/viewCenter",
      //   meta: {
      //     // 视图中心
      //     name: "routers.modelView",
      //     isVisible: true,
      //     breadcrumbIgnore: true,
      //     icon: "el-icon-view",
      //     parent: "/",
      //     isParent: true,
      //     hasChildren: true,
      //     permissions: [1, 2, 3, 4, 5, 6, 7],
      //   },
      // },

      //主视图
      // {
      //   name: "main-view",
      //   path: "/mainView",
      //   component: MainView,
      //   meta: {
      //     name: "主视图",
      //     isVisible: true,
      //     icon: "el-icon-user-solid",
      //     parent: "/viewCenter",
      //     activePath: "/mainView",
      //     permissions: [1, 2, 3, 4, 5, 6, 7],
      //   },
      // },
      ...workflowRouters
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
