<template>
  <div class="loginWarp">
    <!-- <div class="login">密码登录</div> -->
    <!-- <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="0px"
      class="demo-ruleForm"
    >
      <el-form-item prop="username">
        <el-input
          size="small"
          style="width: 20rem"
          v-model="form.username"
          autocomplete="on"
        >
          <template #prepend>账号</template>
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          autocomplete="on"
          size="small"
          style="width: 20rem"
          :show-password="false"
          v-model="form.password"
          @keyup.enter="login"
          :type="[flag ? 'text' : 'password']"
        >
          <template #suffix>
            <img
              v-if="flag"
              @click="flag = !flag"
              src="@/assets/login/eyes.png"
              alt=""
            />
            <img
              v-if="!flag"
              @click="flag = !flag"
              src="@/assets/login/noEye.png"
              alt=""
            />
          </template>
          <template #prepend>密码</template>
        </el-input>
      </el-form-item>
    </el-form> -->
    <div class="myForm">
      <el-input
        size="small"
        style="width: 20rem"
        v-model="form.username"
        autocomplete="on"
      >
      <!-- 账号 -->
        <template #prepend>{{$t("login.username")}}</template>
      </el-input>
    </div>
    <div class="myForm">
      <el-input
        autocomplete="on"
        size="small"
        style="width: 20rem"
        :show-password="false"
        v-model="form.password"
        @keyup.enter="login"
        :type="[flag ? 'text' : 'password']"
      >
        <template #suffix>
          <img
            v-if="flag"
            @click="flag = !flag"
            src="@/assets/login/eyes.png"
            alt=""
          />
          <img
            v-if="!flag"
            @click="flag = !flag"
            src="@/assets/login/noEye.png"
            alt=""
          />
        </template>
        <!-- 密码 -->
        <template #prepend>{{$t("login.password")}}</template>
      </el-input>
    </div>
    <div class="noUsername">
      <div>
        <!-- 还没有账号？去注册 -->
        {{$t("login.moUsername")}}<span @click="registerClick">{{$t("login.goRegiste")}}</span>
        <!-- 忘记密码 -->
        <span @click="forgetPass" class="forgetPass">{{$t("login.forgetPass")}}</span>
      </div>
    </div>
    <div class="tip">{{ submitTip }}</div>
    <div class="btnWarp">
      <button class="loginBtn" @click="login">
        <!-- 登录 -->
        <span v-if="!loading">{{$t("login.login")}}</span
        ><i v-if="loading" class="el-icon-loading"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      flag: false,
      form: {
        username: "",
        password: "",
      },
      pageSwitch: false,
      submitTip: "",
      loading: false,
    };
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    forgetPass() {
      // console.log("忘记密码");
      this.$emit("goForgetPass");
    },
    async login() {
      if (this.form.username === "" || this.form.password === "") {
        // 账号或密码不能为空
        this.submitTip = this.$t('login.tip1');
        return;
      }
      // else if (this.form.password.length < 5) {
      //   this.submitTip = "密码长度不符合要求";
      //   return;
      // }
      else {
        this.submitTip = "";
        //登录
        try {
          this.loading = true;
          await this.$store.dispatch("login", this.form);
          console.log(this.$store.state);
          this.$emit("refreshList");
          if (this.$store.state.TaskDetail.cacheShareUrl) {
            this.$router.push(this.$store.state.TaskDetail.cacheShareUrl);
            this.$store.commit("SET_CACHESHAREURL", null);
          }
          this.form.username = null;
          this.form.password = null;
          this.loading = false;
        } catch (e) {
          console.log(e);
          if (e && e.message) {
            let errorCode = e.code;
            if (errorCode == 40104) {
              // this.errorMessage = this.$t("login.tip1");
              // 用户名或密码错误
              this.submitTip = this.$t('login.tip2');
            } else if (errorCode == 40105) {
              // 用户无使用本应用的权限
              this.submitTip = this.$t('login.tip3');
              // this.errorMessage = this.$t("login.tip2");
            } else if (errorCode == 40101) {
              this.errorMessage = e.message;
            } else if (errorCode == 40199) {
              // console.log(this.loginForm);
              // console.log(e);
              // this.$alert(this.$t("login.loginTip5"), this.$t("login.tip6"), {
              // confirmButtonText: this.$t("login.submit"),
              // callback: (action) => {
              //   setToken(e.accessToken);
              //   this.resetPassword = true;
              // },
              // });
              this.loading = false;
              return;
            } else if (errorCode == 40110) {
              // 账号已被冻结一段时间，请稍后重试
              this.submitTip = this.$t('login.tip4');
            }
          } else {
            // 未知错误
            this.submitTip = this.$t('login.tip5');
          }

          this.loading = false;
        }

        // this.$store.dispatch('getInfo')
      }
    },
    registerClick() {
      this.$emit("goRegister", true);
    },
    init(bool) {
      this.pageSwitch = bool;
    },
    toggleFun(bool) {
      this.pageSwitch = bool;
    },
  },
};
</script>
<style scoped lang='scss'>
.login {
  text-align: center;
  line-height: 20px;
  margin-bottom: 1rem;
}
.tip {
  color: #f55;
  text-align: center;
  line-height: 18px;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  border-bottom: 1px solid #efefef;
  margin-bottom: 1.5rem;
}
// .loginWarp{
//   padding-top: 2rem;
// }
.myForm {
  height: 3rem;
  :deep(.el-input) {
    .el-input-group__prepend {
      width: 55px;
    }
  }
}
.btnWarp {
  text-align: right;
}
.loginBtn {
  width: 120px;
  height: 32px;
  background: #4582ff;
  border-radius: 4px 4px 4px 4px;
  outline: none;
  border: none;
  background: #4582ff;
  color: #fff;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  margin-right: 1.5rem;
  transition: background 0.1s;
}
.loginBtn:hover {
  background: #8fb5ff;
}
// .loginBtn:hover{

// }
.noUsername {
  // margin-top: 1rem;
  color: #ccc;
  display: flex;
  justify-content: center;
  div {
    width: 20rem;
    text-align: left;
    .forgetPass {
      float: right;
      margin-top: 1rem;
    }
    span {
      color: #81aaff;
      cursor: pointer;
    }
    span:hover {
      text-decoration: underline 1px;
    }
  }
}

:deep(.el-input__suffix) {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  .el-input__suffix-inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>