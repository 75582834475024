<template>
  <div class="templateEditWrap">
    <div class="templateEditBody">
      <div class="templateEditHeader">
        <div class="btnWrap">
          <button class="backBtn" @click="backTop">
            <img src="@/assets/task-detail/back.png" alt="" />
          </button>
          <button :class="showMap ? 'map active' : 'map'" @click="toggleMap">
            <img src="@/assets/task-detail/showMap.png" alt="" />
          </button>
          <div class="backBtn">
            {{ taskInfo?.taskName }}
          </div>
        </div>
        <div class="stepNameWrap" v-if="!showMap">
          <el-tooltip
            :manual="true"
            class="item"
            effect="light"
            :disabled="relationship.prev.length <= 1"
            v-model="showPrev"
            placement="left"
          >
            <template #content>
              <div class="itemWrap">
                <div
                  class="item"
                  v-for="(item, index) in relationship.prev"
                  :key="index"
                >
                  <button @click="goItemByNodeInfo(item)" class="itemBtn">
                    {{ item?.text?.value }}
                  </button>
                </div>
              </div>
            </template>
            <button
              @click="prevStep"
              :disabled="relationship.prev.length <= 0"
              class="front"
            >
              <img src="@/assets/task-detail/front.png" alt="" />
            </button>
          </el-tooltip>

          <span>{{ selectStep?.properties?.stepInfo?.stepName }}</span>
          <el-tooltip
            :manual="true"
            v-model="showNext"
            class="item"
            effect="light"
            :disabled="relationship.next.length <= 1"
            placement="right"
          >
            <template #content>
              <div class="itemWrap">
                <div
                  class="item"
                  v-for="(item, index) in relationship.next"
                  :key="index"
                >
                  <button @click="goItemByEdgeInfo(item)" class="itemBtn">
                    {{ item?.text?.value }}
                  </button>
                </div>
              </div>
            </template>
            <button
              @click="nextStep"
              :disabled="relationship.next.length <= 0"
              class="nextStep"
            >
              <img src="@/assets/task-detail/next.png" alt="" />
            </button>
          </el-tooltip>
        </div>
        <div class="placeholde"></div>
      </div>
      <div class="templateEdit">
        <div :class="showMap ? 'mapWrap mapWrapActive' : 'mapWrap'">
          <Logicflow2d
            v-if="projectInfo"
            :projectInfo="projectInfo"
            :taskGuid="selectTaskGuid"
            @closeMap="closeMap"
            @onLogicFlowLoaded="onLogicFlowLoaded"
            @sendData="sendData"
            ref="logicflow2d"
            class="logicflow2d"
          />
        </div>
        <div
          :class="showMap ? 'placeholder placeholderActive' : 'placeholder'"
        ></div>
        <div
          id="baseDom"
          :class="
            showMap ? 'bodyContainer' : 'bodyContainer bodyContainerNoOpenMap'
          "
        >
          <div class="bodyPosition">
            <div class="descriptionWrap">
              <div class="header" v-if="showMap">
                <div class="buttonWraps">
                  <el-tooltip
                    :manual="true"
                    class="item"
                    effect="light"
                    :disabled="relationship.prev.length <= 1"
                    v-model="showPrev"
                    placement="left"
                  >
                    <template #content>
                      <div class="itemWrap">
                        <div
                          class="item"
                          v-for="(item, index) in relationship.prev"
                          :key="index"
                        >
                          <button
                            @click="goItemByNodeInfo(item)"
                            class="itemBtn"
                          >
                            {{ item?.text?.value }}
                          </button>
                        </div>
                      </div>
                    </template>
                    <button
                      @click="prevStep"
                      :disabled="relationship.prev.length <= 0"
                      class="front"
                    >
                      <img src="@/assets/task-detail/front.png" alt="" />
                    </button>
                  </el-tooltip>

                  <span>{{ selectStep?.properties?.stepInfo?.stepName }}</span>
                  <el-tooltip
                    :manual="true"
                    v-model="showNext"
                    class="item"
                    effect="light"
                    :disabled="relationship.next.length <= 1"
                    placement="right"
                  >
                    <template #content>
                      <div class="itemWrap">
                        <div
                          class="item"
                          v-for="(item, index) in relationship.next"
                          :key="index"
                        >
                          <button
                            @click="goItemByEdgeInfo(item)"
                            class="itemBtn"
                          >
                            {{ item?.text?.value }}
                          </button>
                        </div>
                      </div>
                    </template>
                    <button
                      @click="nextStep"
                      :disabled="relationship.next.length <= 0"
                      class="nextStep"
                    >
                      <img src="@/assets/task-detail/next.png" alt="" />
                    </button>
                  </el-tooltip>
                </div>
              </div>
              <!-- 暂无描述 -->
              <!-- <div class="textWrap">
                <div :style="textDivStyle">
                  <div class="textContainer">
                    
                    {{
                      selectStep?.properties?.stepInfo?.description ||
                        $t("prevTemplate2D.noDes")
                    }}
                  </div>
                </div>
              </div> -->
            </div>
            <div class="bodyWrap" id="baseDom1">
              <div
                class="body"
                :style="{ width: maxWidth + 'px', height: maxHeight + 'px' }"
              >
                <Text
                  :data="stepObjectJson"
                  v-if="selectStepInfo && selectStepInfo.panelType === 1"
                />
                <Media
                  :data="stepObjectJson"
                  v-if="
                    selectStepInfo &&
                      selectStepInfo.panelType === 0 &&
                      selectStep?.type !== 'InputShape' &&
                      selectStep?.type !== 'CloneInputShape'
                  "
                />
                <MediaText
                  :data="stepObjectJson"
                  v-if="selectStepInfo && selectStepInfo.panelType === 3"
                />
                <MediaMedia
                  :data="stepObjectJson"
                  v-if="selectStepInfo && selectStepInfo.panelType === 2"
                />
                <TextMediaMedia
                  :data="stepObjectJson"
                  v-if="selectStepInfo && selectStepInfo.panelType === 4"
                />
                <Input
                  ref="input"
                  :data="stepObjectJson"
                  v-if="
                    (selectStepInfo && selectStepInfo.panelType === 5) ||
                      selectStep?.type === 'InputShape' ||
                      selectStep?.type === 'CloneInputShape'
                  "
                />
                <div
                  v-if="
                    selectStepInfo &&
                      selectStepInfo.panelType === pointerPaelType
                  "
                  class="pointStepTip"
                >
                  <!-- 你选的是任务步骤 -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Text from "./components/text.vue";
import Media from "./components/media.vue";
import MediaText from "./components/mediaText.vue";
import MediaMedia from "./components/mediaMedia.vue";
import TextMediaMedia from "./components/textMediaMedia.vue";
import Input from "./components/input.vue";
import Logicflow2d from "@/views/taskDetail/components/logicflowChart/logicflow_2d.vue";
import {
  getTaskById,
  getProjectTasks,
  getReleaseById,
  getProjectById,
  getStepInfo,
  getAuditById,
} from "@/api/workflow.js";
import {
  getSelectedGuid,
  setSelectedGuid,
  removeSelectedGuid,
} from "@/views/taskDetail/components/taskChart/setSelectShape.js";
export default {
  components: {
    Logicflow2d,
    Text,
    Media,
    MediaText,
    MediaMedia,
    TextMediaMedia,
    Input,
  },
  props: {},
  data() {
    return {
      pointerPaelType: 99,
      showPrev: false,
      showNext: false,
      showMap: false,
      projectInfo: null,
      taskInfo: null,
      selectTaskGuid: null,
      maxWidth: 1280,
      maxHeight: 720,
      selectStepInfo: null,
      selectStep: null,
      relationship: {
        prev: [],
        next: [],
      },
    };
  },
  computed: {
    textDivStyle() {
      if (this.showMap) {
        return {
          height: "calc(100% - 3rem)",
        };
      } else {
        return {
          height: "100%",
        };
      }
    },
    stepObjectJson() {
      if (this.selectStepInfo?.stepObjectJson) {
        return JSON.parse(this.selectStepInfo.stepObjectJson);
      }
      return null;
    },
  },
  async created() {
    if (this.$route.query.auditReleaseGuid) {
      // let res = await getAuditById(this.$route.query.auditReleaseGuid);
      // if (res.code == 200) {
      //   this.projectInfo = res.result;
      // }
      this.projectInfo = await getReleaseById(this.$route.query.auditReleaseGuid);
    } else if (this.$route.query.releaseGuid) {
      this.projectInfo = await getReleaseById(this.$route.query.releaseGuid);
    } else {
      this.projectInfo = await getProjectById(this.$route.query.projectGuid);
    }
    this.selectTaskGuid = this.$route.query.taskGuid;
    // this.init(this.$route.query.taskGuid);
  },
  mounted() {
    this.resize();
    // let dom = document.getElementById("defaultTemplateWrap");
    window.addEventListener("resize", this.resize);
  },
  unmounted() {
    window.removeEventListener("resize", this.resize);
  },
  watch: {
    relationship: {
      handler(newVal, oldVal) {
        if (newVal.prev.length <= 1) {
          this.showPrev = false;
        }
        if (newVal.next.length <= 1) {
          this.showNext = false;
        }
      },
      immediate: true,
      deep: true,
    },
    selectStepInfo: {
      handler(newVal, oldVal) {
        console.log(newVal);
        console.log(this.selectStep);
      },
      immediate: true,
      deep: true,
    },
    showMap(newVale, oldVal) {
      console.log("切换了");
      setTimeout(() => {
        this.resize();
      }, 400);
    },
  },
  methods: {
    goItemByEdgeInfo(item) {
      let targetNode = item.targetNodeId;
      this.loadStepInfo(targetNode);
    },
    // 上一步切换
    goItemByNodeInfo(item) {
      this.loadStepInfo(item.id);
    },
    //上一步
    prevStep() {
      if (
        // this.selectStep.type !== "judge" &&
        // this.selectStep.type !== "CloneJudge"
        this.relationship.prev.length === 1
      ) {
        //线性步骤 不存在判断步骤 不存在分叉路经
        console.log(this.relationship.prev[0]);
        let prevNodeID = this.relationship.prev[0].id;

        console.log(prevNodeID);
        this.loadStepInfo(prevNodeID);
      } else {
        //非线性步骤 存在多个"上一步"
        this.showPrev = !this.showPrev;
      }
    },
    //下一步
    nextStep() {
      if (
        // this.selectStep.type !== "judge" &&
        // this.selectStep.type !== "CloneJudge"
        this.relationship.next.length === 1
      ) {
        //线性步骤 不存在判断步骤 不存在分叉路经
        // console.log(this.relationship.prev[0]);
        let nextNodeID = this.relationship.next[0].targetNodeId;
        let selectNode = this.$refs.logicflow2d.apiGetNodeById(nextNodeID);
        if (selectNode.type === "pointer") {
          //下一步是任务步骤
          this.$router.replace({
            path: "/prevTemplate2D",
            query: {
              ...this.$route.query,
              taskGuid: selectNode?.properties?.stepInfo?.refTaskGuid,
            },
          });
          removeSelectedGuid();
          this.selectTaskGuid = selectNode?.properties?.stepInfo?.refTaskGuid;

          // this.onLogicFlowLoaded();
        } else {
          this.loadStepInfo(nextNodeID);
        }
        console.log(nextNodeID);
      } else {
        //非线性步骤 存在判断步骤 存在分叉路经
        this.showNext = !this.showNext;
      }
    },
    //某个步骤被选中
    sendData(data) {
      console.log(data);
      this.loadStepInfo(data);
    },
    //流程图已经加载完毕的回调
    async onLogicFlowLoaded() {
      console.log("加载完毕");
      // if (this.$route.query.releaseGuid) {
      //   this.projectInfo = await getReleaseById(this.$route.query.releaseGuid);
      // } else {
      //   this.projectInfo = await getProjectById(this.$route.query.projectGuid);
      // }
      this.init(this.$route.query.taskGuid);
    },
    async init(taskGuid) {
      console.log(taskGuid);
      console.log(this.$route.query.auditReleaseGuid);
      if (this.$route.query.auditReleaseGuid) {
        this.taskInfo = await getTaskById(
          taskGuid,
          this.$route.query.auditReleaseGuid
        );
      } else {
        this.taskInfo = await getTaskById(
          taskGuid,
          this.projectInfo.releaseGuid
        );
      }

      let selectNodeGuid = getSelectedGuid();

      console.log('selectNodeGuid', selectNodeGuid)

      if (selectNodeGuid) {
        //如果有已选中的步骤，就在流程图上选中，并且获取该步骤对应的stepInfo
        let selectNodeInfo = this.$refs.logicflow2d.apiGetNodeById(
          selectNodeGuid
        );
        console.log(selectNodeInfo);
        this.$refs.logicflow2d.apiSelectById(selectNodeGuid);
        this.relationship = this.$refs.logicflow2d.apiGetEdgeByNodeId(
          selectNodeGuid
        );
        let selectNode = this.$refs.logicflow2d.apiGetNodeById(selectNodeGuid);
        this.selectStep = selectNode;
        if (selectNode.type != "pointer") {
          try {
            let res = null;
            if (selectNode.type === "CloneInputShape") {
              res = await getStepInfo(
                selectNode.properties.stepInfo.sourceStepGuid
              );
            } else {
              res = await getStepInfo(selectNodeGuid);
            }
            console.log(res);
            this.selectStepInfo = res;
          } catch (e) {
            console.log(e);
            this.selectStepInfo = {
              appearType: 0,
              delaySeconds: 0,
              index: 0,
              panelType: 0,
              stepExtend: "",
              stepObjectJson: null,
            };
          }
        } else {
          this.selectStepInfo = {
            appearType: 0,
            delaySeconds: 0,
            index: 0,
            panelType: this.pointerPaelType,
            stepExtend: "",
            stepObjectJson: null,
          };
        }
      } else {
        //如果没有选中的步骤，就寻找开始步骤
        let startNode = this.$refs.logicflow2d.apiGetStartNodeGuid();
        //选中开始步骤
        console.log('try to find start node', startNode);
        this.$refs.logicflow2d.apiSelectById(startNode.id);
        this.relationship = this.$refs.logicflow2d.apiGetEdgeByNodeId(
          startNode.id
        );
        let selectNode = this.$refs.logicflow2d.apiGetNodeById(startNode.id);
        this.selectStep = selectNode;
        //获取开始步骤对应的stepInfo
        if (selectNode.type != "pointer") {
          try {
            let res = await getStepInfo(startNode.id);
            console.log(res);
            this.selectStepInfo = res;
          } catch (e) {
            this.selectStepInfo = {
              appearType: 0,
              delaySeconds: 0,
              index: 0,
              panelType: 0,
              stepExtend: "",
              stepObjectJson: null,
            };
          }
        } else {
          this.selectStepInfo = {
            appearType: 0,
            delaySeconds: 0,
            index: 0,
            panelType: this.pointerPaelType,
            stepExtend: "",
            stepObjectJson: null,
          };
        }
      }
      console.log(this.selectStepInfo);
    },
    async loadStepInfo(stepGuid) {
      let selectNode = this.$refs.logicflow2d.apiGetNodeById(stepGuid);
      this.selectStep = selectNode;
      this.$refs.logicflow2d.apiSelectById(stepGuid);
      this.relationship = this.$refs.logicflow2d.apiGetEdgeByNodeId(stepGuid);
      console.log(this.relationship);
      console.log(this.selectStep);
      this.loadAssets(stepGuid);
      if (this.selectStep.type === "pointer") {
        //点击的是任务步骤 弹出框 提示即将跳到该任务中
        // 即将跳转到该任务中，是否确认跳转?", "提示"
        this.$confirm(
          this.$t("prevTemplate2D.word1"),
          this.$t("prevTemplate2D.tip"),
          {
            // 跳转
            confirmButtonText: this.$t("prevTemplate2D.word2"),
            // 留在此处
            cancelButtonText: this.$t("prevTemplate2D.word3"),
          }
        )
          .then(() => {
            this.$router.replace({
              path: "/prevTemplate2D",
              query: {
                ...this.$route.query,
                taskGuid: selectNode?.properties?.stepInfo?.refTaskGuid,
              },
            });
            removeSelectedGuid();
            this.selectTaskGuid = selectNode?.properties?.stepInfo?.refTaskGuid;
          })
          .catch(async () => {
            let res = this.$refs.logicflow2d.getSelectNodeFun();
            // this.loadStepInfo(res.prevSelectNodeId);
            console.log(res);
            let selectNode = this.$refs.logicflow2d.apiGetNodeById(
              res.nowSelectNodeId
            );
            this.selectStep = selectNode;
            // this.loadStepInfo(res.prevSelectNodeId);
            this.$refs.logicflow2d.apiSelectById(res.nowSelectNodeId);
            this.relationship = this.$refs.logicflow2d.apiGetEdgeByNodeId(
              res.nowSelectNodeId
            );
            // this.$refs.logicflow2d.getSelectNodeFun(res.nowSelectNodeId, null);
            this.loadAssets(stepGuid);
            setSelectedGuid(res.nowSelectNodeId);
            return;
          });
        return;
      }
    },
    async loadAssets(stepGuid) {
      try {
        let res;
        if (this.selectStep.properties.stepInfo.sourceStepGuid) {
          res = await getStepInfo(
            this.selectStep.properties.stepInfo.sourceStepGuid
          );
        } else {
          res = await getStepInfo(stepGuid);
          console.log(res);
        }
        if (this.selectStep.type === "pointer") {
          this.selectStepInfo = {
            appearType: 0,
            delaySeconds: 0,
            index: 0,
            panelType: this.pointerPaelType,
            stepExtend: "",
            stepObjectJson: null,
          };
        } else {
          this.selectStepInfo = res;
        }
      } catch (e) {
        console.log(e);
        this.selectStepInfo = {
          appearType: 0,
          delaySeconds: 0,
          index: 0,
          panelType: 0,
          stepExtend: "",
          stepObjectJson: null,
        };
        if (this.selectStep.type === "pointer") {
          this.selectStepInfo.panelType = this.pointerPaelType;
          this.selectStepInfo.stepObjectJson = null;
        }
      }
    },
    backTop() {
      this.$router.go(-1);
    },
    closeMap() {
      this.showMap = false;
    },
    resize() {
      let dom1 = document.getElementById("baseDom1");
      //用外边框的宽计算高
      let shouldHeight = dom1.offsetWidth * 0.5625;
      if (shouldHeight > dom1.offsetHeight) {
        this.maxHeight = dom1.offsetHeight;
        this.maxWidth = this.maxHeight / 0.5625;
      } else {
        this.maxWidth = dom1.offsetWidth;
        this.maxHeight = this.maxWidth * 0.5625;
      }
      console.log(this.selectStepInfo);
      if (this.selectStepInfo?.panelType == 5) {
        this.$nextTick(() => {
          setTimeout(() => {
            //动画过渡用时0.3s
            this.$refs.input.resize();
          }, 500);
        });
      }
    },
    toggleMap() {
      this.showMap = !this.showMap;
    },
  },
};
</script>

<style scoped lang="scss">
.templateEditWrap {
  width: 100%;
  height: 100%;

  .templateEditBody {
    width: 100%;
    height: 100%;
    background: #ccc;
    .templateEditHeader {
      width: 100%;
      height: 3rem;
      background: #4482ff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.5rem;
      box-sizing: border-box;
      > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .backBtn {
        margin-left: 1rem;
        color: #fff;
      }
      .btnWrap {
        width: 16rem;
        > button {
          border-radius: 5px;
          margin-left: 1rem;
          cursor: pointer;
          width: 2.5rem;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          border: none;
          border: 1px solid #4482ff;
          outline: none;
          background: #4482ff;
          color: #fff;
          transition: all 0.3s;
          > img {
            width: 2rem;
            margin: 0;
            // height: 100%;
          }
        }
        > button:hover {
          background: #5d92ff;
          border: 1px solid #80a9ff;
        }
        > .active {
          background: #5d92ff;
          border: 1px solid #80a9ff;
        }
        > .backBtn {
          font-size: 0.95rem;
        }

        .map {
          //   width: 3rem;
          > img {
            width: 2.5rem;
          }
        }
      }
      .stepNameWrap {
        justify-content: space-between;
        > button {
          cursor: pointer;
          width: 2.5rem;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          border: none;
          border: 1px solid #4482ff;
          outline: none;
          background: #4482ff;
          border-radius: 5px;
          color: #fff;
          transition: all 0.3s;
          > img {
            width: 1.5rem;
          }
        }
        > button:hover {
          background: #5d92ff;
          border: 1px solid #80a9ff;
        }
        > span {
          margin: 0 1rem;
          color: #fff;
          font-size: 0.95rem;
        }
        > button:disabled {
          background: #4482ff;
          opacity: 0.3;
          border: 1px solid #4482ff;
          cursor: not-allowed;
          display: none;
        }
      }
      .placeholde {
        width: 16rem;
      }
    }
    .templateEdit {
      width: 100%;
      height: calc(100% - 3rem);
      background: #000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .mapWrap {
        width: 30%;
        height: 100%;
        padding: 1.5% 1%;
        box-sizing: border-box;
        background: #000;
        position: absolute;
        transition: all 0.3s;
        z-index: -1;
        left: -30%;
        .logicflow2d {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          overflow: hidden;
        }
      }
      .placeholder {
        width: 0%;
        transition: all 0.3s;
      }
      .placeholderActive {
        width: 30%;
      }
      .mapWrapActive {
        position: absolute;
        z-index: 0;
        left: 0;
      }
      .bodyContainer {
        width: 70%;
        height: 100%;
        padding: 1.5% 1.5% 1.5% 0.5%;
        background: #000;
        box-sizing: border-box;
        transition: all 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        .bodyPosition {
          width: 100%;
          height: 100%;
          //   padding-bottom: 56.25%;
          box-sizing: border-box;
          position: relative;
          transition: all 0.3s;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: #f2f5fa;
          border-radius: 5px;
          overflow: hidden;
          .bodyWrap {
            width: 100%;
            height: calc(100% - 48px);
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            .body {
              // position: absolute;
              // top: 0;
              // left: 0;
              // bottom: 0;
              // right: 0;
              // display: flex;
              // justify-content: center;
              // align-items: center;
              background: #fff;
              border-radius: 5px;
              overflow: hidden;
              transition: all 0.3s;
            }
          }

          .descriptionWrap {
            width: 100%;
            // height: 23.5%;
            box-sizing: border-box;
            // padding: 1.5% 2.5%;
            background: #fff;
            // margin-bottom: 1.5%;

            font-size: 22px;
            line-height: 33px;
            .header {
              height: 3rem;
              background: #4482ff;
              display: flex;
              justify-content: center;
              align-items: center;
              .buttonWraps {
                display: flex;
                justify-content: space-between;
                align-items: center;
                > button {
                  cursor: pointer;
                  width: 2.5rem;
                  height: 2rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 0;
                  border: none;
                  border: 1px solid #4482ff;
                  outline: none;
                  background: #4482ff;
                  border-radius: 5px;
                  color: #fff;
                  transition: all 0.3s;
                  > img {
                    width: 1.5rem;
                  }
                }
                > button:hover {
                  background: #5d92ff;
                  border: 1px solid #80a9ff;
                }
                > span {
                  margin: 0 1rem;
                  color: #fff;
                  font-size: 20px;
                }
                > button:disabled {
                  background: #4482ff;
                  opacity: 0.3;
                  border: 1px solid #4482ff;
                  cursor: not-allowed;
                }
              }
            }
            .textWrap {
              width: 100%;
              height: 100%;
              padding: 1.5% 2.5%;
              box-sizing: border-box;
              > div {
                width: 100%;
                height: calc(100% - 3rem);
                overflow: auto;
                box-sizing: border-box;
                .textContainer {
                  background: #ffffff;
                  text-align: left;
                  color: #242424;
                }
              }
              > div::-webkit-scrollbar {
                width: 14px;
                height: 14px;
              }
              > div::-webkit-scrollbar-corner {
                background-color: transparent;
              }
              > div::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.1);
                background-clip: padding-box;
                border: solid transparent;
                border-radius: 10px;
              }
              > div::-webkit-scrollbar-track {
                background-clip: padding-box;
                border: solid transparent;
                border-width: 1px;
              }
            }
          }
        }
      }
      .bodyContainerNoOpenMap {
        width: 100%;
        padding: 0;
        box-sizing: border-box;
      }
    }
  }
}
.itemWrap {
  .item {
    .itemBtn {
      height: 32px;
      padding: 0.4rem 1.5rem;
      border: none;
      outline: none;
      background: #e5edfc;
      font-size: 1rem;
      text-align: center;
      width: 100%;
      cursor: pointer;
      margin: 0.2rem 0;
      border-radius: 5px;
      color: #333;
      // transition: all 0.3s;
    }
    .itemBtn:hover {
      background: #dce4f2;
    }
  }
}
</style>
