<template>
  <div class="animateWarp">
    <div
      ref="animation"
      :style="{ width: scale * 100 + '%', height: scale * 100 + '%' }"
      class="animate"
    ></div>
    <div class="progressWarp">
      <div class="progress" :style="{ width: progress * 100 + '%' }"></div>
    </div>
    <p class="title">{{ tip }}</p>
  </div>
</template>

<script>
import lottie from "lottie-web";
export default {
  props: ["scale", "progress", "tip"],
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {
    this.loadAnimation();
  },
  watch: {},
  methods: {
    loadAnimation() {
      this.animation = lottie.loadAnimation({
        container: this.$refs.animation, // the dom element that will contain the animation
        renderer: "svg",
        loop: true,
        autoplay: false,
        // animationData: animationData
        // path: 'animation/cartoonData.json'
        path:
          process.env.VUE_APP_STATIC_PATH +
          "animate/webGLLoading/changeScense.json", // the path to the animation json
      });
      // this.animation.addEventListener('anmation', lottie.play());
      lottie.play();
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
.animateWarp {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: url("../../../assets/model-previewer/background.png") no-repeat
    center center;
  background-size: 100% 100%;
  .animate {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 22%;
  }
  .progressWarp {
    width: 34%;
    height: 1.2%;
    background: rgba(0, 0, 0, 0);
    border: 2px solid #fff;
    border-radius: 30px;
    position: absolute;
    top: 48%;
    overflow: hidden;
    .progress {
      background: #fff;
      height: 100%;
      box-shadow: 0 2px 14px 5px rgb(0, 0, 0, 0.18);
      transition: width 0.5s;
    }
  }
  .title {
    color: #fff;
    font-size: 120%;
    position: absolute;
    top: 51%;
    letter-spacing: 2px;
  }
}
</style>
