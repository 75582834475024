const selectGuid = 'JTWorkflowChart_selectedGuid'

export function getSelectedGuid() {
  return localStorage.getItem(selectGuid)
}

export function setSelectedGuid(id) {
  return localStorage.setItem(selectGuid, id)
}

export function removeSelectedGuid() {
  return localStorage.removeItem(selectGuid)
}