import { saveStep, saveTask, setTaskStartStep } from "@/api/workflow";
// import AssetThumbnail from "@/views/assetThumbnail/assetThumbnail.vue";
import AssetPicker from "../assetPickerNew/assetPicker.vue";
import HistoryEdition from "../historyEdition/historyEdition.vue";
import ProjectInfo from "../projectInfo/projectInfo.vue";
import EditLink from "../editLink/editLink.vue";
import TemplateChose from "../templateChose/templateChose.vue";
import Thumbnail from "../thumbnail/thumbnail.vue";
export default {
  name: 'EditStep',
  components: {
    // AssetThumbnail,
    AssetPicker,
    HistoryEdition,
    ProjectInfo,
    EditLink,
    TemplateChose,
    Thumbnail
  },
  data() {
    return {
      lineType: ["polyline", "line", "bezier"],
      nodeType: ["action", "pointer", "judge", "StartShape", "CloneActionShape", "CloneJudgeShape", "InputShape", "CloneInputShape"],
      // isCreator: true,
      linkName: "",
      stepName: "",
      description: "",
      showHistory: false,
      activeName: 'projectInfo',
      edit: false,
      originItem: null,
      rules: {
        stepName: [
          { required: false, message: this.$t('editStep.tip1'), trigger: 'change' },
          // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
        ],
      },
      // needSave: false,
      pickIcon: require('@/assets/task-detail/del.svg'),
      shapeItem: null,
      doingInit: true,
      isClone: true,
      // stepItem: null,
    }
  },

  props: ["isCreator", "projectInfo", "own", "templateInfo", "workingType", "step2DInfo", "auditReleaseMode"],
  //   model: {
  //     // prop: 'liveUsers',     
  //     event: 'itemChanged'
  //   },

  watch: {
    stepName(val) {
      if (!this.doingInit && this.stepItem) {
        this.shapeItem.SetContent(this.stepItem.stepName)
        this.shapeItem.instanceShapes.forEach((value, key) => {
          value.SetContent(this.stepItem.stepName)
        });
        this.setNeedSave(true);
      }
    },
    description(val) {
      if (!this.doingInit) {
        // console.log('description:', this.stepItem.description)
        this.setNeedSave(true);
      }
    }
  },

  computed: {
    // linkName: {
    //   get() {
    //     return this.shapeItem.type ? "" : this.shapeItem.data.linkName
    //   }
    // },
    stepItem: {
      get() {
        return this.shapeItem ? this.shapeItem.data : null
      }
    },
    // stepName: {
    //   get() {
    //     return this.shapeItem ? this.shapeItem.data.properties.stepInfo.stepName : ''
    //   },
    // },
    // description: {
    //   get() {
    //     return this.shapeItem && this.shapeItem.data.properties.stepInfo ? this.shapeItem.data.properties.stepInfo.stepName : ''
    //   },
    //   // set(value){
    //   //   if (this.shapeItem){
    //   //     this.shapeItem.data.description = value;
    //   //     this.setNeedSave()
    //   //   }

    //   // }
    // },
  },

  created() {
    console.log(this.$store.state);
  },
  mounted() {
    this.$nextTick(() => {

      // this.$refs.focusedItem.focus();
    })
  },

  methods: {
    languageChange(data){
      this.$emit("languageChange", data);
    },
    gotoPointer(data) {
      this.$emit("gotoPointer", data);
    },
    thumbnailLoaddone() {
      this.$emit("thumbnailLoaddone")
    },
    //接受来自父组件的dom输出的图片
    setWorkingTypeThumbnail(data) {
      if (this.$refs.thumbnail) {
        this.$refs.thumbnail.setWorkingTypeThumbnail(data);
      }

    },
    setWorkingType(data) {
      this.$emit("setWorkingType", data);
    },
    selectedTemplate(data) {
      this.$emit("selectedTemplate", data);
    },
    setStepName() {
      this.$emit("setStepName", {
        id: this.shapeItem.data.id,
        text: this.stepName,
        properties: this.shapeItem.data.properties,
      })
      this.setNeedSave(true);
    },
    setDescription() {
      this.$emit("setDescription", {
        id: this.shapeItem.data.id,
        text: this.description,
        properties: this.shapeItem.data.properties,
      })
      this.setNeedSave(true);
    },
    closeTools() {
      // this.shapeItem = false
      this.$store.commit("SET_PROJECTINFO", false);
      this.$store.commit("SET_SHOWHISTORY", false);
      this.$store.commit("SET_SELECTSHAPE", false);
    },
    projectNameChange(projectName) {
      console.log(projectName);
      this.$emit("projectNameChange", projectName);
    },
    setOwn(bool) {
      this.$emit("setOwn", bool);
      console.log("editStep");
    },
    refreashHistoryList() {
      console.log(this.$store.state.TaskDetail.showHistory);
      this.$nextTick(() => {
        console.log(this.$refs.historyEdition);
        this.$refs.historyEdition.refreashList();
      })
      console.log(this.$refs);
    },
    setIsCreator(bool) {
      this.isCreator = bool;
      console.log(this.$refs);
      // this.$refs.projectInfo.setIsCreator(bool)
    },
    saveHistory() {
      console.log("editStep");
      this.$emit("saveHistory")
      console.log("editStep");
    },
    async handleClick(command, option) {
      if (command.props.name === "assets") {
        this.$nextTick(async () => {
          await this.$refs.assetPicker.getAssetList(
            this.projectInfo.projectGuid,
            0,
            999999
          );
        });
      }
    },
    init(shapeItem) {
      console.log(shapeItem);
      if (!shapeItem) {
        this.activeName = "projectInfo";
        this.shapeItem = false;
        return
      }
      // console.log(shapeItem && shapeItem.data);
      // console.log(shapeItem && shapeItem.data && shapeItem.data.linkName);
      // console.log(shapeItem && shapeItem.type);
      // if (!shapeItem) {
      //   this.$store.commit("SET_SELECTSHAPE", false);
      //   return
      // }
      // if (shapeItem && shapeItem.sourceShape) {
      //   this.isClone = true;
      // }
      this.shapeItem = shapeItem;
      // console.log(shapeItem.data.type.includes(this.nodeType));
      // console.log(this.nodeType.includes(shapeItem.data.type));
      if (shapeItem && !this.nodeType.includes(shapeItem.data.type)) {
        //选中的是连线
        this.activeName = "editStepTag";
        console.log(shapeItem.data.linkName);
        if (shapeItem.data && shapeItem.data.text) {
          this.linkName = shapeItem.data.text.value;
        } else {
          this.linkName = "";
        }

      } else {
        //选中的是节点
        this.activeName = "editStepTag";
        if (shapeItem && shapeItem.data.properties.stepInfo.sourceStepGuid) {
          //克隆节点
          this.isClone = true;

        } else {
          this.isClone = false;
        }
        if (shapeItem.data && shapeItem.data.text) {
          this.stepName = shapeItem.data.text.value;
        } else {
          this.stepName = "";
        }
        if (shapeItem && shapeItem.data.properties) {
          this.description = shapeItem.data.properties.stepInfo.description ? shapeItem.data.properties.stepInfo.description : '';
        }

      }
      // this.doingInit = true;

      // if (shapeItem && shapeItem.sourceShape) {
      //   this.shapeItem = shapeItem.sourceShape;
      //   this.isClone = true;
      // }
      // else {
      //   this.shapeItem = shapeItem;
      //   this.isClone = false;
      //   this.showHistory = false;
      //   this.activeName = 'editStepTag';
      //   this.$nextTick(() => {
      //     this.doingInit = false;
      //   })

      // }
      if (shapeItem) {
        this.$store.commit("SET_SELECTSHAPE", true);
        this.$store.commit("SET_PROJECTINFO", false);
        this.$store.commit("SET_SHOWHISTORY", false);
      }
    },
    setLinkName() {
      this.$emit("setLinkName", {
        id: this.shapeItem.data.id,
        text: this.linkName,
        properties: this.shapeItem.data.properties,
      });
      this.setNeedSave(true);
    },
    uploadModel() {
      console.log('uploadModel');
    },
    setSwitch(bool) {
      console.log(bool);
      this.showHistory = bool;
      if (this.showHistory === false) {
        this.$emit('backPoisition', false)
      }
    },
    removeAsset(asset) {
      const index = this.stepItem.stepAssets.findIndex(x => {
        return x.stepAssetGuid == asset.stepAssetGuid
      })

      if (index >= 0) {
        this.stepItem.stepAssets.splice(index, 1);

        this.setNeedSave(val);
      }
    },

    // async setStartStep(){
    //   await setTaskStartStep(this.stepItem.taskGuid, this.stepItem.stepGuid)
    // },

    setNeedSave(val) {
      console.log('bbbbb')
      this.$emit('onNeedSave', val)
    }
  }
}