const TokenKey = 'JTWorkflow-Token'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}

// const UserInfoKey = "JTWorkflow_userInfo"

// export function getUserInfo() {
//   return localStorage.getItem(UserInfoKey)
// }

// export function setUserInfo(data) {
//   return localStorage.setItem(UserInfoKey, data)
// }

// export function removeUserInfo() {
//   return localStorage.removeItem(UserInfoKey)
// }


const SelectProjectInfo = "JTWorkflow_selectProjectInfo";

export function getProjectInfo() {
  return localStorage.getItem(SelectProjectInfo)
}

export function setProjectInfo(data) {
  return localStorage.setItem(SelectProjectInfo, data)
}

export function removeProjectInfo() {
  return localStorage.removeItem(SelectProjectInfo)
}

const refreshTokenKey = 'JTWorkflow_refreshTokenKey';

export function getTokenKey() {
  return localStorage.getItem(refreshTokenKey)
}

export function setTokenKey(key) {
  return localStorage.setItem(refreshTokenKey, key)
}

export function removeTokenKey() {
  return localStorage.removeItem(refreshTokenKey)
}

const cacheActiveList = 'cacheActive';

export function getcacheActive() {
  return JSON.parse(localStorage.getItem(cacheActiveList))
}

export function setcacheActive(data) {
  return localStorage.setItem(cacheActiveList, JSON.stringify(data))
}

export function removecacheActive() {
  return localStorage.removeItem(cacheActiveList)
}

const cacheActiveArr= 'cacheActiveArr';

export function getcacheActiveArr() {
  return JSON.parse(localStorage.getItem(cacheActiveArr))
}

export function setcacheActiveArr(data) {
  return localStorage.setItem(cacheActiveArr, JSON.stringify(data))
}

export function removecacheActiveArr() {
  return localStorage.removeItem(cacheActiveArr)
}
