<template>
  <div style="width: 800px; height: 600px; border: 1px solid #f00">
    <!-- <Unity :unity="unityContext" width="800px" height="600px" /> -->
    <!-- <Unity
      src="../../../../public/modelLoader/Build/APP.json"
      width="1000"
      height="600"
      unityLoader="../../../../public/modelLoader/Build/APP.loader.js"
    ></Unity> -->
    <iframe
      ref="modelLoader"
      src="modelLoader/index.html"
      frameborder="0"
      scrolling="no"
    ></iframe>
    <button @click="sendTest">sendData</button>
    <el-upload
      class="upload-demo"
      action="https://jsonplaceholder.typicode.com/posts/"
      :on-change="handleChange"
      :file-list="fileList"
      @submit="submit"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <template #tip>
        <div class="el-upload__tip">只能上传 jpg/png 文件，且不超过 500kb</div>
      </template>
    </el-upload>
    <input type="file" οnchange="jsReadFiles(this.files)" />
  </div>
</template>

<script>
// import Unity from "vue-unity-webgl";
// import UnityWebgl from "unity-webgl";
// const Unity = new UnityWebgl({
//   loaderUrl: "modelLoader/Build/APP.loader.js",
//   dataUrl: "/Build/APP.data.unityweb",
//   frameworkUrl: "/Build/APP.framework.js.unityweb",
//   codeUrl: "/Build/App.wasm.unityweb",
// });
// console.log(Unity);
export default {
//   components: { Unity,
// //    Unity: UnityWebgl.vueComponent
//    },
  name: "Unity",
  created() {
    window["getInstruction"] = (type, bool) => {
      console.log(type, bool);
    };
  },
  async mounted() {
    // this.$refs.frame.onload = () => {
    //   console.log(this.$refs.taskChart);
    //   // this.$refs.taskChart.
    // };
    // if (this.workflowChart.shapes.get(getSelectedGuid())) {
    //           this.workflowChart.shapes.get(getSelectedGuid()).Select();
    //           this.$emit("sendData", this.workflowChart.shapes.get(getSelectedGuid()));
    //         }
    window.addEventListener("message", this.receiveMessage);

    window.addEventListener("resize", () => {
      this.iframWidth = window.innerWidth;
      this.iframHeight = window.innerHeight;
    });
    console.log(this.$route.query);

    // await this.$refs.taskChart.createWorkflowChart();
    // await this.$refs.taskChart.loadShapes(this.$route.query.taskGuid);
  },
  //   mounted() {
  //     let UnityContext = new UnityWebgl("#canvas", {
  //       loaderUrl: "modelLoader/Build/APP.loader.js",
  //       dataUrl: "/Build/APP.data.unityweb",
  //       frameworkUrl: "/Build/APP.framework.js.unityweb",
  //       codeUrl: "/Build/App.wasm.unityweb",
  //     });
  //     this.$once("hook:beforeDestroy", function () {
  //       UnityContext.destroy();
  //       UnityContext = null;
  //     });
  //   },
  data() {
    return {
      fileList: [],
    //   unityContext: Unity,
    };
  },
  methods: {
    handleChange(file) {
      console.log(file);
      // URL.createObjectURL(file.raw)
      console.log(URL.createObjectURL(file.raw));
      console.log(document.getElementsByClassName("el-upload__input")[0].value);
    },
    submit() {
      console.log();
    },
    sendTest() {
      let options = {
        key: "111",
        value: "2222",
      };
      this.CallUnity(options);
    },
    CallUnity(data) {
      //调用unity的方法
      this.$refs.modelLoader.contentWindow.send(data);
      // window.parent.postMessage(data, '*')
      // unityInstance.SendMessage('JSScriptObject', 'JSCallUnity', 'MyString');
    },
    receiveMessage(event) {
      //unity告诉web已经加载完成
      if (event.data.type === "LoadDone") {
        console.log("发送");
        let options = {
          type: "LoadModel",
          value: this.modelInfo.fileUrl,
        };
        this.CallUnity(options);
      }
    },
  },
};
</script>