<template>
  <div class="textWrap" @click="focusInput" id="textId">
    <!-- 请输入内容 -->
    <el-input
      v-if="rows"
      id="inputTextarea"
      :ref="textRefName"
      type="textarea"
      :placeholder="$t('textVue.word1')"
      :disabled="readyOnly"
      v-model="text"
      :maxlength="maxlength ? maxlength : 500"
      show-word-limit
      :autosize="{ minRows: rows, maxRows: rows }"
      :style="{ width: '100%', height: textareaHeight + 'px', resize: 'none' }"
      @input="inputChange"
    >
    </el-input>
    <audio @ended="playEnd" @pause="pause" :ref="videoRefName" src="">
      <source />
    </audio>
    <div class="controls audioControls">
      <button
        :disabled="turnSpeakLoaing"
        v-loading="turnSpeakLoaing"
        @click="play"
        :class="playStatus !== 0 ? 'audioWrap active' : 'audioWrap'"
      >
        <div :class="playStatus !== 0 ? 'img active' : 'img'"></div>
      </button>
    </div>
  </div>
</template>

<script>
import { getSpeak } from "@/utils/SpeechSynthesis.js";
import { uuid } from "vue-uuid";
import { ElMessage } from "element-plus";
import { removeNonAlphanumeric } from "@/utils/utils.js";
export default {
  props: ["templateNum", "maxlength", "readyOnly", "projectInfo"],
  data() {
    return {
      videoRefName: "video1",
      textRefName: "input0",
      text: "",
      textareaHeight: 200,
      rows: false,
      playStatus: 0, //0:未播放 1:播放中 2:暂停
      turnSpeakLoaing: false,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.$nextTick(() => {
      let wrapDom = document.getElementById("textId");
      this.textareaHeight = wrapDom.offsetHeight;
      let row = this.textareaHeight / 31.5;
      this.rows = Math.floor(row);
    });
  },
  watch: {
    templateNum(newVal, oldVal) {
      console.log(newVal);
      if (newVal && newVal.assetGuid) {
        //已保存过
        this.text = newVal.content;
        this.$emit("initDone");
      } else {
        //未保存过
        this.text = "";
        this.$emit("initDone");
      }
    },
  },
  methods: {
    // play() {
    //   console.log("开始播放");
    // },
    pause() {
      console.log("暂停播放");
      this.playStatus = 0;
    },
    playEnd() {
      console.log("播放完毕");
      this.playStatus = 0;
    },
    async play(language) {
      if (!this.text) {
        ElMessage.warning({
          // 你还没有输入任何内容呢！
          message: this.$t("textVue.word2"),
          type: "warning",
        });
        return;
      }
      // this.$emit("playText");
      if (this.playStatus !== 0) {
        this.$refs[this.videoRefName].pause();
      } else {
        this.turnSpeakLoaing = true;
        console.log(removeNonAlphanumeric(this.text));
        console.log(this.projectInfo);
        let res = await getSpeak(
          removeNonAlphanumeric(this.text),
          this.projectInfo.language
        );
        console.log(res);
        let blob = new Blob([res], { type: "audio/mp3" });
        blob.name = uuid.v4() + ".mp3";
        this.$refs[this.videoRefName].src = URL.createObjectURL(blob);
        this.$refs[this.videoRefName].load();
        this.turnSpeakLoaing = false;
        this.$refs[this.videoRefName].play();

        this.playStatus = 1;
      }
    },
    returnText() {
      return this.text;
    },
    inputChange() {
      this.$emit("needSave", true, this.templateNum);
    },
    focusInput() {
      let targetDom = document.getElementById("inputTextarea");
      targetDom.focus();
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
.textWrap {
  padding: 18px;
  height: 100%;
  box-sizing: border-box;
  cursor: text;
  position: relative;
  .controls {
    padding: 0.3rem;
    background: #fff;
    position: absolute;
    bottom: 3rem;
    right: 6%;
    border-radius: 0.2rem;
    .audioWrap {
      cursor: pointer;
      padding: 0.5rem 0.5rem;
      transition: all 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.2rem;
      background: linear-gradient(144deg, #1a98ff -8%, #0f66e8 67%);
      border: none;
      outline: none;
      .img {
        width: 2rem;
        height: 1.35rem;
        background: url("~@/assets/project/audio.png") no-repeat center center;
        background-size: 73%;
      }
    }
    .audioWrap:hover {
      // background: $main_color;
      .img {
        background: url("~@/assets/project/audio1.png") no-repeat center center;
        background-size: 73%;
        background-color: linear-gradient(144deg, #1a98ff -8%, #0f66e8 67%);
      }
    }
    .active {
      // background: $main_color;
      .img {
        background: url("~@/assets/project/audio1.png") no-repeat center center;
        background-size: 73%;
      }
    }
  }
  .audioControls {
    padding: 0;
    border-radius: 0.4rem;
    .audioWrap {
      width: 3rem;
      height: 2.6rem;
    }
  }
  :deep(.el-textarea) {
    background: transparent;
    height: 100% !important;
    overflow: hidden;
    > textarea {
      height: 100%;
      background: transparent;
      border: none;
      outline-color: transparent;
    }
    > .el-textarea__inner {
      box-shadow: none;
      font-size: 26px;
      line-height: 40px;
      min-height: auto !important;
      height: 100% !important;
    }
    > .el-textarea__inner::-webkit-scrollbar {
      width: 14px;
      height: 14px;
    }
    > .el-textarea__inner::-webkit-scrollbar-corner {
      background-color: transparent;
    }
    > .el-textarea__inner::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.1);
      background-clip: padding-box;
      border: solid transparent;
      border-radius: 10px;
    }
    > .el-textarea__inner::-webkit-scrollbar-track {
      background-clip: padding-box;
      border: solid transparent;
      border-width: 1px;
    }
    #inputTextarea {
      resize: none;
    }
    > .el-input__count {
      background: #ededed;
    }
  }
}
</style>
