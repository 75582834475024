<template>
    <h1>您无访问该页面的权限。</h1>
</template>

<style scoped>

</style>

<script>

export default({});
</script>
