import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import router from '@/router'
import { getToken, getTokenKey, setToken, setTokenKey } from '@/utils/setLocalstorage'
import qs from 'qs'
// create an axios instance
import app from "../main.js"
// import { ElMessage } from 'element-plus'
const service = axios.create({
  baseURL: process.env.VUE_APP_AUTH_API, // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 120000 // request timeout
})


export function post(url, data) {
  // let token = getToken();
  // if (store.getters.token)
  //   service.defaults.headers.common['Authorization'] = token;
  // else
  //   delete service.defaults.headers.common.Authorization;

  return service.post(url, data);
}


// request interceptor
service.interceptors.request.use(


  config => {
    // do something before request is sent
    // console.log('gogogo');
    // console.log(store.state);
    // console.log(store && store.state && store.state.User);
    // if (store && store.state && store.state.User && store.state.User.userToken) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    const token = getToken();
    if (token)
      config.headers['Authorization'] = `Bearer ${token}`;


    // }
    return config
  },
  error => {
    // do something with request error
    console.log('request err', error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    if (res.code != undefined){
      if (res.code == 200){
        return res.result;
      }
      else{
        throw res;
      }
    }
    else
      return res;


    // return res

    // if the custom code is not 20000, it is judged as an error.
    /* if (res.code !== 20000) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }*/
  },
  async error => {
    console.log('err:', error) // for debug
    // console.log('err:', error.response)
    // console.log('err:', error.code)
    // console.log(Vue);
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    // let config = error.response.config;
    // console.log(error.config);
    // console.log(error.response.config);
    // console.log(app);
    // const Vue = app.config.globalProperties;
    // console.log(Vue);
    // if (!getTokenKey()) {
    //   // ElMessage.error('Token exception, Please log in again!')
    //   Vue.$store.dispatch("logout");
    //   console.log(error);
    //   Vue.$router.push("/")
    // }

    if (!getTokenKey()) {
      // ElMessage.error('Token exception, Please log in again!')
      store.dispatch("logout");
      router.push("/")
    }


    if (error && error.response && error.response.data && error.response.data.status == 401 && error.response.data.name === "invalid_token") {
      const instance = axios.create({
        baseURL: process.env.VUE_APP_LOGIN_SERVER,
        timeout: 5000
      })
      let params = {
        client_id: process.env.VUE_APP_APPGUID,
        grant_type: "refresh_token",
        refresh_token: getTokenKey()
      };
      try {
        const result = await instance({
          url: '/api/token',
          method: 'post',
          data: qs.stringify(params)
        })
        store.commit('SET_USER', result.data)
        store.commit('SET_TOKEN', result.data.refreshToken)
        setToken(result.data.accessToken)
        setTokenKey(result.data.refreshToken);
        try {
          // let getTokenRes = await service(config)
          return service(config);
        } catch {
          // ElMessage.error('Token has expired,Please login again!')
          store.dispatch("logout");
          console.log(error);
          router.push("/")
        }
      } catch (e) {
        if (e.response.status === 400) {
          // ElMessage.error('Token has expired, Please login again!')
          store.dispatch("logout");
          console.log(error);
          router.push("/")
        }
      }

    } 
    // else {
    //   ElMessage.error('Please login again!')
    //   Vue.$store.dispatch("logout");

    //   Vue.$router.push("/")
    // }
    // if (error.response.status === 400 || error.response.status === 401) {
    //   // token过期,请重新登录!
    //   ElMessage.error('token timeout')
    //   Vue.$store.dispatch("logout");
    //   console.log(error);
    //   console.log(error.response);
    //   Vue.$router.push("/login")
    // }
    return Promise.reject(error.response)
  }
)

export default service
