import { h } from "@logicflow/core";
import { RectResize } from "@logicflow/extension";

class HexagonModel extends RectResize.model {
  setAttributes() {
    this.text.x = this.x;
    this.text.y = this.y;
    this.radius = this._height / 2;
    this.menu = [];
    this.width = Math.max(this.width, this.height * 2);
  }
  initNodeData(data) {
    super.initNodeData(data);
    this.width = 160;
    this.height = 64;
    this.radius = this._height / 2;
    const startOnlyHaveOneExit = {
      message: "开始步骤只允许存在一个出口",
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        let edgesList = sourceNode.graphModel.edges;
        let startNode = edgesList.find((item) => {
          return item.sourceNode.id === sourceNode.id;
        });
        if (startNode) {
          return false;
        } else {
          return true
        }
      },
    };
    const rule3 = {
      message: "不允许连接到自己",
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        if (sourceNode.id === targetNode.id) {
          return false
        } else {
          return true
        }
      }
    }
    this.sourceRules.push(startOnlyHaveOneExit, rule3);
  }
  //重写节点文本样式
  getTextStyle() {
    const style = super.getTextStyle();
    style.color = "#333"
    style.padding = "0 10%"
    return style;
  }
  //重写节点样式
  getNodeStyle() {
    const style = super.getNodeStyle();

    style.strokeWidth = 2;
    style.fill = "#fff";
    style.stroke = "#333"
    // if (this.properties.onLinkLine) {
    //   style.strokeDasharray = "0,0";
    // } else {
    //   style.strokeDasharray = "2,2";
    // }
    if (this.properties.isWebGl || this.properties.isAuthor) {
      if (this.isSelected) {
        style.stroke = "#4582FF"
      } else {
        style.stroke = "#000"
      }
    }
    if (this.isSelected) {
      style.stroke = "#4582FF"
    } else {
      style.stroke = "#000"
    }

    return style;
  }
  //重写锚点样式
  getAnchorStyle(anchorInfo) {
    const style = super.getAnchorStyle(anchorInfo);
    // style.fill = "#32ff32";
    // style.stroke = "#32ff32";
    // style.fill = "#4582FF";
    style.stroke = "#4582FF";
    style.r = 8;
    style.strokeWidth = 2;
    style.hover.fill = "transparent";
    style.hover.stroke = "transpanrent";
    style.className = "lf-hide-default";
    return style;
  }
  //重写节点外边框样式
  getResizeOutlineStyle() {
    return {
      strokeWidth: 2,
      // stroke: "#32ff32",
      stroke: "#4582FF",
      x: this.x - this.width / 2 - 0.5,
      y: this.y - this.height / 2 - 0.5,
    }
  }
  //重写节点缩放四个点样式
  getControlPointStyle() {
    return {
      strokeWidth: 2,
      width: 14,
      height: 14,
      fill: "#FFFFFF",
      // stroke: "#32ff32",
      stroke: "#4582FF",
      // x: this.x - this.width / 2 - 3.5,
      // y: this.y - this.height / 2 - 3.5,
    };
  }
}
//重写开始步骤的样式
class HexagonView extends RectResize.view {
  // getResizeShape() {
  //   const { x, y, width, height } = this.props.model;
  //   const style = this.props.model.getNodeStyle();
  //   const pointList = [
  //     [x - 0.28 * width, y - 0.5 * height],
  //     [x + 0.28 * width, y - 0.5 * height],
  //     [x + 0.5 * width, y],
  //     [x + 0.28 * width, y + 0.5 * height],
  //     [x - 0.28 * width, y + 0.5 * height],
  //     [x - 0.5 * width, y]
  //   ];
  //   const points = pointList.map((item) => {
  //     return `${item[0]},${item[1]}`;
  //   });
  //   const attrs = {
  //     ...style,
  //     x,
  //     y,
  //     width,
  //     height,
  //     points: points.join(" ")
  //   };

  //   return h("g", {}, [h("polygon", { ...attrs })]);
  // };
}

export default {
  type: "StartShape",
  view: HexagonView,
  model: HexagonModel
};
