<template>
  <div>
    <div class="accountHeader">
      <Header ref="header" />
      <div class="myBigHeadImage">
        <!-- <img src="/test/headImage.jpg" alt="" /> -->
        <!-- <img :src="profilePhotoUrl" /> -->
        <img v-if="profilePhotoUrl" :src="profilePhotoUrl" alt="" />
        <img v-else src="@/assets/login/default-head.png" alt="" />
      </div>
      <div class="headerContent">
        <div>
          <div class="imgWarp">
            <img v-if="profilePhotoUrl" :src="profilePhotoUrl" alt="" />
            <img v-else src="@/assets/login/default-head.png" alt="" />
          </div>
          <div>
            <div class="username">{{ $store.state.User.displayName }}</div>
            <!-- 编辑个人信息 -->
            <div class="editUserinfo" @click="userInfo">
              {{ $t("myTask.editUserInfo") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="itemType">
      <div class="contantWarp">
         <!-- 已创建的项目： -->
         <div :class="selectItem === listDataType.created ? 'active' : ''" @click="doSelectDataType(listDataType.created)">
          {{ $t("myTask.word3")
          }} {{listInfo.createdTotal }}
          <div class="line"></div>
        </div>
         <!-- 已发布的项目： -->
         <div :class="selectItem === listDataType.published ? 'active' : ''" @click="doSelectDataType(listDataType.published)">
          {{ $t("myTask.word4")
          }}
          <div class="line"></div>
        </div>
        <!-- 我收藏的项目： -->
        <div :class="selectItem === listDataType.favourite ? 'active' : ''" @click="doSelectDataType(listDataType.favourite)">
          {{ $t("myTask.word1")
          }} {{listInfo.favouriteTotal }}
          <div class="line"></div>
        </div>
        <!-- 我拥有的项目： -->
        <div :class="selectItem === listDataType.owned ? 'active' : ''" @click="doSelectDataType(listDataType.owned)">
          {{ $t("myTask.word2")
          }}  {{listInfo.ownedTotal }}
          <div class="line"></div>
        </div>
       
      </div>
    </div>
    <div class="accountBody">
      <div
        v-if="data.length !== 0 &&
            selectItem !== listDataType.published
        "
      >
        <div
          class="myProjectItem"
          v-for="(item, index) in data"
          :key="index"
        >
          <div>
            <div class="accountImgWarp" @click="enterProject(item)">
              <div>
                <div v-if="item.editType == 0" class="threeD">
                  <img src="@/assets/home/threeD.png" alt="" />
                </div>
                <div v-if="item.editType == 1" class="twoD">
                  <img src="@/assets/home/twoD.png" alt="" />
                </div>
              </div>
              <img v-if="item.coverImageUrl" :src="item.coverImageUrl" alt="" />
              <img v-else src="@/assets/home/defaultCover.png" alt="" />
            </div>
            <p>{{ item.projectName ? item.projectName : "0" }}</p>
            <div class="operation">
              <span
                ><img src="@/assets/myTask/thumbnail.png" alt="" />
                {{
                  item.likeCount
                    ? item.likeCount > 99
                      ? "99+"
                      : item.likeCount
                    : "0"
                }}
              </span>
              <span
                ><img src="@/assets/myTask/star.png" alt="" />
                {{
                  item.favourateCount
                    ? item.favourateCount > 99
                      ? "99+"
                      : item.favourateCount
                    : "0"
                }}
              </span>
              <span
                ><img src="@/assets/myTask/eye.png" alt="" />
                {{
                  item.previewCount
                    ? item.previewCount > 99
                      ? "99+"
                      : item.previewCount
                    : "0"
                }}
              </span>
              <span
                ><img src="@/assets/myTask/share.png" alt="" />
                {{
                  item.forwardCount
                    ? item.forwardCount > 99
                      ? "99+"
                      : item.forwardCount
                    : "0"
                }}
              </span>
              <el-dropdown
                v-if="selectItem !== 1"
                trigger="click"
                class="dropDown"
              >
                <span class="more">
                  <i class="el-icon-more selfDelete"></i>
                </span>
                <template #dropdown>
                  <div class="taskCtrlListWarp">
                    <el-dropdown-menu>
                     
                      <el-dropdown-item command="toBottom" class="btnWarp">
                        <!-- 取消收藏 -->
                        <button
                          v-if="selectItem === listDataType.favourite"
                          @click="cancelFav(item, index)"
                        >
                          {{ $t("myTask.word5") }}
                        </button>
                        <!-- 删除项目 -->
                        <button
                          v-if="selectItem === listDataType.created"
                          @click="deleteProject(item, index)"
                        >
                          {{ $t("myTask.word6") }}
                        </button>
                        <!-- 审核记录 -->
                        <button
                          v-if="selectItem === listDataType.created"
                          @click="showAuditHistory(item, index)"
                        >
                          {{ $t("myTask.word11") }}
                        </button>
                        <!-- 下架 -->
                        <button
                          v-if="selectItem === listDataType.published"
                          @click="cancelRelease(item, index)"
                        >
                          {{ $t("myTask.word7") }}
                        </button>
                        <!-- 取消发布所有版本 -->
                        <button
                          v-if="selectItem === listDataType.published"
                          @click="cancelReleaseAll(item, index)"
                        >
                          {{ $t("myTask.word10") }}
                        </button>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </div>
                </template>
              </el-dropdown>
            </div>
          </div>
        </div>

        <div
          class="pages"
          v-if="total > 0"
        >
          <el-pagination
            background
            :page-size="pageSize"
            :page-sizes="[12, 24, 48, 96]"
            layout="prev, pager, next ,jumper"
            :total="total"
            :current-page="page"
            @size-change="handleSizeChange"
            @current-change="changePageIndex"
          >
          </el-pagination>
        </div>
      </div>
      <div class="myPublishedContainer" v-if="selectItem === listDataType.published">
        <MyPublished
          ref="myPublished"
          @publishedListChange="publishedListChange"
          @cancelRelease="callCancelRelease"
          @deleteProject="deleteProject"
        />
      </div>
      <div
        class="enpty"
        v-if="
          selectItem !== listDataType.published &&
            (data.length == 0)
        "
      >
        <div>
          <img src="@/assets/myTask/defaultEnpty.png" alt="" />
          <!-- 当前无可浏览项目 -->
          <div>{{ $t("myTask.word8") }}</div>
          <div v-if="selectItem === listDataType.created" class="createBtn">
            <!-- 创建项目 -->
            <button @click="goCreateProject">{{ $t("myTask.word9") }}</button>
          </div>
        </div>
      </div>
    </div>
    <Footer v-if="language !== 'en'" />

    <AuditHistory ref="auditHistory" />
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import Header from "@/views/components/header/header.vue";
import Footer from "@/views/components/footer/footer.vue";
import { removeTaskGuid } from "@/utils/setTaskGuid";
import MyPublished from "./components/myPublished.vue";
import {
  removeRelease,
  saveProject,
  removeAllReleaseByProject,
} from "@/api/workflow.js";
import { getLanguage } from "@/utils/getLanguage.js";
// getMyCreatedProjects
import {
  getMyFavourateProjects,
  getMyOwnProjects,
  getMyCreatedProjects,
  getMyReleaseProjects,
  saveFavourate,
  getAuditStat
} from "@/api/mine.js";
import AuditHistory from "@/views/myTasks/components/auditHistory.vue";
import LangManager from "../../utils/langManager";

const ListDataType = {  
  favourite: 0,
  owned: 1,  // buy 
  created: 2,  
  published: 3,
  audit: 4
}

const ListDataTypeItem = {  
  0: { value: ListDataType.favourite, name: 'favourite' },
  1: { value: ListDataType.owned, name: 'owned' },
  2: { value: ListDataType.created, name: 'self' },
  3: { value: ListDataType.published, name: 'published' },
  4: { value: ListDataType.audit, name: 'audit' },
}

export default {
  props: {},
  components: { Header, Footer, MyPublished, AuditHistory },
  data() {
    return {
      language: LangManager.getLanguage().value,
      selectItem: null,
      data: [],
      total: 0,
      // numList: [],
      listInfo:{
        favouriteTotal: 0,
        ownedTotal: 0,
        createdTotal: 0
      },
      pageSize: 12,
      page: 1,
      auditStatus: 0,
      listDataType: ListDataType,
    };
  },
  computed: {
    profilePhotoUrl() {
      return this.$store.state.User.profilePhotoUrl;
    },
  },
  async created() {
    this.setPageSizeByScreen();
    window.addEventListener("resize", this.setPageSizeByScreen);
  },
  mounted() {
    this.setRouteTab();
    // window.addEventListener("resize", this.setPageSizeByScreen);
  },
  watch: {
    pageSize(newVal, oldVal) {
      console.log(newVal, oldVal);
      if (newVal != oldVal) {
        this.setPageSizeByScreen();
      }
    },
    $route(to, from) {
      
      if (to.name == 'myTasks'){
        console.log('router changed', to, from)
        this.setRouteTab();
      }
    },
  },
  methods: {
    setRouteTab(){
      const routerTab = this.$route.query.tab || ListDataTypeItem[2].name;
      
      let dataType = ListDataType.created;
  
      if (routerTab){
        for (let key in ListDataTypeItem) {
          if (ListDataTypeItem[key].name === this.$route.query.tab) {
            dataType = ListDataTypeItem[key].value;
            break;
          }
        }
      }
      this.doSelectDataType(dataType)
    },

    async getData(){
      const datas =  await Promise.all([this.getList(this.listDataType.favourite, 1, 1),
        this.getList(this.listDataType.owned, 1, 1),
        this.getList(this.listDataType.created, 1, 1),
        this.getList(this.selectItem, this.page, this.pageSize)
      ]);

      // console.log('datas', datas)
      this.listInfo = {
        favouriteTotal: datas[0].total,
        ownedTotal: datas[1].total,
        createdTotal: datas[2].total
      };
      const result = datas[3];
      // const result = await this.getList(this.selectItem, this.page, this.pageSize);
      this.total = result.total;
      this.data = result.data;

    },
    callCancelRelease(cancelAll, item) {
      // console.log('callCancelRelease', cancelAll, item);
      if (cancelAll) {
        this.cancelReleaseAll(item);
      } else {
        this.cancelRelease(item);
      }
    },
    setPageSizeByScreen() {
      let width = document.body.offsetWidth;
      if (width > 1919) {
        this.pageSize = 15;
      } else {
        this.pageSize = 12;
      }
    },
    
    goCreateProject() {
      console.log(111);
      this.$refs.header.userInfo();
    },
    async cancelFav(item, index) {
      try {
        await saveFavourate(item.projectGuid, true);
        await this.getData();
      } catch (e) {
        console.log(e);
      }
    },
    async deleteProject(item, index) {
      console.log(item);
      this.$confirm(
        // 将同时下架该项目,删除后无法恢复,确认删除该项目吗?
        this.$t("myTask.tip1"),
        // "删除项目"
        this.$t("myTask.tip2"),
        {
          distinguishCancelAndClose: true,
          // 删除
          confirmButtonText: this.$t("myTask.tip3"),
          // 取消
          cancelButtonText: this.$t("myTask.tip4"),
          customClass: "deleteBox",
        }
      )
        .then(async () => {
          try {
            await saveProject({
              projectGuid: item.projectGuid,
              isDeleted: true,
            });

            this.$refs.myPublished?.getData();
            
            this.getData();
          } catch (e) {
            console.log(e);
          }
        })
        .catch((action) => {});
    },
    async cancelRelease(item, index) {
      this.$confirm(
        // 已经获取该项目的用户将不能再使用此项目,是否下架?
        this.$t("myTask.tip5"),
        // 下架项目
        this.$t("myTask.tip6"),
        {
          distinguishCancelAndClose: true,
          // 下架
          confirmButtonText: this.$t("myTask.tip7"),
          // 取消
          cancelButtonText: this.$t("myTask.tip4"),
          customClass: "deleteBox",
        }
      )
      .then(async () => {
          try {
            if (item.releaseInfo) {
              await removeRelease(item.releaseInfo.releaseGuid);
            } else if (item.auditStatus) {
              await removeRelease(item.auditStatus.auditReleaseGuid);
            }
            else {
              await removeRelease(item.releaseGuid);
            }

            ElMessage.success({
              message: this.$t("myTask.tip10"),
              type: "success",
            });

            this.$refs.myPublished?.getData();
            this.getData();
          } catch (e) {
            console.log(e);
            ElMessage.error(this.$t("myTask.tip11"));
          }
        })
        .catch((action) => {});
    },
    async cancelReleaseAll(item, index) {
      this.$confirm(
        // 已经获取该项目的用户将不能再使用此项目,是否下架?
        this.$t("myTask.tip9"),
        // 下架项目
        this.$t("myTask.tip8"),
        {
          distinguishCancelAndClose: true,
          // 下架
          confirmButtonText: this.$t("myTask.tip7"),
          // 取消
          cancelButtonText: this.$t("myTask.tip4"),
          customClass: "deleteBox",
        }
      )
      .then(async () => {
          try {
            await removeAllReleaseByProject(item.projectGuid);
            ElMessage.success({
              message: this.$t("myTask.tip10"),
              type: "success",
            });
            
            this.$refs.myPublished.getData();
            this.getData();
          } catch (e) {
            console.log(e);
            ElMessage.error(this.$t("myTask.tip11"));
          }
        })
        .catch((action) => {});
    },
    userInfo() {
      this.$router.push("/myAccount");
    },
    enterProject(project) {
      console.log(project);
      if (this.selectItem === ListDataType.created) {
        
        this.$router.push({
          path: "/taskDetail",
          query: { projectGuid: project.projectGuid },
        });
      } else {
        
        this.$router.push({
          path: "/taskDetail",
          query: {
            projectGuid: project.projectGuid,
            releaseGuid: project.releaseGuid,
            releaseMode: true,
          },
        });
      }

      removeTaskGuid();
    },
    async changePageIndex(pageIndex) {
      this.page = pageIndex;
      this.getData();
    },

    handleSizeChange(size){
      this.page = 1;
      this.size = size;
      this.getData();
    },

    async doSelectDataType(dataType) {
      // console.log('select', ListDataTypeItem[dataType].name);
      //防止多次点击
      if (this.selectItem === dataType) {
        return;
      }
      this.selectItem = dataType
      this.page = 1;

      await this.getData();

      this.$router.replace(`/myTasks?tab=${ListDataTypeItem[dataType].name}`);

    },
    async getList(dataType, page, size) {
      // let result = [];
      let result = {data: [], total:0};
      switch (dataType) {
        case ListDataType.favourite:
          //我收藏的流程
          // 代码块;
          // result = await getMyFavourateProjects(this.page - 1, this.pageSize);
          result = await getMyFavourateProjects(page - 1, size);
          break;
        case ListDataType.owned:
          //已拥有（购买）的流程
          // 代码块;
          result = await getMyOwnProjects(page - 1, size);
          break;
        case ListDataType.created:
          //已创建的流程
          // 代码块;
          result = await getMyCreatedProjects(page - 1, size);
          break;
        case ListDataType.published:
          //已发布的流程
          // 代码块;
          result = await getMyReleaseProjects(page - 1, size);
          break;

        case ListDataType.audit:
          //待审核的流程
          // 代码块;
          result = await getAuditStat(page - 1, size);

          break;
      }
      // this.total = result.total;
      // this.data = result.data;
      return result;
    },
    editUserInfo() {
      // console.log(111);
    },

    jump() {
      console.log('jump')
      window.location.href = "/explore";
    },

    showAuditHistory(item) {
      this.$refs.auditHistory.open(true, item);
    }
  }
};
</script>

<style scoped lang="scss">
.accountHeader {
  background: -webkit-linear-gradient(#ebf5fd, #cbe4fe);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(#ebf5fd, #cbe4fe);
  /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(#ebf5fd, #cbe4fe);
  /* Firefox 3.6 - 15 */
  background: linear-gradient(#ebf5fd, #cbe4fe);
  // height: 12rem;
  padding-bottom: 18rem;
  // display: flex;
  // justify-content: center;
  position: relative;
  overflow: hidden;
  #headerWarp {
    position: absolute;
    z-index: 3;
  }
  .myBigHeadImage {
    width: 50rem;
    height: 50rem;
    border-radius: 25rem;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    overflow: hidden;
    opacity: 0.5;
    > img {
      width: 100%;
    }
  }
  .headerContent {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 1;
    bottom: 4rem;
    z-index: 2;
    width: 100%;
    > div {
      width: 70%;
      box-sizing: border-box;
      padding-left: 2rem;
      display: flex;
      justify-content: flex-start;
      .imgWarp {
        border-radius: 50%;
        overflow: hidden;
        width: 6rem;
        height: 6rem;
        background-color: #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        > img {
          width: 100%;
        }
      }
      > div {
        width: 15rem;
        height: 6rem;
        box-sizing: border-box;
        padding: 1rem 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        > div {
          width: 100%;
          text-align: left;
          box-sizing: border-box;
          overflow: hidden;
          padding-left: 3rem;
        }
        .username {
          font-size: 1.5rem;
          color: #1b476e;
        }
        .editUserinfo {
          font-size: 0.9rem;
          color: #6291ce;
          cursor: pointer;
        }
        .editUserinfo:hover {
          text-decoration: 1px underline #6291ce;
        }
      }
    }
  }
}
.itemType {
  width: 100%;
  height: 3rem;
  background-color: #f0f5fd;
  display: flex;
  justify-content: center;
  align-items: center;
  .contantWarp {
    width: 75%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > div {
      cursor: pointer;
      height: 100%;
      font-size: 0.85rem;
      color: #8a8a8a;
      position: relative;
      margin-right: 2.5rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .line {
        width: 50%;
        height: 0.2rem;
        background-color: #5380ed;
        border-radius: 0.25rem;
        display: none;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
      }
      > .icon {
        width: 20px;
        height: 20px;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
    > div:hover {
      color: #4582ff;
    }
    .active {
      color: #4582ff;
      font-weight: 500;
      .line {
        display: block;
      }
    }
    .littleMarginRight {
      margin-right: 30px;
    }
    .lineWrap {
      width: 1px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 25px;
      .HDLine {
        width: 1px;
        background: #c8cedd;
        height: 16px;
      }
    }
  }
}
.accountBody {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 23.5rem);
  flex-wrap: wrap;
  .enpty {
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      .createBtn {
        margin-top: 1rem;
        > button {
          width: 120px;
          height: 32px;
          background: #4582ff;
          border-radius: 4px 4px 4px 4px;
          outline: none;
          border: none;
          background: #4582ff;
          color: #fff;
          line-height: 32px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
  > .myPublishedContainer {
    padding: 0;
    width: 100%;
  }
  @media (min-width: 1920px) {
    > div {
      width: 80%;
      // display: flex;
      // justify-content: flex-start;
      // flex-wrap: wrap;
      padding: 2rem 2rem 4rem 2rem;
      position: relative;
      .myProjectItem {
        width: 18%;
        height: 0;
        // padding: 1rem;
        text-align: left;
        box-sizing: border-box;
        position: relative;
        padding-bottom: 16.5%;
        margin-right: 1%;
        margin-left: 1%;
        float: left;
        > div {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          .accountImgWarp {
            border: none;
            background-color: #ccc;
            width: 100%;
            overflow: hidden;
            border-radius: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60%;
            cursor: pointer;
            position: relative;
            > div {
              width: 15%;
              position: absolute;
              bottom: 6%;
              left: 4%;
              // background: #fff;
              border-radius: 14%;
              display: flex;
              justify-content: center;
              align-items: center;
              > div {
                width: 100%;
                > img {
                  width: 100%;
                }
              }
            }
            > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            img {
              width: 100%;
              // height: 5rem;
              border: none;
            }
          }
          > p {
            font-size: 0.7vw;
            font-weight: 600;
            line-height: 1.05vw;
            height: 2vw;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin-top: 5px;
            margin-bottom: 5px;
            color: #262626;
          }
          .operation {
            font-size: 0.7vw;
            color: #888;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 2%;
            :deep(.dropDown) {
              width: 2rem;
              height: 1rem;
              position: absolute;
              right: 0;
            }
            > span {
              // margin-right: 1.5vw;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              // width: 2vw;
              // height: 1vw;
              padding-right: 0.85vw;
              line-height: 1vw;
              > img {
                width: 0.7vw;
                height: 0.7vw;
                margin-right: 0.3vw;
              }
            }
            .more {
              margin: 0;
              position: absolute;
              right: 0;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              font-size: 0.9vw;
              cursor: pointer;
              top: -26%;
              > i {
                transform: rotate(-90deg);
              }
            }
          }
        }
      }
      .pages {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        position: absolute;
        bottom: 1rem;
        :deep(.el-pagination) {
          display: flex;
          justify-content: space-around;
          align-items: center;
          > button {
            background: #fff;
            color: #8fb5ff;
            border: 1px solid #dae6ff;
            border-radius: 10px;
            width: 60px;
            height: 38px;
          }
          > button:hover {
            border: 1px solid #4582ff;
          }
          > button[disabled] {
            border: 1px solid #dae6ff;
          }
          .el-pager {
            display: flex;
            justify-content: space-around;
            align-items: center;
            > li {
              height: 36px;
              padding: 0 1rem;
              background: #ffffff;
              border-radius: 10px;
              opacity: 1;
              border: 1px solid #dae6ff;
              color: #224b77;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 400;
            }
            > li:hover {
              border: 1px solid #4582ff;
            }
            .active {
              border: 1px solid #4582ff;
              background: #4582ff;
              color: #ffffff;
            }
          }
          > span {
            height: auto;
            > div {
              width: auto;
              height: auto;
              > input {
                background: #eff3fc;
                width: 60px;
                height: 36px;
                border-radius: 28px;
                color: #224b77;
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 0px) and (max-width: 1919px) {
    > div {
      width: 80%;
      // display: flex;
      // justify-content: flex-start;
      // flex-wrap: wrap;
      padding: 2rem 2rem 4rem 2rem;
      position: relative;
      .myProjectItem {
        width: 23%;
        height: 0;
        // padding: 1rem;
        text-align: left;
        box-sizing: border-box;
        position: relative;
        padding-bottom: 21.5%;
        margin-right: 1%;
        margin-left: 1%;
        float: left;
        > div {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          .accountImgWarp {
            border: none;
            background-color: #ccc;
            width: 100%;
            overflow: hidden;
            border-radius: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60%;
            cursor: pointer;
            position: relative;
            > div {
              width: 15%;
              position: absolute;
              bottom: 6%;
              left: 4%;
              // background: #fff;
              border-radius: 14%;
              display: flex;
              justify-content: center;
              align-items: center;
              > div {
                width: 100%;
                > img {
                  width: 100%;
                }
              }
            }
            > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            img {
              width: 100%;
              // height: 5rem;
              border: none;
            }
          }
          > p {
            font-size: 0.85vw;
            font-weight: 600;
            line-height: 1.1vw;
            height: 2.5vw;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin-top: 5px;
            margin-bottom: 5px;
            color: #262626;
          }
          .operation {
            font-size: 0.9vw;
            color: #888;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 2%;
            :deep(.dropDown) {
              width: 2rem;
              height: 1rem;
              position: absolute;
              right: 0;
            }
            > span {
              // margin-right: 1.5vw;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              // width: 2vw;
              // height: 1vw;
              padding-right: 0.85vw;
              line-height: 1vw;
              > img {
                width: 1vw;
                height: 1vw;
                margin-right: 0.3vw;
              }
            }
            .more {
              margin: 0;
              position: absolute;
              right: 0;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              font-size: 1.2vw;
              cursor: pointer;
              > i {
                transform: rotate(-90deg);
              }
            }
          }
        }
      }
      .pages {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        position: absolute;
        bottom: 1rem;
        :deep(.el-pagination) {
          display: flex;
          justify-content: space-around;
          align-items: center;
          > button {
            background: #fff;
            color: #8fb5ff;
            border: 1px solid #dae6ff;
            border-radius: 10px;
            width: 60px;
            height: 38px;
          }
          > button:hover {
            border: 1px solid #4582ff;
          }
          > button[disabled] {
            border: 1px solid #dae6ff;
          }
          .el-pager {
            display: flex;
            justify-content: space-around;
            align-items: center;
            > li {
              height: 36px;
              padding: 0 1rem;
              background: #ffffff;
              border-radius: 10px;
              opacity: 1;
              border: 1px solid #dae6ff;
              color: #224b77;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 400;
            }
            > li:hover {
              border: 1px solid #4582ff;
            }
            .active {
              border: 1px solid #4582ff;
              background: #4582ff;
              color: #ffffff;
            }
          }

          .el-input {
            width: auto;
            height: auto;

            .el-input__inner {
              background: #eff3fc;
              width: 60px;  
              // width: fit-content;
              height: 36px;
              border-radius: 28px;
              color: #224b77;
            }
          }

          .el-pagination__sizes{ 
            height: auto;           
            .el-input .el-input__inner {
              width: 80px;  
            }
          }

          .el-pagination__jump{
            height: auto;
            .el-input .el-input__inner {
                width: 60px;  
              }
          }
          // > span {
          //   height: auto;
          //   > div {
          //     width: auto;
          //     height: auto;
          //     > input {
          //       background: #eff3fc;
          //       width: 60px;
          //       height: 36px;
          //       border-radius: 28px;
          //       color: #224b77;
          //     }
          //   }
          // }
        }
      }
    }
    /* 当屏幕宽度在 0 到 1200 像素之间时应用的样式 */
    @media screen and (max-width: 1200px) {
      > div {
        > .myProjectItem {
          > div {
            > .accountImgWarp {
              border-radius: 0.4rem;
              > div {
                width: 25%;
              }
            }
            > p {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              font-size: 13px;
              line-height: 2.5vw;
            }
            > .operation {
              font-size: 10px;
              > span {
                > img {
                  width: 10px;
                  height: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.taskCtrlListWarp {
  padding: 0px 5px 0px 5px;
  width: 7rem;
  // line-height: 2rem;
  .btnWarp {
    padding: 0;
    button {
      // padding: 0 20px;
      display: block;
      outline: none;
      border: none;
      width: 100%;
      background: #fff;
      text-align: left;
      color: #274e78;
      font-size: 0.8rem;
      border-radius: 3px;
      cursor: pointer;
      line-height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      > span {
        font-size: 0.9rem;
        color: #8fb5ff;
      }
      > img {
        width: 1.5rem;
        line-height: 2rem;
        margin-right: 0.5rem;
      }
    }
    button[disabled] {
      // background: #ccc!important;
      color: #ccc !important;
      cursor: not-allowed;
    }
    button:hover {
      background: #ecf2ff;
    }
  }

  .btnWarp {
    .delete {
      background: #fff;
      border-radius: 4px 4px 4px 4px;
      color: #f94a4a;
    }
    .delete:hover {
      background: #ffecec;
    }
  }
  .el-dropdown-menu {
    padding: 5px 0;
  }
}
</style>
