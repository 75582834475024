<template>
  <div class="chartsWrap">
    <div class="date" v-if="releaseInfo">
      {{ getTime(releaseInfo.released_at) }}
    </div>
    <div
      ref="countChartDom"
      id="countChartDom"
      style="width: 100%; height: 100%"
    />
  </div>
</template>

<script>
import * as echarts from "echarts";
// import { provide } from 'vue'
import { getTopDurationStep } from "@/api/workflow.js";
var myCharts = "";
const moment = require("moment");
moment.locale("zh-cn");
export default {
  props: [],
  data() {
    return {
      releaseInfo: null,
    };
  },

  computed: {},
  created() {},
  mounted() {},
  watch: {},
  unmounted() {
    window.removeEventListener("resize", this.handelResize);
  },
  methods: {
    getTime(value) {
      return moment(value).format("L");
    },
    handelResize() {
      myCharts.resize();
    },
    async initChart(data) {
      return new Promise(async (resolve, reject) => {
        // console.log(data);
        this.releaseInfo = data;
        myCharts = echarts.init(this.$refs.countChartDom);
        window.addEventListener("resize", this.handelResize);
        let opt = {
          release_guid: data.release_guid,
        };
        this.options = options;
        // let dom = this.$refs.countChartDom;
        let options = {
          backgroundColor: "#F9FAFD",
          color: "#4582FF",
          title: {
            // 流程断点图
            text: this.$t("topDurationChart.word1"),
            left: "center",
            textStyle: {
              fontSize: 14,
              color: "#274E78",
            },
          },
          grid: {
            top: "14%",
            left: "5%",
            right: "5%",
            bottom: "3%",
            containLabel: true,
          },
          tooltip: {},
          xAxis: {
            axisLabel: {
              show: true,
              interval: 0,
              textStyle: {
                color: "#274E78",
                fontSize: 13,
              },
              rotate: 40,

              formatter: function(value) {
                if (value.length > 5) {
                  return value.slice(0, 5) + "...";
                } else {
                  return value;
                }
              },
            },
            triggerEvent: true, //鼠标悬浮显示全部
            // boundaryGap: true,
          },

          yAxis: {
            type: "value",
            // "时间(分钟)"
            name: this.$t("topDurationChart.word2"),
            axisLabel: {
              show: true,
              textStyle: {
                fontWeight: "500",
                fontSize: 14,
                color: "#274E78",
              },
            },
            axisTick: {
              show: true,
            },
            nameTextStyle: {
              fontWeight: "600",
              color: "#274E78",
              fontFamily: "Source Han Sans CN", // 字体
              fontSize: 14, // 大小
              padding: [0, 10, 0, 30],
            },
          },
          series: [
            {
              name: "",
              type: "bar",
              barWidth: "60%",
              // data: [1],
              animationDuration: 3000,
              barMaxWidth: "40",
            },
          ],
        };
        this.extension(myCharts);
        let res1 = await getTopDurationStep(opt);
        // console.log(res1);
        // this.$emit("topDurationDataChange", res1.result.data);
        options.xAxis.data = [];
        options.series[0].data = [];
        myCharts.clear();
        if (res1.data && res1.data.length > 0) {
          res1.data.forEach((value, index) => {
            options.xAxis.data.push(value.step_name);
            options.series[0].data.push(
              (value.all_duration / 1000 / 60).toFixed(3)
            );
          });
        }
        myCharts.setOption(options, true);
        console.log("321321312" + this.releaseInfo.project_name);
        console.log(options);
        console.log(options.series[0].data);
        console.log(res1);
        resolve();
      });

      // console.log(options);
    },
    extension(chart) {
      // 判断是否创建过div框,如果创建过就不再创建了
      // 该div用来盛放文本显示内容的，方便对其悬浮位置进行处理
      var elementDiv = document.getElementById("extension");
      if (!elementDiv) {
        var div = document.createElement("div");
        div.setAttribute("id", "extension");
        div.style.display = "block";
        document.querySelector("html").appendChild(div);
      }
      chart.on("mouseover", function(params) {
        if (params.componentType == "xAxis") {
          var elementDiv = document.querySelector("#extension");
          //设置悬浮文本的位置以及样式
          var elementStyle =
            "position: absolute;z-index: 99999;color: #000000;font-size: 12px;padding: 10px;display: inline;border-radius: 4px;background-color: #F9FAFD;box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2)";
          elementDiv.style.cssText = elementStyle;
          elementDiv.innerHTML = params.value;
          document.querySelector("html").onmousemove = function(event) {
            var elementDiv = document.querySelector("#extension");
            var xx = event.pageX - 10;
            var yy = event.pageY + 15;
            elementDiv.style.transition = "all 0.1s linear";
            elementDiv.style.top = yy + "px";
            elementDiv.style.left = xx + "px";
          };
        }
      });
      chart.on("mouseout", function(params) {
        //注意这里，如果是y轴的话，需要改为yAxis
        if (params.componentType == "xAxis") {
          var elementDiv = document.querySelector("#extension");

          elementDiv.style.cssText = "display:none";
          document.querySelector("html").onmousemove = null;
        }
      });
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
.chartsWrap {
  width: 100%;
  height: 100%;
  position: relative;
  .date {
    position: absolute;
    right: 4%;
    top: 1%;
    z-index: 1;
    color: #7b7e87;
    font-size: 12px;
  }
}
#countChartDom {
  > div {
    width: 100% !important;
    height: 100% !important;
    > canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
</style>
