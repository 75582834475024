<template>
  <div class="home">
    <div class="header">
      <Header />
      <div class="modelConctrl">
        <BannerLoading class="bannerLoadingComp" v-if="!webGLLoaded" />
        <iframe
          id="enterpriseView"
          ref="enterpriseView"
          :src="
            $store.state.TaskDetail.headerPath + 'enterpriseModel/index.html'
          "
          sandbox="allow-scripts allow-same-origin"
          frameborder="0"
          scrolling="no"
        ></iframe>
      </div>
      <div class="headerBodyWarp">
        <!-- v-if="webGLLoaded" -->
        <div class="paddingBox">
          <div class="headerBody">
            <div class="titleTip">
              <div>
                <!-- 不怕有新人 -->
                <p>{{ $t("enterprise.word1") }}</p>
                <!-- 复杂的操作流程变得所见即所得 -->
                <p>{{ $t("enterprise.word2") }}</p>
              </div>

              <div>
                <!-- 个人免费使用 -->
                <button @click="goRegiste">{{ $t("enterprise.word3") }}</button>
              </div>
              <div>
                <!-- 企业用户计划 -->
                <button @click="scrollPage('#callUs')">
                  {{ $t("enterprise.word4") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="canDo">
      <div class="title">
        <!-- 企业专属的XR流程指导平台 -->
        <div>{{ $t("enterprise.word5") }}</div>
        <!-- 技术的知识分享与交流 -->
        <div>{{ $t("enterprise.word6") }}</div>
      </div>
      <div class="canDoItem">
        <div>
          <div class="backgroundContainer"></div>
          <div class="contant">
            <!-- 轻松创建标准化流程指导 -->
            <p>{{ $t("enterprise.word7") }}</p>
            <!-- 轻松创建虚实结合指导流程 -->
            <p>{{ $t("enterprise.word8") }}</p>
            <p>
              <!-- 自由 -->
              <span>{{ $t("enterprise.word9") }}</span>
              <!-- 轻松 -->
              <span>{{ $t("enterprise.word10") }}</span>
            </p>
          </div>
        </div>
        <div>
          <div class="backgroundContainer"></div>
          <div class="contant">
            <!-- 支持全球头部企业级XR设备 -->
            <p>{{ $t("enterprise.word11") }}</p>
            <!-- 全球范围眼镜设备覆盖 -->
            <p>{{ $t("enterprise.word12") }}</p>
            <p>
              <span>{{ $t("enterprise.word13") }}</span>
              <span>{{ $t("enterprise.word39") }}</span>
              <span>{{ $t("enterprise.word40") }}</span>
            </p>
          </div>
        </div>
        <div>
          <div class="backgroundContainer"></div>
          <div class="contant">
            <!-- 虚实结合的可视化指导,答案就在问题边上 -->
            <p>{{ $t("enterprise.word14") }}</p>
            <!-- 流程问题，即时解决 -->
            <p>{{ $t("enterprise.word15") }}</p>
            <p>
              <!-- 无忧 -->
              <span>{{ $t("enterprise.word16") }}</span>
              <!-- 流畅 -->
              <span>{{ $t("enterprise.word17") }}</span>
            </p>
          </div>
        </div>
        <div>
          <div class="backgroundContainer"></div>
          <div class="contant">
            <!-- 支持手机设备AR查看 -->
            <p>{{ $t("enterprise.word18") }}</p>
            <!-- 掏出手机便可使用 -->
            <p>{{ $t("enterprise.word19") }}</p>
            <p>
              <!-- 便捷 -->
              <span>{{ $t("enterprise.word20") }}</span>
              <!-- iOS -->
              <span>{{ $t("enterprise.word21") }}</span>
              <!-- Android -->
              <span>{{ $t("enterprise.word22") }}</span>
            </p>
          </div>
        </div>
        <div>
          <div class="backgroundContainer"></div>
          <div class="contant">
            <!-- 超过10w+全家可用素材 -->
            <p>{{ $t("enterprise.word23") }}</p>
            <!-- 轻松创建虚实结合指导流程 -->
            <p>{{ $t("enterprise.word24") }}</p>
            <p>
              <!-- 自主 -->
              <span>{{ $t("enterprise.word25") }}</span>
              <!-- 分类 -->
              <span>{{ $t("enterprise.word26") }}</span>
              <!-- 收藏 -->
              <span>{{ $t("enterprise.word27") }}</span>
            </p>
          </div>
        </div>
        <div>
          <div class="backgroundContainer"></div>
          <div class="contant">
            <!-- 兼容VR设备的虚拟操作流程 -->
            <p>{{ $t("enterprise.word28") }}</p>
            <!-- 虚拟现实呈现全流程解决方案 -->
            <p>{{ $t("enterprise.word29") }}</p>
            <p>
              <!-- VR -->
              <span>{{ $t("enterprise.word30") }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="demoVideo">
      <div class="demoVideoTitle">
        <!-- 让用户手册不再生涩难懂 -->
        <div>{{ $t("enterprise.word31") }}</div>
        <div>
          <!-- 企业可以使用JTWorkflow面向终端用户发布产品的XR流程指导，提升用户对产品的接收程度 -->
          {{ $t("enterprise.word32") }}
        </div>
      </div>
      <div class="videoWarp">
        <div class="video">
          <video
            muted
            controls
            :src="$store.state.TaskDetail.headerPath + 'video/workflow1.mp4'"
          ></video>
        </div>
      </div>
      <div class="buttonWarp">
        <button @click="scrollPage('#callUs')">
          <!-- 马上体验 -->
          {{ $t("enterprise.word33")
          }}<img src="@/assets/enterprise/go.png" alt="" />
        </button>
      </div>
    </div>
    <!-- <div class="share">
      <div class="shareTitle">
        <div>分享你的流程和知识并从中获利</div>
        <div>多种方式的分享你的经验</div>
      </div>
      <div class="shareContant">
        <div class="shareItem" v-for="item in 10" :key="item">
          <div class="imgWarp">
            <img src="" alt="" />
          </div>
          <p class="itemName">家具拼装流程</p>
          <div class="operation">
            <span> 点赞 </span>
            <span> 评论 </span>
            <span> 转发 </span>
            <span> 更多 </span>
          </div>
        </div>
      </div>
      <div class="joinBtn">
        <button>马上加入</button>
      </div>
    </div> -->
    <div class="collaborator">
      <div class="collaboratorTitle">
        <!-- 为全球各大企业所信任 -->
        <div>{{ $t("enterprise.word34") }}</div>
        <!-- 已服务过全球各行业客户 -->
        <div>{{ $t("enterprise.word35") }}</div>
      </div>
      <div class="collaboratorItemWarp">
        <div :class="marginLeft(item)" v-for="item in 23" :key="item">
          <div>
            <img
              :src="
                $store.state.TaskDetail.headerPath +
                  'picture/cooperativePartner/cooperativePartner' +
                  item +
                  '.png'
              "
              alt=""
            />
          </div>
        </div>
        <div class="collaboratorItem">
          <div></div>
        </div>
      </div>
      <!-- <div class="collaboratorBtn">
        <button>获取企业用户专属计划</button>
      </div> -->
    </div>
    <div id="callUs" class="callUs">
      <div>
        <div class="callUsTitle">
          <!-- 联系我们获取企业专属解决方案 -->
          <div class="animate__slideOutLeft animate__slideOutLeft">
            {{ $t("enterprise.word36") }} <i class="el-icon-right"></i>
          </div>
          <!-- 支持MR、AR、VR多平台客户端使用，无拘无束 -->
          <div>{{ $t("enterprise.word37") }}</div>
        </div>

        <div class="callUsForm">
          <div class="formWarp">
            <Form />
          </div>
        </div>
      </div>
      <div class="callUsContant">
        <div class="bannerWarp">
          <!-- 合作企业 -->
          <div class="cooperativeEnterprise">{{ $t("enterprise.word38") }}</div>
          <Banner />
        </div>
      </div>
    </div>
    <Footer v-if="language !== 'en'" />
  </div>
</template>

<script>
import Header from "@/views/components/header/header.vue";
import Footer from "@/views/components/footer/footer.vue";
import Form from "./form/form.vue";
import Banner from "./banner/banner.vue";
// import { ElMessage } from "element-plus";
import BannerLoading from "@/views/components/animation/enterprisePageBanner.vue";
// import { getLanguage } from "@/utils/getLanguage.js";
import LangManager from "../../utils/langManager";
export default {
  components: { Footer, Header, Form, Banner, BannerLoading },
  data() {
    return {
      language: LangManager.getLanguage().value,
      viewType: 0,
      showIframe: false,
      webGLLoaded: false,
      // iframeSrc: "enterpriseModel/index.html",
    };
  },
  created() {
    console.log(this.$store.state);
    if (this.$route.query.goForm === "true") {
      this.$nextTick(() => {
        // document.getElementById("scrollMainBody").className = "el-main scrolls";
        this.scrollPage("#callUs");
        this.$router.replace("/enterprisePage");
        console.log(this.$router);
      });
    }
  },
  // mounted() {
  //   this.showIframe = true;
  //   this.$nextTick(() => {
  //     console.log(this.$refs.enterpriseView);
  //     let dom = document.getElementById("enterpriseView");
  //     console.log(dom);
  //     this.$refs.enterpriseView.src = this.iframeSrc;
  //   });
  // },
  unmounted() {
    window.removeEventListener("message", this.receiveMessage);
  },
  mounted() {
    window.addEventListener("message", this.receiveMessage, false);
  },
  methods: {
    receiveMessage(event) {
      if (event.data.type === "loadingProgress") {
        if (event.data.value === 1) {
          setTimeout(() => {
            this.webGLLoaded = true;
          }, 2000);
        }
      }
    },
    scrollPage(domName) {
      //index与循环的index对应可自由设置动态获取
      const PageId = document.querySelector(domName);
      PageId.scrollIntoView();
    },
    goRegiste() {
      if (this.$store.state.User.accountId) {
        this.$router.push("/explore");
        return;
      }
      this.$store.commit("SET_ACCOUNTSIGN", "register");
      this.$store.commit("SET_SHOWlOGIN", true);
    },
    download() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
      window.location.hash = "#download";
    },
    marginLeft(item) {
      if (item / 6 >= 1 && item % 6 === 1 && parseInt(item / 6) % 2 !== 0) {
        return "collaboratorItem lefts";
      } else if (item % 6 === 0 && parseInt(item / 6) % 2 !== 0) {
        return "collaboratorItem rights";
      } else {
        return "collaboratorItem";
      }
    },
  },
};
</script>
<style scoped lang="scss">
.home {
  .header {
    // background: -webkit-linear-gradient(#ebf5fd, #cbe4fe);
    // /* Safari 5.1 - 6.0 */
    // background: -o-linear-gradient(#ebf5fd, #cbe4fe);
    // /* Opera 11.1 - 12.0 */
    // background: -moz-linear-gradient(#ebf5fd, #cbe4fe);
    // /* Firefox 3.6 - 15 */
    // background: linear-gradient(#ebf5fd, #cbe4fe);
    background: linear-gradient(
      180deg,
      rgba(255, 251, 245, 0.33000001311302185) 0%,
      #ffe4b8 100%
    );
    // height: 32rem;
    padding: 0 2rem 2rem 2rem;
    position: relative;
    background: #fff;
    // width: 80%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    .modelConctrl {
      width: 100%;
      // height: 30rem;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      .bannerLoadingComp {
        position: absolute;
        z-index: 2;
        // background: linear-gradient(180deg, #fffefc, #ffe4b8);
        background: #efefef;
      }
      > iframe {
        width: 100%;
        height: 100%;
      }
    }
    > .headerBodyWarp {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem 0;
      position: relative;
      padding-bottom: 28%;
      width: 50%;
      .paddingBox {
        width: 100%;

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: right;
        > .headerBody {
          width: 75%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          position: relative;
          .titleTip {
            width: 35%;
            z-index: 3;
            > div:nth-child(1) {
              height: 8vw;
              //  background-color: #ccc;
              margin-top: 3vw;
              position: relative;
              z-index: 2;
              > p {
                text-align: left;
                word-wrap: normal;
                transition: all 0.3s;
                width: 100%;
                margin: 0;
              }
              > p:nth-child(1) {
                width: 46vw;
                // color: #f7b74f;
                color: #181818;
                // font-size: 2rem;
                font-size: 2.1vw;
                font-weight: 700;
                // letter-spacing: 6px;
              }
              > p:nth-child(2) {
                width: 45vw;
                // font-size: 1.2rem;
                font-size: 1.2vw;
                margin-top: 1vw;
                // color: #aaa;
                color: #181818;
              }
            }
            // > div:nth-child(1):hover {
            //   > p:nth-child(1) {
            //     width: 70rem;
            //     font-size: 3.5rem;
            //   }
            //   > p:nth-child(2) {
            //     width: 70rem;
            //     font-size: 1.5rem;
            //   }
            // }
            > div {
              margin-top: 2.5vw;
              text-align: left;
              z-index: 2;
              > button {
                width: 12vw;
                height: calc(2.5vw + 8px);
                border-radius: calc(1.25vw + 4px);
                outline: none;
                background-color: #fff;
                border: 1px solid #fff;
                color: #fff;
                font-size: 0.9vw;
                cursor: pointer;
                transition: background 0.3s;
              }
            }
            > div:nth-child(2) {
              text-align: left;
              > button {
                background-color: #699bff;
                border: 4px solid #edf4ff;
              }
              > button:hover {
                background: #8fb5ff;
              }
            }
            > div:nth-child(3) {
              margin-top: 1.5rem;
              text-align: left;
              > button {
                background-color: #dfb574;
                border: 4px solid #ffe4b8;
              }
              > button:hover {
                background: #e7c796;
              }
            }
          }
          .titleModel {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30rem;
            position: absolute;
            height: 100%;
          }
        }
      }
    }
  }
  .canDo {
    background-color: #fff;
    .title {
      padding: 8rem 0 4rem 0;
      > div:nth-child(1) {
        // color: #7b4e08;
        color: #181818;
        font-size: 2.6rem;
        font-weight: 700;
      }
      > div:nth-child(2) {
        // color: #7b4e08;
        color: #181818;
        font-size: 1.2rem;
        margin-top: 1rem;
      }
    }
    .canDoItem {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 99vw; //100vw会出现横向滚动条
      height: 31.09vw;
      position: relative;
      > div {
        box-sizing: border-box;
        width: 50%;
        height: 33%;
        display: flex;
        justify-content: left;
        align-items: center;
        // background-color: #ccc;
        overflow: hidden;
        > .backgroundContainer {
          z-index: 1;
          padding: 0 !important;
          width: 100%;
          height: 100%;
          transform: scale(1.06);
          // background: hsla(0, 0%, 100%, 0);
          opacity: 0;
          transform-origin: 50% 50%;
          transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
            opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        > .contant {
          position: absolute;
          padding-left: 6rem;
          z-index: 2;
          background: rgba(0, 0, 0, 0);
          > p {
            text-align: left;
            margin-top: 0.5rem;
            margin-bottom: 0;
            width: 30rem;
          }
          > p:nth-child(1) {
            font-weight: 700;
            font-size: 1.5rem;
            color: #333;
          }
          > p:nth-child(2) {
            font-size: 0.95rem;
            color: #999;
          }
          > p:nth-child(3) {
            margin-top: 1rem;
            span {
              // color: #7b4e08;
              color: #ff9900;
              font-size: 0.85rem;
              margin-right: 1.5rem;
            }
          }
        }
      }
      > div:nth-child(5),
      > div:nth-child(6) {
        border-bottom: 1px solid #efefef;
        height: 34%;
      }
      > div:nth-child(1),
      > div:nth-child(3),
      > div:nth-child(5) {
        border-top: 1px solid #efefef;
        > div {
          padding-left: 13vw;
        }
      }
      > div:nth-child(2),
      > div:nth-child(4),
      > div:nth-child(6) {
        border-top: 1px solid #efefef;
        border-left: 1px solid #efefef;
        // > div {
        //   padding-left: 14rem;
        // }
      }
      > div:nth-child(1):hover .backgroundContainer {
        background: url("../../assets/enterprise/bg1.png");
        background-size: 100% 100%;
        background-size: cover;
        background-repeat: no-repeat;
        // background-attachment: fixed; /*关键*/
        background-position: center;
        background-position: center;
        transform: scale(1);
        opacity: 1;
        transform-origin: 50% 50%;
        transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
          opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      > div:nth-child(2):hover .backgroundContainer {
        background: url("../../assets/enterprise/bg2.png");
        background-size: 100% 100%;
        background-size: cover;
        background-repeat: no-repeat;
        // background-attachment: fixed; /*关键*/
        background-position: center;
        background-position: center;
        transform: scale(1);
        opacity: 1;
        transform-origin: 50% 50%;
        transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
          opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      > div:nth-child(3):hover .backgroundContainer {
        background: url("../../assets/enterprise/bg3.png");
        background-size: 100% 100%;
        background-size: cover;
        background-repeat: no-repeat;
        // background-attachment: fixed; /*关键*/
        background-position: center;
        background-position: center;
        transform: scale(1);
        opacity: 1;
        transform-origin: 50% 50%;
        transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
          opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      > div:nth-child(4):hover .backgroundContainer {
        background: url("../../assets/enterprise/bg4.png");
        background-size: 100% 100%;
        background-size: cover;
        background-repeat: no-repeat;
        // background-attachment: fixed; /*关键*/
        background-position: center;
        background-position: center;
        transform: scale(1);
        opacity: 1;
        transform-origin: 50% 50%;
        transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
          opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      > div:nth-child(5):hover .backgroundContainer {
        background: url("../../assets/enterprise/bg5.png");
        background-size: 100% 100%;
        background-size: cover;
        background-repeat: no-repeat;
        // background-attachment: fixed; /*关键*/
        background-position: center;
        background-position: center;
        transform: scale(1);
        opacity: 1;
        transform-origin: 50% 50%;
        transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
          opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      > div:nth-child(6):hover .backgroundContainer {
        background: url("../../assets/enterprise/bg6.png");
        background-size: 100% 100%;
        background-size: cover;
        background-repeat: no-repeat;
        // background-attachment: fixed; /*关键*/
        background-position: center;
        background-position: center;
        transform: scale(1);
        opacity: 1;
        transform-origin: 50% 50%;
        transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
          opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
  .demoVideo {
    padding: 5rem 0;
    background: url("../../assets/enterprise/demoBg.png") no-repeat center
      center;
    background-size: 100% 100%;
    background-color: #fbfaf8;
    .demoVideoTitle {
      padding: 3rem 0 4rem 0;
      > div:nth-child(1) {
        // color: #7b4e08;
        color: #181818;
        font-weight: 700;
        font-size: 2.6rem;
      }
      > div:nth-child(2) {
        margin-top: 1rem;
        // color: #7b4e08;
        color: #181818;
        font-size: 1.2rem;
      }
    }
    .videoWarp {
      width: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
      .video {
        width: 70rem;
        // height: 40rem;
        background-color: #333;
        > video {
          width: 100%;
        }
      }
    }
    .buttonWarp {
      text-align: center;
      margin: 3rem 0 3rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 10rem;
        height: 3rem;
        outline: none;
        border: 4px solid #fff9f0;
        background-color: #deb573;
        color: #fff;
        font-size: 0.8rem;
        border-radius: 1.5rem;
        transition: background 0.3s;
        > img {
          margin-left: 0.3rem;
          width: 1rem;
        }
      }
      > button:hover {
        background: #e7c796;
        // border: 4px solid #e7c796;
      }
    }
  }
  .share {
    background-color: #fff;
    .shareTitle {
      // padding: 2rem 0;
      padding: 8rem 0 4rem 0;
      > div:nth-child(1) {
        // color: #7b4e08;
        color: #181818;
        font-weight: 700;
        font-size: 2.6rem;
      }
      > div:nth-child(2) {
        margin-top: 1rem;
        // color: #7b4e08;
        color: #181818;
        font-size: 1.2rem;
      }
    }
    .shareContant {
      padding: 0 6rem;
      display: flex;
      justify-content: space-between;
      align-content: center;
      flex-wrap: wrap;
      > div {
        width: calc(20% - 2rem);
        box-sizing: border-box;
        margin: 1rem;
        text-align: left;
      }
      .shareItem {
        .imgWarp {
          width: 100%;
          overflow: hidden;
          border-radius: 1rem;
          background-color: #333;
          > img {
            width: 100%;
            height: 10rem;
          }
        }
        .operation {
          font-size: 0.8rem;
          color: #888;
          span {
            margin-right: 1rem;
          }
        }
      }
    }
    .joinBtn {
      padding: 1.5rem 0 5rem 0;
      > button {
        cursor: pointer;
        width: 9rem;
        height: 2.5rem;
        outline: none;
        border: 1px solid #fff;
        background-color: #699bfe;
        color: #fff;
        font-size: 0.8rem;
        border-radius: 1.25rem;
      }
    }
  }
  .collaborator {
    background: #fff;
    .collaboratorTitle {
      padding: 8rem 0 0rem 0;
      > div:nth-child(1) {
        // color: #7b4e08;
        color: #181818;
        font-weight: 700;
        font-size: 2.6rem;
      }
      > div:nth-child(2) {
        margin-top: 1rem;
        // color: #7b4e08;
        color: #181818;
        font-size: 1.2rem;
      }
    }
    .collaboratorItemWarp {
      background: url("../../assets/enterprise/map.png") no-repeat center center;
      background-size: 100% 114%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 80%;
      margin-left: 10%;
      padding: 10rem 0;

      > div {
        width: 14%;
        background-color: rgba(0, 0, 0, 0);
        margin-top: 3%;
        overflow: hidden;
        border-radius: 8px;
        box-sizing: border-box;
        // padding: 0 1rem;
        box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.06);
        background: #fff;
        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          overflow: hidden;
          > img {
            width: 100%;
            background: #fff;
          }
        }
      }
      .lefts {
        margin-left: 5%;
      }
      .rights {
        margin-right: 5%;
      }
    }
    .collaboratorBtn {
      margin: 2.5rem 0 5rem 0;
      > button {
        cursor: pointer;
        width: 15rem;
        height: 2.5rem;
        outline: none;
        border: 4px solid #ffe4b8;
        background-color: #e7c796;
        color: #fff;
        font-size: 0.8rem;
        border-radius: 1.25rem;
        transition: background 0.3s, border 0.3s;
      }
      > button:hover {
        background: #e7c796;
        // border: 4px solid #e7c796;
      }
    }
  }
  .callUs {
    padding: 4rem 0 0 0;
    margin-bottom: 7rem;
    background: linear-gradient(111deg, #fae7cc 0%, #fbf9f8 86%);
    position: relative;
    > div:nth-child(1) {
      display: flex;
      justify-content: left;

      width: 60%;
      margin-left: 13.5%;
      .callUsTitle {
        padding: 4rem 0 9rem 0;
        text-align: left;
        > div:nth-child(1) {
          font-size: 2.6rem;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          // color: #7b4e08;
          color: #181818;
          > i {
            color: #f7b74f;
            font-size: 2.6rem;
            margin-left: 1.5rem;
          }
        }
        > div:nth-child(2) {
          margin-top: 1rem;
          font-size: 1.2rem;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          // color: #7b4e08;
          color: #181818;
        }
      }

      .callUsForm {
        position: absolute;
        bottom: -2%;
        right: 15%;
        width: 25%;
        height: 90%;
        background: #ffffff;
        box-shadow: 0px 42px 44px 1px rgba(46, 35, 26, 0.23000000417232513);
        border-radius: 16px 16px 16px 16px;
        z-index: 5;
        .formWarp {
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          padding: 3rem;
          .selfFormItem {
            margin-bottom: 1rem;
            > div:nth-child(1) {
              color: #7c5f32;
              text-align: left;
              font-weight: 400;
            }
            > div:nth-child(2) {
              margin-top: 0.8rem;
            }
          }
        }
      }
    }
    .callUsContant {
      padding: 5rem 0 5rem 0;
      display: flex;
      justify-content: center;
      background: #fff;

      .bannerWarp {
        width: 73%;
        // box-sizing: border-box;
        // padding-right: 14%;
        // height: 18rem;
        .cooperativeEnterprise {
          margin-bottom: 3rem;
          font-size: 1rem;
          line-height: 32px;
          padding-left: 1rem;
          text-align: left;
          color: #7b4e08;
          width: 389px;
          height: 32px;
          background: linear-gradient(
            90deg,
            #f9ead5 0%,
            rgba(255, 255, 255, 0) 100%
          );
        }
      }
    }
  }
  .footer {
    padding: 0.5rem 0;
    > p {
      > a {
        text-decoration: none;
        color: #333;
        transition: all 0.3s;
      }
      > a:hover {
        color: #4984f5;
      }
    }
  }
}
/* 当屏幕宽度在 0 到 1200 像素之间时应用的样式 */
@media screen and (max-width: 1200px) {
  .home {
    .header {
      > .headerBodyWarp {
        padding-bottom: 18%;
        > .paddingBox {
          > .headerBody {
            > .titleTip {
              > div {
                margin-top: 0;
                > button {
                  margin-top: 1.5vw;
                  font-size: 12px;
                  min-width: 140px;
                }
              }
              > div:nth-child(1) {
                margin-top: 0.5vw;
                > button {
                  border: 2px;
                }
                > p:nth-child(2) {
                  width: 35vw;
                }
              }
              > div:nth-child(2) {
                margin-top: 1.5vw;
                > button {
                  border: 2px;
                }
              }
              > div:nth-child(3) {
                margin-top: 0;
                > button {
                  border: 2px;
                }
              }
            }
          }
        }
      }
    }
    > .canDo {
      overflow: hidden;
      > .title {
        padding: 4rem 0 4rem 0;
        > div:nth-child(1) {
          font-size: 1.6rem;
        }
        > div:nth-child(2) {
          font-size: 0.9rem;
        }
      }
      > .canDoItem {
        > div {
          // >.backgroundContainer{

          // }
          > .contant {
            padding-left: 3rem;
            width: 49%;
            height: 33.33%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            > p {
              width: 100%;
            }
            > p:nth-child(1) {
              font-size: 0.7rem;
              margin-top: 0;
            }
            > p:nth-child(2) {
              font-size: 0.6rem;
            }
            > p:nth-child(3) {
              margin-top: 0;
              span {
                font-size: 0.5rem;
              }
            }
          }
        }
      }
    }
    > .demoVideo,
    > .share {
      > .demoVideoTitle,
      > .shareTitle {
        padding: 1rem 0 1rem 0;
        // font-size: 1.6rem;
        > div:nth-child(1) {
          font-size: 1.6rem;
        }
        > div:nth-child(2) {
          font-size: 0.9rem;
        }
      }
    }
    > .share {
      > .shareContant {
        > .shareItem {
          > div {
            > .expImgWarp {
              border-radius: 0.4rem;
              > div {
                width: 25%;
              }
            }
            > p {
              font-size: 0.7rem;
              line-height: 2.5vw;
            }
          }
        }
      }
      // >.joinBtn{
      //   padding-bottom: 3rem;
      // }
    }
    > .collaborator {
      > .collaboratorTitle {
        padding: 4rem 0 0 0;
        > div:nth-child(1) {
          font-size: 1.6rem;
        }
        > div:nth-child(2) {
          font-size: 0.9rem;
        }
      }
      > .collaboratorItemWarp {
        padding: 5rem 0;
        > .collaboratorItem {
          border-radius: 4px;
        }
      }
      > .collaboratorBtn {
        margin: 1.5rem 0 5rem 0;
      }
    }
    > .callUs {
      width: 100%;
      > div:nth-child(1) {
        width: 46%;
        > .callUsTitle {
          > div:nth-child(1) {
            font-size: 1.2rem;
          }
          > div:nth-child(2) {
            font-size: 0.9rem;
          }
        }
        > .callUsForm {
          width: 40%;
          right: 2%;
          height: 68%;
          > .formWarp {
            padding: 1.5rem;
          }
        }
      }
    }
  }
}
</style>
