import { h } from "@logicflow/core";
import { RectResize } from "@logicflow/extension";
class CustomDiamondModel extends RectResize.model {
    initNodeData(data) {
        super.initNodeData(data);
        this.width = 160;
        this.height = 64;
    }
}
class HexagonView extends RectResize.view { }
export default {
    type: "temp",
    view: HexagonView,
    model: CustomDiamondModel
};
