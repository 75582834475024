import AccountIndex from "@/views/account/index.vue";
import AccountUpsert from "@/views/account/upsert.vue";
import Explore from "@/views/explore/explore.vue";
import myTasks from "@/views/myTasks/myTasks.vue";
import WebGL from "@/views/webGL/webGL.vue";
// import TaskDetail from "@/views/taskDetail/taskDetail.vue";
import TaskDetailNew from "@/views/taskDetail/taskDetailNew.vue"
// import TaskDetailNew1 from "@/views/taskDetail/taskDetailNew1.vue"
import MyAccount from "@/views/myAccount/myAccount.vue";
import Enterprise from "@/views/enterprise/enterprise.vue";
import Test from "@/views/test/test.vue";
import ModelView from "@/views/components/modelLoader/test.vue";
import DataCharts from "@/views/dataCharts/dataCharts.vue";

import PrevTemplate2D from "@/views/prevTemplate2D/prevTemplate2D.vue";
// import ModelView1 from "@/views/components/modelLoader/test1.vue";
import AuditProject from "@/views/auditProject/auditProject.vue";
import PublishRoles from "@/views/publishRoles/publishRoles.vue";
const workflowRouters = [
    {
        name: "explore",
        path: "/explore",
        component: Explore,
        meta: {
            // 探索
            name: "routers.explore",
            isVisible: true,
            showHeader: true,
            icon: "el-icon-user-solid",
            parent: "/",
            activePath: "/explore",
            permissions: [1, 2],
        },
    },
    {
        name: "modelView",
        path: "/modelView",
        component: ModelView,
        meta: {
            // 视图
            name: "routers.modelView",
            isVisible: true,
            showHeader: true,
            icon: "el-icon-user-solid",
            parent: "/",
            activePath: "/modelView",
            permissions: [1, 2],
        },
    },
    // {
    //     name: "modelView1",
    //     path: "/modelView1",
    //     component: ModelView1,
    //     meta: {
    //         name: "视图1",
    //         isVisible: true,
    //         showHeader: true,
    //         icon: "el-icon-user-solid",
    //         parent: "/",
    //         activePath: "/modelView1",
    //         permissions: [1, 2],
    //     },
    // },
    {
        name: "enterprisePage",
        path: "/enterprisePage",
        component: Enterprise,
        meta: {
            // 企业用户
            name: "routers.enterprise",
            isVisible: true,
            showHeader: true,
            icon: "el-icon-user-solid",
            parent: "/",
            activePath: "/enterprisePage",
            permissions: [1, 2],
        },
    },
    {
        name: "myAccount",
        path: "/myAccount",
        component: MyAccount,
        meta: {
            // 我的账户
            name: "routers.myAccount",
            isVisible: true,
            showHeader: true,
            icon: "el-icon-user-solid",
            parent: "/",
            activePath: "/myAccount",
            permissions: [1, 2],
        },
    },
    {
        name: "myTasks",
        path: "/myTasks",
        component: myTasks,
        meta: {
            // 我的流程
            name: "routers.myTasks",
            isVisible: true,
            showHeader: true,
            icon: "el-icon-user-solid",
            parent: "/",
            activePath: "/myTasks",
            permissions: [1, 2],
        },
    },
    {
        name: "auditProject",
        path: "/auditProject",
        component: AuditProject,
        meta: {
            // 项目审核
            name: "routers.auditProject",
            isVisible: true,
            showHeader: true,
            icon: "el-icon-user-solid",
            parent: "/",
            activePath: "/auditProject",
            permissions: [68],
        },
    },
    {
        name: "publishRoles",
        path: "/publishRoles",
        component: PublishRoles,
        meta: {
            // 企业用户
            name: "routers.publishRoles",
            isVisible: true,
            showHeader: true,
            icon: "el-icon-user-solid",
            parent: "/",
            activePath: "/publishRoles",
            permissions: [1, 2],
        },
    },
    {
        name: "WebGL",
        path: "/webGL",
        component: WebGL,
        meta: {
            // 模型
            name: "routers.webGl",
            isVisible: true,
            showHeader: true,
            icon: "el-icon-user-solid",
            parent: "/",
            activePath: "/webGL",
            permissions: [1, 2],
        },
    },
    {
        name: "PrevTemplate2D",
        path: "/prevTemplate2D",
        component: PrevTemplate2D,
        meta: {
            // 2D模板
            name: "2D模板",
            isVisible: true,
            showHeader: true,
            icon: "el-icon-user-solid",
            parent: "/",
            activePath: "/prevTemplate2D",
            permissions: [1, 2],
        },
    },
    // {
    //     name: "taskDetail",
    //     path: "/taskDetail",
    //     component: TaskDetail,
    //     meta: {
    //         name: "流程图",
    //         isVisible: true,
    //         showHeader: true,
    //         icon: "el-icon-user-solid",
    //         parent: "/",
    //         activePath: "/taskDetail",
    //         permissions: [1, 2],
    //     },
    // },
    {
        name: "taskDetail",
        path: "/taskDetail",
        component: TaskDetailNew,
        meta: {
            // 新流程图  任务详情
            name: "routers.taskDetail",
            isVisible: true,
            showHeader: false,
            icon: "el-icon-user-solid",
            parent: "/",
            activePath: "/taskDetail",
            permissions: [1, 2],
        },
    },
    // {
    //     name: "taskDetail1",
    //     path: "/taskDetail",
    //     component: TaskDetailNew,
    //     meta: {
    //         // 新流程图
    //         name: "新流程图",
    //         isVisible: true,
    //         showHeader: false,
    //         icon: "el-icon-user-solid",
    //         parent: "/",
    //         activePath: "/taskDetail",
    //         permissions: [1, 2],
    //     },
    // },
    // {
    //     name: "test",
    //     path: "/test",
    //     component: Test,
    //     meta: {
    //         // 新流程图
    //         name: "新流程图",
    //         isVisible: true,
    //         showHeader: false,
    //         icon: "el-icon-user-solid",
    //         parent: "/",
    //         activePath: "/test",
    //         permissions: [1, 2],
    //     },
    // },
    // {
    //     name: "taskDetail2",
    //     path: "/taskDetail/:id/:taskGuid",
    //     component: TaskDetailNew,
    //     meta: {
    //         name: "新流程图",
    //         isVisible: true,
    //         showHeader: false,
    //         icon: "el-icon-user-solid",
    //         parent: "/",
    //         activePath: "/taskDetail",
    //         permissions: [1, 2],
    //     },
    // },
    /*
       * 账号
       */
    {
        name: "account",
        path: "/account",
        component: AccountIndex,
        meta: {
            // 用户账号管理
            name: "routers.accounts",
            isVisible: true,
            showHeader: true,
            icon: "el-icon-user-solid",
            parent: "/group-system",
            activePath: "/account",
            permissions: [1, 2],
        },
    },
    {
        name: "account-create",
        path: "/account/new",
        component: AccountUpsert,
        meta: {
            // 新建用户账号
            name: "routers.newAccount",
            parent: "/account",
            activePath: "/account",
            permissions: [1, 2],
        },
    },
    {
        name: "account-update",
        path: "/account/:id",
        component: AccountUpsert,
        meta: {
            // 编辑用户账号
            name: "routers.updateAccount",
            parent: "/account",
            activePath: "/account",
            permissions: [1, 2],
        },
    },
    {
        name: "dataCharts",
        path: "/dataCharts",
        component: DataCharts,
        meta: {
            // 编辑用户账号
            name: "routers.dataCharts",
            parent: "/dataCharts",
            activePath: "/dataCharts",
            permissions: [1, 2],
        },
    },
]



export default workflowRouters;