<template>
  <div id="deleteProject">
    <!-- <div class="212"></div> -->
    <div id="releaseWarp">
      <!-- 发布项目 -->
      <el-dialog
        :title="$t('releaseProject.title')"
        v-model="show"
        width="500px"
        :before-close="handleClose"
        custom-class="releaseWarp"
        @open="open"
      >
        <div class="contants" v-if="show">
          <!-- 发布此项目让其他用户（取决于以下可见范围选择）可以按照您设计的流程进行操作，更高效地解决问题。 -->
          <p>
            {{ $t("releaseProject.word1") }}
          </p>
          <p>
            {{ $t("releaseProject.word8") }}
            <span @click="openPublishRules">{{
              $t("releaseProject.word9")
            }}</span>
            {{ $t("releaseProject.word10") }}
          </p>
          <!-- 项目发布之后，您还可以对您的流程/项目进行修改，您的修改不会影响到已经发布的项目，直到您把修改后的流程/项目再次发布出去。 -->
          <p>
            {{ $t("releaseProject.word2") }}
          </p>
        </div>
        <!-- <div class="prevVersion" v-if="prevVersion !== '1.0.0'">
          上个版本:{{ prevVersion }}
        </div> -->
        <!-- <div class="prevVersion">版本号</div> -->
        <!-- <div class="versionSelectWarp">
          <el-input v-model="version" placeholder="1.0.0"></el-input>
          <el-input-number
            v-model="versionArr[0]"
            controls-position="right"
            @change="handleChange"
            :min="1"
            size="mini"
          ></el-input-number>
          <el-input-number
            v-model="versionArr[1]"
            controls-position="right"
            @change="handleChange"
            :min="0"
            :max="9"
            size="mini"
          ></el-input-number>
          <el-input-number
            v-model="versionArr[2]"
            controls-position="right"
            @change="handleChange"
            :min="0"
            :max="9"
            size="mini"
          ></el-input-number>
        </div> -->

        <div class="selects" v-if="show">
          <!-- 可见范围 -->
          <div>{{ $t("releaseProject.word3") }}</div>
          <div>
            <el-radio-group v-model="isPublic" @change="selectChange">
              <div class="radioItem">
                <!-- 所有人可见 -->
                <el-radio :label="true">{{
                  $t("releaseProject.word4")
                }}</el-radio>
              </div>
              <div class="radioItem">
                <!-- 仅限我的企业（企业用户专用） -->
                <el-radio
                  :disabled="
                    $store.state.User &&
                      $store.state.User.user &&
                      $store.state.User.user.isPubllic
                  "
                  :label="false"
                  >{{ $t("releaseProject.word5") }}</el-radio
                >
              </div>
            </el-radio-group>
          </div>
        </div>
        <div class="submitContent">
          <div class="submitContentWrap">
            <!-- 填写备注 -->
            <el-input
              type="textarea"
              :placeholder="$t('releaseProject.inputDes')"
              v-model="submitContent"
            ></el-input>
          </div>
        </div>
        <template #footer>
          <div class="lines"></div>
          <span class="dialog-footer" v-if="show">
            <!-- <el-button @click="show = false">取 消</el-button>
            <el-button type="primary" @click="show = false">确 定</el-button> -->
            <!-- 取消 -->
            <button @click="handleClose">
              {{ $t("releaseProject.word6") }}
            </button>
            <!-- 发布 -->
            <button :disabled="loading" @click="releaseProject">
              <i class="el-icon-loading" v-if="loading"></i
              >{{ $t("releaseProject.word7") }}
            </button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import { ElMessage } from "element-plus";
import { publishProject, getProjectReleases } from "@/api/workflow";
import { auditProject } from "@/api/mine";
export default {
  components: {},
  data() {
    return {
      loading: false,
      // show: false,
      isPublic: false,
      version: "1.0.0",
      prevVersion: null,
      versionArr: [1, 0, 0],
      submitContent: "",
      // isShowTip: false,
    };
  },
  props: {
    projectGuid: String,
    show: Boolean,
    projectInfo: Object,
  },
  emits: ["onClose"],
  async created() {
    if (
      this.$store.state.User &&
      this.$store.state.User.user &&
      this.$store.state.User.user.isPublic
    ) {
      this.isPublic = true;
    } else {
      this.isPublic = false;
    }
  },
  mounted() {},
  computed: {},
  watch: {
    // submitContent() {
    //   this.isShowTip = true;
    // },
  },
  methods: {
    openPublishRules() {
      let url = "";
      if (this.projectInfo && this.projectInfo.language == "cn") {
        url = "https://jingtengtech.com/publishRules_ZH.html";
      } else {
        url = "https://jingtengtech.com/publishRules_EN.html";
      }
      // 使用 window.open() 打开新的标签页
      window.open(url, "_blank");
    },
    selectChange(val) {
      console.log(val);
      console.log(this.isPublic);
      if (!this.isPublic) {
        if (this.$store.state.User.user.isPublic) {
          this.isPublic = true;
          // "您不是企业用户,不可选择此选项", "提示",
          this.$alert(
            this.$t("releaseProject.tip1"),
            this.$t("releaseProject.tip2"),
            {
              // 确定
              confirmButtonText: this.$t("releaseProject.tip3"),
              callback: (action) => {},
            }
          );
        }
      }
    },
    handleChange() {
      console.log(this.getVersion());
      this.version = this.getVersion();
    },
    //自动设置版本号
    async open() {
      console.log(this.$store.state.User.user.isPublic);
      let result = await getProjectReleases(this.projectGuid);
      console.log(result);
      if (!result) {
        this.version = "1.0.0";
        console.log(this.version);
        return;
      }
      let str = result.version;
      let arr = str.split(".");
      arr.forEach((value, index) => {
        value = Number(value);
      });
      console.log(arr[2]);
      arr[2] = Number(arr[2]) + 1;
      this.version = String(arr.join("."));
      console.log(process.env.VUE_APP_TENANTGUID);
      console.log(this.$store.state.User.user.isPublic);
      if (this.$store.state.User.user.isPublic) {
        this.isPublic = true;
      }
      // let tempNum = Number(arr.join(""));
      // tempNum++;
      // let tempVersionStr = String(tempNum).split("");
      // let versionRes = tempVersionStr.join(".");
      // this.version = versionRes;
      console.log(this.version);
    },
    // async open() {
    //   let result = await getProjectReleases(this.projectGuid);
    //   console.log(result);
    //   if (result) {
    //     this.prevVersion = result.version;
    //     this.versionArr = this.setVersion(result.version);
    //   } else {
    //     this.prevVersion = "1.0.0";
    //     this.versionArr = this.setVersion("1.0.0");
    //   }
    // },
    // setVersion(str) {
    //   let arr = str.split(".");
    //   arr.forEach((value, index) => {
    //     value = Number(value);
    //   });
    //   arr[arr.length - 1] += 1;
    //   return arr;
    // },
    // getVersion() {
    //   return this.versionArr.join(".");
    // },
    // init(bool) {
    //   this.show = bool;
    // },
    async releaseProject() {
      this.loading = true;
      try {
        let res = await auditProject(
          this.projectGuid,
          this.isPublic,
          this.submitContent
        );
        // if (res.code == 200) {
          if (this.isPublic) {
            this.$message.success({
              // 已提交审核
              message: this.$t("releaseProject.tip4"),
              type: "success",
            });
          } else {
            this.$message.success({
              // 发布成功！
              message: this.$t("releaseProject.tip8"),
              type: "success",
            });
          }

          // this.show = false;
          this.$emit("onClose", { result: "ok" });  
          

        this.loading = false;
      } catch (e) {
        console.log('auditProject e', e, e.code != undefined);

        if (e.code != undefined){
          if (this.isPublic) {
              switch (e.code) {
                case 30134:
                this.$message.error({
                    // 发布失败！
                    message: this.$t("releaseProject.tip6"),
                    type: "error",
                  });
                  break;
                case 30135:
                this.$message({
                    // 此项目已在审核中，请耐心等待
                    message: this.$t("releaseProject.tip7"),
                    type: "warning",
                  });
                  break;

                default:
                this.$message.error({
                    // 发布失败！
                    message: this.$t("releaseProject.tip5"),
                    type: "error",
                  });
                  break;
              }
            } else {
              this.$message.success({
                // 发布失败
                message: this.$t("releaseProject.tip9"),
                type: "success",
              });
            }
          
        }
        else{

          this.$message.error({
            // 发布失败！
            message: this.$t("releaseProject.tip5"),
            type: "error",
          });
        }

        this.loading = false;
      }

      // this.$router.push("/myTasks?tab=Published");
    },
    handleClose() {
      // console.log("handleClose");
      this.$emit("onClose");
    },
  },
};
</script>
<style scoped lang="scss">
#releaseWarp {
  :deep(.releaseWarp) {
    border-radius: 10px;
    overflow: hidden;
  }
  :deep(.el-dialog__header) {
    // color: #f55;
    background: #fff;
    line-height: 20px;
    padding: 15px;
    text-align: left;
    padding-left: 1.5rem;
    .el-dialog__title {
      color: #4582ff;
      font-size: 1rem;
    }
    .el-icon-close {
      color: #4582ff;
      top: 15px;
    }
    // .el-icon-close:hover {
    //   color: #4582ff;
    // }
  }
  :deep(.el-dialog__footer) {
    .lines {
      height: 1px;
      background: #efefef;
      width: 100%;
      margin-bottom: 1.5rem;
    }
    button {
      outline: none;
      border: none;
      width: 8rem;
      height: 2rem;
      cursor: pointer;
      border-radius: 4px;
      color: #fff;
      transition: background 0.1s;
    }
    // A8B7CA
    button:nth-child(1) {
      background: #a8b7ca;
      margin-right: 2rem;
    }
    button:nth-child(1):hover {
      background: #b8c2d2;
    }
    button:nth-child(2) {
      background: #4582ff;
    }
    button:nth-child(2):hover {
      background: #8fb5ff;
    }
  }
  :deep(.el-dialog__body) {
    text-align: left;
    box-sizing: border-box;
    // padding-left: 0rem;
    padding: 10px 30px 10px 30px;
    .radioItem {
      margin: 0.5rem 0 0.5rem 0;
    }
    .contants {
      padding: 1rem;
      box-sizing: border-box;
      background: #f6f7fa;
      border-radius: 3px;
      margin-bottom: 1.5rem;
      > p {
        line-height: 1.5rem;
        > span {
          color: #4582ff;
          font-weight: bold;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .selects {
      margin-top: 1rem;
      box-sizing: border-box;
      padding-left: 1rem;
      > div:nth-child(1) {
        color: #224b77;
        font-size: 1rem;
        padding-bottom: 0.5rem;
      }
      .el-radio {
        color: #7b93ad;
        font-size: 0.8rem;
      }
    }
    .submitContent {
      margin-top: 20px;
      > .submitContentWrap {
        > .el-textarea {
          > .el-textarea__inner {
            min-height: 104px !important;
            color: #1f1f1f;
            background: #f6f7fa;
            border: 1px solid #dde2f1;
            resize: none;
          }
          > .el-textarea__inner::-webkit-scrollbar {
            width: 14px;
            height: 14px;
          }
          > .el-textarea__inner::-webkit-scrollbar-corner {
            background-color: transparent;
          }
          > .el-textarea__inner::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.1);
            background-clip: padding-box;
            border: solid transparent;
            border-radius: 10px;
          }
          > .el-textarea__inner::-webkit-scrollbar-track {
            background-clip: padding-box;
            border: solid transparent;
            border-width: 1px;
          }
        }
        > .el-message-box__errormsg {
          padding: 16px 0;
          text-align: center;
          border-bottom: 1px solid #f6f7fa;
          font-size: 14px;
        }
      }
      > .errorTitle {
        color: #f94a4a;
        text-align: center;
        margin-top: 10px;
      }
    }
    .prevVersion {
      padding-left: 1rem;
      margin-bottom: 1rem;
    }
    .versionSelectWarp {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 1rem;
      > div {
        width: 4.5rem;
        margin-right: 1rem;
        > div {
          > input {
            padding-right: 42px;
          }
        }
      }
    }
  }
}
</style>
