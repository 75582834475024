<template>
  <div>
    <el-form :inline="true" :model="query" @submit.prevent>
      <el-form-item>
        <el-button size="mini" type="primary" @click="handleCreate">
          添加用户账号
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="query.name"
          size="mini"
          placeholder="请输入用户名"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" size="mini" @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="dataList"
      empty-text="无数据"
      element-loading-background="rgba(255, 255, 255, 0.8)"
    >
      <el-table-column prop="username" label="用户名" width="140">
      </el-table-column>
      <el-table-column prop="name" label="姓名" width="120"> </el-table-column>
      <el-table-column prop="roleName" label="角色" width="120">
      </el-table-column>
      <el-table-column align="right" min-width="240">
        <template #default="scope">
          <span v-if="scope.row.role == '1'">此账号不允许任何用户操作!</span>
          <el-button
            v-if="scope.row.role != '1'"
            type="warning"
            size="mini"
            @click="resetPassword(scope.$index, scope.row)"
            >重置密码</el-button
          >
          <el-button
            v-if="scope.row.role != '1'"
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="scope.row.role != '1'"
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :page-size="size"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
const _path = "/account";
const _endpoint = "account";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      query: {
        name: "",
      },
      page: 0,
      size: 10,
      total: 0,
      dataList: [],
    };
  },
  created: function () {
    this.fetchData();
  },
  methods: {
    onSubmit: function () {
      this.loading = true;
      const $this = this;
      const params = new URLSearchParams({
        page: this.page,
        size: this.size,
        username: this.query.name,
      });
      const endpoint = `${_endpoint}?${params.toString()}`;
      this.$auth.fetch(
        {
          endpoint,
          method: "GET",
        },
        // success
        (result) => {
          this.loading = false;
          this.list = result.data.data;
          this.total = result.data.total;
        },
        // error
        (error) => {
          console.log(error);
        },
        // final
        () => {}
      );
    },
    resetPassword: function (index, row) {
      this.$confirm("此操作将重置密码为原始密码, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$auth.fetch(
          {
            endpoint: `resetpassword/${row.id}`,
            method: "POST",
          },
          // success
          (result) => {
            if (result.data === "401") {
              ElMessage.error("此账号的密码不允许重置!");
              return;
            }
            this.$message({
              type: "success",
              message: "密码重置成功！",
            });
            console.log(result);
          },
          // error
          (error) => {
            console.log(error);
            this.$message({
              type: error,
              message: "密码重置失败！",
            });
          },
          // final
          () => {}
        );
      });
    },
    handlePageChange: function (page) {
      this.fetchData(page);
    },
    handleCreate: function () {
      this.$router.push({ path: "/account/new" });
    },
    handleEdit: function (index, row) {
      console.log(row);
      this.$router.push({ path: `/account/${row.id}` });
    },
    handleDelete: function (index, row) {
      this.$confirm("是否删除此账号?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let userInfo = JSON.parse(localStorage.getItem("user"));
          if (userInfo.id === row.id) {
            ElMessage.error("不可删除自己的账号!");
            return;
          }
          this.$auth.fetch(
            {
              endpoint: `accountList/${row.id}`,
              method: "DELETE",
            },
            // success
            (result) => {
              console.log(result);
              this.fetchData();
              if (result.data === false) {
                ElMessage.error("您无权限删除此账号!");
                return;
              }
              ElMessage.success({
                message: "该账号已删除",
                type: "success",
              });
            },
            // error
            (error) => {
              console.log(error);
              this.$message({
                type: error,
                message: "用户删除失败。",
              });
            },
            // final
            () => {}
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    fetchData() {
      this.$auth.fetch(
        {
          endpoint: "accountList",
          method: "GET",
        },
        // success
        (result) => {
          // console.log(result.data);
          this.dataList = result.data;
        },
        // error
        (error) => {},
        // final
        () => {}
      )
      // this.$auth.fetch(
      //   {
      //     endpoint: "account",
      //     method: "GET",
      //   },
      //   // success
      //   (result) => {
      //     console.log(result.data);
      //     // this.loading = false;
      //     // this.list = result.data.data;
      //     // this.total = result.data.total;
      //   },
      //   // error
      //   (error) => {
      //     console.log(error);
      //   },
      //   // final
      //   () => {}
      // );
    }, // end of fetchData()
  },
};
</script>
