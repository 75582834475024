const AssetCategory = {
    Image: 1,
    Video: 2,
    PDF: 3,
    ThreeD: 1001,
    UnityAB: 2001,
    Others: 65535
}

const AssetType = {
    System: 1,
    Custom: 2
}

// module.exports = {
//     AssetCategory: AssetCategory,
//     AssetType: AssetType
// }
const baseModelGravityList = [
    {
        key: 1,
        // X轴正方向
        label: 'asset.word1',
        value: {
            x: 1,
            y: 0,
            z: 0,
        },
    },
    {
        key: 2,
        // X轴负方向
        label: 'asset.word2',
        value: {
            x: -1,
            y: 0,
            z: 0,
        },
    },
    {
        key: 3,
        // Y轴正方向
        label: 'asset.word3',
        value: {
            x: 0,
            y: 1,
            z: 0,
        },
    },
    {
        key: 4,
        // Y轴负方向
        label: 'asset.word4',
        value: {
            x: 0,
            y: -1,
            z: 0,
        },
    },
    {
        key: 5,
        // Z轴正方向
        label: 'asset.word5',
        value: {
            x: 0,
            y: 0,
            z: 1,
        },
    },
    {
        key: 6,
        // Z轴负方向
        label: 'asset.word6',
        value: {
            x: 0,
            y: 0,
            z: -1,
        },
    },
]

const positionType = [
    {
        // 二维码定位
        label: 'asset.word7',
        value: false,
    },
    // 物体识别定位
    {
        label: 'asset.word8',
        value: true,
    },
]

const baseModelUnitList = [
    {
        // 米
        label: 'asset.word9',
        value: 1,
    },
    {
        // 分米
        label: 'asset.word10',
        value: 0.1,
    },
    {
        // 厘米
        label: 'asset.word11',
        value: 0.01,
    },
]

const langs = [
    {
        label: "English",
        value: "en",
    },
    {
        label: "简体中文",
        value: "zh",
    },
    {
        label: "한국어",
        value: "kr",
    },
]

const deviceType = [
    {
        // 全部
        label: "asset.word12",
        value: 0,
    },
    {
        // hololens
        label: "asset.word13",
        value: 1,
    },
    {
        // IOS
        label: "asset.word14",
        value: 2,
    },
]
const category = [
    {
        // 全部
        label: "asset.word15",
        value: 0,
    },
    {
        // 工业设备
        label: "asset.word16",
        value: 1,
    },
    {
        // 科研设备
        label: "asset.word17",
        value: 2,
    },
    {
        // 医疗器械
        label: "asset.word18",
        value: 3,
    },
    {
        // 其它
        label: "asset.word19",
        value: 99,
    },
]
const template2DList = [
    {
        // 文本
        name: "asset.word20",
        img: require("@/assets/task-detail/text.png"),
        type: 1,
        refName: "text",
    },
    {
        // 多媒体
        name: "asset.word21",
        img: require("@/assets/task-detail/media.png"),
        type: 0,
        refName: "media",
    },
    {
        // 文本+多媒体
        name: "asset.word22",
        img: require("@/assets/task-detail/mediaText.png"),
        type: 3,
        refName: "mediaText",
    },
    {
        // 多媒体+多媒体
        name: "asset.word23",
        img: require("@/assets/task-detail/mediaMedia.png"),
        type: 2,
        refName: "mediaMedia",
    },
    {
        // 文本+多媒体+多媒体
        name: "asset.word24",
        img: require("@/assets/task-detail/textMediaMedia.png"),
        type: 4,
        refName: "textMediaMedia",
    },
];
const projectLanguage = [
    {
        label: "简体中文",
        value: "cn",
    },
    {
        label: "English",
        value: "en",
    },
];
const fileMaxSize = 20 * 1024 * 1024;
const videoMaxSize = 200 * 1024 * 1024;
const modelMaxSize = 50 * 1024 * 1024;
exports.AssetCategory = AssetCategory
exports.AssetType = AssetType
exports.baseModelGravityList = baseModelGravityList
exports.positionType = positionType
exports.baseModelUnitList = baseModelUnitList
exports.langs = langs;
exports.deviceType = deviceType;
exports.category = category;
exports.fileMaxSize = fileMaxSize;
exports.videoMaxSize = videoMaxSize;
exports.modelMaxSize = modelMaxSize;
exports.template2DList = template2DList;
exports.projectLanguage = projectLanguage;